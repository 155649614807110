import { CustomerActionTypes } from '../types';

const initialState = {
  sidebarInfo: {
    visaType: {},
    selected_sidebar: [],
    child_cases: [],
    master_intake: []
  },
  loading: false
};

export const CustomerSidebarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CustomerActionTypes.TOGGLE_SIDEBAR_DATA_LOADING:
      return {
        ...state,
        loading: payload
      };
    case CustomerActionTypes.SIDEBAR_DATA:
      return {
        ...state,
        sidebarInfo: {
          ...state.sidebarInfo,
          visaType: { ...payload }
        }
      };
    case CustomerActionTypes.SET_SELECTED_SIDEBARINFO:
      return {
        ...state,
        sidebarInfo: {
          ...state.sidebarInfo,
          selected_sidebar: [...payload]
        }
      };
    case CustomerActionTypes.SET_SIDEBAR_CHILD_CASES:
      return {
        ...state,
        sidebarInfo: {
          ...state.sidebarInfo,
          child_cases: [...payload]
        }
      };
    case CustomerActionTypes.SET_MASTER_INTAKE_SIDEBAR:
      return {
        sidebarInfo: {
          ...state.sidebarInfo,
          master_intake: [...payload]
        }
      };
    default:
      return state;
  }
};

export default CustomerSidebarReducer;
