import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import FloatLabelInput from 'components/shared/FloatLabelInput';

import { Button, Form, Modal } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

const SaveViewModal = ({ visible, setIsModalOpen, setActiveContentTab, setOpenDrawer }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [validationResponse, setValidationResponse] = useState('');

  const { isLoading } = useSelector(state => state.ImSpecCaseDashboardBeta.savedViews);

  const {
    savedViews: { list: savedViewsList }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  const modalProps = {
    width: 438,
    centered: true,
    icon: null,
    className: 'confirm-radio',
    cancelButtonProps: {
      className: 'submit-section-confirm-modal-cancel_btn'
    },
    okText: 'Close'
  };

  const onFinish = () => {
    if (savedViewsList.length < 10) {
      dispatch(
        ImSpecCaseDashboardBetaAction.saveFilters(
          form,
          setValidationResponse,
          setIsModalOpen,
          setActiveContentTab,
          setOpenDrawer
        )
      );
    } else {
      onCancel();
      Modal.info({
        ...modalProps,
        content: (
          <>
            <p style={{ color: '#ED6762', marginBottom: '17px', fontWeight: '700' }}>
              <InfoCircleFilled style={{ color: '#ED6762' }} />
              Oops!
            </p>
            <p className="submit-confirm-modal-title" style={{ marginBottom: '17px' }}>
              The view cannot be saved.
            </p>
            <p className="confirm-radio-description" style={{ fontSize: '14px' }}>
              You have exceeded the maximum allowed number of saved views.{' '}
            </p>
          </>
        ),
        onOk: () => {}
      });
    }
  };

  const onFieldsChange = (_, all) => {
    setDisabled(all.some(e => e.touched === false || e.errors.length > 0 || !e.value.trim()));
    setValidationResponse('');
  };

  const onCancel = () => {
    setIsModalOpen(false);
    setValidationResponse('');
    form.resetFields();
  };

  return (
    <Modal
      forceRender
      className="assign-modal save-view-modal"
      width={414}
      title="Name this view"
      centered
      closable={false}
      footer={null}
      open={visible}
      onCancel={onCancel}
    >
      <hr />
      <p style={{ fontSize: '16px', padding: '10px' }}>
        Have easy access to your saved views by naming them.
      </p>
      <Form
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        className="edit-petitioner"
      >
        <Form.Item
          name="view_name"
          validateFirst
          style={{ marginBottom: '5px' }}
          getValueProps={value => ({ value: value || '' })}
          className={validationResponse && 'error-border'}
          rules={[
            {
              required: true,
              message: 'This field is required.'
            },
            {
              max: 50,
              message: 'This view name is too long.'
            },
            {
              validator: (_, value) => (value.trim() ? Promise.resolve() : setDisabled(true))
            }
          ]}
        >
          <FloatLabelInput label="View name" />
        </Form.Item>
        {validationResponse && (
          <p className="error" style={{ paddingInlineStart: '10px' }}>
            {validationResponse}
          </p>
        )}
        <Form.Item className="submitting" htmlFor="submit">
          <Button onClick={onCancel} className="assign-btn cancel">
            Cancel
          </Button>
          <Button
            loading={isLoading}
            disabled={disabled || validationResponse}
            type={disabled ? 'disabled' : 'primary'}
            className="assign-btn assign"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

SaveViewModal.propTypes = {
  visible: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  setActiveContentTab: PropTypes.func,
  setOpenDrawer: PropTypes.func
};

SaveViewModal.defaultProps = {
  visible: false
};

export default SaveViewModal;
