import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerContentAction, HistoryLogCommentsThreadAction } from 'store/Actions';

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import useClosePopoverWhenScrolling from 'hooks/useClosePopoverWhenScrolling';
import { sectionAndSubsectionNames } from 'utils/helper';

// components
import { Grouped } from './Grouped';
import { Ungrouped } from './Ungrouped';
import Comments from './Grouped/Questions/elements/Comments';
import DependentFormModal from 'components/shared/DependentFormModal';

import { Row, Col, Button, Popover, Spin, Modal } from 'antd';
import { congratulationsIcon, lightBulb } from 'components/Icons/Icons';
import { CloseOutlined, InfoCircleFilled, LoadingOutlined } from '@ant-design/icons';
import 'react-datepicker/dist/react-datepicker.css';
import '../index.scss';

const Subsection = () => {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const { masterIntakeID, petitionerName, caseID, sectionTitle, subSectionTitle } = useParams();

  const hideNextAndPreviousButtons = useFlag('hide-next-and-previous-buttons');
  const hideCommentsOverview = useFlag('hide-comments-overview');
  const { flagsReady } = useFlagsStatus();

  const {
    subSectionContent: {
      subsection: subSectionContent,
      loading,
      answersLoading,
      answers,
      sendAnswersLoading,
      form_questionnaire,
      field_name,
      iterationName,
      questionSet,
      createDependentLoading
    },
    user: { user },
    customerSidebar: {
      sidebarInfo: { selected_sidebar, visaType, child_cases }
    }
  } = useSelector(state => ({
    subSectionContent: state.customerContent,
    user: state.auth,
    customerSidebar: state.customerSidebar
  }));

  const [visiblePopover, setVisiblePopover] = useState(false);
  const [hasVisited, setHasVisited] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [nextPreviousLinks, setNextPreviousLinks] = useState({
    next: null,
    previous: null
  });
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const everVisited = JSON.parse(localStorage.getItem(`${user?.email}`));

  const onScroll = () => {
    visiblePopover && setVisiblePopover(false);
  };

  useClosePopoverWhenScrolling(scrollRef, onScroll);

  useEffect(() => {
    !!scrollRef.current && (scrollRef.current.scrollTop = 0);
  }, [subSectionContent]);

  useEffect(() => {
    everVisited && setHasVisited(everVisited.includes(subSectionContent.id));
    !!Object.keys(answers).length &&
      !loading &&
      !!subSectionContent.description &&
      setModalVisible(!hasVisited);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subSectionContent.description, hasVisited, loading]);

  useEffect(() => {
    if (flagsReady && !hideCommentsOverview) {
      dispatch(
        HistoryLogCommentsThreadAction.getCommentsTabs(
          caseID,
          masterIntakeID,
          sectionTitle ? sectionAndSubsectionNames(sectionTitle) : sectionTitle
        )
      );
    }
    return () => {
      dispatch(HistoryLogCommentsThreadAction.setCommentsTab([]));
    };
  }, [caseID, dispatch, flagsReady, hideCommentsOverview, masterIntakeID, sectionTitle]);

  useEffect(() => {
    const getCustomerInitialData = async () => {
      await dispatch(
        CustomerContentAction.getCustomerContentForm(
          masterIntakeID,
          petitionerName,
          subSectionTitle,
          caseID
        )
      );
      await dispatch(
        CustomerContentAction.getContentAnswers(
          masterIntakeID,
          petitionerName,
          sectionTitle,
          caseID
        )
      );
    };
    caseID && sectionTitle && subSectionTitle && getCustomerInitialData();

    return () => {
      dispatch(CustomerContentAction.setCustomerContent({}));
      dispatch(CustomerContentAction.setContentAnswers({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, caseID, subSectionTitle, sectionTitle]);

  useEffect(() => {
    const getMasterIntakeData = async () => {
      await dispatch(
        CustomerContentAction.getCustomerContentForm(
          masterIntakeID,
          petitionerName,
          subSectionTitle,
          caseID
        )
      );
      await dispatch(
        CustomerContentAction.getContentAnswers(
          masterIntakeID,
          petitionerName,
          sectionTitle,
          caseID
        )
      );
    };
    masterIntakeID && petitionerName && subSectionTitle && getMasterIntakeData();

    return () => {
      dispatch(CustomerContentAction.setCustomerContent({}));
      dispatch(CustomerContentAction.setContentAnswers({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, masterIntakeID, petitionerName, subSectionTitle]);

  useEffect(() => {
    const findSection = section => section.title === sectionAndSubsectionNames(sectionTitle);

    if (sectionTitle && !!selected_sidebar.length) {
      const currentSection =
        caseID === visaType.case_id
          ? selected_sidebar.find(findSection)
          : child_cases
              .find(child_case => caseID === child_case.case_id)
              .sections.find(findSection);

      if (currentSection) {
        const currentSubsectionIndex = currentSection.subsection_set.findIndex(
          subsection => subsection.title === sectionAndSubsectionNames(subSectionTitle)
        );

        const pathname = location.pathname.split('/').slice(0, 3).join('/');

        const previous =
          currentSubsectionIndex === 0 || currentSubsectionIndex === -1
            ? null
            : `${pathname}/${sectionTitle}/${currentSection.subsection_set[
                currentSubsectionIndex - 1
              ].title.replaceAll(' ', '-')}`;
        const next =
          currentSubsectionIndex === currentSection.subsection_set.length - 1
            ? null
            : `${pathname}/${sectionTitle}/${currentSection.subsection_set[
                currentSubsectionIndex + 1
              ].title.replaceAll(' ', '-')}`;

        setNextPreviousLinks(prev => ({ ...prev, next, previous }));
      }
    }
  }, [
    caseID,
    child_cases,
    location.pathname,
    sectionTitle,
    selected_sidebar,
    subSectionTitle,
    visaType.case_id
  ]);

  const onReview = () => {
    if (masterIntakeID) {
      navigate(`/master-intake/${masterIntakeID}/${petitionerName.replaceAll(' ', '-')}/review`);
    } else {
      navigate(
        `/your-questionnaires/${caseID}/${subSectionContent?.section_title.replaceAll(
          ' ',
          '-'
        )}/review`
      );
    }
  };

  const handleVisiblePopoverChange = visiblePopover => {
    setVisiblePopover(visiblePopover);
    setModalVisible(false);
  };

  const handleVisitedModalChange = () => {
    setModalVisible(false);
  };

  const subSectionDesc = (
    <>
      <div className="subSectionDescBody">
        <div className="subSectionDescBody_content">
          <span>{lightBulb}</span>
          <p style={{ whiteSpace: 'pre-line' }}>{subSectionContent?.description}</p>
        </div>
      </div>
      <div className="close-icon">
        <Button
          type="ghost"
          shape="circle"
          onClick={() => handleVisiblePopoverChange(false)}
          icon={<CloseOutlined />}
        />
      </div>
    </>
  );

  const Links = useMemo(() => {
    return flagsReady && !hideNextAndPreviousButtons && !masterIntakeID ? (
      <Col className="sections-navigation">
        {!nextPreviousLinks.next && (
          <Row align="middle" className="end-of-section">
            You have reached the end of this section! {congratulationsIcon}
          </Row>
        )}
        <Row className="navigation-btns">
          {nextPreviousLinks.previous && (
            <span
              tabIndex="-1"
              className={`navigation-btn previous ${sendAnswersLoading ? 'disabled' : ''}`}
            >
              <Link to={nextPreviousLinks.previous || '/'}>Previous</Link>
            </span>
          )}

          {!nextPreviousLinks.next && (
            <span tabIndex="-1" className="navigation-btn review-submit">
              <Link
                to={`/your-questionnaires/${caseID}/${subSectionContent?.section_title?.replaceAll(
                  ' ',
                  '-'
                )}/review`}
              >
                Review & Submit
              </Link>
            </span>
          )}
          {nextPreviousLinks.next && (
            <span
              tabIndex="-1"
              className={`navigation-btn ${sendAnswersLoading ? 'disabled' : ''}`}
            >
              <Link to={nextPreviousLinks.next || '/'}>Next</Link>
            </span>
          )}
        </Row>
      </Col>
    ) : null;
  }, [
    caseID,
    flagsReady,
    hideNextAndPreviousButtons,
    masterIntakeID,
    nextPreviousLinks.next,
    nextPreviousLinks.previous,
    sendAnswersLoading,
    subSectionContent?.section_title
  ]);

  const openExplInfo = () => {
    setInfoModalVisible(v => !v);
  };

  const onCancel = () => {
    navigate(location.pathname);
    dispatch(CustomerContentAction.setDependentForm(null, null, null, null, navigate, location));
  };

  const onCreateDep = (values, form) => {
    dispatch(
      CustomerContentAction.createDependent(
        values,
        caseID,
        field_name,
        { navigate, location },
        sectionTitle,
        form,
        masterIntakeID,
        petitionerName
      )
    );
  };

  return (
    <div className={`user-container ${loading ? 'user-container-loading' : ''}`}>
      {loading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 80, color: '#FBD963' }} />} />
      ) : (
        <Spin
          tip="Loading..."
          spinning={answersLoading}
          indicator={<LoadingOutlined style={{ fontSize: 50, color: '#FBD963' }} />}
        >
          <Col className="mainSubsectionContent">
            <div className="pageTitle">
              <div className="pageTitle_content">
                {masterIntakeID ? (
                  <h1 className="sectionName">
                    {sectionAndSubsectionNames(petitionerName)} Master Intake
                  </h1>
                ) : (
                  <h1 className="sectionName">{subSectionContent?.section_title}</h1>
                )}
                {!loading && subSectionContent.section_title && (
                  <Button disabled={sendAnswersLoading} onClick={onReview} className="primary-btn">
                    Review
                  </Button>
                )}
                {!loading && masterIntakeID && (
                  <Button disabled={sendAnswersLoading} onClick={onReview} className="primary-btn">
                    Review
                  </Button>
                )}
              </div>
              {subSectionContent?.section_title && (
                <div className="sectionDesc">
                  <span />
                  <p>
                    {subSectionContent?.has_available_sections_to_sync ? (
                      <>
                        Please complete each section of this questionnaire, then click <b>Review</b>{' '}
                        to check your responses. After reviewing, please <b>Submit</b> the form to
                        sync details provided here to other case questionnaires.
                      </>
                    ) : (
                      <>
                        Please complete each section of this questionnaire, then click Review to
                        check your responses. After reviewing, please submit the form to your
                        Legalpad team.
                      </>
                    )}
                  </p>
                </div>
              )}
              {masterIntakeID && (
                <div className="sectionDesc">
                  <span />
                  <p>
                    Please complete each section of this questionnaire, then click Review to check
                    your responses. After reviewing, submit the form to provide these details in{' '}
                    {petitionerName}
                    's employee cases.
                  </p>
                </div>
              )}
            </div>
            <div ref={scrollRef} className="scrollableBlock">
              <Col className="mainContentBlock">
                <div className="subSectionHeader">
                  <h2 className="subSectionName">{subSectionContent?.title}</h2>
                  {subSectionContent?.description && (
                    <Popover
                      placement="right"
                      overlayClassName="subSectionDesc"
                      content={subSectionDesc}
                      trigger="click"
                      open={visiblePopover}
                      onOpenChange={handleVisiblePopoverChange}
                    >
                      <InfoCircleFilled />
                    </Popover>
                  )}
                  <Popover
                    placement="right"
                    overlayClassName="subSectionDesc"
                    content={subSectionDesc}
                    trigger="click"
                    open={modalVisible}
                    onOpenChange={handleVisitedModalChange}
                  />
                </div>
                <div className="subSectionExplanation">
                  {subSectionContent.explanation && subSectionContent.explanation.includes('[') ? (
                    JSON.parse(subSectionContent.explanation).map((explanation, i) => (
                      <div key={i} className="subSectionExp">
                        <div className="left" />
                        <div className="exp">
                          <p
                            style={{ whiteSpace: 'pre-line' }}
                            dangerouslySetInnerHTML={{
                              __html: explanation.text
                            }}
                          />
                          {explanation.is_info && (
                            <Button type="ghost" className="learn_more" onClick={openExplInfo}>
                              {' '}
                              <p>Learn more</p>
                            </Button>
                          )}
                        </div>
                      </div>
                    ))
                  ) : subSectionContent.explanation ? (
                    <div className="subSectionExp">
                      <div className="left" />
                      <div className="exp">
                        <p
                          style={{ whiteSpace: 'pre-line' }}
                          dangerouslySetInnerHTML={{
                            __html: subSectionContent.explanation
                          }}
                        />
                        {subSectionContent.info && (
                          <Button type="ghost" className="learn_more" onClick={openExplInfo}>
                            {' '}
                            <p>Learn more</p>{' '}
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                {subSectionContent?.type === 'grouped' ? (
                  subSectionContent?.group_set?.map((elem, index) => {
                    return <Grouped key={index} scrollRef={scrollRef} curGroupContent={elem} />;
                  })
                ) : (
                  <Ungrouped scrollRef={scrollRef} curContent={subSectionContent} />
                )}
              </Col>
              {Links}
            </div>
          </Col>
        </Spin>
      )}
      {modalVisible && <div className="mask" />}
      <Comments />
      <Modal
        centered
        className="assign-modal info"
        open={infoModalVisible}
        title={null}
        width={919}
        footer={null}
        onCancel={openExplInfo}
      >
        {subSectionContent.info && (
          <p
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: subSectionContent.info }}
          />
        )}
        <Button type="primary" onClick={openExplInfo}>
          Close
        </Button>
      </Modal>
      <DependentFormModal
        form_questionnaire={form_questionnaire}
        field_name={field_name}
        iterationName={iterationName}
        questionSet={questionSet}
        answers={answers}
        onCancelDep={onCancel}
        onCreateDep={onCreateDep}
        loading={createDependentLoading}
      />
    </div>
  );
};

export default Subsection;
