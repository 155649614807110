import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import CustomerLayout from 'components/Layouts/CustomerLayout';
import ImSpecLayout from 'components/Layouts/ImSpecLayout';
import { AccessDenied, PageNotFound, UnavailableInvitationError } from 'pages/PublicPages';

const LayoutAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('user'));

  const layouts = {
    customer: <CustomerLayout />,
    IS: <ImSpecLayout />,
    CS: <ImSpecLayout />,
    attorney: <ImSpecLayout />
  };

  if (location.state?.type === 'page-not-found') return <PageNotFound />;
  if (location.state?.type === 'access-denied') return <AccessDenied />;
  if (location.state?.type === 'case-invitation-error') return <UnavailableInvitationError />;

  return allowedRoles?.includes(user?.role) ? (
    layouts[user?.role]
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} />
  );
};

LayoutAuth.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string)
};

export default LayoutAuth;
