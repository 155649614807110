import { CustomerActionTypes } from 'store/types';
import { AuthActions } from 'store/Actions/auth.action';
import { CustomerProfilePageService } from 'services';
import { Modal } from 'antd';
import React from 'react';

const getUserProfile = () => async dispatch => {
  try {
    dispatch(profileLoading(true));
    const res = await CustomerProfilePageService.getProfile();

    dispatch(setUserProfile(res.data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(profileLoading(false));
  }
};

const resetUserPassword =
  (passData, setValidationResponse, cancelResetPass) => async (dispatch, getState) => {
    try {
      dispatch(resetPassLoading(true));
      const {
        auth: { user }
      } = getState();
      const { data } = await CustomerProfilePageService.resetPass(passData);

      const refreshPage = async () => {
        await dispatch(getUserProfile());
        cancelResetPass();
      };

      localStorage.setItem('user', JSON.stringify({ ...user, ...data.cred_data }));
      dispatch(AuthActions.loginAccess({ ...user, ...data.cred_data }));

      Modal.info({
        width: 438,
        centered: true,
        icon: null,
        className: 'submit-section-confirm-modal congrats',
        okButtonProps: {
          className: 'submit-section-confirm-modal-submit_btn',
          loading: false
        },
        okText: 'Close',
        content: (
          <>
            <span className="submit-section-confirm-modal-content">Success!</span>
            <span className="submit-section-confirm-modal-title">
              {' '}
              Password successfully updated.{' '}
            </span>
            <hr />
            <span className="submit-section-confirm-modal-title">
              {' '}
              You have been signed out of other devices where you might have been signed in.{' '}
            </span>
          </>
        ),
        onOk: () => refreshPage()
      });
    } catch (e) {
      setValidationResponse(e.data.password);
      console.warn(e.data);
    } finally {
      dispatch(resetPassLoading(false));
    }
  };

const setUserProfile = info => {
  return {
    type: CustomerActionTypes.SET_PROFILE_CONTENT,
    payload: { ...info }
  };
};

const profileLoading = loading => {
  return {
    type: CustomerActionTypes.PROFILE_LOADING,
    payload: loading
  };
};

const resetPassLoading = loading => {
  return {
    type: CustomerActionTypes.RESET_PASS_LOADING,
    payload: loading
  };
};

export const CustomerProfilePageActions = {
  getUserProfile,
  resetUserPassword
};
