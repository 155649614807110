import { ImSpecActionTypes } from 'store/types';

const initialState = {
  cases: {
    list: [],
    isLoading: false
  },
  representatives: {
    list: [],
    isLoading: false
  },
  companyMasterIntakes: {
    list: [],
    isLoading: false
  },
  petitioner: null
};

const ImSpecPetitionerPageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ImSpecActionTypes.PETITIONER_CASES_LOADING:
      return {
        ...state,
        cases: {
          ...state.cases,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_PETITIONER_CASES:
      return {
        ...state,
        cases: {
          ...state.cases,
          list: [...payload]
        }
      };
    case ImSpecActionTypes.REPRESENTATIVES_LOADING:
      return {
        ...state,
        representatives: {
          ...state.representatives,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_REPRESENTATIVES:
      return {
        ...state,
        representatives: {
          ...state.representatives,
          list: [...payload]
        }
      };
    case ImSpecActionTypes.SET_PETITIONER_DETAILS:
      return {
        ...state,
        petitioner: { ...payload }
      };
    case ImSpecActionTypes.SET_COMPANY_MASTER_INTAKES_LOADING:
      return {
        ...state,
        companyMasterIntakes: {
          ...state.companyMasterIntakes,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_COMPANY_MASTER_INTAKES:
      return {
        ...state,
        companyMasterIntakes: {
          ...state.companyMasterIntakes,
          list: [...payload]
        }
      };
    default:
      return state;
  }
};

export default ImSpecPetitionerPageReducer;
