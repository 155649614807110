import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { AuthActions } from 'store/Actions';

const VerifyAccount = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  const sendAnotherEmail = () => {
    dispatch(
      AuthActions.resendVerifyEmail({ email: state.email, type: 'account-verification' }, navigate)
    );
  };

  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content resetPass">
        <Row>
          <h3>Please verify your account</h3>
        </Row>
        <Row type="flex" justify="center" align="middle" className="publicForms_content_cloud">
          <p>
            Please see our welcome email in your inbox and click "Take me to my account" to sign up.
          </p>
        </Row>
        <hr />
        <Row type="flex" justify="center" align="middle" className="publicForms_content_footer">
          <p>
            Can’t find the invitation in your inbox? <br />
            <Button type="link" onClick={sendAnotherEmail}>
              Click here{' '}
            </Button>
            to receive it again.
          </p>
        </Row>
      </Col>
    </Row>
  );
};

export default VerifyAccount;
