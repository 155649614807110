export const SERVER = process.env.REACT_APP_API_URL;

import usStatesData from './usStates.json';
export const usStates = usStatesData;

import currenciesData from './currencies.json';
export const currencies = currenciesData;

const special = [
  'Zeroth',
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
  'Eleventh',
  'Twelfth',
  'Thirteenth',
  'Fourteenth',
  'Fifteenth',
  'Sixteenth',
  'Seventeenth',
  'Eighteenth',
  'Nineteenth'
];

export const SectionsAndSubsections = {
  'F-1-Details': 'F-1 Details',
  'I-539-Information': 'I-539 Information',
  'I-539A-Information': 'I-539A Information',
  'I-539-Child-Information': 'I-539 Child Information',
  'I-539-Spouse-Information': 'I-539 Spouse Information',
  'L-1-Employer-Supplement': 'L-1 Employer Supplement',
  'L-1B-Employee-Supplement': 'L-1B Employee Supplement',
  'EB-2-NIW-Qualifications': 'EB-2 NIW Qualifications'
};

const deca = ['Twent', 'Thirt', 'Fort', 'Fift', 'Sixt', 'Sevent', 'Eight', 'Ninet'];

export const CUSTOMER_SIDEBAR_ITEMS = [
  {
    id: 1,
    value: 'Your Questionnaires',
    path: '/your-questionnaires',
    icon: 'dataSearch',
    className: 'tab'
  },
  {
    id: 2,
    value: 'Legalpad Perks',
    path: '/perks',
    icon: 'perksIcon',
    className: 'tab'
  },
  {
    id: 3,
    value: 'Refer A Friend',
    path: '/refer-a-friend',
    icon: 'referAFriend',
    className: 'tab'
  }
];

export const IS_SIDEBAR_ITEMS = [
  {
    id: 1,
    value: 'Client Management',
    path: '/client-management',
    icon: 'clientManagement',
    className: 'tab'
  },
  {
    id: 2,
    value: 'Case Dashboard',
    path: '/case-dashboard',
    icon: 'caseDashboard',
    className: 'tab'
  },
  {
    id: 3,
    value: 'Petitioner Dashboard',
    path: '/petitioner-dashboard',
    icon: 'petitionerDashboard',
    className: 'tab'
  }
];

export const CUSTOMER_ACCOUNT_PROFILE_SIDEBAR_ITEMS = [
  {
    id: 1,
    value: 'Profile',
    path: '/account-profile',
    icon: 'accountProfile',
    className: 'tab'
  }
];

export const IS_ACCOUNT_PROFILE_SIDEBAR_ITEMS = [
  {
    id: 1,
    value: 'Profile',
    path: '/account-profile',
    icon: 'accountProfile',
    className: 'tab'
  }
];

export const STATE_TABS = [
  { key: 1, state: 'Active' },
  { key: 2, state: 'Closed' },
  { key: 3, state: 'Pending' },
  { key: 4, state: 'All' }
];

export const STATUS_TABS = [
  { key: 1, status: 'Not Attempted' },
  { key: 2, status: 'Attempted' },
  { key: 3, status: 'Client Responded' },
  { key: 4, status: 'IS Reviewed' },
  { key: 5, status: 'Client Revised' },
  { key: 6, status: 'IS Approved' }
];

export const stringifyNumber = n => {
  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';
  return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
};
