import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import IterativeSection from 'pages/CustomerPages/SectionReview/elements/IterativeSection';

const IterativeQuestion = ({
  question: { title, children: nestedQuestion, group_name },
  sectionsAnswers
}) => {
  const [iterativeAnswers, setIterativeAnswers] = useState([]);

  useEffect(() => {
    sectionsAnswers[group_name] &&
      !!Object.keys(sectionsAnswers[group_name]).length &&
      setIterativeAnswers(Object.keys(sectionsAnswers[group_name]));
  }, [group_name, sectionsAnswers]);

  return iterativeAnswers.map((questionSet, index) => (
    <IterativeSection
      key={index + 1}
      index={index}
      questionSet={questionSet}
      title={title}
      nestedQuestion={nestedQuestion}
      sectionsAnswers={sectionsAnswers}
      group_name={group_name}
    />
  ));
};

IterativeQuestion.propTypes = {
  question: PropTypes.shape({
    title: PropTypes.string,
    children: PropTypes.array,
    group_name: PropTypes.string
  }),
  sectionsAnswers: PropTypes.object
};

IterativeQuestion.defaultProps = {
  question: {},
  sectionsAnswers: {}
};

export default IterativeQuestion;
