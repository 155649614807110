import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import AutocompleteCheckboxTags from 'components/shared/AutocompleteCheckboxTags';
import { orderListByKey } from 'utils/helper';
import { Form } from 'antd';

const Beneficiary = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const selectedList = Form.useWatch('beneficiary', form);

  const [searchValue, setSearchValue] = useState('');

  const {
    filters: {
      beneficiary: { list, isLoading }
    },
    filterAnswers: { beneficiary }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    !!searchValue && dispatch(ImSpecCaseDashboardBetaAction.getBeneficiary(searchValue));
  }, [dispatch, searchValue]);

  useEffect(() => {
    if (typeof selectedList !== 'undefined') {
      dispatch(ImSpecCaseDashboardBetaAction.setSelectedBeneficiary(selectedList));
    }
  }, [beneficiary, dispatch, selectedList]);

  const helperBeneficiary = [
    ...orderListByKey(list, ['first_name', 'last_name']).map(
      ({ id, first_name, last_name, email }) => ({
        beneficiary: `${first_name} ${last_name}`,
        email,
        id
      })
    )
  ];

  return (
    <AutocompleteCheckboxTags
      name="beneficiary"
      isLoading={isLoading}
      form={form}
      options={helperBeneficiary}
      filterOption="beneficiary"
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      checkedValues={beneficiary}
    />
  );
};

export default Beneficiary;
