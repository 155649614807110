import PropTypes from 'prop-types';
import nestedTableColumns from 'pages/ImSpecPages/PetitionerDashboard/elements/nestedTableColumns';

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const PetitionerDashboardNestedTable = ({ entryPetitionerCases: { list, isLoading } }) => {
  const hideChildCases = useFlag('hide-child-cases');
  const { flagsReady } = useFlagsStatus();

  const columns = nestedTableColumns({ flagsReady, hideChildCases });

  return (
    <Table
      className="nestedTable"
      tableLayout="fixed"
      columns={columns}
      dataSource={list}
      locale={{ emptyText: 'No Data' }}
      pagination={false}
      loading={{
        spinning: isLoading,
        indicator: <LoadingOutlined style={{ fontSize: 30 }} />
      }}
    />
  );
};

PetitionerDashboardNestedTable.propTypes = {
  entryPetitionerCases: PropTypes.shape({
    list: PropTypes.array,
    isLoading: PropTypes.bool
  })
};

PetitionerDashboardNestedTable.defaultProps = {
  entryPetitionerCases: {}
};

export default PetitionerDashboardNestedTable;
