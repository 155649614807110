import { Link } from 'react-router-dom';
import moment from 'moment';

const clientPageChildCasesColumns = ({ userID, role }) => {
  return [
    {
      title: 'Section name',
      dataIndex: 'section_name',
      key: 'section_name',
      ellipsis: true,
      width: '20%',
      render: (section_name, { caseID, subsection_name }) =>
        role !== 'customer' ? (
          <Link
            className="table-items_link"
            to={`/client-management/userID=${userID}/${caseID}/${section_name.replaceAll(
              ' ',
              '-'
            )}`}
          >
            {section_name}
          </Link>
        ) : (
          <Link
            className="table-items_link"
            to={`/your-questionnaires/${caseID}/${section_name.replaceAll(
              ' ',
              '-'
            )}/${subsection_name.replaceAll(' ', '-')}`}
          >
            {section_name}
          </Link>
        )
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      ellipsis: true,
      width: '20%',
      render: owner => <span className="table-items">{owner.full_name}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '20%',
      render: status => (
        <div className={`${status.replaceAll(' ', '-')} section-status`}>
          <span className="table-items">{status}</span>
        </div>
      )
    },
    {
      title: 'Last update',
      dataIndex: 'last_updated_at',
      key: 'last_updated_at',
      ellipsis: true,
      width: '15%',
      render: last_updated_at => (
        <span className="last-update">
          {moment(last_updated_at).format('MM.DD.YYYY')}
          <span className="hours">{moment(last_updated_at).format('hh:mm A')}</span>
        </span>
      )
    }
  ];
};

export default clientPageChildCasesColumns;
