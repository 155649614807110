import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import CompanyCasesTable from './CompanyCases';

import { Col, Collapse } from 'antd';

const { Panel } = Collapse;

const CompanySection = ({
  companiesList,
  companyCasesList,
  curActiveCompany,
  getCurrentCompany,
  companyCasesListLoading,
  role,
  onRowClick
}) => {
  const scrollRef = useRef();

  useEffect(() => {
    !companyCasesListLoading &&
      !!scrollRef.current &&
      curActiveCompany[0] === scrollRef.current.id &&
      scrollRef.current.offsetParent &&
      scrollRef.current.offsetParent.scrollIntoView({ behavior: 'smooth' });
  }, [curActiveCompany, getCurrentCompany, companyCasesListLoading]);

  return (
    <Col className="section-collapse">
      <Collapse defaultActiveKey={['1']} className="collapse-block">
        <Panel key="1" header={<h2 className="case-dashboard-title">Companies</h2>}>
          <Collapse
            activeKey={curActiveCompany}
            onChange={getCurrentCompany}
            className="collapse-block-nested"
          >
            {companiesList.map(({ id = null, name = '' }) => {
              return (
                <Panel
                  key={id}
                  header={
                    <div
                      className="case-dashboard-petitioner"
                      ref={curActiveCompany[0] === id ? scrollRef : null}
                    >
                      <h3>
                        {role !== 'customer' ? (
                          <Link className="petitioner" to={`/petitioner-dashboard/${id}`}>
                            {name}
                          </Link>
                        ) : (
                          name
                        )}
                      </h3>
                    </div>
                  }
                >
                  <CompanyCasesTable
                    loading={companyCasesListLoading}
                    data={companyCasesList.map((cases, index) => ({
                      ...cases,
                      key: index
                    }))}
                    role={role}
                    onRowClick={onRowClick}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </Panel>
      </Collapse>
    </Col>
  );
};

CompanySection.propTypes = {
  companiesList: PropTypes.array,
  companyCasesList: PropTypes.array,
  curActiveCompany: PropTypes.array,
  getCurrentCompany: PropTypes.func,
  companyCasesListLoading: PropTypes.bool,
  role: PropTypes.string,
  onRowClick: PropTypes.func
};

CompanySection.defaultProps = {
  companiesList: [],
  companyCasesList: [],
  curActiveCompany: [],
  companyCasesListLoading: false,
  role: ''
};

export default CompanySection;
