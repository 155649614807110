import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

const EmailError = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate('/sign-in');
  };

  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content resetPass">
        <Row>
          <h4>Oops! Something went wrong.</h4>
        </Row>
        <Row>
          <Col className="subTitle" type="flex" justify="center" align="middle">
            <p style={{ width: '75%' }}>
              You’re seeing this either because the verification token is invalid, or you have
              already verified your email address.
            </p>
            <p>
              Please try again or{' '}
              <Button type="link" onClick={handleRoute}>
                sign in{' '}
              </Button>
              if you have already verified your account.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EmailError;
