import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecPetitionerPageAction, ImSpecSectionDashboardActions } from 'store/Actions';

import useQuery from 'hooks/useQuery';

import FloatLabelInput from 'components/shared/FloatLabelInput';
import { debounce } from 'utils/helper';

import FloatLabelSelect from 'components/shared/FloatLabelSelect';
import { Modal, Form, Button, Spin, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { useForm } = Form;

const InviteRepresentative = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { action } = useQuery();
  const { petitionerID } = useParams();

  const {
    searchedUsers: { isLoading, list: userOptions, hasData }
  } = useSelector(state => state.ImSpecSectionDashboard);

  const [form] = useForm();

  const usersRef = useRef(null);

  const visible = /(invite-new-person|choose-from-existing-clients)/.test(action);

  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [emailErr, setEmailErr] = useState(null);

  const onCancel = () => {
    form.resetFields();
    setDisabled(true);
    setEmailErr(null);
    navigate(-1);
  };

  const onFieldsChange = (cur, all) => {
    ((cur.length && cur[0].name[0] === 'email' && cur[0].touched) ||
      (cur.length && cur[0].name[0] === 'userID' && cur[0].touched)) &&
      emailErr &&
      setEmailErr(null);

    const fields = all.filter(x => x.name[0] !== 'is_test');

    setDisabled(fields.some(e => e.touched === false || e.errors.length > 0));
  };
  const onFinish = values => {
    if (action === 'choose-from-existing-clients')
      dispatch(
        ImSpecPetitionerPageAction.attachExistingClient(
          { id: petitionerID, ...values },
          { navigate },
          { form, setDisabled, setLoading },
          setEmailErr
        )
      );

    if (action === 'invite-new-person')
      dispatch(
        ImSpecPetitionerPageAction.attachNewPerson(
          { id: petitionerID, data: values },
          setEmailErr,
          { navigate },
          { form, setDisabled, setLoading }
        )
      );
  };

  const onSearchUsers = value => {
    dispatch(ImSpecSectionDashboardActions.searchUsersForInvite({ params: value }));
  };

  const onSelect = () => {
    if (usersRef && usersRef.current) {
      usersRef.current.blur();
    }
  };

  const NoMatchFound = () => <span>No matches found.</span>;

  return (
    <Modal
      forceRender
      title={
        action === 'invite-new-person'
          ? 'Invite New Person'
          : action === 'choose-from-existing-clients'
          ? 'Invite Existing Client'
          : null
      }
      closable={false}
      open={visible}
      className="invitationModal"
      width={414}
      footer={null}
      centered
    >
      <hr />
      {action === 'choose-from-existing-clients' ? (
        <p>Choose a client to invite as a Representative.</p>
      ) : action === 'invite-new-person' ? (
        <p>Fill out the fields to invite a Representative.</p>
      ) : null}
      <Form
        className="invite-owner"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
      >
        {action === 'invite-new-person' && (
          <Form.Item
            name="is_test"
            valuePropName="checked"
            className="invitationForm_testAccount"
            initialValue={false}
          >
            <Checkbox>This is a test account</Checkbox>
          </Form.Item>
        )}
        {action === 'invite-new-person' && (
          <>
            <Form.Item
              name="first_name"
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                },
                {
                  max: 50,
                  message: 'Please enter a value shorter than 50 characters.'
                },
                {
                  pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
                  message: 'This field cannot contain numbers and special characters.'
                }
              ]}
            >
              <FloatLabelInput label="First Name" />
            </Form.Item>
            <Form.Item
              name="last_name"
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                },
                {
                  max: 50,
                  message: 'Please enter a value shorter than 50 characters.'
                },
                {
                  pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
                  message: 'This field cannot contain numbers and special characters.'
                }
              ]}
            >
              <FloatLabelInput label="Last Name" />
            </Form.Item>
            <Form.Item
              name="email"
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                },
                () => ({
                  validator(_, value) {
                    if (
                      !!value &&
                      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        value
                      )
                    ) {
                      return Promise.reject(
                        new Error('You have entered an invalid e-mail address.')
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <FloatLabelInput label="Email Address" />
            </Form.Item>
            {!!emailErr && <span className="error">{emailErr}</span>}
          </>
        )}
        {action === 'choose-from-existing-clients' && (
          <>
            <Form.Item name="userID">
              <FloatLabelSelect
                inputRef={usersRef}
                virtual={false}
                popupClassName="dropdown-field-dropdown"
                showSearch
                label="Client"
                autoClearSearchValue={false}
                filterOption={false}
                onSelect={onSelect}
                onSearch={debounce(onSearchUsers, 500)}
                notFoundContent={
                  isLoading && hasData === null ? (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
                  ) : !isLoading && hasData === false ? (
                    <NoMatchFound />
                  ) : null
                }
                options={userOptions}
              />
            </Form.Item>
            {!!emailErr && <span className="error">{emailErr}</span>}
          </>
        )}
        <div className="formFooter">
          <Form.Item>
            <Button disabled={loading} key="back" onClick={onCancel}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              disabled={loading || disabled || !!emailErr}
              type={disabled || !!emailErr ? 'disabled' : 'primary'}
              htmlType="submit"
            >
              Invite
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default InviteRepresentative;
