import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Select } from 'antd';

import '../FloatLabelInput/floatLabelInput.scss';

const FloatLabelSelect = ({
  id,
  inputRef,
  label,
  value,
  onChange,
  className,
  disabled,
  onSelect,
  ...props
}) => {
  const [focus, setFocus] = useState(false);

  const labelClass = focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  useEffect(() => {
    focus && inputRef?.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus]);

  const onSelectFloating = (value, params) => {
    onSelect && onSelect(value, params);
    inputRef?.current?.blur();
  };

  return (
    <div
      className={`float-label ${focus ? 'focus' : ''}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        {...props}
        ref={inputRef}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onSelect={onSelectFloating}
        className={`floating-input ${className ?? null}`}
      />
      <label htmlFor={id} className={`${labelClass} ${disabled ? 'label-disabled' : ''}`}>
        {label}
      </label>
    </div>
  );
};

FloatLabelSelect.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  inputRef: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onSelect: PropTypes.func
};

FloatLabelSelect.defaultProps = {
  id: null,
  label: '',
  inputRef: React.createRef(),
  value: null,
  disabled: false,
  className: ''
};

export default FloatLabelSelect;
