import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

import { Button, List, Modal, Spin } from 'antd';

import { ImSpecClientPageActions } from 'store/Actions';
import { LoadingOutlined } from '@ant-design/icons';

const ResendInvitationSummaryModal = ({
  user,
  expandedRowKeys,
  resendItems: { cases, petitioners, sections, isLoading, allow_resend, account_activation, state }
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userID } = useParams();
  const { action, search } = useQuery();

  const [isLoadingResend, setIsLoadingResend] = useState(false);

  const clientManagement = useMatch('/client-management');

  const visible = useMemo(() => /(resend-invitation-summary)/.test(action), [action]);

  useEffect(() => {
    if (visible) {
      (user.id || userID) && dispatch(ImSpecClientPageActions.getResendItems(user.id || userID));
    } else {
      dispatch(
        ImSpecClientPageActions.setResendItems({
          allow_resend: false,
          sections: [],
          cases: [],
          petitioners: [],
          account_activation: '',
          isLoading: false
        })
      );
    }
  }, [dispatch, user.id, userID, visible]);

  const onResendInvitationSummary = setLoading => () => {
    dispatch(
      ImSpecClientPageActions.resendInvitationSummary(
        user.id || userID,
        navigate,
        setLoading,
        clientManagement,
        search,
        expandedRowKeys
      )
    );
  };

  const onCancel = () => navigate(-1);

  const renderItemCases = item => {
    const renderSections = item => {
      return (
        <List.Item>
          <div className="nested-list-item">
            <div className="circle" />
            <span className="section-names">{item}</span>
          </div>
        </List.Item>
      );
    };

    return (
      <List.Item>
        <div className="items">
          <div className="circle" />
          <span className="list-item">
            <b>{item.visa_name}</b> questionnaire
          </span>
        </div>
        <div className="nested-list">
          {!!item.sections.length && (
            <List
              split={false}
              itemLayout="vertical"
              className="resend-list"
              dataSource={item.sections}
              renderItem={renderSections}
            />
          )}
        </div>
      </List.Item>
    );
  };

  const renderItemSections = item => {
    return (
      <List.Item>
        <div className="items">
          <div className="circle" />
          <span className="list-item">
            <b>{item.section_name}</b> for{' '}
            <b>
              {item.beneficary_full_name}'s {item.visa_name}
            </b>
          </span>
        </div>
      </List.Item>
    );
  };

  const renderItemPetitioners = item => {
    return (
      <List.Item>
        <div className="items">
          <div className="circle" />
          <span className="list-item">
            Company questionnaire for <b>{item.petitioner_name}</b>
          </span>
        </div>
      </List.Item>
    );
  };

  const Footer = () => {
    return (
      <>
        {allow_resend ? <Button onClick={onCancel}>Cancel</Button> : null}
        <Button
          loading={isLoadingResend}
          disabled={isLoadingResend}
          onClick={allow_resend ? onResendInvitationSummary(setIsLoadingResend) : onCancel}
          type="primary"
        >
          {!isLoading && (allow_resend ? 'Resend' : 'Close')}
        </Button>
      </>
    );
  };

  return (
    <Modal
      closable={false}
      open={visible}
      className="invitationModal resend-summary"
      title={!isLoading && (allow_resend ? 'Resend Invitation Summary' : 'You’re all set!')}
      width={414}
      footer={!isLoading ? <Footer /> : null}
      centered
    >
      <hr />
      <Spin
        wrapperClassName="resend-items-loading"
        spinning={isLoading}
        indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
      >
        {allow_resend ? (
          <>
            {!isLoading && (
              <h2 className="client-name">
                {state === 'Canceled'
                  ? `Here's what's canceled for ${user?.first_name}`
                  : `Here's what's pending for ${user?.first_name}`}
              </h2>
            )}
            <div className="resend-lists">
              {account_activation && (
                <List
                  locale={{ emptyText: null }}
                  split={false}
                  itemLayout="vertical"
                  className="resend-list"
                  dataSource={[account_activation]}
                  renderItem={item => (
                    <List.Item>
                      <div className="items">
                        <div className="circle" />
                        <span className="list-item">{item}</span>
                      </div>
                    </List.Item>
                  )}
                />
              )}
              {!!cases.length && (
                <List
                  locale={{ emptyText: null }}
                  split={false}
                  itemLayout="vertical"
                  className="resend-list"
                  dataSource={cases}
                  renderItem={renderItemCases}
                />
              )}
              {!!sections.length && (
                <List
                  locale={{ emptyText: null }}
                  split={false}
                  itemLayout="vertical"
                  className="resend-list"
                  dataSource={sections}
                  renderItem={renderItemSections}
                />
              )}
              {!!petitioners.length && (
                <List
                  locale={{ emptyText: null }}
                  split={false}
                  itemLayout="vertical"
                  className="resend-list"
                  dataSource={petitioners}
                  renderItem={renderItemPetitioners}
                />
              )}
            </div>
            {!isLoading && (
              <p className="resend-description">The resent invitation will contain this summary.</p>
            )}
          </>
        ) : (
          <>
            {!isLoading && (
              <h2 className="client-name">There are no pending items to be resent.</h2>
            )}
          </>
        )}
      </Spin>
    </Modal>
  );
};

ResendInvitationSummaryModal.propTypes = {
  user: PropTypes.object,
  resendItems: PropTypes.shape({
    cases: PropTypes.array,
    petitioners: PropTypes.array,
    sections: PropTypes.array,
    isLoading: PropTypes.bool,
    allow_resend: PropTypes.bool,
    account_activation: PropTypes.string,
    state: PropTypes.string
  }),
  expandedRowKeys: PropTypes.array
};

ResendInvitationSummaryModal.defaultProps = {
  user: {},
  expandedRowKeys: [],
  resendItems: {},
  cases: [],
  petitioners: [],
  sections: [],
  isLoading: false,
  allow_resend: false,
  account_activation: '',
  state: ''
};

export default ResendInvitationSummaryModal;
