import { Link } from 'react-router-dom';
import { Result } from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';

const Unauthorized = () => {
  return (
    <Result
      status="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Link to="/">
          <ArrowLeftOutlined /> Back to your dashboard
        </Link>
      }
    />
  );
};

export default Unauthorized;
