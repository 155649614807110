import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import moment from 'moment/moment';
import PropTypes from 'prop-types';

import { Button, Avatar, Typography, Row, Col, Steps, Tooltip } from 'antd';
import Icon, { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { syncIconThread } from 'components/Icons/Icons';

import { HistoryLogCommentsThreadAction } from 'store/Actions';

import { getContent } from 'utils/helper';

const IndividualLog = ({ logs, onPreviewUpload, count, answer_id, logsActiveTab }) => {
  const dispatch = useDispatch();
  const { masterIntakeID, petitionerName, userID } = useParams();

  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const onClickShowMore = () => {
    if (showMore) {
      setShowMore(false);
    } else {
      if (masterIntakeID) {
        logsActiveTab === 'sync_updates'
          ? dispatch(
              HistoryLogCommentsThreadAction.getMasterIntakeSyncedNestedLog(
                answer_id,
                setLoading,
                setShowMore
              )
            )
          : dispatch(
              HistoryLogCommentsThreadAction.getMasterIntakeAllRegularNestedLogs(
                answer_id,
                setLoading,
                setShowMore
              )
            );
      } else {
        dispatch(
          HistoryLogCommentsThreadAction.getAllRegularNestedLogs(answer_id, setLoading, setShowMore)
        );
      }
    }
  };

  const getIcon = log => {
    if (log.trigger_by)
      return (
        <Tooltip
          placement="bottomLeft"
          overlayClassName="trigger_tooltip"
          getPopupContainer={trigger => trigger.parentNode}
          title={
            <>
              <p>
                Trigger action: <b>{log.trigger_action}</b>
              </p>
              <p>
                Triggered by: <b>{log.trigger_by}</b>
              </p>
              <p>
                Value provided by:{' '}
                <b>
                  {log.user.first_name} {log.user.last_name}
                </b>
              </p>
            </>
          }
        >
          <Icon className="sync-icon" component={() => syncIconThread} />
        </Tooltip>
      );

    return (
      <Avatar
        size={27}
        style={{ background: log.user.role.name === 'customer' ? '#63A0FF' : '#33B18A' }}
      >
        {(log.user?.first_name?.charAt(0) + log.user?.last_name?.charAt(0)).toUpperCase()}
      </Avatar>
    );
  };

  const itemChildren = useCallback(
    log => {
      const actualValue =
        (log.answer.field_type === 'Upload' ||
          log.answer.field_type === 'Multi Upload' ||
          log.answer.field_type === 'Checkbox') &&
        log?.actual_value
          ? JSON.parse(log?.actual_value)
          : log?.actual_value;

      let url;

      if (masterIntakeID && log.source_master_intake) {
        url = `/petitioner-dashboard/master-intake/${
          log.source_master_intake.id
        }/${petitionerName?.replaceAll(' ', '-')}`;
      }

      if (!masterIntakeID) {
        if (log.source_master_intake) {
          url = `/petitioner-dashboard/master-intake/${
            log.source_master_intake.id
          }/${log.source_master_intake.petitioner?.replaceAll(' ', '-')}`;
        }

        if (log.source_case) {
          url = `/client-management/userID=${userID}/${
            log.source_case?.case_id
          }/${log.source_case?.source_section?.replaceAll(' ', '-')}`;
        }
      }

      const title = log.trigger_by ? (
        <b>
          <Typography.Text className="log-sync">
            Sync Source:{' '}
            <Link to={url} state={{ answer_id: log.source_answer_id, action: 'history_log' }}>
              <Typography.Text className="log-sync-case-id">
                {log.source_master_intake
                  ? `${log.source_master_intake.visa_name} MI`
                  : log.source_case.case_id}
              </Typography.Text>
            </Link>
          </Typography.Text>
        </b>
      ) : (
        <b>
          {log.user.first_name} {log.user.last_name}
        </b>
      );

      return (
        <Col className="timeline-log-container">
          <Row justify="space-between" align="middle" className="timeline-log-container-header">
            <Typography.Text className="user-details">{title}</Typography.Text>
            <Typography.Text className="log-created-at">
              {moment(log.created_at).format('hh:mm A, MMMM DD, YYYY')}
            </Typography.Text>
          </Row>
          <div className="timeline-log-container-content">
            {getContent(log, actualValue, onPreviewUpload)}
          </div>
        </Col>
      );
    },
    [masterIntakeID, onPreviewUpload, petitionerName, userID]
  );

  const items = useMemo(() => {
    if (!logs.length) return logs;
    const firstLog = logs[0];

    return !showMore
      ? [
          {
            icon: getIcon(firstLog),
            description: itemChildren(firstLog)
          }
        ]
      : logs.map(log => {
          return {
            icon: getIcon(log),
            description: itemChildren(log)
          };
        });
  }, [itemChildren, logs, showMore]);

  return (
    <>
      <Steps direction="vertical" items={items} />
      {logs.length && count > 1 ? (
        <Button
          type="ghost"
          className="show-more"
          loading={loading}
          onClick={onClickShowMore}
          icon={
            showMore ? (
              <CaretUpOutlined style={{ color: '#63A0FF' }} />
            ) : (
              <CaretDownOutlined style={{ color: '#63A0FF' }} />
            )
          }
        >
          {showMore ? 'Show less' : 'Show more'}
        </Button>
      ) : null}
    </>
  );
};

IndividualLog.propTypes = {
  logs: PropTypes.array,
  onPreviewUpload: PropTypes.func,
  answer_id: PropTypes.number,
  count: PropTypes.number,
  logsActiveTab: PropTypes.string
};

IndividualLog.defaultProps = {
  logs: [],
  count: 0
};

export default IndividualLog;
