import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

const PassSuccess = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/sign-in');
  };

  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content resetPass">
        <Row>
          <h4>Your password has been successfully reset.</h4>
        </Row>
        <Row>
          <Button onClick={handleClick}>Sign in</Button>
        </Row>
      </Col>
    </Row>
  );
};

export default PassSuccess;
