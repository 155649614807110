import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ImSpecContentAction } from 'store/Actions';

import { browserName } from 'react-device-detect';

import { downloadOrView } from 'utils/helper';
import { stringifyNumber } from 'utils/constants';
import { Button } from 'antd';
import { FileTextFilled } from '@ant-design/icons';

const Answers = ({
  field: {
    id,
    name,
    type: { type },
    placeholder,
    is_required
  },
  answersState,
  question: {
    title,
    type: { type: questionType }
  }
}) => {
  const dispatch = useDispatch();
  const { masterIntakeID } = useParams();

  const [fieldAnswer, setFieldAnswer] = useState('');

  useEffect(() => {
    if (!!answersState && Object.keys(answersState).length && !!answersState[name]) {
      if (type === 'Upload' || type === 'Multi Upload') {
        answersState[name] && setFieldAnswer(JSON.parse(answersState[name]));
      } else {
        answersState[name] && setFieldAnswer(answersState[name]);
      }
    } else {
      setFieldAnswer(answersState[name]);
    }
  }, [answersState, name, type]);

  const onPreviewFile = id => {
    dispatch(ImSpecContentAction.onPreview(id, masterIntakeID));
  };

  return questionType === 'Form' ? (
    <div className="form-type-answers">
      {!!fieldAnswer && fieldAnswer.length ? (
        fieldAnswer.map((answer, i) => {
          const dependentName = answer.answers.reduce(
            (acc, cur, i, array) => acc + cur.field_value + `${array.length - 1 === i ? '' : ' '}`,
            ''
          );
          return (
            <div key={i} className="form-type-answers-dependents">
              <div className="form-type-answers-dependents-name">
                <span className="name">
                  {stringifyNumber(i + 1)} {title.slice(0, 9)}: {dependentName}
                </span>
                <span className="form-created">{title.slice(0, -1)} created</span>
              </div>
            </div>
          );
        })
      ) : is_required ? (
        <span className={`answer ${fieldAnswer && !fieldAnswer.length && 'missing'}`}>
          {fieldAnswer && fieldAnswer.length ? fieldAnswer : '*Required'}
        </span>
      ) : (
        <span
          className="answer"
          style={fieldAnswer && !fieldAnswer.length ? { color: '#B4B3B3' } : { color: 'initial' }}
        >
          {fieldAnswer && fieldAnswer.length ? fieldAnswer : '---'}
        </span>
      )}
    </div>
  ) : type === 'Upload' || type === 'Multi Upload' ? (
    <div className="field-section">
      {fieldAnswer && fieldAnswer.length ? (
        fieldAnswer.map((file, index) => (
          <Button
            type="ghost"
            onClick={() => onPreviewFile(file.id)}
            icon={<div className="uploaded-files-icon_container">{<FileTextFilled />}</div>}
            key={index}
            className="uploaded-files"
          >
            <div className="uploaded-files-file_options">
              <h4 className="uploaded-files-file_options-name">{file.original_name}</h4>
              <span className="uploaded-files-file_options-view_link">
                {downloadOrView(browserName, file.extension)}
              </span>
            </div>
          </Button>
        ))
      ) : is_required ? (
        <span className={`answer ${!fieldAnswer && 'missing'}`}>
          {fieldAnswer ? fieldAnswer : '*Required'}
        </span>
      ) : (
        <span className="answer" style={!fieldAnswer ? { color: '#B4B3B3' } : { color: 'initial' }}>
          {fieldAnswer ? fieldAnswer : '---'}
        </span>
      )}
    </div>
  ) : (
    <div key={id} className="field-section">
      <span className="field-section-title">{placeholder}</span>
      {type === 'Checkbox' ? (
        <div className="multi-select-review">
          {!!fieldAnswer ? (
            JSON.parse(fieldAnswer).map((e, index) => {
              return (
                <span key={index} className="answer">
                  <span className="dot" /> {e}
                </span>
              );
            })
          ) : is_required ? (
            <span className={`answer ${!fieldAnswer && 'missing'}`}>
              {!fieldAnswer && '*Required'}
            </span>
          ) : (
            <span
              className="answer"
              style={!fieldAnswer ? { color: '#B4B3B3' } : { color: 'initial' }}
            >
              {!fieldAnswer && '---'}
            </span>
          )}
        </div>
      ) : (
        <div>
          {is_required ? (
            <span className={`answer ${!fieldAnswer && 'missing'}`}>
              {fieldAnswer ? fieldAnswer : '*Required'}
            </span>
          ) : (
            <span
              className="answer"
              style={!fieldAnswer ? { color: '#B4B3B3' } : { color: 'initial' }}
            >
              {fieldAnswer ? fieldAnswer : '---'}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

Answers.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.shape({
      type: PropTypes.string
    }),
    placeholder: PropTypes.string,
    is_required: PropTypes.bool
  }),
  answersState: PropTypes.object,
  question: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.shape({
      type: PropTypes.string
    })
  })
};

Answers.defaultProps = {
  field: {},
  answersState: {},
  question: {}
};

export default Answers;
