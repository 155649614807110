import { ImSpecClientManagementActions } from 'store/Actions';
import { CustomerActionTypes, ImSpecActionTypes } from 'store/types';
import { ImSpecClientPageService, ImSpecPetitionerPageService } from 'services';
import { Modal } from 'antd';

const getCompanies = userID => async dispatch => {
  try {
    dispatch(companyLoading(true));
    const res = await ImSpecClientPageService.getCompanies(userID);

    if (res.status < 200 || res.status >= 300) {
      throw res;
    }

    dispatch(setCompaniesList(res.data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(companyLoading(false));
  }
};

const getCompanyCases = petitioner_id => async dispatch => {
  try {
    dispatch(companyLoading(true));
    const res = await ImSpecClientPageService.getCompanyCases(petitioner_id);

    if (res.status < 200 || res.status >= 300) {
      throw res;
    }
    dispatch(setCompanyCasesList(res.data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(companyLoading(false));
  }
};

const getBeneficiaryTypes = userID => async dispatch => {
  try {
    dispatch(beneficiaryTypesLoading(true));
    const {
      data: { cases, user }
    } = await ImSpecClientPageService.getBeneficiary(userID);

    dispatch(setBeneficiaryList(cases));
    dispatch(setUserDetails(user));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(beneficiaryTypesLoading(false));
  }
};

const getAssociatedTypes = userID => async dispatch => {
  try {
    dispatch(associatedTypesLoading(true));
    const {
      data: { cases, user }
    } = await ImSpecClientPageService.getAssociated(userID);

    dispatch(setAssociatedList(cases));
    dispatch(setUserDetails(user));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(associatedTypesLoading(false));
  }
};

const getCaseList = (caseID, redirect) => async (dispatch, getState) => {
  try {
    dispatch(caseListLoading(true));
    const {
      ImSpecClientPage: {
        case: { list, child_cases }
      }
    } = getState();

    !!list.length && dispatch(setCaseList([]));
    !!child_cases.length && dispatch(setChildCases([]));

    const { data } = await ImSpecClientPageService.getCaseSections(caseID);

    dispatch(setCaseList(data.sections));
    dispatch(setChildCases(data.child_cases));
  } catch (e) {
    if (e.status === 403 && e.data.detail === 'You have not access to this case.') {
      redirect(0, { state: { type: 'case-invitation-error' }, replace: true });
    }
  } finally {
    dispatch(caseListLoading(false));
  }
};

const getAssociatedCaseList = (caseID, userID, redirect) => async (dispatch, getState) => {
  try {
    dispatch(caseListLoading(true));
    const {
      ImSpecClientPage: {
        case: { list, child_cases }
      }
    } = getState();

    !!list.length && dispatch(setCaseList([]));
    !!child_cases.length && dispatch(setChildCases([]));

    const { data } = await ImSpecClientPageService.getAssociatedSections(caseID, userID);

    dispatch(setCaseList(data.sections));
    dispatch(setChildCases(data.child_cases));
  } catch (e) {
    if (e.status === 403 && e.data.detail === 'You have not access to this case.') {
      redirect(0, { state: { type: 'case-invitation-error' }, replace: true });
    }
  } finally {
    dispatch(caseListLoading(false));
  }
};

const updateUserData = (userID, updatedUser, editName, setEmailErr) => async dispatch => {
  try {
    dispatch(setUserDetailsLoading(true));

    const res = await ImSpecClientPageService.updateUserData(userID, updatedUser);

    dispatch(setUserDetails(res.data.user));
    editName();

    Modal.info({
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal congrats',
      okButtonProps: {
        className: 'submit-section-confirm-modal-submit_btn',
        loading: false
      },
      okText: 'Close',
      content: (
        <>
          <span className="submit-section-confirm-modal-content">Success!</span>
          <hr />
          <span
            className="submit-section-confirm-modal-title"
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: res.data.message }}
          />
        </>
      )
    });
  } catch (e) {
    setEmailErr(...e.data.email);
    console.warn(e);
  } finally {
    dispatch(setUserDetailsLoading(false));
  }
};

const getResendItems = userID => async dispatch => {
  try {
    dispatch(setResendItemsLoading(true));
    const { data } = await ImSpecClientPageService.getResendItems(userID);

    dispatch(setResendItems(data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(setResendItemsLoading(false));
  }
};

const resendInvitationSummary =
  (userID, redirect, setLoading, clientManagement, search, expandedRowKeys) => async dispatch => {
    try {
      setLoading(true);
      const { data } = await ImSpecClientPageService.resendInvitationSummary(userID);
      redirect(-1);

      const refreshPage = async () => {
        if (clientManagement) {
          !search
            ? await dispatch(ImSpecClientManagementActions.getClientManagementTableData())
            : await dispatch(ImSpecClientManagementActions.searchClients({ params: search }));
          expandedRowKeys.length &&
            (await dispatch(
              ImSpecClientManagementActions.getClientManagementNestedTableData(expandedRowKeys[0])
            ));
        }
      };

      Modal.info({
        width: 438,
        centered: true,
        icon: null,
        className: 'submit-section-confirm-modal congrats',
        okButtonProps: {
          className: 'submit-section-confirm-modal-submit_btn',
          loading: false
        },
        okText: 'Close',
        content: (
          <>
            <span className="submit-section-confirm-modal-content">Success!</span>
            <hr className="success-hr" />
            <span
              className="submit-section-confirm-modal-title"
              dangerouslySetInnerHTML={{ __html: data.message }}
            />
          </>
        ),
        onOk: refreshPage
      });
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

const onSync = (masterIntakeID, petitionerName) => async (dispatch, getState) => {
  try {
    const {
      ImSpecClientPage: {
        companyCases: { companyCasesList }
      }
    } = getState();
    const { data } = await ImSpecPetitionerPageService.syncMasterIntake(
      masterIntakeID,
      petitionerName
    );

    const newList = companyCasesList.map(intake =>
      intake.id === masterIntakeID ? { ...intake, ...data } : { ...intake }
    );

    dispatch(setCompanyCasesList(newList));
  } catch (e) {
    const modalProps = {
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal congrats',
      okButtonProps: { className: 'submit-section-confirm-modal-submit_btn' },
      okText: 'Close',
      onOk: () => {}
    };

    const content = (
      <>
        <span className="submit-section-confirm-modal-content">Oops!</span>
        <span className="submit-section-confirm-modal-text">
          This action cannot be completed for now.
        </span>
        <span className="submit-section-confirm-modal-text">Please try again later!</span>
      </>
    );

    e.status === 403 && Modal.info({ ...modalProps, content: content });
  }
};

const setChildCases = cases => {
  return {
    type: ImSpecActionTypes.SET_CLIENT_PAGE_CHILD_CASES,
    payload: [...cases]
  };
};

const setResendItemsLoading = loading => {
  return {
    type: ImSpecActionTypes.RESEND_ITEMS_LOADING,
    payload: loading
  };
};

const setResendItems = items => {
  return {
    type: ImSpecActionTypes.SET_RESEND_ITEMS,
    payload: items
  };
};

const beneficiaryTypesLoading = loading => {
  return {
    type: ImSpecActionTypes.BENEFICIARY_TYPES_LOADING,
    payload: loading
  };
};

const setBeneficiaryList = beneficiaryList => {
  return {
    type: ImSpecActionTypes.SET_BENEFICIARY_TYPES_LIST,
    payload: [...beneficiaryList]
  };
};

const associatedTypesLoading = loading => {
  return {
    type: ImSpecActionTypes.ASSOCIATED_TYPES_LOADING,
    payload: loading
  };
};

const setAssociatedList = associatedList => {
  return {
    type: ImSpecActionTypes.SET_ASSOCIATED_TYPES_LIST,
    payload: [...associatedList]
  };
};

const caseListLoading = loading => {
  return {
    type: ImSpecActionTypes.CLIENT_CASE_LIST_LOADING,
    payload: loading
  };
};

const setCaseList = list => {
  return {
    type: ImSpecActionTypes.SET_CLIENT_CASE_LIST,
    payload: [...list]
  };
};

const setUserDetails = user => {
  return {
    type: ImSpecActionTypes.CLIENT_USER_DETAILS,
    payload: { ...user }
  };
};

const setUserDetailsLoading = loading => {
  return {
    type: ImSpecActionTypes.CLIENT_USER_DETAILS_LOADING,
    payload: loading
  };
};

const companyLoading = loading => {
  return {
    type: CustomerActionTypes.COMPANY_CASE_LIST_LOADING,
    payload: loading
  };
};

const setCompaniesList = companiesList => {
  return {
    type: CustomerActionTypes.COMPANIES_LIST,
    payload: [...companiesList]
  };
};

const setCompanyCasesList = companyCasesList => {
  return {
    type: CustomerActionTypes.SET_COMPANY_CASE_LIST,
    payload: [...companyCasesList]
  };
};

export const ImSpecClientPageActions = {
  getCompanies,
  getCompanyCases,
  getCaseList,
  getBeneficiaryTypes,
  getAssociatedTypes,
  getAssociatedCaseList,
  updateUserData,
  getResendItems,
  setResendItems,
  setUserDetails,
  resendInvitationSummary,
  onSync
};
