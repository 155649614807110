import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ImSpecClientPageActions } from 'store/Actions';

import sectionsTableColumns from './columns';

import { Table, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const CompanyCasesTable = ({ data, loading, role, onRowClick }) => {
  const dispatch = useDispatch();

  const syncMasterIntake = (masterIntakeID, petitionerName) => {
    dispatch(ImSpecClientPageActions.onSync(masterIntakeID, petitionerName));
  };

  const columns = sectionsTableColumns({ role, syncMasterIntake });

  const tooltipRow = ({ className, ...props }) => {
    const classNames = className.split(' ');

    if (classNames.includes('sync-in-progress') || classNames.includes('sync-in-progress-M2M')) {
      const title = classNames.includes('sync-in-progress') ? (
        <span>
          Syncing in progress. <br /> Please wait!
        </span>
      ) : classNames.includes('sync-in-progress-M2M') ? (
        <span>Syncing between company intakes is in progress!</span>
      ) : null;

      return (
        <Tooltip
          title={title}
          overlayClassName="no-access-tooltip"
          getPopupContainer={triggerNode => triggerNode.parentNode.parentNode.parentNode}
        >
          <tr className={className} {...props} />
        </Tooltip>
      );
    }
    return <tr className={className} {...props} />;
  };

  const rowClassName = record => {
    if (
      record.sync_in_progress &&
      !record.synced_with_master_intake &&
      record.last_sync_type === 'section'
    ) {
      return 'case_sections-list-row sync-in-progress';
    }

    if (
      record.sync_in_progress &&
      record.synced_with_master_intake &&
      record.last_sync_type === 'master_intake'
    ) {
      return 'case_sections-list-row sync-in-progress-M2M';
    }
    return 'case_sections-list-row';
  };

  return (
    <Table
      className={`master-intake case_sections-list client ${
        role !== 'customer' ? 'master-intakes-is' : ''
      }`}
      tableLayout="fixed"
      loading={{
        spinning: loading,
        indicator: <LoadingOutlined style={{ fontSize: 30 }} />
      }}
      columns={columns}
      rowClassName={rowClassName}
      dataSource={data}
      pagination={false}
      components={{
        body: { row: tooltipRow }
      }}
      onRow={record => {
        return {
          onClick: () => role === 'customer' && !record.sync_in_progress && onRowClick(record)
        };
      }}
    />
  );
};

CompanyCasesTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  role: PropTypes.string,
  onRowClick: PropTypes.func,
  className: PropTypes.string
};

CompanyCasesTable.defaultProps = {
  data: [],
  loading: false,
  role: ''
};

export default CompanyCasesTable;
