import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import useQuery from 'hooks/useQuery';

import { Grouped } from './Grouped';
import { Ungrouped } from './Ungrouped';

import { Col } from 'antd';

const Subsection = ({ curSubsection }) => {
  const myRef = useRef(null);
  const { subsection, group } = useQuery();

  const {
    ImSpecContent: { answersLoading }
  } = useSelector(state => ({
    ImSpecSideBar: state.ImSpecSideBar,
    ImSpecContent: state.ImSpecContent
  }));

  useEffect(() => {
    if (
      myRef &&
      myRef.current &&
      !group &&
      subsection &&
      subsection === curSubsection.title.replaceAll(' ', '-')
    ) {
      !answersLoading && myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [answersLoading, curSubsection.title, group, myRef, subsection]);

  return (
    <Col ref={myRef} className="mainContentBlock">
      <h2 className="subSectionName">{curSubsection?.title}</h2>
      {curSubsection?.type === 'grouped' ? (
        curSubsection?.group_set?.map((elem, index) => {
          return <Grouped key={index} curContent={curSubsection} curGroupContent={elem} />;
        })
      ) : (
        <Ungrouped curContent={curSubsection} />
      )}
    </Col>
  );
};

Subsection.propTypes = {
  curSubsection: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    group_set: PropTypes.array
  })
};

Subsection.defaultProps = {
  curSubsection: {},
  title: '',
  type: '',
  group_set: []
};

export default Subsection;
