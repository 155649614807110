import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import AutocompleteCheckboxTags from 'components/shared/AutocompleteCheckboxTags';
import CheckboxList from 'components/shared/CheckboxList';

import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const IA = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const attorney_options = Form.useWatch('attorney_options', form);
  const attorney_options_all = Form.useWatch('attorney_options_all', form);
  const selectedList = Form.useWatch('attorney', form);

  const [searchValue, setSearchValue] = useState('');
  const [isVisibleSearch, setIsVisibleSearch] = useState(false);

  const {
    filters: {
      immigrationAttorney: {
        optionList,
        immigrationAttorneysData: { list }
      }
    },
    filterAnswers: {
      attorney: { options, attorney_ids }
    }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    if (!options) {
      form.resetFields();
      setSearchValue('');
    }
  }, [form, options, attorney_ids]);

  useEffect(() => {
    if (options?.includes('assigned_to_others') && !attorney_options_all) {
      !!attorney_ids?.length && setIsVisibleSearch(true);
    } else {
      form.resetFields();
      setSearchValue('');
      setIsVisibleSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attorney_ids, options]);

  useEffect(() => {
    if (typeof attorney_options !== 'undefined') {
      dispatch(
        ImSpecCaseDashboardBetaAction.setSelectedAttorney({
          options: attorney_options,
          attorney_ids: selectedList
        })
      );
    }
  }, [dispatch, attorney_options, selectedList]);

  return (
    <div className="petitionerBlock">
      <CheckboxList
        form={form}
        plainOptions={optionList}
        filterOption="attorney_options"
        checkedValues={options}
      />
      {!isVisibleSearch && (
        <Button
          onClick={() => setIsVisibleSearch(true)}
          className="adderBtn"
          type="ghost"
          icon={<PlusOutlined />}
          disabled={attorney_options_all || !attorney_options?.includes('assigned_to_others')}
        >
          Add IA
        </Button>
      )}
      {isVisibleSearch &&
        !attorney_options_all &&
        attorney_options?.includes('assigned_to_others') && (
          <AutocompleteCheckboxTags
            name="IA"
            form={form}
            options={list}
            filterOption="attorney"
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            checkedValues={attorney_ids}
          />
        )}
    </div>
  );
};

export default IA;
