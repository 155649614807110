import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { AuthActions } from 'store/Actions';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { isLoading } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(AuthActions.emailVerification(id, navigate));
  }, [dispatch, navigate, id]);

  return (
    <div className="authSpinner">
      {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />}
    </div>
  );
};

export default Auth;
