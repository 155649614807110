import { ImSpecActionTypes } from 'store/types';

const initialState = {
  company: {
    companiesList: [],
    isLoading: false
  },
  companyCases: {
    companyCasesList: [],
    isLoading: false
  },
  userDetail: {
    userDetails: {},
    isLoading: false
  },
  resendItems: {
    allow_resend: false,
    sections: [],
    cases: [],
    petitioners: [],
    account_activation: '',
    isLoading: false
  },
  beneficiaryCases: {
    beneficiaryList: [],
    isLoading: false
  },
  associatedCases: {
    associatedList: [],
    isLoading: false
  },
  case: {
    list: [],
    child_cases: [],
    isLoading: false
  }
};

const ImSpecClientPageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ImSpecActionTypes.COMPANIES_LIST:
      return {
        ...state,
        company: {
          ...state.company,
          companiesList: [...payload]
        }
      };
    case ImSpecActionTypes.SET_COMPANY_CASE_LIST:
      return {
        ...state,
        companyCases: {
          ...state.companyCases,
          companyCasesList: [...payload]
        }
      };
    case ImSpecActionTypes.COMPANY_CASE_LIST_LOADING:
      return {
        ...state,
        companyCases: {
          ...state.companyCases,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.BENEFICIARY_TYPES_LOADING:
      return {
        ...state,
        beneficiaryCases: {
          ...state.beneficiaryCases,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_BENEFICIARY_TYPES_LIST:
      return {
        ...state,
        beneficiaryCases: {
          ...state.beneficiaryCases,
          beneficiaryList: [...payload]
        }
      };
    case ImSpecActionTypes.ASSOCIATED_TYPES_LOADING:
      return {
        ...state,
        associatedCases: {
          ...state.associatedCases,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_ASSOCIATED_TYPES_LIST:
      return {
        ...state,
        associatedCases: {
          ...state.associatedCases,
          associatedList: [...payload]
        }
      };

    case ImSpecActionTypes.CLIENT_CASE_LIST_LOADING:
      return {
        ...state,
        case: {
          ...state.case,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_CLIENT_CASE_LIST:
      return {
        ...state,
        case: {
          ...state.case,
          list: [...payload]
        }
      };
    case ImSpecActionTypes.CLIENT_USER_DETAILS:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          userDetails: { ...payload }
        }
      };
    case ImSpecActionTypes.CLIENT_USER_DETAILS_LOADING:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_RESEND_ITEMS:
      return {
        ...state,
        resendItems: {
          ...state.resendItems,
          ...payload
        }
      };
    case ImSpecActionTypes.RESEND_ITEMS_LOADING:
      return {
        ...state,
        resendItems: {
          ...state.resendItems,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_CLIENT_PAGE_CHILD_CASES:
      return {
        ...state,
        case: {
          ...state.case,
          child_cases: [...payload]
        }
      };
    default:
      return state;
  }
};

export default ImSpecClientPageReducer;
