import PropTypes from 'prop-types';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useQuery from 'hooks/useQuery';
import useClosePopoverWhenScrolling from 'hooks/useClosePopoverWhenScrolling';
import {
  OpenEnded,
  SingleSelectNested,
  SingleSelectSimple,
  MultiSelect,
  Iterative,
  FormQuestionType
} from './Options';

import { Badge, Button, Popover, Typography } from 'antd';
import {
  BulbOutlined,
  CheckCircleFilled,
  CloseOutlined,
  CommentOutlined,
  InfoCircleFilled,
  InfoCircleOutlined
} from '@ant-design/icons';
import { lightBulb } from 'components/Icons/Icons';
import 'react-datepicker/dist/react-datepicker.css';

const Questions = ({
  setIsTyping,
  questionSet,
  iterationName,
  curQuestionContent,
  curGroup,
  scrollRef
}) => {
  const { answers, answersLoading } = useSelector(state => state.customerContent);

  const navigate = useNavigate();
  const location = useLocation();

  const { comment } = useQuery();

  const fieldRef = useRef(null);
  const seeCommentsRef = useRef(null);
  const [answersDataState, setAnswersDataState] = useState({});
  const [uploadError, setUploadError] = useState([]);
  const [visiblePopover, setVisiblePopover] = useState(false);

  const onScroll = () => {
    visiblePopover && setVisiblePopover(false);
  };

  useClosePopoverWhenScrolling(scrollRef, onScroll);

  useLayoutEffect(() => {
    if (
      fieldRef &&
      fieldRef.current &&
      answersDataState &&
      Object.keys(answersDataState).length &&
      (location.state?.answer_id || comment)
    ) {
      if (!answersLoading && (+fieldRef.current.id === location.state?.answer_id || +fieldRef.current.id === +comment)) {
        fieldRef.current.scrollIntoView({ behavior: 'smooth' });
        const curAnswer = Object.values(answersDataState).find(
          answer => answer.id === location.state?.answer_id || answer.id === +comment
        );

        if (location.state?.action === 'comment') {
          if (seeCommentsRef && seeCommentsRef.current) {
            setTimeout(() => {
              curAnswer && seeCommentsRef.current.click();
            }, 1000);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersLoading, answersDataState, location.state]);

  useEffect(() => {
    !!iterationName
      ? !questionSet.startsWith('uId') &&
        setAnswersDataState(answers[iterationName]?.field_value[questionSet])
      : setAnswersDataState(answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionSet, answers]);

  const openCommentsModal = useCallback(
    curAnswer => {
      location.state?.answer_id && navigate(location.pathname, { replace: true });
      navigate(`${location.pathname}?comment=${curAnswer.id}`);
    },
    [location.pathname, location.state, navigate]
  );

  const commentsFlag = useCallback(
    (elem, seeCommentsRef) => {
      return (
        answersDataState &&
        answersDataState[elem.name] && (
          <Button
            ref={seeCommentsRef}
            type="ghost"
            className={`commentIconBlock ${elem.type.type !== 'Radio Button' ? 'upload' : ''}`}
            onClick={() => openCommentsModal(answersDataState[elem.name])}
            icon={
              <div className="commentBlock">
                <Badge
                  size="small"
                  color="#E54F4F"
                  count={
                    answersDataState[elem.name]?.comments_count &&
                    answersDataState[elem.name]?.unresolved_comments_count === 0 ? (
                      <CheckCircleFilled style={{ color: '#33B18A' }} />
                    ) : (
                      answersDataState[elem.name]?.unresolved_comments_count
                    )
                  }
                >
                  <CommentOutlined style={{ fontSize: '16px', color: '#63A0FF' }} />
                </Badge>
              </div>
            }
          />
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [answersDataState, openCommentsModal]
  );

  const handleVisiblePopoverChange = visiblePopover => {
    setVisiblePopover(visiblePopover);
  };

  const subSectionDesc = (
    <>
      <div className="subSectionDescBody">
        <div className="subSectionDescBody_content">
          <span>{lightBulb}</span>
          <p
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: curQuestionContent?.info }}
          />
        </div>
      </div>
      <div className="close-icon">
        <Button
          type="ghost"
          shape="circle"
          onClick={() => handleVisiblePopoverChange(false)}
          icon={<CloseOutlined />}
        />
      </div>
    </>
  );

  return (
    <div className="groupContent">
      {curQuestionContent?.type.type !== 'Iterative' && (
        <div className="question-header">
          <Typography.Title level={4} className="questionName">
            {curQuestionContent.title}
            {curQuestionContent?.info && (
              <Popover
                placement="right"
                overlayClassName="subSectionDesc"
                content={subSectionDesc}
                trigger="click"
                open={visiblePopover}
                onOpenChange={handleVisiblePopoverChange}
              >
                <InfoCircleFilled className="subSectionDesc_icon" />
              </Popover>
            )}
          </Typography.Title>
        </div>
      )}
      {curQuestionContent.explanation && (
        <div className="hintNameBlock">
          <InfoCircleOutlined />
          <h5
            className="hintName"
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: curQuestionContent.explanation }}
          />
        </div>
      )}
      {curQuestionContent.hint && (
        <div className="hintNameBlock">
          <BulbOutlined />
          <h5
            className="hintName"
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: curQuestionContent.hint }}
          />
        </div>
      )}
      {curQuestionContent.explanation_doc_link && (
        <a
          className="sample-link"
          target="_blank"
          href={curQuestionContent.explanation_doc_link}
          rel="noreferrer"
        >
          View Sample Document
        </a>
      )}
      {curQuestionContent?.field_set &&
        curQuestionContent?.field_set.map((elem, index) => {
          if (curQuestionContent?.type.type === 'Open Ended' && elem.type.type !== 'Secret')
            return (
              <OpenEnded
                curGroup={curGroup}
                commentsFlag={commentsFlag}
                key={elem.id}
                curField={elem}
                questionSet={questionSet}
                iterationName={iterationName}
                curQuestionContent={curQuestionContent}
                uploadError={uploadError}
                setUploadError={setUploadError}
                setIsTyping={setIsTyping}
              />
            );
          if (curQuestionContent.type.type === 'Form')
            return (
              <div key={elem.id} ref={fieldRef} id={answersDataState?.[elem.name]?.id}>
                <FormQuestionType
                  curGroup={curGroup}
                  curQuestion={curQuestionContent}
                  curField={elem}
                  questionSet={questionSet}
                  iterationName={iterationName}
                />
              </div>
            );
          if (curQuestionContent?.type.type === 'Single Select')
            return curQuestionContent?.has_children ? (
              <SingleSelectNested
                curGroup={curGroup}
                key={elem.id}
                curField={elem}
                nestedQuestion={curQuestionContent?.children}
                commentsFlag={commentsFlag}
                questionSet={questionSet}
                iterationName={iterationName}
              />
            ) : (
              <div key={index} className="curField">
                {commentsFlag(elem, seeCommentsRef)}
                <div key={elem.id} ref={fieldRef} id={answersDataState?.[elem.name]?.id}>
                  <SingleSelectSimple
                    curField={elem}
                    questionSet={questionSet}
                    iterationName={iterationName}
                  />
                </div>
              </div>
            );
          if (curQuestionContent?.type.type === 'Multi Select')
            return (
              <div key={index} className="curField">
                {commentsFlag(elem, seeCommentsRef)}
                <div key={elem.id} ref={fieldRef} id={answersDataState?.[elem.name]?.id}>
                  <MultiSelect
                    curField={elem}
                    questionSet={questionSet}
                    iterationName={iterationName}
                  />
                </div>
              </div>
            );
          if (curQuestionContent?.type.type === 'Iterative')
            return (
              <Iterative
                curGroup={curGroup}
                key={elem.id}
                curQuestionContent={curQuestionContent}
                iterationCount={curQuestionContent?.iteration_count}
                uploadError={uploadError}
                setUploadError={setUploadError}
                curField={elem}
                nestedQuestion={curQuestionContent?.children}
              />
            );
        })}
    </div>
  );
};

Questions.propTypes = {
  setIsTyping: PropTypes.func,
  questionSet: PropTypes.string,
  iterationName: PropTypes.string,
  curGroup: PropTypes.object,
  curQuestionContent: PropTypes.object,
  scrollRef: PropTypes.object
};

Questions.defaultProps = {
  questionSet: '',
  iterationName: '',
  curGroup: {},
  curQuestionContent: {},
  scrollRef: {}
};
export default Questions;
