import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import Contacts from '../Contacts';
import CaseTable from '../CaseTable';
import ChildCases from 'pages/ImSpecPages/SectionDashboard/elements/ChildCases';

import { Col, Collapse, Tooltip } from 'antd';
import { questionMarkIcon } from 'components/Icons/Icons';

const { Panel } = Collapse;

const AssociatedSection = ({
  userDetails: { first_name, id: userID },
  contacts,
  curActiveCase,
  getCurrentCase,
  associatedList,
  casesLoading,
  list,
  role,
  child_cases
}) => {
  const scrollRef = useRef();
  const hideChildCases = useFlag('hide-child-cases');
  const { flagsReady } = useFlagsStatus();

  useEffect(() => {
    !!scrollRef.current &&
      curActiveCase[0] === scrollRef.current.id &&
      scrollRef.current.offsetParent &&
      scrollRef.current.offsetParent.scrollIntoView({ behavior: 'smooth' });
  }, [curActiveCase, getCurrentCase]);

  const viewClientProp = e => {
    e.stopPropagation();
  };

  const childCases = useMemo(() => {
    return flagsReady && !hideChildCases && child_cases ? (
      <ChildCases
        child_cases={child_cases}
        loading={casesLoading}
        userID={userID}
        page="client-page"
        role={role}
      />
    ) : null;
  }, [casesLoading, child_cases, flagsReady, hideChildCases, role, userID]);

  return (
    <Col className="section-collapse">
      <Collapse defaultActiveKey={['1']} className="collapse-block">
        <Panel
          key="1"
          header={
            <h2 className="case-dashboard-title">
              Questionnaires Assigned To {role !== 'customer' ? `${first_name}` : 'You'}
              {role === 'customer' && (
                <Tooltip
                  placement="bottom"
                  overlayClassName="help-text"
                  title="Please complete these questionnaires on behalf of your colleague's immigration case."
                >
                  {questionMarkIcon}
                </Tooltip>
              )}
            </h2>
          }
        >
          <Collapse
            activeKey={curActiveCase}
            onChange={key => getCurrentCase(key, 'associated')}
            className="collapse-block-nested"
          >
            {associatedList.map(elem => {
              return (
                <Panel
                  key={elem.case_id}
                  header={
                    <div
                      id={elem.case_id}
                      ref={curActiveCase[0] === elem.case_id ? scrollRef : null}
                      className="case-dashboard-petitioner"
                    >
                      <h3>
                        {role !== 'customer' && (
                          <Link
                            className="case"
                            onClick={e => viewClientProp(e)}
                            to={`/client-management/userID=${elem.user_id}/${elem.case_id}`}
                          >
                            {elem.case_id}
                          </Link>
                        )}
                        {role !== 'customer' ? (
                          typeof elem.petitioner === 'string' ? (
                            <span>{elem.petitioner}</span>
                          ) : (
                            <Link
                              className="petitioner"
                              onClick={e => viewClientProp(e)}
                              to={`/petitioner-dashboard/${elem.petitioner?.id}`}
                            >
                              {elem.petitioner?.name}
                            </Link>
                          )
                        ) : (
                          elem.petitioner?.name
                        )}
                        {role === 'customer' ? (
                          <span className="beneficiary-customer">{elem.user_details}</span>
                        ) : (
                          <Link
                            className="beneficiary"
                            onClick={e => viewClientProp(e)}
                            to={`/client-management/userID=${elem.user_id}`}
                          >
                            {elem.user_details}
                          </Link>
                        )}
                        <span className="visa">
                          {elem.visa_name}{' '}
                          {elem.visa_name === 'I-539' && elem.is_dependent ? elem.dependent : null}
                        </span>
                      </h3>
                      <div>
                        <h3>{elem.state}</h3>
                        {/*<div className="actions">*/}
                        {/*  <div>*/}
                        {/*    {*/}
                        {/*        elem.resend_available &&*/}
                        {/*          <Tooltip placement="bottom" title="Resend account invitation">*/}
                        {/*            <Button>{sendIcon}</Button>*/}
                        {/*          </Tooltip>*/}
                        {/*    }*/}
                        {/*  </div>*/}
                        {/*  <div>*/}
                        {/*    {*/}
                        {/*        elem.cancel_available &&*/}
                        {/*          <Tooltip placement="bottom" title="Cancel account invitation">*/}
                        {/*            <Button>{outlineCancelIcon}</Button>*/}
                        {/*          </Tooltip>*/}
                        {/*    }*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  }
                >
                  {!!contacts && (contacts.im_spec || contacts.attorney) && (
                    <Contacts contacts={contacts} />
                  )}
                  <CaseTable
                    role={role}
                    state={elem.state}
                    caseID={elem.case_id}
                    loading={casesLoading}
                    data={list.map((section, index) => ({
                      ...section,
                      key: index
                    }))}
                  />
                  {childCases}
                </Panel>
              );
            })}
          </Collapse>
        </Panel>
      </Collapse>
    </Col>
  );
};

AssociatedSection.propTypes = {
  userDetails: PropTypes.shape({
    first_name: PropTypes.string,
    id: PropTypes.number
  }),
  contacts: PropTypes.object,
  getCurrentCase: PropTypes.func,
  associatedList: PropTypes.array,
  casesLoading: PropTypes.bool,
  list: PropTypes.array,
  role: PropTypes.string,
  child_cases: PropTypes.array,
  curActiveCase: PropTypes.array
};

AssociatedSection.defaultProps = {
  userDetails: {},
  contacts: {},
  first_name: '',
  userID: null,
  associatedList: [],
  casesLoading: false,
  list: [],
  role: '',
  child_cases: [],
  curActiveCase: []
};

export default AssociatedSection;
