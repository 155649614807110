import request from 'utils/request';

export const ImSpecClientPageService = {
  getCompanies: userID => {
    return request('GET', `api/petitioner/users/${userID}`);
  },
  getCompanyCases: petitioner_id => {
    return request('GET', `api/master-intake/petitioners/${petitioner_id}`);
  },
  getBeneficiary: userID => {
    return request('GET', `api/is/cases/users/${userID}/beneficiary`);
  },
  getAssociated: userID => {
    return request('GET', `api/is/cases/users/${userID}/associated`);
  },
  getResendItems: userID => {
    return request('GET', `user/user-items/${userID}`);
  },
  getCaseSections: caseID => {
    return request('GET', `api/is/case/${caseID}/sections/`);
  },
  getAssociatedSections: (caseID, userID) => {
    return request('GET', `api/is/case/${caseID}/sections/${userID}/associated`);
  },
  updateUserData: (userID, updatedUser) => {
    return request('PATCH', `/user/${userID}`, { ...updatedUser });
  },
  resendInvitationSummary: userID => {
    return request('POST', `/user/resend-general-invitation/${userID}/`);
  }
};
