import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'antd';

import { assignIcon } from '../../../../components/Icons/Icons';

import moment from 'moment/moment';

const columns = ({ flagsReady, hideChildCases, onClickAssign = () => {} }) => {
  return [
    {
      title: 'Case ID',
      dataIndex: 'case_id',
      key: 'case_id',
      ellipsis: true,
      width: '10%',
      render: (case_id, { user_id, is_migrated, dependent_cases_count, beneficiary }) => (
        <div>
          <div className="case_col">
            <Link className="case-id" to={`/client-management/userID=${user_id}/${case_id}`}>
              {case_id}
              {flagsReady && !hideChildCases && !!dependent_cases_count && (
                <Tooltip
                  overlayClassName="migrate-tooltip"
                  title={`${dependent_cases_count} Child Cases`}
                  placement="top"
                >
                  <div className="migrate">
                    <span>{dependent_cases_count}</span>
                  </div>
                </Tooltip>
              )}
            </Link>
            {is_migrated && (
              <Tooltip overlayClassName="migrate-tooltip" title="Migrated" placement="bottom">
                <div className="migrate">
                  <span>M</span>
                </div>
              </Tooltip>
            )}
          </div>
          {beneficiary.is_test && (
            <div className="test-account-tag">
              <div className="circle" />
              <span>Test case</span>
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Beneficiary',
      dataIndex: 'beneficiary',
      key: 'beneficiary',
      ellipsis: true,
      width: '13%',
      render: (beneficiary, { user_id, dependent }) => (
        <div className="beneficiaryWrapper">
          <Link className="beneficiary" to={`/client-management/userID=${user_id}`}>
            {beneficiary.first_name} {beneficiary.last_name}
          </Link>
          <span className="dependent">{dependent}</span>
        </div>
      )
    },
    {
      title: 'Petitioner',
      dataIndex: 'petitioner',
      key: 'petitioner',
      ellipsis: true,
      width: '13%',
      render: petitioner => {
        return typeof petitioner === 'string' ? (
          <span>{petitioner}</span>
        ) : (
          <Link className="petitioner" to={`/petitioner-dashboard/${petitioner?.id}`}>
            {petitioner?.name}
          </Link>
        );
      }
    },
    {
      title: 'Case',
      dataIndex: 'visa_name',
      key: 'visa_name',
      ellipsis: true,
      width: '10%',
      render: (visa_name, { is_dependent }) => (
        <div className={`visa_name v${visa_name.replaceAll(' ', '-')}`}>
          {visa_name}{' '}
          {visa_name === 'I-539' && is_dependent
            ? 'DEP'
            : visa_name === 'I-539' && !is_dependent
            ? 'BEN'
            : null}
        </div>
      )
    },
    {
      title: 'IS',
      dataIndex: 'im_spec',
      key: 'im_spec',
      ellipsis: true,
      width: '15%',
      render: (im_spec, { case_id }) => {
        const im_spec_value = !Object.keys(im_spec).length
          ? 'Assign'
          : `${im_spec.first_name} ${im_spec.last_name}`;
        return (
          <span className="im_spec">
            <Button
              type="ghost"
              className="assign-icon"
              onClick={() => onClickAssign({ im_spec, case_id })}
            >
              {im_spec_value}
              {im_spec_value === 'Assign' ? assignIcon : null}
            </Button>
          </span>
        );
      }
    },
    {
      title: 'Attorney',
      dataIndex: 'attorney',
      key: 'attorney',
      ellipsis: true,
      width: '15%',
      render: (attorney, { case_id }) => {
        const attorney_value = !Object.keys(attorney).length
          ? 'Assign'
          : `${attorney.first_name} ${attorney.last_name}`;
        return (
          <span className="im_spec">
            <Button
              type="ghost"
              className="assign-icon"
              onClick={() => onClickAssign({ attorney, case_id })}
            >
              {attorney_value}
              {attorney_value === 'Assign' ? assignIcon : null}
            </Button>
          </span>
        );
      }
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: '8%',
      ellipsis: true,
      render: state => <span className="state">{state}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '8%',
      ellipsis: true,
      render: status => (
        <div className={`section-status ${status.replaceAll(' ', '-')}`}>
          <span className="table-items">{status}</span>
        </div>
      )
    },
    {
      title: 'Last update',
      dataIndex: 'last_updated_at',
      key: 'last_updated_at',
      ellipsis: true,
      width: '11%',
      render: last_updated_at => (
        <span className="last-update">
          {moment(last_updated_at).format('MM.DD.YYYY')}
          <span className="hours">{moment(last_updated_at).format('hh:mm A')}</span>
        </span>
      )
    }
  ];
};

export default columns;
