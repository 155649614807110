import request from 'utils/request';

export const ImSpecSectionDashboardService = {
  getCaseSections: caseID => {
    return request('GET', `api/is/case/${caseID}/sections/`);
  },
  updateBeneficiaryAccess: (caseID, sectionName, hasAccess) => {
    return request('PATCH', `/api/is/case/${caseID}/section/${sectionName}/update`, hasAccess);
  },
  getSearchedUsers: data => {
    return request('POST', '/api/is/user-profiles/search', data);
  },
  getAssociatedAccounts: caseID => {
    return request('GET', `/api/is/case/associated-accounts/${caseID}`);
  },
  inviteExistingClient: data => {
    return request('PATCH', 'api/is/case/associated-accounts', data);
  },
  inviteNewPerson: data => {
    return request('POST', '/user/associated-invitation/', data);
  }
};
