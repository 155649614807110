import request from 'utils/request';

export const ImSpecSidebarService = {
  getCases: caseID => {
    return request('GET', `api/is/cases/${caseID}/info`);
  },
  getMasterIntakeCaseTypes: petitionerName => {
    return request('GET', `api/master-intake/petitioner/${petitionerName}/sidebar`);
  },
  getCaseTypes: userID => {
    return request('GET', `api/is/users/${userID}/sidebar_data/`);
  },
  getCaseDetails: case_id => {
    return request('GET', `api/is/case/${case_id}/case_info`);
  },
  getSelectedCaseTypeInfo: (caseID, userID) => {
    return request('GET', `api/is/case/${caseID}/users/${userID}/sidebar_data`);
  },
  getGoogleDriveLink: caseID => {
    return request('GET', `api/drive/case-link/${caseID}`);
  }
};
