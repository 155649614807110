import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerContentAction } from 'store/Actions';

import { stringifyNumber } from 'utils/constants';

import { Button, Modal } from 'antd';
import { addIterationIcon, detachRep } from 'components/Icons/Icons';

const Form = ({ curField, curQuestion, questionSet, iterationName }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { caseID, sectionTitle } = useParams();

  const { subsection, answers, answersLoading, sendAnswersLoading } = useSelector(
    state => state.customerContent
  );

  const [radioDisable, setRadioDisable] = useState(false);
  const [answersDataState, setAnswersDataState] = useState({});

  useEffect(() => {
    if (sendAnswersLoading) {
      setRadioDisable(sendAnswersLoading);
    } else {
      !sendAnswersLoading && setRadioDisable(answersLoading);
    }
  }, [sendAnswersLoading, answersLoading]);

  useEffect(() => {
    !!iterationName
      ? !questionSet.startsWith('uId') &&
        setAnswersDataState(answers[iterationName]?.field_value[questionSet])
      : setAnswersDataState(answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionSet, answers]);

  const onClickToCreateForm = () => {
    dispatch(
      CustomerContentAction.setDependentForm(
        curQuestion.form_questions,
        curField.name,
        iterationName,
        questionSet,
        navigate,
        location
      )
    );
  };

  const deleteDependent = case_id => {
    dispatch(
      CustomerContentAction.deleteDependent({ case_id }, caseID, curField.name, sectionTitle)
    );
  };

  const modalProps = {
    width: 438,
    centered: true,
    icon: null,
    title: 'Are you sure you want to remove this form?',
    className: 'submit-section-confirm-modal delete-dependent',
    cancelButtonProps: { className: 'submit-section-confirm-modal-cancel_btn' },
    okButtonProps: { className: 'submit-section-confirm-modal-submit_btn' },
    okText: 'Remove',
    cancelText: 'Cancel',
    confirmLoading: true,
    content: (
      <>
        <hr />
        <span className="submit-section-confirm-modal-text">
          Selecting <b>Remove</b> will delete any information previously provided for this
          dependent.
        </span>
      </>
    )
  };

  const onDeleteDependent = case_id =>
    Modal.confirm({ ...modalProps, onOk: () => deleteDependent(case_id) });

  return (
    <div className="form-type">
      <div className="form-type-answers">
        {answersDataState &&
          Object.keys(answersDataState) &&
          answersDataState[curField.name] &&
          answersDataState[curField.name].form_questions.map((answer, i) => {
            const dependentName = answer.answers.reduce(
              (acc, cur, i, array) =>
                acc + cur.field_value + `${array.length - 1 === i ? '' : ' '}`,
              ''
            );

            return (
              <div key={i} className="form-type-answers-dependents">
                <div className="form-type-answers-dependents-name">
                  <span className="name">
                    {stringifyNumber(i + 1)} {curQuestion.title.slice(0, 9)}: {dependentName}
                  </span>
                  <span className="form-created">{curQuestion.title.slice(0, -1)} created</span>
                </div>
                <Button
                  type="ghost"
                  className="delete-dependent"
                  icon={detachRep}
                  onClick={() => onDeleteDependent(answer.case_id)}
                  disabled={subsection?.sync_in_progress || subsection?.is_blocked || radioDisable}
                />
              </div>
            );
          })}
      </div>
      {answersDataState &&
        Object.keys(answersDataState) &&
        answersDataState[curField.name] &&
        answersDataState[curField.name].form_questions.length <
          curQuestion.form_questions[0].iteration_count && (
          <Button
            disabled={subsection?.sync_in_progress || subsection?.is_blocked || radioDisable}
            onClick={onClickToCreateForm}
            icon={addIterationIcon}
            className="form-type-btn"
          >
            Create Dependent Form
          </Button>
        )}
    </div>
  );
};

Form.propTypes = {
  curField: PropTypes.object,
  curQuestion: PropTypes.object,
  questionSet: PropTypes.string,
  iterationName: PropTypes.string
};

Form.defaultProps = {
  curField: {},
  curQuestion: {},
  questionSet: '',
  iterationName: ''
};

export default Form;
