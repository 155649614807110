import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import AutocompleteCheckboxTags from 'components/shared/AutocompleteCheckboxTags';
import CheckboxList from 'components/shared/CheckboxList';

import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const IS = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const im_spec_options = Form.useWatch('im_spec_options', form);
  const im_spec_options_all = Form.useWatch('im_spec_options_all', form);
  const selectedList = Form.useWatch('imSpec', form);

  const [searchValue, setSearchValue] = useState('');
  const [isVisibleSearch, setIsVisibleSearch] = useState(false);

  const {
    filters: {
      immigrationSpecialist: {
        optionList,
        immigrationSpecialistsData: { list }
      }
    },
    filterAnswers: {
      im_spec: { options, im_spec_ids }
    }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    if (!options) {
      form.resetFields();
      setSearchValue('');
    }
  }, [form, options, im_spec_ids]);

  useEffect(() => {
    if (options?.includes('assigned_to_others') && !im_spec_options_all) {
      !!im_spec_ids?.length && setIsVisibleSearch(true);
    } else {
      form.resetFields();
      setSearchValue('');
      setIsVisibleSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [im_spec_ids, options]);

  useEffect(() => {
    if (typeof im_spec_options !== 'undefined') {
      dispatch(
        ImSpecCaseDashboardBetaAction.setSelectedImSpec({
          options: im_spec_options,
          im_spec_ids: selectedList
        })
      );
    }
  }, [dispatch, im_spec_options, selectedList]);

  return (
    <div className="petitionerBlock">
      <CheckboxList
        form={form}
        plainOptions={optionList}
        filterOption="im_spec_options"
        checkedValues={options}
      />
      {!isVisibleSearch && (
        <Button
          onClick={() => setIsVisibleSearch(true)}
          className="adderBtn"
          type="ghost"
          icon={<PlusOutlined />}
          disabled={im_spec_options_all || !im_spec_options?.includes('assigned_to_others')}
        >
          Add IS
        </Button>
      )}
      {isVisibleSearch &&
        !im_spec_options_all &&
        im_spec_options?.includes('assigned_to_others') && (
          <AutocompleteCheckboxTags
            name="IS"
            form={form}
            options={list}
            filterOption="imSpec"
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            checkedValues={im_spec_ids}
          />
        )}
    </div>
  );
};

export default IS;
