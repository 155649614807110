import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import CheckboxList from 'components/shared/CheckboxList';

import { Button, Checkbox, Divider, Form, Input } from 'antd';
import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';

const Petitioner = ({ petitioner: { options, companies }, setShowTooltip }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState('');
  const selectedPetitionerList = Form.useWatch('petitioners', form);
  const selectedOptionList = Form.useWatch('petitioner_options', form);
  const selectedOptionListAll = Form.useWatch('petitioner_options_all', form);

  const [isVisibleSearch, setIsVisibleSearch] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const {
    filters: {
      petitioner: { optionList, list }
    },
    appliedSavedView: { id: savedViewId, name: savedViewName }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    setFilteredOptions(list);
  }, [list]);

  useEffect(() => {
    if (options?.includes('company_sponsored') && !selectedOptionListAll) {
      !!companies?.length && setIsVisibleSearch(true);
    } else {
      form.resetFields();
      setSearchValue('');
      setIsVisibleSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, companies]);

  const onQuickPetitionerChange = options => {
    if (options.length) {
      dispatch(
        ImSpecCaseDashboardBetaAction.setPetitioner(
          !options.length
            ? {}
            : {
                options: options,
                ...(selectedPetitionerList && selectedPetitionerList.length
                  ? { companies: selectedPetitionerList }
                  : {})
              }
        )
      );
    } else {
      dispatch(ImSpecCaseDashboardBetaAction.clearFilters('petitioner'));
    }
    dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
    dispatch(ImSpecCaseDashboardBetaAction.applyFilters(savedViewId, savedViewName));
  };

  useEffect(() => {
    if (!options) {
      form.resetFields();
      setSearchValue('');
    }
  }, [form, options]);

  const handleInputChange = e => {
    const inputValue = e.target.value;

    setSearchValue(inputValue);
    const filtered = list.filter(option =>
      option.name?.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleCheckboxChange = checked => {
    const checkedValue = checked.map(id => list.find(option => option.id === id));
    let uniqueArray;

    if (filteredOptions.length === list.length) {
      uniqueArray = [...checkedValue].filter(
        (obj, index, self) => index === self.findIndex(item => item.id === obj.id)
      );
    } else {
      const unFiltered = !companies
        ? []
        : companies.filter(value => !filteredOptions.find(item => item.id === value.id));
      uniqueArray = [...checkedValue, ...unFiltered].filter(
        (obj, index, self) => index === self.findIndex(item => item.id === obj.id)
      );
    }

    form.setFieldValue('petitioners', uniqueArray);
    dispatch(
      ImSpecCaseDashboardBetaAction.setPetitioner({
        options: selectedOptionList,
        ...(uniqueArray.length ? { companies: uniqueArray } : {})
      })
    );
    dispatch(ImSpecCaseDashboardBetaAction.applyFilters(savedViewId, savedViewName));
  };

  const handleClick = e => {
    e.stopPropagation();
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className="petitionerBlock" role="button" tabIndex={0} onClick={e => handleClick(e)}>
      <CheckboxList
        form={form}
        plainOptions={optionList}
        filterOption="petitioner_options"
        checkedValues={options}
        onQuickPetitionerChange={onQuickPetitionerChange}
      />
      {!isVisibleSearch && (
        <Button
          onClick={() => setIsVisibleSearch(true)}
          className="adderBtn"
          type="ghost"
          icon={<PlusOutlined />}
          disabled={selectedOptionListAll || !selectedOptionList?.includes('company_sponsored')}
        >
          Add petitioner
        </Button>
      )}
      {isVisibleSearch &&
        !selectedOptionListAll &&
        selectedOptionList?.includes('company_sponsored') && (
          <div className="caseTypeBlock">
            <Form form={form} className="search-form" initialValues={{ petitioners: companies }}>
              <Input
                addonBefore={<SearchOutlined />}
                allowClear={{
                  clearIcon: <CloseOutlined style={{ color: '#000', fontSize: '14px' }} />
                }}
                className="case-type-search"
                value={searchValue}
                onChange={handleInputChange}
                placeholder="Search for petitioner"
              />
              <Form.Item name="petitioners" getValueProps={() => {}}>
                <Checkbox.Group
                  onChange={handleCheckboxChange}
                  value={companies?.map(option => option.id)}
                  className="checkboxGroup"
                >
                  {filteredOptions.map(option => (
                    <Checkbox
                      style={{ padding: '8px 5px' }}
                      key={option.id}
                      value={option.id}
                      option={option}
                    >
                      {option.name}
                      <br />
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Form>
          </div>
        )}
      <Divider rootClassName="footer-divider" />
      <Button className="addedSubmitSection" type="ghost" onClick={() => setShowTooltip(false)}>
        Close
      </Button>
    </div>
  );
};

Petitioner.propTypes = {
  setShowTooltip: PropTypes.func,
  petitioner: PropTypes.shape({
    options: PropTypes.array,
    companies: PropTypes.array
  })
};

Petitioner.defaultProps = {
  petitioner: { options: [], companies: [] }
};

export default Petitioner;
