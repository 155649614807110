import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';
import moment from 'moment';

import DatePicker from 'components/shared/DatePicker';

import { Switch, Radio, Space, Divider, Form, Button } from 'antd';
const { RangePicker } = DatePicker;

const LastUpdate = ({ type, quick, dataFromStorage, setShowTooltip }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [switcher, setSwitcher] = useState(false);
  const [radioItem, setRadioItem] = useState('');

  const {
    appliedSavedView: { id: savedViewId, name: savedViewName }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  const data = quick
    ? dataFromStorage
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useSelector(state => state.ImSpecCaseDashboardBeta.filterAnswers[type]);

  useEffect(() => {
    if (!Object.keys(data).length) {
      setRadioItem('');
      setSwitcher(false);
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (!quick) {
      if (!Object.keys(data).length) {
        setRadioItem('');
        setSwitcher(false);
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, data]);

  useEffect(() => {
    if (Object.keys(data).length) {
      setRadioItem(Object.keys(data)[0]);
      if (Object.keys(data)[0] !== 'custom') {
        Object.values(data)[0] === 'within' ? setSwitcher(false) : setSwitcher(true);
      } else {
        form.setFieldValue('dateRange', [
          moment(data?.custom.start_date),
          moment(data?.custom.end_date)
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, dataFromStorage]);

  const onRadioChange = ({ target: { value } }) => {
    setRadioItem(value);
    if (value !== 'custom') {
      dispatch(
        ImSpecCaseDashboardBetaAction.setLastUpdate({
          [type]: {
            [value]: !switcher ? 'within' : 'before'
          }
        })
      );
      if (quick) {
        dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
        dispatch(ImSpecCaseDashboardBetaAction.applyFilters(savedViewId, savedViewName));
      }
    }
  };

  const onRangePicker = range_date => {
    form.setFieldsValue({
      dateRange: range_date
    });

    const [start_date, end_date] = range_date ?? [];

    if (start_date && end_date) {
      dispatch(
        ImSpecCaseDashboardBetaAction.setLastUpdate({
          [type]: {
            custom: {
              start_date: start_date?.utcOffset(0),
              end_date: end_date?.utcOffset(0)
            }
          }
        })
      );
      dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
      dispatch(ImSpecCaseDashboardBetaAction.applyFilters(savedViewId, savedViewName));
    }

    if (!range_date) {
      dispatch(ImSpecCaseDashboardBetaAction.clearFilters(type));
      dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
      quick && dispatch(ImSpecCaseDashboardBetaAction.applyFilters(savedViewId, savedViewName));
    }
  };

  const onChangeSwitcher = checked => {
    setSwitcher(checked);
    dispatch(
      ImSpecCaseDashboardBetaAction.setLastUpdate({
        [type]: {
          [radioItem]: !checked ? 'within' : 'before'
        }
      })
    );
    if (quick) {
      dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
      dispatch(ImSpecCaseDashboardBetaAction.applyFilters(savedViewId, savedViewName));
    }
  };

  const handleClick = (e, isClose) => {
    if (isClose) {
      setShowTooltip(false);
    } else {
      e.stopPropagation();
    }
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={quick ? 'lastUpdateBlockWrapper' : ''}
        role="button"
        tabIndex={0}
        onClick={(e, isClose = false) => handleClick(e, isClose)}
      >
        <div className="lastUpdateBlock">
          <div
            className={`switcher ${
              radioItem !== 'custom' ? (!switcher ? 'within' : 'before') : ''
            }`}
          >
            <span>Within</span>
            <Switch
              disabled={radioItem === 'custom'}
              checked={switcher}
              onChange={onChangeSwitcher}
              size="small"
            />
            <span>Before</span>
          </div>

          <Form form={form} initialValues={{ dateRange: [] }}>
            <Radio.Group onChange={onRadioChange} value={radioItem}>
              <Space direction="vertical">
                <Radio value="last_hour">Last hour</Radio>
                <Radio value="last_24_hour">Last 24 hours</Radio>
                <Radio value="last_week">Last week</Radio>
                <Radio value="last_month">Last month</Radio>
                <Radio value="last_6_month">Last 6 months</Radio>

                <Divider plain style={{ margin: '0', color: '#B4B3B3' }}>
                  or
                </Divider>

                <Radio value="custom">Custom range</Radio>
              </Space>
            </Radio.Group>

            <Form.Item name="dateRange">
              <RangePicker
                firstDayOfWeek={1}
                placement="topLeft"
                disabled={radioItem !== 'custom'}
                onChange={onRangePicker}
                allowEmpty={[true, true]}
              />
            </Form.Item>
          </Form>
        </div>
        {quick && (
          <>
            <Divider rootClassName="footer-divider" />
            <Button
              className="addedSubmitSection"
              type="ghost"
              onClick={(e, isClose = true) => handleClick(e, isClose)}
            >
              Close
            </Button>
          </>
        )}
      </div>
    </>
  );
};

LastUpdate.propTypes = {
  setShowTooltip: PropTypes.func,

  type: PropTypes.string,
  quick: PropTypes.bool,
  dataFromStorage: PropTypes.object
};

LastUpdate.defaultProps = {
  type: '',
  quick: false,
  dataFromStorage: {}
};

export default LastUpdate;
