import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useQuery from 'hooks/useQuery';

import { Questions } from './Questions';

import { Button, Col, Popover } from 'antd';
import { lightBulb } from 'components/Icons/Icons';
import useClosePopoverWhenScrolling from 'hooks/useClosePopoverWhenScrolling';
import { CloseOutlined, InfoCircleFilled } from '@ant-design/icons';

const Grouped = ({ curGroupContent, scrollRef }) => {
  const myRef = useRef(null);
  const { group } = useQuery();

  const [visiblePopover, setVisiblePopover] = useState(false);

  const { answersLoading } = useSelector(state => state.customerContent);

  const onScroll = () => {
    visiblePopover && setVisiblePopover(false);
  };

  useClosePopoverWhenScrolling(scrollRef, onScroll);

  useEffect(() => {
    if (group && myRef && myRef.current && group === curGroupContent.title.replaceAll(' ', '-')) {
      !answersLoading && myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [answersLoading, curGroupContent.title, group, myRef]);

  const handleVisiblePopoverChange = visiblePopover => {
    setVisiblePopover(visiblePopover);
  };

  const subSectionDesc = (
    <>
      <div className="subSectionDescBody">
        <div className="subSectionDescBody_content">
          <span>{lightBulb}</span>
          <p style={{ whiteSpace: 'pre-line' }}>{curGroupContent?.info}</p>
        </div>
      </div>
      <div className="close-icon">
        <Button
          type="ghost"
          shape="circle"
          onClick={() => handleVisiblePopoverChange(false)}
          icon={<CloseOutlined />}
        />
      </div>
    </>
  );

  return (
    <Col ref={myRef} className="mainContent cloud">
      <div className="groupName">
        <div className="group-header">
          <h3>{curGroupContent.title}</h3>
          {curGroupContent?.info && (
            <Popover
              placement="right"
              overlayClassName="subSectionDesc"
              content={subSectionDesc}
              trigger="click"
              open={visiblePopover}
              onOpenChange={handleVisiblePopoverChange}
            >
              <InfoCircleFilled />
            </Popover>
          )}
        </div>
        {curGroupContent.description && (
          <div className="groupDesc">
            <p style={{ whiteSpace: 'pre-line' }}>{curGroupContent.description}</p>
          </div>
        )}
      </div>
      {curGroupContent.question_set.map((question, index) => (
        <Questions
          scrollRef={scrollRef}
          key={question.id}
          curGroup={curGroupContent}
          index={index}
          curQuestionContent={question}
        />
      ))}
    </Col>
  );
};

Grouped.propTypes = {
  curGroupContent: PropTypes.object,
  scrollRef: PropTypes.object
};

Grouped.defaultProps = {
  curGroupContent: {},
  scrollRef: {}
};

export default Grouped;
