import generatePicker from 'antd/lib/date-picker/generatePicker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import moment from 'moment';

moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the start of the week
  }
});

const MyDatePicker = generatePicker(momentGenerateConfig);

export default MyDatePicker;
