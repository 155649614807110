import PropTypes from 'prop-types';
import { stringifyNumber } from 'utils/constants';
import Question from 'pages/CustomerPages/SectionReview/elements/question';

const IterativeSection = ({
  title,
  questionSet,
  group_name,
  sectionsAnswers,
  index,
  nestedQuestion
}) => {
  return (
    <>
      <h4 className="iteration-question-title">
        {stringifyNumber(index + 1)} {title}
      </h4>
      {!!nestedQuestion.length &&
        nestedQuestion.map((question = {}) => (
          <Question
            key={question.id}
            question={question}
            iterationName={group_name}
            sectionsAnswers={sectionsAnswers}
            questionSet={questionSet}
          />
        ))}
    </>
  );
};

IterativeSection.propTypes = {
  title: PropTypes.string,
  questionSet: PropTypes.string,
  group_name: PropTypes.string,
  sectionsAnswers: PropTypes.object,
  index: PropTypes.number,
  nestedQuestion: PropTypes.array
};

IterativeSection.defaultProps = {
  title: '',
  questionSet: '',
  group_name: '',
  sectionsAnswers: {},
  index: null,
  nestedQuestion: []
};

export default IterativeSection;
