import { useEffect } from 'react';

const useClosePopoverWhenScrolling = (ref, callback) => {
  useEffect(() => {
    let observerRefValue = null;

    const observer = new IntersectionObserver(() => {}, {});
    if (ref && ref.current) {
      observer.observe(ref.current);
      observerRefValue = ref.current;
      ref.current.addEventListener('scroll', callback);
    }

    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue);
    };
  }, [callback, ref]);
};

export default useClosePopoverWhenScrolling;
