import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Col, Table, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import childColumns from 'pages/ImSpecPages/SectionDashboard/elements/ChildCases/columns';
import clientPageChildCasesColumns from 'pages/ImSpecPages/SectionDashboard/elements/ChildCases/clientPageChildCasesColumns';

const ChildCases = ({ child_cases, loading, userID, page, role, caseType }) => {
  const columns =
    page !== 'client-page'
      ? childColumns({ userID })
      : clientPageChildCasesColumns({ userID, role });

  const tooltipRow = ({ className, ...props }) => {
    const classNames = className.split(' ');
    if (
      classNames.includes('no-access') ||
      classNames.includes('no-access-owner') ||
      classNames.includes('no-access-pending') ||
      classNames.includes('sync-in-progress') ||
      classNames.includes('no-access-rep')
    ) {
      const tooltipTitle = classNames.includes('no-access') ? (
        'This questionnaire has been sent to your colleague for completion.'
      ) : classNames.includes('no-access-rep') ? (
        'This questionnaire has been sent to the company representative for completion.'
      ) : classNames.includes('no-access-owner') ? (
        <span>
          Access to this section has been disabled. Please contact
          <address>
            {' '}
            <a href="mailto:onboarding@legalpad.io"> onboarding@legalpad.io </a>
          </address>
          if you have questions.
        </span>
      ) : classNames.includes('no-access-pending') ? (
        <span>
          The questionnaire is in pending state. You’ll gain access to it, once the beneficiary
          activates it.
        </span>
      ) : classNames.includes('sync-in-progress') ? (
        'Syncing in progress. Please wait.'
      ) : // : classNames.includes('sync-in-progress') && role === 'customer'
      //   ? 'Details from your prior case are now being ported here. Please refresh the page to load them.'
      null;

      return (
        <Tooltip
          title={tooltipTitle}
          overlayClassName="no-access-tooltip"
          getPopupContainer={triggerNode => triggerNode.parentNode.parentNode.parentNode}
        >
          <tr className={className} {...props} />
        </Tooltip>
      );
    }
    return <tr className={className} {...props} />;
  };

  const rowClassName = useCallback(
    record => {
      if (role !== 'customer' && record.sync_in_progress)
        return 'case_sections-list-row sync-in-progress';

      if (caseType === 'own' && role === 'customer') {
        if (record.beneficiary_has_access && record.sync_in_progress)
          return 'case_sections-list-row sync-in-progress';
        if (record.owner.is_representative && !record.beneficiary_has_access)
          return 'case_sections-list-row no-access-rep';

        const noAccessClassName =
          record.owner.id === userID
            ? 'case_sections-list-row no-access-owner'
            : 'case_sections-list-row no-access';

        return record.beneficiary_has_access ? 'case_sections-list-row' : noAccessClassName;
      }

      return 'case_sections-list-row';
    },
    [caseType, role, userID]
  );

  return (
    <Col className="child-cases-section_dashboard">
      {child_cases.map((child, i) => (
        <Col key={child.case_id} className="child-cases-section_dashboard-section">
          <h2 className="child-cases-section_dashboard-section-title">
            {child.visa_name} Child Information {child.name}
          </h2>
          <Table
            showHeader={page !== 'client-page' ? i === 0 : false}
            className={`case_sections-list child-cases ${page === 'client-page' ? 'client' : ''}`}
            rowClassName={rowClassName}
            tableLayout="fixed"
            loading={{
              spinning: loading,
              indicator: <LoadingOutlined style={{ fontSize: 30 }} />
            }}
            columns={columns}
            dataSource={child.sections.map(childCase => ({
              ...childCase,
              caseID: child.case_id,
              key: `${childCase.id} ${childCase.case_id}`
            }))}
            pagination={false}
            components={{
              body: { row: tooltipRow }
            }}
          />
        </Col>
      ))}
    </Col>
  );
};

ChildCases.propTypes = {
  child_cases: PropTypes.array,
  loading: PropTypes.bool,
  page: PropTypes.string,
  role: PropTypes.string,
  caseType: PropTypes.string,
  userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ChildCases.defaultProps = {
  child_cases: [],
  loading: false,
  page: '',
  role: '',
  caseType: ''
};

export default ChildCases;
