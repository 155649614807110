import request from 'utils/request';

export const CustomerClientPageService = {
  getCompanies: () => {
    return request('GET', 'api/petitioner/me');
  },
  getCompanyCases: petitioner_id => {
    return request('GET', `api/master-intake/petitioners/${petitioner_id}`);
  },
  getBeneficiary: () => {
    return request('GET', 'api/answer/cases/beneficiary');
  },
  getAssociated: () => {
    return request('GET', 'api/answer/cases/associated');
  },
  getCaseSections: caseID => {
    return request('GET', `api/answer/petition-data/${caseID}/sections/`);
  }
};
