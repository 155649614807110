import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { Row, Switch } from 'antd';

const columns = ({ userID }) => {
  return [
    {
      title: 'Section name',
      dataIndex: 'section_name',
      key: 'section_name',
      ellipsis: true,
      width: '20%',
      render: (section_name, { caseID }) => (
        <span className="table-items">
          <Link
            className="table-hrefs"
            to={`/client-management/userID=${userID}/${caseID}/${section_name.replaceAll(
              ' ',
              '-'
            )}`}
          >
            {section_name}
          </Link>
        </span>
      )
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      ellipsis: true,
      width: '15%',
      render: owner => <span className="table-items">{owner.full_name}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '15%',
      render: status => (
        <div className={`${status.replaceAll(' ', '-')} section-status`}>
          <span className="table-items">{status}</span>
        </div>
      )
    },
    {
      title: <div className="beneficiary_has_access">Beneficiary Access</div>,
      dataIndex: 'beneficiary_has_access',
      key: 'beneficiary_has_access',
      ellipsis: true,
      width: '16%',
      render: beneficiary_has_access => (
        <Row className="beneficiary_access" justify="center" align="middle">
          <Switch
            disabled
            className="beneficiary_access-switch"
            defaultChecked={beneficiary_has_access}
          />
        </Row>
      )
    },
    {
      title: 'Last update',
      dataIndex: 'last_updated_at',
      key: 'last_updated_at',
      ellipsis: true,
      width: '12%',
      render: last_updated_at => (
        <span className="last-update">
          {moment(last_updated_at).format('MM.DD.YYYY')}
          <span className="hours">{moment(last_updated_at).format('hh:mm A')}</span>
        </span>
      )
    }
  ];
};

export default columns;
