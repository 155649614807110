import request from 'utils/request';

export const ImSpecContentService = {
  getSectionContent: (caseID, sectionTitle) => {
    return request('GET', `api/is/case/${caseID}/sections/${sectionTitle}/`);
  },
  getSectionAnswers: (caseID, sectionTitle) => {
    return request('GET', `api/is/case/${caseID}/section/${sectionTitle}/answers`);
  },
  getMasterIntakeForm: (masterIntakeID, petitionerName) => {
    return request('GET', `api/master-intake/${masterIntakeID}/petitioner/${petitionerName}`);
  },
  getMasterIntakeAnswers: (masterIntakeID, petitionerName) => {
    return request(
      'GET',
      `api/master-intake/${masterIntakeID}/petitioner/${petitionerName}/answers`
    );
  },
  saveAnswer: (caseID, sectionTitle, answers) => {
    return request('POST', `api/is/case/${caseID}/section/${sectionTitle}/answers`, answers);
  },
  sendMasterIntakeFieldAnswer: (masterIntakeID, petitionerName, answer) => {
    return request(
      'POST',
      `api/master-intake/${masterIntakeID}/petitioner/${petitionerName}/answers`,
      answer
    );
  },
  deleteIterationQuestionSet: (caseID, groupName, iterationKey, sectionTitle) => {
    return request('DELETE', `api/answer/${caseID}/${groupName}/${iterationKey}/${sectionTitle}`);
  },
  deleteFile: (fileID, answerID) => {
    return request('DELETE', `api/files/${fileID}/${answerID}/`);
  },
  deleteMasterIntakeFile: (fileID, answerID) => {
    return request('DELETE', `api/files/master_intake/file/${fileID}/answer/${answerID}/`);
  },
  checkPreviewLink: file_id => {
    return request('GET', `api/files/${file_id}`);
  },
  checkMasterIntakePreviewLink: file_id => {
    return request('GET', `api/files/${file_id}/master_intake`);
  },
  completeSection: (caseID, sectionTitle, approved) => {
    return request('PATCH', `api/is/case/${caseID}/sections/${sectionTitle}/submit/`, approved);
  },
  getAnswerLogs: answerID => {
    return request('GET', `api/logs/${answerID}`);
  },
  getMasterIntakeAnswerLogs: answerID => {
    return request('GET', `api/logs/master-intake/${answerID}`);
  },
  getAnswerComments: answerID => {
    return request('GET', `api/comments/${answerID}`);
  },
  getMasterIntakeAnswerComments: answerID => {
    return request('GET', `api/comments/master-intake/answer/${answerID}`);
  },
  saveComment: comment => {
    return request('POST', 'api/comments/', comment);
  },
  saveMasterIntakeComment: comment => {
    return request('POST', 'api/comments/master-intake/', comment);
  },
  deleteComment: comment_id => {
    return request('DELETE', `api/comments/delete/${comment_id}/`);
  },
  deleteMasterIntakeComment: comment_id => {
    return request('DELETE', `api/comments/master-intake/delete/comment/${comment_id}/`);
  },
  resolveComment: answer_id => {
    return request('POST', `api/comments/${answer_id}/resolve/`);
  },
  resolveMasterIntakeComment: answer_id => {
    return request('POST', `api/comments/master-intake/answer/${answer_id}/resolve/`);
  },
  createDependent: (values, caseID, field_name, section_name) => {
    return request(
      'POST',
      `/api/answer/section/${section_name}/dependent/${caseID}/field/${field_name}/`,
      values
    );
  },
  deleteDependent: (data, caseID, field_name, section_name) => {
    return request(
      'DELETE',
      `/api/answer/section/${section_name}/dependent/${caseID}/field/${field_name}/`,
      data
    );
  },
  syncAndSubmit: (masterIntakeID, data) => {
    return request('POST', `api/master-intake/${masterIntakeID}/im_spec/sync_submit/`, data);
  }
};
