import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import { Button, Tooltip } from 'antd';

import FloatLabelInput from 'components/shared/FloatLabelInput';

import { openUrl } from 'components/Icons/Icons';

const Url = ({
  setRequiredErrorClassName,
  curSubsection,
  answersDataState,
  answers,
  curField,
  iterationName,
  questionSet,
  savingValue,
  setSavingValue,
  setInputError,
  inputError,
  setShowRequiredError,
  showRequiredError,
  onFocus,
  onBlur,
  radioDisable,
  inputRef,
  sync_info
}) => {
  const [floatingValue, setFloatingValue] = useState('');
  const [disableIcon, setDisableIcon] = useState(false);

  useEffect(() => {
    if (!!answersDataState && Object.keys(answersDataState).length) {
      setFloatingValue(answersDataState[curField.name]?.field_value);
    }
  }, [answersDataState, curField]);

  useEffect(() => {
    setRequiredErrorClassName(
      (curField.is_required && showRequiredError && !floatingValue) ||
        (savingValue.get('field_type') === 'Url' &&
          savingValue.get('field_value') &&
          inputError.hasValidUrl)
        ? 'required-error-block'
        : ''
    );
  }, [
    curField.is_required,
    floatingValue,
    inputError.hasValidUrl,
    savingValue,
    setRequiredErrorClassName,
    showRequiredError
  ]);

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;

    setFloatingValue(value);
    const formData = new FormData();
    formData.append('field_name', name);
    formData.append('field_value', value);
    formData.append('field_type', type);
    formData.append('group_name', !!iterationName ? iterationName : '');
    questionSet &&
      !questionSet.startsWith('uId') &&
      formData.append(
        'iteration_key',
        !!answers[iterationName]?.field_value || !questionSet.startsWith('uId')
          ? answers[iterationName]?.field_value[questionSet][name]?.iteration_key
          : null
      );
    setSavingValue(formData);

    const hasValidUrl =
      !!value &&
      !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        value
      );
    setInputError({
      ...inputError,
      hasValidUrl
    });

    curField.is_required && !value.trim().length
      ? setShowRequiredError(true)
      : setShowRequiredError(false);
  };

  const onChange = e => handleOnChange(e, curField.type.type);

  const onFocusUrl = () => {
    setDisableIcon(true);
    onFocus();
  };

  const onBlurUrl = () => {
    setDisableIcon(savingValue.get('field_value') && inputError.hasValidUrl);
    onBlur();
  };

  const onClickUrl = useCallback(() => {
    let url =
      answersDataState[curField.name]?.field_value.includes('https://') ||
      answersDataState[curField.name]?.field_value.includes('http://')
        ? answersDataState[curField.name]?.field_value
        : `http://${answersDataState[curField.name]?.field_value}`;

    window.open(url, '_blank');
  }, [answersDataState, curField.name]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FloatLabelInput
          id={curField?.id}
          name={curField?.name}
          label={`${curField?.placeholder}${curField.is_required ? '*' : ''}`}
          onChange={onChange}
          onFocus={onFocusUrl}
          onBlur={onBlurUrl}
          value={floatingValue || ''}
          disabled={sync_info.sync_in_progress || curSubsection?.is_blocked || radioDisable}
          inputRef={inputRef}
        />
        {!!answersDataState &&
          !!Object.keys(answersDataState).length &&
          answersDataState[curField.name]?.field_value && (
            <Tooltip
              placement="right"
              title={radioDisable || disableIcon ? '' : 'Open link in a new tab'}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              overlayClassName="url-tooltip"
            >
              <span className={`url-wrapper ${radioDisable || disableIcon ? 'disabled' : ''}`}>
                <Button type="ghost" className="url-link" onClick={onClickUrl} icon={openUrl} />
              </span>
            </Tooltip>
          )}
      </div>
    </div>
  );
};

Url.propTypes = {
  setRequiredErrorClassName: PropTypes.func,
  curSubsection: PropTypes.object,
  answersDataState: PropTypes.object,
  answers: PropTypes.object,
  curField: PropTypes.object,
  iterationName: PropTypes.string,
  questionSet: PropTypes.string,
  savingValue: PropTypes.object,
  setSavingValue: PropTypes.func,
  setInputError: PropTypes.func,
  inputError: PropTypes.object,
  setShowRequiredError: PropTypes.func,
  showRequiredError: PropTypes.bool,
  radioDisable: PropTypes.bool,
  inputRef: PropTypes.object,
  sync_info: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

Url.defaultProps = {
  curSubsection: {},
  answersDataState: {},
  answers: {},
  curField: {},
  iterationName: '',
  questionSet: '',
  savingValue: {},
  inputError: {},
  showRequiredError: false,
  radioDisable: false,
  inputRef: {},
  sync_info: {}
};

export default Url;
