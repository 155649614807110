import request from 'utils/request';

export const AuthService = {
  signIn: dataForm => {
    return request('POST', 'user/sign-in/', dataForm);
  },
  signOut: dataForm => {
    return request('POST', 'user/sign-out/', dataForm);
  },
  signUp: dataForm => {
    return request('POST', 'user/users/', dataForm);
  },
  signUpInvited: dataForm => {
    return request('PATCH', '/user/invitation-update', dataForm);
  },
  emailVerification: id => {
    return request('PATCH', `user/email-verification/${id}/`, id);
  },
  resendVerifyEmail: dataForm => {
    return request('POST', 'user/verify-now/', dataForm);
  },
  recoveryPassword: dataForm => {
    return request('POST', 'user/forgot-password/', dataForm);
  },
  resetPassword: (id, password) => {
    return request('PATCH', `user/forgot-password/${id}`, password);
  },
  authInvitedUser: (invitation_generated, confirmation_token) => {
    return request('GET', `user/check-invitation/${invitation_generated}/${confirmation_token}`);
  }
};
