import PropTypes from 'prop-types';

import IndividualComment from 'components/HistoryLogCommentsThread/CommentsContent/elements/IndividualComment';
import EmptyContent from 'components/HistoryLogCommentsThread/EmptyContent';

import './CommentsContent.scss';

const CommentsContent = ({
  comments,
  commentsActiveTab,
  question,
  deletedComment,
  sentComment,
  user
}) => {
  if (!comments.length) return <EmptyContent text="There are no comments here yet." />;

  return (
    <>
      {comments.map(comment => (
        <IndividualComment
          key={comment.answer.id}
          deletedComment={deletedComment}
          comment={comment}
          question={question}
          commentsActiveTab={commentsActiveTab}
          sentComment={sentComment}
          user={user}
        />
      ))}
    </>
  );
};

CommentsContent.propTypes = {
  comments: PropTypes.array,
  question: PropTypes.object,
  commentsActiveTab: PropTypes.string,
  deletedComment: PropTypes.object,
  sentComment: PropTypes.object,
  user: PropTypes.object
};

CommentsContent.defaultProps = {
  comments: [],
  count: 0,
  isLoading: false
};

export default CommentsContent;
