import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation, useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerSidebarAction, CustomerContentAction } from 'store/Actions';

import useQuery from 'hooks/useQuery';
import SectionIcon from 'components/Icons/SectionIcon';

import { legalPadIconWithoutText, legalPadIconText, backIcon } from 'components/Icons/Icons';

import { CUSTOMER_ACCOUNT_PROFILE_SIDEBAR_ITEMS, CUSTOMER_SIDEBAR_ITEMS } from 'utils/constants';
import { sectionAndSubsectionNames } from 'utils/helper';

import { Layout, Menu } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';

const { Sider } = Layout;

const MasterIntakeSider = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { subsection, group } = useQuery();

  const masterIntakeCustomer = useMatch(
    '/master-intake/:masterIntakeID/:petitionerName/:subSectionTitle'
  );
  const masterIntakeIS = useMatch(
    '/petitioner-dashboard/master-intake/:masterIntakeID/:petitionerName'
  );
  const section = masterIntakeCustomer || masterIntakeIS;
  const accountProfile = useMatch('/account-profile');

  const { petitionerName, caseID, subSectionTitle, masterIntakeID } = useParams();

  const {
    ImSpecContent: { section: ImSpecContent },
    customerSidebar: {
      sidebarInfo: { master_intake }
    }
  } = useSelector(state => ({
    ImSpecContent: state.ImSpecContent,
    customerSidebar: state.customerSidebar
  }));

  const {
    content: {
      section: { case_type }
    }
  } = useSelector(state => state.customerSectionsReview);

  const [openSubs, setOpenSubs] = useState([]);
  const [sectionOpen, setSectionOpen] = useState([]);
  const [subSectionOpen, setSubSectionOpen] = useState([]);
  const [groupOpen, setGroupOpen] = useState([]);

  useEffect(() => {
    const backPageFromSubSectionContent = () => {
      dispatch(CustomerSidebarAction.setChildCases([]));
      dispatch(CustomerContentAction.setCustomerContent({}));
      dispatch(CustomerContentAction.setContentAnswers({}));
    };

    if (section && section.pathname.endsWith('/review')) {
      backPageFromSubSectionContent();
    }
  }, [dispatch, section]);

  useEffect(() => {
    section &&
      petitionerName &&
      dispatch(CustomerSidebarAction.getCaseTypes(caseID, petitionerName));
  }, [caseID, dispatch, petitionerName, section]);

  useEffect(() => {
    if (!section) {
      dispatch(CustomerSidebarAction.setMasterIntakeSidebar([]));
      dispatch(CustomerSidebarAction.sendSidebarData({}));
      dispatch(CustomerSidebarAction.setChildCases([]));
      dispatch(CustomerContentAction.setCustomerContent({}));
      dispatch(CustomerContentAction.setContentAnswers({}));
    }
  }, [dispatch, section]);

  useEffect(() => {
    const openSubs = (masterIntakeID, subsection, group) => {
      setGroupOpen(group ? [`${sectionAndSubsectionNames(group)} ${masterIntakeID}`] : []);
      setSubSectionOpen(
        subsection ? [`${sectionAndSubsectionNames(subsection)} ${masterIntakeID}`] : []
      );
      setSectionOpen(masterIntakeID ? [masterIntakeID] : []);
    };

    if (masterIntakeIS) openSubs(masterIntakeID, subsection, group);
    if (masterIntakeCustomer) openSubs(masterIntakeID, subSectionTitle, group);
  }, [group, masterIntakeCustomer, masterIntakeID, masterIntakeIS, subSectionTitle, subsection]);

  useEffect(() => {
    setOpenSubs([...sectionOpen, ...subSectionOpen, ...groupOpen]);
  }, [sectionOpen, subSectionOpen, groupOpen]);

  const onSelect = ({ key }) => {
    if (key === 'back') {
      goBackDashboard();
    }

    if (key === 'legalpad') {
      setGroupOpen([]);
      setSectionOpen([]);
      setSubSectionOpen([]);
      setOpenSubs([]);
      dispatch(CustomerSidebarAction.setMasterIntakeSidebar([]));
      dispatch(CustomerSidebarAction.sendSidebarData({}));
      dispatch(CustomerSidebarAction.setChildCases([]));
      dispatch(CustomerContentAction.setCustomerContent({}));
      dispatch(CustomerContentAction.setContentAnswers({}));
      navigate('/dashboard');
    }
  };

  const currentKey = useMemo(() => {
    let items = accountProfile ? CUSTOMER_ACCOUNT_PROFILE_SIDEBAR_ITEMS : CUSTOMER_SIDEBAR_ITEMS;
    return items
      .find(item => {
        if (location.pathname.includes(item.path)) return item;
      })
      ?.id.toString();
  }, [accountProfile, location.pathname]);

  // open sections for IS side

  const openSection = ({ key, name }) => {
    navigate(`/petitioner-dashboard/master-intake/${key}/${name.replaceAll(' ', '-')}`);
  };

  const onCLickSubSections = ({ key }) => {
    const titleWithCaseId = key.split(' ');
    const title = titleWithCaseId.slice(0, -1).join(' ');
    navigate(
      `${location.pathname}${
        subSectionOpen.includes(title) ? '' : `?subsection=${title.replaceAll(' ', '-')}`
      }`
    );
  };

  const onCLickSubSectionsGroup = (subTitle, groupTitle) => {
    navigate(
      `${location.pathname}?subsection=${subTitle.replaceAll(
        ' ',
        '-'
      )}&group=${groupTitle.replaceAll(' ', '-')}`
    );
  };

  // open sections for customer side

  const openSubSectionCus = ({ id, name, subSectionTitle }) => {
    setSubSectionOpen(state => {
      if (state.includes(subSectionTitle)) {
        state.splice(state.indexOf(subSectionTitle), 1);
        return [...state];
      } else {
        return [subSectionTitle];
      }
    });
    setGroupOpen([]);
    navigate(
      `/master-intake/${id}/${name.replaceAll(' ', '-')}/${subSectionTitle.replaceAll(' ', '-')}`
    );
  };

  const onClickSectionsCus = ({ key }) => {
    setSectionOpen(prevState => {
      if (prevState.includes(key)) {
        prevState.splice(prevState.indexOf(key), 1);
        return [...prevState];
      } else {
        return [...prevState, key];
      }
    });
  };

  const onCLickSubSectionsGroupCus = groupTitle => {
    navigate(`${location.pathname}?group=${groupTitle.replaceAll(' ', '-')}`);
  };

  const caseSections = master_intake.map(
    ({
      subsection_set = [],
      title: sectionTitle = '',
      visa_name = '',
      master_intake_id = null,
      petitioner_name = '',
      icon = ''
    }) => {
      return subsection_set?.length !== 0
        ? {
            label: visa_name,
            key: master_intake_id,
            className: `visaType-pages ${openSubs.includes(master_intake_id) && 'active-section'}`,
            popupClassName: 'sub-pop-up',
            onTitleClick: ({ key }) =>
              masterIntakeIS
                ? openSection({ key, name: petitioner_name })
                : masterIntakeCustomer
                ? onClickSectionsCus({ key })
                : null,
            icon: icon ? <SectionIcon name={icon} /> : <GlobalOutlined />,
            children: [
              ...subsection_set.map(({ group_set = [], title: subTitle = '' }) => {
                return group_set?.length !== 0
                  ? {
                      label: subTitle,
                      key: `${subTitle} ${master_intake_id}`,
                      className: `sub-sections ${
                        openSubs.includes(`${subTitle} ${master_intake_id}`) && 'active-subsection'
                      }`,
                      popupClassName: 'sub-pop-up',
                      onTitleClick: masterIntakeIS
                        ? onCLickSubSections
                        : masterIntakeCustomer
                        ? () =>
                            openSubSectionCus({
                              id: master_intake_id,
                              name: petitioner_name,
                              subSectionTitle: subTitle
                            })
                        : null,
                      children: [
                        ...group_set.map(({ title: groupTitle }) => {
                          return {
                            label: groupTitle,
                            key: `${groupTitle} ${master_intake_id}`,
                            className: `group ${
                              openSubs.includes(`${groupTitle} ${master_intake_id}`) &&
                              'active-group'
                            }`,
                            onClick: masterIntakeIS
                              ? () => onCLickSubSectionsGroup(subTitle, groupTitle)
                              : masterIntakeCustomer
                              ? () => onCLickSubSectionsGroupCus(groupTitle)
                              : null
                          };
                        })
                      ]
                    }
                  : {
                      label: subTitle,
                      key: `${subTitle} ${master_intake_id}`,
                      className: `sub-sections-item ${
                        openSubs.includes(`${subTitle} ${master_intake_id}`) &&
                        'active-subsection-item'
                      }`,
                      onClick: masterIntakeIS
                        ? onCLickSubSections
                        : masterIntakeCustomer
                        ? () =>
                            openSubSectionCus({
                              id: master_intake_id,
                              name: petitioner_name,
                              subSectionTitle: subTitle
                            })
                        : null
                    };
              })
            ]
          }
        : {
            label: sectionTitle,
            key: master_intake_id,
            className: 'visaType-pages',
            icon: <SectionIcon name={icon} />,
            onClick: ({ key }) =>
              masterIntakeIS
                ? openSection({ key, name: petitioner_name })
                : masterIntakeCustomer
                ? onClickSectionsCus({ key })
                : null
          };
    }
  );

  const goBackDashboard = () => {
    if (location.pathname.includes('review')) {
      setSectionOpen([]);
      setSubSectionOpen([]);
      setOpenSubs([]);
      dispatch(CustomerSidebarAction.setMasterIntakeSidebar([]));
      dispatch(CustomerSidebarAction.setChildCases([]));
      dispatch(CustomerContentAction.setCustomerContent({}));
      dispatch(CustomerContentAction.setContentAnswers({}));
      navigate(-1);
    }

    if (section) {
      setSectionOpen([]);
      setGroupOpen([]);
      setSubSectionOpen([]);
      setOpenSubs([]);
      dispatch(CustomerSidebarAction.setMasterIntakeSidebar([]));
      dispatch(CustomerSidebarAction.setChildCases([]));
      dispatch(CustomerContentAction.setCustomerContent({}));
      dispatch(CustomerContentAction.setContentAnswers({}));
      masterIntakeCustomer &&
        master_intake.length &&
        navigate(`/your-questionnaires?petitionerID=${master_intake[0].petitioner_id}`);
      masterIntakeIS && navigate(`/petitioner-dashboard/${ImSpecContent?.petitioner_id}`);
    }
  };

  return (
    <Sider style={{ position: 'fixed', height: '100%' }} width={340} className="user-sidebar">
      <Menu
        mode="inline"
        className="ImSpec_Menu"
        onClick={onSelect}
        selectedKeys={[]}
        items={[
          {
            label: legalPadIconText,
            key: 'legalpad',
            className: 'ImSpec-sidebar-logo',
            icon: legalPadIconWithoutText
          },
          ...(section || accountProfile
            ? [
                {
                  label: 'Back',
                  key: 'back',
                  icon: backIcon,
                  className: 'backBtn'
                }
              ]
            : [])
        ]}
      />
      {section && petitionerName && (
        <div key={petitionerName} id="petitionerName" className="petitionerName">
          {sectionAndSubsectionNames(petitionerName)}
        </div>
      )}
      {section && petitionerName && case_type && (
        <div
          key={case_type}
          id="sectionReviewVisaName"
          className="visaTypesBlock"
          style={{ padding: '15px', marginTop: '30px' }}
        >
          <GlobalOutlined />
          <span>{case_type}</span>
        </div>
      )}
      <div className="menu-scroll">
        <Menu
          mode="inline"
          className="ImSpec_Menu case-section-menu"
          onClick={onSelect}
          openKeys={openSubs}
          selectedKeys={[currentKey]}
          items={[
            ...(section && !section.pathname.endsWith('/review') && !!master_intake.length
              ? caseSections
              : [])
          ]}
        />
      </div>
    </Sider>
  );
};

export default MasterIntakeSider;
