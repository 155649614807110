import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { Badge, Button, Col, Drawer, Dropdown, Pagination, Row, Spin, Tabs } from 'antd';

import { FieldTimeOutlined, CommentOutlined, LoadingOutlined } from '@ant-design/icons';
import { threadCloseIcon, threadFilterIcon } from 'components/Icons/Icons';
import SectionIcon from 'components/Icons/SectionIcon';

import { HistoryLogCommentsThreadAction } from 'store/Actions';

import HistoryLogContent from 'components/HistoryLogCommentsThread/HistoryLogContent';
import CommentsContent from 'components/HistoryLogCommentsThread/CommentsContent';

import { sectionAndSubsectionNames } from 'utils/helper';

import './HistoryLogCommentsThread.scss';

const HistoryLogCommentsThread = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { caseID, masterIntakeID, sectionTitle } = useParams();

  const {
    open,
    activeThreadTab,
    caseSections,
    sectionsFilterKey,
    historyLogs: {
      logs,
      page: logsPage,
      count: logsCount,
      isLoading: isLoadingLogs,
      logsActiveTab,
      question
    },
    comments: {
      comments,
      page: commentsPage,
      count: commentsCount,
      isLoading: isLoadingComments,
      commentsActiveTab,
      tabs: commentViewTabs,
      deletedComment,
      sentComment
    }
  } = useSelector(state => state.HistoryLogCommentsThread);

  const { user } = useSelector(state => state.auth);

  const hideCommentsOverview = useFlag('hide-comments-overview');
  const { flagsReady } = useFlagsStatus();

  useEffect(() => {
    dispatch(
      HistoryLogCommentsThreadAction.setSectionsFilterKey(
        sectionTitle ? sectionAndSubsectionNames(sectionTitle) : 'all'
      )
    );
    dispatch(HistoryLogCommentsThreadAction.setThreadOpen(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, caseID, masterIntakeID, sectionTitle, location.pathname]);

  useEffect(() => {
    if (open) {
      if (activeThreadTab === 'history_log') {
        dispatch(HistoryLogCommentsThreadAction.getHistoryLogs(caseID, masterIntakeID));
      }

      if (activeThreadTab === 'comments') {
        dispatch(
          HistoryLogCommentsThreadAction.getCommentsTabs(
            caseID,
            masterIntakeID,
            sectionTitle ? sectionAndSubsectionNames(sectionTitle) : null
          )
        );
        dispatch(HistoryLogCommentsThreadAction.getComments(caseID, masterIntakeID));
      }
    } else {
      dispatch(
        HistoryLogCommentsThreadAction.setSectionsFilterKey(
          sectionTitle ? sectionAndSubsectionNames(sectionTitle) : 'all'
        )
      );
      if (activeThreadTab === 'history_log') {
        dispatch(HistoryLogCommentsThreadAction.setLogsViewActiveTab('all'));
        dispatch(HistoryLogCommentsThreadAction.setLogsPage(1));
        dispatch(HistoryLogCommentsThreadAction.setLogs({ count: 0, logs: [] }));
      }
      if (activeThreadTab === 'comments') {
        dispatch(HistoryLogCommentsThreadAction.setCommentsViewActiveTab('unresolved'));
        dispatch(HistoryLogCommentsThreadAction.setCommentsPage(1));
        dispatch(HistoryLogCommentsThreadAction.setComments({ count: 0, comments: [] }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeThreadTab, dispatch, open]);

  const onClose = useCallback(() => {
    dispatch(HistoryLogCommentsThreadAction.setThreadOpen(false));
  }, [dispatch]);

  useEffect(() => {
    onClose();
  }, [location, onClose]);

  const onThreadTabChange = activeKey => {
    dispatch(
      HistoryLogCommentsThreadAction.onChangeThreadTab(
        activeKey,
        sectionTitle ? sectionAndSubsectionNames(sectionTitle) : sectionTitle
      )
    );
  };

  const onChangeLogsViewTab = useCallback(
    activeKey => {
      dispatch(
        HistoryLogCommentsThreadAction.onChangeLogsViewTab(caseID, masterIntakeID, activeKey)
      );
    },
    [caseID, dispatch, masterIntakeID]
  );

  const onChangeCommentViewTab = useCallback(
    activeKey => {
      dispatch(
        HistoryLogCommentsThreadAction.onChangeCommentsViewTab(caseID, masterIntakeID, activeKey)
      );
    },
    [caseID, dispatch, masterIntakeID]
  );

  const onChangeLogsPage = useCallback(
    page => {
      dispatch(HistoryLogCommentsThreadAction.onChangeLogsPage(page, caseID, masterIntakeID));
    },
    [caseID, dispatch, masterIntakeID]
  );

  const onChangeCommentsPage = useCallback(
    page => {
      dispatch(HistoryLogCommentsThreadAction.onChangeCommentsPage(page, caseID, masterIntakeID));
    },
    [caseID, dispatch, masterIntakeID]
  );

  const onChangeSections = section => {
    if (activeThreadTab === 'history_log') {
      dispatch(HistoryLogCommentsThreadAction.onChangeSectionsLogs(caseID, section.key));
    }
    if (activeThreadTab === 'comments') {
      dispatch(
        HistoryLogCommentsThreadAction.onChangeSectionsComments(caseID, masterIntakeID, section.key)
      );
    }
  };

  const TabsProps = useMemo(() => {
    return activeThreadTab === 'history_log'
      ? {
          activeKey: logsActiveTab,
          onChange: onChangeLogsViewTab,
          items: [
            {
              label: 'Manual updates',
              key: 'manual_updates',
              disabled: isLoadingLogs
            },
            {
              label: 'Sync updates',
              key: 'sync_updates',
              disabled: isLoadingLogs
            },
            {
              label: 'All',
              key: 'all',
              disabled: isLoadingLogs
            }
          ]
        }
      : activeThreadTab === 'comments'
      ? {
          activeKey: commentsActiveTab,
          onChange: onChangeCommentViewTab,
          items: commentViewTabs.map(tab => ({
            label: (
              <div className="comment-view-tab">
                <span className="comment-label">{tab.status}</span>
                <span className="comments-count">{tab.value}</span>
              </div>
            ),
            key: tab.status.toLowerCase(),
            disabled: isLoadingComments
          }))
        }
      : {};
  }, [
    activeThreadTab,
    logsActiveTab,
    onChangeLogsViewTab,
    isLoadingLogs,
    commentsActiveTab,
    onChangeCommentViewTab,
    isLoadingComments,
    commentViewTabs
  ]);

  const threadTabItems = useMemo(() => {
    return flagsReady && !hideCommentsOverview
      ? [
          {
            label: (
              <Badge
                rootClassName="unresolved-badge"
                dot={!!commentViewTabs.find(tab => tab.status === 'Unresolved')?.value}
              >
                <CommentOutlined style={{ fontSize: 24, color: '#63A0FF' }} />
              </Badge>
            ),
            key: 'comments'
          },
          {
            label: <FieldTimeOutlined style={{ fontSize: 24, color: '#63A0FF' }} />,
            key: 'history_log'
          }
        ]
      : [
          {
            label: <FieldTimeOutlined style={{ fontSize: 24, color: '#63A0FF' }} />,
            key: 'history_log'
          }
        ];
  }, [commentViewTabs, flagsReady, hideCommentsOverview]);

  const customerThreadTabItems = useMemo(() => {
    return flagsReady && !hideCommentsOverview
      ? [
          {
            label: (
              <Badge
                rootClassName="unresolved-badge"
                dot={!!commentViewTabs.find(tab => tab.status === 'Unresolved')?.value}
              >
                <CommentOutlined style={{ fontSize: 24, color: '#63A0FF' }} />
              </Badge>
            ),
            key: 'comments'
          }
        ]
      : {};
  }, [commentViewTabs, flagsReady, hideCommentsOverview]);

  const overall_spinning = useMemo(() => {
    if (activeThreadTab === 'history_log') return isLoadingLogs;

    if (activeThreadTab === 'comments') return isLoadingComments;

    return false;
  }, [activeThreadTab, isLoadingComments, isLoadingLogs]);

  const pagination = useMemo(() => {
    if (activeThreadTab === 'comments' && commentsCount < 15) return null;
    if (activeThreadTab === 'history_log' && logsCount < 15) return null;

    let paginationProps = {};
    if (activeThreadTab === 'comments') {
      paginationProps = {
        current: commentsPage,
        total: commentsCount,
        onChange: onChangeCommentsPage
      };
    }

    if (activeThreadTab === 'history_log') {
      paginationProps = {
        current: logsPage,
        total: logsCount,
        onChange: onChangeLogsPage
      };
    }

    return (
      <div className="thread-pagination">
        <Pagination
          {...paginationProps}
          disabled={overall_spinning}
          className="lp-pagination"
          defaultCurrent={1}
          defaultPageSize={15}
          size="small"
          pageSize={15}
          showSizeChanger={false}
          showQuickJumper={false}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        />
      </div>
    );
  }, [
    activeThreadTab,
    commentsCount,
    commentsPage,
    logsCount,
    logsPage,
    onChangeCommentsPage,
    onChangeLogsPage,
    overall_spinning
  ]);

  return (
    <Drawer
      width={567}
      onClose={overall_spinning ? () => {} : onClose}
      open={open}
      placement="right"
      rootClassName="HistoryLogCommentsThread"
    >
      <div className="thread-tabs">
        <Tabs
          onChange={onThreadTabChange}
          type="card"
          tabPosition="left"
          activeKey={activeThreadTab}
          items={user?.role === 'customer' ? customerThreadTabItems : threadTabItems}
        />
      </div>
      <div className="thread-content">
        <div className="thread-content-header">
          <Row className="thread-content-title" justify="space-between" align="middle">
            <Col>
              <h3>
                {activeThreadTab === 'comments'
                  ? 'Comments overview'
                  : activeThreadTab === 'history_log'
                  ? 'History log overview'
                  : null}
              </h3>
            </Col>
            <Col>
              <Button
                onClick={overall_spinning ? () => {} : onClose}
                type="ghost"
                icon={threadCloseIcon}
              />
            </Col>
          </Row>
          <Row className="thread-content-tabs" justify="space-between" align="bottom">
            <Col>
              <Tabs {...TabsProps} />
            </Col>
            {!masterIntakeID ? (
              <Col>
                <Dropdown
                  className="case-sections"
                  placement="bottomRight"
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  menu={{
                    className: 'case-sections-menu',
                    selectable: true,
                    onClick: onChangeSections,
                    selectedKeys: [sectionsFilterKey],
                    items: [
                      {
                        label: 'All',
                        key: 'all',
                        disabled: overall_spinning,
                        icon: <SectionIcon name="folderIcon" />
                      },
                      ...caseSections.map(({ section_name, section_icon }) => ({
                        label: section_name,
                        key: section_name,
                        disabled: overall_spinning,
                        icon: <SectionIcon name={section_icon} />
                      }))
                    ]
                  }}
                  trigger="click"
                  arrow={false}
                >
                  <Button className="filter" type="ghost" icon={threadFilterIcon} />
                </Dropdown>
              </Col>
            ) : null}
          </Row>
        </div>
        <div className="thread-content-inner-content">
          <Spin
            className="content-loading"
            wrapperClassName="content-loading-wrapper"
            tip="Loading..."
            spinning={overall_spinning}
            indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
          >
            {activeThreadTab === 'history_log' ? (
              <HistoryLogContent
                logs={logs}
                isLoading={isLoadingLogs}
                logsActiveTab={logsActiveTab}
                question={question}
              />
            ) : null}
            {activeThreadTab === 'comments' ? (
              <CommentsContent
                deletedComment={deletedComment}
                comments={comments}
                commentsActiveTab={commentsActiveTab}
                question={question}
                sentComment={sentComment}
                user={user}
              />
            ) : null}
          </Spin>
        </div>
        {pagination}
      </div>
    </Drawer>
  );
};

export default HistoryLogCommentsThread;
