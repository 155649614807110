import { CustomerActionTypes } from '../types';
import { CustomerSectionReviewService } from 'services';
import { congratulationsIcon } from 'components/Icons/Icons';

import { sectionAndSubsectionNames } from 'utils/helper';
import React from 'react';

const getSectionForReview =
  (caseID, sectionTitle, masterIntakeID, petitionerName) => async dispatch => {
    try {
      dispatch(sectionLoading(true));

      const res =
        masterIntakeID && petitionerName
          ? await CustomerSectionReviewService.getMasterIntakeContent(
              masterIntakeID,
              sectionAndSubsectionNames(petitionerName)
            )
          : await CustomerSectionReviewService.getSectionContent(
              caseID,
              sectionAndSubsectionNames(sectionTitle)
            );

      dispatch(setSection(res.data));
    } catch (e) {
      console.warn(e);
    } finally {
      dispatch(sectionLoading(false));
    }
  };

const getSectionsAnswers =
  (caseID, sectionTitle, masterIntakeID, petitionerName) => async dispatch => {
    try {
      dispatch(answersLoading(true));

      const res =
        masterIntakeID && petitionerName
          ? await CustomerSectionReviewService.getMasterIntakeAnswers(
              masterIntakeID,
              sectionAndSubsectionNames(petitionerName)
            )
          : await CustomerSectionReviewService.getSectionAnswers(
              caseID,
              sectionAndSubsectionNames(sectionTitle)
            );

      dispatch(setAnswers(res.data));
    } catch (e) {
      console.warn(e);
    } finally {
      dispatch(answersLoading(false));
    }
  };

const onSubmitSection =
  (caseID, sectionTitle, { infoModal, modalProps }, navigate) =>
  async () => {
    try {
      const {
        data: { section_name, first_subsection_name, modal_message, bottom_message }
      } = await CustomerSectionReviewService.onSubmitSection(
        caseID,
        sectionAndSubsectionNames(sectionTitle)
      );

      const onOk = () =>
        navigate(
          section_name && first_subsection_name
            ? `/your-questionnaires/${caseID}/${section_name.replaceAll(
                ' ',
                '-'
              )}/${first_subsection_name.replaceAll(' ', '-')}`
            : `/your-questionnaires?caseID=${caseID}`
        );
      const okText = section_name && first_subsection_name ? 'Go to Next Section' : 'Close';

      const modalContent = (
        <>
          <span className="submit-section-confirm-modal-content">
            Congratulations! {congratulationsIcon}
          </span>
          {!!modal_message ? (
            <>
              <span className="submit-section-confirm-modal-title">{modal_message}</span>
              <hr />
              {!!bottom_message ? (
                <span className="submit-section-confirm-modal-text">{bottom_message}</span>
              ) : (
                <span className="submit-section-confirm-modal-text">
                  Please email us at
                  <address style={{ display: 'inline' }}>
                    <a href="mailto:onboarding@legalpad.io">onboarding@legalpad.io</a>
                  </address>
                  to answer any questions you may have.
                </span>
              )}
            </>
          ) : (
            <>
              <span className="submit-section-confirm-modal-title">
                You have successfully submitted {sectionAndSubsectionNames(sectionTitle)}.
              </span>
              <hr />
              <span className="submit-section-confirm-modal-text">
                Your Legalpad team will review this and advise if additional details are needed.
              </span>
            </>
          )}
        </>
      );

      infoModal({ ...modalProps, onOk, okText, content: modalContent });
    } catch (e) {
      console.warn(e);
    }
  };

const syncAndSubmit =
  (masterIntakeID, petitionerName, { infoModal, modalProps }) =>
  async (dispatch, getState) => {
    try {
      const {
        customerSectionsReview: {
          content: { section }
        }
      } = getState();
      const res = await CustomerSectionReviewService.syncAndSubmit(masterIntakeID);

      dispatch(setSection({ ...section, ...res.data }));

      const modalContent = (
        <>
          <span className="submit-section-confirm-modal-content">
            Congratulations! {congratulationsIcon}
          </span>
          <span className="submit-section-confirm-modal-title">
            You have successfully submitted the {sectionAndSubsectionNames(petitionerName)} company
            questionnaire.
          </span>
          <hr />
          <span className="submit-section-confirm-modal-text">
            The information provided is now being synced to company employee cases.
          </span>
          <span className="submit-section-confirm-modal-text">
            Your Legalpad team will review this and advise if additional details are needed.
          </span>
        </>
      );

      infoModal({ ...modalProps, content: modalContent });
    } catch (e) {
      const content = (
        <>
          <span className="submit-section-confirm-modal-content">Oops!</span>
          <span className="submit-section-confirm-modal-text">
            This action cannot be completed for now.
          </span>
          <span className="submit-section-confirm-modal-text">Please try again later!</span>
        </>
      );
      e.status === 403 && infoModal({ ...modalProps, content: content });
    }
  };

const setSection = section => {
  return {
    type: CustomerActionTypes.SET_REVIEW_SECTION,
    payload: section
  };
};

const setAnswers = answers => {
  return {
    type: CustomerActionTypes.SET_REVIEW_ANSWERS,
    payload: answers
  };
};

const sectionLoading = loading => {
  return {
    type: CustomerActionTypes.REVIEW_SECTION_LOADING,
    payload: loading
  };
};

const answersLoading = loading => {
  return {
    type: CustomerActionTypes.REVIEW_ANSWERS_LOADING,
    payload: loading
  };
};

export const CustomerSectionReviewAction = {
  getSectionForReview,
  getSectionsAnswers,
  setSection,
  setAnswers,
  onSubmitSection,
  syncAndSubmit
};
