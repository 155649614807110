import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';

import Question from './question';

const unGroupSection = ({ section: { title, question_set }, sectionsAnswers, returnToEdit }) => {
  return (
    <Col className="sections">
      <Row justify="space-between" align="middle" className="section-title">
        <Col>
          <h2 className="section-title_title">{title}</h2>
        </Col>
        <Col>
          <Button
            onClick={() => returnToEdit({ title })}
            className="primary-btn section-title_return-btn"
          >
            Return to edit
          </Button>
        </Col>
      </Row>
      <div className="group-section">
        <Col className="group-section-container">
          {question_set.map(question => (
            <Question key={question.id} question={question} sectionsAnswers={sectionsAnswers} />
          ))}
        </Col>
      </div>
    </Col>
  );
};

unGroupSection.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    question_set: PropTypes.array
  }),
  sectionsAnswers: PropTypes.object,
  returnToEdit: PropTypes.func
};

unGroupSection.defaultProps = {
  section: {},
  sectionsAnswers: {}
};

export default unGroupSection;
