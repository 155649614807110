import { Col, Row } from 'antd';

const LinkIsNotAvailable = () => {
  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content resetPass">
        <Row>
          <h3>Oops, the link is not available.</h3>
        </Row>

        <Row type="flex" justify="center" align="middle" className="publicForms_content_cloud">
          <p>
            You’re seeing this because the link to create account in Legalpad platform is expired or
            disabled.
          </p>
        </Row>

        <hr />

        <Row type="flex" justify="center" align="middle" className="publicForms_content_footer">
          <p>
            You can contact us at onboarding@legalpad.io for any questions you might have.
          </p>
        </Row>
      </Col>
    </Row>
  );
};

export default LinkIsNotAvailable;
