import PropTypes from 'prop-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ImSpecSectionDashboardActions } from 'store/Actions';

// components
import sectionColumns from 'pages/ImSpecPages/SectionDashboard/elements/CaseTable/columns';

import { Table, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const CaseTable = ({ data, loading, hasAccounts }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { caseID, userID } = useParams();

  const tooltipRow = ({ className, ...props }) => {
    const classNames = className.split(' ');
    if (classNames.includes('sync-in-progress')) {
      const title = classNames.includes('sync-in-progress') ? (
        <span>
          Syncing in progress. <br /> Please wait!{' '}
        </span>
      ) : null;
      return (
        <Tooltip
          title={title}
          overlayClassName="no-access-tooltip"
          getPopupContainer={triggerNode => triggerNode.parentNode.parentNode.parentNode}
        >
          <tr className={className} {...props} />
        </Tooltip>
      );
    }
    return <tr className={className} {...props} />;
  };

  const rowClassName = record => {
    if (record.sync_in_progress) return 'case_sections-list-row sync-in-progress';
    return 'case_sections-list-row';
  };

  const onClickOwner = (event, section_name, owner) => {
    event.preventDefault();
    event.stopPropagation();
    const sectionName = section_name.replaceAll(' ', '-');
    const ownerID = owner.id;
    navigate(
      `${location.pathname}?owner_id=${ownerID}&section_name=${sectionName}&action=change_owner`
    );
  };

  const onUpdateBeneficiaryAccess = (checked, sectionName) => {
    dispatch(
      ImSpecSectionDashboardActions.updateBeneficiaryAccess(caseID, sectionName, {
        beneficiary_has_access: checked
      })
    );
  };

  const columns = sectionColumns(
    { onClickOwner, onUpdateBeneficiaryAccess, hasAccounts },
    { caseID, userID }
  );

  return (
    <Table
      className="case_sections-list"
      rowClassName={rowClassName}
      tableLayout="fixed"
      loading={{
        spinning: loading,
        indicator: <LoadingOutlined style={{ fontSize: 30 }} />
      }}
      columns={columns}
      dataSource={data}
      pagination={false}
      components={{
        body: { row: tooltipRow }
      }}
    />
  );
};

CaseTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  hasAccounts: PropTypes.bool
};

CaseTable.defaultProps = {
  data: [],
  loading: false,
  hasAccounts: false
};

export default CaseTable;
