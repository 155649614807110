// import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
// import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

// components
import Dashboard from 'pages/ImSpecPages/Dashboard';
import Section from 'pages/ImSpecPages/Section';
import ImSpecCaseDashboard from 'pages/ImSpecPages/CaseDashboard';
import CaseDashboardBeta from 'pages/ImSpecPages/CaseDashboardBeta';
import ClientManagement from 'pages/ImSpecPages/ClientManagement';
import SectionDashboard from 'pages/ImSpecPages/SectionDashboard';
import ClientPage from 'pages/ImSpecPages/ClientPage';
import PetitionerDashboard from 'pages/ImSpecPages/PetitionerDashboard';
import PetitionerPage from 'pages/ImSpecPages/PetitionerPage';

import Account from 'pages/CustomerPages/Account';

import HistoryLogCommentsThread from 'components/HistoryLogCommentsThread';

import { Layout } from 'antd';

import './index.scss';

const { Content } = Layout;

const ImSpecPages = () => {
  // const hideNewCaseDashboard = useFlag('hide-new-case-dashboard');
  // const { flagsReady } = useFlagsStatus();
  const dashboard = useMatch('/dashboard');
  const clientManagement = useMatch('/client-management');
  const clientPage = useMatch('/client-management/userID=:userID');
  const caseDashboard = useMatch('/case-dashboard');
  const caseDashboardBeta = useMatch('/case-dashboard-beta');
  const petitionerDashboard = useMatch('/petitioner-dashboard');
  const petitionerPage = useMatch('/petitioner-dashboard/:petitionerID');
  const sectionDashboard = useMatch('/client-management/userID=:userID/:caseID');
  const section = useMatch('/client-management/userID=:userID/:caseID/:sectionTitle');
  const masterIntake = useMatch(
    '/petitioner-dashboard/master-intake/:masterIntakeID/:petitionerName'
  );
  const profile = useMatch('/account-profile');

  // const CaseDashboard = useMemo(() => {
  //   return flagsReady && !hideNewCaseDashboard ? <CaseDashboardBeta /> : <ImSpecCaseDashboard />;
  // }, [flagsReady, hideNewCaseDashboard]);

  return (
    <Content style={{ height: '100%' }}>
      {dashboard && <Dashboard />}
      {clientManagement && <ClientManagement />}
      {clientPage && <ClientPage />}
      {caseDashboard && <ImSpecCaseDashboard />}
      {caseDashboardBeta && <CaseDashboardBeta />}
      {petitionerDashboard && <PetitionerDashboard />}
      {petitionerPage && <PetitionerPage />}
      {sectionDashboard && <SectionDashboard />}
      {section && <Section />}
      {masterIntake && !masterIntake.pathname.endsWith('/review') && <Section />}
      {profile && <Account />}
      {((masterIntake && !masterIntake.pathname.endsWith('/review')) ||
        sectionDashboard ||
        section) && <HistoryLogCommentsThread />}
    </Content>
  );
};

export default ImSpecPages;
