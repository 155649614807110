import request from 'utils/request';

export const CustomerContentService = {
  getContentForm: (subSectionTitle, caseID) => {
    return request('GET', `api/answer/subsections/${subSectionTitle}/visa/${caseID}/questions/`);
  },
  getContentAnswers: (sectionTitle, caseID) => {
    return request('GET', `api/answer/user-answers/${caseID}/section/${sectionTitle}`);
  },
  getMasterIntakeForm: (masterIntakeID, petitionerName) => {
    return request('GET', `api/master-intake/${masterIntakeID}/petitioner/${petitionerName}`);
  },
  getMasterIntakeAnswers: (masterIntakeID, petitionerName) => {
    return request(
      'GET',
      `api/master-intake/${masterIntakeID}/petitioner/${petitionerName}/answers`
    );
  },
  deleteIterationQuestionSet: (caseID, groupName, iterationKey, sectionTitle) => {
    return request('DELETE', `api/answer/${caseID}/${groupName}/${iterationKey}/${sectionTitle}`);
  },
  sendFieldAnswer: (caseID, sectionTitle, answer) => {
    return request('POST', `api/answer/user-answers/${caseID}/section/${sectionTitle}`, answer);
  },
  sendMasterIntakeFieldAnswer: (masterIntakeID, petitionerName, answer) => {
    return request(
      'POST',
      `api/master-intake/${masterIntakeID}/petitioner/${petitionerName}/answers`,
      answer
    );
  },
  deleteFile: (fileID, answerID) => {
    return request('DELETE', `api/files/${fileID}/${answerID}/`);
  },
  deleteMasterIntakeFile: (fileID, answerID) => {
    return request('DELETE', `api/files/master_intake/file/${fileID}/answer/${answerID}/`);
  },
  getAnswerComments: answerID => {
    return request('GET', `api/comments/${answerID}`);
  },
  getMasterIntakeAnswerComments: answerID => {
    return request('GET', `api/comments/master-intake/answer/${answerID}`);
  },
  saveComment: comment => {
    return request('POST', 'api/comments/', comment);
  },
  saveMasterIntakeComment: comment => {
    return request('POST', 'api/comments/master-intake/', comment);
  },
  deleteComment: comment_id => {
    return request('DELETE', `api/comments/delete/${comment_id}/`);
  },
  deleteMasterIntakeComment: comment_id => {
    return request('DELETE', `api/comments/master-intake/delete/comment/${comment_id}/`);
  },
  checkPreviewLink: file_id => {
    return request('GET', `api/files/${file_id}`);
  },
  checkMasterIntakePreviewLink: file_id => {
    return request('GET', `api/files/${file_id}/master_intake`);
  },
  createDependent: (values, caseID, field_name, section_name) => {
    return request(
      'POST',
      `/api/answer/section/${section_name}/dependent/${caseID}/field/${field_name}/`,
      values
    );
  },
  deleteDependent: (data, caseID, field_name, section_name) => {
    return request(
      'DELETE',
      `/api/answer/section/${section_name}/dependent/${caseID}/field/${field_name}/`,
      data
    );
  }
};
