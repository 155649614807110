import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';

// components
import GroupSection from './groupSection';

const Sections = ({ section: { title, group_set }, sectionsAnswers, returnToEdit }) => {
  return (
    <Col className="sections">
      <Row justify="space-between" align="middle" className="section-title">
        <Col>
          <h2 className="section-title_title">{title}</h2>
        </Col>
        <Col>
          <Button
            onClick={() => returnToEdit({ title })}
            className="primary-btn section-title_return-btn"
          >
            Return to edit
          </Button>
        </Col>
      </Row>
      {group_set.map(group => (
        <GroupSection key={group.id} group={group} sectionsAnswers={sectionsAnswers} />
      ))}
    </Col>
  );
};

Sections.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    group_set: PropTypes.array
  }),
  sectionsAnswers: PropTypes.object,
  returnToEdit: PropTypes.func
};

Sections.defaultProps = {
  section: {},
  sectionsAnswers: {}
};

export default Sections;
