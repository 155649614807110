import { useState } from 'react';

import HeadNavbar from 'components/HeadNavbar';
import CustomerSider from 'components/CustomerSider';
import MasterIntakeSider from 'components/MasterIntakeSider';
import CustomerPages from 'pages/CustomerPages';

import { questionMarkIcon } from 'components/Icons/Icons';

import { Button, Layout, Popover } from 'antd';
import { useParams } from 'react-router-dom';
const { Content } = Layout;

const CustomerLayout = () => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { masterIntakeID } = useParams();

  const handlePopoverVisibleChange = () => {
    setPopoverVisible(open => !open);
  };

  const popoverContent = (
    <div className="helpPopoverContent">
      <div className="helpPopoverContent_header">
        <span>Stuck? Something broke?</span>
        <span>Not sure about a question?</span>
      </div>
      <div className="helpPopoverContent_body">
        <p> For assistance, please contact</p>
        <p>
          Customer Success at
          <address>
            {' '}
            <a href="mailto:onboarding@legalpad.io"> onboarding@legalpad.io </a>
          </address>
        </p>
      </div>
    </div>
  );

  return (
    <Layout hasSider className="root-layout">
      {masterIntakeID ? <MasterIntakeSider /> : <CustomerSider />}
      <Content className="page-content-wrapper">
        <HeadNavbar />
        <CustomerPages />
        <div className="floatingHelpBtnWrapper">
          <Popover
            content={popoverContent}
            placement="topRight"
            trigger="click"
            open={popoverVisible}
            onOpenChange={handlePopoverVisibleChange}
            overlayClassName="helpPopover"
          >
            <Button className="floatingHelpBtn">{questionMarkIcon}</Button>
          </Popover>
        </div>
      </Content>
    </Layout>
  );
};

export default CustomerLayout;
