import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';

const DetachRepresentativeModal = ({ visible, onCancel, onDetach, user, petitioner, disabled }) => {
  const Footer = () => {
    return (
      <>
        <Button onClick={!disabled ? onCancel : null} disabled={disabled} className="cancel">
          Cancel
        </Button>
        <Button
          onClick={onDetach}
          disabled={disabled}
          className="detach"
          type={disabled ? 'disabled' : 'primary'}
        >
          Detach
        </Button>
      </>
    );
  };

  return (
    <Modal
      forceRender
      className="assign-modal detach"
      width={414}
      title="Detach Representative"
      centered
      closable={false}
      footer={<Footer />}
      open={visible}
    >
      <hr />
      <h4 className="case-assign">
        Are you sure you want to detach <b>{user && user.full_name}</b> from{' '}
        <b>{petitioner && petitioner.name}</b>?
      </h4>
    </Modal>
  );
};

DetachRepresentativeModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onDetach: PropTypes.func,
  user: PropTypes.object,
  petitioner: PropTypes.object,
  disabled: PropTypes.bool
};

DetachRepresentativeModal.defaultProps = {
  visible: false,
  user: {},
  petitioner: {},
  disabled: false
};

export default DetachRepresentativeModal;
