import PropTypes from 'prop-types';
import { Button, Row } from 'antd';

import { EditOutlined } from '@ant-design/icons';

const PetitionerPageTop = ({ onTitleClick, petitioner }) => {
  return (
    <div className="dataWrapper-top petitioner-top">
      <Row align="middle">
        <span style={{ marginLeft: '20px' }} className="dataWrapper-top-title">
          {petitioner && petitioner.name}
        </span>
        <Button
          type="ghost"
          icon={<EditOutlined />}
          onClick={onTitleClick}
          className="title-edit"
        />
      </Row>
    </div>
  );
};

PetitionerPageTop.propTypes = {
  onTitleClick: PropTypes.func,
  petitioner: PropTypes.object
};

PetitionerPageTop.defaultProps = {
  petitioner: {}
};

export default PetitionerPageTop;
