import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Question from './question';
import Answers from './Answers';

const NestedQuestions = ({
  question: { field_set, children, has_children, title, type },
  sectionsAnswers
}) => {
  const [nestedOpenValue, setNestedOpenValue] = useState(null);

  useEffect(() => {
    field_set.forEach(field => {
      has_children && setNestedOpenValue(sectionsAnswers[field.name]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionsAnswers]);

  const NestedFields = children?.map(question => {
    return (
      question?.nested_open_value === nestedOpenValue && (
        <Question key={question.id} question={question} sectionsAnswers={sectionsAnswers} />
      )
    );
  });

  return (
    <>
      {field_set.map(field => {
        return (
          <Answers
            question={{ title, type }}
            key={field.id}
            field={field}
            answersState={sectionsAnswers}
          />
        );
      })}
      {NestedFields}
    </>
  );
};

NestedQuestions.propTypes = {
  question: PropTypes.shape({
    field_set: PropTypes.array,
    children: PropTypes.array,
    has_children: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.object
  }),
  sectionsAnswers: PropTypes.object
};

NestedQuestions.defaultProps = {
  question: {},
  sectionsAnswers: {}
};

export default NestedQuestions;
