import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import { Button, Checkbox, Form } from 'antd';

const State = ({ state, setShowTooltip }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const selectedList = Form.useWatch('state_options', form);
  Form.useWatch('state_options_all', form);

  const {
    filters: {
      state: { list }
    },
    appliedSavedView: { id: savedViewId, name: savedViewName }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    if (typeof selectedList !== 'undefined') {
      dispatch(ImSpecCaseDashboardBetaAction.setSelectedState(selectedList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    state && !state.length && form.resetFields();
  }, [state, form]);

  const onChange = newList => {
    form.setFieldValue('state_options', newList);
    form.setFieldValue('state_options_all', newList.length === list.length);
    dispatch(ImSpecCaseDashboardBetaAction.setSelectedState(newList));
    dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
    dispatch(ImSpecCaseDashboardBetaAction.applyFilters(savedViewId, savedViewName));
  };

  const onCheckAllChange = e => {
    form.setFieldValue(
      'state_options',
      e.target.checked ? list.map(option => (typeof option === 'object' ? option?.id : option)) : []
    );
    form.setFieldValue('state_options_all', e.target.checked);
    dispatch(
      ImSpecCaseDashboardBetaAction.setSelectedState(
        e.target.checked
          ? list.map(option => (typeof option === 'object' ? option?.id : option))
          : []
      )
    );
    dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
    dispatch(ImSpecCaseDashboardBetaAction.applyFilters(savedViewId, savedViewName));
  };

  return (
    <>
      <Form
        form={form}
        className="checkboxGroup"
        initialValues={{
          state_options: state,
          state_options_all: state?.length === list.length
        }}
      >
        <Form.Item className="checkboxGroup_all" name="state_options_all">
          <Checkbox onChange={onCheckAllChange} checked={form.getFieldValue('state_options_all')}>
            All cases
          </Checkbox>
        </Form.Item>
        <Form.Item name="state_options">
          <Checkbox.Group value={state} onChange={onChange}>
            {list.map((option, index) => (
              <Checkbox key={index} value={option}>
                {option}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Form>
      <Button className="addedSubmitSection" type="ghost" onClick={() => setShowTooltip(false)}>
        Close
      </Button>
    </>
  );
};

State.propTypes = {
  setShowTooltip: PropTypes.func,
  state: PropTypes.array
};

State.defaultProps = {
  state: []
};

export default State;
