export const CustomerActionTypes = {
  // auth
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  REGISTER_REQUEST_OR_LOGIN: 'REGISTER_REQUEST_OR_LOGIN',
  LOG_OUT: 'LOG_OUT',

  INVITED_AUTH_USER_DATA: 'INVITED_AUTH_USER_DATA',
  INVITE_AUTH_LOADING: 'INVITE_AUTH_LOADING',
  // customer sidebar
  SIDEBAR_DATA: 'SIDEBAR_DATA',
  TOGGLE_SIDEBAR_DATA_LOADING: 'TOGGLE_SIDEBAR_DATA_LOADING',
  SET_SELECTED_SIDEBARINFO: 'SET_SELECTED_SIDEBARINFO',
  SET_SIDEBAR_CHILD_CASES: 'SET_SIDEBAR_CHILD_CASES',
  SET_MASTER_INTAKE_SIDEBAR: 'SET_MASTER_INTAKE_SIDEBAR',
  // customer content
  SECTION_CONTENT: 'SECTION_CONTENT',
  SUBSECTION_CONTENT: 'SUBSECTION_CONTENT',
  SUBSECTION_CONTENT_LOADING: 'SUBSECTION_CONTENT_LOADING',
  NEW_ANSWER: 'NEW_ANSWER',
  NEW_ANSWER_LOADING: 'NEW_ANSWER_LOADING',
  ANSWERS_CONTENT: 'ANSWERS_CONTENT',
  ANSWERS_CONTENT_LOADING: 'ANSWERS_CONTENT_LOADING',
  SET_FORM_QUESTIONNAIRE: 'SET_FORM_QUESTIONER',
  SET_CREATE_DEPENDENT_LOADING: 'SET_CREATE_DEPENDENT_LOADING',
  UPDATE_SYNC_SECTION_STATUS: 'UPDATE_SYNC_SECTION_STATUS',
  // customer review section
  SET_REVIEW_ANSWERS: 'SET_REVIEW_ANSWERS',
  REVIEW_ANSWERS_LOADING: 'REVIEW_ANSWERS_LOADING',
  SET_REVIEW_SECTION: 'SET_REVIEW_SECTION',
  REVIEW_SECTION_LOADING: 'REVIEW_SECTION_LOADING',
  // Client Page
  CLIENT_CASE_LIST_LOADING: 'CLIENT_CASE_LIST_LOADING',
  SET_CLIENT_CASE_LIST: 'SET_CLIENT_CASE_LIST',
  BENEFICIARY_TYPES_LOADING: 'BENEFICIARY_TYPES_LOADING',
  SET_BENEFICIARY_TYPES_LIST: 'SET_BENEFICIARY_TYPES_LIST',
  ASSOCIATED_TYPES_LOADING: 'ASSOCIATED_TYPES_LOADING',
  SET_ASSOCIATED_TYPES_LIST: 'SET_ASSOCIATED_TYPES_LIST',
  COMPANIES_LIST: 'COMPANIES_LIST',
  COMPANY_CASE_LIST_LOADING: 'COMPANY_CASE_LIST_LOADING',
  SET_COMPANY_CASE_LIST: 'SET_COMPANY_CASE_LIST',
  SET_CONTACTS: 'SET_CONTACTS',
  SET_CLIENT_PAGE_CHILD_CASES: 'SET_CLIENT_PAGE_CHILD_CASES',
  // Customer comments
  SET_ENTRY_COMMENTS: 'SET_ENTRY_COMMENTS',
  SECTION_SEE_COMMENTS_LOADING: 'SECTION_SEE_COMMENTS_LOADING',
  SECTION_SAVE_COMMENTS_LOADING: 'SECTION_SAVE_COMMENTS_LOADING',
  SECTION_DELETE_ANSWERS_LOADING: 'SECTION_DELETE_ANSWERS_LOADING',
  // Profile Page
  PROFILE_LOADING: 'PROFILE_LOADING',
  SET_PROFILE_CONTENT: 'SET_PROFILE_CONTENT',
  RESET_PASS_LOADING: 'RESET_PASS_LOADING'
};

export const ImSpecActionTypes = {
  IM_SPEC_SIDEBAR_LOADING: 'IM_SPEC_SIDEBAR_LOADING',
  SET_IM_SPEC_SIDEBAR_DATA: 'SET_IM_SPEC_SIDEBAR_DATA',
  SET_SELECTED_SIDEBARINFO: 'SET_SELECTED_SIDEBARINFO',
  SET_ENTRY_LOGS: 'SET_ENTRY_LOGS',
  ENTRY_LOGS_LOADING: 'ENTRY_LOGS_LOADING',
  SET_IM_SPEC_CONTACTS: 'SET_IM_SPEC_CONTACTS',
  CLIENT_USER_DETAILS_SIDEBAR: 'CLIENT_USER_DETAILS',
  SET_IM_SPEC_VISA_TYPE: 'SET_IM_SPEC_VISA_TYPE',
  SET_IM_SPEC_SIDEBAR_CHILD_CASES: 'SET_IM_SPEC_SIDEBAR_CHILD_CASES',
  SET_FORM_QUESTIONNAIRE: 'SET_FORM_QUESTIONNAIRE',
  // Comments
  SET_ENTRY_COMMENTS: 'SET_ENTRY_COMMENTS',
  IM_SPEC_SECTION_SEE_COMMENTS_LOADING: 'IM_SPEC_SECTION_SEE_COMMENTS_LOADING',
  IM_SPEC_SECTION_SAVE_COMMENTS_LOADING: 'IM_SPEC_SECTION_SAVE_COMMENTS_LOADING',
  IM_SPEC_SECTION_DELETE_ANSWERS_LOADING: 'IM_SPEC_SECTION_DELETE_ANSWERS_LOADING',
  // IS content
  IM_SPEC_SECTION_CONTENT_LOADING: 'IM_SPEC_SECTION_CONTENT_LOADING',
  IM_SPEC_SECTION_ANSWERS_LOADING: 'IM_SPEC_SECTION_ANSWERS_LOADING',
  IM_SPEC_SECTION_SEND_ANSWERS_LOADING: 'IM_SPEC_SECTION_SEND_ANSWERS_LOADING',
  SET_IM_SPEC_SECTION_CONTENT: 'SET_IM_SPEC_SECTION_CONTENT',
  SET_IM_SPEC_ANSWERS_CONTENT: 'SET_IM_SPEC_ANSWERS_CONTENT',
  GET_IM_SPEC_HISTORY_LOADING: 'GET_IM_SPEC_HISTORY_LOADING',
  GET_IM_SPEC_HISTORY_LOG: 'GET_IM_SPEC_HISTORY_LOG',
  STATUSES_LOADING: 'STATUSES_LOADING',
  STATES_LOADING: 'STATES_LOADING',
  SET_STATUSES: 'SET_STATUSES',
  SET_STATES: 'SET_STATES',
  SET_CHILD_CASE_INFO_CONTENT: 'SET_CHILD_CASE_INFO_CONTENT',
  SET_CREATE_DEPENDENT_LOADING: 'SET_CREATE_DEPENDENT_LOADING',
  UPDATE_SYNC_SECTION_STATUS: 'UPDATE_SYNC_SECTION_STATUS',
  // Section dashboard
  CASES_LOADING: 'CASES_LOADING',
  SET_CASES: 'SET_CASES',
  ASSOCIATED_ACCOUNTS_LOADING: 'ASSOCIATED_ACCOUNTS_LOADING',
  SET_ASSOCIATED_ACCOUNTS: 'SET_ASSOCIATED_ACCOUNTS',
  SET_SEARCH_USERS: 'SET_SEARCH_USERS',
  SET_SEARCH_USERS_LOADING: 'SET_SEARCH_USERS_LOADING',
  SET_BENEFICIARY: 'SET_BENEFICIARY',
  SET_SECTION_DASHBOARD_CHILD_CASES: 'SET_SECTION_DASHBOARD_CHILD_CASES',

  SET_ENTRY_STATUS: 'SET_ENTRY_STATUS',
  SET_ENTRY_STATE: 'SET_ENTRY_STATE',
  SET_PAGE: 'SET_PAGE',
  SET_ASSIGN: 'SET_ASSIGN',
  SET_CASE_SPECIALISTS: 'SET_CASE_SPECIALISTS',
  CASE_SPECIALISTS_LOADING: 'CASE_SPECIALISTS_LOADING',
  // Client Management
  // Invitation
  SET_INVITE_CLIENT_FORM_DATA_CASES: 'SET_INVITE_CLIENT_FORM_DATA_CASES',
  SET_INVITE_CLIENT_FORM_DATA_PETITIONS: 'SET_INVITE_CLIENT_FORM_DATA_PETITIONS',
  INVITE_CLIENT_FORM_LOADING: 'INVITE_CLIENT_FORM_LOADING',
  INVITED_CLIENT_INFO: 'INVITED_CLIENT_INFO',
  INVITE_CLIENT_LOADING: 'INVITE_CLIENT_LOADING',
  SET_PRINCIPAL_CASES: 'SET_PRINCIPAL_CASES',
  // Table
  CLIENTS_TABLE_DATA: 'CLIENTS_TABLE_DATA',
  CLIENTS_TABLE_LOADING: 'CLIENTS_TABLE_LOADING',
  NESTED_CLIENTS_TABLE_DATA: 'NESTED_CLIENTS_TABLE_DATA',
  NESTED_CLIENTS_TABLE_LOADING: 'NESTED_CLIENTS_TABLE_LOADING',
  CLIENTS_TABLE_SET_PAGE: 'CLIENTS_TABLE_SET_PAGE',
  CLIENTS_TABLE_SET_PAGE_SIZE: 'CLIENTS_TABLE_SET_PAGE_SIZE',
  RESEND_LOADING: 'RESEND_LOADING',
  CANCEL_LOADING: 'CANCEL_LOADING',
  // Client Page CLIENT
  CLIENT_USER_DETAILS: 'CLIENT_USER_DETAILS',
  CLIENT_USER_DETAILS_LOADING: 'CLIENT_USER_DETAILS_LOADING',
  CLIENT_CASE_LIST_LOADING: 'CLIENT_CASE_LIST_LOADING',
  SET_CLIENT_CASE_LIST: 'SET_CLIENT_CASE_LIST',
  BENEFICIARY_TYPES_LOADING: 'BENEFICIARY_TYPES_LOADING',
  SET_BENEFICIARY_TYPES_LIST: 'SET_BENEFICIARY_TYPES_LIST',
  ASSOCIATED_TYPES_LOADING: 'ASSOCIATED_TYPES_LOADING',
  SET_ASSOCIATED_TYPES_LIST: 'SET_ASSOCIATED_TYPES_LIST',
  SET_RESEND_ITEMS: 'SET_RESEND_ITEMS',
  RESEND_ITEMS_LOADING: 'RESEND_ITEMS_LOADING',
  COMPANIES_LIST: 'COMPANIES_LIST',
  COMPANY_CASE_LIST_LOADING: 'COMPANY_CASE_LIST_LOADING',
  SET_COMPANY_CASE_LIST: 'SET_COMPANY_CASE_LIST',
  SET_CLIENT_PAGE_CHILD_CASES: 'SET_CLIENT_PAGE_CHILD_CASES',

  // Case Dashboard
  CASE_LIST_LOADING: 'CASE_LIST_LOADING',
  SET_CASES_PAGE_SIZE: 'SET_CASES_PAGE_SIZE',
  SET_CASE_LIST: 'SET_CASE_LIST',
  SET_SEARCH_RESULT: 'SET_SEARCH_RESULT',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',

  // Case Dashboard Beta
  DRAWER_LOADING: 'DRAWER_LOADING',

  // filter data
  SET_CASE_TYPES: 'SET_CASE_TYPES',
  SET_STATES_DATA: 'SET_STATES_DATA',
  SET_STATUSES_DATA: 'SET_STATUSES_DATA',
  SET_PETITIONERS_DATA: 'SET_PETITIONERS_DATA',
  SET_IM_SPEC_DATA: 'SET_IM_SPEC_DATA',
  SET_ATTORNEY_DATA: 'SET_ATTORNEY_DATA',
  SET_SOURCE_DATA: 'SET_SOURCE_DATA',
  BENEFICIARY_LOADING: 'BENEFICIARY_LOADING',
  SET_BENEFICIARY_DATA: 'SET_BENEFICIARY_DATA',
  CASE_ID_LOADING: 'CASE_ID_LOADING',
  SET_CASE_ID_DATA: 'SET_CASE_ID_DATA',

  // filter answers
  CLEAR_FILTERS: 'CLEAR_FILTERS',
  ADD_ALL_FILTERS: 'ADD_ALL_FILTERS',
  SET_SAVED_VIEW_LIST: 'SET_SAVED_VIEW_LIST',
  SET_SAVED_VIEW_NAME: 'SET_SAVED_VIEW_NAME',
  RENAME_SAVED_VIEW_NAME: 'RENAME_SAVED_VIEW_NAME',
  SET_SAVE_FILTERS: 'SET_SAVE_FILTERS',
  SET_SAVE_FILTERS_LOADING: 'SET_SAVE_FILTERS_LOADING',
  SET_APPLY_FILTERS: 'SET_APPLY_FILTERS',
  SET_APPLY_FILTERS_LOADING: 'SET_APPLY_FILTERS_LOADING',
  SET_SELECTED_CASE_TYPES: 'SET_SELECTED_CASE_TYPES',
  SET_SELECTED_STATES_DATA: 'SET_SELECTED_STATES_DATA',
  SET_LAST_UPDATE: 'SET_LAST_UPDATE',
  SET_SELECTED_PETITIONERS_DATA: 'SET_SELECTED_PETITIONERS_DATA',
  SET_SELECTED_STATUSES_DATA: 'SET_SELECTED_STATUSES_DATA',
  SET_SELECTED_BENEFICIARY_DATA: 'SET_SELECTED_BENEFICIARY_DATA',
  SET_SELECTED_IM_SPEC_DATA: 'SET_SELECTED_IM_SPEC_DATA',
  SET_SELECTED_ATTORNEY_DATA: 'SET_SELECTED_ATTORNEY_DATA',
  SET_SELECTED_CASE_ID_DATA: 'SET_SELECTED_CASE_ID_DATA',
  SET_SELECTED_SOURCE_DATA: 'SET_SELECTED_SOURCE_DATA',
  SET_INCLUDE_TEST_CASES: 'SET_INCLUDE_TEST_CASES',

  // Petitioner Dashboard
  PETITIONERS_LIST_LOADING: 'PETITIONERS_LIST_LOADING',
  SET_PETITIONERS: 'SET_PETITIONERS',
  SET_PETITIONERS_PAGE: 'SET_PETITIONERS_PAGE',
  SET_PETITIONERS_PAGE_SIZE: 'SET_PETITIONERS_PAGE_SIZE',
  ENTRY_PETITIONER_CASES_LOADING: 'ENTRY_PETITIONER_CASES_LOADING',
  SET_ENTRY_PETITIONER_CASES: 'SET_ENTRY_PETITIONER_CASES',

  // Petitioner Page
  PETITIONER_CASES_LOADING: 'PETITIONER_CASES_LOADING',
  SET_PETITIONER_CASES: 'SET_PETITIONER_CASES',
  REPRESENTATIVES_LOADING: 'REPRESENTATIVES_LOADING',
  SET_REPRESENTATIVES: 'SET_REPRESENTATIVES',
  SET_PETITIONER_DETAILS: 'SET_PETITIONER_DETAILS',
  SET_SEARCH_USERS_LOADING_REPRESENTATIVE: 'SET_SEARCH_USERS_LOADING_REPRESENTATIVE',
  SET_SEARCH_USERS_REPRESENTATIVE: 'SET_SEARCH_USERS_REPRESENTATIVE',
  SET_COMPANY_MASTER_INTAKES: 'SET_COMPANY_MASTER_INTAKES',
  SET_COMPANY_MASTER_INTAKES_LOADING: 'SET_COMPANY_MASTER_INTAKES_LOADING',

  // Profile Page
  PROFILE_LOADING: 'PROFILE_LOADING',
  SET_PROFILE_CONTENT: 'SET_PROFILE_CONTENT',
  RESET_PASS_LOADING: 'RESET_PASS_LOADING'
};

export const HistoryLogCommentsThreadTypes = {
  SET_SCROLL_Y: 'SET_SCROLL_Y',
  SET_OPEN: 'SET_OPEN',
  SET_ACTIVE_THREAD_TAB: 'SET_ACTIVE_THREAD_TAB',
  SET_CASE_SECTIONS: 'SET_CASE_SECTIONS',
  SET_SECTIONS_FILTER_KEY: 'SET_SECTIONS_FILTER_KEY',
  SET_LOGS_LOADING: 'SET_LOGS_LOADING',
  SET_LOGS_LOADING_INFINITE: 'SET_LOGS_LOADING_INFINITE',
  SET_LOGS_VIEW_ACTIVE_TAB: 'SET_LOGS_VIEW_ACTIVE_TAB',
  SET_LOGS: 'SET_LOGS',
  SET_LOGS_PAGE: 'SET_LOGS_PAGE',
  QUESTION_TITLE_LOADING: 'QUESTION_TITLE_LOADING',
  SET_QUESTION_TITLE: 'SET_QUESTION_TITLE',
  SET_COMMENTS_LOADING: 'SET_COMMENTS_LOADING',
  SET_COMMENTS_LOADING_INFINITE: 'SET_COMMENTS_LOADING_INFINITE',
  SET_COMMENTS: 'SET_COMMENTS',
  SET_COMMENTS_VIEW_ACTIVE_TAB: 'SET_COMMENTS_VIEW_ACTIVE_TAB',
  SET_COMMENTS_PAGE: 'SET_COMMENTS_PAGE',
  SET_COMMENTS_TABS: 'SET_COMMENTS_TABS',
  SET_DELETED_COMMENT: 'SET_DELETED_COMMENT',
  SET_SENT_COMMENT_ANSWER_ID: 'SET_SENT_COMMENT_ANSWER_ID',
  SEND_COMMENT_LOADING: 'SEND_COMMENT_LOADING'
};
