import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Input } from 'antd';
const { TextArea } = Input;

const LongText = ({
  setRequiredErrorClassName,
  curSubsection,
  answersDataState,
  answers,
  curField,
  iterationName,
  questionSet,
  setSavingValue,
  setShowRequiredError,
  showRequiredError,
  onFocus,
  onBlur,
  radioDisable,
  inputRef,
  sync_info
}) => {
  const [floatingValue, setFloatingValue] = useState('');
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    focus && inputRef.current.focus();
  });

  useEffect(() => {
    if (!!answersDataState && Object.keys(answersDataState).length) {
      setFloatingValue(answersDataState[curField.name]?.field_value);
    }
  }, [answersDataState, curField]);

  useEffect(() => {
    setRequiredErrorClassName(
      curField.is_required && showRequiredError && !floatingValue ? 'required-error-block' : ''
    );
  }, [curField.is_required, floatingValue, setRequiredErrorClassName, showRequiredError]);

  const onChangeLongText = e => {
    const { name, value } = e.target;
    setFloatingValue(value);

    const formData = new FormData();
    formData.append('field_name', name);
    formData.append('field_value', value);
    formData.append('field_type', curField.type.type);
    formData.append('group_name', !!iterationName ? iterationName : '');
    questionSet &&
      !questionSet.startsWith('uId') &&
      formData.append(
        'iteration_key',
        !!answers[iterationName]?.field_value || !questionSet.startsWith('uId')
          ? answers[iterationName]?.field_value[questionSet][name]?.iteration_key
          : null
      );
    setSavingValue(formData);

    curField.is_required && !value.trim().length
      ? setShowRequiredError(true)
      : setShowRequiredError(false);
  };

  return (
    <div className="longText" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      <TextArea
        ref={inputRef}
        id={curField?.id}
        style={{
          cursor: curSubsection?.is_blocked || radioDisable ? 'not-allowed' : 'initial'
        }}
        name={curField.name}
        value={floatingValue || ''}
        onChange={onChangeLongText}
        maxLength={2500}
        showCount
        placeholder={`${curField?.placeholder}${curField.is_required ? '*' : ''}`}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={sync_info.sync_in_progress || curSubsection?.is_blocked || radioDisable}
      />
    </div>
  );
};

LongText.propTypes = {
  setRequiredErrorClassName: PropTypes.func,
  curSubsection: PropTypes.object,
  answersDataState: PropTypes.object,
  answers: PropTypes.object,
  curField: PropTypes.object,
  iterationName: PropTypes.string,
  questionSet: PropTypes.string,
  setSavingValue: PropTypes.func,
  setShowRequiredError: PropTypes.func,
  showRequiredError: PropTypes.bool,
  radioDisable: PropTypes.bool,
  inputRef: PropTypes.object,
  sync_info: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

LongText.defaultProps = {
  curSubsection: {},
  answersDataState: {},
  answers: {},
  curField: {},
  iterationName: '',
  questionSet: '',
  showRequiredError: false,
  radioDisable: false,
  inputRef: {},
  sync_info: {}
};

export default LongText;
