import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useQuery from 'hooks/useQuery';

import { Questions } from './Questions';

import { Col } from 'antd';

const Grouped = ({ curContent, curGroupContent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const myRef = useRef(null);
  const { group } = useQuery();

  const {
    ImSpecContent: { answersLoading }
  } = useSelector(state => ({ ImSpecContent: state.ImSpecContent }));

  useEffect(() => {
    if (group && myRef && myRef.current && group === curGroupContent.title.replaceAll(' ', '-')) {
      !answersLoading && myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [answersLoading, curGroupContent.title, group, location.pathname, myRef, navigate]);

  return (
    <Col ref={myRef} className="mainContent cloud">
      <div className="groupName">
        <h3>{curGroupContent.title}</h3>
      </div>
      {curGroupContent.question_set.map((question, index) => (
        <Questions
          curSubSection={curContent}
          key={question.id}
          curGroup={curGroupContent}
          index={index}
          curQuestionContent={question}
        />
      ))}
    </Col>
  );
};

Grouped.propTypes = {
  curContent: PropTypes.object,
  curGroupContent: PropTypes.object
};

Grouped.defaultProps = {
  curContent: {},
  curGroupContent: {}
};

export default Grouped;
