import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerClientPageActions } from 'store/Actions';

import useQuery from 'hooks/useQuery';

import CompanySection from 'components/ClientPage/CompanySection';
import BeneficiarySection from 'components/ClientPage/BeneficiarySection';
import AssociatedSection from 'components/ClientPage/AssociatedSection';

import { Col } from 'antd';

const ClientPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { caseID, petitionerID } = useQuery();

  const { user } = useSelector(state => state.auth);

  const {
    customerClientPage: {
      beneficiaryCases: { beneficiaryList },
      company: { companiesList, isLoading: companiesListLoading },
      companyCases: { companyCasesList, isLoading: companyCasesListLoading },
      associatedCases: { associatedList },
      case: { list, child_cases, isLoading: casesLoading },
      contacts
    },
    auth: {
      user: { role, first_name, last_name, is_test }
    }
  } = useSelector(state => ({
    customerClientPage: state.customerClientPage,
    auth: state.auth
  }));

  const [curActiveCase, setActiveCurCase] = useState([]);
  const [curActiveCompany, setActiveCurCompany] = useState([]);

  useEffect(() => {
    const getCaseTypesData = async () => {
      await dispatch(CustomerClientPageActions.getBeneficiaryTypes());
      await dispatch(CustomerClientPageActions.getAssociatedTypes());
      await dispatch(CustomerClientPageActions.getCompanies());
    };
    getCaseTypesData();
  }, [dispatch]);

  useEffect(() => {
    setActiveCurCase([caseID]);
  }, [caseID]);

  useEffect(() => {
    caseID && dispatch(CustomerClientPageActions.getCaseList(caseID, navigate));
  }, [dispatch, caseID, navigate]);

  useEffect(() => {
    setActiveCurCompany([petitionerID]);
  }, [petitionerID]);

  useEffect(() => {
    petitionerID && dispatch(CustomerClientPageActions.getCompanyCases(petitionerID));
  }, [dispatch, petitionerID, navigate]);

  const getCurrentCase = curCase => {
    setActiveCurCase([curCase[curCase.length - 1]]);
    !!curCase.length
      ? navigate(`/your-questionnaires?caseID=${curCase[curCase.length - 1]}`)
      : navigate('/your-questionnaires');
  };

  const getCurrentCompany = curCompany => {
    setActiveCurCompany([curCompany[curCompany.length - 1]]);
    !!curCompany.length
      ? navigate(`/your-questionnaires?petitionerID=${curCompany[curCompany.length - 1]}`)
      : navigate('/your-questionnaires');
  };

  const onRowClick = useCallback(
    record => {
      navigate(
        `/master-intake/${record.id}/${record.petitioner_name.replaceAll(
          ' ',
          '-'
        )}/${record.subsection_name.replaceAll(' ', '-')}`
      );
    },
    [navigate]
  );

  return (
    <Col className="case-dashboard">
      <div className="header-wrapper">
        {is_test && (
          <div className="test-account-tag">
            <div className="circle" />
            <span>Test account</span>
          </div>
        )}
        <h2 className="case-dashboard-user">
          {first_name} {last_name}
        </h2>
      </div>
      <Col>
        {!!companiesList.length && (
          <CompanySection
            companiesList={companiesList}
            companyCasesList={companyCasesList}
            curActiveCompany={curActiveCompany}
            getCurrentCompany={getCurrentCompany}
            companyCasesListLoading={companyCasesListLoading}
            companiesListLoading={companiesListLoading}
            role={role}
            onRowClick={onRowClick}
          />
        )}
        {!!beneficiaryList.length && (
          <BeneficiarySection
            curActiveCase={curActiveCase}
            getCurrentCase={getCurrentCase}
            beneficiaryList={beneficiaryList}
            contacts={contacts}
            child_cases={child_cases}
            casesLoading={casesLoading}
            userDetails={user}
            list={list}
            role={role}
          />
        )}
        {!!associatedList.length && (
          <AssociatedSection
            curActiveCase={curActiveCase}
            getCurrentCase={getCurrentCase}
            associatedList={associatedList}
            contacts={contacts}
            child_cases={child_cases}
            casesLoading={casesLoading}
            list={list}
            role={role}
          />
        )}
      </Col>
    </Col>
  );
};

export default ClientPage;
