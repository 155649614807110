import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation, Link, useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from 'hooks/useQuery';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { CustomerSidebarAction, CustomerContentAction } from 'store/Actions';

import { CUSTOMER_ACCOUNT_PROFILE_SIDEBAR_ITEMS, CUSTOMER_SIDEBAR_ITEMS } from 'utils/constants';
import { sectionAndSubsectionNames } from 'utils/helper';

import { legalPadIconWithoutText, legalPadIconText, backIcon } from 'components/Icons/Icons';

import { Layout, Menu } from 'antd';

import SectionIcon from 'components/Icons/SectionIcon';
import SidebarTabIcons from 'components/Icons/CustomerSideIcons';
import ChildCasesMenu from 'components/ImSpecSider/elements/ChildCasesMenu';
import { GlobalOutlined } from '@ant-design/icons';

const { Sider } = Layout;

const CustomerSider = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { group } = useQuery();

  const section = useMatch('/your-questionnaires/:caseID/:sectionTitle/:subSectionTitle');
  const accountProfile = useMatch('/account-profile');
  const hideChildCases = useFlag('hide-child-cases');
  const { flagsReady } = useFlagsStatus();

  const { caseID, sectionTitle, subSectionTitle } = useParams();

  const {
    customerSidebar: {
      sidebarInfo: { visaType, selected_sidebar, child_cases },
      loading
    },
    customerSectionsReview: {
      content: { section: reviewSection }
    },
    user: { user }
  } = useSelector(state => ({
    customerSidebar: state.customerSidebar,
    customerSectionsReview: state.customerSectionsReview,
    user: state.auth
  }));

  const [openSubs, setOpenSubs] = useState([]);
  const [sectionOpen, setSectionOpen] = useState([]);
  const [subSectionOpen, setSubSectionOpen] = useState([]);
  const [groupOpen, setGroupOpen] = useState([]);
  const [everOpenedSubsection, setEverOpenedSubsection] = useState(
    JSON.parse(localStorage.getItem(`${user.email}`)) || []
  );

  useEffect(() => {
    const backPageFromSubSectionContent = () => {
      dispatch(CustomerSidebarAction.setSelectedSidebarInfo([]));
      dispatch(CustomerSidebarAction.setChildCases([]));
      dispatch(CustomerContentAction.setCustomerContent({}));
      dispatch(CustomerContentAction.setContentAnswers({}));
    };

    if (section && section.pathname.endsWith('/review')) {
      backPageFromSubSectionContent();
    }
  }, [caseID, dispatch, navigate, section, selected_sidebar.length]);

  useEffect(() => {
    section &&
      !section.pathname.endsWith('/review') &&
      caseID &&
      dispatch(CustomerSidebarAction.getCaseTypes(caseID));
  }, [caseID, dispatch, section]);

  useEffect(() => {
    if (!section) {
      dispatch(CustomerSidebarAction.setSelectedSidebarInfo([]));
      dispatch(CustomerSidebarAction.sendSidebarData({}));
      dispatch(CustomerSidebarAction.setChildCases([]));
      dispatch(CustomerContentAction.setCustomerContent({}));
      dispatch(CustomerContentAction.setContentAnswers({}));
    }
  }, [dispatch, section]);

  useEffect(() => {
    const openSubs = async (sectionTitle, subSectionTitle, group) => {
      setGroupOpen(group && visaType.case_id === caseID ? [sectionAndSubsectionNames(group)] : []);
      await setSectionOpen(
        visaType.case_id === caseID ? [sectionAndSubsectionNames(sectionTitle)] : []
      );
      await setSubSectionOpen(
        visaType.case_id === caseID ? [sectionAndSubsectionNames(subSectionTitle)] : []
      );
    };
    if (sectionTitle && subSectionTitle) {
      openSubs(sectionTitle, subSectionTitle, group);
    }
  }, [caseID, group, sectionTitle, subSectionTitle, visaType.case_id]);

  useEffect(() => {
    setOpenSubs([...sectionOpen, ...subSectionOpen, ...groupOpen]);
  }, [sectionOpen, subSectionOpen, groupOpen]);

  const goSubsection = (sectionTitle, subSectionTitle) => {
    navigate(
      `/your-questionnaires/${visaType.case_id}/${sectionTitle.replaceAll(
        ' ',
        '-'
      )}/${subSectionTitle.replaceAll(' ', '-')}`
    );
  };

  const onSelect = ({ key }) => {
    if (key === 'back') {
      goBackDashboard();
    }

    if (key === 'legalpad') {
      setGroupOpen([]);
      setSectionOpen([]);
      setSubSectionOpen([]);
      setOpenSubs([]);
      dispatch(CustomerSidebarAction.setSelectedSidebarInfo([]));
      dispatch(CustomerSidebarAction.sendSidebarData({}));
      dispatch(CustomerSidebarAction.setChildCases([]));
      dispatch(CustomerContentAction.setCustomerContent({}));
      dispatch(CustomerContentAction.setContentAnswers({}));
      navigate('/dashboard');
    }
  };

  const CustomerSidebarItems = CUSTOMER_SIDEBAR_ITEMS.map(
    ({ id, path, className, value, icon }) => {
      return {
        label: <Link to={path}>{value}</Link>,
        className,
        icon: <SidebarTabIcons name={icon} />,
        key: id,
        id: value
      };
    }
  );

  const CustomerSidebarAccountProfileItems = CUSTOMER_ACCOUNT_PROFILE_SIDEBAR_ITEMS.map(
    ({ id, path, className, value, icon }) => {
      return {
        label: <Link to={path}>{value}</Link>,
        className,
        icon: <SidebarTabIcons name={icon} />,
        key: id,
        id: value
      };
    }
  );

  const currentKey = useMemo(() => {
    let items = accountProfile ? CUSTOMER_ACCOUNT_PROFILE_SIDEBAR_ITEMS : CUSTOMER_SIDEBAR_ITEMS;
    return items
      .find(item => {
        if (location.pathname.includes(item.path)) return item;
      })
      ?.id.toString();
  }, [accountProfile, location.pathname]);

  const openSubSection = ({ sectionTitle, subSectionID, subSectionTitle }) => {
    if (everOpenedSubsection.indexOf(subSectionID) === -1) {
      setEverOpenedSubsection(prevState => [...prevState, subSectionID]);
    }
    localStorage.setItem(`${user.email}`, JSON.stringify(everOpenedSubsection));

    setSubSectionOpen(state => {
      if (state.includes(subSectionTitle)) {
        state.splice(state.indexOf(subSectionTitle), 1);
        return [...state];
      } else {
        return [subSectionTitle];
      }
    });
    setGroupOpen([]);
    goSubsection(sectionTitle, subSectionTitle);
  };

  const onClickSections = ({ key }) => {
    setSectionOpen(prevState => {
      if (prevState.includes(key)) {
        prevState.splice(prevState.indexOf(key), 1);
        return [...prevState];
      } else {
        return [...prevState, key];
      }
    });
  };

  const onCLickSubSectionsGroup = groupTitle => {
    navigate(`${location.pathname}?group=${groupTitle.replaceAll(' ', '-')}`);
  };

  const caseSections = selected_sidebar.map(
    ({ title: sectionTitle = '', icon = '', subsection_set = [] }) => {
      return subsection_set.length !== 0
        ? {
            label: sectionTitle,
            key: sectionTitle,
            className: `visaType-pages ${openSubs.includes(sectionTitle) && 'active-section'}`,
            popupClassName: 'sub-pop-up',
            onTitleClick: onClickSections,
            icon: <SectionIcon name={icon} />,
            children: [
              ...subsection_set.map(({ group_set = [], title: subTitle = '', id }) => {
                return group_set.length !== 0
                  ? {
                      label: subTitle,
                      key: subTitle,
                      className: `sub-sections ${
                        openSubs.includes(subTitle) && 'active-subsection'
                      }`,
                      popupClassName: 'sub-pop-up',
                      onTitleClick: ({ key }) =>
                        openSubSection({
                          sectionTitle: sectionTitle,
                          subSectionID: id,
                          subSectionTitle: key
                        }),
                      children: [
                        ...group_set.map(({ title: groupTitle = '' }) => {
                          return {
                            label: groupTitle,
                            key: groupTitle,
                            className: `group ${openSubs.includes(groupTitle) && 'active-group'}`,
                            onClick: () => onCLickSubSectionsGroup(groupTitle)
                          };
                        })
                      ]
                    }
                  : {
                      label: subTitle,
                      key: subTitle,
                      className: `sub-sections-item ${
                        openSubs.includes(subTitle) && 'active-subsection-item'
                      }`,
                      onClick: ({ key }) =>
                        openSubSection({
                          sectionTitle: sectionTitle,
                          subSectionID: id,
                          subSectionTitle: key
                        })
                    };
              })
            ]
          }
        : {
            label: sectionTitle,
            key: sectionTitle,
            className: 'visaType-pages',
            icon: <SectionIcon name={icon} />,
            onClick: onClickSections
          };
    }
  );

  const goBackDashboard = () => {
    if (location.pathname.includes('review')) {
      setSectionOpen([]);
      setSubSectionOpen([]);
      setOpenSubs([]);
      dispatch(CustomerSidebarAction.setSelectedSidebarInfo([]));
      dispatch(CustomerSidebarAction.setChildCases([]));
      dispatch(CustomerContentAction.setCustomerContent({}));
      dispatch(CustomerContentAction.setContentAnswers({}));
      navigate(-1);
    }

    if (caseID && !sectionTitle && !subSectionTitle) {
      navigate('/dashboard');
    }

    if (accountProfile) {
      navigate('/dashboard');
    }

    if (caseID && sectionTitle && subSectionTitle) {
      setSectionOpen([]);
      setGroupOpen([]);
      setSubSectionOpen([]);
      setOpenSubs([]);
      dispatch(CustomerSidebarAction.setSelectedSidebarInfo([]));
      dispatch(CustomerSidebarAction.setChildCases([]));
      dispatch(CustomerContentAction.setCustomerContent({}));
      dispatch(CustomerContentAction.setContentAnswers({}));
      navigate(`/your-questionnaires?caseID=${visaType.case_id}`);
    }
  };

  const ChildCases = useMemo(() => {
    return flagsReady && !hideChildCases && child_cases.length ? (
      <ChildCasesMenu visaType={visaType} child_cases={child_cases} loading={loading} />
    ) : null;
  }, [child_cases, flagsReady, hideChildCases, loading, visaType]);

  return (
    <Sider style={{ position: 'fixed', height: '100%' }} width={340} className="user-sidebar">
      <Menu
        mode="inline"
        className="ImSpec_Menu"
        onClick={onSelect}
        selectedKeys={[]}
        items={[
          {
            label: legalPadIconText,
            key: 'legalpad',
            className: 'ImSpec-sidebar-logo',
            icon: legalPadIconWithoutText
          },
          ...(section || accountProfile
            ? [
                {
                  label: 'Back',
                  key: 'back',
                  icon: backIcon,
                  className: 'backBtn'
                }
              ]
            : [])
        ]}
      />
      {section && !section.pathname.endsWith('/review') && !!visaType.visa_name && (
        <div className="user-details-sidebar">
          <div id="VisaTypes" className="visaTypesBlock" key={visaType.case_id}>
            <GlobalOutlined />
            <span>
              {visaType.visa_name} {visaType.dependent || null}
            </span>
          </div>
          {visaType.is_test && (
            <div className="test-account-tag">
              <div className="circle" />
              <span>Test case</span>
            </div>
          )}
        </div>
      )}
      {section && section.pathname.endsWith('/review') && !!reviewSection.visa_name && (
        <div id="VisaTypes" className="visaTypesBlock">
          <GlobalOutlined />
          <span>
            {reviewSection.visa_name} {reviewSection.dependent || null}
          </span>
        </div>
      )}
      <div className="menu-scroll">
        <Menu
          mode="inline"
          className="ImSpec_Menu case-section-menu"
          onClick={onSelect}
          openKeys={openSubs}
          selectedKeys={[currentKey]}
          items={[
            ...(!accountProfile && !section ? [...CustomerSidebarItems] : []),
            ...(section && !section.pathname.endsWith('/review') && !!selected_sidebar.length
              ? caseSections
              : []),
            ...(accountProfile ? CustomerSidebarAccountProfileItems : [])
          ]}
        />
        {ChildCases}
      </div>
    </Sider>
  );
};

export default CustomerSider;
