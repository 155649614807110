import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import useQuery from 'hooks/useQuery';

import { Col, Tabs } from 'antd';

// components
import CaseDashboardTop from 'pages/ImSpecPages/CaseDashboard/elements/CaseDashboardTop';
import CaseDashboardTable from 'pages/ImSpecPages/CaseDashboard/elements/CaseDashboardTable';
import AssignModal from 'components/shared/Modals/AssignModal';

// actions
import { ImSpecCaseDashboardAction } from 'store/Actions';

// constants
import { STATE_TABS } from 'utils/constants';

const ImSpecCaseDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useQuery();

  const {
    states: { list: states },
    statuses: { list: statuses },
    cases: { list: cases, isLoading: loadingCases, count, page, pageSize, pageSizeOptions },
    filterCases: { assign, status, state },
    caseSpecialists,
    searchCases
  } = useSelector(state => state.ImSpecCaseDashboard);
  const [specialist, setSpecialist] = useState(null);

  useEffect(() => {
    const getInitialSearchData = async () => {
      dispatch(ImSpecCaseDashboardAction.setEntryAssigned('all'));
      dispatch(ImSpecCaseDashboardAction.setEntryState('All'));
      await dispatch(ImSpecCaseDashboardAction.getStates(search));
      await dispatch(ImSpecCaseDashboardAction.getStatuses());
      await dispatch(ImSpecCaseDashboardAction.getCasesTableData(search));
    };
    const getInitialData = async () => {
      await dispatch(ImSpecCaseDashboardAction.getStates());
      await dispatch(ImSpecCaseDashboardAction.getStatuses());
      await dispatch(ImSpecCaseDashboardAction.getCasesTableData());
    };
    search ? getInitialSearchData() : getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search]);

  useEffect(() => {
    return () => {
      dispatch(ImSpecCaseDashboardAction.setSearchQuery(null));
      dispatch(
        ImSpecCaseDashboardAction.setSearchResult({
          results: [],
          count: 0,
          hasData: null
        })
      );
      dispatch(ImSpecCaseDashboardAction.setPage(1));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeStatuses = status => {
    dispatch(ImSpecCaseDashboardAction.onChangeStatuses(status));
  };

  const onChangeStates = state => {
    dispatch(ImSpecCaseDashboardAction.onChangeStates(state));
  };

  const onChangePage = (page, pageSize) => {
    dispatch(ImSpecCaseDashboardAction.onChangePage(page, pageSize, search));
  };

  const onChangeAssignSelect = value => {
    dispatch(ImSpecCaseDashboardAction.onChangeAssign(value, search));
  };

  const onClickAssign = ({ case_id, im_spec, attorney }) => {
    if (!!im_spec) {
      setSpecialist(Object.keys(im_spec).length ? im_spec : null);
      navigate(`${location.pathname}?case_id=${case_id}&assign=im_spec`);
    }
    if (!!attorney) {
      setSpecialist(Object.keys(attorney).length ? attorney : null);
      !!attorney && navigate(`${location.pathname}?case_id=${case_id}&assign=attorney`);
    }
  };

  const onAssignCase = (data, caseID, setDisabled) => {
    dispatch(ImSpecCaseDashboardAction.onSubmitAssignCase(caseID, data, navigate, setDisabled));
  };

  const stateTabs = useMemo(() => {
    return STATE_TABS.map(({ state }) => {
      const entryState = states.find(item => item.state === state);
      const stateValue = entryState ? `(${entryState.value})` : '(0)';
      return {
        key: state,
        label: `${state} ${search && state !== 'All' ? '' : stateValue}`,
        disabled: search && state !== 'All'
      };
    });
  }, [states, search]);

  return (
    <Col className="dataWrapper">
      <CaseDashboardTop onChange={onChangeAssignSelect} assign={assign} searchCases={searchCases} />
      <Tabs
        className="case_status-tabs"
        type="card"
        activeKey={state}
        onChange={onChangeStates}
        items={stateTabs}
      />
      <div className="wrapper">
        <CaseDashboardTable
          state={state}
          status={status}
          assign={assign}
          page={page}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          statuses={statuses}
          cases={cases}
          count={count}
          loadingTable={loadingCases}
          onClickAssign={onClickAssign}
          onChangeStatuses={onChangeStatuses}
          onChangePage={onChangePage}
        />
      </div>
      <AssignModal
        onSubmit={onAssignCase}
        caseSpecialists={caseSpecialists}
        specialist={specialist}
      />
    </Col>
  );
};

export default ImSpecCaseDashboard;
