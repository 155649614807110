import PropTypes from 'prop-types';
import { Questions } from '../Grouped/Questions';

import { Row } from 'antd';

const Ungrouped = ({ curContent, scrollRef }) => {
  return (
    <Row className="mainContent cloud">
      {curContent.question_set?.map((question, index) => {
        return (
          <Questions
            scrollRef={scrollRef}
            key={question.id}
            index={index}
            curQuestionContent={question}
          />
        );
      })}
    </Row>
  );
};

Ungrouped.propTypes = {
  curContent: PropTypes.object,
  scrollRef: PropTypes.object
};

Ungrouped.defaultProps = {
  curContent: {},
  scrollRef: {}
};

export default Ungrouped;
