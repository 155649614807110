import { ImSpecActionTypes } from '../types';

const initialState = {
  states: {
    list: [],
    isLoading: false
  },
  statuses: {
    list: [],
    isLoading: false
  },
  cases: {
    count: 0,
    page: 1,
    pageSize: 100,
    pageSizeOptions: [50, 100, 150, 200],
    next: null,
    previous: null,
    list: [],
    isLoading: false
  },
  filterCases: {
    state: 'Active',
    status: 'Not Attempted',
    assign: 'assigned_to_me'
  },
  searchCases: {
    params: {
      query: null,
      pageSize: 5
    },
    result: {
      hasData: null,
      count: 0,
      list: []
    }
  },
  caseSpecialists: {
    immigration_specialists: [],
    immigration_attorneys: [],
    isLoading: false
  }
};

const ImSpecCaseDashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ImSpecActionTypes.STATUSES_LOADING:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.STATES_LOADING:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_STATUSES:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          list: [...payload]
        }
      };
    case ImSpecActionTypes.SET_STATES:
      return {
        ...state,
        states: {
          ...state.states,
          list: [...payload]
        }
      };
    case ImSpecActionTypes.CASES_LOADING:
      return {
        ...state,
        cases: {
          ...state.cases,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_CASES:
      return {
        ...state,
        cases: {
          ...state.cases,
          count: payload.count,
          next: payload.next,
          previous: payload.previous,
          list: payload.results
        }
      };
    case ImSpecActionTypes.SET_ENTRY_STATUS:
      return {
        ...state,
        filterCases: {
          ...state.filterCases,
          status: payload
        }
      };
    case ImSpecActionTypes.SET_ENTRY_STATE:
      return {
        ...state,
        filterCases: {
          ...state.filterCases,
          state: payload
        }
      };
    case ImSpecActionTypes.SET_PAGE:
      return {
        ...state,
        cases: {
          ...state.cases,
          page: payload
        }
      };
    case ImSpecActionTypes.SET_CASES_PAGE_SIZE:
      return {
        ...state,
        cases: {
          ...state.cases,
          pageSize: payload
        }
      };
    case ImSpecActionTypes.SET_ASSIGN:
      return {
        ...state,
        filterCases: {
          ...state.filterCases,
          assign: payload
        }
      };
    case ImSpecActionTypes.CASE_SPECIALISTS_LOADING:
      return {
        ...state,
        caseSpecialists: {
          ...state.caseSpecialists,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_CASE_SPECIALISTS:
      return {
        ...state,
        caseSpecialists: {
          ...state.caseSpecialists,
          immigration_specialists: payload.immigration_specialists,
          immigration_attorneys: payload.immigration_attorneys
        }
      };
    case ImSpecActionTypes.SET_SEARCH_RESULT:
      return {
        ...state,
        searchCases: {
          ...state.searchCases,
          result: {
            count: payload.count,
            list: [...payload.results],
            hasData: payload.hasData
          }
        }
      };
    case ImSpecActionTypes.SET_SEARCH_QUERY:
      return {
        ...state,
        searchCases: {
          ...state.searchCases,
          params: {
            ...state.searchCases.params,
            query: payload
          }
        }
      };
    default:
      return state;
  }
};

export default ImSpecCaseDashboardReducer;
