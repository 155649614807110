import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';
import moment from 'moment';

import { calendarIcon } from 'components/Icons/Icons';
import { Tooltip } from 'antd';

const DateField = ({
  setRequiredErrorClassName,
  curSubsection,
  answersDataState,
  answers,
  curField,
  iterationName,
  questionSet,
  setSavingValue,
  setShowRequiredError,
  showRequiredError,
  onFocus,
  onBlur,
  radioDisable,
  sync_info
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [startDate, setStartDate] = useState('');

  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM');

  useEffect(() => {
    if (!!answersDataState && Object.keys(answersDataState).length) {
      setStartDate(
        (answersDataState[curField.name]?.field_value &&
          new Date(answersDataState[curField.name]?.field_value)) ||
          null
      );
    }
  }, [answersDataState, curField]);

  useEffect(() => {
    setRequiredErrorClassName(
      curField.is_required && showRequiredError && !startDate ? 'required-error-block' : ''
    );
  }, [curField.is_required, setRequiredErrorClassName, showRequiredError, startDate]);

  const setVisible = visible => setTooltipVisible(visible);

  const handleOnChangeDate = (e, name, type) => {
    setStartDate(e);
    const formData = new FormData();

    formData.append('field_value', !!e ? moment(e).format('MM/DD/YYYY') : '');
    formData.append('field_name', name);
    formData.append('field_type', type);
    formData.append('group_name', !!iterationName ? iterationName : '');
    questionSet &&
      !questionSet.startsWith('uId') &&
      formData.append(
        'iteration_key',
        !!answers[iterationName]?.field_value || !questionSet.startsWith('uId')
          ? answers[iterationName]?.field_value[questionSet][name]?.iteration_key
          : null
      );

    setSavingValue(formData);
    onBlur();

    curField.is_required && !!e ? setShowRequiredError(true) : setShowRequiredError(false);
  };

  return (
    <div
      className="datePickerWrapper"
      style={{
        backgroundColor:
          (sync_info.sync_in_progress || curSubsection?.is_blocked || radioDisable) && '#e9ecef',
        cursor:
          sync_info.sync_in_progress || curSubsection?.is_blocked || radioDisable
            ? 'not-allowed'
            : 'initial'
      }}
    >
      {calendarIcon}
      <Tooltip
        open={startDate && tooltipVisible}
        trigger="hover"
        getTooltipContainer={trigger => trigger.parentNode}
        mouseEnterDelay={1.5}
        title={curField.placeholder}
      >
        <DatePicker
          id={curField?.id}
          isClearable={
            !sync_info.sync_in_progress &&
            !curSubsection?.is_blocked &&
            !radioDisable &&
            !!startDate
          }
          name={curField.name}
          calendarStartDay={1}
          selected={startDate}
          placeholderText={`${curField?.placeholder}${curField.is_required ? '*' : ''}`}
          onChange={(e, b) => handleOnChangeDate(e, curField.name, curField.type.type, b)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat="MM/dd/yyyy"
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={sync_info.sync_in_progress || curSubsection?.is_blocked || radioDisable}
          customInput={
            <MaskedInput
              onMouseLeave={() => setVisible(false)}
              onMouseEnter={() => setVisible(true)}
              pipe={autoCorrectedDatePipe}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              keepCharPositions
              guide
            />
          }
        />
      </Tooltip>
    </div>
  );
};

DateField.propTypes = {
  setRequiredErrorClassName: PropTypes.func,
  curSubsection: PropTypes.object,
  answersDataState: PropTypes.object,
  answers: PropTypes.object,
  curField: PropTypes.object,
  iterationName: PropTypes.string,
  questionSet: PropTypes.string,
  setSavingValue: PropTypes.func,
  setShowRequiredError: PropTypes.func,
  showRequiredError: PropTypes.bool,
  radioDisable: PropTypes.bool,
  inputRef: PropTypes.object,
  sync_info: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

DateField.defaultProps = {
  curSubsection: {},
  answersDataState: {},
  answers: {},
  curField: {},
  iterationName: '',
  questionSet: '',
  showRequiredError: false,
  radioDisable: false,
  inputRef: {},
  sync_info: {}
};

export default DateField;
