import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import useQuery from 'hooks/useQuery';
import { isFilterAnswersEmpty } from 'utils/helper';

import AssignModal from 'components/shared/Modals/AssignModal';

import CaseDashboardTop from './elements/CaseDashboardTop';
import CaseDashboardFilters from './elements/CaseDashboardFilters';
import CaseDashboardDrawer from './elements/CaseDashboardDrawer';
import CaseDashboardTable from './elements/CaseDashboardTable';
import SaveViewModal from './elements/SaveViewModal';

import { Col } from 'antd';

const CaseDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useQuery();
  const caseDashboardFilters = JSON.parse(localStorage.getItem('case-dashboard-filters'));
  const caseDashboardSavedView = JSON.parse(localStorage.getItem('case-dashboard-saved_view'));
  const [activeContentTab, setActiveContentTab] = useState('1');
  const [activeTab, setActiveTab] = useState('Empty');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [specialist, setSpecialist] = useState(null);

  const {
    searchCases,
    filterAnswers,
    cases: { list: cases, isLoading: loadingCases, count, page, pageSize, pageSizeOptions },
    caseSpecialists
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    if (search && caseDashboardFilters) {
      dispatch(ImSpecCaseDashboardBetaAction.clearFilters());
    }
  }, [dispatch, search, caseDashboardFilters]);

  useEffect(() => {
    dispatch(ImSpecCaseDashboardBetaAction.getDrawerData());

    if (!isFilterAnswersEmpty(caseDashboardFilters) && !search) {
      dispatch(
        ImSpecCaseDashboardBetaAction.applyFilters(
          caseDashboardSavedView?.id,
          caseDashboardSavedView?.name,
          caseDashboardFilters
        )
      );
      dispatch(ImSpecCaseDashboardBetaAction.setAllFilters(caseDashboardFilters));
    } else {
      dispatch(ImSpecCaseDashboardBetaAction.getCasesTableData(search));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search]);

  const onChangePage = (page, pageSize) => {
    dispatch(ImSpecCaseDashboardBetaAction.onChangePage(page, pageSize, search));
  };

  const onDrawerClose = () => {
    setOpenDrawer(false);
  };

  const showDrawer = () => {
    isFilterAnswersEmpty(filterAnswers) ? setActiveTab('Empty') : setActiveTab('Case type');
    setOpenDrawer(true);
  };

  const showModal = () => {
    setIsSaveModalOpen(true);
  };

  const onClickAssign = ({ case_id, im_spec, attorney }) => {
    if (!!im_spec) {
      setSpecialist(Object.keys(im_spec).length ? im_spec : null);
      navigate(`${location.pathname}?case_id=${case_id}&assign=im_spec`);
    }
    if (!!attorney) {
      setSpecialist(Object.keys(attorney).length ? attorney : null);
      !!attorney && navigate(`${location.pathname}?case_id=${case_id}&assign=attorney`);
    }
  };

  const onAssignCase = (data, caseID, setDisabled) => {
    dispatch(ImSpecCaseDashboardBetaAction.onSubmitAssignCase(caseID, data, navigate, setDisabled));
  };

  return (
    <Col className="dataWrapper client-management">
      <Col className="wrapper">
        <CaseDashboardTop searchCases={searchCases} />
        <CaseDashboardFilters
          showDrawer={showDrawer}
          showModal={showModal}
          setActiveTab={setActiveTab}
        />
        <CaseDashboardDrawer
          onDrawerCloseMain={onDrawerClose}
          openDrawer={openDrawer}
          showModal={showModal}
          activeContentTab={activeContentTab}
          setActiveContentTab={setActiveContentTab}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <CaseDashboardTable
          page={page}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          cases={cases}
          count={count}
          loadingTable={loadingCases}
          onClickAssign={onClickAssign}
          onChangePage={onChangePage}
        />
        <AssignModal
          onSubmit={onAssignCase}
          caseSpecialists={caseSpecialists}
          specialist={specialist}
        />
        <SaveViewModal
          visible={isSaveModalOpen}
          setIsModalOpen={setIsSaveModalOpen}
          setActiveContentTab={setActiveContentTab}
          setOpenDrawer={setOpenDrawer}
        />
      </Col>
    </Col>
  );
};

export default CaseDashboard;
