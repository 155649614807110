import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

// columns
import statusTableColumns from './statusTableColumns';
import AttorneyTableColumns from './attorneyTableColumns';

import TableNoData from 'pages/ImSpecPages/CaseDashboard/elements/TableNoData';
import SelectComponentClass from 'components/shared/SelectComponentClass';

// constants
import { STATUS_TABS } from 'utils/constants';

import { Col, Table, Tabs } from 'antd';
// icons
import { LoadingOutlined } from '@ant-design/icons';

const CaseDashboardTable = ({
  state,
  status,
  assign,
  page,
  pageSize,
  pageSizeOptions,
  statuses,
  cases,
  count,
  loadingTable,
  onClickAssign,
  onChangeStatuses,
  onChangePage
}) => {
  const { user } = useSelector(state => state.auth);
  const hideChildCases = useFlag('hide-child-cases');
  const { flagsReady } = useFlagsStatus();

  const columns =
    user?.role === 'IS' || user?.role === 'CS'
      ? statusTableColumns({
          state,
          assign,
          onClickAssign,
          flagsReady,
          hideChildCases
        })
      : AttorneyTableColumns({
          state,
          assign,
          onClickAssign,
          flagsReady,
          hideChildCases
        });

  const statusTabs = useMemo(() => {
    return STATUS_TABS.map(({ status }) => {
      const entryStatus = statuses.find(item => item.status === status);
      const statusValue = entryStatus ? `(${entryStatus.value})` : '(0)';
      return {
        key: status,
        label: `${status} ${statusValue}`
      };
    });
  }, [statuses]);

  const pagination = useMemo(
    () => ({
      className: 'lp-pagination',
      defaultCurrent: 1,
      defaultPageSize: 100,
      hideOnSinglePage: true,
      current: page,
      position: ['bottomCenter'],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      size: 'small',
      pageSize: pageSize,
      pageSizeOptions: pageSizeOptions,
      showSizeChanger: count > pageSize,
      total: count,
      showQuickJumper: false,
      onChange: onChangePage,
      selectComponentClass: SelectComponentClass
    }),
    [count, onChangePage, page, pageSize, pageSizeOptions]
  );

  return (
    <Col className="table-tab-col">
      {state === 'Active' && (
        <Tabs
          className="status-tabs"
          activeKey={status}
          onChange={onChangeStatuses}
          items={statusTabs}
        />
      )}
      <Table
        loading={{
          spinning: loadingTable,
          indicator: <LoadingOutlined style={{ fontSize: 30 }} />
        }}
        dataSource={cases}
        columns={columns}
        locale={{
          emptyText: <TableNoData assign={assign} role={user?.role} />
        }}
        className="status-table"
        tableLayout="fixed"
        scroll={{ y: '100%' }}
        pagination={pagination}
      />
    </Col>
  );
};

CaseDashboardTable.propTypes = {
  status: PropTypes.string,
  onClickAssign: PropTypes.func,
  cases: PropTypes.array,
  loadingTable: PropTypes.bool,
  statuses: PropTypes.array,
  count: PropTypes.number,
  onChangeStatuses: PropTypes.func,
  onChangePage: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.array,
  assign: PropTypes.string,
  state: PropTypes.string
};

CaseDashboardTable.defaultProps = {
  status: '',
  cases: [],
  loadingTable: false,
  statuses: [],
  count: null,
  page: null,
  assign: '',
  state: ''
};

export default CaseDashboardTable;
