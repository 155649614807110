import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutocompleteCheckboxTags from 'components/shared/AutocompleteCheckboxTags';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import { Form } from 'antd';

const CaseType = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const selectedList = Form.useWatch('visa_name', form);
  const [searchValue, setSearchValue] = useState('');

  const {
    filters: {
      caseType: { list }
    },
    filterAnswers: { case_type }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    if (!case_type.length) {
      form.setFieldValue('visa_name', []);
    }
  }, [case_type, form]);

  useEffect(() => {
    if (typeof selectedList !== 'undefined') {
      dispatch(ImSpecCaseDashboardBetaAction.setSelectedCaseType(selectedList));
    }
  }, [dispatch, selectedList]);

  return (
    <div>
      <AutocompleteCheckboxTags
        name="case type"
        form={form}
        options={list}
        filterOption="visa_name"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        checkedValues={case_type}
      />
    </div>
  );
};

export default CaseType;
