import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import moment from 'moment/moment';

import { browserName } from 'react-device-detect';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { ImSpecContentAction } from 'store/Actions';
import { downloadOrView } from 'utils/helper';

import { alreadySyncedReview } from 'components/Icons/Icons';
import {
  FileTextFilled,
  LoadingOutlined,
  InfoCircleFilled,
  CheckOutlined
} from '@ant-design/icons';
import { Modal, Button, Avatar, Typography, Tooltip, Spin } from 'antd';
const { Paragraph } = Typography;

const HistoryLog = ({ logModalVisible, setLogModalVisible }) => {
  const { masterIntakeID } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hideSyncHistoryLog = useFlag('hide-sync-history-log');
  const { flagsReady } = useFlagsStatus();

  const {
    entryLogs: { logs, curItem, entryLogsLoading, curAnswer }
  } = useSelector(state => state.ImSpecContent);

  const onCloseLogsModal = () => {
    setLogModalVisible(false);
    dispatch(ImSpecContentAction.setEntryLogs({ logs: [], curItem: {} }));
    navigate(location.pathname, { replace: true });
  };

  const logHeader = ({
    is_synced,
    source_case_id,
    source_section_name,
    user,
    created_at,
    master_intake_visa_name,
    source_master_intake_id,
    petitioner_name,
    source_answer_id,
    source_beneficiary_id
  }) => (
    <div className="historyItem_header">
      <h6>
        {is_synced && flagsReady && !hideSyncHistoryLog ? (
          <>
            {alreadySyncedReview}
            {source_master_intake_id ? (
              <span className="userName">
                Source:{' '}
                <Link
                  className="table-items_link"
                  to={`/petitioner-dashboard/master-intake/${source_master_intake_id}/${petitioner_name.replaceAll(
                    ' ',
                    '-'
                  )}`}
                  disabled={!source_answer_id}
                  state={{ answer_id: source_answer_id, action: 'history_log' }}
                  style={{ pointerEvents: !source_answer_id ? 'none' : 'pointer' }}
                >
                  {master_intake_visa_name} MI
                </Link>
                {!source_answer_id && (
                  <Tooltip
                    trigger="click"
                    title="Source field not available anymore"
                    color="#FFFFFF"
                    overlayInnerStyle={{ color: '#222124' }}
                  >
                    <InfoCircleFilled className="logInfo_icon" />
                  </Tooltip>
                )}
              </span>
            ) : (
              <span className="userName">
                Source:{' '}
                <Link
                  className="table-items_link"
                  to={`/client-management/userID=${source_beneficiary_id}/${source_case_id}/${source_section_name.replaceAll(
                    ' ',
                    '-'
                  )}`}
                  disabled={!source_answer_id}
                  state={{ answer_id: source_answer_id, action: 'history_log' }}
                  style={{ pointerEvents: !source_answer_id ? 'none' : 'pointer' }}
                >
                  {source_case_id}
                </Link>
                {!source_answer_id && (
                  <Tooltip
                    trigger="click"
                    title="Source field not available anymore"
                    color="#FFFFFF"
                    overlayInnerStyle={{ color: '#222124' }}
                  >
                    <InfoCircleFilled className="logInfo_icon" />
                  </Tooltip>
                )}
              </span>
            )}
          </>
        ) : (
          <>
            <Avatar style={{ backgroundColor: '#33B18A', marginRight: '7px' }} size="small">
              {(user?.first_name?.charAt(0) + user?.last_name?.charAt(0)).toUpperCase()}
            </Avatar>
            <span className="userName">{`${user?.first_name} ${user?.last_name}`}</span>
          </>
        )}
      </h6>
      <span>{moment(created_at).format('hh:mm A, ll')}</span>
    </div>
  );

  const emptyText =
    curItem?.name === 'child_firstname' ||
    curItem?.name === 'child_lastname' ||
    curItem?.name === 'spouse_lastname' ||
    curItem?.name === 'spouse_firstname'
      ? 'This field value is pulled from invitation details. A log per each inputted value will'
      : curAnswer?.field_value !== null
      ? 'There are no logs here.'
      : null;

  return (
    <Modal
      title={
        !!logs[0]?.answer.field_placeholder
          ? logs[0]?.answer.field_placeholder
          : logs[0]?.answer.field_name
      }
      centered
      open={logModalVisible}
      onCancel={onCloseLogsModal}
      footer={null}
      wrapClassName="seeHistoryLogModal"
      mask={false}
    >
      {entryLogsLoading ? (
        <div className="seeHistoryLogModal_spinner">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
        </div>
      ) : !logs.length ? (
        <div className="seeHistoryLogModal_empty">
          <p>{emptyText}</p>
        </div>
      ) : (
        logs.map(curHistoryItem => {
          const actualValue =
            (curItem.type.type === 'Upload' ||
              curItem.type.type === 'Multi Upload' ||
              curHistoryItem.answer.field_type === 'Upload' ||
              curHistoryItem.answer.field_type === 'Multi Upload') &&
            curHistoryItem?.actual_value
              ? JSON.parse(curHistoryItem?.actual_value)
              : curHistoryItem?.actual_value;

          const onPreviewUpload = id => {
            dispatch(ImSpecContentAction.onPreview(id, masterIntakeID));
          };

          return (
            <Tooltip
              key={curHistoryItem.id}
              placement="topLeft"
              overlayInnerStyle={{ width: 'max-content' }}
              title={
                curHistoryItem.is_synced &&
                flagsReady &&
                !hideSyncHistoryLog && (
                  <>
                    <p>
                      Trigger action: <b>{curHistoryItem.trigger_action}</b>
                    </p>
                    <p>
                      Triggered by: <b>{curHistoryItem.trigger_by}</b>
                    </p>
                    <p>
                      Value provided by:{' '}
                      <b>
                        {curHistoryItem.user.first_name} {curHistoryItem.user.last_name}
                      </b>
                    </p>
                  </>
                )
              }
            >
              {curItem.type.type === 'Upload' ||
              curItem.type.type === 'Multi Upload' ||
              curHistoryItem.answer.field_type === 'Upload' ||
              curHistoryItem.answer.field_type === 'Multi Upload' ? (
                <div
                  className={`${
                    curHistoryItem.is_synced && flagsReady && !hideSyncHistoryLog
                      ? 'syncedHistoryUploadedItem'
                      : ''
                  }`}
                >
                  <div className="uploaded-files-logs">
                    {logHeader(curHistoryItem)}
                    <div className={`uploaded-files-logs-items ${!actualValue && 'empty'}`}>
                      {actualValue
                        ? actualValue.map(({ original_name, id, extension }, index) => (
                            <div key={index + 1} className="uploaded-files-logs-items-log">
                              <div className="uploaded-files-logs-items-log-icon">
                                {<FileTextFilled />}
                              </div>
                              <div className="uploaded-files-logs-items-log-title">
                                <Tooltip
                                  overlayClassName="upload-title-tooltip"
                                  title={original_name}
                                  placement="bottom"
                                >
                                  <span>{original_name}</span>
                                </Tooltip>
                              </div>
                              <Button
                                type="ghost"
                                onClick={() => onPreviewUpload(id)}
                                className="uploaded-files-logs-items-log-downloadBtn"
                              >
                                {downloadOrView(browserName, extension)}
                              </Button>
                            </div>
                          ))
                        : 'Emptied the field.'}
                    </div>
                    {(!curHistoryItem.is_synced ||
                      curHistoryItem.trigger_action === 'Field-by-field-sync') &&
                      flagsReady &&
                      !hideSyncHistoryLog && (
                        <div className={`uploaded-files-logs-action ${curHistoryItem.action}`}>
                          {curHistoryItem.action}
                        </div>
                      )}
                  </div>
                </div>
              ) : (
                <div
                  className={`${
                    curHistoryItem.is_synced && flagsReady && !hideSyncHistoryLog
                      ? `${
                          curItem.type.type === 'Checkbox'
                            ? 'syncedHistoryUploadedItem'
                            : 'syncedHistoryItem'
                        }`
                      : ''
                  }`}
                >
                  <div
                    className={`historyItem${
                      curItem.type.type === 'Checkbox' ? '-multi_select' : ''
                    }`}
                  >
                    {logHeader(curHistoryItem)}
                    <div className={`historyItem_body ${!actualValue && 'empty'}`}>
                      <Paragraph ellipsis={{ rows: 2, expandable: true }}>
                        {!actualValue ? (
                          'Emptied the field.'
                        ) : curItem.type.type === 'Checkbox' ? (
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {JSON.parse(actualValue).map((elem, index) => {
                              return (
                                <span key={index} style={{ lineHeight: '170%' }}>
                                  {curHistoryItem.is_synced && (
                                    <CheckOutlined style={{ fontSize: 11, marginRight: 6 }} />
                                  )}{' '}
                                  {elem}
                                </span>
                              );
                            })}
                          </div>
                        ) : (
                          actualValue
                        )}
                      </Paragraph>
                    </div>
                    {curItem.type.type === 'Checkbox' &&
                      (!curHistoryItem.is_synced ||
                        curHistoryItem.trigger_action === 'Field-by-field-sync') &&
                      flagsReady &&
                      !hideSyncHistoryLog && (
                        <div className={`multi-select-logs-action ${curHistoryItem.action}`}>
                          {curHistoryItem.action}
                        </div>
                      )}
                  </div>
                </div>
              )}
            </Tooltip>
          );
        })
      )}
    </Modal>
  );
};

HistoryLog.propTypes = {
  logModalVisible: PropTypes.bool,
  setLogModalVisible: PropTypes.func
};

HistoryLog.defaultProps = {
  logModalVisible: false
};

export default HistoryLog;
