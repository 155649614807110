import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardAction } from 'store/Actions';

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import useQuery from 'hooks/useQuery';
import useOutsideClicker from 'hooks/useOutsideClicker';

import debounce from 'lodash.debounce';

import { Col, Input, Row, Select, Popover, Form, Tooltip, Button } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { arrowDownAssignSelect, arrowTopAssignSelect } from 'components/Icons/Icons';

const CaseDashboardTop = ({
  onChange,
  assign,
  searchCases: {
    params: { pageSize, query },
    result: { count, list, hasData }
  }
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { search } = useQuery();
  const { user } = useSelector(state => state.auth);

  const hideChildCases = useFlag('hide-child-cases');
  const { flagsReady } = useFlagsStatus();

  const searchContainerRef = useRef(null);
  const searchRef = useRef(null);
  const [searchResultVisible, setSearchResultVisible] = useState(true);
  const [suffixIcon, setSuffixIcon] = useState(arrowDownAssignSelect);

  const [form] = Form.useForm();

  // todo need refactor querySelector part
  useOutsideClicker(
    [searchContainerRef, { current: document.querySelector('.search-result-popover') }],
    () => {
      query && form.setFieldsValue({ search_value: search || null });
      query && dispatch(ImSpecCaseDashboardAction.setSearchQuery(null));
      query &&
        dispatch(
          ImSpecCaseDashboardAction.setSearchResult({
            results: [],
            count: 0,
            hasData: null
          })
        );
    }
  );

  useEffect(() => {
    search && form.setFieldsValue({ search_value: search });
  }, [form, search]);

  useEffect(() => {
    dispatch(
      query
        ? ImSpecCaseDashboardAction.onSearch(query)
        : ImSpecCaseDashboardAction.setSearchResult({
            results: [],
            count: 0,
            hasData: null
          })
    );
  }, [dispatch, query]);

  useEffect(() => {
    setSearchResultVisible(!!(query && hasData !== null));
  }, [hasData, query]);

  const onDropdownVisibleChange = open => {
    setSuffixIcon(open ? arrowTopAssignSelect : arrowDownAssignSelect);
  };

  const onChangeSearch = ({ search_value }) => {
    debounceSearch(search_value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce(search_value => {
      dispatch(ImSpecCaseDashboardAction.setSearchQuery(search_value || null));
      !search_value && navigate(pathname);
    }, 500),
    []
  );

  const onEnterSearch = ({ target: { value } }) => {
    enterDebounceSearch(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const enterDebounceSearch = useCallback(
    debounce(search_value => {
      dispatch(ImSpecCaseDashboardAction.setPage(1));
      dispatch(ImSpecCaseDashboardAction.setSearchQuery(null));
      dispatch(
        ImSpecCaseDashboardAction.setSearchResult({
          results: [],
          count: 0,
          hasData: null
        })
      );
      navigate(`${pathname}?search=${search_value}`);
      searchRef.current.blur();
    }, 500),
    []
  );

  const onClickSeeAll = () => {
    dispatch(ImSpecCaseDashboardAction.setPage(1));
    dispatch(ImSpecCaseDashboardAction.setSearchQuery(null));
    dispatch(
      ImSpecCaseDashboardAction.setSearchResult({
        results: [],
        count: 0,
        hasData: null
      })
    );
    navigate(`${pathname}?search=${query}`);
    searchRef.current.blur();
  };

  const content =
    hasData !== null && !hasData ? (
      <Row className="no-items" justify="center" align="middle">
        <div className="counting">
          <span>No items match your search</span>
        </div>
      </Row>
    ) : hasData ? (
      <>
        <Row className="content-top" justify="space-between" align="middle">
          <div className="counting">
            <span>
              {list.length && count <= 5
                ? `Search results: ${count}`
                : `Showing ${pageSize} of ${count} results.`}
            </span>
          </div>
        </Row>
        <div>
          {list.slice(0, 5).map(item => (
            <Col key={item.case_id} className="case-item">
              <Row align="middle" className="case-item-top">
                <Col className="case-item-case_id">
                  <Link
                    className="case_id"
                    to={`/client-management/userID=${item.user_id}/${item.case_id}`}
                  >
                    <span>{item.case_id}</span>
                    {flagsReady && !hideChildCases && !!item.dependent_cases_count && (
                      <Tooltip
                        overlayClassName="migrate-tooltip"
                        title={`${item.dependent_cases_count} Child Cases`}
                        placement="top"
                      >
                        <div className="case-item-migrated">
                          <span>{item.dependent_cases_count}</span>
                        </div>
                      </Tooltip>
                    )}
                  </Link>
                </Col>
                <Col className={`case-item-visa_name v${item?.visa_name?.replaceAll(' ', '-')}`}>
                  {item.visa_name}{' '}
                  {item.visa_name === 'I-539' && item.is_dependent
                    ? 'DEP'
                    : item.visa_name === 'I-539' && !item.is_dependent
                    ? 'BEN'
                    : null}{' '}
                </Col>
                <Tooltip title={`${item.beneficiary.first_name} ${item.beneficiary.last_name}`}>
                  <Col className="case-item-beneficiary">
                    <Link to={`/client-management/userID=${item.beneficiary.id}`}>
                      {item.beneficiary.first_name} {item.beneficiary.last_name}
                    </Link>
                  </Col>
                </Tooltip>
                <div className="circle" />
                {typeof item.petitioner === 'string' ? (
                  <Tooltip title={item.petitioner}>
                    <span>{item.petitioner}</span>
                  </Tooltip>
                ) : (
                  <Tooltip title={item.petitioner?.name}>
                    <Link to={`/petitioner-dashboard/${item.petitioner?.id}`}>
                      {item.petitioner?.name}
                    </Link>
                  </Tooltip>
                )}
                {item.is_migrated && <Col className="case-item-migrated">M</Col>}
              </Row>
              <Row align="middle" className="case-item-bottom">
                <Col className={`case-item-state ${item.state}`}>{item.state}</Col>
                <div className="circle" />
                <Col className={`case-item-status ${item.status.replaceAll(' ', '-')}`}>
                  {item.status}
                </Col>
              </Row>
            </Col>
          ))}
        </div>
        <Row className="content-bottom" align="middle" justify="space-between">
          <Row align="middle">
            <Button
              type="ghost"
              className="see-all"
              icon={<SearchOutlined />}
              onClick={onClickSeeAll}
            >
              <span>
                See all search results for “
                <Tooltip placement="top" title={query}>
                  {query}
                </Tooltip>
                ”
              </span>
            </Button>
          </Row>
          <span className="press-enter">Press Enter</span>
        </Row>
      </>
    ) : null;

  return (
    <div className="dataWrapper-top">
      <Row>
        <span className="dataWrapper-top-title">Cases</span>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Popover
            open={searchResultVisible}
            overlayClassName="search-result-popover"
            content={content}
            placement="bottom"
            getPopupContainer={triggerNode => triggerNode.parentNode}
            arrow={false}
          >
            <div ref={searchContainerRef}>
              <Form form={form} className="search-form" onValuesChange={onChangeSearch}>
                <Form.Item name="search_value">
                  <Input
                    ref={searchRef}
                    onPressEnter={onEnterSearch}
                    addonBefore={<SearchOutlined />}
                    placeholder="Search for cases by ID, Beneficiary, or Petitioner"
                    allowClear={{
                      clearIcon: <CloseOutlined style={{ color: '#000', fontSize: '14px' }} />
                    }}
                    className="case-dashboard-search"
                  />
                </Form.Item>
              </Form>
            </div>
          </Popover>
        </Col>
        <Col>
          <Select
            className="select-assign"
            defaultValue="assigned_to_me"
            value={assign}
            onChange={onChange}
            getPopupContainer={trigger => trigger.parentNode}
            suffixIcon={suffixIcon}
            onDropdownVisibleChange={onDropdownVisibleChange}
            popupClassName="select-assign-dropdown"
            options={[
              { key: 'assigned_to_me', value: 'assigned_to_me', label: 'Assigned to me' },
              {
                key: 'assigned_to_others',
                value: 'assigned_to_others',
                label:
                  user?.role === 'IS' || user?.role === 'CS'
                    ? 'Assigned to other IS'
                    : 'Assigned to other IA'
              },
              { key: 'unassigned', value: 'unassigned', label: 'Unassigned' },
              { key: 'all', value: 'all', label: 'All cases' }
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

CaseDashboardTop.propTypes = {
  onChange: PropTypes.func,
  assign: PropTypes.string,
  searchCases: PropTypes.shape({
    params: PropTypes.shape({
      pageSize: PropTypes.number,
      query: PropTypes.string
    }),
    result: PropTypes.shape({
      count: PropTypes.number,
      list: PropTypes.array,
      hasData: PropTypes.bool
    })
  })
};

CaseDashboardTop.defaultProps = {
  assign: '',
  searchCases: {}
};

export default CaseDashboardTop;
