import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { Button, Row, Typography } from 'antd';
import { RotateLeftOutlined } from '@ant-design/icons';

import { HistoryLogCommentsThreadAction } from 'store/Actions';

const BlurCommentContent = ({ form }) => {
  const dispatch = useDispatch();
  const { caseID, masterIntakeID } = useParams();

  const onFollowToSee = () => {
    dispatch(
      HistoryLogCommentsThreadAction.onChangeCommentsViewTab(caseID, masterIntakeID, 'unresolved')
    );
    dispatch(HistoryLogCommentsThreadAction.setSentCommentAnswerId(null));
    form.resetFields();
  };

  const onClose = () => {
    dispatch(HistoryLogCommentsThreadAction.setSentCommentAnswerId(null));
    form.resetFields();
  };

  return (
    <>
      <Row className="rotate-icon">
        <RotateLeftOutlined style={{ fontSize: 32, color: '#63A0FF' }} />
      </Row>
      <Row className="description">
        <Typography.Text>Comment thread moved to "Unresolved"</Typography.Text>
      </Row>
      <Row>
        <Button onClick={onFollowToSee} className="follow-to-see" type="ghost">
          Follow to see it
        </Button>
      </Row>
      <Row>
        <Button onClick={onClose} className="close" type="ghost">
          Close
        </Button>
      </Row>
    </>
  );
};

BlurCommentContent.propTypes = {
  form: PropTypes.object
};

export default BlurCommentContent;
