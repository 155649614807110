import { ImSpecActionTypes } from '../types';

const initialState = {
  content: {
    tableData: [],
    page: 1,
    count: 0,
    pageSize: 100,
    pageSizeOptions: [50, 100, 150, 200],
    isLoading: false
  },
  nestedTable: {
    nestedTableData: [],
    isNestedLoading: false
  },
  resendInvitation: {
    isLoading: false
  },
  cancelInvitation: {
    isLoading: false
  },
  inviteClientFormData: {
    cases: [],
    petitions: [],
    principal_cases: [],
    isLoading: false
  },
  inviteClient: {
    invitedClientInfo: {},
    isLoading: false
  }
};

export const ImSpecClientManagementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ImSpecActionTypes.CLIENTS_TABLE_LOADING:
      return {
        ...state,
        content: {
          ...state.content,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.CLIENTS_TABLE_DATA:
      return {
        ...state,
        content: {
          ...state.content,
          tableData: [...payload.results],
          count: payload.count
        }
      };
    case ImSpecActionTypes.NESTED_CLIENTS_TABLE_DATA:
      return {
        ...state,
        nestedTable: {
          ...state.nestedTable,
          nestedTableData: [...payload]
        }
      };
    case ImSpecActionTypes.NESTED_CLIENTS_TABLE_LOADING:
      return {
        ...state,
        nestedTable: {
          ...state.nestedTable,
          isNestedLoading: payload
        }
      };
    case ImSpecActionTypes.RESEND_LOADING:
      return {
        ...state,
        resendInvitation: {
          ...state.resendInvitation,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.CANCEL_LOADING:
      return {
        ...state,
        cancelInvitation: {
          ...state.cancelInvitation,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.CLIENTS_TABLE_SET_PAGE:
      return {
        ...state,
        content: {
          ...state.content,
          page: payload
        }
      };
    case ImSpecActionTypes.CLIENTS_TABLE_SET_PAGE_SIZE:
      return {
        ...state,
        content: {
          ...state.content,
          pageSize: payload
        }
      };
    case ImSpecActionTypes.SET_INVITE_CLIENT_FORM_DATA_CASES:
      return {
        ...state,
        inviteClientFormData: {
          ...state.inviteClientFormData,
          cases: [...payload]
        }
      };
    case ImSpecActionTypes.SET_INVITE_CLIENT_FORM_DATA_PETITIONS:
      return {
        ...state,
        inviteClientFormData: {
          ...state.inviteClientFormData,
          petitions: [...payload]
        }
      };
    case ImSpecActionTypes.INVITE_CLIENT_FORM_LOADING:
      return {
        ...state,
        inviteClientFormData: {
          ...state.inviteClientFormData,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.INVITED_CLIENT_INFO:
      return {
        ...state,
        inviteClient: {
          ...state.inviteClient,
          invitedClientInfo: payload
        }
      };
    case ImSpecActionTypes.INVITE_CLIENT_LOADING:
      return {
        ...state,
        inviteClient: {
          ...state.inviteClient,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_PRINCIPAL_CASES:
      return {
        ...state,
        inviteClientFormData: {
          ...state.inviteClientFormData,
          principal_cases: [...payload]
        }
      };
    default:
      return state;
  }
};

export default ImSpecClientManagementReducer;
