import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ImSpecPetitionerPageAction } from 'store/Actions';

import FloatLabelInput from 'components/shared/FloatLabelInput';

import { Modal, Form, Button } from 'antd';

const EditPetitionerNameModal = ({ visible, setVisible, petitioner }) => {
  const dispatch = useDispatch();
  const { petitionerID } = useParams();

  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState(true);
  const [nameErr, setNameErr] = useState(null);

  useEffect(() => {
    if (visible && petitioner && form) {
      form.setFieldsValue({ name: petitioner.name });
    }
  }, [form, petitioner, visible]);

  const onCancel = () => {
    form.resetFields();
    setDisabled(true);
    setNameErr(null);
    setVisible(false);
  };

  const onFieldsChange = (cur, all) => {
    cur[0].touched && nameErr && setNameErr(null);
    setDisabled(
      all.some(e => e.value === petitioner.name || e.touched === false || e.errors.length > 0)
    );
  };

  const onFinish = data => {
    dispatch(
      ImSpecPetitionerPageAction.editPetitionerName(
        petitionerID,
        data,
        setDisabled,
        form,
        setVisible,
        setNameErr
      )
    );
  };

  return (
    <Modal
      title="Edit Company Details"
      forceRender
      className="assign-modal"
      width={414}
      centered
      closable={false}
      footer={null}
      open={visible}
    >
      <hr />
      <Form
        form={form}
        onFieldsChange={onFieldsChange}
        onFinish={onFinish}
        className="add-petitioner"
      >
        <Form.Item
          name="name"
          htmlFor="name"
          validateFirst
          getValueProps={value => ({ value: value || '' })}
          rules={[
            {
              required: true,
              message: 'This field is required.'
            }
          ]}
        >
          <FloatLabelInput label="Company name" />
        </Form.Item>
        {nameErr && <span className="error">{nameErr}</span>}
        <Form.Item className="submitting" htmlFor="submit">
          <Button onClick={onCancel} className="assign-btn cancel">
            Cancel
          </Button>
          <Button
            disabled={disabled}
            type={disabled ? 'disabled' : 'primary'}
            className="assign-btn assign"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

EditPetitionerNameModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  petitioner: PropTypes.object
};

EditPetitionerNameModal.defaultProps = {
  visible: false,
  petitioner: {}
};

export default EditPetitionerNameModal;
