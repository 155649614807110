import { Col, Row } from 'antd';
import React from 'react';

const InvitationCanceledError = () => {
  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content resetPass">
        <Row>
          <h3>Invitation canceled</h3>
        </Row>

        <Row type="flex" justify="center" align="middle" className="publicForms_content_cloud">
          <p>The invitation to sign up at Legalpad has been canceled.</p>
        </Row>

        <hr />

        <Row type="flex" justify="center" align="middle" className="publicForms_content_footer">
          <p className="customer-success">
            Please contact us at{' '}
            <address>
              <a href="mailto:onboarding@legalpad.io">onboarding@legalpad.io</a>
            </address>{' '}
            for assistance.
          </p>
        </Row>
      </Col>
    </Row>
  );
};

export default InvitationCanceledError;
