import React from 'react';
import ReactGA from 'react-ga4';
import { BroadcastChannel } from 'broadcast-channel';

import store from 'store/index';
import { CustomerActionTypes } from 'store/types';
import { AuthService } from 'services';

import { Modal } from 'antd';

const userChanel = new BroadcastChannel('user');

const modalProps = {
  centered: true,
  icon: null,
  className: 'publicModal',
  okButtonProps: { className: 'publicModal-submit_btn' },
  okText: 'Dismiss'
};

const signIn = (userData, redirect, setAuthResponse, locationState) => async dispatch => {
  try {
    dispatch(requestSignInOrSignUp(true));
    const { data } = await AuthService.signIn(userData);

    if (data.migration_completed) {
      location.href = data.url;
      return;
    }

    if (data.access) {
      localStorage.setItem('user', JSON.stringify(data));
      !localStorage.getItem(`${data.email}`) &&
        localStorage.setItem(`${data.email}`, JSON.stringify([]));
    }

    dispatch(loginAccess(data));
    ReactGA.set({ user_id: data.id });
    redirect(
      locationState?.from.pathname
        ? `${locationState?.from.pathname}${locationState?.from.search}`
        : '/dashboard'
    );
  } catch ({ data: { type, detail }, statusText }) {
    if (type === 'verify-updated-email-error')
      redirect('/verify-updated-email-error', {
        state: { email: userData.email }
      });
    else if (type === 'Migrated')
      redirect('/verify-migrated-account', { state: { email: userData.email } });
    else if (type === 'old-email-error')
      redirect('/old-email-error', { state: { email: userData.email } });
    else
      setAuthResponse(res => ({
        ...res,
        detail: detail,
        statusText: statusText,
        type: type
      }));
  } finally {
    dispatch(requestSignInOrSignUp(false));
  }
};

const signUp = (user, isInvited, redirect, growsurfFlag) => async dispatch => {
  try {
    dispatch(requestSignInOrSignUp(true));

    let res;
    if (!isInvited) {
      res = await AuthService.signUp(user);
    } else {
      res = await AuthService.signUpInvited(user);
    }

    const content =
      res.status === 200 && res.statusText === 'OK' ? (
        <>
          <h4 style={{ paddingBottom: '25px' }}>Success!</h4>
          <div style={{ paddingBottom: '20px' }}>
            <p style={{ paddingBottom: '0' }}>Your account has been successfully created.</p>
            <p>You can now sign in.</p>
          </div>
        </>
      ) : res.status === 201 && res.statusText === 'Created' ? (
        <>
          <h4 style={{ marginBottom: '50px' }}>
            A confirmation email has been sent to {user.email}
          </h4>
          <p>Please check your email to verify your account.</p>
        </>
      ) : null;

    const onOk = () => {
      redirect('/sign-in');
      isInvited && dispatch(setInvitedUserData({}));
    };

    Modal.info({ ...modalProps, content, onOk });

    growsurfFlag === false &&
      (await window.growsurf.triggerReferral({
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name
      }));
  } catch (e) {
    if (e.data) {
      if (e.data.url && !isInvited) {
        redirect(`/${e.data.url[0]}`);
        return;
      }

      const content = e.data.email ? (
        <>
          <h4>Oops, an account with this email address already exists.</h4>
          <div>
            <p>
              If this your email address, and you can’t sign in with it, you can{' '}
              <a href="/forgot-password">recover your password.</a>
            </p>
            <p>
              Or, if you want to create another account, please close this window and provide
              another email address.
            </p>
          </div>
        </>
      ) : e.data.phone_number ? (
        <>
          <h4>Oops, an account with this phone number already exists.</h4>
          <div>
            <p>
              If this your phone number, you can try to <a href="/sign-in">sign in</a> or{' '}
              <a href="/forgot-password"> recover your password.</a>
            </p>
            <p>
              Or, if you want to create another account, please close this window and provide
              another phone number.
            </p>
          </div>
        </>
      ) : null;

      Modal.info({ ...modalProps, content });
    }
  } finally {
    dispatch(requestSignInOrSignUp(false));
  }
};

const signOut = (data, redirect, growsurfFlag) => async dispatch => {
  try {
    await AuthService.signOut(data);

    await userChanel.postMessage('SIGN_OUT');
    localStorage.removeItem('user');
    localStorage.removeItem('case-dashboard-filters');
    localStorage.removeItem('case-dashboard-saved-view');
    dispatch(userSignOut());
    redirect('/sign-in');

    growsurfFlag === false && window.growsurf.logout();
  } catch (e) {
    console.warn(e);
  }
};

const logOutListener = () => {
  userChanel.onmessage = msg => {
    if (msg === 'SIGN_OUT') store.dispatch(userSignOut());
  };
};

const recoveryPassword = (data, redirect, setEmailErr) => async dispatch => {
  try {
    dispatch(requestSignInOrSignUp(true));

    await AuthService.recoveryPassword(data);

    const content = (
      <>
        <h4>Forgot your password?</h4>
        <p>
          If an account exists with this email address, an email will be sent to it with further
          instructions.
        </p>
      </>
    );

    Modal.info({ ...modalProps, content });
  } catch (e) {
    const { data: errorData } = e;

    if (errorData.type === 'Migrated')
      redirect('/verify-migrated-account', { state: { email: data.email } });
    if (errorData.state === 'Pending')
      redirect(errorData.is_verified ? '/verify-updated-email-error' : '/verify-account', {
        state: { email: data.email }
      });
    if (errorData.state === 'Canceled') redirect('/invitation-canceled');

    if (errorData.error === 'There is no user with given email.')
      setEmailErr('The email entered does not match our records.');
  } finally {
    dispatch(requestSignInOrSignUp(false));
  }
};

const resetPassword = (password, id, redirect) => async () => {
  try {
    await AuthService.resetPassword(id, password);

    redirect('/password-changed');
  } catch (e) {
    redirect('/password-change-error');
  } finally {
  }
};

const emailVerification = (id, redirect) => async dispatch => {
  try {
    dispatch(requestSignInOrSignUp(true));
    const res = await AuthService.emailVerification(id);

    res.data && redirect('/email-success');
  } catch (e) {
    redirect('/email-error');
  } finally {
    dispatch(requestSignInOrSignUp(false));
  }
};

const resendVerifyEmail = (data, redirect) => async dispatch => {
  try {
    dispatch(requestSignInOrSignUp(true));
    const res = await AuthService.resendVerifyEmail(data);

    const content = (
      <>
        <h4>{res.data.success ? 'Recovery link was sent successfully!' : 'Success!'}</h4>
        {res.data.success && (
          <p style={{ marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: res.data.success }} />
        )}
      </>
    );

    Modal.info({ ...modalProps, content, onOk: () => redirect('/sign-in') });
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(requestSignInOrSignUp(false));
  }
};

const authInvitedUser = (invitation_generated, confirmation_token, redirect) => async dispatch => {
  try {
    dispatch(inviteAuthLoading(true));

    const res = await AuthService.authInvitedUser(invitation_generated, confirmation_token);

    dispatch(setInvitedUserData(res.data));
  } catch (e) {
    const {
      data: { url }
    } = e;

    !!url ? redirect(`/${url}`) : redirect('/invitation-error');
  } finally {
    dispatch(inviteAuthLoading(false));
  }
};

const setInvitedUserData = userData => {
  return {
    type: CustomerActionTypes.INVITED_AUTH_USER_DATA,
    payload: { ...userData }
  };
};

const inviteAuthLoading = loading => {
  return {
    type: CustomerActionTypes.INVITE_AUTH_LOADING,
    payload: loading
  };
};

const loginAccess = user => {
  return {
    type: CustomerActionTypes.LOGIN_SUCCESS,
    payload: { ...user }
  };
};

const requestSignInOrSignUp = loading => {
  return {
    type: CustomerActionTypes.REGISTER_REQUEST_OR_LOGIN,
    payload: loading
  };
};

const userSignOut = () => {
  return {
    type: CustomerActionTypes.LOG_OUT
  };
};

export const AuthActions = {
  signIn,
  signUp,
  signOut,
  emailVerification,
  resendVerifyEmail,
  authInvitedUser,
  resetPassword,
  recoveryPassword,
  logOutListener,
  loginAccess,
  userSignOut
};
