import PropTypes from 'prop-types';
import { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerContentAction } from 'store/Actions';

import { Questions } from '../index';
import { stringifyNumber } from 'utils/constants';

import { Button, Modal } from 'antd';
import { addIterationIcon } from 'components/Icons/Icons';

const Iterative = ({ curQuestionContent, curField, nestedQuestion, curGroup }) => {
  const dispatch = useDispatch();
  const { masterIntakeID, petitionerName, caseID, sectionTitle } = useParams();

  const { subsection, answers, sendAnswersLoading, answersLoading, sync_info } = useSelector(
    state => state.customerContent
  );

  const [iterativeSetAnswers, setIterativeSetAnswers] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const modalProps = {
    width: 438,
    centered: true,
    icon: null,
    className: 'confirm-radio',
    cancelButtonProps: {
      className: 'submit-section-confirm-modal-cancel_btn'
    },
    okButtonProps: { className: 'confirm-radio-confirm_btn', loading: false },
    okText: 'Remove'
  };

  useEffect(() => {
    if (sendAnswersLoading) {
      setDisabled(sendAnswersLoading);
    } else if (!sendAnswersLoading && answersLoading) {
      setDisabled(answersLoading);
    } else {
      setDisabled(isTyping || !!iterativeSetAnswers.find(e => e.startsWith('uId')));
    }
  }, [answersLoading, isTyping, iterativeSetAnswers, sendAnswersLoading]);

  useEffect(() => {
    !!answers?.[curField.name]?.field_value &&
      setIterativeSetAnswers(Object.keys(answers?.[curField.name]?.field_value));
  }, [answers, curField.name]);

  useEffect(() => {
    !answers?.[curField.name]?.field_value &&
      setIterativeSetAnswers([...iterativeSetAnswers, `uId_${Date.now()}`]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addIterative = () => {
    setIterativeSetAnswers([...iterativeSetAnswers, `uId_${Date.now()}`]);
  };

  const deleteIterative = (caseID, groupName, iterationKey) => {
    const content = (
      <>
        <p className="submit-confirm-modal-title">Remove {curQuestionContent.title}?</p>
        <hr />
        <p className="confirm-radio-description">
          Are you sure you want to remove this <b>{curQuestionContent.title}</b>?
        </p>
      </>
    );

    const deleteIterativeQuestion = async () => {
      await dispatch(
        CustomerContentAction.removeIterativeQuestionSet(
          caseID,
          groupName,
          iterationKey,
          sectionTitle
        )
      );
      await dispatch(
        CustomerContentAction.getContentAnswers(
          masterIntakeID,
          petitionerName,
          sectionTitle,
          caseID
        )
      );
    };

    iterationKey.startsWith('uId')
      ? setIterativeSetAnswers(iterativeSetAnswers.filter(item => item !== iterationKey))
      : Modal.confirm({
          ...modalProps,
          content,
          onOk: () => deleteIterativeQuestion()
        });
  };

  const IterativeQuestionSet =
    !!Object.keys(iterativeSetAnswers).length &&
    iterativeSetAnswers?.map((questionSet, index) => {
      return (
        <Fragment key={index}>
          {!!curQuestionContent.description && index === 0 && (
            <div className="description">
              <p>{curQuestionContent.description}</p>
            </div>
          )}
          <div className="iterationName">
            <h5>
              {stringifyNumber(index + 1)} {curQuestionContent?.title}
            </h5>
          </div>
          {((iterativeSetAnswers.length > 1 && !iterativeSetAnswers[1]?.startsWith('uId')) ||
            (questionSet.startsWith('uId') && index !== 0)) && (
            <Button
              className="deleteIteration"
              disabled={
                sync_info.sync_in_progress ||
                subsection?.is_blocked ||
                isTyping ||
                sendAnswersLoading
              }
              onClick={() => deleteIterative(caseID, curQuestionContent.group_name, questionSet)}
            >
              Remove {curQuestionContent?.title}
            </Button>
          )}
          {nestedQuestion?.map(question => {
            return (
              <Questions
                curGroup={curGroup}
                questionSet={questionSet}
                iterationName={curQuestionContent.group_name}
                key={question.id}
                curQuestionContent={question}
                setIsTyping={setIsTyping}
              />
            );
          })}
        </Fragment>
      );
    });

  return (
    <>
      {IterativeQuestionSet}
      {iterativeSetAnswers.length < curQuestionContent.iteration_count && (
        <Button
          icon={addIterationIcon}
          className="iterationBtn primary-btn"
          onClick={addIterative}
          disabled={
            sync_info.sync_in_progress ||
            subsection?.is_blocked ||
            isTyping ||
            sendAnswersLoading ||
            disabled
          }
          tabIndex="-1"
        >
          Add {curQuestionContent?.title}
        </Button>
      )}
    </>
  );
};

Iterative.propTypes = {
  curQuestionContent: PropTypes.object,
  curField: PropTypes.object,
  nestedQuestion: PropTypes.array,
  curGroup: PropTypes.object
};

Iterative.defaultProps = {
  curQuestionContent: {},
  curField: {},
  nestedQuestion: [],
  curGroup: {}
};

export default Iterative;
