import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useQuery from 'hooks/useQuery';

import debounce from 'lodash.debounce';

import PropTypes from 'prop-types';

import { ImSpecPetitionerDashboardAction } from 'store/Actions';

import { Button, Col, Form, Input, Row } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

// import {debounce} from 'utils/helper';

const PetitionerDashboardTop = ({ onCreatePetitioner }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useQuery();

  const [form] = Form.useForm();

  const {
    petitioners: { count, isLoading }
  } = useSelector(state => state.ImSpecPetitionerDashboard);

  useEffect(() => {
    search && form.setFieldsValue({ params: search });
  }, [search, form]);

  const onChangeSearch = ({ params }) => {
    debounceSearch(params);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce(params => {
      dispatch(ImSpecPetitionerDashboardAction.setPage(1));
      navigate(
        params ? `${location.pathname}?search=${encodeURIComponent(params)}` : location.pathname
      );
    }, 500),
    []
  );

  return (
    <div className="dataWrapper-top">
      <Col>
        <span className="dataWrapper-top-title">Petitioners</span>
      </Col>
      <Row justify="space-between" align="middle" style={{ width: '100%', marginTop: '10px' }}>
        <div>
          <Form form={form} className="search-form" onValuesChange={onChangeSearch}>
            <Form.Item name="params">
              <Input
                addonBefore={<SearchOutlined />}
                placeholder="Search for petitioners"
                allowClear={{
                  clearIcon: <CloseOutlined style={{ color: '#000', fontSize: '14px' }} />
                }}
                className="case-dashboard-search"
              />
            </Form.Item>
          </Form>
        </div>
        <Col className="invite-client-btn-wr">
          <Button onClick={onCreatePetitioner} className="primary-btn">
            Create petitioner
          </Button>
        </Col>
      </Row>
      {search && !isLoading && <span className="search-result-count">Results found: {count}</span>}
    </div>
  );
};

PetitionerDashboardTop.propTypes = {
  onCreatePetitioner: PropTypes.func
};

export default PetitionerDashboardTop;
