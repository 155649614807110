import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

const AccountHasCreated = () => {
  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content resetPass">
        <Row>
          <h3>Account has already been created.</h3>
        </Row>

        <Row type="flex" justify="center" align="middle" className="publicForms_content_cloud">
          <p>You’re seeing this because the link that you used has been used previously.</p>
        </Row>

        <hr />

        <Row type="flex" justify="center" align="middle" className="publicForms_content_footer">
          <p>
            Please <Link to="/sign-in">sign in</Link> with the email address to which the invitation
            was sent.
          </p>
        </Row>
      </Col>
    </Row>
  );
};

export default AccountHasCreated;
