import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ImSpecContentAction} from 'store/Actions';

import useQuery from 'hooks/useQuery';
import moment from 'moment';

import {Avatar, Button, Input, Modal, Spin, Tooltip} from 'antd';
import {CheckCircleFilled, CheckCircleOutlined, CheckOutlined, LoadingOutlined} from '@ant-design/icons';
import {removeIcon, sendCommentIcon} from 'components/Icons/Icons';

const { TextArea } = Input;

const Comments = () => {
  const dispatch = useDispatch();
  const {
    ImSpecContent: {
      answers,
      entryComments: {
        comments,
        answer,
        getCommentLoading,
        sendCommentLoading,
        deleteCommentLoading
      }
    },
    user: { id: user_id }
  } = useSelector(state => ({
    ImSpecContent: state.ImSpecContent,
    user: state.auth.user
  }));

  const navigate = useNavigate();
  const location = useLocation();
  const { masterIntakeID } = useParams();

  const { comment } = useQuery();

  const commentsEndRef = useRef(null);
  const [message, setMessage] = useState('');
  const [curRemovedItem, setCurRemovedItem] = useState(null);

  useEffect(() => {
    if (comment && Object.keys(answers).length) {
      const curAnswer = Object.values(answers).reduce((prev, answer) => {
        const findItem = prev || (answer.field_type === 'Hidden' && Object.values(answer.field_value).reduce((prev, values) => {
          return prev || Object.values(values).find(answer => answer.id === +comment);
        }, undefined));
        if (typeof findItem !== 'undefined' && answer.id === +comment) return answer;
        return findItem;
      }, undefined)

      if (curAnswer) dispatch(
        ImSpecContentAction.seeComments(curAnswer, masterIntakeID)
      );
      else navigate(location.pathname, { replace: true })
    }
  }, [answers, comment, dispatch, location.pathname, masterIntakeID, navigate]);

  useEffect(() => {
    !!commentsEndRef.current &&
      !deleteCommentLoading &&
      commentsEndRef.current.scrollTo({
        top: commentsEndRef.current.scrollHeight,
        behavior: 'smooth'
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments, getCommentLoading]);

  const onChangeComment = message => {
    setMessage(message);
  };

  const sendComment = answerID => {
    !!message &&
      dispatch(
        ImSpecContentAction.addComment({ id: answerID, message: message }, answer, masterIntakeID)
      );
    setMessage('');
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendComment(answer.id);
    }
  };

  const onRemoveComment = (comment, answer) => {
    dispatch(ImSpecContentAction.removeComment(comment, answer, masterIntakeID));
    setCurRemovedItem(comment.id);
  };

  const resolveComments = answer => {
    dispatch(ImSpecContentAction.resolveComment(answer, masterIntakeID));
  };

  const onCloseCommentsModal = () => {
    setMessage('');
    dispatch(ImSpecContentAction.setEntryComments({ comments: [], answer: {} }));
    navigate(location.pathname, { replace: true });
  };

  return (
    <Modal
      centered
      open={!!comment && !!Object.keys(answer).length}
      onCancel={onCloseCommentsModal}
      closable={false}
      footer={null}
      mask={false}
      wrapClassName="commentsModal"
    >
      <Spin spinning={getCommentLoading} indicator={<LoadingOutlined style={{ fontSize: 40 }} />}>
        <div className="ant-modal-header commentsHeader">
          <div className="ant-modal-title">Comments</div>
          {!!comments.length && (
            <Tooltip
              placement="left"
              overlayClassName="comment_resolve"
              title={`${answer?.unresolved_comments_count !== 0 ? 'Resolve' : 'Resolved'}`}
              getPopupContainer={trigger => trigger.parentNode}
            >
              {answer?.unresolved_comments_count !== 0 ? (
                <CheckCircleOutlined onClick={() => resolveComments(answer)} />
              ) : (
                <CheckCircleFilled className="resolvedIcon" />
              )}
            </Tooltip>
          )}
        </div>
        <div className="commentsBlock" ref={commentsEndRef}>
          {!getCommentLoading && (
            <>
              {!!answer?.comments_count ? (
                comments.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`commentItem ${
                        curRemovedItem === item.id && deleteCommentLoading && 'removing'
                      }`}
                    >
                      <div className="commentItem_header">
                        <h6>
                          <Avatar
                            style={{
                              backgroundColor: '#33B18A',
                              marginRight: '7px'
                            }}
                            size="small"
                          >
                            {(
                              item?.user?.first_name?.charAt(0) + item?.user?.last_name?.charAt(0)
                            ).toUpperCase()}
                          </Avatar>
                          <span
                            className="userName"
                            style={{
                              color: item.resolved ? '#A1A5AF' : 'initial'
                            }}
                          >
                            {`${item?.user?.first_name} ${item?.user?.last_name}`}
                          </span>
                        </h6>
                        <span>{moment(item?.created_at).format('hh:mm A, ll')}</span>
                      </div>
                      <div className="commentItem_body">
                        <p
                          style={{
                            color: item.resolved ? '#A1A5AF' : 'initial'
                          }}
                        >
                          {item?.message}
                        </p>
                        {item.resolved ? (
                          <CheckOutlined style={{ fontSize: '20px', color: '#319E7A' }} />
                        ) : (
                          user_id === item.user.id && (
                            <Button
                              type="ghost"
                              icon={removeIcon}
                              onClick={() => onRemoveComment(item, answer)}
                            />
                          )
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="commentsBlock_empty">
                  <p>
                    If you have a question or a comment regarding this particular field, post it
                    here!
                  </p>
                </div>
              )}
            </>
          )}
        </div>
        <div className="inputWrapper">
          <TextArea
            value={message}
            onChange={e => onChangeComment(e.target.value)}
            onKeyDown={e => handleKeyDown(e)}
            autoSize={{ minRows: 2, maxRows: 3 }}
          />
          <Button
            className="comment-button"
            loading={sendCommentLoading}
            icon={sendCommentIcon}
            onClick={() => sendComment(answer.id)}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default Comments;
