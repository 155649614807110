import PropTypes from 'prop-types';
import { Col } from 'antd';

import Question from './question';

const groupSection = ({ group: { title, question_set }, sectionsAnswers }) => {
  return (
    <div className="group-section">
      <Col className="group-section-container">
        <h2 className="group-section_title">{title}</h2>
        {question_set.map(question => (
          <Question key={question.id} question={question} sectionsAnswers={sectionsAnswers} />
        ))}
      </Col>
    </div>
  );
};

groupSection.propTypes = {
  group: PropTypes.shape({
    title: PropTypes.string,
    question: PropTypes.array
  }),
  sectionsAnswers: PropTypes.object
};

groupSection.defaultProps = {
  group: {},
  sectionsAnswers: {}
};

export default groupSection;
