import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search).entries();
    let query = {};

    for (const [key, value] of params) {
      query = { ...query, [key]: value };
    }

    return query;
  }, [search]);
};

export default useQuery;
