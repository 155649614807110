import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

const Contacts = ({ contacts: { im_spec, attorney } }) => {
  return (
    <Row className="case-dashboard-contacts" justify="space-between" align="middle">
      {im_spec && (
        <Col className="case-dashboard-contacts-section">
          <Col className="case-dashboard-contacts-section-names">
            <Row align="middle" className="main">
              Main Contact
            </Row>
            <Row align="middle" className="first-name">
              {im_spec.first_name} {im_spec.last_name}
            </Row>
          </Col>
          <Col className="case-dashboard-contacts-section-email">
            <Row align="middle">
              <address>
                <a href={`mailto:${im_spec.email}`}>{im_spec.email}</a>
              </address>
            </Row>
          </Col>
        </Col>
      )}

      {attorney && (
        <Col className="case-dashboard-contacts-section">
          <Col className="case-dashboard-contacts-section-names">
            <Row align="middle" className="main">
              Immigration Attorney
            </Row>
            <Row align="middle" className="first-name">
              {attorney.first_name} {attorney.last_name}
            </Row>
          </Col>
          <Col className="case-dashboard-contacts-section-email">
            <Row align="middle">
              <address>
                <a href={`mailto:${attorney.email}`}>{attorney.email}</a>
              </address>
            </Row>
          </Col>
        </Col>
      )}
    </Row>
  );
};

Contacts.propTypes = {
  contacts: PropTypes.shape({
    im_spec: PropTypes.object,
    attorney: PropTypes.object
  })
};

Contacts.defaultProps = {
  contacts: {}
};

export default Contacts;
