import request from 'utils/request';

export const ImSpecPetitionerDashboardService = {
  getPetitioners: (pageSize, page) => {
    return request('GET', `api/petitioner/list?page_size=${pageSize}&page=${page}`);
  },
  getPetitionerCases: id => {
    return request('GET', `api/petitioner/${id}/cases `);
  },
  createPetitioner: data => {
    return request('POST', 'api/petitioner/create', data);
  },
  searchPetitioners: (page, pageSize, data) => {
    return request('POST', `api/petitioner/search?page=${page}&pageSize=${pageSize}`, data);
  }
};
