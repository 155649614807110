import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button, Col, Tooltip } from 'antd';
import { detachRep } from 'components/Icons/Icons';

const AssociatedAccounts = ({ account, permission, onDetach }) => {
  const onClick = () => {
    onDetach(account);
  };

  return (
    <Col span={8}>
      <div className="associated_accounts">
        <div className="associated_accounts-top">
          <div className="associated_accounts-field_section">
            <span className="associated_accounts-field_section-label">Name:</span>
            <Tooltip placement="top" title={account.full_name}>
              <Link
                to={`/client-management/userID=${account.id}`}
                className="associated_accounts-field_section-full_name"
              >
                {account.full_name}
              </Link>
            </Tooltip>
            {permission && (
              <Tooltip
                title="Detach"
                overlayClassName="upload-title-tooltip"
                getPopupContainer={triggerNode => triggerNode.parentNode}
              >
                <Button
                  type="ghost"
                  className="detach-rep-icon"
                  icon={detachRep}
                  onClick={onClick}
                />
              </Tooltip>
            )}
            {account.is_representative && <div className="is-rep">REP</div>}
          </div>
        </div>
        <div className="associated_accounts-bottom">
          <div className="associated_accounts-field_section">
            <span className="associated_accounts-field_section-label">Mail:</span>
            <Tooltip placement="top" title={account.email}>
              <span className="associated_accounts-field_section-email">{account.email}</span>
            </Tooltip>
          </div>
          <div className="associated_accounts-field_section">
            <span className="associated_accounts-field_section-label">State:</span>
            <div className={`associated_accounts-field_section-state ${account.state}`}>
              {account.state}
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

AssociatedAccounts.propTypes = {
  account: PropTypes.object,
  permission: PropTypes.string,
  onDetach: PropTypes.func
};

AssociatedAccounts.defaultProps = {
  account: {},
  permission: ''
};

export default AssociatedAccounts;
