import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Skeleton, Tooltip } from 'antd';
import { alreadySynced, syncIcon } from 'components/Icons/Icons';

const sectionsTableColumns = ({ role, syncMasterIntake }) => {
  const syncCol =
    role !== 'customer'
      ? [
          {
            title: null,
            dataIndex: 'sync',
            key: 'sync',
            ellipsis: true,
            width: '10%',
            render: (_, { sync_available, sync_in_progress, id, petitioner_name }) => {
              return (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Tooltip
                    title={
                      sync_available && !sync_in_progress
                        ? 'Sync details to employee cases'
                        : !sync_available && !sync_in_progress
                        ? 'Master intake already synced!'
                        : null
                    }
                    placement="top"
                    overlayClassName="sync-tooltip"
                  >
                    <Button
                      type="ghost"
                      className="sync_icon"
                      style={{
                        cursor: sync_available && !sync_in_progress ? 'pointer' : 'unset'
                      }}
                      onClick={sync_available ? () => syncMasterIntake(id, petitioner_name) : null}
                      icon={
                        sync_available && !sync_in_progress
                          ? syncIcon
                          : !sync_available && !sync_in_progress
                          ? alreadySynced
                          : null
                      }
                    >
                      {sync_in_progress ? (
                        <div className="in-progress">
                          {syncIcon}
                          <Skeleton.Input className="skeleton-loading" active />
                        </div>
                      ) : null}
                    </Button>
                  </Tooltip>
                </div>
              );
            }
          }
        ]
      : [];

  return [
    {
      title: 'Case type',
      dataIndex: 'case_type',
      key: 'case_type',
      ellipsis: true,
      width: '15%',
      render: (case_type, { id, petitioner_name }) =>
        role !== 'customer' ? (
          <div>
            <Link
              to={`/petitioner-dashboard/master-intake/${id}/${petitioner_name.replaceAll(
                ' ',
                '-'
              )}`}
              style={{ display: 'flex', width: 'fit-content' }}
            >
              <div className={`visa_name v${case_type.replaceAll(' ', '-')}`}>{case_type}</div>
            </Link>
          </div>
        ) : (
          <div className={`visa_name v${case_type.replaceAll(' ', '-')}`}>{case_type}</div>
        )
    },
    {
      title: 'Active cases',
      dataIndex: 'active_cases',
      key: 'active_cases',
      ellipsis: true,
      width: '20%',
      render: active_cases => (
        <span className="table-items" style={{ marginLeft: '45px' }}>
          {active_cases}
        </span>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '20%',
      render: status => (
        <div className={`section-status ${status.replaceAll(' ', '-')}`}>
          <span className="table-items">{status}</span>
        </div>
      )
    },
    {
      title: 'Last update',
      dataIndex: 'last_updated_at',
      key: 'last_updated_at',
      ellipsis: true,
      width: '15%',
      render: last_updated_at => (
        <span className="last-update">
          {last_updated_at !== null ? (
            <>
              {moment(last_updated_at).format('MM.DD.YYYY')}
              <span className="hours">{moment(last_updated_at).format('hh:mm A')}</span>
            </>
          ) : (
            <>--</>
          )}
        </span>
      )
    },
    {
      title: 'Last sync',
      dataIndex: 'sync_date',
      key: 'sync_date',
      ellipsis: true,
      width: '15%',
      render: sync_date => (
        <span className="last-update">
          {sync_date !== null ? (
            <>
              {moment(sync_date).format('MM.DD.YYYY')}
              <span className="hours">{moment(sync_date).format('hh:mm A')}</span>
            </>
          ) : (
            <>--</>
          )}
        </span>
      )
    },
    ...syncCol
  ];
};

export default sectionsTableColumns;
