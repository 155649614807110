import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import CheckboxList from 'components/shared/CheckboxList';
import { Form } from 'antd';

const Source = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const selectedList = Form.useWatch('source_options', form);
  Form.useWatch('source_options_all', form);

  const {
    filters: {
      source: { list }
    },
    filterAnswers: { source }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    source && !source.length && form.resetFields();
  }, [source, form]);

  useEffect(() => {
    if (typeof selectedList !== 'undefined') {
      dispatch(ImSpecCaseDashboardBetaAction.setSelectedSource(selectedList));
    }
  }, [dispatch, form, list.length, selectedList, source]);

  return (
    <CheckboxList
      form={form}
      plainOptions={list}
      filterOption="source_options"
      checkedValues={source}
    />
  );
};

export default Source;
