import { Col } from 'antd';
import 'pages/CustomerPages/Dashboard/index.scss';

const Perks = () => {
  return (
    <Col className="dashboard perks">
      <Col className="dashboard_header" type="flex" justify="center" align="top">
        <h2>Legalpad Partner Perks</h2>
        <p>
          Legalpad partners with companies to provide our customers with exclusive perks and
          discounts.
        </p>
        <p>
          Our partner companies excel in various verticals including HR, payroll, and finance that
          can support the growth of your business.
        </p>
        <p style={{ marginTop: '15px' }}>
          As a customer, you can redeem discounts from our channel partners below!
        </p>
      </Col>

      <Col className="dashboard_stepsContainer" type="flex" justify="center" align="top">
        <Col>
          <div className="stepTitle">
            <div>
              <span>Hubspot for Startups</span>
            </div>
          </div>
          <ul>
            <li>Get up to 30% off your first year</li>
            <li>Get 15% off ongoing subscriptions</li>
            <li>
              Redeem
              <a
                href="https://app.hubspot.com/signup-hubspot/hubspot-for-startups?partner-code=a5Xirn22bM4Q"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              if interested!
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Deel</span>
            </div>
          </div>
          <ul>
            <li>30% off contractors for your first year</li>
            <li>20% off employees for your first year</li>
            <li>
              Request a demo
              <a href="https://www.letsdeel.com/partners/legalpad" target="_blank" rel="noreferrer">
                here
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Firstbase</span>
            </div>
          </div>
          <ul>
            <li>
              10% off Firstbase services with discount code: <b>LEGALPAD</b>
            </li>
            <li>Free tax/legal consultation</li>
            <li>Post-Incorporation Package</li>
            <li>
              Check it out
              <a href="https://get.firstbase.io/legalpad" target="_blank" rel="noreferrer">
                here
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Middesk</span>
            </div>
          </div>
          <ul>
            <li>
              Middesk can take care of compliance for your existing registrations and help you
              register any time you hire in a new state
            </li>
            <li>Middesk provides 1 dashboard to manage all your state registrations</li>
            <li>Get a 20% first-year discount on any plan</li>
            <li>
              Check it out
              <a
                href="https://www.middesk.com/agent?referral_code=fd0e1cf5"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>AWS Activate</span>
            </div>
          </div>
          <ul>
            <li>Get $5,000 in AWS credits</li>
            <li>Get 1 year of AWS Business Support (up to $1,500)</li>
            <li>Get 80 credits for self-paced labs</li>
            <li>
              Check it out
              <a href="https://legalpad.io/aws-activate/" target="_blank" rel="noreferrer">
                here
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Capbase</span>
            </div>
          </div>
          <ul>
            <li>Get 20% off your first year when you incorporate with Capbase</li>
            <li>
              Check it out
              <a
                href="https://capbase.com/partner/refer/LEGALPAD20"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Zendesk</span>
            </div>
          </div>
          <ul>
            <li>
              <b>Startup Program Benefits:</b>
            </li>
            <ul>
              <li>Six months of access to the Zendesk Suite and Zendesk Sales CRM at no cost*</li>
              <li>Online office-hour sessions with Zendesk’s Startups customer success team</li>
              <li>Access to the Startups Community including events, content, and resources</li>
              <li>
                *Note: Qualifying products and services are listed on
                <a
                  href="https://support.zendesk.com/hc/en-us/articles/360036942773-Startups-Program-Credits-Coverage"
                  target="_blank"
                  rel="noreferrer"
                >
                  our website
                </a>
              </li>
            </ul>

            <li>
              <b>Requirements:</b>
            </li>
            <ul>
              <li>New Zendesk customers only</li>
              <li>Your company is under 100 employees</li>
              <li>Your startup funding is no greater than Series B</li>
            </ul>

            <li>
              Start your access
              <a
                href="https://www.zendesk.com/campaign/partner-startups/?partner_account=0016R00003C2Jd1QAF"
                target="_blank"
                rel="noreferrer"
              >
                here!
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>ForeCastr</span>
            </div>
          </div>
          <ul>
            <li>25% OFF your first year</li>
            <li>Includes free VIP onboarding</li>
            <li>
              Includes access to ForeCastr's INVESTOR CONNECT platform for early-stage investors
            </li>
            <li>
              <address>
                {' '}
                To redeem this offer, email{' '}
                <a href="mailto:jeff@forecastr.co"> jeff@forecastr.co </a>{' '}
              </address>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Stripe Atlas</span>
            </div>
          </div>
          <ul>
            <li>$100 off your setup fee</li>
            <li>First-year of registered agent fees waived</li>
            <li>Tax ID (EIN) filing assistance</li>
            <li>
              Create your Stripe account
              <a
                href="https://dashboard.stripe.com/register/atlas?atlas_invite=prod-legalpad-D8e34i"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>doola</span>
            </div>
          </div>
          <ul>
            <li>A special partnership price of $197/year</li>
            <li>Company Formation + Legal Address Creation</li>
            <li>EIN + US Bank Account Registration</li>
            <li>
              Start your access
              <a href="https://www.doola.com/?via=legalpad" target="_blank" rel="noreferrer">
                here!
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Segment by Twilio</span>
            </div>
          </div>
          <ul>
            <li>
              <b>Startup Program Benefits:</b>
            </li>
            <ul>
              <li>
                $50,000 in Segment credits that last 1 year and an additional year of credits if you
                still qualify. A 50% discount for the following year, then 1 year at a 25% discount.
              </li>
              <li>Access to our dealbook of over $1M in other software deals.</li>
            </ul>

            <li>
              <b>Requirements:</b>
            </li>
            <ul>
              <li>Less than $5M in funds raised</li>
              <li>
                Your company was founded less than 2 years ago. Companies outside US/CAN/UK are
                exempt if they are under 20 employees and do not have a sales contract already.
              </li>
            </ul>

            <li>
              Start your access
              <a
                href="https://airtable.com/shrLP3GSZnxt1WT2v?prefill_Partner%20Code=legalpad.io&hide_Partner%20Code=true&hide_Segment%20Code=true"
                target="_blank"
                rel="noreferrer"
              >
                here!
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Sales Booster</span>
            </div>
          </div>
          <ul>
            <li>Get a $500 discount on their services</li>
            <li>
              Get in touch with Todd, Sales Booster’s Head of Global Strategic Partnerships at
              <a href="mailto:todd.benson@leadspicker.com">todd.benson@leadspicker.com</a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Remotebase</span>
            </div>
          </div>
          <ul>
            <li>30% off on your first month's bill</li>
            <li>15% off your first year (annual contracts)</li>
            <li>
              Start your access
              <a
                href="https://www.remotebase.com/?rfsn=6839299.989801&utm_source=marketing_tool&utm_medium=refersion&utm_campaign=demand_referrals&referralCode=refLegalPad"
                target="_blank"
                rel="noreferrer"
              >
                here!
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Stellar Business Plans</span>
            </div>
          </div>
          <ul>
            <li>Free Consultation</li>
            <li>$500 off exclusively for Legalpad customers on consulting projects</li>
            <li>
              10% off on Business Plan and Pitch Deck Review Service with code '<b>LEGALPAD10</b>'
            </li>
            <li>
              To redeem, use this meeting link:
              <a
                href="https://calendly.com/onsstellar/partners-consultation"
                target="_blank"
                rel="noreferrer"
              >
                www.calendly.com/onsstellar/partners-consultation
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Kwanza</span>
            </div>
          </div>
          <ul>
            <li>$100 off your set-up fee</li>
            <li>First-year registered agent service</li>
            <li>EIN + US bank account creation</li>
            <li>Free 1:1 tax and legal consultation</li>
            <li>Post-incorporation package</li>
            <li>$100K in software & service perks</li>
            <li>
              Use code ‘<b>LP100</b>’ to get $100 off on{' '}
              <a href="https://usekwanza.com/" target="_blank" rel="noreferrer">
                www.usekwanza.com
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Brex</span>
            </div>
          </div>
          <ul>
            <li>
              Legalpad customers receive an increased sign up bonus of $250 when they spend $1000
            </li>
            <li>VIP onboarding provided</li>
            <li>
              Start your access
              <a href="http://Brex.com/legalpad" target="_blank" rel="noreferrer">
                here!
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Neo.Tax</span>
            </div>
          </div>
          <ul>
            <li>$500 off the processing fees to prepare your R&D tax credit</li>
            <li>
              Start your access
              <a href="http://www.neo.tax/partners/legalpad" target="_blank" rel="noreferrer">
                here!
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Outsite</span>
            </div>
          </div>
          <ul>
            <li>$50 off an Outsite Membership</li>
            <li>Access any Outsite Space with an Outsite Membership</li>
            <li>
              Apply promo code ‘<b>LEGALPAD</b>’ at checkout
            </li>
            <li>
              Start your access
              <a href="https://www.outsite.co/partners/legalpad" target="_blank" rel="noreferrer">
                here!
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Lazo</span>
            </div>
          </div>
          <ul>
            <li>Up to 30% off on Finance solutions</li>
            <li>20% off on Legal solutions</li>
            <li>10% off on Tax and Bookkeeping solutions</li>
            <li>
              Start your access
              <a href="https://www.lazo.us/partners-legalpad/" target="_blank" rel="noreferrer">
                here!
              </a>
            </li>
          </ul>
        </Col>

        <Col>
          <div className="stepTitle">
            <div>
              <span>Levro</span>
            </div>
          </div>
          <ul>
            <li>Free FX for the first $25,000 in transfers for new customers</li>
            <li>
              Sign up
              <a href="http://app.levro.com/apply" target="_blank" rel="noreferrer">
                here
              </a>
              with promo code <b>LEGALPAD23</b>
            </li>
          </ul>
        </Col>
      </Col>
    </Col>
  );
};

export default Perks;
