import { ImSpecActionTypes } from 'store/types';

const initialState = {
  petitioners: {
    list: [],
    page: 1,
    count: 0,
    pageSize: 100,
    pageSizeOptions: [50, 100, 150, 200],
    isLoading: false
  },
  entryPetitionerCases: {
    list: [],
    isLoading: false
  }
};

const ImSpecPetitionerDashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ImSpecActionTypes.PETITIONERS_LIST_LOADING:
      return {
        ...state,
        petitioners: {
          ...state.petitioners,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_PETITIONERS:
      return {
        ...state,
        petitioners: {
          ...state.petitioners,
          list: [...payload.results],
          count: payload.count
        }
      };
    case ImSpecActionTypes.SET_PETITIONERS_PAGE:
      return {
        ...state,
        petitioners: {
          ...state.petitioners,
          page: payload
        }
      };
    case ImSpecActionTypes.SET_PETITIONERS_PAGE_SIZE:
      return {
        ...state,
        petitioners: {
          ...state.petitioners,
          pageSize: payload
        }
      };
    case ImSpecActionTypes.ENTRY_PETITIONER_CASES_LOADING:
      return {
        ...state,
        entryPetitionerCases: {
          ...state.entryPetitionerCases,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_ENTRY_PETITIONER_CASES:
      return {
        ...state,
        entryPetitionerCases: {
          ...state.entryPetitionerCases,
          list: [...payload]
        }
      };
    default:
      return state;
  }
};

export default ImSpecPetitionerDashboardReducer;
