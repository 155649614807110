import PropTypes from 'prop-types';

import { Checkbox, Form } from 'antd';

const CheckboxList = ({
  form,
  plainOptions,
  filterOption,
  checkedValues,
  onQuickPetitionerChange
}) => {
  const onChange = list => {
    form.setFieldValue(filterOption, list);
    form.setFieldValue(`${filterOption}_all`, list.length === plainOptions.length);
    onQuickPetitionerChange && onQuickPetitionerChange(list);
  };

  const onCheckAllChange = e => {
    const list = e.target.checked
      ? plainOptions.map(option => (typeof option === 'object' ? option?.id : option))
      : [];
    form.setFieldValue(filterOption, list);

    form.setFieldValue(`${filterOption}_all`, e.target.checked);
    onQuickPetitionerChange && onQuickPetitionerChange(list);
  };

  return (
    <Form
      form={form}
      className="checkboxGroup"
      initialValues={{
        [filterOption]: checkedValues,
        [`${filterOption}_all`]: checkedValues?.length === plainOptions.length
      }}
    >
      <Form.Item className="checkboxGroup_all" name={`${filterOption}_all`}>
        <Checkbox onChange={onCheckAllChange} checked={form.getFieldValue(`${filterOption}_all`)}>
          All cases
        </Checkbox>
      </Form.Item>
      <Form.Item name={filterOption}>
        <Checkbox.Group value={checkedValues} onChange={onChange}>
          {plainOptions.map((option, index) => (
            <Checkbox key={index} value={typeof option === 'object' ? option?.id : option}>
              {typeof option === 'object' ? option?.value : option}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

CheckboxList.propTypes = {
  form: PropTypes.object,
  plainOptions: PropTypes.array,
  checkedValues: PropTypes.array,
  filterOption: PropTypes.string,
  onQuickPetitionerChange: PropTypes.func
};

CheckboxList.defaultProps = {
  form: {},
  plainOptions: [],
  checkedValues: undefined,
  filterOption: ''
};

export default CheckboxList;
