import PropTypes from 'prop-types';
import Icon, { FileSearchOutlined, GiftOutlined } from '@ant-design/icons';

import { referAFriend, accountProfile } from 'components/Icons/Icons';

const icons = {
  dataSearch: FileSearchOutlined,
  perksIcon: GiftOutlined,
  referAFriend,
  accountProfile
};

const CustomerSideBarIcons = ({ name, ...props }) => {
  const IconComponent = icons[name];
  return <Icon component={IconComponent} {...props} />;
};

CustomerSideBarIcons.propTypes = {
  name: PropTypes.string
};

CustomerSideBarIcons.defaultProps = {
  name: ''
};

export default CustomerSideBarIcons;
