import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from 'hooks/useQuery';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { Menu, Tooltip } from 'antd';
import { backIcon } from 'components/Icons/Icons';

import ChildCasesMenu from 'components/ImSpecSider/elements/ChildCasesMenu';
import GoogleDriveButton from 'components/ImSpecSider/elements/GoogleDriveButton';
import SectionIcon from 'components/Icons/SectionIcon';

import { sectionAndSubsectionNames } from 'utils/helper';

import {
  ImSpecContentAction,
  ImSpecSectionDashboardActions,
  ImSpecSideBarAction
} from 'store/Actions';
import { GlobalOutlined } from '@ant-design/icons';

const QuestionnaireSidebarItems = ({ collapsed, carousel, setCollapsed }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subsection, group } = useQuery();
  const { caseID, userID, sectionTitle } = useParams();

  const section = useMatch('/client-management/userID=:userID/:caseID/:sectionTitle');

  const hideChildCases = useFlag('hide-child-cases');
  const { flagsReady } = useFlagsStatus();

  const {
    ImSpecSideBar: {
      sidebarInfo: {
        sideBarData: { visaType },
        selected_sidebar,
        child_cases
      }
    },
    ImSpecContent: { loading: contentLoading }
  } = useSelector(state => ({
    ImSpecSideBar: state.ImSpecSideBar,
    ImSpecContent: state.ImSpecContent
  }));

  const [openSub, setOpenSub] = useState([]);
  const [sectionOpen, setSectionOpen] = useState([]);
  const [subSectionOpen, setSubSectionOpen] = useState([]);
  const [groupOpen, setGroupOpen] = useState([]);

  const getChildFlag = useCallback(() => {
    if (flagsReady) return hideChildCases;
  }, [flagsReady, hideChildCases]);

  useEffect(() => {
    const openSubs = (sectionTitle, subsection, group) => {
      setGroupOpen(group ? [sectionAndSubsectionNames(group)] : []);
      setSubSectionOpen(subsection ? [sectionAndSubsectionNames(subsection)] : []);
      setSectionOpen([sectionAndSubsectionNames(sectionTitle)]);
    };
    if (sectionTitle) openSubs(sectionTitle, subsection, group);
  }, [group, sectionTitle, subsection]);

  useEffect(() => {
    setOpenSub([...sectionOpen, ...subSectionOpen, ...groupOpen]);
  }, [sectionOpen, subSectionOpen, groupOpen]);

  useEffect(() => {
    if (!section) {
      setGroupOpen([]);
      setSectionOpen([]);
      setSubSectionOpen([]);
      setOpenSub([]);
      dispatch(ImSpecSectionDashboardActions.setCaseList([]));
      dispatch(ImSpecSideBarAction.setCaseDetails({}));
      dispatch(ImSpecSideBarAction.setSelectedSidebarInfo([]));
      dispatch(ImSpecContentAction.setSectionContent([]));
      dispatch(ImSpecContentAction.answersContent({}));
      dispatch(ImSpecSideBarAction.setChildCases([]));
    }
  }, [dispatch, section]);

  useEffect(() => {
    const flag = getChildFlag();
    if (flag === false) {
      section && caseID && dispatch(ImSpecSideBarAction.getImSpecSidebarCases(caseID));
    }
  }, [caseID, dispatch, getChildFlag, section]);

  useEffect(() => {
    const flag = getChildFlag();
    if (flag) {
      section &&
        !selected_sidebar.length &&
        dispatch(ImSpecSideBarAction.onSelectVisaType(caseID, userID));
    }
  }, [caseID, dispatch, getChildFlag, section, selected_sidebar.length, userID]);

  useEffect(() => {
    const flag = getChildFlag();
    if (flag) {
      section &&
        !Object.keys(visaType).length &&
        dispatch(ImSpecSideBarAction.getCaseDetails(caseID));
    }
  }, [caseID, dispatch, getChildFlag, section, visaType]);

  const openSection = ({ key }) => {
    navigate(`/client-management/userID=${userID}/${visaType.case_id}/${key.replaceAll(' ', '-')}`);
  };

  const onCLickSubSections = ({ key }) => {
    navigate(
      `${location.pathname}${
        subSectionOpen.includes(key) ? '' : `?subsection=${key.replaceAll(' ', '-')}`
      }`,
      { replace: true }
    );
  };

  const onCLickSubSectionsGroup = (subTitle, groupTitle) => {
    navigate(
      `${location.pathname}?subsection=${subTitle.replaceAll(
        ' ',
        '-'
      )}&group=${groupTitle.replaceAll(' ', '-')}`,
      { replace: true }
    );
  };

  const onSelect = ({ key }) => {
    if (key === 'back') {
      setCollapsed(false);
      carousel.current.decrement();
    }
  };

  const sectionsCallBack = ({ subsection_set = [], title: sectionTitle = '', icon = '' }) => {
    return subsection_set?.length !== 0
      ? {
          label: sectionTitle,
          key: sectionTitle,
          className: `visaType-pages ${openSub.includes(sectionTitle) && 'active-section'}`,
          popupClassName: 'sub-pop-up',
          disabled: contentLoading,
          onTitleClick: openSection,
          icon: (
            <Tooltip
              overlayClassName="sections-tooltip"
              trigger={collapsed ? 'hover' : ''}
              placement="right"
              title={sectionTitle}
            >
              <SectionIcon name={icon} />
            </Tooltip>
          ),
          children: [
            ...subsection_set.map(({ group_set = [], title: subTitle = '' }) => {
              return group_set?.length !== 0
                ? {
                    label: subTitle,
                    key: subTitle,
                    className: `sub-sections ${openSub.includes(subTitle) && 'active-subsection'}`,
                    popupClassName: 'sub-pop-up',
                    onTitleClick: onCLickSubSections,
                    children: [
                      ...group_set.map(({ title: groupTitle = '' }) => {
                        return {
                          label: groupTitle,
                          key: groupTitle,
                          className: `group ${openSub.includes(groupTitle) && 'active-group'}`,
                          onClick: () => onCLickSubSectionsGroup(subTitle, groupTitle)
                        };
                      })
                    ]
                  }
                : {
                    label: subTitle,
                    key: subTitle,
                    className: `sub-sections-item ${
                      openSub.includes(subTitle) && 'active-subsection-item'
                    }`,
                    onClick: onCLickSubSections
                  };
            })
          ]
        }
      : {
          label: sectionTitle,
          key: sectionTitle,
          className: 'visaType-pages',
          icon: <SectionIcon name={icon} />,
          disabled: contentLoading,
          onClick: openSection
        };
  };

  const caseSections = selected_sidebar.map(sectionsCallBack);

  const ChildCases = useMemo(() => {
    return flagsReady && !hideChildCases && child_cases.length ? (
      <ChildCasesMenu
        visaType={visaType}
        child_cases={child_cases}
        loading={contentLoading}
        collapsed={collapsed}
      />
    ) : null;
  }, [child_cases, collapsed, contentLoading, flagsReady, hideChildCases, visaType]);

  return (
    <div>
      <Menu
        mode="inline"
        className="ImSpec_Menu back"
        onClick={onSelect}
        openKeys={openSub}
        items={[
          {
            label: 'Back',
            key: 'back',
            icon: backIcon,
            className: 'backBtn',
            style: { marginBottom: '30px' }
          }
        ]}
      />
      {!!visaType?.visa_name && (
        <div className="user-details-sidebar">
          <div id="VisaTypes" className="visaTypesBlock" key={visaType?.case_id}>
            <GlobalOutlined />
            <span>
              {visaType?.visa_name} {visaType?.dependent || null}
            </span>
          </div>
          {visaType?.is_test && (
            <div className="test-account-tag">
              <div className="circle" />
              <span>Test case</span>
            </div>
          )}
        </div>
      )}
      <div className="menu-scroll">
        <Menu
          mode="inline"
          className="ImSpec_Menu case-section-menu"
          openKeys={openSub}
          selectedKeys={openSub}
          items={caseSections}
        />
        {ChildCases}
      </div>
      {!!visaType?.visa_name && <GoogleDriveButton caseID={caseID} collapsed={collapsed} />}
    </div>
  );
};

QuestionnaireSidebarItems.propTypes = {
  collapsed: PropTypes.bool,
  carousel: PropTypes.object,
  setCollapsed: PropTypes.func
};

QuestionnaireSidebarItems.defaultProps = {
  collapsed: false,
  carousel: {}
};

export default QuestionnaireSidebarItems;
