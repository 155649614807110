import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecSideBarAction } from 'store/Actions';

import { Menu } from 'antd';

import { backIcon } from 'components/Icons/Icons';

const SectionDashboardSidebarItems = ({ carousel, collapsed, selected_sidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userID, caseID } = useParams();

  const {
    sidebarInfo: {
      sideBarData: { beneficiary, associated, visaType },
      userDetails
    }
  } = useSelector(state => state.ImSpecSideBar);

  useEffect(() => {
    userID && caseID
      ? dispatch(ImSpecSideBarAction.getUserAllCases(userID, navigate))
      : dispatch(
          ImSpecSideBarAction.setSideBarData({
            beneficiary: [],
            associated: []
          })
        );
  }, [caseID, dispatch, navigate, userID]);

  const beneficiaryGroupChildren = beneficiary.map(
    ({ visa_name, case_id, dependent, is_dependent }) => {
      let dep = null;
      if (visa_name === 'I-539' || visa_name === 'I-539A') {
        dep = is_dependent ? `DEP ${dependent}` : 'BEN';
      }
      return {
        label: (
          <Link to={`/client-management/userID=${userID}/${case_id}`}>
            {visa_name} {dep}
          </Link>
        ),
        key: case_id,
        className: 'cases'
      };
    }
  );

  const associatedGroupChildren = associated.map(({ visa_name, case_id, user_details }) => {
    return {
      label: (
        <Link to={`/client-management/userID=${userID}/${case_id}`} className="associated-case">
          {visa_name}
          <div className="circle" />
          {user_details}
        </Link>
      ),
      key: case_id,
      className: 'cases'
    };
  });

  const onSelect = ({ key }) => {
    if (key === 'back') carousel.current.decrement();
  };

  return (
    <div>
      <Menu
        className="ImSpec_Menu back"
        selectedKeys={[caseID]}
        onClick={onSelect}
        items={[
          {
            label: 'Back',
            key: 'back',
            icon: backIcon,
            className: 'backBtn',
            style: {
              marginBottom: collapsed && !!selected_sidebar.length ? '60px' : '14px'
            }
          }
        ]}
      />
      {userDetails && !!Object.keys(userDetails).length && (
        <div className="user-details-sidebar">
          <div className="details">
            <span>
              {userDetails.first_name} {userDetails.last_name}
            </span>
          </div>
          {userDetails.is_test && (
            <div className="test-account-tag">
              <div className="circle" />
              <span>Test account</span>
            </div>
          )}
        </div>
      )}
      <Menu
        className="ImSpec_Menu cases-menu"
        selectedKeys={[visaType?.case_id ?? caseID]}
        onClick={onSelect}
        items={[
          ...(beneficiary.length && userDetails && Object.keys(userDetails).length
            ? [
                {
                  type: 'group',
                  label: `${userDetails.first_name}'s cases`,
                  children: beneficiaryGroupChildren
                }
              ]
            : []),
          ...(associated.length && userDetails && Object.keys(userDetails).length
            ? [
                {
                  type: 'group',
                  label: `Assigned to ${userDetails.first_name}`,
                  children: associatedGroupChildren
                }
              ]
            : [])
        ]}
      />
    </div>
  );
};

SectionDashboardSidebarItems.propTypes = {
  carousel: PropTypes.object,
  collapsed: PropTypes.bool,
  selected_sidebar: PropTypes.array
};

SectionDashboardSidebarItems.defaultProp = {
  carousel: {},
  collapsed: false,
  selected_sidebar: []
};

export default SectionDashboardSidebarItems;
