import { useState } from 'react';
import PropTypes from 'prop-types';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardAction } from 'store/Actions';

import AssignModal from 'components/shared/Modals/AssignModal';

import { Button, Col, Row } from 'antd';
import { assignIcon } from 'components/Icons/Icons';

const Contacts = ({ contacts: { im_spec, attorney, beneficiary, petitioner } }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { caseID } = useParams();

  const { caseSpecialists } = useSelector(state => state.ImSpecCaseDashboard);
  const [specialist, setSpecialist] = useState(null);

  const onClickAssign =
    ({ caseID, type, im_spec, attorney }) =>
    () => {
      if (type === 'im_spec') {
        setSpecialist(im_spec);
        navigate(`${location.pathname}?case_id=${caseID}&assign=im_spec`);
      }
      if (type === 'attorney') {
        setSpecialist(attorney);
        navigate(`${location.pathname}?case_id=${caseID}&assign=attorney`);
      }
    };

  const onAssignCase = (data, caseID, setDisabled) => {
    dispatch(ImSpecCaseDashboardAction.onSubmitAssignCase(caseID, data, navigate, setDisabled));
  };

  return (
    <Row
      style={{ marginBottom: '45px' }}
      className="case-dashboard-contacts"
      justify="space-between"
      align="middle"
    >
      <Col className="case-dashboard-contacts-section">
        <Col className="case-dashboard-contacts-section-names">
          <Row align="middle" className="label">
            Beneficiary:
          </Row>
          <Row align="middle" className="value">
            {beneficiary && (
              <Link className="petitioner" to={`/client-management/userID=${beneficiary?.id}`}>
                {beneficiary?.first_name} {beneficiary?.last_name}
              </Link>
            )}
          </Row>
        </Col>
        <Col className="case-dashboard-contacts-section-email">
          <Row align="middle">
            <Col align="middle" className="label">
              Petitioner:
            </Col>
            <Row align="middle" className="value">
              {typeof petitioner === 'string' ? (
                <span style={{ color: '#5d5e60' }}>{petitioner}</span>
              ) : (
                <Link className="petitioner" to={`/petitioner-dashboard/${petitioner?.id}`}>
                  {petitioner?.name}
                </Link>
              )}
            </Row>
          </Row>
        </Col>
      </Col>
      <Col className="case-dashboard-contacts-section">
        <Col className="case-dashboard-contacts-section-names">
          <Row align="middle" className="label">
            Attorney:
          </Row>
          <Row align="middle" className="value">
            <Button type="link" onClick={onClickAssign({ caseID, type: 'attorney', attorney })}>
              <span className="attorney">
                {attorney ? (
                  `${attorney.first_name} ${attorney.last_name}`
                ) : (
                  <>Assign {assignIcon}</>
                )}
              </span>
            </Button>
          </Row>
        </Col>
        <Col className="case-dashboard-contacts-section-email">
          <Row align="middle">
            <Col align="middle" className="label">
              IS:
            </Col>
            <Row align="middle" className="value">
              <Button type="link" onClick={onClickAssign({ caseID, type: 'im_spec', im_spec })}>
                <span className="im_spec">
                  {im_spec ? (
                    `${im_spec.first_name} ${im_spec.last_name}`
                  ) : (
                    <>Assign {assignIcon}</>
                  )}
                </span>
              </Button>
            </Row>
          </Row>
        </Col>
      </Col>
      <AssignModal
        onSubmit={onAssignCase}
        caseSpecialists={caseSpecialists}
        specialist={specialist}
        im_spec={im_spec}
        attorney={attorney}
      />
    </Row>
  );
};

Contacts.propTypes = {
  contacts: PropTypes.shape({
    im_spec: PropTypes.object,
    attorney: PropTypes.object,
    beneficiary: PropTypes.object,
    petitioner: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  })
};

Contacts.defaultProps = {
  contacts: {},
  im_spec: {},
  attorney: {},
  beneficiary: {}
};

export default Contacts;
