import { CustomerActionTypes } from '../types';

const initialState = {
  content: {
    section: {},
    isLoading: false
  },
  answers: {
    sectionsAnswers: {},
    isLoading: false
  }
};

const CustomerSectionReviewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CustomerActionTypes.REVIEW_SECTION_LOADING:
      return {
        ...state,
        content: {
          ...state.content,
          isLoading: payload
        }
      };
    case CustomerActionTypes.SET_REVIEW_SECTION:
      return {
        ...state,
        content: {
          ...state.content,
          section: { ...payload }
        }
      };
    case CustomerActionTypes.REVIEW_ANSWERS_LOADING:
      return {
        ...state,
        answers: {
          ...state.answers,
          isLoading: payload
        }
      };
    case CustomerActionTypes.SET_REVIEW_ANSWERS:
      return {
        ...state,
        answers: {
          ...state.answers,
          sectionsAnswers: { ...payload }
        }
      };
    default:
      return state;
  }
};

export default CustomerSectionReviewReducer;
