import request from 'utils/request';

export const HistoryLogCommentsThreadService = {
  getSyncedLogs: (caseID, filter_section, page) => {
    const section_name = `${
      filter_section !== 'all' ? `?section_name=${filter_section}&` : `?`
    }page=${page}&page_size=15`;
    return request('GET', `api/logs/case/${caseID}/synced${section_name}`);
  },
  getSyncedNestedLogs: log_uuid => {
    return request('GET', `api/logs/case/${log_uuid}/synced/nested`);
  },
  getAllRegularLogs: (caseID, filter_section, logs_active_tab, page) => {
    const section_name = filter_section !== 'all' ? `?section_name=${filter_section}` : '';
    const not_synced = logs_active_tab === 'all' ? 'not_synced=False' : 'not_synced=True';
    return request(
      'GET',
      `api/logs/case/${caseID}/regular${section_name}${
        section_name ? `&${not_synced}` : `?${not_synced}`
      }&page=${page}&page_size=15`
    );
  },
  getAllRegularNestedLogs: (answer_id, logs_active_tab) => {
    const not_synced = `?not_synced=${logs_active_tab === 'all' ? 'False' : 'True'}`;
    return request('GET', `api/logs/case/${answer_id}/regular/nested${not_synced}`);
  },
  getQuestionTitle: answerID => {
    return request('GET', `api/answer/${answerID}/title`);
  },
  getMasterIntakeSyncedLogs: (masterIntakeID, page) => {
    return request(
      'GET',
      `api/logs/master-intake/${masterIntakeID}/synced?page=${page}&page_size=15`
    );
  },
  getMasterIntakeSyncedNestedLogs: id => {
    return request('GET', `api/logs/master-intake/${id}/synced/nested`);
  },
  getMasterIntakeAllRegularLogs: (masterIntakeID, logs_active_tab, page) => {
    return request(
      'GET',
      `api/logs/master-intake/${masterIntakeID}/regular?not_synced=${
        logs_active_tab === 'all' ? 'False' : 'True'
      }&page=${page}&page_size=15`
    );
  },
  getMasterIntakeAllRegularNestedLogs: (answer_id, logs_active_tab) => {
    return request(
      'GET',
      `api/logs/master-intake/${answer_id}/regular/nested?not_synced=${
        logs_active_tab === 'all' ? 'False' : 'True'
      }`
    );
  },
  getMasterIntakeQuestionTitle: answerID => {
    return request('GET', `api/master-intake/answer/${answerID}/title`);
  },
  getCommentsTab: (caseID, section_name) => {
    const section = section_name ? `?section_name=${section_name}` : '';
    return request('GET', `api/comments/case/${caseID}/count${section}`);
  },
  getMasterIntakeCommentsTab: masterIntakeID => {
    return request('GET', `api/comments/master-intake/${masterIntakeID}/count`);
  },
  getComments: (caseID, page, filter_section, comments_active_tab) => {
    const tab =
      comments_active_tab === 'all'
        ? ''
        : `&resolved=${
            comments_active_tab === 'resolved'
              ? 'True'
              : comments_active_tab === 'unresolved'
              ? 'False'
              : ''
          }`;

    const section_name = `${
      filter_section !== 'all' ? `?section_name=${filter_section}&` : `?`
    }page=${page}&page_size=15`;

    return request('GET', `api/comments/case/${caseID}/thread${section_name}${tab}`);
  },
  getMasterIntakeComments: (masterIntakeID, page, comments_active_tab) => {
    const tab =
      comments_active_tab === 'all'
        ? ''
        : `&resolved=${
            comments_active_tab === 'resolved'
              ? 'True'
              : comments_active_tab === 'unresolved'
              ? 'False'
              : ''
          }`;

    return request(
      'GET',
      `api/comments/master-intake/${masterIntakeID}/thread?page=${page}&page_size=15${tab}`
    );
  },
  getNestedComments: (case_id, answer_id, comments_active_tab) => {
    const tab =
      comments_active_tab === 'all'
        ? ''
        : `?resolved=${
            comments_active_tab === 'resolved'
              ? 'True'
              : comments_active_tab === 'unresolved'
              ? 'False'
              : ''
          }`;
    return request('GET', `api/comments/case/${case_id}/answer/${answer_id}/nested${tab}`);
  },
  getMasterIntakeNestedComments: (master_intake_id, answer_id, comments_active_tab) => {
    const tab =
      comments_active_tab === 'all'
        ? ''
        : `?resolved=${
            comments_active_tab === 'resolved'
              ? 'True'
              : comments_active_tab === 'unresolved'
              ? 'False'
              : ''
          }`;
    return request(
      'GET',
      `api/comments/master-intake/${master_intake_id}/answer/${answer_id}/nested${tab}`
    );
  }
};
