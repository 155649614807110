import PropTypes from 'prop-types';

import { emptyLogs } from 'components/Icons/Icons';

const EmptyContent = ({ text }) => {
  return (
    <div className="empty-logs-content">
      {emptyLogs}
      <span className="empty-logs-content-text">{text}</span>
    </div>
  );
};

EmptyContent.propTypes = {
  text: PropTypes.string
};

EmptyContent.defaultProps = {
  text: ''
};

export default EmptyContent;
