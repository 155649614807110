import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AuthActions } from 'store/Actions';
import useQuery from 'hooks/useQuery';

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { Button, Col, Form, Input, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { successIcon, errorIcon } from 'components/Icons/Icons';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

const passVerificationError = {
  hasLength: false,
  isNotEmpty: false,
  hasUpper: false,
  hasLower: false,
  hasSpecial: false,
  hasNumber: false,
  confirm_password: false
};

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invitation_generated, confirmation_token } = useQuery();

  const {
    isLoading,
    invitedUser: { user: invitedUser, isLoading: invitedUserIsLoading }
  } = useSelector(state => state.auth);

  const onlyProdGrowsurf = useFlag('only-prod-growsurf');
  const { flagsReady } = useFlagsStatus();

  const [passwordError, setPasswordError] = useState(passVerificationError);
  const [touched, setTouched] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    !!invitation_generated &&
      !!confirmation_token &&
      dispatch(AuthActions.authInvitedUser(invitation_generated, confirmation_token, navigate));
  }, [confirmation_token, dispatch, navigate, invitation_generated]);

  useEffect(() => {
    invitedUser.first_name &&
      form.setFieldsValue({
        first_name: invitedUser.first_name,
        last_name: invitedUser.last_name,
        email: invitedUser.email
      });
  }, [form, invitedUser]);

  const getGrowsurfFlag = useCallback(() => {
    if (flagsReady) return onlyProdGrowsurf;
  }, [flagsReady, onlyProdGrowsurf]);

  const onFinish = newUserData => {
    dispatch(AuthActions.signUp(newUserData, !!invitedUser.email, navigate, getGrowsurfFlag()));
  };

  const onFieldsChange = (cur, all) => {
    const { touched, name, value } = cur[0];
    const passwordValue = all.find(e => e.name[0] === 'password').value;
    const confirmPasswordValue = all.find(e => e.name[0] === 'confirm_password').value;
    if (touched) {
      if (name[0] === 'password') {
        setTouched(touched);
        setPasswordError(passwordError => ({
          ...passwordError,
          isNotEmpty: !!value,
          hasLength: value.length > 11,
          hasUpper: /[A-Z]/.test(value),
          hasLower: /[a-z]/.test(value),
          hasSpecial: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value),
          hasNumber: /[0-9]/.test(value),
          confirm_password: confirmPasswordValue === value
        }));
      }
      if (name[0] === 'confirm_password') {
        setPasswordError(passwordError => ({
          ...passwordError,
          confirm_password: passwordValue === value
        }));
      }
    }

    const touchedOrHasErrorCallBack = e => e.touched === false || e.errors.length > 0;

    const touchedOrHasError = invitedUser.first_name
      ? all
          .filter(
            e => e.name[0] !== 'first_name' && e.name[0] !== 'last_name' && e.name[0] !== 'email'
          )
          .some(touchedOrHasErrorCallBack)
      : all.some(touchedOrHasErrorCallBack);

    setDisabled(
      Object.values(passwordError).some(val => val === false) ||
        touchedOrHasError ||
        passwordValue !== confirmPasswordValue
    );
  };

  return invitedUserIsLoading ? (
    <div className="authSpinner">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />
    </div>
  ) : (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content">
        <Row>
          <h1>Sign up with Legalpad</h1>
        </Row>
        <Row>
          <Form form={form} name="signUp" onFinish={onFinish} onFieldsChange={onFieldsChange}>
            <Form.Item
              name="first_name"
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                },
                {
                  max: 50,
                  message: 'Please enter a value shorter than 50 characters.'
                },
                {
                  pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
                  message: 'This field cannot contain numbers and special characters.'
                }
              ]}
            >
              <Input placeholder="First Name" disabled={invitedUser.first_name} />
            </Form.Item>
            <Form.Item
              name="last_name"
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                },
                {
                  max: 50,
                  message: 'Please enter a value shorter than 50 characters.'
                },
                {
                  pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
                  message: 'This field cannot contain numbers and special characters.'
                }
              ]}
            >
              <Input placeholder="Last Name" disabled={invitedUser.last_name} />
            </Form.Item>
            <Form.Item
              name="email"
              validateFirst
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'This field is required.'
                },
                () => ({
                  validator(_, value) {
                    if (
                      !!value &&
                      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        value
                      )
                    ) {
                      return Promise.reject(
                        new Error('You have entered an invalid e-mail address.')
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input placeholder="Email" disabled={invitedUser.email} />
            </Form.Item>
            <Form.Item
              name="phone_number"
              validateFirst
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'This field is required.'
                },
                () => ({
                  validator(_, value) {
                    if (value && !isValidPhoneNumber(value)) {
                      return Promise.reject(new Error('You have entered an invalid phone number'));
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <PhoneInput international placeholder="Enter Phone Number" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!'
                }
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!'
                }
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <div className="pass-errs">
              <ul>
                <li>
                  {touched && (passwordError.hasLength ? successIcon : errorIcon)}
                  At least 12 characters
                </li>
                <li>
                  {touched && (passwordError.hasLower ? successIcon : errorIcon)}1 lowercase letter
                </li>
                <li>{touched && (passwordError.hasNumber ? successIcon : errorIcon)}1 number</li>
              </ul>
              <ul>
                <li>
                  {touched && (passwordError.hasSpecial ? successIcon : errorIcon)}1 special
                  character
                </li>
                <li>
                  {touched && (passwordError.hasUpper ? successIcon : errorIcon)}1 uppercase letter
                </li>
                <li>
                  {touched &&
                    (passwordError.confirm_password && passwordError.isNotEmpty
                      ? successIcon
                      : errorIcon)}
                  Passwords match
                </li>
              </ul>
            </div>
            <Form.Item style={{ marginBottom: invitedUser.email ? '2rem' : '0' }}>
              <Button disabled={disabled || isLoading} htmlType="submit">
                {!isLoading ? (
                  <span>Sign up</span>
                ) : (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                )}
              </Button>
            </Form.Item>
          </Form>
        </Row>
        {!invitedUser.email && (
          <Row className="ifHasAcc">
            Already have an account? <Link to="/sign-in">Sign in</Link>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default SignUp;
