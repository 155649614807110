import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import FloatLabelSelect from 'components/shared/FloatLabelSelect';

import { usStates } from 'utils/constants';

const State = ({
  setRequiredErrorClassName,
  curSubsection,
  answersDataState,
  answers,
  curField,
  iterationName,
  questionSet,
  setSavingValue,
  setShowRequiredError,
  showRequiredError,
  radioDisable,
  inputRef,
  sync_info
}) => {
  const [UsState, setUsState] = useState(null);
  const [focus] = useState(false);

  useEffect(() => {
    focus && inputRef.current.focus();
  });

  useEffect(() => {
    if (!!answersDataState && Object.keys(answersDataState).length) {
      setUsState(answersDataState[curField.name]?.field_value || null);
    }
  }, [answersDataState, curField]);

  useEffect(() => {
    setRequiredErrorClassName(
      curField.is_required && showRequiredError && !UsState ? 'required-error-block' : ''
    );
  }, [UsState, curField.is_required, setRequiredErrorClassName, showRequiredError]);

  const onDropdownVisibleChange = open => {
    !open && curField.is_required && !UsState
      ? setShowRequiredError(true)
      : setShowRequiredError(false);
  };

  const handleOnChangeState = (e, type, name) => {
    setUsState(e);
    const formData = new FormData();
    formData.append('field_name', name);
    formData.append('field_value', e);
    formData.append('field_type', type);
    formData.append('group_name', !!iterationName ? iterationName : '');
    questionSet &&
      !questionSet.startsWith('uId') &&
      formData.append(
        'iteration_key',
        !!answers[iterationName]?.field_value || !questionSet.startsWith('uId')
          ? answers[iterationName]?.field_value[questionSet][name]?.iteration_key
          : null
      );
    setSavingValue(formData);

    curField.is_required && !UsState ? setShowRequiredError(true) : setShowRequiredError(false);
  };

  return (
    <FloatLabelSelect
      inputRef={inputRef}
      id={curField?.id}
      getPopupContainer={trigger => trigger.parentNode}
      showSearch
      label={`${curField?.placeholder}${curField.is_required ? '*' : ''}`}
      optionFilterProp="children"
      onDropdownVisibleChange={onDropdownVisibleChange}
      showArrow
      filterOption={(input, option) =>
        option.label.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.label.props.children
          .toLowerCase()
          .localeCompare(optionB.label.props.children.toLowerCase())
      }
      value={UsState}
      onSelect={e => handleOnChangeState(e, curField.type.type, curField?.name)}
      dropdownStyle={{ borderRadius: '12px' }}
      disabled={sync_info.sync_in_progress || curSubsection?.is_blocked || radioDisable}
      options={Object.entries(usStates).map(([key, value]) => ({
        key,
        value: key,
        label: <div className="ant-select-selection-item-label">{value}</div>
      }))}
    />
  );
};

State.propTypes = {
  setRequiredErrorClassName: PropTypes.func,
  curSubsection: PropTypes.object,
  answersDataState: PropTypes.object,
  answers: PropTypes.object,
  curField: PropTypes.object,
  iterationName: PropTypes.string,
  questionSet: PropTypes.string,
  setSavingValue: PropTypes.func,
  setShowRequiredError: PropTypes.func,
  showRequiredError: PropTypes.bool,
  radioDisable: PropTypes.bool,
  inputRef: PropTypes.object,
  sync_info: PropTypes.object
};

State.defaultProps = {
  curSubsection: {},
  answersDataState: {},
  answers: {},
  curField: {},
  iterationName: '',
  questionSet: '',
  showRequiredError: false,
  radioDisable: false,
  inputRef: {},
  sync_info: {}
};

export default State;
