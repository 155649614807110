import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

import FloatLabelInput from 'components/shared/FloatLabelInput';

import { stringifyNumber } from 'utils/constants';
import { Button, Form, Modal } from 'antd';
import { BulbOutlined, InfoCircleOutlined } from '@ant-design/icons';

const DependentFormModal = ({
  form_questionnaire,
  onCancelDep,
  field_name,
  iterationName,
  questionSet,
  answers,
  onCreateDep,
  loading
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { action } = useQuery();

  const [form] = Form.useForm();
  const visible = /(create_dependent)/.test(action);

  const [disabled, setDisabled] = useState(true);
  const [answersDataState, setAnswersDataState] = useState({});

  useEffect(() => {
    !!iterationName
      ? !questionSet.startsWith('uId') &&
        setAnswersDataState(answers[iterationName]?.field_value[questionSet])
      : setAnswersDataState(answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionSet, answers]);

  useEffect(() => {
    action && !form_questionnaire && navigate(location.pathname, { replace: true });
  }, [action, form_questionnaire, location.pathname, navigate]);

  const onCancel = () => {
    form.resetFields();
    setDisabled(true);
    onCancelDep();
  };

  const onFieldsChange = (cur, all) => {
    setDisabled(!!all.find(e => e.touched === false || e.errors.length > 0));
  };

  const onFinish = values => {
    onCreateDep(values, form);
    setDisabled(true);
  };

  return (
    <Modal
      title={`${
        answersDataState &&
        Object.keys(answersDataState) &&
        answersDataState[field_name] &&
        stringifyNumber(answersDataState[field_name].form_questions.length + 1)
      } Dependent`}
      className="form-modal"
      centered
      closable={false}
      open={visible}
      width="auto"
      footer={null}
    >
      <hr />
      {form_questionnaire &&
        form_questionnaire.map(
          ({ id, title, explanation, hint, explanation_doc_link, field_set }) => (
            <div className="question" key={id}>
              <h4 className="questionName">{title}</h4>
              {explanation && (
                <div className="hintNameBlock">
                  <div className="hintIcon">
                    <InfoCircleOutlined />
                  </div>
                  <h5 className="hintName">{explanation}</h5>
                </div>
              )}
              {hint && (
                <div className="hintNameBlock">
                  <div className="hintIcon">
                    <BulbOutlined />
                  </div>
                  <h5 className="hintName">{hint}</h5>
                </div>
              )}
              {explanation_doc_link && (
                <a
                  className="sample-link"
                  target="_blank"
                  href={explanation_doc_link}
                  rel="noreferrer"
                >
                  View Sample Document
                </a>
              )}

              <Form
                form={form}
                onFinish={onFinish}
                onFieldsChange={onFieldsChange}
                className="dependent-form"
              >
                {field_set.map(({ name, placeholder }, index) => (
                  <Form.Item
                    key={index}
                    name={name}
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: 'This field is required.'
                      },
                      {
                        max: 50,
                        message: 'Please enter a value shorter than 50 characters.'
                      },
                      {
                        pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
                        message: 'This field cannot contain numbers and special characters.'
                      }
                    ]}
                  >
                    <FloatLabelInput label={placeholder} />
                  </Form.Item>
                ))}
                <div className="form-submit">
                  <Form.Item>
                    <Button onClick={onCancel} className="cancel">
                      Cancel
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      disabled={loading || disabled}
                      type={loading || disabled ? 'disabled' : 'primary'}
                      htmlType="submit"
                      className="create"
                    >
                      Create
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )
        )}
    </Modal>
  );
};

DependentFormModal.propTypes = {
  form_questionnaire: PropTypes.array,
  onCancelDep: PropTypes.func,
  field_name: PropTypes.string,
  iterationName: PropTypes.string,
  questionSet: PropTypes.string,
  answers: PropTypes.object,
  onCreateDep: PropTypes.func,
  loading: PropTypes.bool
};

DependentFormModal.defaultProps = {
  form_questionnaire: [],
  field_name: '',
  iterationName: '',
  questionSet: '',
  answers: null,
  loading: false
};

export default DependentFormModal;
