import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AuthActions } from 'store/Actions';

import { Button, Col, Row } from 'antd';
import { congratulationsIcon } from 'components/Icons/Icons';

const VerifyMigratedAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: { email }
  } = location;

  const sendAnotherEmail = () => {
    dispatch(AuthActions.resendVerifyEmail({ email, type: 'reactivate_migrated' }, navigate));
  };

  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content resetPass">
        <Row>
          <h3>We have launched a new site! {congratulationsIcon}</h3>
        </Row>

        <Row type="flex" justify="center" align="middle" className="publicForms_content_cloud">
          <p>
            We have sent an email to <b>{email}</b> with reactivation instructions.
          </p>
          <p style={{ marginTop: 18 }}>
            {' '}
            Please reactivate your account to gain access to your case details.
          </p>
        </Row>

        <hr />

        <Row type="flex" justify="center" align="middle" className="publicForms_content_footer">
          <p>
            Can’t find the reactivation email in your inbox? <br />
            <Button type="link" onClick={sendAnotherEmail}>
              Click here{' '}
            </Button>
            to receive it again.
          </p>
        </Row>
      </Col>
    </Row>
  );
};

export default VerifyMigratedAccount;
