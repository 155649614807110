import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';

// actions
import { ImSpecSectionDashboardActions } from 'store/Actions';

// components
import SectionDashboardSidebarItems from 'components/ImSpecSider/elements/PageSidebars/SectionDashboardSidebarItems';
import MainSidebarItems from 'components/ImSpecSider/elements/PageSidebars/MainSidebarItems';
import QuestionnaireSidebarItems from 'components/ImSpecSider/elements/PageSidebars/QuestionnaireSidebarItems';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { DoubleLeftOutlined } from '@ant-design/icons';
import { legalPadIconText, legalPadIconWithoutText } from 'components/Icons/Icons';

import { Layout, Menu, Button } from 'antd';
import './ImSpecSider.scss';

const { Sider } = Layout;

const ImSpecSider = ({ collapsed, onTriggerClick, setCollapsed }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const carousel = useRef();

  const sectionDashboard = useMatch('/client-management/userID=:userID/:caseID');
  const section = useMatch('/client-management/userID=:userID/:caseID/:sectionTitle');

  const {
    sidebarInfo: { selected_sidebar }
  } = useSelector(state => state.ImSpecSideBar);

  const [carouselIndex, setCarouselIndex] = useState(null);

  useEffect(() => {
    if (section) {
      carousel.current.moveTo(2);
    } else if (sectionDashboard) {
      carousel.current.moveTo(1);
    } else {
      carousel.current.moveTo(0);
    }
  }, [section, sectionDashboard, carousel]);

  const onSelect = ({ key }) => {
    if (key === 'legalpad') {
      dispatch(ImSpecSectionDashboardActions.setCaseList([]));
      navigate('/dashboard');
    }
  };

  const onChangeCarousel = index => {
    setCarouselIndex(index);
  };

  return (
    <Sider
      className="ImSpec-sidebar"
      trigger={null}
      collapsible
      collapsedWidth={72}
      width={340}
      collapsed={collapsed}
    >
      {carouselIndex !== 1 && (
        <Button
          icon={<DoubleLeftOutlined rotate={collapsed ? 180 : 0} style={{ fontSize: '12px' }} />}
          onClick={onTriggerClick}
          className="sidebar-trigger"
        />
      )}
      <Menu
        mode="inline"
        className="ImSpec_Menu"
        onClick={onSelect}
        selectedKeys={[]}
        items={[
          {
            label: !collapsed ? legalPadIconText : 'Legalpad',
            key: 'legalpad',
            className: 'ImSpec-sidebar-logo',
            icon: legalPadIconWithoutText,
            style: { marginBottom: '30px' }
          }
        ]}
      />

      <Carousel
        onChange={onChangeCarousel}
        ref={carousel}
        showThumbs={false}
        renderThumbs={null}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
      >
        <MainSidebarItems collapsed={collapsed} />
        <SectionDashboardSidebarItems
          collapsed={collapsed}
          carousel={carousel}
          selected_sidebar={selected_sidebar}
        />
        <QuestionnaireSidebarItems
          collapsed={collapsed}
          carousel={carousel}
          setCollapsed={setCollapsed}
        />
      </Carousel>
    </Sider>
  );
};

ImSpecSider.propTypes = {
  collapsed: PropTypes.bool,
  onTriggerClick: PropTypes.func,
  setCollapsed: PropTypes.func
};

ImSpecSider.defaultProp = {
  collapsed: false
};

export default ImSpecSider;
