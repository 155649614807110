import { CustomerActionTypes } from '../types';
import { CustomerContentService } from 'services';
import { CustomerSidebarAction } from 'store/Actions/customer.sidebar.action';

import { sectionAndSubsectionNames } from 'utils/helper';
import { Modal } from 'antd';

const getCustomerContentForm =
  (masterIntakeID, petitionerName, subSectionTitle, caseID) => async dispatch => {
    try {
      await dispatch(SubsectionContentLoader(true));

      let res;

      if (!!masterIntakeID) {
        res = await CustomerContentService.getMasterIntakeForm(
          masterIntakeID,
          sectionAndSubsectionNames(petitionerName)
        );
        let subsection = res.data.subsection_set.find(
          e => e.title === sectionAndSubsectionNames(subSectionTitle)
        );

        const {
          sync_available,
          sync_date,
          sync_in_progress,
          synced_with_master_intake,
          master_intake_sync_obligation,
          last_sync_type
        } = res.data;
        dispatch(
          updateSyncStatus({
            sync_available,
            sync_date,
            sync_in_progress,
            synced_with_master_intake,
            master_intake_sync_obligation,
            last_sync_type
          })
        );
        dispatch(setCustomerContent(subsection));
        dispatch(setCustomerSectionContent(res.data));
      } else {
        res = await CustomerContentService.getContentForm(
          sectionAndSubsectionNames(subSectionTitle),
          caseID
        );
        const { last_sync_time, last_sync_source_type, sync_in_progress, sync_obligation } =
          res.data;

        dispatch(
          updateSyncStatus({
            last_sync_time,
            last_sync_source_type,
            sync_in_progress,
            sync_obligation
          })
        );
        dispatch(setCustomerContent(res.data));
      }
    } catch (e) {
      console.warn(e);
    } finally {
      await dispatch(SubsectionContentLoader(false));
    }
  };

const updateSyncStatus = data => {
  return {
    type: CustomerActionTypes.UPDATE_SYNC_SECTION_STATUS,
    payload: data
  };
};

const getContentAnswers =
  (masterIntakeID, petitionerName, sectionTitle, caseID) => async dispatch => {
    try {
      dispatch(answersLoading(true));

      let res;

      if (!!masterIntakeID) {
        res = await CustomerContentService.getMasterIntakeAnswers(
          masterIntakeID,
          sectionAndSubsectionNames(petitionerName)
        );
      } else {
        res = await CustomerContentService.getContentAnswers(
          sectionAndSubsectionNames(sectionTitle),
          caseID
        );
      }

      dispatch(setContentAnswers(res.data));
    } catch (e) {
      console.warn(e);
    } finally {
      setTimeout(() => dispatch(answersLoading(false)), 1000);
    }
  };

const removeIterativeQuestionSet = (caseID, groupName, iterationKey, sectionTitle) => async () => {
  try {
    await CustomerContentService.deleteIterationQuestionSet(
      caseID,
      groupName,
      iterationKey,
      sectionAndSubsectionNames(sectionTitle)
    );
  } catch (e) {
    console.warn(e);
  }
};

const sendFieldAnswer =
  ({
    sectionTitle,
    newAnswer,
    caseID,
    setUploadLoading,
    setUploadError,
    redirect,
    location,
    masterIntakeID,
    petitionerName
  }) =>
  async (dispatch, getState) => {
    try {
      // ((!!newAnswer.get('group_name') && !newAnswer.get('iteration_key')) || (newAnswer.get('field_type') === 'Radio Button')) && dispatch(sendAnswersLoader(true));
      dispatch(sendAnswersLoader(true));
      const {
        customerContent: { answers }
      } = getState();

      let res;
      if (masterIntakeID) {
        res = await CustomerContentService.sendMasterIntakeFieldAnswer(
          masterIntakeID,
          sectionAndSubsectionNames(petitionerName),
          newAnswer
        );
      } else {
        res = await CustomerContentService.sendFieldAnswer(
          caseID,
          sectionAndSubsectionNames(sectionTitle),
          newAnswer
        );
      }

      !!newAnswer.get('field_file') &&
        setUploadError(prevErrors =>
          prevErrors.map(error =>
            error.name === newAnswer.get('field_name') ? { id: error.id } : { ...error }
          )
        );

      const setNewAnswer = (answer, value) => {
        for (let key in answer) {
          if (answer[key].field_type === 'Hidden' && typeof answer[key].field_value === 'object') {
            setNewAnswer(answer[key].field_value, 'value');
          }

          if (value && typeof answer[key] === 'object') {
            setNewAnswer(answer[key]);
          }
          if (answer[key].id === res.data.data.id) {
            answer[key] = { ...answer[key], ...res.data.data };
          }
        }
      };
      if (!(!!newAnswer.get('group_name') && !newAnswer.get('iteration_key'))) {
        (newAnswer.get('field_type') !== 'Radio Button' ||
          newAnswer.get('field_type') !== 'Upload' ||
          newAnswer.get('field_type') !== 'Multi Upload') &&
          setNewAnswer(answers);
        (newAnswer.get('field_type') !== 'Radio Button' ||
          newAnswer.get('field_type') !== 'Upload' ||
          newAnswer.get('field_type') !== 'Multi Upload') &&
          dispatch(setContentAnswers(answers));
      }

      ((!!newAnswer.get('group_name') && !newAnswer.get('iteration_key')) ||
        newAnswer.get('field_type') === 'Radio Button') &&
        redirect(location.pathname);
      ((!!newAnswer.get('group_name') && !newAnswer.get('iteration_key')) ||
        newAnswer.get('field_type') === 'Radio Button') &&
        dispatch(getContentAnswers(masterIntakeID, petitionerName, sectionTitle, caseID));
    } catch (e) {
      console.warn(e);
    } finally {
      !!newAnswer.get('field_file') && setUploadLoading(false);
      dispatch(sendAnswersLoader(false));
      // ((!!newAnswer.get('group_name') && !newAnswer.get('iteration_key')) || (newAnswer.get('field_type') === 'Radio Button')) && dispatch(sendAnswersLoader(false));
    }
  };

const deleteFile =
  (fileID, answerID, setUploadLoading, masterIntakeID) => async (dispatch, getState) => {
    try {
      await setUploadLoading(true);
      const {
        customerContent: { answers }
      } = getState();
      let res;

      if (masterIntakeID) {
        res = await CustomerContentService.deleteMasterIntakeFile(fileID, answerID);
      } else {
        res = await CustomerContentService.deleteFile(fileID, answerID);
      }

      const setNewAnswer = (answer, value) => {
        for (let key in answer) {
          if (answer[key].field_type === 'Hidden' && typeof answer[key].field_value === 'object') {
            setNewAnswer(answer[key].field_value, 'value');
          }

          if (value && typeof answer[key] === 'object') {
            setNewAnswer(answer[key]);
          }

          if (answer[key].id === answerID) {
            answer[key] = {
              ...answer[key],
              field_value: JSON.stringify(res.data.data),
              existing_files: [...res.data.data]
            };
          }
        }
      };
      setNewAnswer(answers);

      dispatch(setContentAnswers(answers));
    } catch (e) {
      console.warn(e);
      // todo error handle
      // setUploadError(prevErrors => {
      //     let newState = [];
      //     if (prevErrors.some(({id}) => id === curField.id)) {
      //         newState = prevErrors.map((error) => error.name === curField.name ? {
      //             ...error,
      //             message: e.message
      //         } : {...error});
      //     } else {
      //         newState = [...prevErrors, {id: curField.id, name: curField.name, message: e.message}];
      //     }
      //
      //     return newState;
      // });
    } finally {
      setUploadLoading(false);
    }
  };

const onPreview = (file_id, masterIntakeID) => async () => {
  try {
    let res;
    if (masterIntakeID) {
      res = await CustomerContentService.checkMasterIntakePreviewLink(file_id);
    } else {
      res = await CustomerContentService.checkPreviewLink(file_id);
    }

    window.open(res.data.url);
  } catch (e) {
    console.warn(e);
  }
};

const seeComments = (answer, masterIntakeID) => async dispatch => {
  try {
    dispatch(CustomerSeeCommentsLoading(true));
    let res;

    if (masterIntakeID) {
      res = await CustomerContentService.getMasterIntakeAnswerComments(answer.id);
    } else {
      res = await CustomerContentService.getAnswerComments(answer.id);
    }

    dispatch(setEntryComments({ comments: [...res.data], answer }));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(CustomerSeeCommentsLoading(false));
  }
};

const setEntryComments = curItemLogs => {
  return {
    type: CustomerActionTypes.SET_ENTRY_COMMENTS,
    payload: curItemLogs
  };
};

const addComment = (comment, answer, masterIntakeID) => async (dispatch, getState) => {
  const {
    customerContent: {
      entryComments: { comments },
      answers
    }
  } = getState();
  const answerState = !!answer.iteration_key
    ? answers[answer.group_name].field_value[answer.iteration_key]
    : answers;

  try {
    dispatch(CustomerSaveCommentLoading(true));
    let res;

    if (masterIntakeID) {
      res = await CustomerContentService.saveMasterIntakeComment({
        answer: comment.id,
        message: comment.message
      });
    } else {
      res = await CustomerContentService.saveComment({
        answer: comment.id,
        message: comment.message
      });
    }

    dispatch(
      setEntryComments({
        comments: [...comments, res.data],
        answer: {
          ...answer,
          comments_count: answer.comments_count + 1,
          unresolved_comments_count: answer.unresolved_comments_count + 1
        }
      })
    );

    for (let key in answerState) {
      if (answerState[key].id === answer.id) {
        answerState[key].unresolved_comments_count += 1;
        answerState[key].comments_count += 1;
      }
    }
    dispatch(setContentAnswers({ ...answers }));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(CustomerSaveCommentLoading(false));
  }
};

const removeComment = (comment, answer, masterIntakeID) => async (dispatch, getState) => {
  const {
    customerContent: {
      entryComments: { comments },
      answers
    }
  } = getState();
  const answerState = !!answer.iteration_key
    ? answers[answer.group_name].field_value[answer.iteration_key]
    : answers;

  try {
    dispatch(CustomerDeleteCommentLoading(true));

    if (masterIntakeID) {
      await CustomerContentService.deleteMasterIntakeComment(comment.id);
    } else {
      await CustomerContentService.deleteComment(comment.id);
    }

    const updatedComments = comments.filter(elem => elem.id !== comment.id);

    dispatch(
      setEntryComments({
        comments: [...updatedComments],
        answer: {
          ...answer,
          comments_count: answer.comments_count + 1,
          unresolved_comments_count: answer.unresolved_comments_count - 1
        }
      })
    );

    for (let key in answerState) {
      if (answerState[key].id === answer.id) {
        answerState[key].unresolved_comments_count -= 1;
        answerState[key].comments_count -= 1;
      }
    }
    dispatch(setContentAnswers({ ...answers }));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(CustomerDeleteCommentLoading(false));
  }
};

const createDependent =
  (
    values,
    caseID,
    field_name,
    { location, navigate },
    sectionTitle,
    form,
    masterIntakeID,
    petitionerName
  ) =>
  async dispatch => {
    try {
      dispatch(setCreateDependentLoading(true));
      await CustomerContentService.createDependent(
        values,
        caseID,
        field_name,
        sectionAndSubsectionNames(sectionTitle)
      );

      await navigate(location.pathname);

      await dispatch(getContentAnswers(masterIntakeID, petitionerName, sectionTitle, caseID));
      await dispatch(CustomerSidebarAction.getCaseTypes(caseID, petitionerName));
    } catch (e) {
      console.warn(e);
    } finally {
      dispatch(setCreateDependentLoading(false));
      dispatch(setDependentForm(null, null, null, null, navigate, location));
      form && form.resetFields();
    }
  };

const deleteDependent =
  (data, caseID, field_name, sectionTitle, masterIntakeID, petitionerName) => async dispatch => {
    try {
      await CustomerContentService.deleteDependent(
        data,
        caseID,
        field_name,
        sectionAndSubsectionNames(sectionTitle)
      );

      const modalProps = {
        width: 438,
        centered: true,
        icon: null,
        title: 'Success!',
        className: 'submit-section-confirm-modal delete-dependent',
        okButtonProps: { className: 'submit-section-confirm-modal-submit_btn' },
        okText: 'Close',
        content: (
          <>
            <span className="submit-section-confirm-modal-text">
              Dependent form successfully removed.
            </span>
          </>
        )
      };

      Modal.info({ ...modalProps });

      dispatch(getContentAnswers(masterIntakeID, petitionerName, sectionTitle, caseID));
      dispatch(CustomerSidebarAction.getCaseTypes(caseID, petitionerName));
    } catch (e) {
      console.warn(e);
    }
  };

const setDependentForm =
  (form_questions, name, iterationName, questionSet, navigate, location) => async dispatch => {
    dispatch(
      setFormQuestionnaire({
        form_questions,
        field_name: name,
        iterationName,
        questionSet
      })
    );

    form_questions && navigate(`${location.pathname}?action=create_dependent`);
  };

const setCreateDependentLoading = loading => {
  return {
    type: CustomerActionTypes.SET_CREATE_DEPENDENT_LOADING,
    payload: loading
  };
};

const setFormQuestionnaire = questionnaire => {
  return {
    type: CustomerActionTypes.SET_FORM_QUESTIONNAIRE,
    payload: questionnaire
  };
};

const setCustomerContent = subsectionInfo => {
  return {
    type: CustomerActionTypes.SUBSECTION_CONTENT,
    payload: subsectionInfo
  };
};

const setCustomerSectionContent = sectionInfo => {
  return {
    type: CustomerActionTypes.SECTION_CONTENT,
    payload: sectionInfo
  };
};

const setContentAnswers = answers => {
  return {
    type: CustomerActionTypes.ANSWERS_CONTENT,
    payload: answers
  };
};

const answersLoading = loading => {
  return {
    type: CustomerActionTypes.ANSWERS_CONTENT_LOADING,
    payload: loading
  };
};

const sendAnswersLoader = loading => {
  return {
    type: CustomerActionTypes.NEW_ANSWER_LOADING,
    payload: loading
  };
};

const SubsectionContentLoader = loading => {
  return {
    type: CustomerActionTypes.SUBSECTION_CONTENT_LOADING,
    payload: loading
  };
};

const CustomerDeleteCommentLoading = loading => {
  return {
    type: CustomerActionTypes.SECTION_DELETE_ANSWERS_LOADING,
    payload: loading
  };
};

const CustomerSeeCommentsLoading = loading => {
  return {
    type: CustomerActionTypes.SECTION_SEE_COMMENTS_LOADING,
    payload: loading
  };
};

const CustomerSaveCommentLoading = loading => {
  return {
    type: CustomerActionTypes.SECTION_SAVE_COMMENTS_LOADING,
    payload: loading
  };
};

export const CustomerContentAction = {
  getCustomerContentForm,
  setCustomerContent,
  getContentAnswers,
  setContentAnswers,
  onPreview,
  removeIterativeQuestionSet,
  sendFieldAnswer,
  deleteFile,
  seeComments,
  setEntryComments,
  addComment,
  removeComment,
  setDependentForm,
  setFormQuestionnaire,
  createDependent,
  deleteDependent
};
