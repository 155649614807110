import { useEffect, useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';

import HeadNavbar from 'components/HeadNavbar';
import ImSpecSider from 'components/ImSpecSider';
import MasterIntakeSider from 'components/MasterIntakeSider';
import ImSpecPages from 'pages/ImSpecPages';

import { Layout } from 'antd';

const { Content } = Layout;

const ImSpecLayout = () => {
  const sectionDashboard = useMatch('/client-management/userID=:userID/:caseID');
  const [ImSpecSideBarCollapsed, setImSpecSideBarCollapsed] = useState(false);
  const { masterIntakeID } = useParams();

  useEffect(() => {
    if (sectionDashboard) setImSpecSideBarCollapsed(false);
  }, [sectionDashboard]);

  useEffect(() => {
    if (masterIntakeID) setImSpecSideBarCollapsed(false);
  }, [masterIntakeID]);

  const onTriggerClick = () => {
    setImSpecSideBarCollapsed(collapsed => !collapsed);
  };

  return (
    <Layout hasSider className="root-layout">
      {masterIntakeID ? (
        <MasterIntakeSider />
      ) : (
        <ImSpecSider
          onTriggerClick={onTriggerClick}
          collapsed={ImSpecSideBarCollapsed}
          setCollapsed={setImSpecSideBarCollapsed}
        />
      )}
      <Content
        className={`page-content-wrapper ${ImSpecSideBarCollapsed && 'small-page-content-wrapper'}`}
      >
        <HeadNavbar />
        <ImSpecPages />
      </Content>
    </Layout>
  );
};

export default ImSpecLayout;
