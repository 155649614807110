import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

const EmailSuccess = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate('/sign-in');
  };

  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content resetPass">
        <Row>
          <h4>Account email address successfully confirmed</h4>
        </Row>
        <Row>
          <Button onClick={handleRoute}>Access my account</Button>
        </Row>
      </Col>
    </Row>
  );
};

export default EmailSuccess;
