import { useDispatch } from 'react-redux';
import { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Input, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { removeIcon } from 'components/Icons/Icons';

import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

const SavedViewCollapsePanelHeader = ({
  id,
  name,
  filter_params,
  savedViewEditable,
  setSavedViewEditable,
  handleRenameSavedView,
  onDeleteSavedView,
  onDrawerClose
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [newSavedViewName, setNewSavedViewName] = useState('');

  const onRenameSavedView = (e, id, name) => {
    e.stopPropagation();
    setSavedViewEditable({ [id]: true });
    setNewSavedViewName(name);
    inputRef && inputRef.current && inputRef.current.select();
  };

  const onChangeName = e => {
    setNewSavedViewName(e.target.value);
  };

  return (
    <>
      <div className="header-input">
        <Input
          ref={inputRef}
          onClick={event => {
            event.stopPropagation();
          }}
          readOnly={!savedViewEditable[id]}
          value={savedViewEditable[id] ? newSavedViewName : name}
          defaultValue={name}
          onChange={onChangeName}
          onPressEnter={
            savedViewEditable[id] ? handleRenameSavedView(id, filter_params, name) : () => {}
          }
          onBlur={savedViewEditable[id] ? handleRenameSavedView(id, filter_params, name) : () => {}}
        />
      </div>
      <div className="header-actions">
        <Tooltip placement="top" title="Rename">
          <Button
            onClick={e => {
              onRenameSavedView(e, id, name);
            }}
            type="ghost"
            icon={<EditOutlined />}
          />
        </Tooltip>

        <Tooltip placement="top" title="Delete">
          <Button
            onClick={e => onDeleteSavedView(e, id, name)}
            type="ghost"
            style={{ color: '#ED6762', opacity: savedViewEditable[id] ? '0.4' : '1' }}
            icon={removeIcon}
            disabled={savedViewEditable[id]}
          />
        </Tooltip>
        <Button
          className="applyBtn"
          type="ghost"
          onClick={event => {
            event.stopPropagation();
            localStorage.setItem('case-dashboard-filters', JSON.stringify(filter_params));
            onDrawerClose();
            dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
            dispatch(ImSpecCaseDashboardBetaAction.applyFilters(id, name, filter_params));
          }}
        >
          Apply view
        </Button>
      </div>
    </>
  );
};

SavedViewCollapsePanelHeader.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  filter_params: PropTypes.object,
  savedViewEditable: PropTypes.object,
  setSavedViewEditable: PropTypes.func,
  handleRenameSavedView: PropTypes.func,
  onDeleteSavedView: PropTypes.func,
  onDrawerClose: PropTypes.func
};

export default SavedViewCollapsePanelHeader;
