import PropTypes from 'prop-types';
import CaseType from './CaseType';
import State from './State';
import LastUpdate from './LastUpdate';
import Petitioner from './Petitioner';
import Status from './Status';
import Beneficiary from './Beneficiary';
import IS from './IS';
import IA from './IA';
import CaseID from './CaseID';
import Source from './Source';

import { filtersEmpty } from 'components/Icons/Icons';

const DrawerContent = ({ filter }) => {
  const filterMap = {
    Empty: <div>{filtersEmpty}</div>,
    'Case type': <CaseType />,
    State: <State />,
    'Submitted Cases': <LastUpdate type="last_submitted" />,
    'Edited Cases': <LastUpdate type="last_edited" />,
    'Reviewed Cases': <LastUpdate type="last_reviewed" />,
    'Commented Cases': <LastUpdate type="last_commented" />,
    'Created Cases': <LastUpdate type="last_created" />,
    Petitioner: <Petitioner />,
    Status: <Status />,
    Beneficiary: <Beneficiary />,
    IS: <IS />,
    IA: <IA />,
    'Case ID': <CaseID />,
    Source: <Source />
  };

  const content = filterMap[filter] || null;

  return (
    <div className="filtersContent">
      {content && (
        <div>
          <div className="filtersContent_header">
            {filter !== 'Empty' ? <h2>{filter}</h2> : null}
          </div>
          <div className="filtersContent_body">{content}</div>
        </div>
      )}
    </div>
  );
};

DrawerContent.propTypes = {
  filter: PropTypes.string
};

DrawerContent.defaultProps = {
  filter: ''
};

export default DrawerContent;
