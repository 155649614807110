import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import { Button, Checkbox, Form, Input } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

const CaseType = ({ case_type, showTooltip, setShowTooltip }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const selectedList = Form.useWatch('visa_name', form);
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  const {
    filters: {
      caseType: { list }
    },
    appliedSavedView: { id: savedViewId, name: savedViewName }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    setFilteredOptions(list);
    setSearchValue('');
  }, [list, showTooltip]);

  useEffect(() => {
    if (typeof selectedList !== 'undefined') {
      dispatch(ImSpecCaseDashboardBetaAction.setSelectedCaseType(selectedList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldValue('visa_name_all', list.length === case_type.length);
  }, [case_type.length, form, list.length]);

  useEffect(() => {
    if (case_type && !case_type.length) {
      form.resetFields();
      setSearchValue('');
    }
  }, [case_type, form]);

  const handleInputChange = e => {
    const inputValue = e.target.value;

    setSearchValue(inputValue);

    const filtered = list.filter(option =>
      option.visa_name?.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const onCheckAllChange = e => {
    form.setFieldValue('visa_name', e.target.checked ? list.map(option => option) : []);
    form.setFieldValue('visa_name_all', e.target.checked);
    dispatch(
      ImSpecCaseDashboardBetaAction.setSelectedCaseType(
        e.target.checked ? list.map(option => option) : []
      )
    );
    dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
    dispatch(ImSpecCaseDashboardBetaAction.applyFilters(savedViewId, savedViewName));
  };

  const handleCheckboxChange = checked => {
    const checkedValue = checked.map(id => list.find(option => option.id === id));
    let uniqueArray;

    if (filteredOptions.length === list.length) {
      uniqueArray = [...checkedValue].filter(
        (obj, index, self) => index === self.findIndex(item => item.id === obj.id)
      );
    } else {
      uniqueArray = [...checkedValue, ...case_type].filter(
        (obj, index, self) => index === self.findIndex(item => item.id === obj.id)
      );
    }

    form.setFieldValue('visa_name', uniqueArray);
    form.setFieldValue('visa_name_all', uniqueArray.length === list.length);

    dispatch(ImSpecCaseDashboardBetaAction.setSelectedCaseType(uniqueArray));
    dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
    dispatch(ImSpecCaseDashboardBetaAction.applyFilters(savedViewId, savedViewName));
  };

  return (
    <div className="caseTypeBlock">
      <Form form={form} className="search-form" initialValues={{ visa_name: case_type }}>
        <Input
          addonBefore={<SearchOutlined />}
          allowClear={{
            clearIcon: <CloseOutlined style={{ color: '#000', fontSize: '14px' }} />
          }}
          className="case-type-search"
          value={searchValue}
          onChange={handleInputChange}
          placeholder="Search for case type"
        />
        <Form.Item className="checkboxGroup_all" name="visa_name_all">
          <Checkbox onChange={onCheckAllChange} checked={form.getFieldValue('visa_name_all')}>
            All cases
          </Checkbox>
        </Form.Item>
        <Form.Item name="visa_name" getValueProps={() => {}}>
          <Checkbox.Group
            value={case_type?.map(option => option.id)}
            onChange={handleCheckboxChange}
            className="checkboxGroup"
            options={filteredOptions.map(option => ({
              label: option.visa_name,
              value: option.id
            }))}
          />
        </Form.Item>
      </Form>
      <Button className="addedSubmitSection" type="ghost" onClick={() => setShowTooltip(false)}>
        Close
      </Button>
    </div>
  );
};

CaseType.propTypes = {
  setShowTooltip: PropTypes.func,
  case_type: PropTypes.array,
  showTooltip: PropTypes.bool
};

CaseType.defaultProps = {
  case_type: [],
  showTooltip: false
};

export default CaseType;
