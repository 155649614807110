import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import DrawerContent from './DrawerElements';
import CaseDashboardSavedViews from './CaseDashboardSavedViews';

import { filterNameHelper, isFilterAnswersEmpty } from 'utils/helper';

import { Button, Checkbox, Col, Drawer, Row, Space, Tabs, Dropdown, Menu } from 'antd';
import { CloseOutlined, DownOutlined, InfoCircleFilled, ReloadOutlined } from '@ant-design/icons';
import { saveIcon } from 'components/Icons/Icons';
import './CaseDashboardDrawer.scss';

const CaseDashboardDrawer = ({
  onDrawerCloseMain,
  openDrawer,
  showModal,
  activeContentTab,
  setActiveContentTab,
  activeTab,
  setActiveTab
}) => {
  const dispatch = useDispatch();
  const [lastUpdate, setLastUpdate] = useState('');
  const [openKeys, setOpenKeys] = useState([]);

  const {
    filterAnswers,
    savedViews: { list: savedViewsList },
    appliedSavedView: { id: savedViewId, name: savedViewName }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  const caseDashboardSavedView = JSON.parse(localStorage.getItem('case-dashboard-saved_view'));

  const onDrawerClose = () => {
    onDrawerCloseMain();
    setActiveContentTab('1');
    setLastUpdate('');
  };

  const onApplyFilters = () => {
    onDrawerCloseMain();
    onDrawerClose();
    dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
    dispatch(ImSpecCaseDashboardBetaAction.applyFilters());
  };

  const {
    case_type = [],
    state = [],
    last_submitted = {},
    last_edited = {},
    last_reviewed = {},
    last_commented = {},
    last_created = {},
    petitioner = {},
    status = [],
    beneficiary = [],
    im_spec = {},
    attorney = {},
    case_id = [],
    source = [],
    include_test_cases
  } = filterAnswers;

  const filters = [
    'Empty',
    'Case type',
    'State',
    'Last update',
    'Petitioner',
    'Status',
    'Beneficiary',
    'IS',
    'IA',
    'Case ID',
    'Source'
  ];

  const items = savedViewId
    ? [
        {
          icon: saveIcon,
          label: 'Save as new',
          key: '2',
          onClick: () => showModal()
        },
        {
          icon: <ReloadOutlined />,
          label: 'Revert changes',
          key: '3',
          onClick: () =>
            dispatch(
              ImSpecCaseDashboardBetaAction.applyFilters(
                caseDashboardSavedView.id,
                caseDashboardSavedView.name,
                savedViewsList.find(view => view.id === savedViewId).filter_params
              )
            )
        }
      ]
    : [
        {
          icon: saveIcon,
          label: 'Save this view',
          key: '1',
          onClick: () => showModal()
        }
      ];

  const isSelected = filter => {
    switch (filter) {
      case 'Case type':
        return !!case_type.length;
      case 'State':
        return !!state.length;
      case 'Last update':
        return (
          !!Object.keys(last_submitted).length ||
          !!Object.keys(last_edited).length ||
          !!Object.keys(last_reviewed).length ||
          !!Object.keys(last_commented).length ||
          !!Object.keys(last_created).length
        );
      case 'Submitted':
        return !!Object.keys(last_submitted).length;
      case 'Edited':
        return !!Object.keys(last_edited).length;
      case 'Reviewed':
        return !!Object.keys(last_reviewed).length;
      case 'Commented':
        return !!Object.keys(last_commented).length;
      case 'Created':
        return !!Object.keys(last_created).length;
      case 'Petitioner':
        return !!petitioner.options?.length;
      case 'Status':
        return !!status.length;
      case 'Beneficiary':
        return !!beneficiary.length;
      case 'IS':
        return !!im_spec.options?.length;
      case 'IA':
        return !!attorney.options?.length;
      case 'Case ID':
        return !!case_id.length;
      case 'Source':
        return !!source.length;
      default:
        return null;
    }
  };

  const lastUpdateOptions = filter => [
    {
      label: <> Last update {isSelected(filter) && <span className="yellowDot" />} </>,
      key: 'last_update_sub_menu',
      popupOffset: [10, 0],
      children: [
        {
          label: (
            <div className="menuItemsLabel">
              <span>Submitted</span>
              {isSelected('Submitted') && (
                <div className="tabActive">
                  <span className="yellowDot" />
                  <CloseOutlined
                    onClick={() =>
                      dispatch(ImSpecCaseDashboardBetaAction.clearFilters('last_submitted'))
                    }
                  />
                </div>
              )}
            </div>
          ),
          key: 'Submitted',
          className: 'lastUpdatePopup'
        },
        {
          label: (
            <div className="menuItemsLabel">
              <span>Edited</span>
              {isSelected('Edited') && (
                <div className="tabActive">
                  <span className="yellowDot" />
                  <CloseOutlined
                    onClick={() =>
                      dispatch(ImSpecCaseDashboardBetaAction.clearFilters('last_edited'))
                    }
                  />
                </div>
              )}
            </div>
          ),
          key: 'Edited',
          className: 'lastUpdatePopup'
        },
        {
          label: (
            <div className="menuItemsLabel">
              <span>Reviewed</span>
              {isSelected('Reviewed') && (
                <div className="tabActive">
                  <span className="yellowDot" />
                  <CloseOutlined
                    onClick={() =>
                      dispatch(ImSpecCaseDashboardBetaAction.clearFilters('last_reviewed'))
                    }
                  />
                </div>
              )}
            </div>
          ),
          key: 'Reviewed',
          className: 'lastUpdatePopup'
        },
        {
          label: (
            <div className="menuItemsLabel">
              <span>Commented</span>
              {isSelected('Commented') && (
                <div className="tabActive">
                  <span className="yellowDot" />
                  <CloseOutlined
                    onClick={() =>
                      dispatch(ImSpecCaseDashboardBetaAction.clearFilters('last_commented'))
                    }
                  />
                </div>
              )}
            </div>
          ),
          key: 'Commented',
          className: 'lastUpdatePopup'
        },
        {
          label: (
            <div className="menuItemsLabel">
              <span>Created</span>
              {isSelected('Created') && (
                <div className="tabActive">
                  <span className="yellowDot" />
                  <CloseOutlined
                    onClick={() =>
                      dispatch(ImSpecCaseDashboardBetaAction.clearFilters('last_created'))
                    }
                  />
                </div>
              )}
            </div>
          ),
          key: 'Created',
          className: 'lastUpdatePopup'
        }
      ]
    }
  ];

  const onMenuClick = () => {
    setLastUpdate('');
  };

  const onTestCases = e => {
    dispatch(ImSpecCaseDashboardBetaAction.setIncludeTestCases(e.target.checked));
  };

  const onLastUpdate = e => {
    setLastUpdate(e.key);
    setActiveTab('Last update');
  };

  const updateViewDisabled = useMemo(() => {
    if (savedViewId) {
      const savedView = savedViewsList.find(view => view.id === savedViewId);

      return JSON.stringify(savedView?.filter_params) === JSON.stringify(filterAnswers);
    }
    return false;
  }, [filterAnswers, savedViewId, savedViewsList]);

  const onOpenChange = openKeys => setOpenKeys(openKeys);

  return (
    <>
      <Drawer
        className="filtersDrawer"
        rootClassName="filtersDrawerRoot"
        placement="right"
        width={703}
        closable={false}
        onClose={onDrawerClose}
        open={openDrawer}
        footer={
          activeContentTab === '1' ? (
            <Space>
              {!isFilterAnswersEmpty(filterAnswers) && (
                <Button
                  type="ghost"
                  className="clearAll"
                  onClick={() => dispatch(ImSpecCaseDashboardBetaAction.clearFilters(null, true))}
                >
                  Clear all
                </Button>
              )}

              {savedViewId ? (
                <>
                  <Button onClick={onApplyFilters} type="secondary" className="onlyApply">
                    Apply view
                  </Button>

                  <Dropdown.Button
                    onClick={() =>
                      dispatch(
                        ImSpecCaseDashboardBetaAction.updateSavedFilter(
                          savedViewId,
                          savedViewName,
                          null,
                          null,
                          filterAnswers
                        )
                      )
                    }
                    trigger="click"
                    disabled={updateViewDisabled}
                    type="primary"
                    icon={<DownOutlined />}
                    menu={{ items }}
                    overlayClassName="applyActions"
                    className="updateBtn"
                  >
                    Update view
                  </Dropdown.Button>
                </>
              ) : (
                <Dropdown.Button
                  onClick={onApplyFilters}
                  type="primary"
                  icon={<DownOutlined />}
                  menu={{ items }}
                  trigger="click"
                  className="updateBtn"
                  overlayClassName="applyActions"
                >
                  Apply filters
                </Dropdown.Button>
              )}
            </Space>
          ) : (
            <Space className="savedViewFooter">
              <InfoCircleFilled style={{ fontSize: '18px', color: '#B4B3B3' }} />
              <p>You can save up to 10 views</p>
            </Space>
          )
        }
      >
        <Row className="filtersHeader">
          <Space>
            <Tabs
              defaultActiveKey="1"
              activeKey={activeContentTab}
              onTabClick={key => setActiveContentTab(key)}
              items={[
                {
                  key: '1',
                  label: `${savedViewName ? savedViewName : 'Filters'}`
                },
                {
                  key: '2',
                  label: `Saved views`
                }
              ]}
            />
          </Space>
          <Button type="ghost" icon={<CloseOutlined />} onClick={() => onDrawerClose()} />
        </Row>
        <Col className="filtersBody">
          {activeContentTab === '1' ? (
            <>
              <Row className="addedFilters" />
              <Row>
                <div className={`filters ${activeTab}`}>
                  <Tabs
                    activeKey={activeTab}
                    onTabClick={key => {
                      if (key !== 'Last update') {
                        setActiveTab(key);
                        openKeys.includes('last_update_sub_menu') && setOpenKeys([]);
                      }
                    }}
                    tabPosition="left"
                    tabBarExtraContent={
                      <Checkbox
                        checked={include_test_cases}
                        style={{ marginTop: '10px' }}
                        onChange={onTestCases}
                      >
                        Include test cases
                      </Checkbox>
                    }
                    destroyInactiveTabPane
                    items={filters.map(filter => {
                      return {
                        label: (
                          <>
                            {filter === 'Last update' ? (
                              <Menu
                                mode="vertical"
                                openKeys={openKeys}
                                selectedKeys={[lastUpdate]}
                                items={lastUpdateOptions(filter)}
                                onSelect={onLastUpdate}
                                onOpenChange={onOpenChange}
                                className="lastUpdate"
                                triggerSubMenuAction="click"
                              />
                            ) : (
                              <Button onClick={onMenuClick}>
                                {filter}
                                {isSelected(filter) && (
                                  <div className="tabActive">
                                    <span className="yellowDot"></span>
                                    <CloseOutlined
                                      onClick={() =>
                                        filter === 'IS'
                                          ? dispatch(
                                              ImSpecCaseDashboardBetaAction.clearFilters(
                                                filterNameHelper('im_spec')
                                              )
                                            )
                                          : filter === 'IA'
                                          ? dispatch(
                                              ImSpecCaseDashboardBetaAction.clearFilters(
                                                filterNameHelper('attorney')
                                              )
                                            )
                                          : dispatch(
                                              ImSpecCaseDashboardBetaAction.clearFilters(
                                                filterNameHelper(filter)
                                              )
                                            )
                                      }
                                    />
                                  </div>
                                )}
                              </Button>
                            )}
                          </>
                        ),
                        key: filter,
                        children: (
                          <DrawerContent
                            filter={filter === 'Last update' ? `${lastUpdate} Cases` : filter}
                          />
                        )
                      };
                    })}
                  />
                </div>
              </Row>
            </>
          ) : activeContentTab === '2' ? (
            <CaseDashboardSavedViews
              savedViewsList={savedViewsList}
              onDrawerClose={onDrawerClose}
            />
          ) : null}
        </Col>
      </Drawer>
    </>
  );
};

CaseDashboardDrawer.propTypes = {
  onDrawerCloseMain: PropTypes.func,
  openDrawer: PropTypes.bool,
  showModal: PropTypes.func,
  activeContentTab: PropTypes.string,
  setActiveContentTab: PropTypes.func,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func
};

CaseDashboardDrawer.defaultProps = {
  openDrawer: false,
  activeContentTab: '1',
  activeTab: 'Empty'
};

export default CaseDashboardDrawer;
