import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import moment from 'moment';

import HistoryLogManualContent from 'components/HistoryLogCommentsThread/HistoryLogContent/HistoryLogManualContent';
import HistoryLogSyncContent from 'components/HistoryLogCommentsThread/HistoryLogContent/HistoryLogSyncContent';
import IndividualLog from 'components/HistoryLogCommentsThread/HistoryLogContent/IndividualLog';
import IndividualSyncLog from 'components/HistoryLogCommentsThread/HistoryLogContent/IndividualSyncLog';
import EmptyContent from 'components/HistoryLogCommentsThread/EmptyContent';

import { Col, Divider, Row } from 'antd';

import { ImSpecContentAction } from 'store/Actions';

import './HistoryLogContent.scss';

const HistoryLogContent = ({ logs, logsActiveTab, question }) => {
  const dispatch = useDispatch();
  const { masterIntakeID, userID, petitionerName } = useParams();

  const onPreviewUpload = id => {
    dispatch(ImSpecContentAction.onPreview(id, masterIntakeID));
  };

  if (!logs.length) return <EmptyContent text="There are no history logs here yet." />;

  return (
    <>
      {logs.map(log => {
        let url;

        if (masterIntakeID) {
          if (log.source_master_intake) {
            url = `/petitioner-dashboard/master-intake/${
              log.source_master_intake.id
            }/${log.source_master_intake.petitioner?.replaceAll(' ', '-')}`;
          } else {
            url = `/petitioner-dashboard/master-intake/${masterIntakeID}/${petitionerName?.replaceAll(
              ' ',
              '-'
            )}`;
          }
        }

        if (!masterIntakeID) {
          if (log.source_case) {
            url = `/client-management/userID=${userID}/${
              log.source_case.case_id
            }/${log.source_case.source_section?.replaceAll(' ', '-')}`;
          } else if (log.source_master_intake) {
            url = `/petitioner-dashboard/master-intake/${
              log.source_master_intake.id
            }/${log.source_master_intake.petitioner?.replaceAll(' ', '-')}`;
          } else if (log.target_case) {
            url = `/client-management/userID=${userID}/${
              log.target_case.case_id
            }/${log.target_case.section_name.replaceAll(' ', '-')}`;
          }
        }

        if (logsActiveTab === 'sync_updates') {
          return (
            <div
              key={log.log_uuid ?? log.answer.id}
              className={`history-log-container${log.log_uuid ? '-sync' : ''}`}
            >
              {!log.log_uuid ? (
                <HistoryLogManualContent log={log} url={url} question={question} />
              ) : (
                <HistoryLogSyncContent log={log} url={url} />
              )}
              <div className="history-log-content">
                {!log.log_uuid ? (
                  <IndividualLog
                    answer_id={log.answer.id}
                    logs={log.logs}
                    onPreviewUpload={onPreviewUpload}
                    count={log.logs_count}
                    logsActiveTab={logsActiveTab}
                  />
                ) : (
                  <IndividualSyncLog
                    logs={log.logs}
                    count={log.logs_count}
                    log_uuid={log.log_uuid}
                    onPreviewUpload={onPreviewUpload}
                  />
                )}
              </div>
            </div>
          );
        }

        if (!log.answer)
          return (
            <div key={log.created_at} className="history-log-container-submitted">
              <div>
                <Divider>
                  <Col>
                    <Row className="history-log-container-submitted-content" justify="center">
                      {log.target_case ? log.target_case.section_name : 'Master Intake'}
                    </Row>
                    <Row className="history-log-container-submitted-content" justify="center">
                      {log.actual_value} by {log.user.first_name} {log.user.last_name}
                    </Row>
                    <Row className="history-log-container-submitted-content" justify="center">
                      {moment(log.created_at).format('hh:mm A, MMMM DD, YYYY')}
                    </Row>
                  </Col>
                </Divider>
              </div>
            </div>
          );

        return (
          <div key={log.answer.id} className="history-log-container">
            <HistoryLogManualContent log={log} url={url} question={question} />
            <div className="history-log-content">
              <IndividualLog
                answer_id={log.answer.id}
                logs={log.logs}
                count={log.logs_count}
                onPreviewUpload={onPreviewUpload}
                logsActiveTab={logsActiveTab}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

HistoryLogContent.propTypes = {
  logs: PropTypes.array,
  logsActiveTab: PropTypes.string,
  question: PropTypes.object
};

HistoryLogContent.defaultProps = {
  logs: [],
  count: 0,
  logsActiveTab: ''
};

export default HistoryLogContent;
