import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { SERVER } from 'utils/constants';
import history from 'utils/history';

// redux
import store from 'store';
import { AuthActions } from 'store/Actions';

import { Modal } from 'antd';

const modalProps = {
  centered: true,
  width: 322,
  icon: null,
  className: 'submit-section-confirm-modal sign-in',
  okButtonProps: { className: 'submit-section-confirm-modal-submit_btn' },
  okText: 'Sign in',
  content: (
    <>
      <span className="submit-section-confirm-modal-text">Your session has expired.</span>
      <span className="submit-section-confirm-modal-text">Please sign in again.</span>
    </>
  ),
  onOk: () => {
    controller = newController();
    localStorage.removeItem('user');
    history.push('/sign-in', { from: history.location });
    store.dispatch(AuthActions.userSignOut());
  }
};

const newController = () => new AbortController();

let controller = newController();

const axiosInstance = axios.create({
  baseURL: SERVER
});

const refreshAuthLogic = async failedRequest => {
  const user = JSON.parse(localStorage.getItem('user'));
  try {
    const tokenRefreshResponse = await axiosInstance.post('user/token/refresh/', {
      refresh: user?.refresh
    });
    const userData = { ...user, access: tokenRefreshResponse.data.access };
    store.dispatch(AuthActions.loginAccess(userData));
    localStorage.setItem('user', JSON.stringify(userData));

    failedRequest.response.config.headers[
      'Authorization'
    ] = `Bearer ${tokenRefreshResponse.data.access}`;

    return Promise.resolve();
  } catch (_error) {
    return Promise.reject(_error);
  }
};

axiosInstance.interceptors.request.use(
  async config => {
    const user = JSON.parse(localStorage.getItem('user'));
    config.signal = controller.signal;
    config.headers =
      config.url !== 'user/token/refresh/' && user
        ? { ...config.headers, Authorization: `Bearer ${user?.access}` }
        : { ...config.headers };

    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error.response) {
      const { status, data, config } = error.response || {};

      if (
        (config.url === 'user/token/refresh/' && status === 401) ||
        (status === 400 && data[0] === 'Token is invalid or expired')
      ) {
        controller.abort();
        Modal.info({ ...modalProps });
      }

      if (status === 404 && config.method === 'get') {
        history.replace(history.location, { type: 'page-not-found' });
      }

      if (status === 403 && data.detail === 'You have not access to this case.') {
        history.replace(history.location, { type: 'case-invitation-error' });
      }

      if (status === 403 && config.method === 'get') {
        history.replace(history.location, { type: 'access-denied' });
      }
    }
    return Promise.reject(error);
  }
);

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
  interceptNetworkError: true,
  retryInstance: axiosInstance,
  shouldRefresh: error =>
    error.response.status === 401 &&
    error.response.config.url !== 'user/token/refresh/' &&
    Object.prototype.hasOwnProperty.call(error.response.config.headers, 'Authorization')
});

export default axiosInstance;
