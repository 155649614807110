import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { HistoryLogCommentsThreadAction, ImSpecContentAction } from 'store/Actions';

import Subsection from './Subsection';
import Comments from './Subsection/Grouped/Questions/elements/Comments';
import DependentFormModal from 'components/shared/DependentFormModal';

import { sectionAndSubsectionNames } from 'utils/helper';
import { alreadySynced, caseInfoIcon, syncIcon } from 'components/Icons/Icons';
import { FieldTimeOutlined, CommentOutlined, LoadingOutlined } from '@ant-design/icons';

import {
  Row,
  Button,
  Modal,
  Checkbox,
  Spin,
  Breadcrumb,
  Col,
  Dropdown,
  Skeleton,
  Tooltip,
  Badge
} from 'antd';

import 'react-datepicker/dist/react-datepicker.css';
import '../index.scss';

const Section = () => {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { masterIntakeID, petitionerName, sectionTitle, caseID, userID } = useParams();

  const [syncDisable, setSyncDisable] = useState(false);

  const hideNextAndPreviousButtons = useFlag('hide-next-and-previous-buttons');
  const hideHistoryLogCommentsThread = useFlag('hide-history-log-comments-thread');
  const hideCommentsOverview = useFlag('hide-comments-overview');
  const { flagsReady } = useFlagsStatus();

  const {
    ImSpecContent: {
      section,
      loading,
      answers,
      answersLoading,
      sendAnswersLoading,
      form_questionnaire,
      field_name,
      iterationName,
      questionSet,
      childCaseInfo,
      createDependentLoading,
      sync_info
    },
    ImSpecSideBar: {
      sidebarInfo: {
        selected_sidebar,
        sideBarData: { visaType }
      }
    },
    HistoryLogCommentsThread: {
      comments: { tabs: commentViewTabs }
    }
  } = useSelector(state => ({
    ImSpecContent: state.ImSpecContent,
    ImSpecSideBar: state.ImSpecSideBar,
    HistoryLogCommentsThread: state.HistoryLogCommentsThread
  }));

  const [approve, setApprove] = useState(0);
  const [visible, setVisible] = useState(false);
  const [complete, setComplete] = useState(false);
  const [nextPreviousLinks, setNextPreviousLinks] = useState({
    next: null,
    previous: null
  });

  useEffect(() => {
    !!scrollRef.current && (scrollRef.current.scrollTop = 0);
  }, [section]);

  useEffect(() => {
    if (flagsReady && !hideCommentsOverview) {
      dispatch(
        HistoryLogCommentsThreadAction.getCommentsTabs(
          caseID,
          masterIntakeID,
          sectionTitle ? sectionAndSubsectionNames(sectionTitle) : sectionTitle
        )
      );
    }

    return () => {
      dispatch(HistoryLogCommentsThreadAction.setCommentsTab([]));
    };
  }, [caseID, dispatch, flagsReady, hideCommentsOverview, masterIntakeID, sectionTitle]);

  useEffect(() => {
    const getCustomerInitialData = async () => {
      await dispatch(
        ImSpecContentAction.getSectionContent(masterIntakeID, petitionerName, sectionTitle, caseID)
      );
      await dispatch(
        ImSpecContentAction.getSectionAnswers(masterIntakeID, petitionerName, sectionTitle, caseID)
      );
      dispatch(
        HistoryLogCommentsThreadAction.setSectionsFilterKey(sectionAndSubsectionNames(sectionTitle))
      );
    };
    sectionTitle && caseID && userID && getCustomerInitialData();

    return () => {
      dispatch(ImSpecContentAction.setSectionContent({}));
      dispatch(ImSpecContentAction.answersContent({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sectionTitle, caseID, userID]);

  useEffect(() => {
    const getMasterIntakeData = async () => {
      await dispatch(
        ImSpecContentAction.getSectionContent(masterIntakeID, petitionerName, sectionTitle, caseID)
      );
      await dispatch(
        ImSpecContentAction.getSectionAnswers(masterIntakeID, petitionerName, sectionTitle, caseID)
      );
    };
    masterIntakeID && petitionerName && getMasterIntakeData();

    return () => {
      dispatch(ImSpecContentAction.setSectionContent({}));
      dispatch(ImSpecContentAction.answersContent({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sectionTitle, masterIntakeID, petitionerName]);

  useEffect(() => {
    if (sectionTitle && !!selected_sidebar.length && userID) {
      const currentSectionIndex = selected_sidebar.findIndex(
        section => section.title === sectionAndSubsectionNames(sectionTitle)
      );

      const pathname = location.pathname.split('/').slice(0, 4).join('/');

      const previous =
        currentSectionIndex === -1 || currentSectionIndex === 0
          ? null
          : `${pathname}/${selected_sidebar[currentSectionIndex - 1].title.replaceAll(' ', '-')}`;
      const next =
        currentSectionIndex === -1 || currentSectionIndex === selected_sidebar.length - 1
          ? null
          : `${pathname}/${selected_sidebar[currentSectionIndex + 1].title.replaceAll(' ', '-')}`;

      setNextPreviousLinks(prev => ({ ...prev, next, previous }));
    }
  }, [location.pathname, sectionTitle, selected_sidebar, userID]);

  const onChangeApproveSection = ({ target: { checked } }) => {
    setApprove(checked ? 1 : 0);
  };

  const onCompleteReviewMasterIntake = () => {
    dispatch(ImSpecContentAction.syncAndSubmit(masterIntakeID, approve, setComplete));
  };

  const CompleteReviewContentForSubmit = () => (
    <>
      <hr />
      {masterIntakeID && (
        <span className="master-complete-content">
          Completing Review will show all the comments to the client.
        </span>
      )}
      {!masterIntakeID && (
        <h4>
          Are you sure you want to complete the Review of{' '}
          <b>{sectionAndSubsectionNames(sectionTitle)}</b> intake?
        </h4>
      )}
      <Checkbox className="approve-section" onChange={onChangeApproveSection} checked={!!approve}>
        <span className="checkbox-title">Approve this intake section</span>
      </Checkbox>
    </>
  );

  const CompleteReviewContentClose = () => (
    <>
      <span>You have successfully completed the review of</span>
      <span>{section?.title}</span>
    </>
  );

  const CompleteReviewContentCloseMasterIntake = () => (
    <span className="master-content">
      The information is now being synced to company employee cases.
    </span>
  );

  const ModalFooterComplete = () => (
    <div className="modal-footer-complete">
      <Button className="cancel-review" onClick={closeModal}>
        Cancel
      </Button>
      <Button
        type="primary"
        className="complete-review"
        onClick={masterIntakeID ? onCompleteReviewMasterIntake : onComplete}
      >
        Complete
      </Button>
    </div>
  );

  const closeModal = () => {
    setComplete(false);
    setVisible(false);
  };

  const closeModalAfterComplete = () => {
    closeModal();
    !masterIntakeID && navigate(`/client-management/userID=${userID}/${visaType.case_id}`);
  };

  const ModalFooterClose = () => (
    <div className="modal-footer-close">
      <Button type="primary" className="close-review" onClick={closeModalAfterComplete}>
        Close
      </Button>
    </div>
  );

  const onComplete = () => {
    dispatch(ImSpecContentAction.completeSection(sectionTitle, caseID, approve, setComplete));
  };

  const onClickCompleteReview = () => {
    setApprove(0);
    setVisible(true);
  };

  const onOpenHistoryLogCommentsThread = useCallback(
    tab => () => {
      dispatch(HistoryLogCommentsThreadAction.onOpenChange(tab));
    },
    [dispatch]
  );

  const Links = useMemo(() => {
    return flagsReady && !hideNextAndPreviousButtons && !masterIntakeID ? (
      <Row className="navigation-btns im_spec">
        {nextPreviousLinks.previous && (
          <span
            tabIndex="-1"
            className={`navigation-btn previous ${sendAnswersLoading ? 'disabled' : ''}`}
          >
            <Link to={nextPreviousLinks.previous || '/'}>Previous</Link>
          </span>
        )}
        {nextPreviousLinks.next && (
          <span tabIndex="-1" className={`navigation-btn ${sendAnswersLoading ? 'disabled' : ''}`}>
            <Link to={nextPreviousLinks.next || '/'}>Next</Link>
          </span>
        )}
      </Row>
    ) : null;
  }, [
    flagsReady,
    hideNextAndPreviousButtons,
    masterIntakeID,
    nextPreviousLinks.next,
    nextPreviousLinks.previous,
    sendAnswersLoading
  ]);

  const historyLogCommentsThread = useMemo(() => {
    return flagsReady && !hideHistoryLogCommentsThread ? (
      <Row gutter={12}>
        <Col>
          <Button
            onClick={onOpenHistoryLogCommentsThread('history_log')}
            type="ghost"
            className="google-drive"
            icon={<FieldTimeOutlined style={{ fontSize: 24, color: '#63A0FF' }} />}
          />
        </Col>
        {!hideCommentsOverview ? (
          <Col>
            <Button
              onClick={onOpenHistoryLogCommentsThread('comments')}
              type="ghost"
              className="google-drive"
              icon={
                <Badge
                  rootClassName="unresolved-badge"
                  dot={!!commentViewTabs.find(tab => tab.status === 'Unresolved')?.value}
                >
                  <CommentOutlined style={{ fontSize: 24, color: '#63A0FF' }} />
                </Badge>
              }
            />
          </Col>
        ) : null}
      </Row>
    ) : null;
  }, [
    commentViewTabs,
    flagsReady,
    hideCommentsOverview,
    hideHistoryLogCommentsThread,
    onOpenHistoryLogCommentsThread
  ]);

  const breadCrumbsOrHeader = useMemo(() => {
    const menu = section
      ? {
          className: 'associated_accounts-menu breadcrumb',
          selectable: false,
          items: [
            {
              label: (
                <Link to={`/client-management/userID=${section.beneficiary?.id}`}>
                  {section.beneficiary?.first_name} {section.beneficiary?.last_name}
                </Link>
              ),
              key: 'Client page'
            },
            {
              label: (
                <Link
                  to={`/client-management/userID=${section.beneficiary?.id}/${visaType.case_id}`}
                >
                  <span className="case-details">
                    {visaType.case_id}
                    <div className="circle" />
                    <div className={`visa_name v${visaType?.visa_name?.replaceAll(' ', '-')}`}>
                      {visaType.visa_name}{' '}
                      {visaType.visa_name === 'I-539' && visaType.is_dependent
                        ? 'DEP'
                        : visaType.visa_name === 'I-539' && !visaType.is_dependent
                        ? 'BEN'
                        : null}
                    </div>
                    {visaType.dependent && (
                      <>
                        <div className="circle" />
                        {visaType.dependent}
                      </>
                    )}
                  </span>
                </Link>
              ),
              key: 'Section dashboard'
            }
          ]
        }
      : {};

    return (
      section && (
        <div className="questionnaire_breadcrumb">
          <Row justify="space-between" align="middle" style={{ width: '100%' }}>
            {masterIntakeID ? (
              <>
                <Breadcrumb
                  className="breadcrumb"
                  separator=">"
                  items={[
                    {
                      title: (
                        <Link to={`/petitioner-dashboard/${section?.petitioner_id}`}>
                          {sectionAndSubsectionNames(petitionerName)}
                        </Link>
                      )
                    },
                    {
                      title: (
                        <span className="case-details">
                          <div
                            className={`visa_name v${section.case_type?.replaceAll(' ', '-')}`}
                            style={{ margin: 0 }}
                          >
                            {section.case_type}
                          </div>
                          <div className="circle" />
                          Master Intake
                        </span>
                      )
                    }
                  ]}
                />
              </>
            ) : (
              <Breadcrumb
                className="breadcrumb"
                separator=">"
                items={[
                  {
                    title:
                      typeof section.parent_breadcrumbs === 'string' ? (
                        <span className="petitioner">{section.parent_breadcrumbs}</span>
                      ) : (
                        <Link to={`/petitioner-dashboard/${section.parent_breadcrumbs?.id}`}>
                          {section.parent_breadcrumbs?.name}
                        </Link>
                      )
                  },
                  ...(Object.keys(childCaseInfo).length
                    ? [
                        {
                          title: (
                            <Dropdown
                              overlayClassName="associated_accounts-dropdown breadcrumb"
                              className="associated_accounts-dropdown"
                              placement="top"
                              getPopupContainer={triggerNode => triggerNode.parentNode}
                              menu={menu}
                              trigger="click"
                              arrow={false}
                            >
                              <span className="dots">...</span>
                            </Dropdown>
                          )
                        },
                        {
                          title: (
                            <Link
                              to={`/client-management/userID=${section.beneficiary?.id}/${visaType?.case_id}`}
                            >
                              <span className="case-details">
                                {caseID}
                                <div className="circle" />
                                <div
                                  className={`visa_name v${childCaseInfo.visa_name?.replaceAll(
                                    ' ',
                                    '-'
                                  )}`}
                                >
                                  {childCaseInfo.visa_name}
                                </div>
                                {childCaseInfo.dependent && (
                                  <>
                                    <div className="circle" />
                                    {childCaseInfo.dependent}
                                  </>
                                )}
                              </span>
                            </Link>
                          )
                        }
                      ]
                    : [
                        {
                          title: (
                            <Link to={`/client-management/userID=${section.beneficiary?.id}`}>
                              {section.beneficiary?.first_name} {section.beneficiary?.last_name}
                            </Link>
                          )
                        },
                        {
                          title: (
                            <Link
                              to={`/client-management/userID=${section.beneficiary?.id}/${visaType?.case_id}`}
                            >
                              <span className="case-details">
                                {caseID}
                                <div className="circle" />
                                <div
                                  className={`visa_name v${visaType?.visa_name?.replaceAll(
                                    ' ',
                                    '-'
                                  )}`}
                                >
                                  {visaType.visa_name}{' '}
                                  {visaType.visa_name === 'I-539' && visaType.is_dependent
                                    ? 'DEP'
                                    : visaType.visa_name === 'I-539' && !visaType.is_dependent
                                    ? 'BEN'
                                    : null}
                                </div>
                                {visaType.dependent && (
                                  <>
                                    <div className="circle" />
                                    {visaType.dependent}
                                  </>
                                )}
                              </span>
                            </Link>
                          )
                        }
                      ]),
                  {
                    title: (
                      <div className="questionnaire_info">
                        <span>Questionnaire</span>
                        <Tooltip
                          placement="topLeft"
                          overlayInnerStyle={{ padding: '5px 15px' }}
                          title={
                            <p>
                              Case State: <b>{section.case_state}</b> <br /> Section Status:{' '}
                              <b>{section.section_status}</b>
                            </p>
                          }
                        >
                          <span className="questionnaire_info-icon">{caseInfoIcon}</span>
                        </Tooltip>
                      </div>
                    )
                  }
                ]}
              />
            )}
            {historyLogCommentsThread}
          </Row>
          <Row className="questionnaire_breadcrumb_owner-rep" align="middle">
            {section.owner?.id !== section.beneficiary && (
              <Row align="middle" className="owner">
                <Link to={`/client-management/userID=${section.owner?.id}`}>
                  {section.owner?.first_name} {section.owner?.last_name}
                </Link>
                <Row justify="center" align="middle" className="owner_block">
                  Owner
                </Row>
              </Row>
            )}
            {section.representative && (
              <Row align="middle" className="owner">
                <Link to={`/client-management/userID=${section.representative?.id}`}>
                  {section.representative?.first_name} {section.representative?.last_name}
                </Link>
                <Row justify="center" align="middle" className="owner_block">
                  Rep
                </Row>
              </Row>
            )}
          </Row>
        </div>
      )
    );
  }, [
    caseID,
    section,
    visaType,
    childCaseInfo,
    masterIntakeID,
    petitionerName,
    historyLogCommentsThread
  ]);

  const onCancel = () => {
    navigate(location.pathname);
    dispatch(ImSpecContentAction.setDependentForm(null, null, null, null, navigate, location));
  };

  const onCreateDep = (values, form) => {
    dispatch(
      ImSpecContentAction.createDependent(
        values,
        caseID,
        field_name,
        { navigate, location },
        sectionTitle,
        form,
        masterIntakeID,
        petitionerName
      )
    );
  };

  const onSync = () => {
    dispatch(ImSpecContentAction.onSync(masterIntakeID, petitionerName, setSyncDisable));
  };

  return (
    <div className={`imSpec-container ${loading ? 'imSpec-container-loading' : ''}`}>
      {loading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 80, color: '#FBD963' }} />} />
      ) : (
        <Spin
          tip="Loading..."
          spinning={answersLoading}
          indicator={<LoadingOutlined style={{ fontSize: 50, color: '#FBD963' }} />}
        >
          <Col className="mainSubsectionContent">
            {breadCrumbsOrHeader}
            <div className="pageTitle">
              <div className="pageTitle_content">
                {masterIntakeID ? (
                  <h1 className="sectionName">
                    {sectionAndSubsectionNames(petitionerName)} Master Intake
                  </h1>
                ) : (
                  <h1 className="sectionName">{section?.title}</h1>
                )}
                <Row align="middle">
                  {masterIntakeID && section?.status !== 'Not Attempted' && (
                    <Tooltip
                      title={
                        sync_info?.sync_available && !sync_info?.sync_in_progress
                          ? 'Sync details to employee cases'
                          : !sync_info?.sync_available && !sync_info?.sync_in_progress
                          ? 'Master intake already synced!'
                          : null
                      }
                      placement="top"
                      overlayClassName="sync-tooltip"
                    >
                      <Button
                        type="ghost"
                        className="sync_icon"
                        style={{
                          cursor:
                            sync_info?.sync_available && !sync_info?.sync_in_progress
                              ? 'pointer'
                              : 'unset'
                        }}
                        onClick={() =>
                          sync_info?.sync_available && !sync_info?.sync_in_progress && onSync()
                        }
                        disabled={syncDisable}
                        icon={
                          sync_info?.sync_available && !sync_info?.sync_in_progress
                            ? syncIcon
                            : !sync_info?.sync_available && !sync_info?.sync_in_progress
                            ? alreadySynced
                            : null
                        }
                      >
                        {sync_info?.sync_in_progress ? (
                          <div className="in-progress">
                            {syncIcon}
                            <Skeleton.Input className="skeleton-loading" active />
                          </div>
                        ) : null}
                      </Button>
                    </Tooltip>
                  )}
                  {!loading && section?.status !== 'Not Attempted' && (
                    <Button
                      disabled={sync_info?.sync_in_progress}
                      onClick={onClickCompleteReview}
                      className="primary-btn"
                    >
                      Complete review
                    </Button>
                  )}
                </Row>
              </div>
            </div>
            <div ref={scrollRef} className="scrollableBlock">
              {section?.subsection_set?.map(subsection => (
                <Subsection curSubsection={subsection} key={subsection.id} />
              ))}
              {Links}
            </div>
          </Col>
        </Spin>
      )}
      <Modal
        className={`complete-section ${
          masterIntakeID && complete
            ? 'review-close-modal master-intake'
            : !masterIntakeID && complete && 'review-close-modal'
        }`}
        title={
          masterIntakeID && !complete
            ? 'Are you sure you want to complete the Review?'
            : !masterIntakeID && !complete
            ? 'Complete Review'
            : masterIntakeID && complete
            ? `You have successfully completed the review of ${sectionAndSubsectionNames(
                petitionerName
              )} Master Intake.`
            : null
        }
        centered
        width={438}
        closable={false}
        footer={complete ? <ModalFooterClose /> : <ModalFooterComplete />}
        open={visible}
      >
        {complete && masterIntakeID ? (
          <CompleteReviewContentCloseMasterIntake />
        ) : complete && !masterIntakeID ? (
          <CompleteReviewContentClose />
        ) : (
          <CompleteReviewContentForSubmit />
        )}
      </Modal>
      <Comments />
      <DependentFormModal
        form_questionnaire={form_questionnaire}
        field_name={field_name}
        iterationName={iterationName}
        questionSet={questionSet}
        answers={answers}
        onCancelDep={onCancel}
        onCreateDep={onCreateDep}
        loading={createDependentLoading}
      />
    </div>
  );
};

export default Section;
