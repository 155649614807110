import { useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

// public
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import PassSuccess from './pages/ResetPassword/PassSuccess';
import PassError from './pages/ResetPassword/PassError';

import {
  Auth,
  Unauthorized,
  EmailSuccess,
  EmailError,
  AccountHasCreated,
  LinkIsNotAvailable,
  SignUpThroughInvitation,
  VerifyUpdatedEmail,
  OldEmailError,
  PageNotFound,
  InvitationCanceledError,
  VerifyAccount,
  VerifyMigratedAccount
} from './pages/PublicPages';

// private
import LayoutAuth from 'LayoutAuth';

// actions
import { AuthActions } from 'store/Actions';

// auth routes
import ProtectedRoutes from 'ProtectedRoutes';
import PublicRoutes from 'PublicRoutes';

import './global.scss';

export const App = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const location = useLocation();
  const navigate = useNavigate();

  const onlyProdGrowsurf = useFlag('only-prod-growsurf');
  const { flagsReady } = useFlagsStatus();

  const getGrowsurfFlag = useCallback(() => {
    if (flagsReady) return onlyProdGrowsurf;
  }, [flagsReady, onlyProdGrowsurf]);

  ReactGA.initialize('G-95Y22C29LM', {
    trackingId: 'G-95Y22C29LM',
    gtagOptions: {
      user_id: user?.id
    }
  });

  useEffect(() => {
    AuthActions.logOutListener();
  }, []);

  useEffect(() => {
    const flag = getGrowsurfFlag();
    if (!window.growsurf && flag === false) {
      const script = document.createElement('script');
      script.src = 'https://growsurf.com/growsurf.js?v=2.0.0';
      script.setAttribute('grsf-campaign', '3ekc8b');
      script.async = true;
      document.head.appendChild(script);
    }
  }, [getGrowsurfFlag]);

  useEffect(() => {
    if (location.pathname.includes('data-intake/')) {
      navigate(`/your-questionnaires/${location.pathname.split('/data-intake/')[1]}`, {
        replace: true
      });
    }
    if (
      !location.pathname.endsWith('case-dashboard/') &&
      location.pathname.includes('/case-dashboard/')
    ) {
      navigate(`/client-management/${location.pathname.split('/case-dashboard/')[1]}`, {
        replace: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        {/* public routes */}
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<Navigate replace to="sign-in" />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="email_confirmation/:id" element={<Auth />} />
          <Route path="email-success" element={<EmailSuccess />} />
          <Route path="email-error" element={<EmailError />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="change-password/:id" element={<ResetPassword />} />
          <Route path="password-changed" element={<PassSuccess />} />
          <Route path="password-change-error" element={<PassError />} />
          <Route path="already-verified" element={<AccountHasCreated />} />
          <Route path="invitation-error" element={<LinkIsNotAvailable />} />
          <Route path="sign-up-error" element={<SignUpThroughInvitation />} />
          <Route path="verify-updated-email-error" element={<VerifyUpdatedEmail />} />
          <Route path="old-email-error" element={<OldEmailError />} />
          <Route path="invitation-canceled" element={<InvitationCanceledError />} />
          <Route path="verify-account" element={<VerifyAccount />} />
          <Route path="verify-migrated-account" element={<VerifyMigratedAccount />} />
        </Route>

        {/* private routes */}
        <Route path="/" element={<ProtectedRoutes />}>
          {/* overall */}
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="/" element={<Navigate replace to="dashboard" />} />
          <Route
            path="dashboard"
            element={<LayoutAuth allowedRoles={['customer', 'IS', 'attorney', 'CS']} />}
          />
          <Route
            path="account-profile"
            element={<LayoutAuth allowedRoles={['customer', 'IS', 'attorney', 'CS']} />}
          />

          {/* IS */}
          <Route
            path="client-management"
            element={<LayoutAuth allowedRoles={['IS', 'attorney', 'CS']} />}
          >
            <Route
              path="userID=:userID"
              element={<LayoutAuth allowedRoles={['IS', 'attorney', 'CS']} />}
            >
              <Route
                path=":caseID"
                element={<LayoutAuth allowedRoles={['IS', 'attorney', 'CS']} />}
              >
                <Route
                  path=":sectionTitle"
                  element={<LayoutAuth allowedRoles={['IS', 'attorney', 'CS']} />}
                />
              </Route>
            </Route>
          </Route>
          <Route
            path="petitioner-dashboard"
            element={<LayoutAuth allowedRoles={['IS', 'attorney', 'CS']} />}
          >
            <Route
              path=":petitionerID"
              element={<LayoutAuth allowedRoles={['IS', 'attorney', 'CS']} />}
            />
            <Route
              path="master-intake/:masterIntakeID/:petitionerName"
              element={<LayoutAuth allowedRoles={['IS', 'attorney', 'CS']} />}
            />
          </Route>
          <Route
            path="case-dashboard"
            element={<LayoutAuth allowedRoles={['IS', 'attorney', 'CS']} />}
          />
          <Route
            path="case-dashboard-beta"
            element={<LayoutAuth allowedRoles={['IS', 'attorney', 'CS']} />}
          />

          {/* customer */}
          <Route path="your-questionnaires" element={<LayoutAuth allowedRoles={['customer']} />}>
            <Route path=":caseID" element={<LayoutAuth allowedRoles={['customer']} />}>
              <Route
                path=":sectionTitle/:subSectionTitle"
                element={<LayoutAuth allowedRoles={['customer']} />}
              />
              <Route
                path=":sectionTitle/review"
                element={<LayoutAuth allowedRoles={['customer']} />}
              />
            </Route>
          </Route>

          {/* master intake */}
          <Route
            path="master-intake/:masterIntakeID/:petitionerName/:subSectionTitle"
            element={<LayoutAuth allowedRoles={['customer']} />}
          />
          <Route
            path="master-intake/:masterIntakeID/:petitionerName/review"
            element={<LayoutAuth allowedRoles={['customer']} />}
          />

          <Route path="perks" element={<LayoutAuth allowedRoles={['customer']} />} />
          <Route path="refer-a-friend" element={<LayoutAuth allowedRoles={['customer']} />} />
        </Route>

        {/* catch all */}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};
