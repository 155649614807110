import PropTypes from 'prop-types';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { ImSpecContentAction } from 'store/Actions';

import useQuery from 'hooks/useQuery';


import {
  OpenEnded,
  SingleSelectSimple,
  SingleSelectNested,
  MultiSelect,
  Iterative,
  FormQuestionType
} from './Options';
import HistoryLog from './elements/HistoryLog';

import { Button, Badge } from 'antd';
import { CheckCircleFilled, FieldTimeOutlined, CommentOutlined } from '@ant-design/icons';

import 'react-datepicker/dist/react-datepicker.css';

const Questions = ({
  curSubSection,
  questionSet,
  iterationName,
  curQuestionContent,
  curGroup,
  setIsTyping
}) => {
  const dispatch = useDispatch();
  const { answers: answersData, answersLoading: answersDataLoading } = useSelector(
    state => state.ImSpecContent
  );

  const navigate = useNavigate();
  const location = useLocation();
  const fieldRef = useRef();
  const seeMoreRef = useRef(null);
  const seeCommentsRef = useRef(null);
  const { masterIntakeID } = useParams();
  const { comment } = useQuery();
  const { state } = location;

  const [answersDataState, setAnswersDataState] = useState({});
  const [logModalVisible, setLogModalVisible] = useState(false);
  const [uploadError, setUploadError] = useState([]);

  useLayoutEffect(() => {
    if (
      fieldRef &&
      fieldRef.current &&
      answersDataState &&
      Object.keys(answersDataState).length &&
      (state?.answer_id || comment)
    ) {
      if (!answersDataLoading && (+fieldRef.current.id === state?.answer_id || +fieldRef.current.id === +comment)) {
        fieldRef.current.scrollIntoView({ behavior: 'smooth' });

        const curAnswer = Object.values(answersDataState).find(
          answer => answer.id === state?.answer_id || answer.id === +comment
        );

        if (state?.action === 'history_log') {
          if (seeMoreRef && seeMoreRef.current) {
            setTimeout(() => {
              curAnswer && seeMoreRef.current.click();
            }, 1000);
          }
        }

        if (state?.action === 'comment' || comment) {
          if (seeCommentsRef && seeCommentsRef.current) {
            setTimeout(() => {
              curAnswer && seeCommentsRef.current.click();
            }, 1000);
          }
        }
      }
    }
  }, [answersDataLoading, answersDataState, state?.answer_id, state?.action, seeMoreRef, comment]);

  useEffect(() => {
    !!iterationName && !answersDataLoading
      ? !questionSet.startsWith('uId') &&
        setAnswersDataState(answersData[iterationName]?.field_value[questionSet])
      : setAnswersDataState(answersData);
  }, [questionSet, answersData, iterationName, answersDataLoading]);

  const seeMore = (elemAnswer, elem) => {
    dispatch(ImSpecContentAction.seeMoreLogs(elemAnswer, elem, setLogModalVisible, masterIntakeID));
  };

  const openCommentsModal = useCallback(
    curAnswer => {
      location.state?.answer_id && navigate(location.pathname, { replace: true });
      navigate(`${location.pathname}?comment=${curAnswer.id}`)
    },
    [location.pathname, location.state?.answer_id, navigate]
  );

  const commentsFlag = useCallback(
    ({ name, type }, seeCommentsRef) => {
      return (
        answersDataState &&
        answersDataState[name] && (
          <Button
            ref={seeCommentsRef}
            type="ghost"
            className={`commentIconBlock ${type.type !== 'Radio Button' ? 'upload' : ''}`}
            onClick={() => openCommentsModal(answersDataState[name])}
            icon={
              <div className="commentBlock">
                <Badge
                  size="small"
                  color="#E54F4F"
                  count={
                    answersDataState[name]?.comments_count &&
                    answersDataState[name]?.unresolved_comments_count === 0 ? (
                      <CheckCircleFilled style={{ color: '#33B18A' }} />
                    ) : (
                      answersDataState[name]?.unresolved_comments_count
                    )
                  }
                >
                  <CommentOutlined style={{ fontSize: '16px', color: '#63A0FF' }} />
                </Badge>
              </div>
            }
          />
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [answersDataState, openCommentsModal]
  );

  return (
    <div
      className={
        !curQuestionContent?.parent_question_key ? 'groupContentBlock' : 'nestedContentBlock'
      }
    >
      <div className={!curQuestionContent?.parent_question_key ? 'groupContent' : 'questionsBlock'}>
        <div className="questions">
          {curQuestionContent?.type.type !== 'Iterative' && (
            <h4
              className="questionName"
              style={
                !curQuestionContent?.parent_question_key
                  ? { marginTop: '0.5rem' }
                  : { marginTop: '1rem' }
              }
            >
              {curQuestionContent.title}
            </h4>
          )}
          {curQuestionContent?.field_set.length &&
            curQuestionContent?.field_set.map((elem, index) => {
              if (curQuestionContent?.type.type === 'Open Ended' && elem.type.type !== 'Secret')
                return (
                  <div key={elem.id}>
                    <OpenEnded
                      seeMoreRef={seeMoreRef}
                      curGroup={curGroup}
                      curField={elem}
                      seeMore={seeMore}
                      commentsFlag={commentsFlag}
                      questionSet={questionSet}
                      iterationName={iterationName}
                      curQuestionContent={curQuestionContent}
                      uploadError={uploadError}
                      setUploadError={setUploadError}
                      realSubsection={curSubSection}
                      setIsTyping={setIsTyping}
                    />
                  </div>
                );
              if (curQuestionContent?.type.type === 'Single Select')
                return curQuestionContent?.has_children ? (
                  <div key={elem.id}>
                    <SingleSelectNested
                      seeMoreRef={seeMoreRef}
                      curGroup={curGroup}
                      curField={elem}
                      nestedQuestion={curQuestionContent?.children}
                      seeMore={seeMore}
                      commentsFlag={commentsFlag}
                      curSubSection={curSubSection}
                    />
                  </div>
                ) : (
                  <div key={index} className="curField">
                    {commentsFlag(elem, seeCommentsRef)}
                    <div key={elem.id} ref={fieldRef} id={answersDataState?.[elem.name]?.id}>
                      <SingleSelectSimple
                        curField={elem}
                        questionSet={questionSet}
                        iterationName={iterationName}
                        curSubSection={curSubSection}
                      />
                    </div>
                    {answersDataState &&
                      answersDataState[elem.name] &&
                      answersDataState[elem.name]?.field_value !== null && (
                        <Button
                          ref={seeMoreRef}
                          type="ghost"
                          className="historyIcon"
                          onClick={() => seeMore(answersDataState[elem.name], elem)}
                          icon={<FieldTimeOutlined style={{ fontSize: 21, color: '#63A0FF' }} />}
                        />
                      )}
                  </div>
                );
              if (curQuestionContent.type.type === 'Form')
                return (
                  <div key={elem.id} ref={fieldRef} id={answersDataState?.[elem.name]?.id}>
                    <FormQuestionType
                      curGroup={curGroup}
                      curQuestion={curQuestionContent}
                      curField={elem}
                      questionSet={questionSet}
                      iterationName={iterationName}
                    />
                  </div>
                );
              if (curQuestionContent?.type.type === 'Multi Select')
                return (
                  <div key={index} className="curField">
                    {commentsFlag(elem, seeCommentsRef)}
                    <div key={elem.id} ref={fieldRef} id={answersDataState?.[elem.name]?.id}>
                      <MultiSelect
                        key={elem.id}
                        curField={elem}
                        questionSet={questionSet}
                        iterationName={iterationName}
                        curSubSection={curSubSection}
                      />
                    </div>
                    {answersDataState &&
                      answersDataState[elem.name] &&
                      answersDataState[elem.name]?.field_value !== null && (
                        <Button
                          ref={seeMoreRef}
                          type="ghost"
                          className="historyIcon"
                          onClick={() => seeMore(answersDataState[elem.name], elem)}
                          icon={<FieldTimeOutlined style={{ fontSize: 21, color: '#63A0FF' }} />}
                        />
                      )}
                  </div>
                );
              if (curQuestionContent?.type.type === 'Iterative')
                return (
                  <Iterative
                    curGroup={curGroup}
                    key={elem.id}
                    curQuestionContent={curQuestionContent}
                    iterationCount={curQuestionContent?.iteration_count}
                    curField={elem}
                    nestedQuestion={curQuestionContent?.children}
                    uploadError={uploadError}
                    setUploadError={setUploadError}
                    curSubSection={curSubSection}
                  />
                );
            })}
        </div>
      </div>
      <HistoryLog logModalVisible={logModalVisible} setLogModalVisible={setLogModalVisible} />
    </div>
  );
};

Questions.propTypes = {
  setIsTyping: PropTypes.func,
  curSubSection: PropTypes.object,
  questionSet: PropTypes.string,
  iterationName: PropTypes.string,
  curQuestionContent: PropTypes.object,
  curGroup: PropTypes.object
};

Questions.defaultProps = {
  curSubSection: {},
  questionSet: '',
  iterationName: '',
  curQuestionContent: {},
  curGroup: {}
};

export default Questions;
