import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecClientManagementActions, ImSpecClientPageActions } from 'store/Actions';

import useQuery from 'hooks/useQuery';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import moment from 'moment';

import TableNoData from 'pages/ImSpecPages/ClientManagement/elements/TableNoData';
import ResendInvitationSummaryModal from 'pages/ImSpecPages/ClientPage/elements/ResendInvitationSummaryModal';
import SelectComponentClass from 'components/shared/SelectComponentClass';

import { Button, Checkbox, Col, Modal, Table, Tooltip, Form } from 'antd';
import {
  CloseCircleOutlined,
  DownOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  UpOutlined
} from '@ant-design/icons';
import { sendIcon } from 'components/Icons/Icons';

const ClientManagementTable = ({ caseUser, setCaseUser, setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useQuery();

  const hideChildCases = useFlag('hide-child-cases');
  const { flagsReady } = useFlagsStatus();

  const {
    ImSpecClientPage: {
      userDetail: { userDetails },
      resendItems
    },
    ImSpecClientManagement: {
      content: { tableData, page, count, pageSize, pageSizeOptions, isLoading },
      nestedTable: { nestedTableData, isNestedLoading }
    }
  } = useSelector(state => ({
    ImSpecClientPage: state.ImSpecClientPage,
    ImSpecClientManagement: state.ImSpecClientManagement
  }));

  const [form] = Form.useForm();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [nestedTableDataState, setNestedTableDataState] = useState([]);

  useEffect(() => {
    !search
      ? dispatch(ImSpecClientManagementActions.getClientManagementTableData())
      : dispatch(ImSpecClientManagementActions.searchClients({ params: search }));
  }, [dispatch, search]);

  useEffect(() => {
    !!nestedTableData && setNestedTableDataState(nestedTableData);
  }, [nestedTableData]);

  useEffect(() => {
    if (expandedRowKeys.length) {
      const caseUser = tableData.find(user => user.key === expandedRowKeys[0]) ?? {};
      setCaseUser(caseUser);
    }
  }, [tableData, expandedRowKeys, setCaseUser]);

  const onResendClient = client => {
    const { key, beneficiary, email, first_name, account_state } = client;
    const last_name = beneficiary.split(`${first_name} `)[1];
    dispatch(
      ImSpecClientPageActions.setUserDetails({
        id: key,
        full_name: beneficiary,
        email,
        first_name,
        last_name,
        account_state
      })
    );
    navigate(`${location.pathname}?action=resend-invitation-summary`);
  };

  const columns = [
    {
      title: 'Client',
      dataIndex: 'beneficiary',
      key: 'beneficiary',
      width: '20%',
      ellipsis: true,
      render: (client, { key, is_test }) => (
        <div className="beneficiary">
          <Link className="table-hrefs" to={`/client-management/userID=${key}`}>
            {client}
          </Link>
          {is_test && (
            <div className="test-account-tag">
              <div className="circle" />
              <span>Test account</span>
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '25%',
      ellipsis: true,
      render: email => <span className="email">{email}</span>
    },
    {
      title: 'Account type',
      dataIndex: 'account_type',
      key: 'account_type',
      width: '15%',
      ellipsis: true,
      render: account_type => <span className="account_type">{account_type}</span>
    },
    {
      title: 'Account state',
      dataIndex: 'account_state',
      key: 'account_state',
      width: '15%',
      ellipsis: true,
      render: account_state => (
        <div className={`account_state ${account_state}`}>{account_state}</div>
      )
    },
    {
      title: 'Last update',
      dataIndex: 'last_updated_at',
      key: 'last_updated_at',
      width: '12%',
      ellipsis: true,
      render: last_updated_at => (
        <span className="last-update">
          {moment(last_updated_at).format('MM.DD.YYYY')}
          <span className="hours">{moment(last_updated_at).format('hh:mm A')}</span>
        </span>
      )
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '8%',
      render: (_, row) => {
        const { key, cancel_available } = row;
        return (
          <div className="actions">
            <div>
              <Tooltip placement="bottom" title="Resend account invitation">
                <Button onClick={() => onResendClient(row)}>{sendIcon}</Button>
              </Tooltip>
            </div>
            <div>
              {cancel_available && (
                <Tooltip placement="bottom" title="Cancel account invitation">
                  <Button onClick={() => cancelInvitationModal(key)}>
                    {<CloseCircleOutlined />}
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        );
      }
    },
    Table.EXPAND_COLUMN
  ];

  const onExpandedRowsChange = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record.key);
      dispatch(ImSpecClientManagementActions.getClientManagementNestedTableData(record.key));
    }
    setExpandedRowKeys(keys);
    setCaseUser(record);
  };

  const showModal = () => {
    setOpen(true);
    navigate(location, { state: { invitation: 'case' } });
  };

  const nestedFooter = useCallback(() => {
    return (
      <Tooltip
        placement="bottom"
        title={
          caseUser.account_state === 'Canceled'
            ? 'This account is canceled. Cases can only be added to pending and verified accounts.'
            : 'Add case'
        }
      >
        <Button
          disabled={caseUser.account_state === 'Canceled'}
          onClick={showModal}
          icon={<PlusCircleOutlined style={{ fontSize: 20, color: '#63A0FF' }} />}
        />
      </Tooltip>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseUser.account_state]);

  const resendCancel = (
    resend_available,
    cancel_available,
    case_id,
    user_id,
    visa_name,
    user_details
  ) => {
    return (
      <div className="actions">
        <div>
          {resend_available && (
            <Tooltip placement="bottom" title="Resend case invitation">
              <Button onClick={() => resendInvitationModal(case_id, 'case', user_id, visa_name)}>
                {sendIcon}
              </Button>
            </Tooltip>
          )}
        </div>
        <div>
          {cancel_available && (
            <Tooltip placement="bottom" title="Cancel case invitation">
              <Button
                onClick={() =>
                  cancelCaseInvitationConfirm(case_id, user_id, visa_name, user_details)
                }
                icon={<CloseCircleOutlined />}
              />
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  const expandedRowRender = () => {
    const nestedColumns = [
      {
        title: 'Case ID',
        dataIndex: 'case_id',
        key: 'case_id',
        ellipsis: true,
        width: '8%',
        render: (case_id, { user_id, is_migrated, dependent_cases_count }) => (
          <div className="case_col">
            <Link className="case-id" to={`/client-management/userID=${user_id}/${case_id}`}>
              {case_id}
              {flagsReady && !hideChildCases && !!dependent_cases_count && (
                <Tooltip
                  overlayClassName="migrate-tooltip"
                  title={`${dependent_cases_count} Child Cases`}
                  placement="top"
                >
                  <div className="migrate">
                    <span>{dependent_cases_count}</span>
                  </div>
                </Tooltip>
              )}
            </Link>
            {is_migrated && (
              <Tooltip overlayClassName="migrate-tooltip" title="Migrated" placement="bottom">
                <div className="migrate">
                  <span>M</span>
                </div>
              </Tooltip>
            )}
          </div>
        )
      },
      {
        title: 'Case',
        dataIndex: 'visa_name',
        key: 'visa_name',
        width: '8%',
        ellipsis: true,
        render: (visa_name, { user_id, case_id, is_dependent }) => (
          <div className={`visa_name v${visa_name.replaceAll(' ', '-')}`}>
            <Link to={`/client-management/userID=${user_id}/${case_id}`}>
              {visa_name}{' '}
              {visa_name === 'I-539' && is_dependent
                ? 'DEP'
                : visa_name === 'I-539' && !is_dependent
                ? 'BEN'
                : null}
            </Link>
          </div>
        )
      },
      {
        title: 'Case type',
        dataIndex: 'case_type',
        key: 'case_type',
        width: '12%',
        ellipsis: true,
        render: case_type => <span className="case_type">{case_type}</span>
      },
      {
        title: 'Beneficiary',
        dataIndex: 'user_details',
        key: 'user_details',
        width: '13%',
        ellipsis: true,
        render: (user_details, { user_id }) => (
          <Link className="beneficiary" to={`/client-management/userID=${user_id}`}>
            {user_details}
          </Link>
        )
      },
      {
        title: 'Petitioner',
        dataIndex: 'petitioner',
        key: 'petitioner',
        width: '10%',
        ellipsis: true,
        render: petitioner => {
          return typeof petitioner === 'string' ? (
            <span>{petitioner}</span>
          ) : (
            <Link className="petitioner" to={`/petitioner-dashboard/${petitioner?.id}`}>
              {petitioner?.name}
            </Link>
          );
        }
      },
      {
        title: 'State/Status',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        ellipsis: true,
        render: (state, { status }) => {
          return (
            <div>
              <div className={`case_state ${state}`}>{state}</div>
              <div className={`${status.replaceAll(' ', '-')} section-status`}>
                <span className="table-items">{status}</span>
              </div>
            </div>
          );
        }
      },
      {
        title: 'Last update',
        dataIndex: 'last_updated_at',
        key: 'last_updated_at',
        width: '10%',
        ellipsis: true,
        render: last_updated_at => (
          <span className="last-update">
            {moment(last_updated_at).format('MM.DD.YYYY')}
            <span className="hours">{moment(last_updated_at).format('hh:mm A')}</span>
          </span>
        )
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: '5%',
        render: (
          _,
          { resend_available, cancel_available, case_id, user_id, visa_name, user_details }
        ) =>
          resendCancel(
            resend_available,
            cancel_available,
            case_id,
            user_id,
            visa_name,
            user_details
          )
      }
    ];

    return (
      <Table
        className="nestedTable"
        tableLayout="fixed"
        locale={{ emptyText: 'No Data' }}
        loading={{
          spinning: isNestedLoading,
          indicator: <LoadingOutlined style={{ fontSize: 30 }} />
        }}
        columns={nestedColumns}
        dataSource={nestedTableDataState}
        pagination={false}
        footer={
          (caseUser?.account_type !== 'Self-registered' && !isNestedLoading && nestedFooter) || null
        }
      />
    );
  };

  // const onChangePage = useCallback(, [dispatch, search]);

  const onChangePage = useCallback(
    (page, pageSize) => {
      dispatch(ImSpecClientManagementActions.onChangePage(page, pageSize, search));
    },
    [dispatch, search]
  );

  const resendInvitationModal = (key, type, userID, visa_name) => {
    const contentText =
      type === 'case'
        ? `This will resend the same invitation to access ${visa_name} intakes`
        : 'This will resend the same invitation to create an account.';

    const modalProps = {
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal resend',
      cancelButtonProps: {
        className: 'submit-section-confirm-modal-cancel_btn'
      },
      okButtonProps: { className: 'submit-section-confirm-modal-submit_btn' },
      okText: 'Resend',
      content: (
        <>
          <span className="resend_title">Resend Invitation</span>
          <hr />
          <span className="resend_content">
            <p>{contentText}</p>
            <p>Are you sure you want to resend?</p>
          </span>
        </>
      ),
      onOk: () => (type === 'case' ? onResendCase(key, userID) : onResendSubmit(key))
    };
    Modal.confirm(modalProps);
  };

  const onResendSubmit = key => {
    dispatch(ImSpecClientManagementActions.resendInvitation(key, search));
  };

  const onResendCase = (caseID, userID) => {
    dispatch(ImSpecClientManagementActions.resendCaseInvitation(caseID, userID, search));
  };

  const onCancelCase = useCallback(
    (caseID, userID) => {
      dispatch(
        ImSpecClientManagementActions.cancelCaseInvitation(
          caseID,
          userID,
          search,
          form.getFieldValue('destroy')
        )
      );
    },
    [dispatch, form, search]
  );

  const cancelCaseInvitationConfirm = useCallback(
    (caseID, userID, caseType, userDetails) => {
      const onCancel = () => form.resetFields();

      const modalProps = {
        width: 438,
        centered: true,
        icon: null,
        className: 'submit-section-confirm-modal resend cancel',
        cancelButtonProps: {
          className: 'submit-section-confirm-modal-cancel_btn'
        },
        okButtonProps: { className: 'submit-section-confirm-modal-submit_btn' },
        okText: 'Yes, cancel',
        cancelText: 'No, thanks',
        content: (
          <>
            <span className="resend_title">Cancel Invitation</span>
            <hr />
            <div className="resend_content">
              <p>
                If you cancel, {userDetails} will lose access to {caseType} intakes.
              </p>
              <div className="resend-checkbox">
                <Form form={form}>
                  <Form.Item name="destroy" valuePropName="checked">
                    <Checkbox defaultChecked={false}>Also delete this case forever.</Checkbox>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </>
        ),
        onCancel,
        onOk: () => onCancelCase(caseID, userID)
      };
      Modal.confirm(modalProps);
      form.setFieldsValue({ destroy: false });
    },
    [form, onCancelCase]
  );

  const cancelInvitationModal = key => {
    const modalProps = {
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal resend cancel',
      cancelButtonProps: {
        className: 'submit-section-confirm-modal-cancel_btn'
      },
      okButtonProps: { className: 'submit-section-confirm-modal-submit_btn' },
      okText: 'Yes, cancel',
      cancelText: 'No, thanks',
      content: (
        <>
          <span className="resend_title">Cancel Invitation</span>
          <hr />
          <span className="resend_content">
            <p>
              The client won’t be able to sign up with the link in the invitation previously sent.
            </p>
            <p>Are you sure you want to cancel?</p>
          </span>
        </>
      ),
      onOk: () => onCancelSubmit(key)
    };
    Modal.confirm(modalProps);
  };

  const onCancelSubmit = key => {
    dispatch(ImSpecClientManagementActions.cancelInvitation(key, search, expandedRowKeys));
  };

  const pagination = useMemo(
    () => ({
      className: 'lp-pagination',
      defaultCurrent: 1,
      defaultPageSize: 100,
      hideOnSinglePage: true,
      current: page,
      position: ['bottomCenter'],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      size: 'small',
      pageSize: pageSize,
      pageSizeOptions: pageSizeOptions,
      showSizeChanger: count > pageSize,
      total: count,
      showQuickJumper: false,
      onChange: onChangePage,
      selectComponentClass: SelectComponentClass
    }),
    [count, onChangePage, page, pageSize, pageSizeOptions]
  );

  return (
    <Col style={{ height: '100%' }}>
      <Table
        dataSource={tableData}
        columns={columns}
        className="status-table"
        scroll={{ y: '100%' }}
        locale={{ emptyText: <TableNoData search={search} /> }}
        tableLayout="fixed"
        loading={{
          spinning: isLoading,
          indicator: <LoadingOutlined style={{ fontSize: 30 }} />
        }}
        expandable={{
          onExpand: onExpandedRowsChange,
          expandedRowKeys: expandedRowKeys,
          expandedRowRender,
          expandIcon: ({ expanded, onExpand, record }) => (
            <Button
              className="expandIcon"
              onClick={e => onExpand(record, e)}
              icon={expanded ? <UpOutlined /> : <DownOutlined />}
            />
          )
        }}
        pagination={pagination}
      />
      <ResendInvitationSummaryModal
        user={userDetails}
        resendItems={resendItems}
        expandedRowKeys={expandedRowKeys}
      />
    </Col>
  );
};

ClientManagementTable.propTypes = {
  caseUser: PropTypes.object,
  setCaseUser: PropTypes.func,
  setOpen: PropTypes.func
};

ClientManagementTable.defaultProps = {
  caseUser: {}
};

export default ClientManagementTable;
