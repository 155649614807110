import { createRoot } from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from 'react-router-dom';
import { App } from 'routes';
import history from './utils/history';

import { Provider } from 'react-redux';
import store from './store';

import FlagProvider from '@unleash/proxy-client-react';

import 'antd/dist/antd';
import './index.scss';

const {
  REACT_APP_UNLEASH_PROXY_CLIENT_KEYS,
  REACT_APP_UNLEASH_URL,
  REACT_APP_UNLEASH_ENV,
  REACT_APP_UNLEASH_APP_NAME
} = process.env;

const config = {
  url: REACT_APP_UNLEASH_URL,
  clientKey: REACT_APP_UNLEASH_PROXY_CLIENT_KEYS,
  appName: REACT_APP_UNLEASH_APP_NAME,
  environment: REACT_APP_UNLEASH_ENV,
  refreshInterval: 1800,
  disableMetrics: true
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <FlagProvider config={config}>
    <Provider store={store}>
      <HistoryRouter history={history}>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </HistoryRouter>
    </Provider>
  </FlagProvider>
);
