import React, { useCallback, useMemo } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions, HistoryLogCommentsThreadAction } from 'store/Actions';

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { Col, Layout, Dropdown, Row, Button, Badge, Divider } from 'antd';
import { CommentOutlined, InfoCircleFilled, LogoutOutlined } from '@ant-design/icons';
import { avatar, avatarIconBold } from 'components/Icons/Icons';

import './HeadNavbar.scss';

const { Header } = Layout;

const HeadNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customer_questionnaire = useMatch(
    '/your-questionnaires/:caseID/:sectionTitle/:subSectionTitle'
  );
  const is_questionnaire = useMatch('/client-management/userID=:userID/:caseID/:sectionTitle');
  const ISMasterIntake = useMatch(
    '/petitioner-dashboard/master-intake/:masterIntakeID/:petitionerName'
  );
  const masterIntake = useMatch('/master-intake/:masterIntakeID/:petitionerName/:subSectionTitle');
  const masterIntakeReview = useMatch('/master-intake/:masterIntakeID/:petitionerName/review');

  const {
    auth: { user },
    ImSpecContent: { section, sync_info },
    customerContent: { section: section_cus, subsection, sync_info: sync_info_cus },
    customerSectionsReview: {
      content: { section: reviewSection }
    },
    customerSidebar: {
      sidebarInfo: { master_intake }
    },
    HistoryLogCommentsThread: {
      comments: { tabs }
    }
  } = useSelector(state => ({
    auth: state.auth,
    ImSpecContent: state.ImSpecContent,
    customerContent: state.customerContent,
    customerSectionsReview: state.customerSectionsReview,
    customerSidebar: state.customerSidebar,
    HistoryLogCommentsThread: state.HistoryLogCommentsThread
  }));

  const onlyProdGrowsurf = useFlag('only-prod-growsurf');
  const hideCommentsOverview = useFlag('hide-comments-overview');
  const { flagsReady } = useFlagsStatus();

  const getGrowsurfFlag = useCallback(() => {
    if (flagsReady) return onlyProdGrowsurf;
  }, [flagsReady, onlyProdGrowsurf]);

  const signOut = () => {
    dispatch(AuthActions.signOut({ refresh: user.refresh }, navigate, getGrowsurfFlag()));
  };

  const goProfilePage = () => {
    navigate('/account-profile');
  };

  const onOpenHistoryLogCommentsThread = useCallback(
    tab => () => {
      dispatch(HistoryLogCommentsThreadAction.onOpenChange(tab));
    },
    [dispatch]
  );

  const commentsThread = useMemo(() => {
    return flagsReady &&
      !hideCommentsOverview &&
      user?.role === 'customer' &&
      (customer_questionnaire || masterIntake || masterIntakeReview) ? (
      <>
        <Col>
          <Button
            onClick={onOpenHistoryLogCommentsThread('comments')}
            type="ghost"
            className="google-drive"
            icon={
              <Badge
                rootClassName="unresolved-badge"
                dot={!!tabs.find(tab => tab.status === 'Unresolved')?.value}
              >
                <CommentOutlined style={{ fontSize: 24, color: '#63A0FF' }} />
              </Badge>
            }
          />
        </Col>
        <Divider type="vertical" />
      </>
    ) : null;
  }, [
    customer_questionnaire,
    flagsReady,
    hideCommentsOverview,
    masterIntake,
    masterIntakeReview,
    onOpenHistoryLogCommentsThread,
    tabs,
    user?.role
  ]);

  return (
    <Header className="headNavbar">
      <Col className="widget-container">
        {subsection?.section_status === 'IS Approved' && (
          <div className="sectionMessage sectionMessage_green">
            <InfoCircleFilled style={{ color: '#3eb43c' }} />
            <p>This section has been approved by an LP specialist.</p>
          </div>
        )}
        {((customer_questionnaire?.params.subSectionTitle !== 'review' &&
          !sync_info_cus?.sync_in_progress &&
          sync_info_cus?.last_sync_source_type === 'section' &&
          (subsection?.section_status === 'Attempted' ||
            subsection?.section_status === 'Not Attempted')) ||
          (customer_questionnaire?.params.subSectionTitle === 'review' &&
            !reviewSection?.sync_in_progress &&
            reviewSection?.last_sync_source_type === 'section' &&
            (reviewSection?.section_status === 'Attempted' ||
              reviewSection?.section_status === 'Not Attempted'))) && (
          <div className="sectionMessage sectionMessage_blue">
            <InfoCircleFilled />
            <p>
              Some details below may have been copied from another application for you. Please
              review this questionnaire carefully and update your information as needed.
            </p>
          </div>
        )}
        {((ISMasterIntake &&
          sync_info.sync_in_progress &&
          sync_info.synced_with_master_intake &&
          sync_info.last_sync_source_type === 'master_intake') ||
          (masterIntake &&
            sync_info_cus?.sync_in_progress &&
            sync_info_cus?.synced_with_master_intake &&
            sync_info_cus?.last_sync_type === 'master_intake') ||
          (masterIntakeReview &&
            reviewSection.sync_in_progress &&
            reviewSection.synced_with_master_intake &&
            reviewSection.last_sync_source_type === 'master_intake')) && (
          <div className="sectionMessage sectionMessage_blue">
            <InfoCircleFilled />
            <p>
              Syncing between company intakes is in progress! The fields will be enabled again once
              the sync is completed. Please refresh the page to confirm sync completion.
            </p>
          </div>
        )}
        {((ISMasterIntake &&
          !sync_info.sync_in_progress &&
          sync_info.synced_with_master_intake &&
          sync_info.master_intake_sync_obligation === 'target') ||
          (masterIntake &&
            !sync_info_cus?.sync_in_progress &&
            sync_info_cus?.synced_with_master_intake &&
            section_cus?.master_intake_sync_obligation === 'target') ||
          (masterIntakeReview &&
            !reviewSection.sync_in_progress &&
            reviewSection.synced_with_master_intake &&
            reviewSection.master_intake_sync_obligation === 'target')) && (
          <div className="sectionMessage sectionMessage_blue">
            <InfoCircleFilled />
            <p>
              The details below are derived from a prior {section?.petitioner_name} questionnaire.
              Please review this company questionnaire carefully and add or update information as
              needed.
            </p>
          </div>
        )}
        {(ISMasterIntake || masterIntake) && master_intake.length > 1 && (
          <div className="sectionMessage sectionMessage_blue">
            <InfoCircleFilled />
            <p>
              The information provided here will also update other company questionnaires for{' '}
              {section?.petitioner_name ?? section_cus?.petitioner_name}
            </p>
          </div>
        )}
        {(ISMasterIntake || masterIntakeReview || masterIntake) &&
          (sync_info?.sync_in_progress ||
            sync_info_cus?.sync_in_progress ||
            reviewSection?.sync_in_progress) && (
            <div className="sectionMessage sectionMessage_blue">
              <InfoCircleFilled />
              <p>
                The information in this intake is being synced to employee cases. The fields will be
                enabled again once the sync is completed. Please refresh the page to confirm sync
                completion.
              </p>
            </div>
          )}
        {((customer_questionnaire?.params.subSectionTitle !== 'review' &&
          subsection?.owner?.is_representative &&
          subsection?.is_blocked) ||
          (customer_questionnaire?.params.subSectionTitle === 'review' &&
            reviewSection?.owner?.is_representative &&
            reviewSection?.is_blocked)) && (
          <div className="sectionMessage sectionMessage_blue">
            <InfoCircleFilled />
            <p>This questionnaire has been sent to the company representative for completion.</p>
          </div>
        )}
        {((customer_questionnaire?.params.subSectionTitle !== 'review' &&
          !subsection?.owner?.is_representative &&
          subsection?.is_blocked) ||
          (customer_questionnaire?.params.subSectionTitle === 'review' &&
            !reviewSection?.owner?.is_representative &&
            reviewSection?.is_blocked)) && (
          <div className="sectionMessage sectionMessage_blue">
            <InfoCircleFilled />
            <p>This questionnaire has been sent to your colleague for completion.</p>
          </div>
        )}
        {((customer_questionnaire?.params.subSectionTitle !== 'review' &&
          !subsection?.is_blocked &&
          sync_info_cus?.sync_in_progress &&
          subsection?.last_sync_source_type === 'master_intake') ||
          (customer_questionnaire?.params.subSectionTitle === 'review' &&
            !reviewSection?.is_blocked &&
            reviewSection?.sync_in_progress &&
            reviewSection?.last_sync_source_type === 'master_intake')) && (
          <div className="sectionMessage sectionMessage_blue">
            <InfoCircleFilled />
            <p>
              Information in this intake is being synced from Master intake. The fields will be
              enabled again once the sync is completed.
            </p>
          </div>
        )}
        {((customer_questionnaire?.params.subSectionTitle !== 'review' &&
          !subsection?.is_blocked &&
          sync_info_cus?.sync_in_progress &&
          subsection?.last_sync_source_type === 'section' &&
          subsection?.sync_obligation === 'target') ||
          (customer_questionnaire?.params.subSectionTitle === 'review' &&
            !reviewSection?.is_blocked &&
            reviewSection?.sync_in_progress &&
            reviewSection?.last_sync_source_type === 'section' &&
            reviewSection?.sync_obligation === 'target')) && (
          <div className="sectionMessage sectionMessage_blue">
            <InfoCircleFilled />
            <p>
              Details are now being ported here from one of your related cases. Please refresh the
              page to load them.
            </p>
          </div>
        )}
        {((customer_questionnaire?.params.subSectionTitle !== 'review' &&
          !subsection?.is_blocked &&
          sync_info_cus?.sync_in_progress &&
          subsection?.sync_obligation === 'source') ||
          (customer_questionnaire?.params.subSectionTitle === 'review' &&
            !reviewSection?.is_blocked &&
            reviewSection?.sync_in_progress &&
            reviewSection?.sync_obligation === 'source')) && (
          <div className="sectionMessage sectionMessage_blue">
            <InfoCircleFilled />
            <p>Syncing in progress. Please wait.</p>
          </div>
        )}
        {is_questionnaire &&
          sync_info?.sync_in_progress &&
          sync_info?.last_sync_source_type === 'master_intake' && (
            <div className="sectionMessage sectionMessage_blue">
              <InfoCircleFilled />
              <p>
                Information in this intake is being synced from{' '}
                <Link
                  className="master-intake-link"
                  to={`/petitioner-dashboard/master-intake/${
                    section?.master_intake_id
                  }/${section?.petitioner?.name.replaceAll(' ', '-')}`}
                >
                  Master intake
                </Link>
                . The fields will be enabled again once the sync is completed.
              </p>
            </div>
          )}
        {is_questionnaire &&
          sync_info?.sync_in_progress &&
          sync_info?.last_sync_source_type === 'section' &&
          sync_info?.sync_obligation === 'target' && (
            <div className="sectionMessage sectionMessage_blue">
              <InfoCircleFilled />
              <p>Syncing in progress. Please wait.</p>
            </div>
          )}
        {is_questionnaire &&
          sync_info?.sync_in_progress &&
          sync_info?.sync_obligation === 'source' && (
            <div className="sectionMessage sectionMessage_blue">
              <InfoCircleFilled />
              <p>Syncing in progress. Please wait.</p>
            </div>
          )}
      </Col>
      <Row align="middle">
        {commentsThread}
        <Dropdown
          className={`account-settings${user?.role === 'customer' ? ' customer' : ''}`}
          placement="bottomRight"
          getPopupContainer={triggerNode => triggerNode.parentNode}
          menu={{
            className: 'account-settings-menu',
            selectable: false,
            items: [
              {
                label: 'My account',
                key: 'profile',
                icon: avatarIconBold,
                onClick: goProfilePage
              },
              {
                label: 'Sign Out',
                key: 'signOut',
                icon: <LogoutOutlined style={{ margin: 0 }} />,
                onClick: signOut
              }
            ]
          }}
          trigger="click"
          arrow={false}
        >
          <Row align="middle" justify="center" style={{ cursor: 'pointer' }}>
            <span>
              {user.first_name} {user.last_name}
            </span>{' '}
            {avatar}
          </Row>
        </Dropdown>
      </Row>
    </Header>
  );
};

export default HeadNavbar;
