import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';
import CheckboxList from 'components/shared/CheckboxList';
import { Form } from 'antd';

const Status = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const selectedList = Form.useWatch('status_options', form);
  Form.useWatch('status_options_all', form);

  const {
    filters: {
      status: { list }
    },
    filterAnswers: { status }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    if (typeof selectedList !== 'undefined') {
      dispatch(ImSpecCaseDashboardBetaAction.setStatus(selectedList));
    }
  }, [dispatch, selectedList]);

  useEffect(() => {
    status && !status.length && form.resetFields();
  }, [status, form]);

  return (
    <CheckboxList
      form={form}
      plainOptions={list}
      filterOption="status_options"
      checkedValues={status}
    />
  );
};

export default Status;
