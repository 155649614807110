import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  HistoryLogCommentsThreadAction,
  ImSpecSectionDashboardActions,
  ImSpecSideBarAction
} from 'store/Actions';

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

// components
import CaseTable from 'pages/ImSpecPages/SectionDashboard/elements/CaseTable';
import AssociatedAccounts from 'pages/ImSpecPages/SectionDashboard/elements/AssociatedAccounts';
import ChangeOwnerModal from 'pages/ImSpecPages/SectionDashboard/elements/ChangeOwner';
import InviteOwner from 'pages/ImSpecPages/SectionDashboard/elements/InviteOwner';
import Contacts from 'pages/ImSpecPages/SectionDashboard/elements/Contacts';
import ChildCases from 'pages/ImSpecPages/SectionDashboard/elements/ChildCases';

import { Button, Col, Collapse, Dropdown, Row, Breadcrumb, Spin, Badge } from 'antd';
import {
  FieldTimeOutlined,
  CommentOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { googleDriveIcon } from 'components/Icons/Icons';

const { Panel } = Collapse;

const SectionDashboard = () => {
  const dispatch = useDispatch();
  const { caseID, userID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const hideChildCases = useFlag('hide-child-cases');
  const hideHistoryLogCommentsThread = useFlag('hide-history-log-comments-thread');
  const hideCommentsOverview = useFlag('hide-comments-overview');
  const { flagsReady } = useFlagsStatus();

  const {
    ImSpecSectionDashboard: {
      case: { list, child_cases, isLoading },
      associated_accounts: { list: accounts },
      contacts: {
        im_spec,
        attorney,
        beneficiary,
        petitioner,
        visa_name,
        parent_breadcrumbs,
        dependent,
        is_dependent
      }
    },
    HistoryLogCommentsThread: {
      comments: { tabs: commentViewTabs }
    }
  } = useSelector(state => ({
    ImSpecSectionDashboard: state.ImSpecSectionDashboard,
    HistoryLogCommentsThread: state.HistoryLogCommentsThread
  }));

  const [activeKey, setActiveKey] = useState([]);

  useEffect(() => {
    if (caseID) {
      dispatch(ImSpecSectionDashboardActions.getCaseList(caseID, navigate));
      dispatch(ImSpecSectionDashboardActions.getAssociatedAccounts(caseID, navigate));
      setActiveKey([]);
    }
  }, [dispatch, caseID, navigate]);

  useEffect(() => {
    if (flagsReady && !hideCommentsOverview) {
      dispatch(HistoryLogCommentsThreadAction.getCommentsTabs(caseID));
    }
  }, [caseID, dispatch, flagsReady, hideCommentsOverview]);

  const stopProp = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onClickForChooseAccount = ({ key }) => {
    navigate(`${location.pathname}?action=${key}`);
  };

  const menu = {
    className: 'associated_accounts-menu',
    selectable: false,
    onClick: onClickForChooseAccount,
    items: [
      { label: 'Invite New Person', key: 'invite-new-person' },
      {
        label: 'Choose from existing clients',
        key: 'choose-from-existing-clients'
      }
    ]
  };

  const CollapseHeader = () => (
    <Row className="collapse-accounts">
      <span className="title">Associated Accounts</span>
      <Row onClick={stopProp} align="middle" justify="center" style={{ cursor: 'pointer' }}>
        <Dropdown
          overlayClassName="associated_accounts-dropdown"
          className="associated_accounts-dropdown"
          placement="top"
          getPopupContainer={triggerNode => triggerNode.parentNode}
          menu={menu}
          trigger="click"
          arrow={false}
        >
          <Button
            className="associated_accounts-btn"
            icon={<PlusOutlined style={{ fontSize: '20px', color: '#FFF' }} />}
          />
        </Dropdown>
      </Row>
    </Row>
  );

  const onClickGoogleDrive = useCallback(() => {
    dispatch(ImSpecSideBarAction.goToGoogleDrive(caseID));
  }, [caseID, dispatch]);

  const onChangeCollapse = key => {
    setActiveKey(key);
  };

  const ChildCasesFlag = useMemo(() => {
    return flagsReady && !hideChildCases ? (
      <ChildCases child_cases={child_cases} loading={isLoading} userID={userID} />
    ) : null;
  }, [child_cases, flagsReady, hideChildCases, isLoading, userID]);

  const onOpenHistoryLogCommentsThread = useCallback(
    tab => () => {
      dispatch(HistoryLogCommentsThreadAction.onOpenChange(tab));
    },
    [dispatch]
  );

  const historyLogCommentsThread = useMemo(() => {
    return flagsReady && !hideHistoryLogCommentsThread ? (
      <Row gutter={12}>
        <Col>
          <Button
            onClick={onOpenHistoryLogCommentsThread('history_log')}
            type="ghost"
            className="google-drive"
            icon={<FieldTimeOutlined style={{ fontSize: 24, color: '#63A0FF' }} />}
          />
        </Col>
        {!hideCommentsOverview ? (
          <Col>
            <Button
              onClick={onOpenHistoryLogCommentsThread('comments')}
              type="ghost"
              className="google-drive"
              icon={
                <Badge
                  rootClassName="unresolved-badge"
                  dot={!!commentViewTabs.find(tab => tab.status === 'Unresolved')?.value}
                >
                  <CommentOutlined style={{ fontSize: 24, color: '#63A0FF' }} />
                </Badge>
              }
            />
          </Col>
        ) : null}
        <Col>
          <Button
            type="ghost"
            className="google-drive"
            onClick={onClickGoogleDrive}
            icon={googleDriveIcon}
          />
        </Col>
      </Row>
    ) : (
      <Button
        type="ghost"
        className="google-drive-old"
        onClick={onClickGoogleDrive}
        icon={googleDriveIcon}
      >
        G-DRIVE
      </Button>
    );
  }, [
    commentViewTabs,
    flagsReady,
    hideCommentsOverview,
    hideHistoryLogCommentsThread,
    onClickGoogleDrive,
    onOpenHistoryLogCommentsThread
  ]);

  if (isLoading)
    return (
      <Spin
        className="section_dashboard_loading"
        indicator={<LoadingOutlined style={{ fontSize: 80, color: '#63A0FF' }} />}
      />
    );

  return (
    <Col className="section_dashboard">
      <Row className="section_dashboard_breadcrumb" justify="space-between" align="middle">
        <Col>
          <Breadcrumb
            className="breadcrumb"
            separator=">"
            items={[
              {
                title:
                  typeof parent_breadcrumbs === 'string' ? (
                    <span className="petitioner">{parent_breadcrumbs}</span>
                  ) : (
                    <Link to={`/petitioner-dashboard/${parent_breadcrumbs?.id}`}>
                      {parent_breadcrumbs?.name}
                    </Link>
                  )
              },
              {
                title: (
                  <Link to={`/client-management/userID=${beneficiary?.id}`}>
                    {beneficiary?.first_name} {beneficiary?.last_name}
                  </Link>
                )
              },
              {
                title: (
                  <span className="case-details">
                    {caseID}
                    <div className="circle" />
                    <div className={`visa_name v${visa_name?.replaceAll(' ', '-')}`}>
                      {visa_name}{' '}
                      {visa_name === 'I-539' && is_dependent
                        ? 'DEP'
                        : visa_name === 'I-539' && !is_dependent
                        ? 'BEN'
                        : null}
                    </div>
                    {dependent && (
                      <>
                        <div className="circle" />
                        {dependent}
                      </>
                    )}
                  </span>
                )
              }
            ]}
          />
        </Col>
        <Col>{historyLogCommentsThread}</Col>
      </Row>
      <Col className="case-dashboard">
        <Col>
          <Contacts contacts={{ im_spec, attorney, beneficiary, petitioner }} />
          <Col className="section-table">
            <h2 className="case-dashboard-title">Intake Sections</h2>
            <CaseTable loading={isLoading} data={list} hasAccounts={!!accounts.length} />
          </Col>
          {ChildCasesFlag}
          <Col className="section-associated_accounts">
            <Collapse
              activeKey={activeKey}
              onChange={onChangeCollapse}
              collapsible={!accounts.length && 'disabled'}
              className="collapse-associated"
            >
              <Panel key="1" header={<CollapseHeader />}>
                <Row gutter={[28, 28]} className="account-block">
                  {accounts.map(account => (
                    <AssociatedAccounts key={account.id} account={account} />
                  ))}
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Col>
        <ChangeOwnerModal sections={list} accounts={accounts} beneficiary={beneficiary || {}} />
        <InviteOwner />
      </Col>
    </Col>
  );
};

export default SectionDashboard;
