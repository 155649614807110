import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';
import { CloseOutlined } from '@ant-design/icons';

import { filterNameHelperRemoveLine } from 'utils/helper';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const AdvancedQuickFilters = ({ showDrawer, setShowAdvancedTooltip, setActiveTab }) => {
  const dispatch = useDispatch();
  const caseDashboardFilters = JSON.parse(localStorage.getItem('case-dashboard-filters'));

  const {
    appliedSavedView: { id: savedViewId, name: savedViewName }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  const {
    status = [],
    beneficiary = [],
    im_spec = {},
    attorney = {},
    case_id = [],
    source = [],
    include_test_cases
  } = caseDashboardFilters ?? [];

  const renderButton = (property, label, clearFilterName, filterOption = null) => {
    if (Array.isArray(property) && property.length > 0) {
      return (
        <div className="advancedQuickFilter">
          <Button
            type="ghost"
            onClick={() => {
              showDrawer();
              setShowAdvancedTooltip(false);
              setActiveTab(label);
            }}
          >
            <div>
              {label}:
              <span className="boldTip">
                {property?.map((item, index, propertyArray) => {
                  if (typeof item === 'object' && Object.keys(item).length > 0) {
                    return `${item[filterOption]}${propertyArray.length - 1 !== index ? ', ' : ''}`;
                  } else {
                    return `${item}${propertyArray.length - 1 !== index ? ', ' : ''}`;
                  }
                })}
              </span>
            </div>
          </Button>
          <CloseOutlined
            onClick={() => {
              dispatch(ImSpecCaseDashboardBetaAction.clearFilters(clearFilterName));
              dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
              dispatch(ImSpecCaseDashboardBetaAction.applyFilters());
              dispatch(ImSpecCaseDashboardBetaAction.setApplySavedViewName({ id: null, name: '' }));
              localStorage.removeItem('case-dashboard-saved_view');
            }}
          />
        </div>
      );
    } else if (typeof property === 'object' && Object.keys(property).length > 0) {
      if (property.options && Array.isArray(property.options) && property.options.length > 0) {
        return (
          <div className="advancedQuickFilter">
            <Button
              type="ghost"
              onClick={() => {
                showDrawer();
                setShowAdvancedTooltip(false);
                setActiveTab(label);
              }}
            >
              <div>
                {label}:
                <span className="boldTip">
                  {property.options?.map((option, index, optionsArray) => {
                    let ISIANames = '';
                    if (label === 'IS' || label === 'IA') {
                      ISIANames = property[filterOption]
                        ? `: (${property[filterOption]?.map(
                            (option, index) =>
                              `${index !== 0 ? ' ' : ''}${option.first_name} ${option.last_name}`
                          )})`
                        : '';
                    }
                    const common = optionsArray.length - 1 !== index ? ', ' : '';

                    return `${filterNameHelperRemoveLine(option)}${
                      filterNameHelperRemoveLine(option) === 'assigned to others'
                        ? `${ISIANames}`
                        : ''
                    }${common}`;
                  })}

                  {label !== 'IS' &&
                    label !== 'IA' &&
                    property[filterOption] &&
                    property[filterOption]?.map(
                      (option, index, filterOptionArray) =>
                        `${option.first_name} ${option.last_name}${
                          filterOptionArray.length - 1 !== index ? ', ' : ''
                        }`
                    )}
                </span>
              </div>
            </Button>
            <CloseOutlined
              onClick={() => {
                dispatch(ImSpecCaseDashboardBetaAction.clearFilters(clearFilterName));
                dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
                dispatch(ImSpecCaseDashboardBetaAction.applyFilters(savedViewId, savedViewName));
              }}
            />
          </div>
        );
      }
    } else if (typeof property === 'boolean' && property) {
      return (
        <div className="advancedQuickFilter">
          <Button
            type="ghost"
            onClick={() => {
              showDrawer();
              setShowAdvancedTooltip(false);
              setActiveTab(label);
            }}
          >
            <div>{label}</div>
          </Button>
          <CloseOutlined
            onClick={() => {
              dispatch(ImSpecCaseDashboardBetaAction.clearFilters(clearFilterName));
              dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
              dispatch(ImSpecCaseDashboardBetaAction.applyFilters(savedViewId, savedViewName));
            }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {renderButton(status, 'Status', 'status')}
      {renderButton(beneficiary, 'Beneficiary', 'beneficiary', 'beneficiary')}
      {renderButton(im_spec, 'IS', 'im_spec', 'im_spec_ids')}
      {renderButton(attorney, 'IA', 'attorney', 'attorney_ids')}
      {renderButton(case_id, 'Case ID', 'case_id', 'case_id')}
      {renderButton(source, 'Source', 'source')}
      {renderButton(include_test_cases, 'Include Test Cases', 'include_test_cases')}
    </div>
  );
};

AdvancedQuickFilters.propTypes = {
  showDrawer: PropTypes.func,
  setShowAdvancedTooltip: PropTypes.func,
  setActiveTab: PropTypes.func
};

export default AdvancedQuickFilters;
