import request from 'utils/request';

export const CustomerSidebarService = {
  getCaseTypes: caseID => {
    return request('GET', `api/answer/cases/${caseID}/sidebar-data`);
  },
  getMasterIntakeCaseTypes: petitionerName => {
    return request('GET', `api/master-intake/petitioner/${petitionerName}/sidebar`);
  },
  getEntryVisaTypeSectionSets: caseID => {
    return request('GET', `api/answer/visa-section-set/${caseID}`);
  }
};
