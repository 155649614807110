import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import Contacts from '../Contacts';
import CaseTable from '../CaseTable';
import ChildCases from 'pages/ImSpecPages/SectionDashboard/elements/ChildCases';

import { Col, Collapse } from 'antd';

const { Panel } = Collapse;

const BeneficiarySection = ({
  curActiveCase,
  getCurrentCase,
  beneficiaryList,
  contacts,
  casesLoading,
  list,
  role,
  userDetails,
  child_cases
}) => {
  const scrollRef = useRef();
  const hideChildCases = useFlag('hide-child-cases');
  const { flagsReady } = useFlagsStatus();

  useEffect(() => {
    !casesLoading &&
      !!scrollRef.current &&
      curActiveCase[0] === scrollRef.current.id &&
      scrollRef.current.offsetParent &&
      scrollRef.current.offsetParent.scrollIntoView({ behavior: 'smooth' });
  }, [curActiveCase, getCurrentCase, casesLoading]);

  const viewClientProp = e => {
    e.stopPropagation();
  };

  const childCases = useMemo(() => {
    return flagsReady && !hideChildCases && child_cases ? (
      <ChildCases
        child_cases={child_cases}
        loading={casesLoading}
        userID={userDetails?.id}
        page="client-page"
        role={role}
        caseType="own"
      />
    ) : null;
  }, [casesLoading, child_cases, flagsReady, hideChildCases, role, userDetails?.id]);

  return (
    <Col className="section-collapse">
      <Collapse defaultActiveKey={['1']} className="collapse-block">
        <Panel
          key="1"
          header={
            <h2 className="case-dashboard-title">
              {role !== 'customer' ? `${userDetails.first_name}'s` : 'Your'} Case Questionnaires
            </h2>
          }
        >
          <Collapse
            activeKey={curActiveCase}
            onChange={getCurrentCase}
            className="collapse-block-nested"
          >
            {beneficiaryList.map(elem => {
              return (
                <Panel
                  key={elem.case_id}
                  header={
                    <div
                      id={elem.case_id}
                      ref={curActiveCase[0] === elem.case_id ? scrollRef : null}
                      className="case-dashboard-petitioner"
                    >
                      <h3>
                        {role !== 'customer' && (
                          <Link
                            className="case"
                            onClick={e => viewClientProp(e)}
                            to={`/client-management/userID=${elem.user_id}/${elem.case_id}`}
                          >
                            {elem.case_id}
                          </Link>
                        )}
                        {role !== 'customer' ? (
                          typeof elem.petitioner === 'string' ? (
                            <span>{elem.petitioner}</span>
                          ) : (
                            <Link
                              className="petitioner"
                              onClick={e => viewClientProp(e)}
                              to={`/petitioner-dashboard/${elem.petitioner?.id}`}
                            >
                              {elem.petitioner?.name}
                            </Link>
                          )
                        ) : (
                          elem.petitioner?.name
                        )}
                        <span className="visa">
                          {elem.visa_name}{' '}
                          {elem.visa_name === 'I-539' && elem.is_dependent ? elem.dependent : null}
                        </span>
                      </h3>
                      {role !== 'customer' && (
                        <div>
                          <h3>{elem.state}</h3>
                          {/*<div className="actions">*/}
                          {/*  <div>*/}
                          {/*    {*/}
                          {/*        elem.resend_available &&*/}
                          {/*          <Tooltip placement="bottom" title="Resend account invitation">*/}
                          {/*            <Button>{sendIcon}</Button>*/}
                          {/*          </Tooltip>*/}
                          {/*    }*/}
                          {/*  </div>*/}
                          {/*  <div>*/}
                          {/*    {*/}
                          {/*        elem.cancel_available &&*/}
                          {/*          <Tooltip placement="bottom" title="Cancel account invitation">*/}
                          {/*            <Button>{outlineCancelIcon}</Button>*/}
                          {/*          </Tooltip>*/}
                          {/*    }*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                        </div>
                      )}
                    </div>
                  }
                >
                  {!!contacts && (contacts.im_spec || contacts.attorney) && (
                    <Contacts contacts={contacts} />
                  )}
                  <CaseTable
                    caseType="own"
                    role={role}
                    caseID={elem.case_id}
                    loading={casesLoading}
                    data={list.map((section, index) => ({
                      ...section,
                      key: index
                    }))}
                  />
                  {childCases}
                </Panel>
              );
            })}
          </Collapse>
        </Panel>
      </Collapse>
    </Col>
  );
};

BeneficiarySection.propTypes = {
  userDetails: PropTypes.object,
  contacts: PropTypes.object,
  curActiveCase: PropTypes.array,
  getCurrentCase: PropTypes.func,
  beneficiaryList: PropTypes.array,
  casesLoading: PropTypes.bool,
  list: PropTypes.array,
  role: PropTypes.string,
  child_cases: PropTypes.array
};

BeneficiarySection.defaultProps = {
  userDetails: {},
  contacts: {},
  curActiveCase: [],
  first_name: '',
  userID: null,
  beneficiaryList: [],
  casesLoading: false,
  list: [],
  role: '',
  child_cases: []
};

export default BeneficiarySection;
