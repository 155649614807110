import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ImSpecSideBarAction } from 'store/Actions';

import { googleDriveIcon } from 'components/Icons/Icons';
import { Button } from 'antd';

const GoogleDriveButton = ({ caseID, collapsed }) => {
  const dispatch = useDispatch();

  const collapsedStyle = collapsed
    ? { padding: '5px', background: 'transparent', justifyContent: 'center' }
    : {};

  const onClickGoogleDrive = () => {
    dispatch(ImSpecSideBarAction.goToGoogleDrive(caseID));
  };

  return (
    <Button
      type="ghost"
      className="googleDriveBtn"
      onClick={onClickGoogleDrive}
      style={collapsedStyle}
      icon={googleDriveIcon}
    >
      {!collapsed && <span>Google Drive</span>}
    </Button>
  );
};

GoogleDriveButton.propTypes = {
  caseID: PropTypes.string,
  collapsed: PropTypes.bool
};

GoogleDriveButton.defaultProps = {
  caseID: '',
  collapsed: false
};

export default GoogleDriveButton;
