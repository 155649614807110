import { CustomerActionTypes } from '../types';

const initialState = {
  section: {},
  subsection: {},
  answers: {},
  sync_info: {
    last_sync_time: null,
    last_sync_source_type: null,
    last_sync_type: null,
    sync_in_progress: false,
    synced_with_master_intake: null,
    master_intake_sync_obligation: null,
    sync_obligation: null
  },
  entryComments: {
    answer: {},
    comments: [],
    getCommentLoading: false,
    sendCommentLoading: false,
    deleteCommentLoading: false
  },
  form_questionnaire: null,
  field_name: null,
  iterationName: null,
  questionSet: null,
  loading: false,
  answersLoading: false,
  sendAnswersLoading: false,
  createDependentLoading: false
};

export const CustomerContentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CustomerActionTypes.SUBSECTION_CONTENT_LOADING:
      return {
        ...state,
        loading: payload
      };
    case CustomerActionTypes.SECTION_CONTENT:
      return {
        ...state,
        section: { ...payload }
      };
    case CustomerActionTypes.SUBSECTION_CONTENT:
      return {
        ...state,
        subsection: { ...payload }
      };
    case CustomerActionTypes.ANSWERS_CONTENT:
      return {
        ...state,
        answers: { ...payload }
      };
    case CustomerActionTypes.ANSWERS_CONTENT_LOADING:
      return {
        ...state,
        answersLoading: payload
      };
    case CustomerActionTypes.NEW_ANSWER_LOADING:
      return {
        ...state,
        sendAnswersLoading: payload
      };
    case CustomerActionTypes.SET_ENTRY_COMMENTS:
      return {
        ...state,
        entryComments: {
          ...state.entryComments,
          answer: payload.answer,
          comments: payload.comments
        }
      };
    case CustomerActionTypes.SECTION_SEE_COMMENTS_LOADING:
      return {
        ...state,
        entryComments: {
          ...state.entryComments,
          getCommentLoading: payload
        }
      };
    case CustomerActionTypes.SECTION_SAVE_COMMENTS_LOADING:
      return {
        ...state,
        entryComments: {
          ...state.entryComments,
          sendCommentLoading: payload
        }
      };
    case CustomerActionTypes.SECTION_DELETE_ANSWERS_LOADING:
      return {
        ...state,
        entryComments: {
          ...state.entryComments,
          deleteCommentLoading: payload
        }
      };
    case CustomerActionTypes.SET_FORM_QUESTIONNAIRE:
      return {
        ...state,
        form_questionnaire: payload.form_questions,
        field_name: payload.field_name,
        iterationName: payload.iterationName,
        questionSet: payload.questionSet
      };
    case CustomerActionTypes.SET_CREATE_DEPENDENT_LOADING:
      return {
        ...state,
        createDependentLoading: payload
      };
    case CustomerActionTypes.UPDATE_SYNC_SECTION_STATUS:
      return {
        ...state,
        sync_info: {
          ...state.sync_info,
          ...payload
        }
      };
    default:
      return state;
  }
};

export default CustomerContentReducer;
