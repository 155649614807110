import { HistoryLogCommentsThreadTypes } from '../types';

const initialState = {
  scrollY: 0,
  open: false,
  activeThreadTab: null,
  sectionsFilterKey: 'all',
  caseSections: [],
  historyLogs: {
    logs: [],
    count: 0,
    isLoading: false,
    isLoadingInfinite: false,
    logsActiveTab: 'all',
    page: 1,
    question: {
      title: null,
      isLoading: false
    }
  },
  comments: {
    tabs: [],
    comments: [],
    count: 0,
    page: 1,
    isLoading: false,
    isLoadingInfinite: false,
    commentsActiveTab: 'unresolved',
    deletedComment: {
      commentId: null,
      isLoading: false
    },
    sentComment: {
      answerId: null,
      isLoading: false
    }
  }
};

const HistoryLogCommentsThreadReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case HistoryLogCommentsThreadTypes.SET_OPEN:
      return {
        ...state,
        open: payload
      };
    case HistoryLogCommentsThreadTypes.SET_ACTIVE_THREAD_TAB:
      return {
        ...state,
        activeThreadTab: payload
      };
    case HistoryLogCommentsThreadTypes.SET_CASE_SECTIONS:
      return {
        ...state,
        caseSections: [...payload]
      };
    case HistoryLogCommentsThreadTypes.SET_SECTIONS_FILTER_KEY:
      return {
        ...state,
        sectionsFilterKey: payload
      };
    case HistoryLogCommentsThreadTypes.SET_LOGS_LOADING:
      return {
        ...state,
        historyLogs: {
          ...state.historyLogs,
          isLoading: payload
        }
      };
    case HistoryLogCommentsThreadTypes.SET_LOGS_LOADING_INFINITE:
      return {
        ...state,
        historyLogs: {
          ...state.historyLogs,
          isLoadingInfinite: payload
        }
      };
    case HistoryLogCommentsThreadTypes.SET_LOGS_VIEW_ACTIVE_TAB:
      return {
        ...state,
        historyLogs: {
          ...state.historyLogs,
          logsActiveTab: payload
        }
      };
    case HistoryLogCommentsThreadTypes.SET_LOGS:
      return {
        ...state,
        historyLogs: {
          ...state.historyLogs,
          logs: [...payload.logs],
          count: payload.count
        }
      };
    case HistoryLogCommentsThreadTypes.SET_LOGS_PAGE:
      return {
        ...state,
        historyLogs: {
          ...state.historyLogs,
          page: payload
        }
      };
    case HistoryLogCommentsThreadTypes.SET_SCROLL_Y:
      return {
        ...state,
        scrollY: payload
      };
    case HistoryLogCommentsThreadTypes.QUESTION_TITLE_LOADING:
      return {
        ...state,
        historyLogs: {
          ...state.historyLogs,
          question: {
            ...state.historyLogs.question,
            isLoading: payload
          }
        }
      };
    case HistoryLogCommentsThreadTypes.SET_QUESTION_TITLE:
      return {
        ...state,
        historyLogs: {
          ...state.historyLogs,
          question: {
            ...state.historyLogs.question,
            title: payload
          }
        }
      };
    case HistoryLogCommentsThreadTypes.SET_COMMENTS_LOADING:
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: payload
        }
      };
    case HistoryLogCommentsThreadTypes.SET_COMMENTS_LOADING_INFINITE:
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoadingInfinite: payload
        }
      };
    case HistoryLogCommentsThreadTypes.SET_COMMENTS:
      return {
        ...state,
        comments: {
          ...state.comments,
          comments: [...payload.comments],
          count: payload.count
        }
      };
    case HistoryLogCommentsThreadTypes.SET_COMMENTS_VIEW_ACTIVE_TAB:
      return {
        ...state,
        comments: {
          ...state.comments,
          commentsActiveTab: payload
        }
      };
    case HistoryLogCommentsThreadTypes.SET_COMMENTS_PAGE:
      return {
        ...state,
        comments: {
          ...state.comments,
          page: payload
        }
      };
    case HistoryLogCommentsThreadTypes.SET_COMMENTS_TABS:
      return {
        ...state,
        comments: {
          ...state.comments,
          tabs: [...payload]
        }
      };
    case HistoryLogCommentsThreadTypes.SET_DELETED_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          deletedComment: { isLoading: payload.loading, commentId: payload.id }
        }
      };
    case HistoryLogCommentsThreadTypes.SET_SENT_COMMENT_ANSWER_ID:
      return {
        ...state,
        comments: {
          ...state.comments,
          sentComment: {
            ...state.comments.sentComment,
            answerId: payload
          }
        }
      };
    case HistoryLogCommentsThreadTypes.SEND_COMMENT_LOADING:
      return {
        ...state,
        comments: {
          ...state.comments,
          sentComment: {
            ...state.comments.sentComment,
            isLoading: payload
          }
        }
      };
    default:
      return state;
  }
};

export default HistoryLogCommentsThreadReducer;
