import PropTypes from 'prop-types';
import { tableNoData } from 'components/Icons/Icons';

const TableNoData = ({ search }) => {
  return (
    <div className="no-data">
      {tableNoData}
      {search ? (
        <div className="search-not_found">
          <span>Haven't found what you were looking for?</span>
          <span>Try using different keywords or checking for typos.</span>
        </div>
      ) : (
        <span className="assign-text">No Data</span>
      )}
    </div>
  );
};

TableNoData.propTypes = {
  search: PropTypes.string
};

TableNoData.defaultProps = {
  search: ''
};

export default TableNoData;
