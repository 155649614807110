import { useLocation, Navigate, Outlet } from 'react-router-dom';

const ProtectedRoutes = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const location = useLocation();

  return user?.access ? <Outlet /> : <Navigate to="/sign-in" state={{ from: location }} replace />;
};

export default ProtectedRoutes;
