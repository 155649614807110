import { ImSpecCaseDashboardBetaService } from 'services';
import { ImSpecActionTypes } from '../types';
import { isFilterAnswersEmpty } from 'utils/helper';
import { ImSpecSectionDashboardActions } from './im_spec.section.dashboard.action';
import { Modal } from 'antd';
import history from 'utils/history';

const getCasesTableData = search => async (dispatch, getState) => {
  try {
    dispatch(tableDataLoading(true));
    const {
      ImSpecCaseDashboardBeta: {
        cases: { pageSize, page }
      }
    } = getState();

    const { data } = search
      ? await ImSpecCaseDashboardBetaService.getSearchCasesData(search, page, pageSize)
      : await ImSpecCaseDashboardBetaService.getCasesData(page, pageSize);
    const result = search
      ? {
          ...data[0],
          results: data[0].results.map((item, i) => ({
            ...item,
            key: i + 1
          }))
        }
      : {
          ...data,
          results: data.results.map((item, i) => ({ ...item, key: i + 1 }))
        };
    dispatch(setTableData(result));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(tableDataLoading(false));
  }
};

const onChangePage = (page, pageSize, search) => async (dispatch, getState) => {
  const {
    ImSpecCaseDashboardBeta: {
      cases: { pageSize: pageSizeFromState, page: pageFromState }
    }
  } = getState();

  const filterCases = localStorage.getItem('case-dashboard-filters');

  if (page !== pageFromState) dispatch(setPage(page));

  if (pageSize !== pageSizeFromState) {
    dispatch(setPage(1));
    dispatch(setPageSize(pageSize));
  }

  if (filterCases && !search) {
    dispatch(applyFilters());
  } else {
    dispatch(getCasesTableData(search));
  }
};

const setTableData = cases => {
  return {
    type: ImSpecActionTypes.SET_CASES,
    payload: { ...cases }
  };
};

const setPage = page => {
  return {
    type: ImSpecActionTypes.SET_PAGE,
    payload: page
  };
};

const setSearchQuery = query => {
  return {
    type: ImSpecActionTypes.SET_SEARCH_QUERY,
    payload: query
  };
};

const setSearchResult = result => {
  return {
    type: ImSpecActionTypes.SET_SEARCH_RESULT,
    payload: result
  };
};

const setPageSize = pageSize => {
  return {
    type: ImSpecActionTypes.SET_CASES_PAGE_SIZE,
    payload: pageSize
  };
};

const tableDataLoading = loading => {
  return {
    type: ImSpecActionTypes.CASES_LOADING,
    payload: loading
  };
};

const saveFilters =
  (form, setValidationResponse, setIsModalOpen, setActiveContentTab, setOpenDrawer) =>
  async (dispatch, getState) => {
    try {
      dispatch(setSaveFiltersLoading(true));

      const {
        ImSpecCaseDashboardBeta: { filterAnswers }
      } = getState();

      await ImSpecCaseDashboardBetaService.saveFilters({
        name: form.getFieldValue('view_name'),
        filter_params: { ...filterAnswers }
      });
      dispatch(getSavedFilters());
      setIsModalOpen(false);
      setOpenDrawer(true);
      form.resetFields();
      setActiveContentTab('2');
    } catch ({ data: { error } }) {
      setValidationResponse(error);
    } finally {
      dispatch(setSaveFiltersLoading(false));
    }
  };

const setSaveFiltersLoading = loading => {
  return {
    type: ImSpecActionTypes.SET_SAVE_FILTERS_LOADING,
    payload: loading
  };
};

const getSavedFilters = () => async dispatch => {
  try {
    const { data } = await ImSpecCaseDashboardBetaService.getSavedFilters();

    dispatch(setSavedViewsList(data));
  } catch (e) {
    console.warn(e);
  }
};

const setSavedViewsList = list => {
  return {
    type: ImSpecActionTypes.SET_SAVED_VIEW_LIST,
    payload: list
  };
};

const updateSavedFilter =
  (id, name, setSavedViewEditable, openNotification, filterAnswers) =>
  async (dispatch, getState) => {
    try {
      const {
        ImSpecCaseDashboardBeta: {
          appliedSavedView: { id: appliedId },
          savedViews: { list }
        }
      } = getState();

      const { data } = await ImSpecCaseDashboardBetaService.updateSavedFilter(
        id,
        name,
        filterAnswers
      );
      const newList = list.map(view => {
        if (view.id === data.id) return { ...data };
        return { ...view };
      });

      if (appliedId === id) {
        dispatch(setApplySavedViewName({ id: data.id, name: data.name }));
        localStorage.setItem(
          'case-dashboard-saved_view',
          JSON.stringify({ id: data.id, name: data.name })
        );
      }

      dispatch(setRenameSavedFilter(newList));
      setSavedViewEditable !== null && setSavedViewEditable({});
    } catch ({ data: { error } }) {
      openNotification(error);
    }
  };

const setRenameSavedFilter = view => {
  return {
    type: ImSpecActionTypes.RENAME_SAVED_VIEW_NAME,
    payload: view
  };
};

const deleteSavedFilters = id => async (dispatch, getState) => {
  try {
    const {
      ImSpecCaseDashboardBeta: {
        appliedSavedView: { id: appliedId }
      }
    } = getState();

    await ImSpecCaseDashboardBetaService.deleteSavedFilter(id);

    if (appliedId === id) {
      localStorage.removeItem('case-dashboard-saved_view');
      dispatch(clearFilters(null, false));
      dispatch(setApplySavedViewName({ id: null, name: '' }));
      dispatch(getCasesTableData());
    }
    dispatch(getSavedFilters());
  } catch (e) {
    console.warn(e);
  }
};

const clearFilters = (name, onlyFilterAnswers) => {
  !name && !onlyFilterAnswers && localStorage.removeItem('case-dashboard-filters');
  return {
    type: ImSpecActionTypes.CLEAR_FILTERS,
    payload: name
  };
};

const onSearch = search => async dispatch => {
  try {
    const { data } = await ImSpecCaseDashboardBetaService.onSearch(search);

    dispatch(setSearchResult({ ...data[0], hasData: !!data[0].results.length }));
  } catch (e) {
    console.warn(e);
  }
};

const applyFilters = (id, name, caseDashboardFilters) => async (dispatch, getState) => {
  try {
    dispatch(tableDataLoading(true));

    const {
      ImSpecCaseDashboardBeta: {
        filterAnswers,
        cases: { pageSize, page },
        appliedSavedView
      }
    } = getState();

    let filterAnswersWithID;

    // if save view has been set
    if (!isFilterAnswersEmpty(caseDashboardFilters)) {
      filterAnswersWithID = {
        ...caseDashboardFilters,
        attorney: {
          ...caseDashboardFilters.attorney,
          attorney_ids: caseDashboardFilters.attorney.attorney_ids?.map(attorney => attorney.id)
        },
        beneficiary: caseDashboardFilters.beneficiary?.map(beneficiary => beneficiary.id),
        case_type: caseDashboardFilters.case_type?.map(caseType => caseType.visa_name),
        im_spec: {
          ...caseDashboardFilters.im_spec,
          im_spec_ids: caseDashboardFilters.im_spec.im_spec_ids?.map(im_spec => im_spec.id)
        },
        petitioner: {
          ...caseDashboardFilters.petitioner,
          companies: caseDashboardFilters.petitioner.companies?.map(company => company.id)
        },
        case_id: caseDashboardFilters.case_id?.map(company => company.case_id)
      };

      dispatch(setAllFilters(caseDashboardFilters));
      dispatch(setApplySavedViewName({ id, name }));
      localStorage.setItem('case-dashboard-filters', JSON.stringify(caseDashboardFilters));
      localStorage.setItem('case-dashboard-saved_view', JSON.stringify({ id, name }));
    }
    // if apply filters has been set
    else {
      filterAnswersWithID = {
        ...filterAnswers,
        attorney: {
          ...filterAnswers.attorney,
          attorney_ids: filterAnswers.attorney.attorney_ids?.map(attorney => attorney.id)
        },
        beneficiary: filterAnswers.beneficiary?.map(beneficiary => beneficiary.id),
        case_type: filterAnswers.case_type?.map(caseType => caseType.visa_name),
        im_spec: {
          ...filterAnswers.im_spec,
          im_spec_ids: filterAnswers.im_spec.im_spec_ids?.map(im_spec => im_spec.id)
        },
        petitioner: {
          ...filterAnswers.petitioner,
          companies: filterAnswers.petitioner.companies?.map(company => company.id)
        },
        case_id: filterAnswers.case_id?.map(company => company.case_id)
      };
      localStorage.setItem('case-dashboard-filters', JSON.stringify(filterAnswers));
      localStorage.setItem('case-dashboard-saved_view', JSON.stringify(appliedSavedView));
    }

    const { data } = await ImSpecCaseDashboardBetaService.applyFilters(
      filterAnswersWithID,
      page,
      pageSize
    );

    const result = {
      ...data,
      results: data.results.map((item, i) => ({ ...item, key: i + 1 }))
    };

    history.push(history.location.pathname);

    dispatch(setTableData(result));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(tableDataLoading(false));
  }
};

const setApplySavedViewName = data => {
  return {
    type: ImSpecActionTypes.SET_SAVED_VIEW_NAME,
    payload: data
  };
};

const setAllFilters = data => {
  return {
    type: ImSpecActionTypes.ADD_ALL_FILTERS,
    payload: data
  };
};

const onSubmitAssignCase = (caseID, data, navigate, setDisabled) => async dispatch => {
  try {
    await dispatch(caseSpecialistsLoading(true));
    const res = await ImSpecCaseDashboardBetaService.handleCaseAssign(caseID, data);

    setDisabled(true);
    navigate(-1);

    await dispatch(caseSpecialistsLoading(false));

    const refreshPage = async () => {
      await dispatch(getCasesTableData());
      await dispatch(ImSpecSectionDashboardActions.getCaseList(caseID));
    };

    Modal.info({
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal congrats',
      okButtonProps: {
        className: 'submit-section-confirm-modal-submit_btn',
        loading: false
      },
      okText: 'Close',
      content: (
        <>
          <span className="submit-section-confirm-modal-content">Success!</span>
          <span
            className="submit-section-confirm-modal-title"
            dangerouslySetInnerHTML={{ __html: res.data.message }}
          />
        </>
      ),
      onOk: () => refreshPage()
    });
  } catch (e) {
    console.warn(e);
    await dispatch(caseSpecialistsLoading(false));
    navigate(-1);
  }
};

const caseSpecialistsLoading = loading => {
  return {
    type: ImSpecActionTypes.CASE_SPECIALISTS_LOADING,
    payload: loading
  };
};

const getDrawerData = () => async dispatch => {
  try {
    dispatch(getDrawerDataLoading(true));

    dispatch(getCaseTypes());
    dispatch(getSavedFilters());
    dispatch(getStatesStatusesSource());
    dispatch(getImSpecs());
    dispatch(getAttorneys());
    dispatch(getPetitioners());
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(getDrawerDataLoading(false));
  }
};

const getCaseTypes = () => async dispatch => {
  try {
    const { data } = await ImSpecCaseDashboardBetaService.getCaseTypes();

    dispatch(setCasesTypesList(data));
  } catch (e) {
    console.warn(e);
  }
};

const getStatesStatusesSource = () => async dispatch => {
  try {
    const {
      data: { source, states, statuses }
    } = await ImSpecCaseDashboardBetaService.getStatesStatusesSource();

    dispatch(setSourceData(source));
    dispatch(setStatesData(states));
    dispatch(setStatusesData(statuses));
  } catch (e) {
    console.warn(e);
  }
};

const getImSpecs = () => async dispatch => {
  try {
    const {
      data: { immigration_specialists }
    } = await ImSpecCaseDashboardBetaService.getImSpecs();

    dispatch(setImSpecs(immigration_specialists));
  } catch (e) {
    console.warn(e);
  }
};

const getAttorneys = () => async dispatch => {
  try {
    const {
      data: { immigration_attorneys }
    } = await ImSpecCaseDashboardBetaService.getAttorneys();

    dispatch(setAttorneys(immigration_attorneys));
  } catch (e) {
    console.warn(e);
  }
};

const getPetitioners = () => async dispatch => {
  try {
    const { data } = await ImSpecCaseDashboardBetaService.getPetitioners();

    dispatch(setPetitioners(data));
  } catch (e) {
    console.warn(e);
  }
};

const getBeneficiary = search_word => async dispatch => {
  try {
    dispatch(setBeneficiaryLoading(true));
    const { data } = await ImSpecCaseDashboardBetaService.getBeneficiary(search_word);

    dispatch(setBeneficiary(data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(setBeneficiaryLoading(false));
  }
};

const getCaseID = search_word => async dispatch => {
  try {
    dispatch(setCaseIDLoading(true));

    const { data } = await ImSpecCaseDashboardBetaService.getCaseID(search_word);

    dispatch(setCaseID(data.results));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(setCaseIDLoading(false));
  }
};

const getDrawerDataLoading = loading => {
  return {
    type: ImSpecActionTypes.DRAWER_LOADING,
    payload: loading
  };
};

const setCasesTypesList = list => {
  return {
    type: ImSpecActionTypes.SET_CASE_TYPES,
    payload: list
  };
};

const setStatesData = states => {
  return {
    type: ImSpecActionTypes.SET_STATES_DATA,
    payload: states
  };
};

const setPetitioners = immigration_attorneys => {
  return {
    type: ImSpecActionTypes.SET_PETITIONERS_DATA,
    payload: immigration_attorneys
  };
};

const setStatusesData = statuses => {
  return {
    type: ImSpecActionTypes.SET_STATUSES_DATA,
    payload: statuses
  };
};

const setBeneficiary = data => {
  return {
    type: ImSpecActionTypes.SET_BENEFICIARY_DATA,
    payload: data
  };
};
const setBeneficiaryLoading = loading => {
  return {
    type: ImSpecActionTypes.BENEFICIARY_LOADING,
    payload: loading
  };
};

const setImSpecs = immigration_specialists => {
  return {
    type: ImSpecActionTypes.SET_IM_SPEC_DATA,
    payload: immigration_specialists
  };
};

const setAttorneys = immigration_attorneys => {
  return {
    type: ImSpecActionTypes.SET_ATTORNEY_DATA,
    payload: immigration_attorneys
  };
};

const setCaseID = data => {
  return {
    type: ImSpecActionTypes.SET_CASE_ID_DATA,
    payload: data
  };
};

const setCaseIDLoading = loading => {
  return {
    type: ImSpecActionTypes.CASE_ID_LOADING,
    payload: loading
  };
};

const setSourceData = source => {
  return {
    type: ImSpecActionTypes.SET_SOURCE_DATA,
    payload: source
  };
};

const setSelectedCaseType = selectedList => {
  return {
    type: ImSpecActionTypes.SET_SELECTED_CASE_TYPES,
    payload: selectedList
  };
};
const setSelectedState = selectedList => {
  return {
    type: ImSpecActionTypes.SET_SELECTED_STATES_DATA,
    payload: selectedList
  };
};

const setLastUpdate = range => {
  return {
    type: ImSpecActionTypes.SET_LAST_UPDATE,
    payload: range
  };
};

const setPetitioner = data => {
  return {
    type: ImSpecActionTypes.SET_SELECTED_PETITIONERS_DATA,
    payload: data
  };
};

const setStatus = list => {
  return {
    type: ImSpecActionTypes.SET_SELECTED_STATUSES_DATA,
    payload: list
  };
};

const setSelectedBeneficiary = list => {
  return {
    type: ImSpecActionTypes.SET_SELECTED_BENEFICIARY_DATA,
    payload: list
  };
};

const setSelectedImSpec = data => {
  return {
    type: ImSpecActionTypes.SET_SELECTED_IM_SPEC_DATA,
    payload: data
  };
};

const setSelectedAttorney = data => {
  return {
    type: ImSpecActionTypes.SET_SELECTED_ATTORNEY_DATA,
    payload: data
  };
};

const setSelectedCaseID = list => {
  return {
    type: ImSpecActionTypes.SET_SELECTED_CASE_ID_DATA,
    payload: list
  };
};

const setSelectedSource = list => {
  return {
    type: ImSpecActionTypes.SET_SELECTED_SOURCE_DATA,
    payload: list
  };
};

const setIncludeTestCases = checked => {
  return {
    type: ImSpecActionTypes.SET_INCLUDE_TEST_CASES,
    payload: checked
  };
};

export const ImSpecCaseDashboardBetaAction = {
  getCasesTableData,
  onChangePage,
  getSavedFilters,
  saveFilters,
  setPage,
  setPageSize,
  setSearchResult,
  setSearchQuery,
  onSearch,
  setApplySavedViewName,
  updateSavedFilter,
  deleteSavedFilters,
  applyFilters,
  clearFilters,
  setAllFilters,
  onSubmitAssignCase,
  getDrawerData,
  getBeneficiary,
  getCaseID,
  setSelectedCaseType,
  setSelectedState,
  setLastUpdate,
  setPetitioner,
  setStatus,
  setSelectedBeneficiary,
  setSelectedImSpec,
  setSelectedAttorney,
  setSelectedCaseID,
  setSelectedSource,
  setIncludeTestCases
};
