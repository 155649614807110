import PropTypes from 'prop-types';
import Icon, { UserOutlined, FileSearchOutlined } from '@ant-design/icons';

import { petitionerDashboard } from 'components/Icons/Icons';

const icons = {
  clientManagement: UserOutlined,
  caseDashboard: FileSearchOutlined,
  petitionerDashboard,
  accountProfile: UserOutlined
};

const SidebarTabIcons = ({ name, ...props }) => {
  const IconComponent = icons[name];
  return <Icon component={IconComponent} {...props} />;
};

SidebarTabIcons.propTypes = {
  name: PropTypes.string
};

SidebarTabIcons.defaultProps = {
  name: ''
};

export default SidebarTabIcons;
