import PropTypes from 'prop-types';
import { tableNoData } from 'components/Icons/Icons';
import useQuery from 'hooks/useQuery';

const TableNoData = ({ assign, role }) => {
  const { search } = useQuery();

  const assignText =
    assign === 'assigned_to_others' && (role === 'IS' || role === 'CS')
      ? 'There are no cases assigned to other Immigration Specialists.'
      : assign === 'assigned_to_others' && role === 'Attorney'
      ? 'There are no cases assigned to other Immigration Attorney.'
      : assign === 'all'
      ? 'There are no cases currently.'
      : assign === 'unassigned'
      ? 'There are no unassigned cases.'
      : assign === 'assigned_to_me'
      ? 'There are no cases assigned to you.'
      : null;

  return (
    <div className="no-data">
      {tableNoData}
      {search ? (
        <div className="search-not_found">
          <span>Haven't found what you were looking for?</span>
          <span>Try using different keywords or checking for typos.</span>
        </div>
      ) : (
        <span className="assign-text">{assignText}</span>
      )}
    </div>
  );
};

TableNoData.propTypes = {
  assign: PropTypes.string,
  role: PropTypes.string
};

TableNoData.defaultProps = {
  assign: '',
  role: ''
};

export default TableNoData;
