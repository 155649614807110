import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { CustomerContentAction, ImSpecContentAction } from 'store/Actions';

import { sectionAndSubsectionNames } from 'utils/helper';
import { uploadIcon } from 'components/Icons/Icons';

import { Button, Modal, Upload } from 'antd';

const SingleUpload = ({
  curGroup,
  curSubsection,
  realSubsection,
  answersDataState,
  answers,
  curField,
  iterationName,
  questionSet,
  radioDisable,
  UploadListItemRender,
  onPreviewUpload,
  setUploadLoading,
  setUploadError,
  uploadLoading,
  sync_info
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    user: { role }
  } = useSelector(state => state.auth);

  const { masterIntakeID, petitionerName, caseID, sectionTitle, subSectionTitle } = useParams();

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const listener = () => setUploadError([]);
    window.addEventListener('click', listener);

    return () => window.removeEventListener('click', listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const answersState = !!iterationName
      ? !questionSet.startsWith('uId') && answers[iterationName]?.field_value[questionSet]
      : answers;

    if (!!answersState && Object.keys(answersState).length) {
      answersState[curField.name] && setFileList([...answersState[curField.name].existing_files]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionSet, answers, curField]);

  const customUploadRequest = async ({ file }) => {
    await setUploadLoading(true);

    const allFilesTypeIsValid =
      /([a-zA-Z0-9\s_\\.\-\(\):])+(.png|.jpg|.jpeg|.xlsx|.csv|.doc|.vnd.openxmlformats-officedocument.wordprocessingml.document|.msword|.pdf|.vnd.openxmlformats-officedocument.spreadsheetml.sheet|.vnd.ms-excel|.x-csv)$/.test(
        file.type
      );
    const isFileSize10M = file.size / 1024 / 1024 < 11;

    if (!allFilesTypeIsValid) {
      setUploadError(prevErrors => {
        let newState;
        if (prevErrors.some(({ id }) => id === curField.id)) {
          newState = prevErrors.map(error =>
            error.name === curField.name
              ? {
                  ...error,
                  message: 'Format of one or more files is not supported.'
                }
              : { ...error }
          );
        } else {
          newState = [
            ...prevErrors,
            {
              id: curField.id,
              name: curField.name,
              message: 'Format of one or more files is not supported.'
            }
          ];
        }

        return newState;
      });
      await setUploadLoading(false);
      return;
    }

    if (!isFileSize10M) {
      setUploadError(prevErrors => {
        let newState;
        if (prevErrors.some(({ id }) => id === curField.id)) {
          newState = prevErrors.map(error =>
            error.name === curField.name
              ? {
                  ...error,
                  message: 'Max file size supported is 10 MB.'
                }
              : { ...error }
          );
        } else {
          newState = [
            ...prevErrors,
            {
              id: curField.id,
              name: curField.name,
              message: 'Max file size supported is 10 MB.'
            }
          ];
        }

        return newState;
      });
      setUploadLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('field_file', file);
    formData.append('field_name', curField.name);
    formData.append('field_value', '');
    formData.append('field_type', curField.type.type);
    formData.append('group', curGroup.title ?? null);
    formData.append(
      'subsection',
      !!subSectionTitle
        ? sectionAndSubsectionNames(subSectionTitle)
        : realSubsection.title?.split('?')[0]
    );
    formData.append('group_name', !!iterationName ? iterationName : '');
    questionSet &&
      !questionSet.startsWith('uId') &&
      formData.append(
        'iteration_key',
        !!answers[iterationName]?.field_value || !questionSet.startsWith('uId')
          ? answers[iterationName]?.field_value[questionSet][curField.name]?.iteration_key
          : null
      );

    if (role === 'customer') {
      dispatch(
        CustomerContentAction.sendFieldAnswer({
          sectionTitle,
          newAnswer: formData,
          caseID,
          setUploadLoading,
          setUploadError,
          redirect: navigate,
          location,
          masterIntakeID,
          petitionerName
        })
      );
    } else {
      dispatch(
        ImSpecContentAction.saveAnswer({
          sectionTitle,
          newAnswer: formData,
          caseID,
          setUploadLoading,
          setUploadError,
          redirect: navigate,
          location,
          masterIntakeID,
          petitionerName
        })
      );
    }
  };

  const onRemoveUpload = async file => {
    const fileID = file.id;
    const answerID = answersDataState[curField.name].id;

    Modal.confirm({
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal delete-upload',
      cancelButtonProps: {
        className: 'submit-section-confirm-modal-cancel_btn'
      },
      okButtonProps: {
        className: 'submit-section-confirm-modal-delete_btn'
      },
      okText: 'Delete',
      content: (
        <>
          <span className="submit-section-confirm-modal-content" style={{ padding: '10px 0' }}>
            Delete Uploaded File?
          </span>
          <hr />
          <span className="submit-section-confirm-modal-title" style={{ width: '90%' }}>
            Are you sure you want to delete <b>“{file.original_name}”</b>?
          </span>
          {role !== 'customer' && (
            <span
              className="submit-section-confirm-modal-title"
              style={{ width: '90%', marginTop: '15px' }}
            >
              Deleted files will no longer be available to client. However, you will always be able
              to find them in the history log.
            </span>
          )}
        </>
      ),
      onCancel: () => setUploadLoading(false),
      onOk: () => {
        setUploadLoading(true);
        if (role === 'customer') {
          dispatch(
            CustomerContentAction.deleteFile(fileID, answerID, setUploadLoading, masterIntakeID)
          );
        } else {
          dispatch(
            ImSpecContentAction.deleteFile(fileID, answerID, setUploadLoading, masterIntakeID)
          );
        }
      }
    });
  };

  return (
    <div>
      <Upload
        id={curField?.id}
        className="upload-container"
        customRequest={customUploadRequest}
        fileList={fileList}
        maxCount={1}
        accept=".png, .jpg, .jpeg, .xlsx, .csv, .docx, .pdf, .doc"
        itemRender={UploadListItemRender}
        onPreview={onPreviewUpload}
        onRemove={onRemoveUpload}
        disabled={
          sync_info.sync_in_progress ||
          curSubsection?.is_blocked ||
          radioDisable ||
          uploadLoading ||
          fileList.length !== 0
        }
      >
        <Button
          disabled={
            sync_info.sync_in_progress ||
            curSubsection?.is_blocked ||
            radioDisable ||
            uploadLoading ||
            fileList.length !== 0
          }
          loading={uploadLoading}
          className="upload-btn"
          icon={uploadIcon}
        >
          Upload {`${curField?.placeholder}${curField.is_required ? '*' : ''}`}
        </Button>
      </Upload>
    </div>
  );
};

SingleUpload.propTypes = {
  curGroup: PropTypes.object,
  realSubsection: PropTypes.object,
  answersDataState: PropTypes.object,
  UploadListItemRender: PropTypes.func,
  onPreviewUpload: PropTypes.func,
  setUploadLoading: PropTypes.func,
  setUploadError: PropTypes.func,
  uploadLoading: PropTypes.bool,
  curSubsection: PropTypes.object,
  answers: PropTypes.object,
  curField: PropTypes.object,
  iterationName: PropTypes.string,
  questionSet: PropTypes.string,
  radioDisable: PropTypes.bool,
  inputRef: PropTypes.object,
  sync_info: PropTypes.object
};

SingleUpload.defaultProps = {
  curGroup: {},
  answersDataState: {},
  realSubsection: {},
  uploadLoading: {},
  curSubsection: {},
  answers: {},
  curField: {},
  iterationName: '',
  questionSet: '',
  radioDisable: false,
  inputRef: {},
  sync_info: {}
};

export default SingleUpload;
