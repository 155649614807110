import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';

import crypto from 'crypto-browserify';

const ReferAFriend = () => {
  const { user } = useSelector(state => state.auth);

  const getAuthInfo = useCallback(async () => {
    const hash = crypto
      .createHmac('sha256', 'afd04b0682b2bcb475f1b3b2e40a653b5bddd7e6')
      .update(user.email)
      .digest('hex');
    return {
      email: user.email,
      hash
    };
  }, [user.email]);

  const autoAuthParticipant = useCallback(async () => {
    const authInfo = await getAuthInfo();

    await window.growsurf.init(authInfo);
  }, [getAuthInfo]);

  const handleGrowSurfReady = useCallback(async () => {
    // remove our listener
    window.removeEventListener('grsfReady', handleGrowSurfReady, false);
    // auto authenticate the participant
    await autoAuthParticipant();
  }, [autoAuthParticipant]);

  useEffect(() => {
    if (!window.growsurf) {
      // Listen and wait for the Growsurf Universal Code to initialize
      window.addEventListener('grsfReady', handleGrowSurfReady);
    } else {
      // Authorize authenticate your participant (Optional)
      handleGrowSurfReady();
    }
  }, [handleGrowSurfReady]);

  return (
    <Col className="dashboard">
      <div className="referral-block">
        <h1 className="referral-block-title">Share The Love:</h1>
        <h1 className="referral-block-title">Refer A Friend, Earn $250</h1>
        <p className="referral-block-paragraph">
          Earn $250 for every referral that uses your unique sign-up link and becomes a customer.
          And your friend gets a $250 dollar credit for our top-notch immigration services!. Plus,
          for every referral you will earn an extra entry to win a $1,000 Delta Airlines Gift Card.
          So what are you waiting for?
        </p>
      </div>
      <div style={{ margin: '50px auto', maxWidth: '450px' }}>
        <div data-grsf-block-form />
      </div>
    </Col>
  );
};

export default ReferAFriend;
