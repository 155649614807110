import { ImSpecActionTypes } from 'store/types';

const initialState = {
  case: {
    list: [],
    child_cases: [],
    isLoading: false
  },
  associated_accounts: {
    list: [],
    isLoading: false
  },
  contacts: {
    im_spec: null,
    attorney: null,
    beneficiary: null,
    petitioner: null,
    parent_breadcrumbs: null,
    visa_name: null,
    is_dependent: false,
    dependent: null
  },
  searchedUsers: {
    list: [],
    isLoading: false,
    hasData: null
  }
};

const ImSpecSectionDashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ImSpecActionTypes.CASE_LIST_LOADING:
      return {
        ...state,
        case: {
          ...state.case,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_CASE_LIST:
      return {
        ...state,
        case: {
          ...state.case,
          list: [...payload]
        }
      };
    case ImSpecActionTypes.ASSOCIATED_ACCOUNTS_LOADING:
      return {
        ...state,
        associated_accounts: {
          ...state.associated_accounts,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_ASSOCIATED_ACCOUNTS:
      return {
        ...state,
        associated_accounts: {
          ...state.associated_accounts,
          list: [...payload]
        }
      };
    case ImSpecActionTypes.SET_BENEFICIARY:
      return {
        ...state,
        beneficiary: { ...payload }
      };
    case ImSpecActionTypes.SET_SEARCH_USERS_LOADING:
      return {
        ...state,
        searchedUsers: {
          ...state.searchedUsers,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_SEARCH_USERS:
      return {
        ...state,
        searchedUsers: {
          ...state.searchedUsers,
          list: [...payload.users],
          hasData: payload.hasData
        }
      };
    case ImSpecActionTypes.SET_IM_SPEC_CONTACTS:
      return {
        ...state,
        contacts: { ...payload }
      };
    case ImSpecActionTypes.SET_SECTION_DASHBOARD_CHILD_CASES:
      return {
        ...state,
        case: {
          ...state.case,
          child_cases: [...payload]
        }
      };
    default:
      return state;
  }
};

export default ImSpecSectionDashboardReducer;
