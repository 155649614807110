import { useState } from 'react';
import PropTypes from 'prop-types';

import FloatLabelInput from 'components/shared/FloatLabelInput';

import { Col, Row } from 'antd';

const Profile = ({ userInfo }) => {
  // eslint-disable-next-line
  const [isEditable, setIsEditable] = useState(false);

  return (
    <Col className="profile-page">
      <Row className="profile-page_header" type="flex" justify="space-between" align="middle">
        <h2 className="title"> Profile </h2>
        {/*todo uncomment when update is added*/}
        {/*{*/}
        {/*  isEditable ?*/}
        {/*    <Row>*/}
        {/*      <Button className="primary-btn cancel" onClick={() => setIsEditable(false)}> Cancel </Button>*/}
        {/*      <Button className="primary-btn"> Save </Button>*/}
        {/*    </Row>*/}
        {/*    :*/}
        {/*    <Button className="primary-btn" onClick={() => setIsEditable(true)}> Update Profile </Button>*/}
        {/*}*/}
      </Row>
      <Col className={`profile-page_content isEditable_${isEditable}`}>
        {Object.entries(userInfo).map(([key, value], index) => {
          return (
            <FloatLabelInput
              disabled={!isEditable}
              key={index}
              id={index}
              name="key"
              label={key.replaceAll('_', ' ')}
              value={value}
            />
          );
        })}
      </Col>
    </Col>
  );
};

Profile.propTypes = {
  userInfo: PropTypes.object
};

Profile.defaultProps = {
  userInfo: {}
};

export default Profile;
