import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import CheckboxList from 'components/shared/CheckboxList';

import { Form } from 'antd';

const State = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const selectedList = Form.useWatch('state_options', form);
  Form.useWatch('state_options_all', form);

  const {
    filters: {
      state: { list }
    },
    filterAnswers: { state }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    if (typeof selectedList !== 'undefined') {
      dispatch(ImSpecCaseDashboardBetaAction.setSelectedState(selectedList));
    }
  }, [dispatch, selectedList]);

  useEffect(() => {
    state && !state.length && form.resetFields();
  }, [state, form]);

  return (
    <>
      <CheckboxList
        form={form}
        plainOptions={list}
        filterOption="state_options"
        checkedValues={state}
      />
    </>
  );
};

export default State;
