import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import useQuery from 'hooks/useQuery';

import { ImSpecPetitionerDashboardAction } from 'store/Actions';

// components
import PetitionerDashboardNestedTable from 'pages/ImSpecPages/PetitionerDashboard/elements/PetitionerDashboardNestedTable';
import petitionsTableColumns from 'pages/ImSpecPages/PetitionerDashboard/elements/tableColumns';
import TableNoData from 'pages/ImSpecPages/ClientManagement/elements/TableNoData';
import SelectComponentClass from 'components/shared/SelectComponentClass';

import { Button, Col, Table } from 'antd';
import { DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';

const PetitionerDashboardTable = ({
  entryPetitionerCases,
  petitioners: { list, isLoading, page, count, pageSize, pageSizeOptions }
}) => {
  const dispatch = useDispatch();
  const { search } = useQuery();

  const [expandedRowKeys, setExpandedRowKeys] = useState();

  const columns = petitionsTableColumns();

  const onChangePage = useCallback(
    (page, pageSize) => {
      dispatch(ImSpecPetitionerDashboardAction.onChangePage(page, pageSize, search));
    },
    [dispatch, search]
  );

  const expandedRowRender = () => (
    <PetitionerDashboardNestedTable entryPetitionerCases={entryPetitionerCases} />
  );

  const onExpand = (expanded, record) => {
    setExpandedRowKeys(expanded ? [record.key] : []);
    dispatch(ImSpecPetitionerDashboardAction.getEntryPetitionerCases(record.key));
  };

  const pagination = useMemo(
    () => ({
      className: 'lp-pagination',
      defaultCurrent: 1,
      defaultPageSize: 100,
      hideOnSinglePage: true,
      total: count,
      current: page,
      size: 'small',
      pageSize: pageSize,
      pageSizeOptions: pageSizeOptions,
      showSizeChanger: count > pageSize,
      showQuickJumper: false,
      position: ['bottomCenter'],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      onChange: onChangePage,
      selectComponentClass: SelectComponentClass
    }),
    [count, onChangePage, page, pageSize, pageSizeOptions]
  );

  return (
    <Col style={{ height: '100%' }}>
      <Table
        className="status-table"
        scroll={{ y: '100%' }}
        columns={columns}
        dataSource={list}
        tableLayout="fixed"
        locale={{ emptyText: <TableNoData search={search} /> }}
        loading={{
          spinning: isLoading,
          indicator: <LoadingOutlined style={{ fontSize: 30 }} />
        }}
        expandable={{
          onExpand,
          expandIcon: ({ expanded, onExpand, record }) => (
            <Button
              className="expandIcon"
              onClick={e => onExpand(record, e)}
              icon={expanded ? <UpOutlined /> : <DownOutlined />}
            />
          ),
          expandedRowRender,
          expandedRowKeys
        }}
        pagination={pagination}
      />
    </Col>
  );
};

PetitionerDashboardTable.propTypes = {
  entryPetitionerCases: PropTypes.object,
  petitioners: PropTypes.shape({
    list: PropTypes.array,
    isLoading: PropTypes.bool,
    page: PropTypes.number,
    count: PropTypes.number,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.array
  })
};

PetitionerDashboardTable.defaultProps = {
  entryPetitionerCases: {},
  petitioners: {}
};

export default PetitionerDashboardTable;
