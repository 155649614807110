import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';

const nestedTableColumns = ({ flagsReady, hideChildCases }) => {
  return [
    {
      title: 'Case ID',
      dataIndex: 'case_id',
      key: 'case_id',
      width: '10%',
      ellipsis: true,
      render: (case_id, { beneficiary, is_migrated, dependent_cases_count }) => (
        <div className="case_col">
          <Link
            className="table-hrefs"
            to={`/client-management/userID=${beneficiary.id}/${case_id}`}
          >
            {case_id}
            {flagsReady && !hideChildCases && !!dependent_cases_count && (
              <Tooltip
                overlayClassName="migrate-tooltip"
                title={`${dependent_cases_count} Child Cases`}
                placement="top"
              >
                <div className="migrate">
                  <span>{dependent_cases_count}</span>
                </div>
              </Tooltip>
            )}
          </Link>
          {is_migrated && (
            <Tooltip overlayClassName="migrate-tooltip" title="Migrated" placement="bottom">
              <div className="migrate">
                <span>M</span>
              </div>
            </Tooltip>
          )}
        </div>
      )
    },
    {
      title: 'Case',
      dataIndex: 'visa_name',
      key: 'visa_name',
      width: '10%',
      ellipsis: true,
      render: (visa_name, { is_dependent }) => (
        <div className={`visa_name v${visa_name.replaceAll(' ', '-')}`}>
          {visa_name}{' '}
          {visa_name === 'I-539' && is_dependent
            ? 'DEP'
            : visa_name === 'I-539' && !is_dependent
            ? 'BEN'
            : null}
        </div>
      )
    },
    {
      title: 'Beneficiary',
      dataIndex: 'beneficiary',
      key: 'beneficiary',
      width: '25%',
      ellipsis: true,
      render: beneficiary => (
        <div className="beneficiary">
          <Link className="table-hrefs" to={`/client-management/userID=${beneficiary.id}`}>
            {beneficiary.first_name} {beneficiary.last_name}
          </Link>
          {beneficiary.is_test && (
            <div className="test-account-tag">
              <div className="circle" />
              <span>Test account</span>
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Case state',
      dataIndex: 'state',
      key: 'state',
      width: '12%',
      ellipsis: true,
      render: state => <span className={`state ${state}`}>{state}</span>
    },
    {
      title: 'Case status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      ellipsis: true,
      render: status => (
        <div className={`section-status ${status.replaceAll(' ', '-')}`}>
          <span className="table-items">{status}</span>
        </div>
      )
    },
    {
      title: 'Last update',
      dataIndex: 'last_updated_at',
      key: 'last_updated_at',
      width: '15%',
      ellipsis: true,
      render: state_last_updated_at => (
        <span className="last-update">
          {moment(state_last_updated_at).format('MM.DD.YYYY')}
          <span className="hours">{moment(state_last_updated_at).format('hh:mm A')}</span>
        </span>
      )
    }
  ];
};

export default nestedTableColumns;
