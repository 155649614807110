import { useMatch } from 'react-router-dom';

// components
import Dashboard from 'pages/CustomerPages/Dashboard';
import Perks from 'pages/CustomerPages/Perks';
import Subsection from 'pages/CustomerPages/Subsection';
import SectionReview from 'pages/CustomerPages/SectionReview';
import ClientPage from 'pages/CustomerPages/ClientPage';
import ReferAFriend from 'pages/CustomerPages/ReferAFriend';
import Account from 'pages/CustomerPages/Account';

import HistoryLogCommentsThread from 'components/HistoryLogCommentsThread';

import { Layout } from 'antd';

import './index.scss';

const { Content } = Layout;

const CustomerPages = () => {
  const dashboard = useMatch('/dashboard');
  const perks = useMatch('/perks');
  const clientPage = useMatch('/your-questionnaires');
  const section = useMatch('/your-questionnaires/:caseID/:sectionTitle/:subSectionTitle');
  const sectionReview = useMatch('/your-questionnaires/:caseID/:sectionTitle/review');
  const masterIntake = useMatch('/master-intake/:masterIntakeID/:petitionerName/:subSectionTitle');
  const masterIntakeReview = useMatch('/master-intake/:masterIntakeID/:petitionerName/review');
  const referAFriend = useMatch('/refer-a-friend');
  const profile = useMatch('/account-profile');

  return (
    <Content style={{ height: '100%' }}>
      {dashboard && <Dashboard />}
      {perks && <Perks />}
      {referAFriend && <ReferAFriend />}
      {clientPage && <ClientPage />}
      {section && !section.pathname.endsWith('/review') && <Subsection />}
      {masterIntake && !masterIntake.pathname.endsWith('/review') && <Subsection />}
      {sectionReview && sectionReview.pathname.endsWith('/review') && <SectionReview />}
      {masterIntakeReview && masterIntakeReview.pathname.endsWith('/review') && <SectionReview />}
      {profile && <Account />}
      {(masterIntake && !masterIntake.pathname.endsWith('/review')) || section ? (
        <HistoryLogCommentsThread />
      ) : null}
    </Content>
  );
};

export default CustomerPages;
