import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerClientPageActions, ImSpecClientPageActions } from 'store/Actions';

import useQuery from 'hooks/useQuery';

import { Button, Col, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { sendIcon } from 'components/Icons/Icons';

// components
import AssociatedSection from 'components/ClientPage/AssociatedSection';
import BeneficiarySection from 'components/ClientPage/BeneficiarySection';
import ChangeNameModal from 'pages/ImSpecPages/ClientPage/elements/ChangeNameModal';
import ResendInvitationSummaryModal from 'pages/ImSpecPages/ClientPage/elements/ResendInvitationSummaryModal';
import CompanySection from 'components/ClientPage/CompanySection';

const ClientPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userID } = useParams();
  const { caseID, questionnaire, petitionerID } = useQuery();
  const scrollRef = useRef();

  const {
    ImSpecClientPage: {
      company: { companiesList, isLoading: companiesListLoading },
      companyCases: { companyCasesList, isLoading: companyCasesListLoading },
      userDetail: { userDetails, isLoading: userDetailsLoading },
      beneficiaryCases: { beneficiaryList },
      associatedCases: { associatedList },
      case: { list, child_cases, isLoading: casesLoading },
      resendItems
    },
    auth: {
      user: { role }
    }
  } = useSelector(state => ({
    ImSpecClientPage: state.ImSpecClientPage,
    auth: state.auth
  }));

  const [curActiveCase, setActiveCurCase] = useState([]);
  const [curActiveCompany, setActiveCurCompany] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const getCaseTypesData = async () => {
      await dispatch(ImSpecClientPageActions.getBeneficiaryTypes(userID, navigate));
      await dispatch(ImSpecClientPageActions.getAssociatedTypes(userID, navigate));
      await dispatch(ImSpecClientPageActions.getCompanies(userID));
    };
    getCaseTypesData();
  }, [dispatch, navigate, userID]);

  useEffect(() => {
    if (questionnaire && caseID) {
      dispatch(ImSpecClientPageActions.getAssociatedCaseList(caseID, userID, navigate));
    } else if (!questionnaire && caseID) {
      dispatch(ImSpecClientPageActions.getCaseList(caseID, navigate));
    }
  }, [dispatch, caseID, navigate, questionnaire, userID]);

  useEffect(() => {
    petitionerID && dispatch(CustomerClientPageActions.getCompanyCases(petitionerID));
  }, [dispatch, petitionerID, navigate]);

  useEffect(() => {
    petitionerID && setActiveCurCompany([petitionerID]);
  }, [petitionerID]);

  useEffect(() => {
    caseID && setActiveCurCase([caseID]);
  }, [caseID]);

  useEffect(() => {
    !!scrollRef.current && userID && !caseID && (scrollRef.current.scrollTop = 0);
  }, [caseID, userID]);

  const getCurrentCase = (curCase, client) => {
    setActiveCurCase([curCase[curCase.length - 1]]);
    const associatedQuery = client ? '&questionnaire=assigned' : '';
    !!curCase.length
      ? navigate(
          `/client-management/userID=${userID}?caseID=${
            curCase[curCase.length - 1]
          }${associatedQuery}`
        )
      : navigate(`/client-management/userID=${userID}`);
  };

  const getCurrentCompany = curCompany => {
    setActiveCurCompany([curCompany[curCompany.length - 1]]);
    !!curCompany.length
      ? navigate(
          `/client-management/userID=${userID}?petitionerID=${curCompany[curCompany.length - 1]}`
        )
      : navigate(`/client-management/userID=${userID}`);
  };

  const editName = () => {
    setIsVisible(isVisible => !isVisible);
  };

  const openResendSummary = useCallback(() => {
    navigate(`${location.pathname}?action=resend-invitation-summary`);
  }, [location.pathname, navigate]);

  const onRowClick = useCallback(
    record => {
      navigate(
        `/petitioner-dashboard/master-intake/${record.id}/${record.petitioner_name.replaceAll(
          ' ',
          '-'
        )}`
      );
    },
    [navigate]
  );

  return (
    <Col className="case-dashboard">
      {userDetails?.full_name && (
        <div className="case-dashboard-userBlock-container">
          {userDetails?.is_test && (
            <div className="test-account-tag">
              <div className="circle" />
              <span>Test account</span>
            </div>
          )}
          <div className="case-dashboard-userBlock">
            <h2 className="case-dashboard-user">{userDetails?.full_name}</h2>
            <Button
              type="ghost"
              icon={<EditOutlined />}
              className="case-dashboard-user-icon edit"
              onClick={editName}
            />
            <Tooltip
              placement="right"
              overlayClassName="resend-tooltip"
              title="Resend Invitation Summary"
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              <Button
                className="case-dashboard-user-icon resend"
                type="ghost"
                icon={sendIcon}
                onClick={openResendSummary}
              />
            </Tooltip>
          </div>
          <div className="case-dashboard-userBlock">
            <h2 className="case-dashboard-email">{userDetails?.email}</h2>
            <div className={`case-dashboard-account_state ${userDetails?.state}`}>
              {userDetails?.state}
            </div>
          </div>
        </div>
      )}
      <Col ref={scrollRef}>
        {!!companiesList.length && (
          <CompanySection
            companiesList={companiesList}
            companyCasesList={companyCasesList}
            curActiveCompany={curActiveCompany}
            getCurrentCompany={getCurrentCompany}
            companyCasesListLoading={companyCasesListLoading}
            companiesListLoading={companiesListLoading}
            role={role}
            onRowClick={onRowClick}
          />
        )}
        {!!beneficiaryList.length && (
          <BeneficiarySection
            curActiveCase={curActiveCase}
            getCurrentCase={getCurrentCase}
            beneficiaryList={beneficiaryList}
            casesLoading={casesLoading}
            child_cases={child_cases}
            list={list}
            role={role}
            userDetails={userDetails}
          />
        )}
        {!!associatedList.length && (
          <AssociatedSection
            curActiveCase={curActiveCase}
            getCurrentCase={getCurrentCase}
            associatedList={associatedList}
            casesLoading={casesLoading}
            child_cases={child_cases}
            list={list}
            role={role}
            userDetails={userDetails}
          />
        )}
      </Col>
      <ChangeNameModal
        visible={isVisible}
        editName={editName}
        userDetails={userDetails}
        userDetailsLoading={userDetailsLoading}
        setActiveCurCase={setActiveCurCase}
      />
      <ResendInvitationSummaryModal user={userDetails} resendItems={resendItems} />
    </Col>
  );
};

export default ClientPage;
