import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Input } from 'antd';

import './floatLabelInput.scss';

const FloatLabelInput = ({ id, inputRef, label, value, onChange, ...props }) => {
  const [focus, setFocus] = useState(false);

  const labelClass = focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  useEffect(() => {
    focus && inputRef?.current?.focus();
  });

  return (
    <div
      className={`float-label ${focus ? 'focus' : ''}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input
        {...props}
        value={value}
        onChange={onChange}
        ref={inputRef}
        className="floating-input"
      />
      <label htmlFor={id} className={labelClass}>
        {label}
      </label>
    </div>
  );
};

FloatLabelInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRef: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

FloatLabelInput.defaultProps = {
  id: null,
  inputRef: {},
  label: '',
  value: ''
};

export default FloatLabelInput;
