import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { Row, Col, Table, Collapse } from 'antd';
import { tableNoData } from 'components/Icons/Icons';
import { LoadingOutlined } from '@ant-design/icons';

import tableColumns from 'pages/ImSpecPages/PetitionerPage/elements/tableColumns';

const { Panel } = Collapse;

const PetitionerPageTable = ({ cases: { list, isLoading } }) => {
  const [activeKey, setActiveKey] = useState([]);
  const hideChildCases = useFlag('hide-child-cases');
  const { flagsReady } = useFlagsStatus();

  const columns = tableColumns({ flagsReady, hideChildCases });

  useEffect(() => {
    setActiveKey(list.length ? ['1'] : []);
  }, [list.length]);

  const TableNoData = (
    <div className="no-data">
      {tableNoData}
      <span className="assign-text">There are no cases attached to this Petitioner.</span>
    </div>
  );

  const CasesCollapseHeader = () => (
    <Row className="collapse-accounts">
      <span className="title">Cases</span>
    </Row>
  );

  const onChangeCollapse = key => setActiveKey(key);

  return (
    <Col className="section-associated_accounts">
      <Collapse
        onChange={onChangeCollapse}
        collapsible={!list.length && 'disabled'}
        activeKey={activeKey}
        className="collapse-associated"
      >
        <Panel key="1" header={<CasesCollapseHeader />}>
          <Col className="wrapper">
            <Table
              className="status-table petitioner-page-table"
              columns={columns}
              dataSource={list}
              tableLayout="fixed"
              locale={{ emptyText: TableNoData }}
              loading={{
                spinning: isLoading,
                indicator: <LoadingOutlined style={{ fontSize: 30 }} />
              }}
              pagination={false}
            />
          </Col>
        </Panel>
      </Collapse>
    </Col>
  );
};

PetitionerPageTable.propTypes = {
  cases: PropTypes.shape({
    list: PropTypes.array,
    isLoading: PropTypes.bool
  })
};

PetitionerPageTable.defaultProps = {
  cases: {}
};

export default PetitionerPageTable;
