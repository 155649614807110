import request from 'utils/request';

export const ImSpecPetitionerPageService = {
  getPetitionerCases: id => {
    return request('GET', `api/petitioner/${id}/cases`);
  },
  getPetitioner: id => {
    return request('GET', `api/petitioner/single/${id}`);
  },
  getPetitionerRepresentatives: id => {
    return request('GET', `api/petitioner/${id}/representatives`);
  },
  getCompanyMasterIntakes: petitioner_id => {
    return request('GET', `api/master-intake/petitioners/${petitioner_id}`);
  },
  editPetitionerName: (id, data) => {
    return request('PATCH', `api/petitioner/${id}/update`, data);
  },
  attachExistingClients: (id, userID) => {
    return request('POST', `/api/petitioner/${id}/representative/${userID}/attach/`);
  },
  attachNewPerson: (id, data) => {
    return request('POST', `/api/petitioner/${id}/representative/invite/`, data);
  },
  detachRepresentative: (userID, petitionerID) => {
    return request('POST', `api/petitioner/${petitionerID}/representative/${userID}/detach/`);
  },
  syncMasterIntake: (masterIntakeID, petitionerName) => {
    return request(
      'POST',
      `api/master-intake/sync/${masterIntakeID}/petitioner/${petitionerName}/`
    );
  }
};
