import { combineReducers } from 'redux';

// Action types
import { CustomerActionTypes } from '../types';

/* Reducers */
// Auth
import AuthReducer from 'store/Reducers/auth.reducer';
// Customer
import CustomerSidebarReducer from 'store/Reducers/customer.sidebar.reducer';
import CustomerContentReducer from 'store/Reducers/customer.content.reducer';
import CustomerSectionReviewReducer from 'store/Reducers/customer.section.review.reducer';
import CustomerClientPageReducer from 'store/Reducers/customer.client.page.reducer';
import CustomerProfilePageReducer from 'store/Reducers/customer.profile.page.reducer';

// IS
import ImSpecSideBar from 'store/Reducers/im_spec.sidebar.reducer';
import ImSpecCaseDashboardReducer from 'store/Reducers/im_spec.case.dashboard.reducer';
import ImSpecCaseDashboardBetaReducer from 'store/Reducers/im_spec.case.dashboard.beta.reducer';
import ImSpecContentReducer from 'store/Reducers/im_spec.content.reducer';
import ImSpecSectionDashboardReducer from 'store/Reducers/im_spec.section.dashboard.reducer';
import ImSpecClientManagementReducer from 'store/Reducers/im_spec.client.management.reducer';
import ImSpecClientPageReducer from 'store/Reducers/im_spec.client.page.reducer';
import ImSpecPetitionerDashboardReducer from 'store/Reducers/im_spec.petitioner.dashboard.reducer';
import ImSpecPetitionerPageReducer from 'store/Reducers/im_spec.petitioner.page.reducer';
import ImSpecProfilePageReducer from 'store/Reducers/im_spec.profile.page.reducer';

import HistoryLogCommentsThreadReducer from './historylog.comments.thread.reducer';

const appReducer = combineReducers({
  auth: AuthReducer,
  customerSidebar: CustomerSidebarReducer,
  customerContent: CustomerContentReducer,
  customerClientPage: CustomerClientPageReducer,
  customerSectionsReview: CustomerSectionReviewReducer,
  customerProfilePage: CustomerProfilePageReducer,
  ImSpecSideBar: ImSpecSideBar,
  ImSpecCaseDashboard: ImSpecCaseDashboardReducer,
  ImSpecCaseDashboardBeta: ImSpecCaseDashboardBetaReducer,
  ImSpecClientManagement: ImSpecClientManagementReducer,
  ImSpecClientPage: ImSpecClientPageReducer,
  ImSpecPetitionerDashboard: ImSpecPetitionerDashboardReducer,
  ImSpecPetitionerPage: ImSpecPetitionerPageReducer,
  ImSpecContent: ImSpecContentReducer,
  ImSpecSectionDashboard: ImSpecSectionDashboardReducer,
  ImSpecProfilePage: ImSpecProfilePageReducer,
  HistoryLogCommentsThread: HistoryLogCommentsThreadReducer
});

const rootReducer = (state, action) => {
  if (action.type === CustomerActionTypes.LOG_OUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
