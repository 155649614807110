import { ImSpecActionTypes, CustomerActionTypes } from 'store/types';
import { ImSpecClientPageService, ImSpecSidebarService } from 'services';

import { HistoryLogCommentsThreadAction } from 'store/Actions';

const getImSpecSidebarCases = caseID => async dispatch => {
  try {
    dispatch(sideBarDataLoading(true));

    const res = await ImSpecSidebarService.getCases(caseID);

    const { sections, child_cases, ...case_info } = res.data;
    const sections_for_threads = sections.map(section => ({
      section_name: section.title,
      section_icon: section.icon,
      section_id: section.id
    }));

    dispatch(setCaseDetails({ ...case_info }));
    dispatch(setSelectedSidebarInfo([...sections]));
    dispatch(setChildCases([...child_cases]));

    dispatch(HistoryLogCommentsThreadAction.setCaseSections(sections_for_threads));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(sideBarDataLoading(false));
  }
};

const getImSpecSideBarData = userID => async (dispatch, getState) => {
  try {
    dispatch(sideBarDataLoading(true));
    const {
      ImSpecSideBar: {
        sidebarInfo: { sideBarData }
      }
    } = getState();
    if (sideBarData.some(visa => +visa.user_id === +userID)) return;

    const res = await ImSpecSidebarService.getCaseTypes(userID);

    if (res.status !== 200) {
      throw new Error(res.data);
    }

    dispatch(setSideBarData(res.data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(sideBarDataLoading(false));
  }
};

const getCaseDetails = caseID => async dispatch => {
  try {
    const res = await ImSpecSidebarService.getCaseDetails(caseID);

    dispatch(setCaseDetails(res.data));
  } catch (e) {
    console.warn(e);
  }
};

const getUserAllCases = userID => async dispatch => {
  try {
    const benRes = await ImSpecClientPageService.getBeneficiary(userID);
    const assRes = await ImSpecClientPageService.getAssociated(userID);

    dispatch(
      setSideBarData({
        beneficiary: benRes.data.cases,
        associated: assRes.data.cases
      })
    );
    dispatch(setUserDetails(benRes.data.user));
  } catch (e) {
    console.warn(e);
  }
};

const onSelectVisaType = (caseID, userID) => async dispatch => {
  try {
    const res = await ImSpecSidebarService.getSelectedCaseTypeInfo(caseID, userID);

    dispatch(setSelectedSidebarInfo([...res.data]));
  } catch (e) {
    console.warn(e);
  }
};

const goToGoogleDrive = caseID => async () => {
  try {
    const res = await ImSpecSidebarService.getGoogleDriveLink(caseID);

    window.open(res.data.link, '_blank');
  } catch (e) {
    console.warn(e);
  }
};

const setChildCases = cases => {
  return {
    type: ImSpecActionTypes.SET_IM_SPEC_SIDEBAR_CHILD_CASES,
    payload: [...cases]
  };
};

const setCaseDetails = details => {
  return {
    type: ImSpecActionTypes.SET_IM_SPEC_VISA_TYPE,
    payload: { ...details }
  };
};

const setSelectedSidebarInfo = sideBarData => {
  return {
    type: CustomerActionTypes.SET_SELECTED_SIDEBARINFO,
    payload: [...sideBarData]
  };
};

const setSideBarData = data => {
  return {
    type: ImSpecActionTypes.SET_IM_SPEC_SIDEBAR_DATA,
    payload: { ...data }
  };
};

const setUserDetails = user => {
  return {
    type: ImSpecActionTypes.CLIENT_USER_DETAILS_SIDEBAR,
    payload: { ...user }
  };
};

const sideBarDataLoading = loading => {
  return {
    type: ImSpecActionTypes.IM_SPEC_SIDEBAR_LOADING,
    payload: loading
  };
};

export const ImSpecSideBarAction = {
  getImSpecSidebarCases,
  getImSpecSideBarData,
  setSideBarData,
  getCaseDetails,
  getUserAllCases,
  setSelectedSidebarInfo,
  onSelectVisaType,
  setCaseDetails,
  goToGoogleDrive,
  setChildCases
};
