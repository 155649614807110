import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ImSpecSectionDashboardActions } from 'store/Actions';

import useQuery from 'hooks/useQuery';

import FloatLabelSelect from 'components/shared/FloatLabelSelect';
import { Button, Form, Modal } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { sectionAndSubsectionNames } from 'utils/helper';

const ChangeOwnerModal = ({ sections, accounts, beneficiary: { id, first_name, last_name } }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { caseID } = useParams();
  const { owner_id, section_name, action } = useQuery();
  const [form] = Form.useForm();

  const ownerRef = useRef(null);
  const [fullName, setFullName] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [suffixIcon, setSuffixIcon] = useState(<DownOutlined />);

  const visible = /(change_owner)/.test(action);

  useEffect(() => {
    owner_id && form.setFieldsValue({ client: +owner_id });
  }, [form, owner_id]);

  const onDropdownVisibleChange = open => {
    setSuffixIcon(open ? <UpOutlined /> : <DownOutlined />);
  };

  const onFieldsChange = (cur, all) => {
    setDisabled(
      all.some(e => +e.value === +owner_id || e.touched === false || e.errors.length > 0)
    );
  };

  const onFinish = values => {
    const section = sections.filter(
      section => section.section_name === sectionAndSubsectionNames(section_name)
    );
    const data = {
      ...values,
      case_id: caseID,
      petition_sections: [...section.map(section => ({ ...section, selected: true }))]
    };

    dispatch(
      ImSpecSectionDashboardActions.changeOwner(
        { data, full_name: fullName, section_name },
        navigate,
        setDisabled
      )
    );
  };

  const onCancel = () => {
    setDisabled(true);
    navigate(-1);
  };

  const onSelect = (_, option) => {
    setFullName(option.fullName);
    ownerRef.current && ownerRef.current.blur();
  };

  return (
    <Modal
      forceRender
      open={visible}
      className="assign-modal"
      width={414}
      title="Change Owner"
      centered
      closable={false}
      footer={null}
    >
      <hr />
      <p className="case-assign">
        Once you change the owner of this intake, only the new owner will have access to it.
      </p>
      <p className="case-assign">You can restore Beneficiary’s access later.</p>

      <Form
        form={form}
        layout="vertical"
        name="assignment-change-owner-form"
        autoComplete="off"
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item name="client">
          <FloatLabelSelect
            inputRef={ownerRef}
            label="Owner"
            virtual={false}
            popupClassName="dropdown-field-dropdown"
            suffixIcon={suffixIcon}
            onDropdownVisibleChange={onDropdownVisibleChange}
            getPopupContainer={trigger => trigger.parentNode}
            onSelect={onSelect}
            options={[
              {
                label: `${first_name} ${last_name}`,
                value: id,
                key: id,
                fullName: `${first_name} ${last_name}`
              },
              ...accounts
                .filter(client => client.state !== 'Canceled' && client.id !== id)
                .map(client => ({
                  label: client.full_name,
                  value: client.id,
                  key: client.id,
                  fullName: client.full_name
                }))
            ]}
          />
        </Form.Item>

        <Form.Item className="submitting" htmlFor="submit">
          <Button onClick={onCancel} className="assign-btn cancel">
            Cancel
          </Button>
          <Button
            disabled={disabled}
            type={disabled ? 'disabled' : 'primary'}
            className="assign-btn assign"
            htmlType="submit"
          >
            Change
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ChangeOwnerModal.propTypes = {
  sections: PropTypes.array,
  accounts: PropTypes.array,
  beneficiary: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  ])
};

ChangeOwnerModal.defaultProps = {
  accounts: [],
  beneficiary: {},
  sections: []
};

export default ChangeOwnerModal;
