import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ImSpecCaseDashboardAction } from 'store/Actions';

import { useLocation, useNavigate } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

import FloatLabelSelect from 'components/shared/FloatLabelSelect';
import { orderListByKey } from 'utils/helper';

import { Modal, Form, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const AssignModal = ({
  onSubmit,
  caseSpecialists: { immigration_specialists, immigration_attorneys, isLoading },
  specialist,
  im_spec,
  attorney
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [form] = Form.useForm();
  const { case_id, assign } = useQuery();

  const [defaultAssigned, setDefaultAssigned] = useState({});
  const [disabled, setDisabled] = useState(true);

  const visible = !!case_id && (assign === 'im_spec' || assign === 'attorney');

  useEffect(() => {
    if (case_id && !(assign === 'im_spec' || assign === 'attorney')) navigate(location.pathname);
  }, [assign, case_id, location.pathname, navigate]);

  useEffect(() => {
    !isLoading &&
      setDefaultAssigned({
        attorney: !!specialist ? specialist.id : 'Unassigned',
        im_spec: !!specialist ? specialist.id : 'Unassigned'
      });
    !isLoading &&
      form.setFieldsValue({
        attorney: !!specialist ? specialist.id : 'Unassigned',
        im_spec: !!specialist ? specialist.id : 'Unassigned'
      });
  }, [attorney, form, im_spec, isLoading, specialist]);

  useEffect(() => {
    case_id && dispatch(ImSpecCaseDashboardAction.caseSpecialists(assign));
  }, [dispatch, case_id, assign]);

  const onFinish = values => {
    const data = Object.entries(values).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value === 'Unassigned' ? '' : value };
    }, {});
    onSubmit(data, case_id, setDisabled);
  };

  const onFieldsChange = (cur, all) => {
    setDisabled(all.some(e => e.touched === false || e.errors.length > 0));

    if (assign === 'im_spec') {
      defaultAssigned.im_spec === cur[0]?.value && setDisabled(true);
    }
    if (assign === 'attorney') {
      defaultAssigned.attorney === cur[0]?.value && setDisabled(true);
    }
  };

  const onCancelAssign = () => {
    setDisabled(true);
    navigate(`${location.pathname}`);
  };

  return (
    <Modal
      forceRender
      className="assign-modal assign"
      width={414}
      title="Case Assignment"
      centered
      closable={false}
      open={visible}
      footer={null}
    >
      <hr />

      <Spin spinning={isLoading} size="large" indicator={<LoadingOutlined color="#63A0FF" />}>
        <h4 className="case-assign">
          Assign <b>{case_id}</b> to {assign === 'im_spec' ? 'IS' : 'Attorney'}.
        </h4>

        <Form
          form={form}
          layout="vertical"
          name="assignment-form"
          autoComplete="off"
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
        >
          {assign === 'attorney' ? (
            <Form.Item name="attorney">
              <FloatLabelSelect
                label="Attorney"
                showSearch
                virtual={false}
                listHeight={192}
                popupClassName="dropdown-field-dropdown"
                getPopupContainer={trigger => trigger.parentNode}
                filterOption={(input, option) =>
                  option.label.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.label.props.children
                    .toLowerCase()
                    .localeCompare(optionB.label.props.children.toLowerCase())
                }
                options={[
                  {
                    label: <div className="ant-select-selection-item-label">Unassigned</div>,
                    key: 'Unassigned',
                    value: 'Unassigned'
                  },
                  ...orderListByKey(immigration_attorneys, ['first_name', 'last_name']).map(
                    ({ id, first_name, last_name }) => ({
                      label: (
                        <div className="ant-select-selection-item-label">{`${first_name} ${last_name}`}</div>
                      ),
                      value: id,
                      key: id
                    })
                  )
                ]}
              />
            </Form.Item>
          ) : null}
          {assign === 'im_spec' ? (
            <Form.Item name="im_spec">
              <FloatLabelSelect
                label="IS"
                showSearch
                virtual={false}
                listHeight={192}
                popupClassName="dropdown-field-dropdown"
                getPopupContainer={trigger => trigger.parentNode}
                filterOption={(input, option) =>
                  option.label.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.label.props.children
                    .toLowerCase()
                    .localeCompare(optionB.label.props.children.toLowerCase())
                }
                options={[
                  {
                    label: <div className="ant-select-selection-item-label">Unassigned</div>,
                    key: 'Unassigned',
                    value: 'Unassigned'
                  },
                  ...orderListByKey(immigration_specialists, ['first_name', 'last_name']).map(
                    ({ id, first_name, last_name }) => ({
                      label: (
                        <div className="ant-select-selection-item-label">{`${first_name} ${last_name}`}</div>
                      ),
                      value: id,
                      key: id
                    })
                  )
                ]}
              />
            </Form.Item>
          ) : null}
          <Form.Item className="submitting" htmlFor="submit">
            <Button onClick={onCancelAssign} className="assign-btn cancel">
              Cancel
            </Button>
            <Button
              disabled={disabled}
              type={disabled ? 'disabled' : 'primary'}
              className="assign-btn assign"
              htmlType="submit"
            >
              Assign
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

AssignModal.propTypes = {
  onSubmit: PropTypes.func,
  caseSpecialists: PropTypes.shape({
    immigration_specialists: PropTypes.array,
    immigration_attorneys: PropTypes.array,
    isLoading: PropTypes.bool
  }),
  specialist: PropTypes.object,
  im_spec: PropTypes.object,
  attorney: PropTypes.object
};

AssignModal.defaultProps = {
  caseSpecialists: {},
  specialist: {},
  im_spec: {},
  attorney: {}
};

export default AssignModal;
