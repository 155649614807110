import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useQuery from 'hooks/useQuery';

import { Col } from 'antd';

// components
import PetitionerDashboardTop from 'pages/ImSpecPages/PetitionerDashboard/elements/PetitionerDashboardTop';
import PetitionerDashboardTable from 'pages/ImSpecPages/PetitionerDashboard/elements/PetitionerDashboardTable';
import CreatePetitionerModal from 'pages/ImSpecPages/PetitionerDashboard/elements/CreatePetitionerModal';
import { ImSpecPetitionerDashboardAction } from 'store/Actions';

const PetitionerDashboard = () => {
  const dispatch = useDispatch();
  const { search } = useQuery();

  const { petitioners, entryPetitionerCases } = useSelector(
    state => state.ImSpecPetitionerDashboard
  );

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    search
      ? dispatch(ImSpecPetitionerDashboardAction.searchPetitioners(search))
      : dispatch(ImSpecPetitionerDashboardAction.getPetitioners());
  }, [dispatch, search]);

  const onCreatePetitioner = () => {
    setVisible(true);
  };

  return (
    <Col className="dataWrapper petitioner-dashboard">
      <Col className="wrapper">
        <PetitionerDashboardTop onCreatePetitioner={onCreatePetitioner} />
        <PetitionerDashboardTable
          petitioners={petitioners}
          entryPetitionerCases={entryPetitionerCases}
        />
        <CreatePetitionerModal visible={visible} setVisible={setVisible} />
      </Col>
    </Col>
  );
};

export default PetitionerDashboard;
