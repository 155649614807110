import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import NestedQuestions from 'pages/CustomerPages/SectionReview/elements/nestedQuestions';
import IterativeQuestion from 'pages/CustomerPages/SectionReview/elements/IterativeQuestion';
import Answers from 'pages/CustomerPages/SectionReview/elements/Answers';

const Question = ({
  question: { field_set, children, has_children, title, type, group_name },
  sectionsAnswers,
  iterationName,
  questionSet
}) => {
  const [answersState, setAnswersState] = useState({});

  useEffect(() => {
    setAnswersState(
      !!iterationName && !!questionSet
        ? sectionsAnswers[iterationName][questionSet]
        : sectionsAnswers
    );
  }, [questionSet, iterationName, sectionsAnswers]);

  return (
    <>
      {type.type !== 'Iterative' && <h4 className="question-title">{title}</h4>}
      {type.type !== 'Iterative' && has_children && (
        <NestedQuestions
          question={{ title, field_set, type, has_children, children }}
          sectionsAnswers={answersState}
        />
      )}
      {type.type === 'Iterative' && (
        <IterativeQuestion
          question={{ title, field_set, type, children, group_name }}
          sectionsAnswers={answersState}
        />
      )}
      {!has_children &&
        type.type !== 'Iterative' &&
        field_set.map(field => {
          return (
            <Answers
              question={{ title, type }}
              key={field.id}
              field={field}
              answersState={answersState}
            />
          );
        })}
    </>
  );
};

Question.propTypes = {
  question: PropTypes.shape({
    field_set: PropTypes.array,
    children: PropTypes.array,
    has_children: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.object,
    group_name: PropTypes.string
  }),
  sectionsAnswers: PropTypes.object,
  iterationName: PropTypes.string,
  questionSet: PropTypes.string
};

Question.defaultProps = {
  question: {},
  sectionsAnswers: {},
  iterationName: '',
  questionSet: ''
};

export default Question;
