import { ImSpecActionTypes } from 'store/types';
import { ImSpecPetitionerDashboardService } from 'services';
import { Modal } from 'antd';

const getPetitioners = () => async (dispatch, getState) => {
  try {
    dispatch(petitionersLoading(true));
    const {
      ImSpecPetitionerDashboard: {
        petitioners: { pageSize, page }
      }
    } = getState();

    const res = await ImSpecPetitionerDashboardService.getPetitioners(pageSize, page);

    const results = res.data.results.map(petitioner => ({
      ...petitioner,
      key: petitioner.id
    }));

    dispatch(setPetitioners({ results, count: res.data.count }));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(petitionersLoading(false));
  }
};

const searchPetitioners = search => async (dispatch, getState) => {
  try {
    dispatch(petitionersLoading(true));

    const {
      ImSpecPetitionerDashboard: {
        petitioners: { pageSize, page }
      }
    } = getState();

    const { data } = await ImSpecPetitionerDashboardService.searchPetitioners(page, pageSize, {
      params: search
    });

    const results = data.results.map(petitioner => ({ ...petitioner, key: petitioner.id }));

    dispatch(setPetitioners({ results, count: data.count }));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(petitionersLoading(false));
  }
};

const getEntryPetitionerCases = id => async dispatch => {
  try {
    dispatch(entryPetitionerCasesLoading(true));

    const res = await ImSpecPetitionerDashboardService.getPetitionerCases(id);

    dispatch(setEntryPetitionersCases(res.data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(entryPetitionerCasesLoading(false));
  }
};

const onCreatePetitioner =
  (data, form, setDisabled, setVisible, setNameErr, search) => async dispatch => {
    try {
      await ImSpecPetitionerDashboardService.createPetitioner(data);

      setDisabled(true);
      form.resetFields();
      setVisible(false);

      const refreshPage = () => {
        search ? dispatch(searchPetitioners(search)) : dispatch(getPetitioners());
      };

      Modal.info({
        width: 438,
        centered: true,
        icon: null,
        className: 'submit-section-confirm-modal congrats',
        okButtonProps: {
          className: 'submit-section-confirm-modal-submit_btn',
          loading: false
        },
        okText: 'Close',
        content: (
          <>
            <span className="submit-section-confirm-modal-content">Success!</span>
            <span className="submit-section-confirm-modal-title">
              {data.name} petitioner account successfully created.
            </span>
            <span className="submit-section-confirm-modal-title">
              You can now select this petitioner in account and case invitations.
            </span>
          </>
        ),
        onOk: () => refreshPage()
      });
    } catch (e) {
      e.data.non_field_errors ? setNameErr(e.data.non_field_errors[0]) : console.warn(e);
      setDisabled(true);
    }
  };

const onChangePage = (page, pageSize, search) => async (dispatch, getState) => {
  const {
    ImSpecPetitionerDashboard: {
      petitioners: { pageSize: pageSizeFromState, page: pageFromState }
    }
  } = getState();

  if (page !== pageFromState) dispatch(setPage(page));

  if (pageSize !== pageSizeFromState) {
    dispatch(setPage(1));
    dispatch(setPageSize(pageSize));
  }
  search ? dispatch(searchPetitioners(search)) : dispatch(getPetitioners());
};

const setPageSize = pageSize => {
  return {
    type: ImSpecActionTypes.SET_PETITIONERS_PAGE_SIZE,
    payload: pageSize
  };
};

const setPage = page => {
  return {
    type: ImSpecActionTypes.SET_PETITIONERS_PAGE,
    payload: page
  };
};

const petitionersLoading = loading => {
  return {
    type: ImSpecActionTypes.PETITIONERS_LIST_LOADING,
    payload: loading
  };
};

const setPetitioners = petitioners => {
  return {
    type: ImSpecActionTypes.SET_PETITIONERS,
    payload: { ...petitioners }
  };
};

const entryPetitionerCasesLoading = loading => {
  return {
    type: ImSpecActionTypes.ENTRY_PETITIONER_CASES_LOADING,
    payload: loading
  };
};

const setEntryPetitionersCases = cases => {
  return {
    type: ImSpecActionTypes.SET_ENTRY_PETITIONER_CASES,
    payload: [...cases]
  };
};

export const ImSpecPetitionerDashboardAction = {
  getPetitioners,
  onChangePage,
  setPage,
  getEntryPetitionerCases,
  onCreatePetitioner,
  searchPetitioners
};
