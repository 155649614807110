import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ImSpecPetitionerPageAction } from 'store/Actions';

import masterIntakeTableColumns from 'pages/ImSpecPages/PetitionerPage/elements/masterIntakeTableColumns';

import { Col, Table, Collapse, Row, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const CompanyMasterIntake = ({ companyMasterIntakes: { list, isLoading } }) => {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState([]);

  const syncMasterIntake = (masterIntakeID, petitionerName) => {
    dispatch(ImSpecPetitionerPageAction.syncMasterIntake(masterIntakeID, petitionerName));
  };

  const columns = masterIntakeTableColumns({ syncMasterIntake });

  useEffect(() => {
    setActiveKey(list.length ? ['1'] : []);
  }, [list.length]);

  const MasterIntakeCollapseHeader = () => (
    <Row className="collapse-accounts">
      <span className="title">Company Master Intakes</span>
    </Row>
  );

  const onChangeCollapse = key => setActiveKey(key);

  const tooltipRow = ({ className, ...props }) => {
    const classNames = className.split(' ');

    if (classNames.includes('sync-in-progress')) {
      const title = classNames.includes('sync-in-progress') ? (
        <span>
          Syncing in progress. <br /> Please wait!
        </span>
      ) : classNames.includes('sync-in-progress-M2M') ? (
        <span>Syncing between company intakes is in progress!</span>
      ) : null;

      return (
        <Tooltip
          title={title}
          overlayClassName="no-access-tooltip"
          getPopupContainer={triggerNode => triggerNode.parentNode.parentNode.parentNode}
        >
          <tr className={className} {...props} />
        </Tooltip>
      );
    }
    return <tr className={className} {...props} />;
  };

  const rowClassName = record => {
    if (
      record.sync_in_progress &&
      !record.synced_with_master_intake &&
      record.last_sync_type === 'section'
    ) {
      return 'case_sections-list-row sync-in-progress';
    }

    if (
      record.sync_in_progress &&
      record.synced_with_master_intake &&
      record.last_sync_type === 'master_intake'
    ) {
      return 'case_sections-list-row sync-in-progress-M2M';
    }
    return 'case_sections-list-row';
  };

  return (
    <Col className="section-associated_accounts">
      <Collapse
        onChange={onChangeCollapse}
        activeKey={activeKey}
        collapsible={!list.length && 'disabled'}
        className="collapse-associated"
      >
        <Panel key="1" header={<MasterIntakeCollapseHeader />}>
          <Col className="wrapper">
            <Table
              className="case_sections-list client"
              rowClassName={rowClassName}
              tableLayout="fixed"
              loading={{
                spinning: isLoading,
                indicator: <LoadingOutlined style={{ fontSize: 30 }} />
              }}
              columns={columns}
              dataSource={list.map(intake => ({ ...intake, key: intake.id }))}
              pagination={false}
              components={{
                body: { row: tooltipRow }
              }}
            />
          </Col>
        </Panel>
      </Collapse>
    </Col>
  );
};

CompanyMasterIntake.propTypes = {
  companyMasterIntakes: PropTypes.shape({
    list: PropTypes.array,
    isLoading: PropTypes.bool
  })
};

CompanyMasterIntake.defaultProps = {
  companyMasterIntakes: {}
};

export default CompanyMasterIntake;
