import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';

import { Menu } from 'antd';

import { IS_ACCOUNT_PROFILE_SIDEBAR_ITEMS, IS_SIDEBAR_ITEMS } from 'utils/constants';

import SidebarTabIcons from 'components/Icons/SidebarTabIcons';
import { backIcon } from 'components/Icons/Icons';

const MainSidebarItems = ({ collapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const accountProfile = useMatch('/account-profile');

  const IsSidebarItems = IS_SIDEBAR_ITEMS.map(({ id, path, className, value, icon }) => {
    const props = collapsed ? { onClick: () => navigate(path) } : {};
    return {
      label: <Link to={path}>{value}</Link>,
      className,
      icon: <SidebarTabIcons name={icon} />,
      key: id,
      ...props
    };
  });

  const IsSidebarAccountProfileItems = IS_ACCOUNT_PROFILE_SIDEBAR_ITEMS.map(
    ({ id, path, className, value, icon }) => {
      const props = collapsed ? { onClick: () => navigate(path) } : {};
      return {
        label: <Link to={path}>{value}</Link>,
        className,
        icon: <SidebarTabIcons name={icon} />,
        key: id,
        ...props
      };
    }
  );

  const currentKey = useMemo(() => {
    let items = accountProfile ? IS_ACCOUNT_PROFILE_SIDEBAR_ITEMS : IS_SIDEBAR_ITEMS;

    return items
      .find(item => {
        if (location.pathname.includes(item.path)) return item;
      })
      ?.id.toString();
  }, [accountProfile, location.pathname]);

  const onSelect = ({ key }) => {
    if (key === 'back') {
      navigate('/dashboard');
    }
  };

  return (
    <div>
      <Menu
        mode="inline"
        className="ImSpec_Menu"
        onClick={onSelect}
        selectedKeys={[]}
        items={[
          ...(accountProfile
            ? [
                {
                  label: 'Back',
                  key: 'back',
                  icon: backIcon,
                  className: 'backBtn'
                }
              ]
            : [])
        ]}
      />
      <Menu
        mode="inline"
        className="ImSpec_Menu main-manu"
        selectedKeys={[currentKey]}
        items={accountProfile ? IsSidebarAccountProfileItems : IsSidebarItems}
      />
    </div>
  );
};

MainSidebarItems.propTypes = {
  collapsed: PropTypes.bool
};

MainSidebarItems.defaultProp = {
  collapsed: false
};

export default MainSidebarItems;
