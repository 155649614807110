import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecContentAction } from 'store/Actions';
import useQuery from 'hooks/useQuery';

import { Questions } from '../index';

import { Radio, Modal, Button } from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';

const SingleSelectNested = ({
  curSubSection,
  curField,
  nestedQuestion,
  seeMore,
  commentsFlag,
  curGroup,
  seeMoreRef
}) => {
  const formData = new FormData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { masterIntakeID, petitionerName, sectionTitle, caseID } = useParams();

  const { section, answers, answersLoading, sendAnswersLoading, sync_info } = useSelector(
    state => state.ImSpecContent
  );

  const { comment } = useQuery();

  const fieldRef = useRef(null);
  const seeCommentsRef = useRef(null);
  const [radioValue, setRadioValue] = useState('');
  const [radioDisable, setRadioDisable] = useState(false);
  const [answersDataState, setAnswersDataState] = useState({});
  const [showRequiredError, setShowRequiredError] = useState(false);

  useLayoutEffect(() => {
    if (
      fieldRef &&
      fieldRef.current &&
      answersDataState &&
      Object.keys(answersDataState).length &&
      (location.state?.answer_id || comment)
    ) {
      if (!answersLoading && (+fieldRef.current.id === location.state?.answer_id || +fieldRef.current.id === +comment)) {
        fieldRef.current.scrollIntoView({ behavior: 'smooth' });
        const curAnswer = Object.values(answersDataState).find(
          answer => answer.id === location.state?.answer_id || answer.id === +comment
        );

        if (location.state?.action === 'history_log') {
          setTimeout(() => {
            curAnswer && seeMore(curAnswer, curField);
          }, 1000);
        }

        if (location.state?.action === 'comment' || comment) {
          if (seeCommentsRef && seeCommentsRef.current) {
            setTimeout(() => {
              curAnswer && seeCommentsRef.current.click();
            }, 1000);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersLoading, answersDataState, location.state]);

  useEffect(() => {
    setShowRequiredError(!!location.state?.review);
  }, [location.state?.review, answersDataState]);

  useEffect(() => {
    setAnswersDataState(answers);
  }, [answers]);

  useEffect(() => {
    if (sendAnswersLoading) {
      setRadioDisable(sendAnswersLoading);
    } else {
      !sendAnswersLoading && setRadioDisable(answersLoading);
    }
  }, [sendAnswersLoading, answersLoading]);

  useEffect(() => {
    answersDataState &&
      answersDataState[curField.name] &&
      setRadioValue(answersDataState[curField.name]?.field_value);
  }, [answersDataState, curField.name]);

  const onSubmit = (formData, caseID) => {
    dispatch(
      ImSpecContentAction.saveAnswer({
        sectionTitle,
        newAnswer: formData,
        caseID,
        redirect: navigate,
        location,
        masterIntakeID,
        petitionerName
      })
    );
  };

  const modalProps = {
    width: 438,
    centered: true,
    icon: null,
    className: 'confirm-radio',
    cancelButtonProps: { className: 'confirm-radio-cancel_btn' },
    okButtonProps: { className: 'confirm-radio-confirm_btn' },
    okText: 'Confirm',
    onCancel: () => setRadioDisable(false)
  };

  const onChangeRadio = e => {
    setRadioDisable(true);
    const content = (
      <>
        <p className="confirm-radio-description">
          Selecting <b>{e.target.value}</b> will omit any details you might have provided under
          option <b>{radioValue}</b>.
        </p>
        <p className="confirm-radio-description">
          Please confirm if you want to change the answer anyway.
        </p>
      </>
    );

    const hasChildrenInValue = nestedQuestion?.some(
      question => question?.nested_open_value === radioValue
    );
    formData.append('field_name', curField.name);
    formData.append('field_value', e.target.value);
    formData.append('field_type', curField.type.type);

    !radioValue || !hasChildrenInValue
      ? onSubmit(formData, caseID)
      : Modal.confirm({
          ...modalProps,
          content,
          onOk: () => onSubmit(formData, caseID)
        });
  };

  const NestedFields = nestedQuestion?.map(question => {
    return (
      question?.nested_open_value === radioValue && (
        <Questions
          curGroup={curGroup}
          curSubSection={curSubSection}
          key={question.id}
          curQuestionContent={question}
        />
      )
    );
  });

  return (
    <div
      id={answersDataState?.[curField.name]?.id}
      ref={fieldRef}
      className={`${
        curField.is_required && showRequiredError && !radioValue
          ? 'required-error-block-nested'
          : ''
      }`}
    >
      <div className="curField">
        {commentsFlag(curField, seeCommentsRef)}
        <Radio.Group
          className="nestedRadioBtnContainer"
          id={curField.name}
          onChange={onChangeRadio}
          value={radioValue}
          disabled={sync_info?.sync_in_progress || section?.is_blocked || radioDisable}
        >
          {curField?.content.map(value => {
            return (
              <Radio
                className="nestedRadioBtn"
                tabIndex="-1"
                id={`${curField.name}_${value}`}
                key={value}
                value={value}
              >
                {value}
              </Radio>
            );
          })}
        </Radio.Group>
        {answersDataState[curField.name] &&
          answersDataState[curField.name]?.field_value !== null && (
            <Button
              ref={seeMoreRef}
              type="ghost"
              className="historyIcon"
              icon={<FieldTimeOutlined style={{ fontSize: 21, color: '#63A0FF' }} />}
              onClick={() => seeMore(answersDataState[curField.name], curField)}
            />
          )}
      </div>
      {curField.is_required && <p className="required-error">This field is required.</p>}
      {NestedFields}
    </div>
  );
};

SingleSelectNested.propTypes = {
  curSubSection: PropTypes.object,
  curField: PropTypes.object,
  nestedQuestion: PropTypes.array,
  seeMore: PropTypes.func,
  commentsFlag: PropTypes.func,
  curGroup: PropTypes.object,
  seeMoreRef: PropTypes.object
};

SingleSelectNested.defaultProps = {
  curSubSection: {},
  curField: {},
  nestedQuestion: [],
  curGroup: {},
  seeMoreRef: { current: null }
};

export default SingleSelectNested;
