import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecContentAction } from 'store/Actions';

import { Radio } from 'antd';

const SingleSelectSimple = ({ curField, questionSet, iterationName }) => {
  const formData = new FormData();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { masterIntakeID, petitionerName, sectionTitle, caseID } = useParams();

  const { section, answers, answersLoading, sendAnswersLoading, sync_info } = useSelector(
    state => state.ImSpecContent
  );

  const [answersDataState, setAnswersDataState] = useState({});
  const [radioDisable, setRadioDisable] = useState(false);
  const [radioValue, setRadioValue] = useState('');
  const [showRequiredError, setShowRequiredError] = useState(false);

  useEffect(() => {
    setShowRequiredError(!!location.state?.review);
  }, [location.state?.review, answersDataState]);

  useEffect(() => {
    if (sendAnswersLoading) {
      setRadioDisable(sendAnswersLoading);
    } else {
      !sendAnswersLoading && setRadioDisable(answersLoading);
    }
  }, [sendAnswersLoading, answersLoading]);

  useEffect(() => {
    !!iterationName
      ? !questionSet.startsWith('uId') &&
        setAnswersDataState(answers[iterationName]?.field_value[questionSet])
      : setAnswersDataState(answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionSet, answers]);

  useEffect(() => {
    answersDataState &&
      answersDataState[curField.name] &&
      setRadioValue(answersDataState[curField.name]?.field_value);
  }, [answersDataState, curField.name]);

  const onChange = e => {
    setRadioValue(e.target.key);
    formData.append('field_name', curField.name);
    formData.append('field_value', e.target.value);
    formData.append('field_type', curField.type.type);
    formData.append('group_name', !!iterationName ? iterationName : '');
    questionSet &&
      !questionSet.startsWith('uId') &&
      formData.append(
        'iteration_key',
        !!answers[iterationName]?.field_value || !questionSet.startsWith('uId')
          ? answers[iterationName]?.field_value[questionSet][curField.name]?.iteration_key
          : null
      );
    dispatch(
      ImSpecContentAction.saveAnswer({
        sectionTitle,
        newAnswer: formData,
        caseID,
        location,
        redirect: navigate,
        masterIntakeID,
        petitionerName
      })
    );
  };

  return (
    <div
      className={`${
        curField.is_required && showRequiredError && !radioValue
          ? 'required-error-block-nested'
          : ''
      }`}
    >
      <Radio.Group
        className="radioFieldWrapper"
        onChange={onChange}
        value={radioValue}
        disabled={sync_info?.sync_in_progress || section?.is_blocked || radioDisable}
      >
        {curField?.content.map(value => {
          return (
            <Radio className="nestedRadioBtn" tabIndex="-1" key={value} value={value}>
              {value}
            </Radio>
          );
        })}
      </Radio.Group>
      {curField.is_required && <p className="required-error">This field is required.</p>}
    </div>
  );
};

SingleSelectSimple.propTypes = {
  curField: PropTypes.object,
  questionSet: PropTypes.string,
  iterationName: PropTypes.string
};

SingleSelectSimple.defaultProps = {
  curField: {},
  questionSet: '',
  iterationName: ''
};

export default SingleSelectSimple;
