import axiosInstance from 'lib/axiosENV';

export default function request(method, url, data = null) {
  return axiosInstance({ method, url, data }).then(
    res => {
      return res;
    },
    error => {
      throw error.response;
    }
  );
}
