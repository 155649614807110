import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import { formatData, filterNameHelperWithCapitalize, shouldRenderValue } from 'utils/helper';

import { Col, Collapse, Modal, notification } from 'antd';
import { savedViewsEmpty } from 'components/Icons/Icons';

import SavedViewCollapsePanelHeader from 'pages/ImSpecPages/CaseDashboardBeta/elements/SavedViewCollapsePanelHeader';

const CaseDashboardSavedViews = ({ savedViewsList, onDrawerClose }) => {
  const dispatch = useDispatch();
  const [savedViewEditable, setSavedViewEditable] = useState({});

  const [api, contextHolder] = notification.useNotification();

  const openNotification = text => {
    api.info({
      message: text
    });
  };

  const modalProps = {
    width: 438,
    centered: true,
    icon: null,
    className: 'confirm-radio',
    cancelButtonProps: {
      className: 'submit-section-confirm-modal-cancel_btn'
    },
    okButtonProps: { className: 'confirm-radio-confirm_btn', loading: false },
    okText: 'Delete'
  };

  const onDeleteSavedView = (e, id, name) => {
    e.stopPropagation();
    Modal.confirm({
      ...modalProps,
      content: (
        <>
          <p className="submit-confirm-modal-title">Delete View</p>
          <hr />
          <p className="confirm-radio-description">
            Are you sure you want to delete <b>"{name}"</b>?
          </p>
        </>
      ),
      onOk: () => dispatch(ImSpecCaseDashboardBetaAction.deleteSavedFilters(id))
    });
  };

  const handleRenameSavedView = (id, filter_params, name) => e => {
    e.preventDefault();
    const newName = e.target.value;

    if (newName === name) {
      setSavedViewEditable({});
      return;
    }

    if (!newName || !newName.trim()) {
      openNotification('View name is invalid.');
      setSavedViewEditable({});
    } else if (newName.length > 50) {
      openNotification('This view name is too long.');
    } else {
      dispatch(
        ImSpecCaseDashboardBetaAction.updateSavedFilter(
          id,
          newName,
          setSavedViewEditable,
          openNotification,
          filter_params
        )
      );
    }
  };

  return (
    <>
      {contextHolder}
      {!savedViewsList.length ? (
        <div className="emptySavedViews">
          {savedViewsEmpty}
          <p>There are no saved views yet.</p>
          <p>For any set of filters applied you can click on</p>
          <p>
            <b>Save this view</b> button to access it in no time!
          </p>
        </div>
      ) : (
        <Collapse ghost accordion className="savedViewsCollapse">
          {savedViewsList.map(({ id, name, filter_params }) => {
            return (
              <Collapse.Panel
                key={id}
                collapsible={Object.keys(savedViewEditable).length && 'disabled'}
                header={
                  <SavedViewCollapsePanelHeader
                    id={id}
                    name={name}
                    filter_params={filter_params}
                    savedViewEditable={savedViewEditable}
                    handleRenameSavedView={handleRenameSavedView}
                    setSavedViewEditable={setSavedViewEditable}
                    onDeleteSavedView={onDeleteSavedView}
                    onDrawerClose={onDrawerClose}
                  />
                }
              >
                <Col className="wrapper">
                  {Object.entries(filter_params).map(([key, value]) => {
                    let formattedValue = formatData(value);
                    if (key === 'case_id') {
                      formattedValue = value.map(item => item.case_id).join(', ');
                    } else if (key === 'case_type') {
                      formattedValue = value.map(item => item.visa_name).join(', ');
                    } else if (key === 'beneficiary') {
                      formattedValue = value.map(item => item.beneficiary).join(', ');
                    }
                    return shouldRenderValue(formattedValue) ? (
                      <div key={key} className="filterValues">
                        <div>
                          <span className="blueDot"></span>
                          <span>{filterNameHelperWithCapitalize(key)}:</span>
                        </div>
                        <p>{formattedValue}</p>
                      </div>
                    ) : null;
                  })}
                </Col>
              </Collapse.Panel>
            );
          })}
        </Collapse>
      )}
    </>
  );
};

CaseDashboardSavedViews.propTypes = {
  savedViewsList: PropTypes.array,
  onChange: PropTypes.func,
  onDrawerClose: PropTypes.func
};

CaseDashboardSavedViews.defaultProps = {
  savedViewsList: []
};

export default CaseDashboardSavedViews;
