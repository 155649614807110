import { Link } from 'react-router-dom';
import { Table } from 'antd';
import moment from 'moment/moment';

const petitionsTableColumns = () => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: (name, { key }) => {
        return (
          <Link className="table-hrefs" to={`/petitioner-dashboard/${key}`}>
            {name}
          </Link>
        );
      }
    },
    {
      title: 'Cases attached',
      dataIndex: 'cases_attached',
      key: 'cases_attached',
      ellipsis: true,
      render: cases_attached => <span>{cases_attached || '---'}</span>
    },
    {
      title: 'Last update',
      dataIndex: 'updated_at',
      key: 'updated_at',
      ellipsis: true,
      render: updated_at => (
        <span className="last-update">
          {moment(updated_at).format('MM.DD.YYYY')}
          <span className="hours">{moment(updated_at).format('hh:mm A')}</span>
        </span>
      )
    },
    Table.EXPAND_COLUMN
  ];
};

export default petitionsTableColumns;
