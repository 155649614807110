import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { AuthActions } from 'store/Actions';

import { Button, Col, Form, Input, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading } = useSelector(state => state.auth);

  const [disabled, setDisabled] = useState(true);
  const [emailErr, setEmailErr] = useState(null);

  const onFieldsChange = (cur, all) => {
    setDisabled(all.some(e => e.touched === false || e.errors.length > 0));
  };

  const onFinish = data => {
    dispatch(AuthActions.recoveryPassword(data, navigate, setEmailErr));
  };

  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content">
        <Row>
          <h2>Forgot your password?</h2>
        </Row>
        <Row>
          <Col className="subTitle" type="flex" justify="center" align="middle">
            <p>Don’t worry, happens to the best of us.</p>
            <p>Please enter your email address.</p>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle">
          <Form name="signIn" onFinish={onFinish} onFieldsChange={onFieldsChange}>
            <Form.Item
              name="email"
              className={emailErr ? 'error-border' : ''}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'This field is required.'
                },
                () => ({
                  validator(_, value) {
                    if (
                      !!value &&
                      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        value
                      )
                    ) {
                      return Promise.reject(
                        new Error('You have entered an invalid e-mail address.')
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            {!!emailErr && <p className="error">{emailErr}</p>}
            <Form.Item>
              <Button disabled={disabled || isLoading} htmlType="submit">
                {!isLoading ? (
                  <span>Email me recovery link</span>
                ) : (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                )}
              </Button>
            </Form.Item>
          </Form>
        </Row>
        {/*todo uncomment when feature is active*/}
        {/*<Row className="ifHasAcc">*/}
        {/*  Don’t have an account yet? <Link to="/sign-up">Sign up</Link>*/}
        {/*</Row>*/}
      </Col>
    </Row>
  );
};

export default ForgotPassword;
