import request from 'utils/request';

export const CustomerSectionReviewService = {
  getSectionContent: (caseID, sectionTitle) => {
    return request('GET', `api/answer/section/${sectionTitle}/visa/${caseID}/questions/`);
  },
  getSectionAnswers: (caseID, sectionTitle) => {
    return request('GET', `api/answer/review-answers/${caseID}/section/${sectionTitle}`);
  },
  onSubmitSection: (caseID, sectionTitle) => {
    return request('PATCH', `api/answer/visa/${caseID}/section/${sectionTitle}/submit/`, {});
  },
  getMasterIntakeContent: (masterIntakeID, petitionerName) => {
    return request('GET', `api/master-intake/${masterIntakeID}/petitioner/${petitionerName}`);
  },
  getMasterIntakeAnswers: (masterIntakeID, petitionerName) => {
    return request(
      'GET',
      `api/master-intake/${masterIntakeID}/petitioner/${petitionerName}/review/answers`
    );
  },
  syncAndSubmit: masterIntakeID => {
    return request('POST', `api/master-intake/${masterIntakeID}/customer/sync_submit/`);
  }
};
