import { useState } from 'react';
import { Select } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const SelectComponentClass = props => {
  const [suffixIcon, setSuffixIcon] = useState(<DownOutlined />);

  const onDropdownVisibleChange = open => setSuffixIcon(open ? <UpOutlined /> : <DownOutlined />);

  return (
    <Select
      {...props}
      size="small"
      placement="topLeft"
      popupClassName="ant-pagination-options-size-changer-dropdown"
      onDropdownVisibleChange={onDropdownVisibleChange}
      suffixIcon={suffixIcon}
    />
  );
};

SelectComponentClass.Option = Select.Option;

export default SelectComponentClass;
