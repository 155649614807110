import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from 'store/Actions';

import { Row, Col, Form, Input, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { successIcon, errorIcon } from 'components/Icons/Icons';

const passVerificationError = {
  hasLength: false,
  isNotEmpty: false,
  hasUpper: false,
  hasLower: false,
  hasSpecial: false,
  hasNumber: false,
  confirm_password: false
};

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading } = useSelector(state => state.auth);

  const [touched, setTouched] = useState(false);
  const [passwordError, setPasswordError] = useState(passVerificationError);
  const [disabled, setDisabled] = useState(true);

  const onFinish = newPass => {
    dispatch(AuthActions.resetPassword(newPass, id, navigate));
  };

  const onFieldsChange = (cur, all) => {
    const { touched, name, value } = cur[0];
    const passwordValue = all.find(e => e.name[0] === 'password').value;
    const confirmPasswordValue = all.find(e => e.name[0] === 'confirm_password').value;
    if (touched) {
      if (name[0] === 'password') {
        setTouched(touched);
        setPasswordError({
          ...passwordError,
          isNotEmpty: !!value,
          hasLength: value.length > 11,
          hasUpper: /[A-Z]/.test(value),
          hasLower: /[a-z]/.test(value),
          hasSpecial: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value),
          hasNumber: /[0-9]/.test(value),
          confirm_password: confirmPasswordValue === value
        });
      }
      if (name[0] === 'confirm_password') {
        setPasswordError({
          ...passwordError,
          confirm_password: passwordValue === value
        });
      }
    }
    setDisabled(
      Object.values(passwordError).some(val => val === false) ||
        all.find(e => e.touched === false || e.errors.length > 0) ||
        passwordValue !== confirmPasswordValue
    );
  };

  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content">
        <Row>
          <h1>Reset password</h1>
        </Row>
        <Row>
          <Form name="signUp" onFinish={onFinish} onFieldsChange={onFieldsChange}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!'
                }
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!'
                }
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <div className="pass-errs">
              <ul>
                <li>
                  {touched && (passwordError.hasLength ? successIcon : errorIcon)}
                  At least 12 characters
                </li>
                <li>
                  {touched && (passwordError.hasLower ? successIcon : errorIcon)}1 lowercase letter
                </li>
                <li>{touched && (passwordError.hasNumber ? successIcon : errorIcon)}1 number</li>
              </ul>
              <ul>
                <li>
                  {touched && (passwordError.hasSpecial ? successIcon : errorIcon)}1 special
                  character
                </li>
                <li>
                  {touched && (passwordError.hasUpper ? successIcon : errorIcon)}1 uppercase letter
                </li>
                <li>
                  {touched &&
                    (passwordError.confirm_password && passwordError.isNotEmpty
                      ? successIcon
                      : errorIcon)}
                  Passwords match
                </li>
              </ul>
            </div>
            <Form.Item>
              <Button disabled={disabled || isLoading} htmlType="submit">
                {!isLoading ? (
                  <span>Reset</span>
                ) : (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                )}
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Col>
    </Row>
  );
};

export default ResetPassword;
