import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';

const UnavailableInvitationError = () => {
  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content resetPass">
        <Row>
          <h3>Oops, the link is not available.</h3>
        </Row>

        <Row type="flex" justify="center" align="middle" className="publicForms_content_cloud">
          <p>You’re seeing this because the link is expired or disabled.</p>
        </Row>

        <hr />

        <Row type="flex" justify="center" align="middle" className="publicForms_content_footer">
          <p>
            You can contact us at onboarding@legalpad.io for any questions you might have.
          </p>
        </Row>

        <Row type="flex" justify="center" align="middle" className="publicForms_content_footer">
          <Link to="/">
            <ArrowLeftOutlined /> Back to your dashboard
          </Link>
        </Row>
      </Col>
    </Row>
  );
};

export default UnavailableInvitationError;
