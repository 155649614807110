import { Col, Row } from 'antd';
import { ghostNotFound } from 'components/Icons/Icons';
import { Link } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';

const PageNotFound = () => {
  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content notFound">
        <Row>
          <h3>Page doesn’t exist</h3>
        </Row>

        <Row type="flex" justify="center" align="middle">
          {ghostNotFound}
        </Row>

        <Row type="flex" justify="center" align="middle">
          <Link to="/">
            <ArrowLeftOutlined /> Back to your dashboard
          </Link>
        </Row>

        <Row type="flex" justify="center" align="middle">
          <address>
            Please contact us at{' '}
            <a href="mailto:onboarding@legalpad.io">onboarding@legalpad.io</a> for
            assistance.
          </address>
        </Row>
      </Col>
    </Row>
  );
};

export default PageNotFound;
