import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { ImSpecContentAction } from 'store/Actions';

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { Questions } from '../index';
import { stringifyNumber } from 'utils/constants';
import { Button, Modal } from 'antd';
import { addIterationIcon } from 'components/Icons/Icons';

const Iterative = ({ curSubSection, curQuestionContent, curField, nestedQuestion, curGroup }) => {
  const dispatch = useDispatch();
  const { masterIntakeID, petitionerName, sectionTitle, caseID } = useParams();
  const {
    section,
    answers: answersData,
    sendAnswersLoading,
    answersLoading,
    sync_info
  } = useSelector(state => state.ImSpecContent);

  const iterationIsAddDelete = useFlag('hide-iteration-is-add-delete');
  const { flagsReady } = useFlagsStatus();

  const [iterativeSetAnswers, setIterativeSetAnswers] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (sendAnswersLoading) {
      setDisabled(sendAnswersLoading);
    } else if (!sendAnswersLoading && answersLoading) {
      setDisabled(answersLoading);
    } else {
      setDisabled(isTyping || !!iterativeSetAnswers.find(e => e.startsWith('uId')));
    }
  }, [answersLoading, isTyping, iterativeSetAnswers, sendAnswersLoading]);

  useEffect(() => {
    !!answersData?.[curField.name]?.field_value &&
      setIterativeSetAnswers(Object.keys(answersData?.[curField.name]?.field_value));
  }, [answersData, curField.name]);

  useEffect(() => {
    !answersData?.[curField.name]?.field_value &&
      setIterativeSetAnswers([...iterativeSetAnswers, `uId_${Date.now()}`]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteIterative = useCallback(
    (caseID, groupName, iterationKey) => {
      const content = (
        <>
          <p className="submit-confirm-modal-title">Remove {curQuestionContent.title}?</p>
          <hr />
          <p className="confirm-radio-description">
            Are you sure you want to remove this <b>{curQuestionContent.title}</b>?
          </p>
        </>
      );

      const deleteIterativeQuestion = async () => {
        await dispatch(
          ImSpecContentAction.removeIterativeQuestionSet(
            caseID,
            groupName,
            iterationKey,
            sectionTitle
          )
        );
        await dispatch(
          ImSpecContentAction.getSectionAnswers(
            masterIntakeID,
            petitionerName,
            sectionTitle,
            caseID
          )
        );
      };

      iterationKey.startsWith('uId')
        ? setIterativeSetAnswers(iterativeSetAnswers.filter(item => item !== iterationKey))
        : Modal.confirm({
            width: 438,
            centered: true,
            icon: null,
            className: 'confirm-radio',
            cancelButtonProps: {
              className: 'submit-section-confirm-modal-cancel_btn'
            },
            okButtonProps: { className: 'confirm-radio-confirm_btn', loading: false },
            okText: 'Remove',
            content,
            onOk: () => deleteIterativeQuestion()
          });
    },
    [
      curQuestionContent,
      dispatch,
      iterativeSetAnswers,
      masterIntakeID,
      petitionerName,
      sectionTitle
    ]
  );

  const deleteIterationFlag = useCallback(
    (questionSet, index) => {
      return flagsReady && !iterationIsAddDelete && iterativeSetAnswers
        ? ((iterativeSetAnswers.length > 1 && !iterativeSetAnswers[1]?.startsWith('uId')) ||
            (questionSet.startsWith('uId') && index !== 0)) && (
            <Button
              className="deleteIteration"
              disabled={
                sync_info.sync_in_progress || section?.is_blocked || isTyping || sendAnswersLoading
              }
              onClick={() => deleteIterative(caseID, curQuestionContent.group_name, questionSet)}
            >
              Remove {curQuestionContent?.title}
            </Button>
          )
        : null;
    },
    [
      caseID,
      curQuestionContent,
      section,
      deleteIterative,
      flagsReady,
      isTyping,
      iterationIsAddDelete,
      iterativeSetAnswers,
      sendAnswersLoading,
      sync_info
    ]
  );

  const addIterationFlag = useMemo(() => {
    return flagsReady && !iterationIsAddDelete
      ? iterativeSetAnswers.length < curQuestionContent.iteration_count && (
          <Button
            icon={addIterationIcon}
            className="iterationBtn primary-btn"
            onClick={() => setIterativeSetAnswers([...iterativeSetAnswers, `uId_${Date.now()}`])}
            disabled={
              sync_info.sync_in_progress ||
              section?.is_blocked ||
              isTyping ||
              sendAnswersLoading ||
              disabled
            }
            tabIndex="-1"
          >
            Add {curQuestionContent?.title}
          </Button>
        )
      : null;
  }, [
    section,
    curQuestionContent,
    disabled,
    flagsReady,
    isTyping,
    iterationIsAddDelete,
    iterativeSetAnswers,
    sendAnswersLoading,
    sync_info
  ]);

  return (
    <>
      {!!Object.keys(iterativeSetAnswers).length &&
        iterativeSetAnswers?.map((questionSet, index) => {
          return (
            <div
              key={index}
              className={`${stringifyNumber(index + 1)} ${curQuestionContent?.title}`}
            >
              <div className="iterationName">
                <h5>
                  {stringifyNumber(index + 1)} {curQuestionContent?.title}
                </h5>
              </div>
              {deleteIterationFlag(questionSet, index)}
              {!answersLoading &&
                nestedQuestion?.map(question => {
                  return (
                    <Questions
                      curGroup={curGroup}
                      curSubSection={curSubSection}
                      key={question.id}
                      questionSet={questionSet}
                      iterationName={curQuestionContent.group_name}
                      curQuestionContent={question}
                      setIsTyping={setIsTyping}
                    />
                  );
                })}
            </div>
          );
        })}
      {addIterationFlag}
    </>
  );
};

Iterative.propTypes = {
  curSubSection: PropTypes.object,
  curQuestionContent: PropTypes.object,
  curField: PropTypes.object,
  nestedQuestion: PropTypes.array,
  curGroup: PropTypes.object
};

Iterative.defaultProps = {
  curSubSection: {},
  curQuestionContent: {},
  curField: {},
  nestedQuestion: [],
  curGroup: {}
};

export default Iterative;
