import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Row, Switch } from 'antd';

const columns = ({ onClickOwner, onUpdateBeneficiaryAccess, hasAccounts }, { caseID, userID }) => {
  const stopProp = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return [
    {
      title: 'Section name',
      dataIndex: 'section_name',
      key: 'section_name',
      ellipsis: true,
      width: '20%',
      render: section_name => (
        <span className="table-items">
          <Link
            className="table-hrefs"
            to={`/client-management/userID=${userID}/${caseID}/${section_name.replaceAll(
              ' ',
              '-'
            )}`}
          >
            {section_name}
          </Link>
        </span>
      )
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      ellipsis: true,
      width: '15%',
      render: (owner, { section_name, sync_in_progress }) => (
        <div>
          <Button
            type="ghost"
            style={{
              pointerEvents:
                owner.is_representative || !hasAccounts || sync_in_progress ? 'none' : 'fill',
              color:
                owner.is_representative || !hasAccounts || sync_in_progress ? '#161616' : '#63A0FF',
              cursor:
                owner.is_representative || !hasAccounts || sync_in_progress ? 'initial' : 'pointer'
            }}
            onClick={event => onClickOwner(event, section_name, owner)}
            className="table-items"
          >
            {owner.full_name}
          </Button>
          {owner.is_representative && <div className="is-rep">Rep</div>}
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '15%',
      render: status => (
        <div className={`${status.replaceAll(' ', '-')} section-status`}>
          <span className="table-items">{status}</span>
        </div>
      )
    },
    {
      title: <div className="beneficiary_has_access">Beneficiary Access</div>,
      dataIndex: 'beneficiary_has_access',
      key: 'beneficiary_has_access',
      ellipsis: true,
      width: '16%',
      render: (beneficiary_has_access, { section_name }) => (
        <Row onClick={stopProp} className="beneficiary_access" justify="center" align="middle">
          <Switch
            onChange={checked => onUpdateBeneficiaryAccess(checked, section_name)}
            className="beneficiary_access-switch"
            defaultChecked={beneficiary_has_access}
          />
        </Row>
      )
    },
    {
      title: 'Last update',
      dataIndex: 'last_updated_at',
      key: 'last_updated_at',
      ellipsis: true,
      width: '12%',
      render: last_updated_at => (
        <span className="last-update">
          {moment(last_updated_at).format('MM.DD.YYYY')}
          <span className="hours">{moment(last_updated_at).format('hh:mm A')}</span>
        </span>
      )
    }
  ];
};

export default columns;
