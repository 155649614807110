import request from 'utils/request';

export const CustomerProfilePageService = {
  getProfile: () => {
    return request('GET', 'user/details');
  },
  resetPass: passData => {
    return request('POST', 'user/change-password/', { ...passData });
  }
};
