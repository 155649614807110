import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import ReactFlagsSelect from 'react-flags-select';

const Country = ({
  setRequiredErrorClassName,
  curSubsection,
  answersDataState,
  answers,
  curField,
  iterationName,
  questionSet,
  setSavingValue,
  setShowRequiredError,
  showRequiredError,
  radioDisable,
  inputRef,
  sync_info
}) => {
  const [country, setCountry] = useState(null);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    focus && inputRef?.current.focus();
  });

  useEffect(() => {
    if (!!answersDataState && Object.keys(answersDataState).length) {
      setCountry(answersDataState[curField.name]?.field_value || null);
    }
  }, [answersDataState, curField]);

  useEffect(() => {
    setRequiredErrorClassName(
      curField.is_required && showRequiredError && !country ? 'required-error-block' : ''
    );
  }, [country, curField.is_required, setRequiredErrorClassName, showRequiredError]);

  const handleOnChangeCounty = (e, type, name) => {
    setCountry(e);
    const formData = new FormData();
    formData.append('field_name', name);
    formData.append('field_value', e);
    formData.append('field_type', type);
    formData.append('group_name', !!iterationName ? iterationName : '');
    questionSet &&
      !questionSet.startsWith('uId') &&
      formData.append(
        'iteration_key',
        !!answers[iterationName]?.field_value || !questionSet.startsWith('uId')
          ? answers[iterationName]?.field_value[questionSet][name]?.iteration_key
          : null
      );
    setSavingValue(formData);

    curField.is_required && !country.trim().length
      ? setShowRequiredError(true)
      : setShowRequiredError(false);
    setFocus(false);
  };

  return (
    <div
      className={`countryPicker ${focus ? 'focus' : ''}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <div ref={inputRef}>
        <ReactFlagsSelect
          id={curField?.id}
          name={curField?.name}
          selected={country}
          onSelect={e => handleOnChangeCounty(e, curField.type.type, curField?.name)}
          placeholder={`${curField?.placeholder}${curField.is_required ? '*' : ''}`}
          disabled={sync_info.sync_in_progress || curSubsection?.is_blocked || radioDisable}
          searchable
          searchPlaceholder="Search for countries"
        />
      </div>
    </div>
  );
};

Country.propTypes = {
  setRequiredErrorClassName: PropTypes.func,
  curSubsection: PropTypes.object,
  answersDataState: PropTypes.object,
  answers: PropTypes.object,
  curField: PropTypes.object,
  iterationName: PropTypes.string,
  questionSet: PropTypes.string,
  setSavingValue: PropTypes.func,
  setShowRequiredError: PropTypes.func,
  showRequiredError: PropTypes.bool,
  radioDisable: PropTypes.bool,
  inputRef: PropTypes.object,
  sync_info: PropTypes.object
};

Country.defaultProps = {
  curSubsection: {},
  answersDataState: {},
  answers: {},
  curField: {},
  iterationName: '',
  questionSet: '',
  showRequiredError: false,
  radioDisable: false,
  inputRef: {},
  sync_info: {}
};

export default Country;
