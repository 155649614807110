import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import AutocompleteCheckboxTags from 'components/shared/AutocompleteCheckboxTags';
import CheckboxList from 'components/shared/CheckboxList';

import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const Petitioner = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState('');
  const selectedPetitionerList = Form.useWatch('petitioners', form);
  const selectedOptionList = Form.useWatch('petitioner_options', form);
  const selectedOptionListAll = Form.useWatch('petitioner_options_all', form);

  const [isVisibleSearch, setIsVisibleSearch] = useState(false);

  const {
    filters: {
      petitioner: { optionList, list }
    },
    filterAnswers: {
      petitioner: { options, companies }
    }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    if (!options) {
      form.resetFields();
      setSearchValue('');
    }
  }, [form, options, companies]);

  useEffect(() => {
    if (options?.includes('company_sponsored') && !selectedOptionListAll) {
      !!companies?.length && setIsVisibleSearch(true);
    } else {
      form.resetFields();
      setSearchValue('');
      setIsVisibleSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, options]);

  useEffect(() => {
    if (typeof selectedOptionList !== 'undefined') {
      dispatch(
        ImSpecCaseDashboardBetaAction.setPetitioner(
          !selectedOptionList.length
            ? {}
            : {
                options: selectedOptionList,
                companies: selectedPetitionerList
              }
        )
      );
    }
  }, [dispatch, selectedOptionList, selectedPetitionerList]);

  return (
    <div className="petitionerBlock">
      <CheckboxList
        form={form}
        plainOptions={optionList}
        filterOption="petitioner_options"
        checkedValues={options}
      />
      {!isVisibleSearch && (
        <Button
          onClick={() => setIsVisibleSearch(true)}
          className="adderBtn"
          type="ghost"
          icon={<PlusOutlined />}
          disabled={selectedOptionListAll || !selectedOptionList?.includes('company_sponsored')}
        >
          Add petitioner
        </Button>
      )}
      {isVisibleSearch &&
        !selectedOptionListAll &&
        selectedOptionList?.includes('company_sponsored') && (
          <AutocompleteCheckboxTags
            name="petitioner"
            form={form}
            options={list}
            filterOption="petitioners"
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            checkedValues={companies}
          />
        )}
    </div>
  );
};

export default Petitioner;
