import { ImSpecActionTypes } from '../types';

const initialState = {
  section: null,
  answers: {},
  sync_info: {
    sync_available: false,
    sync_date: null,
    sync_in_progress: false,
    last_sync_source_type: null,
    last_sync_type: null,
    synced_with_master_intake: null,
    master_intake_sync_obligation: null,
    sync_obligation: null
  },
  entryLogs: {
    curItem: {},
    logs: [],
    entryLogsLoading: false
  },
  childCaseInfo: {},
  entryComments: {
    answer: {},
    comments: [],
    getCommentLoading: false,
    sendCommentLoading: false,
    deleteCommentLoading: false
  },
  answersLoading: false,
  sendAnswersLoading: false,
  loading: false,
  form_questionnaire: null,
  field_name: null,
  iterationName: null,
  questionSet: null,
  createDependentLoading: false
};

export const im_specContentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ImSpecActionTypes.IM_SPEC_SECTION_CONTENT_LOADING:
      return {
        ...state,
        loading: payload
      };
    case ImSpecActionTypes.IM_SPEC_SECTION_ANSWERS_LOADING:
      return {
        ...state,
        answersLoading: payload
      };
    case ImSpecActionTypes.IM_SPEC_SECTION_SEND_ANSWERS_LOADING:
      return {
        ...state,
        sendAnswersLoading: payload
      };
    case ImSpecActionTypes.SET_IM_SPEC_SECTION_CONTENT:
      return {
        ...state,
        section: { ...payload }
      };
    case ImSpecActionTypes.SET_IM_SPEC_ANSWERS_CONTENT:
      return {
        ...state,
        answers: { ...payload }
      };
    case ImSpecActionTypes.SET_ENTRY_LOGS:
      return {
        ...state,
        entryLogs: {
          ...state.entryLogs,
          curAnswer: payload.curAnswer,
          curItem: payload.curItem,
          logs: payload.logs
        }
      };
    case ImSpecActionTypes.ENTRY_LOGS_LOADING:
      return {
        ...state,
        entryLogs: {
          ...state.entryLogs,
          entryLogsLoading: payload
        }
      };
    case ImSpecActionTypes.SET_ENTRY_COMMENTS:
      return {
        ...state,
        entryComments: {
          ...state.entryComments,
          answer: payload.answer,
          comments: payload.comments
        }
      };
    case ImSpecActionTypes.IM_SPEC_SECTION_SEE_COMMENTS_LOADING:
      return {
        ...state,
        entryComments: {
          ...state.entryComments,
          getCommentLoading: payload
        }
      };
    case ImSpecActionTypes.IM_SPEC_SECTION_SAVE_COMMENTS_LOADING:
      return {
        ...state,
        entryComments: {
          ...state.entryComments,
          sendCommentLoading: payload
        }
      };
    case ImSpecActionTypes.IM_SPEC_SECTION_DELETE_ANSWERS_LOADING:
      return {
        ...state,
        entryComments: {
          ...state.entryComments,
          deleteCommentLoading: payload
        }
      };
    case ImSpecActionTypes.SET_FORM_QUESTIONNAIRE:
      return {
        ...state,
        form_questionnaire: payload.form_questions,
        field_name: payload.field_name,
        iterationName: payload.iterationName,
        questionSet: payload.questionSet
      };
    case ImSpecActionTypes.SET_CHILD_CASE_INFO_CONTENT:
      return {
        ...state,
        childCaseInfo: { ...payload }
      };
    case ImSpecActionTypes.SET_CREATE_DEPENDENT_LOADING:
      return {
        ...state,
        createDependentLoading: payload
      };
    case ImSpecActionTypes.UPDATE_SYNC_SECTION_STATUS:
      return {
        ...state,
        sync_info: {
          ...state.sync_info,
          ...payload
        }
      };
    default:
      return state;
  }
};

export default im_specContentReducer;
