import { CustomerActionTypes } from '../types';
import { CustomerSidebarService } from 'services';

import { HistoryLogCommentsThreadAction } from 'store/Actions';

import { sectionAndSubsectionNames } from 'utils/helper';

const getCaseTypes = (caseID, petitionerName) => async dispatch => {
  try {
    dispatch(sideBarDataLoading(true));

    let res;

    if (!!petitionerName) {
      res = await CustomerSidebarService.getMasterIntakeCaseTypes(
        sectionAndSubsectionNames(petitionerName)
      );
      dispatch(setMasterIntakeSidebar(res.data));
    } else {
      res = await CustomerSidebarService.getCaseTypes(caseID);
    }

    const { child_cases, sections, ...caseInfo } = res.data;

    let sections_for_threads = [];

    if (!petitionerName) {
      sections_for_threads = sections.map(section => ({
        section_name: section.title,
        section_icon: section.icon,
        section_id: section.id
      }));
    }

    !petitionerName &&
      dispatch(HistoryLogCommentsThreadAction.setCaseSections(sections_for_threads));

    !petitionerName && dispatch(sendSidebarData(caseInfo));
    !petitionerName && dispatch(setSelectedSidebarInfo(sections));
    child_cases && dispatch(setChildCases(child_cases));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(sideBarDataLoading(false));
  }
};

const curCaseTypeSider = caseID => async dispatch => {
  try {
    const { data } = await CustomerSidebarService.getEntryVisaTypeSectionSets(caseID);

    dispatch(setSelectedSidebarInfo(data));
  } catch (e) {
    console.warn(e);
  }
};

const setMasterIntakeSidebar = sidebar => {
  return {
    type: CustomerActionTypes.SET_MASTER_INTAKE_SIDEBAR,
    payload: [...sidebar]
  };
};

const setChildCases = cases => {
  return {
    type: CustomerActionTypes.SET_SIDEBAR_CHILD_CASES,
    payload: [...cases]
  };
};

const setSelectedSidebarInfo = sideBarData => {
  return {
    type: CustomerActionTypes.SET_SELECTED_SIDEBARINFO,
    payload: [...sideBarData]
  };
};

const sendSidebarData = visaType => {
  return {
    type: CustomerActionTypes.SIDEBAR_DATA,
    payload: visaType
  };
};

const sideBarDataLoading = loading => {
  return {
    type: CustomerActionTypes.TOGGLE_SIDEBAR_DATA_LOADING,
    payload: loading
  };
};

export const CustomerSidebarAction = {
  getCaseTypes,
  curCaseTypeSider,
  setChildCases,
  setSelectedSidebarInfo,
  sendSidebarData,
  setMasterIntakeSidebar
};
