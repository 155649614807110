import { ImSpecActionTypes } from 'store/types';
import { ImSpecSectionDashboardService } from 'services';
import { Modal } from 'antd';

import { sectionAndSubsectionNames } from 'utils/helper';
import { HistoryLogCommentsThreadAction } from './historylog.comments.thread.action';

const getCaseList = caseID => async (dispatch, getState) => {
  try {
    dispatch(caseListLoading(true));
    const {
      ImSpecSectionDashboard: {
        case: { list }
      }
    } = getState();
    list.length && dispatch(setCaseList([]));

    const res = await ImSpecSectionDashboardService.getCaseSections(caseID);

    const {
      im_spec,
      attorney,
      beneficiary,
      petitioner,
      visa_name,
      is_dependent,
      dependent,
      parent_breadcrumbs,
      child_cases
    } = res.data;

    dispatch(
      setCaseList(
        res.data.sections.map(section => ({
          ...section,
          key: section.section_id
        }))
      )
    );
    dispatch(
      HistoryLogCommentsThreadAction.setCaseSections(
        res.data.sections.map(({ section_name, section_icon, section_id }) => ({
          section_name,
          section_icon,
          section_id
        }))
      )
    );
    child_cases && dispatch(setChildCases(child_cases));
    dispatch(
      setContacts({
        im_spec,
        attorney,
        beneficiary,
        petitioner,
        visa_name,
        is_dependent,
        dependent,
        parent_breadcrumbs
      })
    );
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(caseListLoading(false));
  }
};

const updateBeneficiaryAccess = (caseID, SectionName, hasAccess) => async dispatch => {
  try {
    dispatch(caseListLoading(true));

    await ImSpecSectionDashboardService.updateBeneficiaryAccess(caseID, SectionName, hasAccess);

    await dispatch(getCaseList(caseID));
  } catch (e) {
  } finally {
    dispatch(caseListLoading(false));
  }
};

const getAssociatedAccounts = caseID => async dispatch => {
  try {
    dispatch(setAccountsLoading(true));

    const res = await ImSpecSectionDashboardService.getAssociatedAccounts(caseID);

    dispatch(setAssociatedAccount(res.data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(setAccountsLoading(false));
  }
};

const searchUsersForInvite = data => async dispatch => {
  try {
    dispatch(setSearchUsersLoading(true));
    const res = await ImSpecSectionDashboardService.getSearchedUsers(data);

    const users = res.data.map(user => {
      const label = (
        <div className="user-details">
          <span className="full-name">
            {user.first_name} {user.last_name}
          </span>
          <span className="email">{user.email}</span>
        </div>
      );
      return { label, value: user.id, ...user };
    });
    const hasData = !data.params ? null : !!users.length;

    dispatch(setSearchedUsers({ users, hasData }));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(setSearchUsersLoading(false));
  }
};

const changeOwner =
  ({ data, full_name, section_name }, navigate, setDisabled) =>
  async dispatch => {
    try {
      setDisabled(true);
      await ImSpecSectionDashboardService.inviteExistingClient(data);

      const refreshPage = async () => {
        dispatch(setCaseList([]));
        dispatch(setBeneficiary({}));
        dispatch(getCaseList(data.case_id));
        dispatch(getAssociatedAccounts(data.case_id));
      };

      navigate(-1);

      Modal.info({
        width: 438,
        centered: true,
        icon: null,
        className: 'submit-section-confirm-modal congrats',
        okButtonProps: {
          className: 'submit-section-confirm-modal-submit_btn',
          loading: false
        },
        okText: 'Close',
        content: (
          <>
            <span className="submit-section-confirm-modal-content">Success!</span>
            <span className="submit-section-confirm-modal-title">
              {full_name} now owns {sectionAndSubsectionNames(section_name)} intake.
            </span>
          </>
        ),
        onOk: () => refreshPage()
      });
    } catch (e) {
      console.warn(e);
    }
  };

const inviteExistingClient =
  (data, { navigate }, { form, setDisabled }) =>
  async dispatch => {
    try {
      await setDisabled(true);
      const res = await ImSpecSectionDashboardService.inviteExistingClient(data);

      const refreshPage = async () => {
        dispatch(setCaseList([]));
        dispatch(setBeneficiary({}));
        await dispatch(getCaseList(data.case_id));
        await dispatch(getAssociatedAccounts(data.case_id));
      };
      navigate(-1);

      Modal.info({
        width: 438,
        centered: true,
        icon: null,
        className: 'submit-section-confirm-modal congrats',
        okButtonProps: {
          className: 'submit-section-confirm-modal-submit_btn',
          loading: false
        },
        okText: 'Close',
        content: (
          <>
            <span className="submit-section-confirm-modal-content">
              Invitation successfully sent!
            </span>
            <span
              className="submit-section-confirm-modal-title"
              dangerouslySetInnerHTML={{ __html: res.data.message }}
            />
          </>
        ),
        onOk: () => refreshPage()
      });

      await form.resetFields();
    } catch (e) {
      console.warn(e);
    }
  };

const inviteNewPerson =
  (data, setEmailErr, { navigate }, { form, setDisabled }) =>
  async dispatch => {
    try {
      await setDisabled(true);
      const res = await ImSpecSectionDashboardService.inviteNewPerson(data);

      const refreshPage = async () => {
        dispatch(setCaseList([]));
        dispatch(setBeneficiary({}));
        await dispatch(getCaseList(data.case_id));
        await dispatch(getAssociatedAccounts(data.case_id));
      };
      navigate(-1);

      Modal.info({
        width: 438,
        centered: true,
        icon: null,
        className: 'submit-section-confirm-modal congrats',
        okButtonProps: {
          className: 'submit-section-confirm-modal-submit_btn',
          loading: false
        },
        okText: 'Close',
        content: (
          <>
            <span className="submit-section-confirm-modal-content">
              Invitation successfully sent!
            </span>
            <span
              className="submit-section-confirm-modal-title"
              dangerouslySetInnerHTML={{ __html: res.data.message }}
            />
          </>
        ),
        onOk: () => refreshPage()
      });

      await form.resetFields();
      await setEmailErr(null);
    } catch (e) {
      const res = { ...e }?.data;

      res.email || res.url ? await setEmailErr('Account already exists') : console.warn(e);
    }
  };

const setChildCases = cases => {
  return {
    type: ImSpecActionTypes.SET_SECTION_DASHBOARD_CHILD_CASES,
    payload: [...cases]
  };
};

const setBeneficiary = beneficiary => {
  return {
    type: ImSpecActionTypes.SET_BENEFICIARY,
    payload: { ...beneficiary }
  };
};

const setContacts = contacts => {
  return {
    type: ImSpecActionTypes.SET_IM_SPEC_CONTACTS,
    payload: { ...contacts }
  };
};

const setAccountsLoading = loading => {
  return {
    type: ImSpecActionTypes.ASSOCIATED_ACCOUNTS_LOADING,
    payload: loading
  };
};

const setAssociatedAccount = accounts => {
  return {
    type: ImSpecActionTypes.SET_ASSOCIATED_ACCOUNTS,
    payload: [...accounts]
  };
};

const setSearchedUsers = users => {
  return {
    type: ImSpecActionTypes.SET_SEARCH_USERS,
    payload: { ...users }
  };
};

const setSearchUsersLoading = loading => {
  return {
    type: ImSpecActionTypes.SET_SEARCH_USERS_LOADING,
    payload: loading
  };
};

const setCaseList = list => {
  return {
    type: ImSpecActionTypes.SET_CASE_LIST,
    payload: [...list]
  };
};

const caseListLoading = loading => {
  return {
    type: ImSpecActionTypes.CASE_LIST_LOADING,
    payload: loading
  };
};

export const ImSpecSectionDashboardActions = {
  getCaseList,
  setCaseList,
  updateBeneficiaryAccess,
  searchUsersForInvite,
  getAssociatedAccounts,
  inviteExistingClient,
  inviteNewPerson,
  changeOwner
};
