import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ImSpecContentAction } from 'store/Actions';

import useQuery from 'hooks/useQuery';
import { sectionAndSubsectionNames } from 'utils/helper';
import SectionIcon from 'components/Icons/SectionIcon';

import { Menu, Tooltip, Col } from 'antd';

const ChildCasesMenu = ({ collapsed, child_cases, loading, visaType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subsection, group } = useQuery();
  const { caseID, userID, sectionTitle, subSectionTitle } = useParams();

  const { user } = useSelector(state => state.auth);

  const [openSub, setOpenSub] = useState([]);
  const [sectionOpen, setSectionOpen] = useState([]);
  const [subSectionOpen, setSubSectionOpen] = useState([]);
  const [groupOpen, setGroupOpen] = useState([]);
  const [collapsedSubMenuSelectedKey, setCollapsedSubMenuSelectedKey] = useState([]);

  useEffect(() => {
    if (child_cases.length) {
      const childCase = child_cases.find(child => child.case_id === caseID);
      if (childCase) {
        const { ...caseInfo } = childCase;
        dispatch(ImSpecContentAction.setChildCaseInfo({ ...caseInfo }));
      } else {
        dispatch(ImSpecContentAction.setChildCaseInfo({}));
      }

      setCollapsedSubMenuSelectedKey(
        childCase ? [`${childCase.dependent} ${childCase.case_id}`] : []
      );
    }
  }, [caseID, child_cases, collapsed, dispatch, setCollapsedSubMenuSelectedKey]);

  useEffect(() => {
    const openSubs = (sectionTitle, subsection, group) => {
      setGroupOpen(
        group && visaType.case_id !== caseID
          ? [`${sectionAndSubsectionNames(group)} ${caseID}`]
          : []
      );
      setSubSectionOpen(
        subsection && visaType.case_id !== caseID
          ? [`${sectionAndSubsectionNames(subsection)} ${caseID}`]
          : []
      );
      setSectionOpen(
        sectionTitle && visaType.case_id !== caseID
          ? [`${sectionAndSubsectionNames(sectionTitle)} ${caseID}`]
          : []
      );
    };
    if (sectionTitle && !subSectionTitle) openSubs(sectionTitle, subsection, group);
    if (sectionTitle && subSectionTitle) openSubs(sectionTitle, subSectionTitle, group);
  }, [caseID, group, sectionTitle, subSectionTitle, subsection, visaType.case_id]);

  useEffect(() => {
    setOpenSub([...sectionOpen, ...subSectionOpen, ...groupOpen]);
  }, [sectionOpen, subSectionOpen, groupOpen]);

  // open sections for IS side

  const openSection = ({ key }) => {
    const titleWithCaseId = key.split(' ');
    const caseID = titleWithCaseId[titleWithCaseId.length - 1];
    const title = titleWithCaseId.slice(0, -1).join(' ');

    navigate(`/client-management/userID=${userID}/${caseID}/${title.replaceAll(' ', '-')}`);
  };

  const onCLickSubSections = ({ key }) => {
    const titleWithCaseId = key.split(' ');
    const title = titleWithCaseId.slice(0, -1).join(' ');
    navigate(
      `${location.pathname}${
        subSectionOpen.includes(title) ? '' : `?subsection=${title.replaceAll(' ', '-')}`
      }`
    );
  };

  const onCLickSubSectionsGroup = (title, group) => {
    navigate(
      `${location.pathname}?subsection=${title.replaceAll(' ', '-')}&group=${group.title.replaceAll(
        ' ',
        '-'
      )}`
    );
  };

  // open sections for customer side

  const openSubSectionCus = ({ sectionTitle, subSectionTitle }) => {
    const titleWithCaseId = subSectionTitle.split(' ');
    const caseID = titleWithCaseId[titleWithCaseId.length - 1];
    const title = titleWithCaseId.slice(0, -1).join(' ');

    setSubSectionOpen(state => {
      if (state.includes(subSectionTitle)) {
        state.splice(state.indexOf(subSectionTitle), 1);
        return [...state];
      } else {
        return [subSectionTitle];
      }
    });
    setGroupOpen([]);
    navigate(
      `/your-questionnaires/${caseID}/${sectionTitle.replaceAll(' ', '-')}/${title.replaceAll(
        ' ',
        '-'
      )}`
    );
  };

  const onClickSectionsCus = ({ key }) => {
    setSectionOpen(prevState => {
      if (prevState.includes(key)) {
        prevState.splice(prevState.indexOf(key), 1);
        return [...prevState];
      } else {
        return [...prevState, key];
      }
    });
  };

  const onCLickSubSectionsGroupCus = group => {
    navigate(`${location.pathname}?group=${group.title.replaceAll(' ', '-')}`);
  };

  const childCases = child_cases.map(child => {
    const items = child.sections.map(
      ({ subsection_set = [], title: sectionTitle = '', icon = '' }) => {
        return subsection_set.length !== 0
          ? {
              label: sectionTitle,
              key: `${sectionTitle} ${child.case_id}`,
              className: `visaType-pages ${
                openSub.includes(`${sectionTitle} ${child.case_id}`) && 'active-section'
              }`,
              popupClassName: 'sub-pop-up',
              disabled: loading,
              onTitleClick: user.role !== 'customer' ? openSection : onClickSectionsCus,
              icon: (
                <Tooltip
                  trigger={collapsed ? 'hover' : ''}
                  placement="right"
                  title={`Child Information ${child.dependent}`}
                >
                  <SectionIcon name={icon} />
                </Tooltip>
              ),
              children: [
                ...subsection_set.map(({ group_set = [], title: subTitle = '' }) => {
                  return group_set.length !== 0
                    ? {
                        label: subTitle,
                        key: `${subTitle} ${child.case_id}`,
                        className: `sub-sections ${
                          openSub.includes(`${subTitle} ${child.case_id}`) && 'active-subsection'
                        }`,
                        popupClassName: 'sub-pop-up',
                        onTitleClick:
                          user.role !== 'customer'
                            ? onCLickSubSections
                            : () =>
                                openSubSectionCus({
                                  sectionTitle: sectionTitle,
                                  subSectionTitle: subTitle
                                }),
                        children: [
                          ...group_set.map(group => {
                            return {
                              label: group.title,
                              key: `${group.title} ${child.case_id}`,
                              className: `group ${
                                openSub.includes(`${group.title} ${child.case_id}`) &&
                                'active-group'
                              }`,
                              onClick:
                                user.role !== 'customer'
                                  ? () => onCLickSubSectionsGroup(subTitle, group)
                                  : () => onCLickSubSectionsGroupCus(group)
                            };
                          })
                        ]
                      }
                    : {
                        label: subTitle,
                        key: `${subTitle} ${child.case_id}`,
                        className: `sub-sections-item ${
                          openSub.includes(`${subTitle} ${child.case_id}`) &&
                          'active-subsection-item'
                        }`,
                        onClick:
                          user.role !== 'customer'
                            ? onCLickSubSections
                            : ({ key }) =>
                                openSubSectionCus({
                                  sectionTitle: sectionTitle,
                                  subSectionTitle: key
                                })
                      };
                })
              ]
            }
          : {
              label: sectionTitle,
              key: `${sectionTitle} ${child.case_id}`,
              className: 'visaType-pages',
              icon: <SectionIcon name={icon} />,
              disabled: loading,
              onClick: user.role !== 'customer' ? openSection : onClickSectionsCus
            };
      }
    );

    return (
      <Col key={child.case_id} className="child-cases-sections">
        <h3 className="child-cases-sections-title">{child.dependent}'s Case</h3>
        <Menu
          mode="inline"
          className="ImSpec_Menu case-section-menu"
          openKeys={openSub}
          selectedKeys={openSub}
          items={items}
        />
      </Col>
    );
  });

  const collapsedOpenSection = (caseID, title) => {
    navigate(`/client-management/userID=${userID}/${caseID}/${title.replaceAll(' ', '-')}`);
  };

  return (
    <Col className="child-cases">
      {!collapsed ? (
        <>
          <h2>Child Information</h2>
          {childCases}
        </>
      ) : (
        <Menu
          className="ImSpec_Menu collapsed-child-cases-menu"
          triggerSubMenuAction="click"
          selectedKeys={collapsedSubMenuSelectedKey}
          items={[
            {
              popupOffset: [-10, 48],
              popupClassName: 'sections-submenu-popup',
              label: 'I-539A Child Information',
              key: 'I539 Children',
              disabled: loading,
              icon: (
                <Tooltip
                  overlayClassName="sections-tooltip"
                  trigger={collapsed ? 'hover' : ''}
                  placement="right"
                  title="I-539A Child Information"
                >
                  <SectionIcon name="intakeIcon" />
                </Tooltip>
              ),
              children: child_cases.map(child => ({
                label: child.dependent,
                key: `${child.dependent} ${child.case_id}`,
                className: 'visaType-pages',
                onClick: () => collapsedOpenSection(child.case_id, child.sections[0].title)
              }))
            }
          ]}
        />
      )}
    </Col>
  );
};

ChildCasesMenu.propTypes = {
  collapsed: PropTypes.bool,
  child_cases: PropTypes.array,
  loading: PropTypes.bool,
  visaType: PropTypes.object
};

ChildCasesMenu.defaultProps = {
  collapsed: false,
  child_cases: [],
  loading: false,
  visaType: {}
};

export default ChildCasesMenu;
