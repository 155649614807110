import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import moment from 'moment/moment';

import { Col, Row, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { alreadySyncedReview } from 'components/Icons/Icons';

const HistoryLogSyncContent = ({ log, url }) => {
  return (
    <Col className="history-log-header-container">
      <Row justify="space-between" align="middle" className="history-log-header-title">
        <Col>
          <Row align="middle">
            <Icon className="sync-icon" component={() => alreadySyncedReview} />
            <Typography.Title level={5}>
              Source:{' '}
              <Link to={url}>
                <Typography.Text>
                  {log.source_master_intake
                    ? `${log.source_master_intake.visa_name} MI`
                    : log.source_case.case_id}
                </Typography.Text>
              </Link>
            </Typography.Title>
          </Row>
        </Col>
        <Col>
          <Row align="middle">
            <Typography.Title className="last-update" level={5}>
              {moment(log.created_at).format('hh:mm A, MMMM DD, YYYY')}
            </Typography.Title>
          </Row>
        </Col>
      </Row>
      <Row className="history-log-header-description">
        <Col>
          {log.target_case && log.target_case.section_name ? (
            <Row align="middle" className="history-log-header-description-item-container">
              <Typography.Text className="history-log-header-description-item">
                Section:{' '}
                <Typography.Text className="history-log-header-description-item-value">
                  {log.target_case.section_name}
                </Typography.Text>
              </Typography.Text>
            </Row>
          ) : null}
          {log.trigger_by ? (
            <Row align="middle" className="history-log-header-description-item-container">
              <Typography.Text className="history-log-header-description-item">
                Triggered by:{' '}
                <Typography.Text className="history-log-header-description-item-value">
                  {log.trigger_by}
                </Typography.Text>
              </Typography.Text>
            </Row>
          ) : null}
          {log.trigger_action ? (
            <Row align="middle" className="history-log-header-description-item-container">
              <Typography.Text className="history-log-header-description-item">
                Trigger Action:{' '}
                <Typography.Text className="history-log-header-description-item-value">
                  {log.trigger_action}
                </Typography.Text>
              </Typography.Text>
            </Row>
          ) : null}
        </Col>
      </Row>
    </Col>
  );
};

HistoryLogSyncContent.propTypes = {
  log: PropTypes.object,
  url: PropTypes.string
};

HistoryLogSyncContent.defaultProps = {
  log: {},
  url: ''
};

export default HistoryLogSyncContent;
