import request from 'utils/request';

export const ImSpecCaseDashboardBetaService = {
  getCasesData: (page, pageSize) => {
    return request('GET', `api/is/petitions?page=${page}&page_size=${pageSize}`);
  },
  getSearchCasesData: (search, page, pageSize) => {
    return request(
      'GET',
      `api/is/petition-cases/search?params=${search}&pageSize=${pageSize}&page=${page}`
    );
  },
  onSearch: search => {
    return request('GET', `api/is/petition-cases/search?params=${search}&pageSize=5`);
  },
  handleCaseAssign: (caseID, dataForm) => {
    return request('PATCH', `api/is/case/${caseID}/assign`, dataForm);
  },
  getCaseTypes: () => {
    return request('GET', 'api/answer/visa_list?account_invitation=false');
  },
  getStatesStatusesSource: () => {
    return request('GET', 'api/answer/all-cases/data');
  },
  getImSpecs: () => {
    return request('GET', 'api/is/case-specialists/im_spec');
  },
  getAttorneys: () => {
    return request('GET', 'api/is/case-specialists/attorney');
  },
  getPetitioners: () => {
    return request('GET', 'api/answer/petitioner/');
  },
  getBeneficiary: search_word => {
    return request('POST', 'api/is/user-profiles/search?allow_cancel=true', {
      params: search_word
    });
  },
  getCaseID: search_word => {
    return request('POST', 'api/is/cases/search', { params: `LP-${search_word}` });
  },
  applyFilters: (filters, page, pageSize) => {
    return request('POST', `api/data_filtering/cases/filter?page=${page}&pageSize=${pageSize}`, {
      ...filters
    });
  },
  saveFilters: savedFilters => {
    return request('POST', 'api/data_filtering/saved_view', { ...savedFilters });
  },
  getSavedFilters: () => {
    return request('GET', 'api/data_filtering/saved_view');
  },
  deleteSavedFilter: id => {
    return request('DELETE', `api/data_filtering/saved_view/${id}`);
  },
  updateSavedFilter: (id, name, filter_params) => {
    return request('PUT', `api/data_filtering/saved_view/${id}`, { name, filter_params });
  }
};
