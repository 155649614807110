import PropTypes from 'prop-types';

import { Skeleton, Typography } from 'antd';

const QuestionTooltipContent = ({ question }) => {
  if (question.isLoading || !question.title)
    return (
      <Skeleton active title={false} paragraph={{ rows: 2 }} className="question-title-skeleton" />
    );

  return (
    <Typography.Text className="question-title">
      Question: <Typography.Text className="question-title-inner">{question.title}</Typography.Text>
    </Typography.Text>
  );
};

QuestionTooltipContent.propTypes = {
  question: PropTypes.object
};

export default QuestionTooltipContent;
