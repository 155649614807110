import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import useOutsideClicker from 'hooks/useOutsideClicker';

import { Form, Input, Menu, Checkbox, Dropdown, Button, Space, Tag, Spin } from 'antd';
import { CloseOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';

const AutocompleteCheckboxTags = ({
  name,
  form,
  options,
  filterOption,
  searchValue,
  setSearchValue,
  isLoading,
  checkedValues
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showPrefix, setShowPrefix] = useState(false);
  const [checkedAllCaseTypes, setCheckedAllCaseTypes] = useState(false);
  const formRef = useRef(null);

  // todo need refactor querySelector part
  useOutsideClicker([formRef, { current: document.querySelector('.checkboxGroup') }], () => {
    dropdownVisible && setDropdownVisible(false);
  });

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    setCheckedAllCaseTypes(options.length === checkedValues.length);
  }, [options.length, checkedValues.length]);

  const handleInputChange = elem => {
    const inputValue = elem.target.value;

    if (!inputValue && filterOption === 'petitioners') setDropdownVisible(false);

    if (filterOption === 'case_id' && !Number(inputValue) && Number(inputValue) !== 0) {
      return;
    }
    const filtered = options.filter(option =>
      filterOption === 'petitioners'
        ? option?.name?.toLowerCase().includes(inputValue.toLowerCase())
        : filterOption === 'imSpec' || filterOption === 'attorney'
        ? option.first_name
            .concat(option.last_name)
            ?.toLowerCase()
            .includes(inputValue.toLowerCase())
        : option[filterOption]?.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
    setSearchValue(inputValue);
  };

  useEffect(() => {
    !!searchValue ? setDropdownVisible(true) : setDropdownVisible(false);
  }, [searchValue]);

  const handleCheckboxChange = checked => {
    const checkedValue = checked.map(id => options.find(option => option.id === id));
    let uniqueArray;

    if (
      filteredOptions.length === options.length &&
      filterOption !== 'case_id' &&
      filterOption !== 'beneficiary'
    ) {
      uniqueArray = [...checkedValue].filter(
        (obj, index, self) => index === self.findIndex(item => item.id === obj.id)
      );
    } else {
      const unFiltered = checkedValues.filter(
        value => !filteredOptions.find(item => item.id === value.id)
      );
      uniqueArray = [...checkedValue, ...unFiltered].filter(
        (obj, index, self) => index === self.findIndex(item => item.id === obj.id)
      );
    }

    form.setFieldValue(filterOption, uniqueArray);
  };

  const handleRemoveCheckboxChange = removedTag => {
    const newTags = checkedValues.filter(tag => tag !== removedTag);
    form.setFieldValue(filterOption, newTags);
  };

  const handleDropdownVisibleChange = visible => {
    if (searchValue) {
      setDropdownVisible(visible);
    } else if (
      filterOption !== 'petitioners' &&
      filterOption !== 'beneficiary' &&
      filterOption !== 'case_id' &&
      filterOption !== 'source'
    ) {
      setDropdownVisible(visible);
    }
    setShowPrefix(true);
  };

  const onCheckAllChange = ({ target: { checked } }) => {
    form.setFieldValue(filterOption, checked ? options.map(option => option) : []);
    setCheckedAllCaseTypes(checked);
  };

  const menu = (
    <Menu selectable={false}>
      <Spin
        spinning={typeof isLoading === 'undefined' ? !!isLoading : isLoading}
        size="large"
        indicator={<LoadingOutlined color="#63A0FF" />}
      >
        {!!filteredOptions.length && !isLoading ? (
          <>
            <Menu.Item key="checkboxGroup">
              {name === 'case type' ? (
                <Checkbox
                  rootClassName="all-types"
                  onChange={onCheckAllChange}
                  checked={checkedAllCaseTypes}
                >
                  All cases
                </Checkbox>
              ) : null}
              <Checkbox.Group
                onChange={handleCheckboxChange}
                value={checkedValues.map(option => option.id)}
              >
                {filteredOptions.map(option => (
                  <Checkbox key={option.id} value={option.id}>
                    {filterOption === 'petitioners'
                      ? option?.name
                      : filterOption === 'attorney' || filterOption === 'imSpec'
                      ? `${option.first_name} ${option.last_name}`
                      : option[filterOption]}
                    {filterOption === 'beneficiary' && option.email ? (
                      <>
                        <br />
                        <span className="beneficiaryEmail">{option.email}</span>
                      </>
                    ) : null}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Menu.Item>
            <Button
              className="addedSubmitSection"
              type="ghost"
              onClick={() => setDropdownVisible(false)}
            >
              Close
            </Button>
          </>
        ) : typeof isLoading !== 'undefined' && !filteredOptions.length ? (
          <span className="emptySearch">No items match your search</span>
        ) : null}
      </Spin>
    </Menu>
  );

  return (
    <div>
      <div ref={formRef}>
        <Form form={form} className="search-form" initialValues={{ [filterOption]: checkedValues }}>
          <Form.Item name={filterOption}>
            <Dropdown
              overlay={menu}
              trigger={['click']}
              open={dropdownVisible}
              onOpenChange={handleDropdownVisibleChange}
              overlayClassName="checkboxGroup"
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Input
                prefix={filterOption === 'case_id' ? showPrefix && 'LP-' : ''}
                addonBefore={<SearchOutlined />}
                placeholder={
                  filterOption !== 'case_id' ? `Search for ${name}` : !showPrefix && 'LP-'
                }
                allowClear={{
                  clearIcon: <CloseOutlined style={{ color: '#000', fontSize: '14px' }} />
                }}
                className="case-type-search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </Dropdown>
          </Form.Item>
        </Form>
      </div>
      <div className="case-tags-container">
        {checkedValues.map(elem => {
          return (
            <Space key={elem.id} size={[0, 8]} wrap className="case-tags">
              <Tag closable onClose={() => handleRemoveCheckboxChange(elem)}>
                <div>
                  {filterOption === 'petitioners'
                    ? elem?.name
                    : filterOption === 'attorney' || filterOption === 'imSpec'
                    ? `${elem.first_name} ${elem.last_name}`
                    : elem[filterOption]}
                  {filterOption === 'beneficiary' && ':'}
                  {filterOption === 'beneficiary' && (
                    <span style={{ fontWeight: '400', marginLeft: '7px' }}>{elem.email}</span>
                  )}
                </div>
              </Tag>
            </Space>
          );
        })}
      </div>
    </div>
  );
};

AutocompleteCheckboxTags.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  checkedValues: PropTypes.array,
  form: PropTypes.object,
  filterOption: PropTypes.string,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  isLoading: PropTypes.bool
};

AutocompleteCheckboxTags.defaultProps = {
  name: '',
  options: [],
  checkedValues: [],
  form: {},
  filterOption: '',
  searchValue: ''
};
export default AutocompleteCheckboxTags;
