import { ImSpecActionTypes } from '../types';

const initialState = {
  sidebarInfo: {
    userDetails: {},
    sideBarData: {
      visaType: {},
      beneficiary: [],
      associated: []
    },
    selected_sidebar: [],
    child_cases: []
  },
  loading: false
};

const ImSpecSideBar = (state = initialState, { type, payload }) => {
  switch (type) {
    case ImSpecActionTypes.IM_SPEC_SIDEBAR_LOADING:
      return {
        ...state,
        loading: payload
      };
    case ImSpecActionTypes.SET_IM_SPEC_SIDEBAR_DATA:
      return {
        ...state,
        sidebarInfo: {
          ...state.sidebarInfo,
          sideBarData: {
            ...state.sidebarInfo.sideBarData,
            beneficiary: payload.beneficiary,
            associated: payload.associated
          }
        }
      };
    case ImSpecActionTypes.SET_SELECTED_SIDEBARINFO:
      return {
        ...state,
        sidebarInfo: {
          ...state.sidebarInfo,
          selected_sidebar: [...payload]
        }
      };
    case ImSpecActionTypes.CLIENT_USER_DETAILS_SIDEBAR:
      return {
        ...state,
        sidebarInfo: {
          ...state.sidebarInfo,
          userDetails: { ...payload }
        }
      };
    case ImSpecActionTypes.SET_IM_SPEC_VISA_TYPE:
      return {
        ...state,
        sidebarInfo: {
          ...state.sidebarInfo,
          sideBarData: {
            ...state.sidebarInfo.sideBarData,
            visaType: { ...payload }
          }
        }
      };
    case ImSpecActionTypes.SET_IM_SPEC_SIDEBAR_CHILD_CASES:
      return {
        ...state,
        sidebarInfo: {
          ...state.sidebarInfo,
          child_cases: [...payload]
        }
      };
    default:
      return state;
  }
};

export default ImSpecSideBar;
