import PropTypes from 'prop-types';
import { useEffect, useState, createRef } from 'react';

import FloatLabelSelect from '../shared/FloatLabelSelect';

import { dropdownBottomArrow, dropdownTopArrow } from 'components/Icons/Icons';

const Dropdown = ({
  setRequiredErrorClassName,
  curSubsection,
  answersDataState,
  answers,
  curField,
  iterationName,
  questionSet,
  setSavingValue,
  setShowRequiredError,
  showRequiredError,
  radioDisable,
  inputRef,
  sync_info
}) => {
  const [dropdownSuffixIcon, setDropdownSuffixIcon] = useState(dropdownBottomArrow);
  const [dropdownValue, setDropdownValue] = useState(null);
  const [focus] = useState(false);

  useEffect(() => {
    focus && inputRef.current.focus();
  });

  useEffect(() => {
    setRequiredErrorClassName(
      curField.is_required && showRequiredError && !dropdownValue ? 'required-error-block' : ''
    );
  }, [curField.is_required, dropdownValue, setRequiredErrorClassName, showRequiredError]);

  useEffect(() => {
    if (!!answersDataState && Object.keys(answersDataState).length) {
      setDropdownValue(answersDataState[curField.name]?.field_value || null);
    }
  }, [answersDataState, curField]);

  const onDropdownVisibleChange = open => {
    setDropdownSuffixIcon(open ? dropdownTopArrow : dropdownBottomArrow);
    !open && curField.is_required && !dropdownValue
      ? setShowRequiredError(true)
      : setShowRequiredError(false);
  };

  const handleOnChangeDropdown = value => {
    setDropdownValue(value);
    const formData = new FormData();

    formData.append('field_name', curField.name);
    formData.append('field_value', value);
    formData.append('field_type', curField.type.type);
    formData.append('group_name', !!iterationName ? iterationName : '');
    questionSet &&
      !questionSet.startsWith('uId') &&
      formData.append(
        'iteration_key',
        !!answers[iterationName]?.field_value || !questionSet.startsWith('uId')
          ? answers[iterationName]?.field_value[questionSet][curField.name]?.iteration_key
          : null
      );
    setSavingValue(formData);

    curField.is_required && !dropdownValue
      ? setShowRequiredError(true)
      : setShowRequiredError(false);
  };

  return (
    <FloatLabelSelect
      inputRef={inputRef}
      id={curField?.id}
      virtual={false}
      suffixIcon={dropdownSuffixIcon}
      getPopupContainer={trigger => trigger.parentNode}
      onDropdownVisibleChange={onDropdownVisibleChange}
      label={`${curField?.placeholder}${curField.is_required ? '*' : ''}`}
      placeholder={`${curField?.placeholder}${curField.is_required ? '*' : ''}`}
      showArrow
      value={dropdownValue}
      onSelect={handleOnChangeDropdown}
      popupClassName="dropdown-field-dropdown"
      disabled={sync_info.sync_in_progress || curSubsection?.is_blocked || radioDisable}
      options={curField.content.map((value, index) => ({
        key: index,
        value,
        label: <div className="ant-select-selection-item-label">{value}</div>
      }))}
    />
  );
};

Dropdown.propTypes = {
  setRequiredErrorClassName: PropTypes.func,
  curSubsection: PropTypes.object,
  answersDataState: PropTypes.object,
  answers: PropTypes.object,
  curField: PropTypes.object,
  iterationName: PropTypes.string,
  questionSet: PropTypes.string,
  setSavingValue: PropTypes.func,
  setShowRequiredError: PropTypes.func,
  showRequiredError: PropTypes.bool,
  radioDisable: PropTypes.bool,
  inputRef: PropTypes.object,
  sync_info: PropTypes.object
};

Dropdown.defaultProps = {
  curSubsection: {},
  answersDataState: {},
  answers: {},
  curField: {},
  iterationName: '',
  questionSet: '',
  showRequiredError: false,
  radioDisable: false,
  inputRef: createRef(),
  sync_info: {}
};

export default Dropdown;
