import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AuthActions } from 'store/Actions';

import { Button, Col, Row } from 'antd';

const VerifyUpdatedEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const sendAnotherEmail = () => {
    const {
      state: { email }
    } = location;
    dispatch(
      AuthActions.resendVerifyEmail({ email, type: 'changed-email-verification' }, navigate)
    );
  };

  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content resetPass">
        <Row>
          <h3>Please verify your updated email with us first.</h3>
        </Row>

        <Row type="flex" justify="center" align="middle" className="publicForms_content_cloud">
          <p>
            Please see our verification email in your inbox and click "Take me to my account" to log
            in again.
          </p>
        </Row>

        <hr />

        <Row type="flex" justify="center" align="middle" className="publicForms_content_footer">
          <p>
            Can’t find the verification email in your inbox? <br />
            <Button type="link" onClick={sendAnotherEmail}>
              Click here{' '}
            </Button>
            to receive it again.
          </p>
        </Row>
      </Col>
    </Row>
  );
};

export default VerifyUpdatedEmail;
