import request from 'utils/request';

export const ImSpecCaseDashboardService = {
  getCasesData: (page, status, assign, state, pageSize) => {
    const urlQuery =
      state === 'Active'
        ? `page=${page}&status=${status}&assignee=${assign}&state=${state}&page_size=${pageSize}`
        : `page=${page}&assignee=${assign}&state=${state}&page_size=${pageSize}`;
    return request('GET', `api/is/petitions?${urlQuery}`);
  },
  getSearchCasesData: (search, assign, page, pageSize) => {
    return request(
      'GET',
      `api/is/petition-cases/search?params=${search}&pageSize=${pageSize}&assignee=${assign}&page=${page}`
    );
  },
  getStatuses: assign => {
    return request('GET', `api/is/petition-statuses?assignee=${assign}`);
  },
  getStates: assign => {
    return request('GET', `api/is/petition-states?assignee=${assign}`);
  },
  getCaseSpecialists: assign => {
    return request('GET', `api/is/case-specialists/${assign}`);
  },
  handleCaseAssign: (caseID, dataForm) => {
    return request('PATCH', `api/is/case/${caseID}/assign`, dataForm);
  },
  onSearch: search => {
    return request('GET', `api/is/petition-cases/search?params=${search}&pageSize=5`);
  }
};
