import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ImSpecClientManagementActions } from 'store/Actions';

import useQuery from 'hooks/useQuery';
import FloatLabelInput from 'components/shared/FloatLabelInput';

import { Modal, Button, Form, Checkbox, Spin } from 'antd';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import FloatLabelSelect from '../../../../components/shared/FloatLabelSelect';

const InvitationModal = ({ open, setOpen, caseUser }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { search } = useQuery();
  const petitionRef = useRef(null);

  const { state } = location;

  const [form] = Form.useForm();
  const principal_case = Form.useWatch('principal_case', form);

  const {
    inviteClientFormData: { cases, petitions, principal_cases, isLoading: formLoading },
    inviteClient: { isLoading }
  } = useSelector(state => state.ImSpecClientManagement);

  const [petitionsState, setPetitionsState] = useState([]);
  const [caseDropdownProps, setCaseDropdownProps] = useState({});
  const [petitionerDropdownProps, setPetitionerDropdownProps] = useState({});

  const [checked, setChecked] = useState(false);
  const [selfPetitioningDisabled, setSelfPetitioningDisabled] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [emailErr, setEmailErr] = useState(null);

  useEffect(() => {
    if (location.state?.invitation) {
      const account_invitation =
        location.state?.invitation === 'client'
          ? true
          : location.state?.invitation === 'case'
          ? false
          : null;
      dispatch(ImSpecClientManagementActions.getCasesForInvitationForm(account_invitation));
      dispatch(ImSpecClientManagementActions.getPetitionsForInvitationForm());
    }
  }, [dispatch, location.state?.invitation]);

  useEffect(() => {
    setPetitionsState(petitions);
  }, [petitions]);

  useEffect(() => {
    if (caseDropdownProps.petitioning_type === 'universal') {
      setChecked(false);
      form.setFieldsValue({ self_petitioning: false });
    }
  }, [caseDropdownProps.petitioning_type, form]);

  const handlePetitionerAdding = value => {
    setPetitionsState(prevState => {
      let newState = [];
      if (prevState.some(e => e.id === 'newItem')) {
        newState = prevState.map(e =>
          e.id === 'newItem'
            ? {
                ...e,
                name: !!value ? value : ''
              }
            : { ...e }
        );
      } else {
        newState = [{ ['id']: 'newItem', ['name']: value }, ...prevState];
      }
      return newState;
    });
  };

  const onPetitionerSelect = (value, params) => {
    setPetitionerDropdownProps({ value, ...params });
    petitionRef.current.blur();
  };

  const handleOnChangeCaseDropdown = (value, params) => {
    !!params.is_form && form.setFieldsValue({ petitioner: null });
    if (params.available_principal_case) {
      form.resetFields(['principal_case']);
      dispatch(
        ImSpecClientManagementActions.getPrincipalCases(caseUser.key, params.visa_group, form)
      );
    }

    if (
      (caseDropdownProps.petitioning_type === 'universal' ||
        caseDropdownProps.petitioning_type === 'petitioner_required') &&
      params.petitioning_type !== 'universal' &&
      params.petitioning_type !== 'petitioner_required'
    )
      form.resetFields(['petitioner']);

    if (caseDropdownProps.is_dependent !== params.is_dependent)
      form.resetFields(['dependent_lastname', 'dependent_firstname']);

    setCaseDropdownProps({ value, ...params });
  };

  const onCheckboxChange = e => {
    setChecked(e.target.checked);
  };

  const onFieldsChange = (cur, all) => {
    cur[0].name[0] === 'email' && cur[0].touched && emailErr && setEmailErr(null);

    const fields = all.filter(x => x.name[0] !== 'is_test');

    const withoutPetitioner = fields.filter(x => x.name[0] !== 'petitioner');
    const withoutSelfPetitioning = fields.filter(x => x.name[0] !== 'self_petitioning');

    if (caseDropdownProps.petitioning_type === 'petitioner_required') {
      setSelfPetitioningDisabled(false);
      setDisabled(fields.find(e => !e.value || e.errors.length > 0));
      setChecked(false);
      form.setFieldsValue({ self_petitioning: false });
    } else if (caseDropdownProps.petitioning_type === 'universal') {
      setSelfPetitioningDisabled(false);
      if (fields.find(x => x.name[0] === 'self_petitioning')?.value) {
        setDisabled(withoutPetitioner.find(e => !e.value || e.errors.length > 0));
      } else {
        setDisabled(!!withoutSelfPetitioning.find(e => !e.value || e.errors.length > 0));
      }
    } else if (caseDropdownProps.petitioning_type === 'self_petitioning') {
      if (caseDropdownProps.available_principal_case) {
        setDisabled(
          !!withoutSelfPetitioning.find(
            e =>
              (e.name[0] === 'principal_case' && e.value === 'No principal case available') ||
              !e.value ||
              e.errors.length > 0
          )
        );
      } else {
        setDisabled(!!withoutSelfPetitioning.find(e => !e.value || e.errors.length > 0));
      }
      setChecked(true);
      form.setFieldsValue({ self_petitioning: true });
      setSelfPetitioningDisabled(true);
    } else {
      setDisabled(!!fields.find(e => !e.touched || e.errors.length > 0));
    }
  };

  const onFinish = clientInfo => {
    if (
      caseDropdownProps.petitioning_type === 'petitioner_required' ||
      (caseDropdownProps.petitioning_type === 'universal' &&
        !clientInfo.self_petitioning &&
        clientInfo.petitioner)
    ) {
      clientInfo['self_petitioning'] = false;
    }
    if (clientInfo.self_petitioning) {
      delete clientInfo['petitioner'];
    }

    if (caseDropdownProps.is_dependent) {
      clientInfo['case_option'] = 'Dependent';
    }

    dispatch(
      ImSpecClientManagementActions.onInvitationForm(
        clientInfo,
        setEmailErr,
        setOpen,
        setDisabled,
        form,
        caseUser,
        setCaseDropdownProps,
        search,
        location.state?.invitation
      )
    );
  };

  const onCancel = () => {
    dispatch(ImSpecClientManagementActions.setPrincipalCases([]));
    setOpen(false);
    emailErr && setEmailErr(null);
    setSelfPetitioningDisabled(false);
    setCaseDropdownProps({});
    setChecked(false);
    setDisabled(true);
    form.resetFields();
  };

  const NoMatchFound = () => <span>No matches found.</span>;

  return (
    <Modal
      forceRender
      title={
        state?.invitation === 'client'
          ? 'Invite a client'
          : state?.invitation === 'case'
          ? 'New Case'
          : null
      }
      closable={false}
      open={open && state?.invitation}
      className="invitationModal"
      width={414}
      footer={null}
      centered
    >
      <hr />
      {state?.invitation === 'client' ? (
        <p>Fill out the fields to invite a client.</p>
      ) : state?.invitation === 'case' ? (
        <p>
          Select the new case of <br />
          {caseUser?.beneficiary}.
        </p>
      ) : null}
      {principal_case === 'No principal case available' ? (
        <div className="note">
          <div className="note-header">
            <ExclamationCircleFilled />
            <span>Note</span>
          </div>
          <div className="note-text">
            Please create the AOS principal case first in order to open dependent AOS cases.
          </div>
        </div>
      ) : null}
      <Form
        form={form}
        name="invitationForm"
        autoComplete="off"
        className="invitationForm"
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        style={{ width: state?.invitation === 'client' ? '' : '100%' }}
      >
        <Spin spinning={formLoading} indicator={<LoadingOutlined style={{ fontSize: 40 }} />}>
          {state?.invitation === 'client' && (
            <Form.Item
              name="is_test"
              valuePropName="checked"
              className="invitationForm_testAccount"
              initialValue={false}
            >
              <Checkbox>This is a test account</Checkbox>
            </Form.Item>
          )}
          {state?.invitation === 'client' && (
            <>
              <Form.Item
                name="first_name"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: 'This field is required.'
                  },
                  {
                    max: 50,
                    message: 'Please enter a value shorter than 50 characters.'
                  },
                  {
                    pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
                    message: 'This field cannot contain numbers and special characters.'
                  }
                ]}
              >
                <FloatLabelInput label="First Name" />
              </Form.Item>
              <Form.Item
                name="last_name"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: 'This field is required.'
                  },
                  {
                    max: 50,
                    message: 'Please enter a value shorter than 50 characters.'
                  },
                  {
                    pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
                    message: 'This field cannot contain numbers and special characters.'
                  }
                ]}
              >
                <FloatLabelInput label="Last Name" />
              </Form.Item>
              <Form.Item
                name="email"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: 'This field is required.'
                  },
                  () => ({
                    validator(_, value) {
                      if (
                        !!value &&
                        !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                          value
                        )
                      ) {
                        return Promise.reject(
                          new Error('You have entered an invalid e-mail address.')
                        );
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <FloatLabelInput label="Email" />
              </Form.Item>
            </>
          )}
          {!!emailErr && (
            <span style={{ marginTop: '-16px' }} className="error">
              {emailErr}
            </span>
          )}
          <Form.Item
            name="case_access"
            rules={[
              {
                required: true,
                message: 'Please input your Case Type!'
              }
            ]}
          >
            <FloatLabelSelect
              virtual={false}
              getPopupContainer={trigger => trigger.parentNode}
              showSearch
              label="Case"
              optionFilterProp="children"
              showArrow
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onSelect={handleOnChangeCaseDropdown}
              value={caseDropdownProps.value}
              notFoundContent={<NoMatchFound />}
              popupClassName="dropdown-field-dropdown"
              options={cases.map(caseState => ({
                key: caseState.id,
                value: caseState.visa_name,
                label: caseState.visa_name,
                ...caseState
              }))}
            />
          </Form.Item>
          {caseDropdownProps.available_principal_case && !formLoading && (
            <Form.Item
              name="principal_case"
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                }
              ]}
            >
              <FloatLabelSelect
                inputRef={React.createRef()}
                virtual={false}
                getPopupContainer={trigger => trigger.parentNode}
                label="Related principal case"
                popupClassName="dropdown-field-dropdown"
                showArrow={!!principal_cases.length}
                disabled={!principal_cases.length}
                options={
                  principal_cases.length
                    ? principal_cases.map(principal_case => ({
                        key: principal_case.case_id,
                        value: principal_case.case_id,
                        label: (
                          <div className="case-details">
                            <div className="case">
                              <span>{principal_case.case_id}</span>
                              <div className="circle" />
                              <span>{principal_case.visa_name}</span>
                            </div>
                            <div className={`state ${principal_case.state}`}>
                              {principal_case.state}
                            </div>
                          </div>
                        ),
                        ...principal_case
                      }))
                    : [
                        {
                          key: 'No principal case available',
                          value: 'No principal case available',
                          label: 'No principal case available'
                        }
                      ]
                }
              />
            </Form.Item>
          )}
          {((caseDropdownProps.available_principal_case &&
            principal_cases.length &&
            !!caseDropdownProps.value &&
            caseDropdownProps.is_dependent) ||
            (!caseDropdownProps.available_principal_case &&
              !!caseDropdownProps.value &&
              caseDropdownProps.is_dependent)) &&
            !formLoading && (
              <>
                <Form.Item
                  name="dependent_firstname"
                  validateFirst
                  rules={[
                    {
                      required: true,
                      message: 'This field is required.'
                    },
                    {
                      max: 50,
                      message: 'Please enter a value shorter than 50 characters.'
                    },
                    {
                      pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
                      message: 'This field cannot contain numbers and special characters.'
                    }
                  ]}
                >
                  <FloatLabelInput label="Dependent First Name" />
                </Form.Item>
                <Form.Item
                  name="dependent_lastname"
                  validateFirst
                  rules={[
                    {
                      required: true,
                      message: 'This field is required.'
                    },
                    {
                      max: 50,
                      message: 'Please enter a value shorter than 50 characters.'
                    },
                    {
                      pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
                      message: 'This field cannot contain numbers and special characters.'
                    }
                  ]}
                >
                  <FloatLabelInput label="Dependent Last Name" />
                </Form.Item>
              </>
            )}
          {!!caseDropdownProps.value &&
            (caseDropdownProps.petitioning_type === 'petitioner_required' ||
              caseDropdownProps.petitioning_type === 'universal') && (
              <Form.Item
                name="petitioner"
                validateFirst
                help={!checked ? null : ''}
                rules={[
                  {
                    required: !checked,
                    whitespace: true,
                    message: 'This field is required.'
                  },
                  {
                    max: 50,
                    message: 'Please enter a value shorter than 50 characters.'
                  }
                ]}
              >
                <FloatLabelSelect
                  inputRef={petitionRef}
                  showSearch
                  disabled={checked}
                  popupClassName="dropdown-field-dropdown"
                  label="Petitioner"
                  allowClear
                  value={petitionerDropdownProps.value}
                  onSelect={onPetitionerSelect}
                  onSearch={handlePetitionerAdding}
                  listHeight={190}
                  options={petitionsState.map((e, index) => ({
                    key: index,
                    value: e.name,
                    label: <div className="ant-select-selection-item-label">{e.name}</div>
                  }))}
                ></FloatLabelSelect>
              </Form.Item>
            )}
          {!!caseDropdownProps.value &&
            (caseDropdownProps.petitioning_type === 'self_petitioning' ||
              caseDropdownProps.petitioning_type === 'universal') &&
            !caseDropdownProps.is_form && (
              <Form.Item
                name="self_petitioning"
                valuePropName="checked"
                style={{ marginBottom: '10px', marginTop: '-5px' }}
              >
                <Checkbox
                  disabled={selfPetitioningDisabled}
                  onChange={onCheckboxChange}
                  checked={checked}
                >
                  Self-petitioning
                </Checkbox>
              </Form.Item>
            )}
        </Spin>
        <div className="formFooter">
          <Form.Item>
            <Button key="back" onClick={onCancel}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              loading={isLoading}
              disabled={formLoading || disabled || !!emailErr}
              type={formLoading || disabled || !!emailErr ? 'disabled' : 'primary'}
              htmlType="submit"
            >
              Invite
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

InvitationModal.propTypes = {
  caseUser: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

InvitationModal.defaultProps = {
  caseUser: {},
  open: false
};

export default InvitationModal;
