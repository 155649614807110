import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import useQuery from 'hooks/useQuery';

import { Button, Form, Modal } from 'antd';
import FloatLabelInput from 'components/shared/FloatLabelInput';

import { ImSpecPetitionerDashboardAction } from 'store/Actions';

const CreatePetitionerModal = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const { search } = useQuery();

  const [disabled, setDisabled] = useState(true);
  const [nameErr, setNameErr] = useState(null);

  const [form] = Form.useForm();

  const onCancel = () => {
    form.resetFields();
    setDisabled(true);
    setNameErr(null);
    setVisible(false);
  };

  const onFieldsChange = (cur, all) => {
    cur[0].touched && nameErr && setNameErr(null);
    setDisabled(all.some(e => e.touched === false || e.errors.length > 0));
  };

  const onFinish = data => {
    dispatch(
      ImSpecPetitionerDashboardAction.onCreatePetitioner(
        data,
        form,
        setDisabled,
        setVisible,
        setNameErr,
        search
      )
    );
  };

  return (
    <Modal
      forceRender
      className="assign-modal"
      width={414}
      title="Create Petitioner"
      centered
      closable={false}
      footer={null}
      open={visible}
    >
      <hr />
      <Form
        form={form}
        onFieldsChange={onFieldsChange}
        onFinish={onFinish}
        className="add-petitioner"
      >
        <Form.Item
          name="name"
          validateFirst
          rules={[
            {
              required: true,
              message: 'This field is required.'
            }
          ]}
        >
          <FloatLabelInput label="Petitioner Name" />
        </Form.Item>
        {nameErr && <span className="error">{nameErr}</span>}
        <Form.Item className="submitting" htmlFor="submit">
          <Button onClick={onCancel} className="assign-btn cancel">
            Cancel
          </Button>
          <Button
            disabled={disabled}
            type={disabled ? 'disabled' : 'primary'}
            className="assign-btn assign"
            htmlType="submit"
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreatePetitionerModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func
};

CreatePetitionerModal.defaultProps = {
  visible: false
};
export default CreatePetitionerModal;
