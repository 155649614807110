import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import moment from 'moment';

import { Button, Col, Row, Typography } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import { HistoryLogCommentsThreadAction } from 'store/Actions';

import { getContent } from 'utils/helper';

const IndividualSyncLog = ({ logs, onPreviewUpload, count, log_uuid }) => {
  const dispatch = useDispatch();
  const { masterIntakeID, userID } = useParams();

  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const onClickShowMore = () => {
    if (showDetails) {
      setShowDetails(false);
    } else {
      masterIntakeID
        ? dispatch(
            HistoryLogCommentsThreadAction.getMasterIntakeSyncedNestedLog(
              log_uuid,
              setLoading,
              setShowDetails
            )
          )
        : dispatch(
            HistoryLogCommentsThreadAction.getSyncedNestedLogs(log_uuid, setLoading, setShowDetails)
          );
    }
  };

  const LogView = log => {
    const actualValue =
      (log.answer.field_type === 'Upload' ||
        log.answer.field_type === 'Multi Upload' ||
        log.answer.field_type === 'Checkbox') &&
      log?.actual_value
        ? JSON.parse(log?.actual_value)
        : log?.actual_value;

    let url;

    if (masterIntakeID && log.source_master_intake) {
      url = `/petitioner-dashboard/master-intake/${
        log.source_master_intake.id
      }/${log.source_master_intake.petitioner?.replaceAll(' ', '-')}`;
    }

    if (!masterIntakeID) {
      if (log.source_master_intake) {
        url = `/petitioner-dashboard/master-intake/${
          log.source_master_intake.id
        }/${log.source_master_intake.petitioner?.replaceAll(' ', '-')}`;
      }

      if (log.source_case) {
        url = `/client-management/userID=${userID}/${
          log.source_case.case_id
        }/${log.source_case.source_section?.replaceAll(' ', '-')}`;
      }
    }

    return (
      <Row key={log.id} className="sync-log-container">
        <Col style={{ width: '100%' }}>
          <Row justify="space-between" align="middle" className="sync-log-details">
            <Typography.Text className="sync-log-details-item">
              Field:{' '}
              <Link to={url} state={{ answer_id: log.source_answer_id, action: 'history_log' }}>
                <Typography.Text className="sync-log-details-item-value field">
                  {log.answer.field_placeholder || log.answer.field_name}
                </Typography.Text>
              </Link>
            </Typography.Text>
            <Typography.Text className="sync-log-details-item">
              {moment(log.created_at).format('hh:mm A, MMMM DD, YYYY')}
            </Typography.Text>
          </Row>
          <Row justify="space-between" align="middle" className="sync-log-details">
            <Typography.Text className="sync-log-details-item">
              Value provided by:{' '}
              <Typography.Text className="sync-log-details-item-value">
                {`${log.user.first_name} ${log.user.last_name}`}
              </Typography.Text>
            </Typography.Text>
          </Row>
          <Row justify="space-between" align="middle" className="sync-log-details">
            {getContent(log, actualValue, onPreviewUpload)}
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row className="content-trigger">
        {logs.length && count > 0 ? (
          <Button
            type="ghost"
            className="show-more"
            loading={loading}
            onClick={onClickShowMore}
            icon={
              showDetails ? (
                <CaretUpOutlined style={{ color: '#63A0FF' }} />
              ) : (
                <CaretDownOutlined style={{ color: '#63A0FF' }} />
              )
            }
          >
            {showDetails ? 'Hide details' : 'Show details'}
          </Button>
        ) : null}
      </Row>
      {showDetails ? <Col>{logs.map(log => LogView(log))}</Col> : null}
    </>
  );
};

IndividualSyncLog.propTypes = {
  logs: PropTypes.array,
  onPreviewUpload: PropTypes.func,
  log_uuid: PropTypes.string,
  count: PropTypes.number
};

IndividualSyncLog.defaultProps = {
  logs: [],
  count: 0
};

export default IndividualSyncLog;
