import { HistoryLogCommentsThreadTypes } from 'store/types';
import { HistoryLogCommentsThreadService, ImSpecContentService } from 'services';

// --------------------------- History Logs ---------------------------------
const getHistoryLogs = (caseID, masterIntakeID, action) => async (dispatch, getState) => {
  try {
    dispatch(setLogsLoading(true));
    const {
      HistoryLogCommentsThread: {
        sectionsFilterKey,
        historyLogs: { logsActiveTab, logs, page, count: logsCount }
      }
    } = getState();

    if (logs.length && !action) dispatch(setLogs({ count: 0, logs: [] }));

    if (logs.length && action === 'on_change_pagination')
      dispatch(setLogs({ count: logsCount, logs: [] }));

    let res;

    if (logsActiveTab === 'sync_updates') {
      res = masterIntakeID
        ? await HistoryLogCommentsThreadService.getMasterIntakeSyncedLogs(masterIntakeID, page)
        : await HistoryLogCommentsThreadService.getSyncedLogs(caseID, sectionsFilterKey, page);
    } else {
      res = masterIntakeID
        ? await HistoryLogCommentsThreadService.getMasterIntakeAllRegularLogs(
            masterIntakeID,
            logsActiveTab,
            page
          )
        : await HistoryLogCommentsThreadService.getAllRegularLogs(
            caseID,
            sectionsFilterKey,
            logsActiveTab,
            page
          );
    }

    const { count, results: resLogs } = res.data;

    dispatch(setLogs({ count, logs: [...resLogs] }));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(setLogsLoading(false));
  }
};

const getSyncedNestedLogs =
  (log_uuid, setLoading, setShowDetails) => async (dispatch, getState) => {
    try {
      setLoading(true);
      const {
        HistoryLogCommentsThread: {
          historyLogs: { logs, count }
        }
      } = getState();

      const { data } = await HistoryLogCommentsThreadService.getSyncedNestedLogs(log_uuid);

      const newLogs = logs.map(log =>
        log.log_uuid === log_uuid ? { ...log, logs: [...data] } : { ...log }
      );

      dispatch(setLogs({ count, logs: [...newLogs] }));
      setShowDetails(sh => !sh);
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

const getMasterIntakeSyncedNestedLog =
  (id, setLoading, setShowMore) => async (dispatch, getState) => {
    try {
      setLoading(true);
      const {
        HistoryLogCommentsThread: {
          historyLogs: { logs, count }
        }
      } = getState();

      const { data } = await HistoryLogCommentsThreadService.getMasterIntakeSyncedNestedLogs(id);

      const newLogs = logs.map(log => {
        if (log.log_uuid) {
          if (log.log_uuid === id) return { ...log, logs: [...data] };
        } else if (log.answer) {
          if (log.answer.id === id) return { ...log, logs: [...data] };
        }
        return { ...log };
      });

      dispatch(setLogs({ count, logs: [...newLogs] }));
      setShowMore(sh => !sh);
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

const getAllRegularNestedLogs =
  (answer_id, setLoading, setShowDetails) => async (dispatch, getState) => {
    try {
      setLoading(true);
      const {
        HistoryLogCommentsThread: {
          historyLogs: { logsActiveTab, logs, count }
        }
      } = getState();

      const { data } = await HistoryLogCommentsThreadService.getAllRegularNestedLogs(
        answer_id,
        logsActiveTab
      );

      const newLogs = logs.map(log =>
        log.answer && log.answer.id === answer_id ? { ...log, logs: [...data] } : { ...log }
      );

      dispatch(setLogs({ count, logs: [...newLogs] }));
      setShowDetails(sh => !sh);
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

const getMasterIntakeAllRegularNestedLogs =
  (answer_id, setLoading, setShowMore) => async (dispatch, getState) => {
    try {
      setLoading(true);
      const {
        HistoryLogCommentsThread: {
          historyLogs: { logsActiveTab, logs, count }
        }
      } = getState();

      const { data } = await HistoryLogCommentsThreadService.getMasterIntakeAllRegularNestedLogs(
        answer_id,
        logsActiveTab
      );

      const newLogs = logs.map(log =>
        log.answer && log.answer.id === answer_id ? { ...log, logs: [...data] } : { ...log }
      );

      dispatch(setLogs({ count, logs: [...newLogs] }));
      setShowMore(sh => !sh);
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

const getQuestionTitle = (answerID, masterIntakeID) => async dispatch => {
  try {
    dispatch(setQuestionTitleLoading(true));

    const res = masterIntakeID
      ? await HistoryLogCommentsThreadService.getMasterIntakeQuestionTitle(answerID)
      : await HistoryLogCommentsThreadService.getQuestionTitle(answerID);

    dispatch(setQuestionTitle(res.data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(setQuestionTitleLoading(false));
  }
};

const onChangeSectionsLogs = (caseID, section) => async dispatch => {
  dispatch(setLogsPage(1));
  dispatch(setSectionsFilterKey(section));
  dispatch(getHistoryLogs(caseID));
};

const onChangeLogsViewTab = (caseID, masterIntakeID, activeKey) => async dispatch => {
  dispatch(setLogsPage(1));
  dispatch(setLogsViewActiveTab(activeKey));
  dispatch(getHistoryLogs(caseID, masterIntakeID));
};

const onOpenChange = activeTabKey => async dispatch => {
  dispatch(setThreadOpen(true));
  dispatch(setActiveThreadTab(activeTabKey));
};

const onChangeLogsPage = (page, caseID, masterIntakeID) => async dispatch => {
  dispatch(setLogsPage(page));
  dispatch(getHistoryLogs(caseID, masterIntakeID, 'on_change_pagination'));
};

// ---------------------- Comments --------------------

const getCommentsTabs = (caseID, masterIntakeID, sectionTitle) => async dispatch => {
  try {
    const { data } = masterIntakeID
      ? await HistoryLogCommentsThreadService.getMasterIntakeCommentsTab(masterIntakeID)
      : await HistoryLogCommentsThreadService.getCommentsTab(caseID, sectionTitle);

    dispatch(setCommentsTab(data));
  } catch (e) {
    console.warn(e);
  }
};

const getComments = (caseID, masterIntakeID, action) => async (dispatch, getState) => {
  try {
    dispatch(setCommentsLoading(true));
    const {
      HistoryLogCommentsThread: {
        sectionsFilterKey,
        comments: { commentsActiveTab, comments, page, count: commentsCount, sentComment }
      }
    } = getState();

    if (sentComment.answerId) dispatch(setSentCommentAnswerId(null));

    if (comments.length && !action) dispatch(setComments({ comments: [], count: 0 }));

    if (comments.length && action === 'on_change_pagination')
      dispatch(setComments({ comments: [], count: commentsCount }));

    const { data } = masterIntakeID
      ? await HistoryLogCommentsThreadService.getMasterIntakeComments(
          masterIntakeID,
          page,
          commentsActiveTab
        )
      : await HistoryLogCommentsThreadService.getComments(
          caseID,
          page,
          sectionsFilterKey,
          commentsActiveTab
        );

    const { count, results: resComments } = data;

    dispatch(setComments({ count, comments: [...resComments] }));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(setCommentsLoading(false));
  }
};

const onChangeCommentsViewTab =
  (caseID, masterIntakeID, activeKey) => async (dispatch, getState) => {
    const {
      HistoryLogCommentsThread: { sectionsFilterKey }
    } = getState();

    dispatch(setCommentsPage(1));
    dispatch(setCommentsViewActiveTab(activeKey));
    dispatch(
      getCommentsTabs(
        caseID,
        masterIntakeID,
        sectionsFilterKey !== 'all' ? sectionsFilterKey : null
      )
    );
    dispatch(getComments(caseID, masterIntakeID));
  };

const onChangeSectionsComments = (caseID, masterIntakeID, section) => async dispatch => {
  await dispatch(setCommentsPage(1));
  await dispatch(setSectionsFilterKey(section));
  await dispatch(getCommentsTabs(caseID, null, section !== 'all' ? section : null));
  await dispatch(getComments(caseID, masterIntakeID));
};

const onChangeThreadTab = (activeKey, sectionTitle) => async dispatch => {
  if (activeKey === 'history_log') {
    dispatch(setCommentsViewActiveTab('unresolved'));
    dispatch(setCommentsPage(1));
    dispatch(setComments({ count: 0, comments: [] }));
  }
  if (activeKey === 'comments') {
    dispatch(setLogsViewActiveTab('all'));
    dispatch(setLogsPage(1));
    dispatch(setLogs({ count: 0, logs: [] }));
  }
  dispatch(setSectionsFilterKey(sectionTitle ?? 'all'));
  dispatch(setActiveThreadTab(activeKey));
};

const onChangeCommentsPage = (page, caseID, masterIntakeID) => async dispatch => {
  dispatch(setCommentsPage(page));
  dispatch(getComments(caseID, masterIntakeID, 'on_change_pagination'));
};

const onRemoveComment =
  (caseID, masterIntakeID, comment_id, answer_id, showMore) => async (dispatch, getState) => {
    try {
      dispatch(setDeletedComment({ id: comment_id, loading: true }));
      const {
        HistoryLogCommentsThread: {
          comments: { comments, count, commentsActiveTab }
        }
      } = getState();

      masterIntakeID
        ? await ImSpecContentService.deleteMasterIntakeComment(comment_id)
        : await ImSpecContentService.deleteComment(comment_id);

      const commentField = await comments.find(comment => comment.answer.id === answer_id);

      const { data } = masterIntakeID
        ? await HistoryLogCommentsThreadService.getMasterIntakeNestedComments(
            masterIntakeID,
            answer_id,
            commentsActiveTab
          )
        : await HistoryLogCommentsThreadService.getNestedComments(
            caseID,
            answer_id,
            commentsActiveTab
          );

      commentField.comments = showMore ? data : await data.slice(0, 2);

      const newComments = comments.map(comment =>
        comment.answer.id === answer_id
          ? { ...commentField, comments_count: data.length }
          : { ...comment }
      );

      dispatch(setComments({ count, comments: newComments }));
    } catch (e) {
      console.warn(e);
    } finally {
      dispatch(setDeletedComment({ id: null, loading: false }));
    }
  };

const onResolveComment = (answer_id, caseID, masterIntakeID, sectionTitle) => async dispatch => {
  try {
    masterIntakeID
      ? await ImSpecContentService.resolveMasterIntakeComment(answer_id)
      : await ImSpecContentService.resolveComment(answer_id);

    dispatch(setCommentsViewActiveTab('resolved'));
    dispatch(setCommentsPage(1));
    dispatch(setSectionsFilterKey(sectionTitle ?? 'all'));
    dispatch(setComments({ count: 0, comments: [] }));
    dispatch(getCommentsTabs(caseID, masterIntakeID, sectionTitle));
    dispatch(getComments(caseID, masterIntakeID));
  } catch (e) {
    console.warn(e);
  }
};

const onSubmitComment =
  (comment, form, input_ref, caseID, masterIntakeID, sectionTitle) =>
  async (dispatch, getState) => {
    try {
      dispatch(setSendCommentLoading(true));
      dispatch(setSentCommentAnswerId(comment.answer));
      const {
        HistoryLogCommentsThread: {
          comments: { comments, count, commentsActiveTab, tabs }
        }
      } = getState();

      const { data } = masterIntakeID
        ? await ImSpecContentService.saveMasterIntakeComment(comment)
        : await ImSpecContentService.saveComment(comment);

      const { created_at, id, message, resolved, user } = data;

      const commentField = await comments.find(com => com.answer.id === comment.answer);

      if (commentsActiveTab !== 'resolved') {
        commentField.comments = [
          { created_at, id, message, resolved, user },
          ...commentField.comments
        ];

        const newComments = comments.map(com =>
          com.answer.id === comment.answer
            ? { ...commentField, unresolved_comments: true, comments_count: com.comments_count + 1 }
            : { ...com }
        );

        dispatch(getCommentsTabs(caseID, masterIntakeID, sectionTitle));
        dispatch(setComments({ count, comments: newComments }));

        form.resetFields();
        input_ref && input_ref.current && input_ref.current.focus();
      }

      if (!commentField.unresolved_comments) {
        const newTabs = tabs.map(tab => {
          if (tab.status === 'Unresolved') return { ...tab, value: tab.value + 1 };
          return { ...tab };
        });

        dispatch(setCommentsTab(newTabs));
      }
      if (commentsActiveTab !== 'resolved') dispatch(setSentCommentAnswerId(null));
    } catch (e) {
      console.warn(e);
    } finally {
      dispatch(setSendCommentLoading(false));
    }
  };

const getNestedComments =
  (caseID, masterIntakeID, answer_id, showMore, setLoading, setShowMore) =>
  async (dispatch, getState) => {
    try {
      setLoading(true);
      const {
        HistoryLogCommentsThread: {
          comments: { comments, count, commentsActiveTab }
        }
      } = getState();

      if (showMore) {
        const newComments = comments.map(comment => {
          if (comment.answer.id === answer_id) {
            comment.comments = comment.comments.slice(0, 2);
          }

          return { ...comment };
        });

        dispatch(setComments({ count, comments: newComments }));
        setShowMore(false);
      } else {
        const { data } = masterIntakeID
          ? await HistoryLogCommentsThreadService.getMasterIntakeNestedComments(
              masterIntakeID,
              answer_id,
              commentsActiveTab
            )
          : await HistoryLogCommentsThreadService.getNestedComments(
              caseID,
              answer_id,
              commentsActiveTab
            );

        const newComments = comments.map(comment =>
          comment.answer && comment.answer.id === answer_id
            ? { ...comment, comments_count: data.length, comments: [...data] }
            : { ...comment }
        );

        dispatch(setComments({ count, comments: newComments }));
        setShowMore(true);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

const setSentCommentAnswerId = id => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_SENT_COMMENT_ANSWER_ID,
    payload: id
  };
};

const setSendCommentLoading = loading => {
  return {
    type: HistoryLogCommentsThreadTypes.SEND_COMMENT_LOADING,
    payload: loading
  };
};

const setDeletedComment = comment => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_DELETED_COMMENT,
    payload: { ...comment }
  };
};

const setLogsPage = page => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_LOGS_PAGE,
    payload: page
  };
};

const setLogs = logs => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_LOGS,
    payload: { ...logs }
  };
};

const setThreadOpen = open => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_OPEN,
    payload: open
  };
};

const setActiveThreadTab = key => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_ACTIVE_THREAD_TAB,
    payload: key
  };
};

const setCaseSections = sections => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_CASE_SECTIONS,
    payload: [...sections]
  };
};

const setSectionsFilterKey = key => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_SECTIONS_FILTER_KEY,
    payload: key
  };
};

const setLogsViewActiveTab = activeKey => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_LOGS_VIEW_ACTIVE_TAB,
    payload: activeKey
  };
};

const setLogsLoading = loading => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_LOGS_LOADING,
    payload: loading
  };
};

const setScrollY = y => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_SCROLL_Y,
    payload: y
  };
};

const setQuestionTitleLoading = loading => {
  return {
    type: HistoryLogCommentsThreadTypes.QUESTION_TITLE_LOADING,
    payload: loading
  };
};

const setQuestionTitle = title => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_QUESTION_TITLE,
    payload: title
  };
};

const setCommentsLoading = loading => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_COMMENTS_LOADING,
    payload: loading
  };
};

const setComments = data => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_COMMENTS,
    payload: { ...data }
  };
};

const setCommentsPage = page => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_COMMENTS_PAGE,
    payload: page
  };
};

const setCommentsViewActiveTab = activeKey => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_COMMENTS_VIEW_ACTIVE_TAB,
    payload: activeKey
  };
};

const setCommentsTab = tabs => {
  return {
    type: HistoryLogCommentsThreadTypes.SET_COMMENTS_TABS,
    payload: [...tabs]
  };
};

export const HistoryLogCommentsThreadAction = {
  onOpenChange,
  onChangeThreadTab,
  setActiveThreadTab,
  setThreadOpen,
  setCaseSections,
  setSectionsFilterKey,
  setLogsViewActiveTab,
  setLogsLoading,
  getHistoryLogs,
  getSyncedNestedLogs,
  getAllRegularNestedLogs,
  getMasterIntakeSyncedNestedLog,
  getMasterIntakeAllRegularNestedLogs,
  setLogs,
  onChangeLogsViewTab,
  onChangeSectionsLogs,
  setScrollY,
  getQuestionTitle,
  setQuestionTitle,
  onChangeLogsPage,
  setLogsPage,
  getCommentsTabs,
  getComments,
  setCommentsPage,
  setComments,
  setCommentsTab,
  setCommentsViewActiveTab,
  onChangeSectionsComments,
  onChangeCommentsViewTab,
  onChangeCommentsPage,
  onRemoveComment,
  onResolveComment,
  onSubmitComment,
  setSentCommentAnswerId,
  getNestedComments
};
