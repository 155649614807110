import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import TableNoData from './TableNoData';
import casesColumns from './columns';
import SelectComponentClass from 'components/shared/SelectComponentClass';

import { Col, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const CaseDashboardTable = ({
  page,
  pageSize,
  pageSizeOptions,
  cases,
  count,
  loadingTable,
  onClickAssign,
  onChangePage
}) => {
  const hideChildCases = useFlag('hide-child-cases');
  const { flagsReady } = useFlagsStatus();

  const columns = casesColumns({ flagsReady, hideChildCases, onClickAssign });

  const pagination = useMemo(
    () => ({
      className: 'lp-pagination',
      defaultCurrent: 1,
      defaultPageSize: 100,
      hideOnSinglePage: true,
      current: page,
      position: ['bottomCenter'],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      size: 'small',
      pageSize: pageSize,
      pageSizeOptions: pageSizeOptions,
      showSizeChanger: count > pageSize,
      total: count,
      showQuickJumper: false,
      onChange: onChangePage,
      selectComponentClass: SelectComponentClass
    }),
    [count, onChangePage, page, pageSize, pageSizeOptions]
  );

  return (
    <Col style={{ height: '100%' }}>
      <Table
        className="status-table"
        tableLayout="fixed"
        scroll={{ y: '100%' }}
        columns={columns}
        dataSource={cases}
        loading={{
          spinning: loadingTable,
          indicator: <LoadingOutlined style={{ fontSize: 30 }} />
        }}
        locale={{
          emptyText: <TableNoData />
        }}
        pagination={pagination}
      />
    </Col>
  );
};

CaseDashboardTable.propTypes = {
  cases: PropTypes.array,
  loadingTable: PropTypes.bool,
  count: PropTypes.number,
  onChangePage: PropTypes.func,
  onClickAssign: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.array
};

CaseDashboardTable.defaultProps = {
  cases: [],
  loadingTable: false,
  count: null,
  page: null
};

export default CaseDashboardTable;
