import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

const SignUpThroughInvitation = () => {
  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content resetPass">
        <Row>
          <h3>Please sign up through invitation.</h3>
        </Row>

        <Row type="flex" justify="center" align="middle" className="publicForms_content_cloud">
          <p>
            An invitation has been sent to the email address you are trying to sign up with. <br />{' '}
            Please use the link provided in the invitation.
          </p>
        </Row>

        <hr />

        <Row type="flex" justify="center" align="middle" className="publicForms_content_footer">
          <p>
            Can’t find the invitation in your inbox? <br /> <Link to="/">Click here</Link> to
            receive it again.
          </p>
        </Row>
      </Col>
    </Row>
  );
};

export default SignUpThroughInvitation;
