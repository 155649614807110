import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Col } from 'antd';
import './index.scss';

const Dashboard = () => {
  const {
    auth: { user }
  } = useSelector(state => ({ auth: state.auth }));

  return (
    <Col className="dashboard">
      <Col className="dashboard_header" type="flex" justify="center" align="top">
        <h2>Hello {user.first_name}!</h2>
        <h3>Welcome to your Legalpad Dashboard!</h3>
        <hr />
        {user.has_cases ? (
          <p>
            You can share important immigration information with us here. The priority is to get as
            much information on your case so we can assist attorneys and immigration specialists
            with building your case.
          </p>
        ) : (
          <p className="has_cases">
            You can share important information here to help us prepare your colleague’s visa or
            immigration case.
          </p>
        )}
      </Col>
      {user.has_cases && (
        <Col className="dashboard_stepsContainer" type="flex" justify="center" align="top">
          <Col>
            <div className="stepTitle">
              <div>
                <span>Step 1</span>
              </div>
            </div>
            <ul>
              <li>
                Fill and submit the questionnaires assigned to you, under Your Questionnaires: Case
              </li>
              <li>Each questionnaire takes about 30-45 minutes to complete</li>
              <li>Fill as much as you can, but don't worry if some fields are left blank</li>
              <li>
                Contact us at
                <address style={{ display: 'inline' }}>
                  <a href="mailto:onboarding@legalpad.io"> onboarding@legalpad.io </a>
                </address>
                if you have any questions during the intake process.
              </li>
            </ul>
          </Col>

          <Col>
            <div className="stepTitle">
              <div>
                <span>Step 2</span>
              </div>
            </div>
            <ul>
              <li>
                Once you have submitted your questionnaires, email us at
                <address style={{ display: 'inline' }}>
                  <a href="mailto:onboarding@legalpad.io"> onboarding@legalpad.io </a>
                </address>
                to answer any questions you may have.
              </li>
            </ul>
          </Col>

          <Col>
            <div className="stepTitle">
              <div>
                <span>Step 3</span>
              </div>
            </div>
            <ul>
              <li>
                We transfer your case to your legal team to strategize the best approach for your
                petition.{' '}
              </li>
            </ul>
          </Col>
        </Col>
      )}
      <Col type="flex" justify="center" align="top">
        <Button className="primary-btn">
          <Link to="/your-questionnaires">Get Started</Link>
        </Button>
      </Col>
    </Col>
  );
};

export default Dashboard;
