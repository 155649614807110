import { ImSpecActionTypes } from 'store/types';
import { ImSpecPetitionerPageService } from 'services';

import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

const getPetitionerCases = id => async dispatch => {
  try {
    dispatch(petitionerCasesLoading(true));

    const res = await ImSpecPetitionerPageService.getPetitionerCases(id);

    dispatch(setPetitionerCases(res.data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(petitionerCasesLoading(false));
  }
};

const getPetitioner = id => async dispatch => {
  try {
    const res = await ImSpecPetitionerPageService.getPetitioner(id);

    dispatch(setPetitionerDetails(res.data));
  } catch (e) {
    console.warn(e);
  }
};

const getPetitionerRepresentatives = id => async dispatch => {
  try {
    dispatch(representativesLoading(true));

    const res = await ImSpecPetitionerPageService.getPetitionerRepresentatives(id);

    dispatch(setRepresentatives(res.data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(representativesLoading(false));
  }
};

const getCompanyMasterIntakes = petitionerID => async dispatch => {
  try {
    const res = await ImSpecPetitionerPageService.getCompanyMasterIntakes(petitionerID);

    dispatch(setCompanyMasterIntakes(res.data));
  } catch (e) {
    console.warn(e);
  }
};

const editPetitionerName =
  (id, data, setDisabled, form, setVisible, setNameErr) => async (dispatch, getState) => {
    try {
      const {
        ImSpecPetitionerPage: { petitioner }
      } = getState();
      const res = await ImSpecPetitionerPageService.editPetitionerName(id, data);

      dispatch(setPetitionerDetails({ ...petitioner, name: res.data.name }));

      setDisabled(true);
      form.resetFields();
      setVisible(false);
    } catch (e) {
      e.data.non_field_errors ? setNameErr(e.data.non_field_errors[0]) : console.warn(e);
      setDisabled(true);
    }
  };

const attachExistingClient =
  ({ id, userID }, { navigate }, { form, setLoading }, setEmailErr) =>
  async dispatch => {
    try {
      await setLoading(true);
      const res = await ImSpecPetitionerPageService.attachExistingClients(id, userID);

      const refreshPage = async () => {
        await dispatch(getPetitionerCases(id));
        await dispatch(getPetitionerRepresentatives(id));
      };
      navigate(-1);

      Modal.info({
        width: 438,
        centered: true,
        icon: null,
        className: 'submit-section-confirm-modal congrats',
        okButtonProps: {
          className: 'submit-section-confirm-modal-submit_btn',
          loading: false
        },
        okText: 'Close',
        content: (
          <>
            <span className="submit-section-confirm-modal-content">
              Invitation successfully sent!
            </span>
            <span
              className="submit-section-confirm-modal-title"
              dangerouslySetInnerHTML={{ __html: res.data.message }}
            />
          </>
        ),
        onOk: () => refreshPage()
      });
      await form.resetFields();
    } catch (e) {
      const res = { ...e }?.data;
      res.message ? await setEmailErr(res.message) : console.warn(e);
    } finally {
      await setLoading(false);
    }
  };

const attachNewPerson =
  ({ id, data }, setEmailErr, { navigate }, { form, setLoading }) =>
  async dispatch => {
    try {
      setLoading(true);
      const res = await ImSpecPetitionerPageService.attachNewPerson(id, data);

      navigate(-1);

      const refreshPage = async () => {
        await dispatch(getPetitionerCases(id));
        await dispatch(getPetitionerRepresentatives(id));
      };

      Modal.info({
        width: 438,
        centered: true,
        icon: null,
        className: 'submit-section-confirm-modal congrats',
        okButtonProps: {
          className: 'submit-section-confirm-modal-submit_btn',
          loading: false
        },
        okText: 'Close',
        content: (
          <>
            <span className="submit-section-confirm-modal-content">
              Invitation successfully sent!
            </span>
            <span
              className="submit-section-confirm-modal-title"
              dangerouslySetInnerHTML={{ __html: res.data.message }}
            />
          </>
        ),
        onOk: () => refreshPage()
      });
      await form.resetFields();
      await setEmailErr(null);
    } catch (e) {
      const res = { ...e }?.data;
      res.message ? await setEmailErr(res.message) : console.warn(e);
    } finally {
      setLoading(false);
    }
  };

const onDetachRepresentative =
  (userID, petitionerID, visible, setDisabled, petitioner) => async dispatch => {
    try {
      setDisabled(true);
      const res = await ImSpecPetitionerPageService.detachRepresentative(userID, petitionerID);

      const refreshPage = async () => {
        await dispatch(getPetitionerCases(petitionerID));
        await dispatch(getPetitionerRepresentatives(petitionerID));
      };

      visible(false);

      Modal.info({
        width: 438,
        centered: true,
        icon: null,
        className: 'submit-section-confirm-modal congrats',
        okButtonProps: {
          className: 'submit-section-confirm-modal-submit_btn',
          loading: false
        },
        okText: 'Close',
        content: (
          <>
            <span className="submit-section-confirm-modal-content">Success!</span>
            <hr className="success-hr" />
            <span
              className="submit-section-confirm-modal-title"
              dangerouslySetInnerHTML={{ __html: res.data.message }}
            />
            <div className="reminder">
              <div>
                <ExclamationCircleFilled /> Reminder{' '}
              </div>
              <p className="reminder-text">
                Please verify with {petitioner.name} if a new representative should be assigned for
                future company intakes.
              </p>
            </div>
          </>
        ),
        onOk: () => refreshPage()
      });
    } catch (e) {
      console.warn(e);
    } finally {
      setDisabled(false);
    }
  };

const syncMasterIntake = (masterIntakeID, petitionerName) => async (dispatch, getState) => {
  try {
    const {
      ImSpecPetitionerPage: {
        companyMasterIntakes: { list }
      }
    } = getState();
    const res = await ImSpecPetitionerPageService.syncMasterIntake(masterIntakeID, petitionerName);

    const newList = list.map(intake =>
      intake.id === masterIntakeID ? { ...intake, ...res.data } : { ...intake }
    );

    dispatch(setCompanyMasterIntakes(newList));
  } catch (e) {
    const modalProps = {
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal congrats',
      okButtonProps: { className: 'submit-section-confirm-modal-submit_btn' },
      okText: 'Close',
      onOk: () => {}
    };

    const content = (
      <>
        <span className="submit-section-confirm-modal-content">Oops!</span>
        <span className="submit-section-confirm-modal-text">
          This action cannot be completed for now..
        </span>
        <span className="submit-section-confirm-modal-text">Please try again later!</span>
      </>
    );

    e.status === 403 && Modal.info({ ...modalProps, content: content });
  }
};

const setCompanyMasterIntakes = intakes => {
  return {
    type: ImSpecActionTypes.SET_COMPANY_MASTER_INTAKES,
    payload: [...intakes]
  };
};

const setPetitionerDetails = petitioner => {
  return {
    type: ImSpecActionTypes.SET_PETITIONER_DETAILS,
    payload: { ...petitioner }
  };
};

const representativesLoading = loading => {
  return {
    type: ImSpecActionTypes.REPRESENTATIVES_LOADING,
    payload: loading
  };
};

const setRepresentatives = representatives => {
  return {
    type: ImSpecActionTypes.SET_REPRESENTATIVES,
    payload: [...representatives]
  };
};

const setPetitionerCases = cases => {
  return {
    type: ImSpecActionTypes.SET_PETITIONER_CASES,
    payload: [...cases]
  };
};

const petitionerCasesLoading = loading => {
  return {
    type: ImSpecActionTypes.PETITIONER_CASES_LOADING,
    payload: loading
  };
};

export const ImSpecPetitionerPageAction = {
  getPetitionerCases,
  getPetitioner,
  getPetitionerRepresentatives,
  getCompanyMasterIntakes,
  setCompanyMasterIntakes,
  setPetitionerCases,
  setPetitionerDetails,
  setRepresentatives,
  editPetitionerName,
  attachExistingClient,
  attachNewPerson,
  onDetachRepresentative,
  syncMasterIntake
};
