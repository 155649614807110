import { ImSpecActionTypes } from '../types';
import { ImSpecClientManagementService } from 'services';
import { Modal } from 'antd';

// Invitation Actions

const getCasesForInvitationForm = account_invitation => async dispatch => {
  try {
    dispatch(inviteClientFormLoading(true));
    const { data } = await ImSpecClientManagementService.getCasesInvitationForm(account_invitation);

    dispatch(setCases(data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(inviteClientFormLoading(false));
  }
};

const getPetitionsForInvitationForm = () => async dispatch => {
  try {
    dispatch(inviteClientFormLoading(true));
    const { data } = await ImSpecClientManagementService.getPetitionsInvitationForm();

    dispatch(setPetitions(data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(inviteClientFormLoading(false));
  }
};

const getPrincipalCases = (userID, visa_name, form) => async dispatch => {
  try {
    dispatch(inviteClientFormLoading(true));
    const { data } = await ImSpecClientManagementService.getPrincipalCases(userID, visa_name);

    dispatch(setPrincipalCases(data));
    form.setFieldsValue({
      principal_case: data.length ? data[0].case_id : 'No principal case available'
    });
    form.validateFields();
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(inviteClientFormLoading(false));
  }
};

const onInvitationForm =
  (
    clientInfo,
    setEmailErr,
    setOpen,
    setDisabled,
    form,
    caseUser,
    setCaseDropdownProps,
    search,
    invitationType
  ) =>
  async (dispatch, getState) => {
    try {
      const {
        ImSpecClientManagement: {
          nestedTable: { nestedTableData }
        }
      } = getState();
      await setDisabled(true);
      dispatch(inviteClientLoading(true));

      let res;
      if (invitationType === 'client') {
        res = await ImSpecClientManagementService.inviteClient({
          ...clientInfo
        });
      } else if (invitationType === 'case') {
        res = await ImSpecClientManagementService.inviteCaseClient({ ...clientInfo }, caseUser);
      }

      dispatch(invitedClientInfo(res.data));

      if (invitationType === 'client') {
        !search
          ? dispatch(getClientManagementTableData())
          : dispatch(searchClients({ params: search }));
      } else if (invitationType === 'case') {
        dispatch(setNestedUsers([...nestedTableData, res.data]));
      }

      const title =
        invitationType === 'client'
          ? 'Invitation successfully sent!'
          : invitationType === 'case'
          ? 'Case added and assigned to Client Success!'
          : null;
      const content =
        invitationType === 'client' ? (
          <div className="successModalContent">
            An invitation to create an account with an access to{' '}
            <nobr>
              {' '}
              <b>{clientInfo.case_access}</b>{' '}
            </nobr>{' '}
            intakes has been sent to {clientInfo.email}.
            <br />
            The case has been assigned to Client Success.
          </div>
        ) : invitationType === 'case' ? (
          <div className="successModalContent">
            {caseUser.beneficiary} has been granted access to{' '}
            <nobr>
              <b>{clientInfo.case_access}</b>
            </nobr>{' '}
            intakes.
            <br />
            <br />
            An email is sent to {caseUser.email}.
          </div>
        ) : null;

      await setOpen(false);

      Modal.info({
        width: 438,
        centered: true,
        icon: null,
        title: title,
        className: 'submit-section-confirm-modal success-invitation',
        okButtonProps: {
          className: 'submit-section-confirm-modal-submit_btn',
          loading: false
        },
        okText: 'Close',
        onOk: () => form.resetFields(),
        content: content
      });

      await setEmailErr(null);
      await setCaseDropdownProps({});
    } catch (e) {
      const res = { ...e }?.data;

      invitationType === 'client'
        ? (res.email || res.url) && (await setEmailErr('Account already exists'))
        : console.warn(e);
      dispatch(inviteClientLoading(false));
    } finally {
      dispatch(inviteClientLoading(false));
    }
  };

const setPrincipalCases = cases => {
  return {
    type: ImSpecActionTypes.SET_PRINCIPAL_CASES,
    payload: cases
  };
};

const setCases = cases => {
  return {
    type: ImSpecActionTypes.SET_INVITE_CLIENT_FORM_DATA_CASES,
    payload: cases
  };
};

const setPetitions = petitions => {
  return {
    type: ImSpecActionTypes.SET_INVITE_CLIENT_FORM_DATA_PETITIONS,
    payload: petitions
  };
};

const inviteClientFormLoading = loading => {
  return {
    type: ImSpecActionTypes.INVITE_CLIENT_FORM_LOADING,
    payload: loading
  };
};

const invitedClientInfo = data => {
  return {
    type: ImSpecActionTypes.INVITED_CLIENT_INFO,
    payload: data
  };
};

const inviteClientLoading = loading => {
  return {
    type: ImSpecActionTypes.INVITE_CLIENT_LOADING,
    payload: loading
  };
};

// Table Actions

const getClientManagementTableData = () => async (dispatch, getState) => {
  try {
    dispatch(clientManagementTableDataLoading(true));

    const {
      ImSpecClientManagement: {
        content: { page, pageSize }
      }
    } = getState();

    const { data } = await ImSpecClientManagementService.getClients(page, pageSize);

    dispatch(setUsers(data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(clientManagementTableDataLoading(false));
  }
};

const clientManagementTableDataLoading = loading => {
  return {
    type: ImSpecActionTypes.CLIENTS_TABLE_LOADING,
    payload: loading
  };
};

const setUsers = users => {
  return {
    type: ImSpecActionTypes.CLIENTS_TABLE_DATA,
    payload: users
  };
};

const onChangePage = (page, pageSize, search) => async (dispatch, getState) => {
  const {
    ImSpecClientManagement: {
      content: { page: pageFromState, pageSize: pageSizeFromState }
    }
  } = getState();

  if (page !== pageFromState) dispatch(setPage(page));
  if (pageSize !== pageSizeFromState) {
    dispatch(setPage(1));
    dispatch(setPageSize(pageSize));
  }
  !search ? dispatch(getClientManagementTableData()) : dispatch(searchClients({ params: search }));
};

const setPage = page => {
  return {
    type: ImSpecActionTypes.CLIENTS_TABLE_SET_PAGE,
    payload: page
  };
};

const setPageSize = pageSize => {
  return {
    type: ImSpecActionTypes.CLIENTS_TABLE_SET_PAGE_SIZE,
    payload: pageSize
  };
};

const getClientManagementNestedTableData = userId => async dispatch => {
  try {
    dispatch(clientManagementNestedTableDataLoading(true));

    const {
      data: { cases }
    } = await ImSpecClientManagementService.getClientCases(userId);

    dispatch(setNestedUsers(cases));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(clientManagementNestedTableDataLoading(false));
  }
};

const clientManagementNestedTableDataLoading = loading => {
  return {
    type: ImSpecActionTypes.NESTED_CLIENTS_TABLE_LOADING,
    payload: loading
  };
};

const setNestedUsers = users => {
  return {
    type: ImSpecActionTypes.NESTED_CLIENTS_TABLE_DATA,
    payload: users
  };
};

const resendInvitation = (userId, search) => async dispatch => {
  try {
    dispatch(resendInvitationLoading(true));

    const { data } = await ImSpecClientManagementService.resendInvitation(userId);

    dispatch(resendInvitationLoading(false));

    const refreshPage = async () => {
      !search
        ? await dispatch(getClientManagementTableData())
        : await dispatch(searchClients({ params: search }));
      await dispatch(getClientManagementNestedTableData(userId));
    };

    Modal.info({
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal congrats',
      okButtonProps: {
        className: 'submit-section-confirm-modal-submit_btn',
        loading: false
      },
      okText: 'Close',
      content: (
        <>
          <span className="submit-section-confirm-modal-content">
            Invitation successfully sent!
          </span>
          <span
            className="submit-section-confirm-modal-title"
            dangerouslySetInnerHTML={{ __html: data.message }}
          />
        </>
      ),
      onOk: () => refreshPage()
    });
  } catch (e) {
    console.warn(e);
  }
};

const resendInvitationLoading = loading => {
  return {
    type: ImSpecActionTypes.RESEND_LOADING,
    payload: loading
  };
};

const cancelInvitation = (userId, search, expandedRowKeys) => async dispatch => {
  try {
    dispatch(cancelInvitationLoading(true));

    const { data } = await ImSpecClientManagementService.cancelInvitation(userId);

    dispatch(cancelInvitationLoading(false));

    const refreshPage = async () => {
      !search
        ? await dispatch(getClientManagementTableData())
        : await dispatch(searchClients({ params: search }));
      expandedRowKeys.length &&
        (await dispatch(getClientManagementNestedTableData(expandedRowKeys[0])));
    };

    Modal.info({
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal congrats',
      okButtonProps: {
        className: 'submit-section-confirm-modal-submit_btn',
        loading: false
      },
      okText: 'Close',
      content: (
        <>
          <span className="submit-section-confirm-modal-content">
            Invitation link successfully disabled.
          </span>
          <span
            className="submit-section-confirm-modal-title"
            dangerouslySetInnerHTML={{ __html: data.message }}
          />
        </>
      ),
      onOk: () => refreshPage()
    });
  } catch (e) {
    console.warn(e);
  }
};

const resendCaseInvitation = (caseID, userID, search) => async dispatch => {
  try {
    dispatch(resendInvitationLoading(true));

    const { data } = await ImSpecClientManagementService.resendCaseInvitation(caseID);

    dispatch(resendInvitationLoading(false));

    const refreshPage = async () => {
      !search
        ? await dispatch(getClientManagementTableData())
        : await dispatch(searchClients({ params: search }));
      await dispatch(getClientManagementNestedTableData(userID));
    };

    Modal.info({
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal congrats',
      okButtonProps: {
        className: 'submit-section-confirm-modal-submit_btn',
        loading: false
      },
      okText: 'Close',
      content: (
        <>
          <span className="submit-section-confirm-modal-content">
            Invitation successfully sent!
          </span>
          <span
            className="submit-section-confirm-modal-title"
            dangerouslySetInnerHTML={{ __html: data.message }}
          />
        </>
      ),
      onOk: () => refreshPage()
    });
  } catch (e) {
    console.warn(e);
  }
};

const cancelCaseInvitation = (caseID, userID, search, destroy) => async dispatch => {
  try {
    dispatch(cancelInvitationLoading(true));

    const { data } = await ImSpecClientManagementService.cancelCaseInvitation(caseID, { destroy });

    dispatch(cancelInvitationLoading(false));

    const refreshPage = async () => {
      !search
        ? await dispatch(getClientManagementTableData())
        : await dispatch(searchClients({ params: search }));
      await dispatch(getClientManagementNestedTableData(userID));
    };

    Modal.info({
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal congrats',
      okButtonProps: {
        className: 'submit-section-confirm-modal-submit_btn',
        loading: false
      },
      okText: 'Close',
      content: (
        <>
          <span className="submit-section-confirm-modal-content">Case invitation canceled!</span>
          <span
            className="submit-section-confirm-modal-title"
            dangerouslySetInnerHTML={{ __html: data.message }}
          />
        </>
      ),
      onOk: () => refreshPage()
    });
  } catch (e) {
    console.warn(e);
  }
};

const searchClients = data => async (dispatch, getState) => {
  try {
    dispatch(clientManagementTableDataLoading(true));
    const {
      ImSpecClientManagement: {
        content: { page, pageSize }
      }
    } = getState();

    const res = await ImSpecClientManagementService.searchClients(page, pageSize, data);

    dispatch(setUsers(res.data[0]));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(clientManagementTableDataLoading(false));
  }
};

const cancelInvitationLoading = loading => {
  return {
    type: ImSpecActionTypes.CANCEL_LOADING,
    payload: loading
  };
};

export const ImSpecClientManagementActions = {
  getCasesForInvitationForm,
  getPetitionsForInvitationForm,
  onInvitationForm,
  getClientManagementTableData,
  onChangePage,
  setPage,
  getClientManagementNestedTableData,
  resendInvitation,
  cancelInvitation,
  resendCaseInvitation,
  cancelCaseInvitation,
  searchClients,
  getPrincipalCases,
  setPrincipalCases
};
