import { useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import moment from 'moment';

import { Avatar, Button, Col, Row, Steps, Typography } from 'antd';

import { CaretDownOutlined, CaretUpOutlined, CheckOutlined } from '@ant-design/icons';
import { removeIcon } from 'components/Icons/Icons';

import { HistoryLogCommentsThreadAction } from 'store/Actions';

const CommentContent = ({ comments, answer_id, count, deletedComment, user }) => {
  const dispatch = useDispatch();
  const { masterIntakeID, caseID } = useParams();

  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const onRemoveComment = useCallback(
    comment_id => () => {
      dispatch(
        HistoryLogCommentsThreadAction.onRemoveComment(
          caseID,
          masterIntakeID,
          comment_id,
          answer_id,
          showMore
        )
      );
    },
    [answer_id, caseID, dispatch, masterIntakeID, showMore]
  );

  const onClickShowMore = () => {
    dispatch(
      HistoryLogCommentsThreadAction.getNestedComments(
        caseID,
        masterIntakeID,
        answer_id,
        showMore,
        setLoading,
        setShowMore
      )
    );
  };

  const getIcon = comment => {
    return (
      <Avatar
        size={27}
        style={{ background: comment.user.role.name === 'customer' ? '#63A0FF' : '#33B18A' }}
      >
        {(comment.user?.first_name?.charAt(0) + comment.user?.last_name?.charAt(0)).toUpperCase()}
      </Avatar>
    );
  };

  const description = useCallback(
    comment => {
      return (
        <Col className="timeline-log-container">
          <Row justify="space-between" align="middle" className="timeline-log-container-header">
            <Typography.Text strong className="user-details">
              {`${comment.user.first_name} ${comment.user.last_name}`}
            </Typography.Text>
            <Typography.Text className="log-created-at">
              {moment(comment.created_at).format('hh:mm A, MMMM DD, YYYY')}
            </Typography.Text>
          </Row>
          <div className="timeline-log-container-content">
            <Row justify="space-between">
              <Typography.Paragraph
                className="log-actual-value"
                ellipsis={{ rows: 2, expandable: true }}
              >
                {comment.message}
              </Typography.Paragraph>
              {comment.resolved ? (
                <CheckOutlined style={{ fontSize: 24, color: '#319E7A' }} />
              ) : null}
              {!comment.resolved && comment.user.id === user.id ? (
                <Button
                  className="remove-comment-btn"
                  type="ghost"
                  icon={removeIcon}
                  loading={deletedComment.commentId === comment.id && deletedComment.isLoading}
                  onClick={onRemoveComment(comment.id)}
                />
              ) : null}
            </Row>
          </div>
        </Col>
      );
    },
    [deletedComment.commentId, deletedComment.isLoading, onRemoveComment, user.id]
  );

  const items = useMemo(() => {
    if (!comments.length) return comments;
    const lessComments = comments.slice(0, 2);

    return !showMore
      ? lessComments.map(comment => {
          return {
            icon: getIcon(comment),
            description: description(comment)
          };
        })
      : comments.map(comment => {
          return {
            icon: getIcon(comment),
            description: description(comment)
          };
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments, description, showMore, comments.length, deletedComment]);

  return (
    <div className="comment-content">
      <Steps rootClassName="comment-steps" direction="vertical" items={items} />
      {count > 2 ? (
        <Button
          type="ghost"
          className="show-more"
          onClick={onClickShowMore}
          loading={loading}
          icon={
            showMore ? (
              <CaretUpOutlined style={{ color: '#63A0FF' }} />
            ) : (
              <CaretDownOutlined style={{ color: '#63A0FF' }} />
            )
          }
        >
          {showMore ? 'Hide' : 'Show all'}
        </Button>
      ) : null}
    </div>
  );
};

CommentContent.propTypes = {
  comments: PropTypes.array,
  answer_id: PropTypes.number,
  deletedComment: PropTypes.object,
  user: PropTypes.object,
  count: PropTypes.number
};

CommentContent.defaultProps = {
  comments: [],
  count: 0
};

export default CommentContent;
