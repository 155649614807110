import { useLayoutEffect } from 'react';

const useOutsideClicker = (refs, callBack) => {
  useLayoutEffect(() => {
    const handleClickOutside = event => {
      if (
        refs.some(ref => ref.current) &&
        refs.every(ref => ref.current && !ref.current.contains(event.target))
      ) {
        callBack();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callBack, refs]);
};

export default useOutsideClicker;
