import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecPetitionerPageAction } from 'store/Actions';

// components
import AssociatedAccounts from 'pages/ImSpecPages/SectionDashboard/elements/AssociatedAccounts';
import PetitionerPageTop from 'pages/ImSpecPages/PetitionerPage/elements/PetitionerPageTop';
import PetitionerPageTable from 'pages/ImSpecPages/PetitionerPage/elements/PetitionerPageTable';
import EditPetitionerNameModal from 'pages/ImSpecPages/PetitionerPage/elements/EditPetitionerNameModal';
import InviteRepresentative from 'pages/ImSpecPages/PetitionerPage/elements/InviteRepresentative';
import DetachRepresentativeModal from 'pages/ImSpecPages/PetitionerPage/elements/DetachRepresentativeModal';
import CompanyMasterIntake from 'pages/ImSpecPages/PetitionerPage/elements/CompanyMasterIntake';

import { Button, Col, Collapse, Dropdown, Row, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const PetitionerPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { petitionerID } = useParams();

  const {
    cases,
    petitioner,
    representatives: { list },
    companyMasterIntakes
  } = useSelector(state => state.ImSpecPetitionerPage);

  const [activeKey, setActiveKey] = useState([]);
  const [visible, setVisible] = useState(false);
  const [detachVisible, setDetachVisible] = useState(false);
  const [disabledDetach, setDisabledDetach] = useState(false);
  const [entryRep, setEntryRep] = useState(null);

  useEffect(() => {
    if (!list.length) setActiveKey([]);
  }, [list.length]);

  useEffect(() => {
    const getInitialData = async () => {
      await dispatch(
        ImSpecPetitionerPageAction.getPetitionerRepresentatives(petitionerID, navigate)
      );
      await dispatch(ImSpecPetitionerPageAction.getPetitioner(petitionerID, navigate));
      await dispatch(ImSpecPetitionerPageAction.getPetitionerCases(petitionerID, navigate));
    };

    petitionerID && getInitialData();

    return () => {
      dispatch(ImSpecPetitionerPageAction.setPetitionerCases([]));
      dispatch(ImSpecPetitionerPageAction.setPetitionerDetails({}));
      dispatch(ImSpecPetitionerPageAction.setRepresentatives([]));
      dispatch(ImSpecPetitionerPageAction.setCompanyMasterIntakes([]));
    };
  }, [dispatch, navigate, petitionerID]);

  useEffect(() => {
    dispatch(ImSpecPetitionerPageAction.getCompanyMasterIntakes(petitionerID));
  }, [dispatch, petitionerID]);

  const onTitleClick = () => setVisible(true);

  const onClickForChooseAccount = ({ key }) => {
    navigate(`${location.pathname}?action=${key}`);
  };

  const onChangeCollapse = key => setActiveKey(key);

  const menu = {
    className: 'associated_accounts-menu',
    selectable: false,
    onClick: onClickForChooseAccount,
    items: [
      { label: 'Invite New Person', key: 'invite-new-person' },
      {
        label: 'Choose from existing clients',
        key: 'choose-from-existing-clients'
      }
    ]
  };

  const stopProp = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const CollapseHeader = () => (
    <Row className="collapse-accounts">
      <span className="title">Representatives</span>
      <Row onClick={stopProp} align="middle" justify="center" style={{ cursor: 'pointer' }}>
        <Dropdown
          disabled={!!list.length}
          overlayClassName="associated_accounts-dropdown"
          className="associated_accounts-dropdown"
          placement="top"
          getPopupContainer={triggerNode => triggerNode.parentNode}
          menu={menu}
          trigger="click"
          arrow={false}
        >
          <Tooltip
            title={!!list.length ? 'Only 1 Rep can be attached to Petitioner.' : ''}
            overlayClassName="migrate-tooltip"
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            <Button
              disabled={!!list.length}
              className="associated_accounts-btn"
              icon={<PlusOutlined style={{ fontSize: '20px', color: '#FFF' }} />}
            />
          </Tooltip>
        </Dropdown>
      </Row>
    </Row>
  );

  const onOpenDetach = user => {
    setEntryRep(user);
    setDetachVisible(visible => !visible);
  };

  const onCancelDetach = () => {
    setEntryRep(null);
    setDetachVisible(visible => !visible);
  };

  const onDetach = () => {
    dispatch(
      ImSpecPetitionerPageAction.onDetachRepresentative(
        entryRep.id,
        petitioner.id,
        setDetachVisible,
        setDisabledDetach,
        petitioner
      )
    );
  };

  return (
    <Col className="dataWrapper petitioner-page">
      <Col className="wrapper">
        <PetitionerPageTop onTitleClick={onTitleClick} petitioner={petitioner} />
        <CompanyMasterIntake companyMasterIntakes={companyMasterIntakes} />
        <Col className="section-associated_accounts">
          <Collapse
            onChange={onChangeCollapse}
            activeKey={activeKey}
            collapsible={!list.length && 'disabled'}
            className="collapse-associated"
          >
            <Panel key="1" header={<CollapseHeader />}>
              <Row gutter={[28, 28]} className="account-block">
                {list.map(account => (
                  <AssociatedAccounts
                    key={account.id}
                    account={account}
                    permission="representatives"
                    onDetach={onOpenDetach}
                  />
                ))}
              </Row>
            </Panel>
          </Collapse>
        </Col>
        <PetitionerPageTable cases={cases} />
        <InviteRepresentative />
        <DetachRepresentativeModal
          onCancel={onCancelDetach}
          onDetach={onDetach}
          visible={detachVisible}
          user={entryRep}
          petitioner={petitioner}
          disabled={disabledDetach}
        />
        <EditPetitionerNameModal
          visible={visible}
          setVisible={setVisible}
          petitioner={petitioner}
        />
      </Col>
    </Col>
  );
};

export default PetitionerPage;
