import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import FloatLabelInput from 'components/shared/FloatLabelInput';

const Email = ({
  setRequiredErrorClassName,
  curSubsection,
  answersDataState,
  answers,
  curField,
  iterationName,
  questionSet,
  setSavingValue,
  setInputError,
  inputError,
  setShowRequiredError,
  showRequiredError,
  onFocus,
  onBlur,
  radioDisable,
  inputRef,
  sync_info
}) => {
  const [floatingValue, setFloatingValue] = useState('');

  useEffect(() => {
    if (!!answersDataState && Object.keys(answersDataState).length) {
      setFloatingValue(answersDataState[curField.name]?.field_value);
    }
  }, [answersDataState, curField]);

  useEffect(() => {
    setRequiredErrorClassName(
      curField.is_required && showRequiredError && !floatingValue ? 'required-error-block' : ''
    );
  }, [curField.is_required, floatingValue, setRequiredErrorClassName, showRequiredError]);

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;

    setFloatingValue(value);
    const formData = new FormData();
    formData.append('field_name', name);
    formData.append('field_value', value);
    formData.append('field_type', type);
    formData.append('group_name', !!iterationName ? iterationName : '');
    questionSet &&
      !questionSet.startsWith('uId') &&
      formData.append(
        'iteration_key',
        !!answers[iterationName]?.field_value || !questionSet.startsWith('uId')
          ? answers[iterationName]?.field_value[questionSet][name]?.iteration_key
          : null
      );
    setSavingValue(formData);

    const hasValidEmail =
      !!value &&
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      );
    setInputError({
      ...inputError,
      hasValidEmail
    });

    curField.is_required && !value.trim().length
      ? setShowRequiredError(true)
      : setShowRequiredError(false);
  };

  const onChange = e => handleOnChange(e, curField.type.type);

  return (
    <div>
      <FloatLabelInput
        id={curField?.id}
        name={curField?.name}
        label={`${curField?.placeholder}${curField.is_required ? '*' : ''}`}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={floatingValue || ''}
        disabled={sync_info.sync_in_progress || curSubsection?.is_blocked || radioDisable}
        inputRef={inputRef}
      />
    </div>
  );
};

Email.propTypes = {
  setRequiredErrorClassName: PropTypes.func,
  curSubsection: PropTypes.object,
  answersDataState: PropTypes.object,
  answers: PropTypes.object,
  curField: PropTypes.object,
  iterationName: PropTypes.string,
  questionSet: PropTypes.string,
  setSavingValue: PropTypes.func,
  setInputError: PropTypes.func,
  inputError: PropTypes.object,
  setShowRequiredError: PropTypes.func,
  showRequiredError: PropTypes.bool,
  radioDisable: PropTypes.bool,
  inputRef: PropTypes.object,
  sync_info: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

Email.defaultProps = {
  curSubsection: {},
  answersDataState: {},
  answers: {},
  curField: {},
  iterationName: '',
  questionSet: '',
  inputError: {},
  showRequiredError: false,
  radioDisable: false,
  inputRef: {},
  sync_info: {}
};

export default Email;
