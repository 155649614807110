import React, { useState } from 'react';
import { Col } from 'antd';

// components
import ClientManagementTop from './elements/ClientManagementTop';
import ClientManagementTable from './elements/ClientManagementTable';
import InvitationModal from './elements/InvitationModal';

import './index.scss';

const ClientManagement = () => {
  const [caseUser, setCaseUser] = useState({});
  const [open, setOpen] = useState(false);

  return (
    <Col className="dataWrapper client-management">
      <Col className="wrapper">
        <ClientManagementTop setOpen={setOpen} />
        <ClientManagementTable caseUser={caseUser} setCaseUser={setCaseUser} setOpen={setOpen} />
      </Col>
      <InvitationModal caseUser={caseUser} open={open} setOpen={setOpen} />
    </Col>
  );
};

export default ClientManagement;
