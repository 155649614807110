import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

import FloatLabelInput from 'components/shared/FloatLabelInput';

import FloatLabelSelect from 'components/shared/FloatLabelSelect';
import { Modal, Form, Button, Checkbox, Spin } from 'antd';

import { debounce } from 'utils/helper';

import { ImSpecSectionDashboardActions } from 'store/Actions';

import { LoadingOutlined } from '@ant-design/icons';

const InviteOwner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { caseID } = useParams();
  const { action } = useQuery();

  const {
    case: { list },
    searchedUsers: { isLoading, list: userOptions, hasData }
  } = useSelector(state => state.ImSpecSectionDashboard);

  const [form] = Form.useForm();
  const clientRef = useRef(null);

  const [disabled, setDisabled] = useState(true);
  const [emailErr, setEmailErr] = useState(null);

  const visible = /(invite-new-person|choose-from-existing-clients)/.test(action);

  const onCancel = () => {
    form.resetFields();
    setDisabled(true);
    setEmailErr(null);
    navigate(-1);
  };

  const options = list.map(({ section_name, section_id, owner }) => ({
    label: section_name,
    value: section_id,
    disabled: owner.is_representative
  }));

  const onFinish = values => {
    const petition_sections = list.map(({ section_id, section_name }) => ({
      selected: values.petition_sections.includes(section_id),
      section_name,
      section_id
    }));
    const data = { ...values, petition_sections, case_id: caseID };

    if (action === 'choose-from-existing-clients')
      dispatch(
        ImSpecSectionDashboardActions.inviteExistingClient(
          data,
          { navigate },
          { form, setDisabled }
        )
      );

    if (action === 'invite-new-person')
      dispatch(
        ImSpecSectionDashboardActions.inviteNewPerson(
          data,
          setEmailErr,
          { navigate },
          { form, setDisabled }
        )
      );
  };

  const onFieldsChange = (cur, all) => {
    cur[0].name[0] === 'email' && cur[0].touched && emailErr && setEmailErr(null);

    const fields = all.filter(x => x.name[0] !== 'is_test');

    setDisabled(fields.some(e => e.touched === false || e.errors.length > 0));
  };

  const onSearchUsers = value => {
    dispatch(ImSpecSectionDashboardActions.searchUsersForInvite({ params: value }));
  };

  const onSelect = () => {
    if (clientRef && clientRef.current) {
      clientRef.current.blur();
    }
  };

  const NoMatchFound = () => <span>No matches found.</span>;

  return (
    <Modal
      forceRender
      title={
        action === 'invite-new-person'
          ? 'Invite New Person'
          : action === 'choose-from-existing-clients'
          ? 'Invite Existing Client'
          : null
      }
      closable={false}
      open={visible}
      className="invitationModal"
      width={414}
      footer={null}
      centered
    >
      <hr />
      {action === 'choose-from-existing-clients' ? (
        <p>Choose a client to invite as an Associated account.</p>
      ) : action === 'invite-new-person' ? (
        <p>Fill out the fields to invite an Associated account.</p>
      ) : null}
      <Form
        className="invite-owner"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
      >
        {action === 'invite-new-person' && (
          <Form.Item
            name="is_test"
            valuePropName="checked"
            className="invitationForm_testAccount"
            initialValue={false}
          >
            <Checkbox>This is a test account</Checkbox>
          </Form.Item>
        )}
        {action === 'invite-new-person' && (
          <>
            <Form.Item
              name="first_name"
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                },
                {
                  max: 50,
                  message: 'Please enter a value shorter than 50 characters.'
                },
                {
                  pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
                  message: 'This field cannot contain numbers and special characters.'
                }
              ]}
            >
              <FloatLabelInput label="First Name" />
            </Form.Item>
            <Form.Item
              name="last_name"
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                },
                {
                  max: 50,
                  message: 'Please enter a value shorter than 50 characters.'
                },
                {
                  pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
                  message: 'This field cannot contain numbers and special characters.'
                }
              ]}
            >
              <FloatLabelInput label="Last Name" />
            </Form.Item>
            <Form.Item
              name="email"
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                },
                () => ({
                  validator(_, value) {
                    if (
                      !!value &&
                      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        value
                      )
                    ) {
                      return Promise.reject(
                        new Error('You have entered an invalid e-mail address.')
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <FloatLabelInput label="Email Address" />
            </Form.Item>
          </>
        )}
        {!!emailErr && <span className="error">{emailErr}</span>}
        {action === 'choose-from-existing-clients' && (
          <Form.Item name="client">
            <FloatLabelSelect
              inputRef={clientRef}
              virtual={false}
              popupClassName="dropdown-field-dropdown"
              showSearch
              label="Client"
              autoClearSearchValue={false}
              filterOption={false}
              onSelect={onSelect}
              onSearch={debounce(onSearchUsers, 500)}
              notFoundContent={
                isLoading && hasData === null ? (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
                ) : !isLoading && hasData === false ? (
                  <NoMatchFound />
                ) : null
              }
              options={userOptions}
            />
          </Form.Item>
        )}
        <Form.Item
          className="sections"
          name="petition_sections"
          label="Intake sections to be assigned:"
          rules={[
            {
              required: true,
              message: 'This field is required.'
            }
          ]}
        >
          <Checkbox.Group options={options} />
        </Form.Item>
        <div className="formFooter">
          <Form.Item>
            <Button key="back" onClick={onCancel}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              disabled={disabled || !!emailErr}
              type={disabled || !!emailErr ? 'disabled' : 'primary'}
              htmlType="submit"
            >
              Invite
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default InviteOwner;
