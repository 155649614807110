import { SectionsAndSubsections } from 'utils/constants';
import moment from 'moment/moment';

import { browserName } from 'react-device-detect';

import { Button, Tooltip, Typography } from 'antd';
import { FileTextFilled } from '@ant-design/icons';

export const debounce = (funcCB, delay = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => funcCB.apply(this, args), delay);
  };
};

export const downloadOrView = (browserName, extension) => {
  if (
    browserName === 'Chrome' ||
    browserName === 'Firefox' ||
    browserName === 'Safari' ||
    browserName === 'Yandex' ||
    browserName === 'Opera'
  ) {
    return /^(.pdf|.jpeg|.jpg|.png)$/i.test(extension) ? 'View' : 'Download';
  }
  if (browserName === 'Edge') {
    return /^(.pdf|.jpeg|.jpg|.png|.xlsx)$/i.test(extension) ? 'View' : 'Download';
  }
  return 'Download';
};

export const sectionAndSubsectionNames = name =>
  SectionsAndSubsections[name] ?? name.replaceAll('-', ' ');

export const filterNameHelper = name => name.replaceAll(' ', '_').toLowerCase();

export const filterNameHelperRemoveLine = name => name.replaceAll('_', ' ').toLowerCase();

export const capitalizeFirstLetter = str => {
  if (str === 'case id') {
    return 'Case ID';
  }
  if (str === 'im spec') {
    return 'IS';
  }
  // Split the string into an array of words
  let words = str.split(' ');

  // Iterate over each word in the array
  for (let i = 0; i < words.length; i++) {
    // Capitalize the first letter of the word and convert the rest of the word to lowercase
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }

  // Join the words back into a string and return it
  return words.join(' ');
};

// FILTER HELPERS !!!!!!!
export const filterNameHelperWithCapitalize = name =>
  capitalizeFirstLetter(name?.replaceAll('_', ' '));

export const orderListByKey = (data, keys, order) => {
  const compareValues =
    (key, order = 'asc') =>
    (elemA, elemB) => {
      if (
        !keys.some(key => Object.hasOwn(elemA, key)) ||
        !keys.some(key => Object.hasOwn(elemB, key))
      )
        return 0;
      const elemAText = keys.map(key => elemA[key]).join(' ');
      const elemBText = keys.map(key => elemB[key]).join(' ');

      const comparison = elemAText.localeCompare(elemBText);

      return order === 'desc' ? comparison * -1 : comparison;
    };
  return data.sort(compareValues(keys, order));
};

export const isFilterAnswersEmpty = filterAnswers => {
  for (const key in filterAnswers) {
    if (Array.isArray(filterAnswers[key]) && filterAnswers[key].length > 0) {
      return false;
    } else if (
      typeof filterAnswers[key] === 'object' &&
      Object.keys(filterAnswers[key]).length > 0 &&
      filterAnswers[key]?.options?.length > 0
    ) {
      return false;
    } else if (
      typeof filterAnswers[key] === 'object' &&
      key.startsWith('last_') &&
      Object.keys(filterAnswers[key]).length > 0
    ) {
      return false;
    } else if (filterAnswers.include_test_cases) {
      return false;
    }
  }
  return true;
};

//filter data format to string
export const formatData = value => {
  if (typeof value == 'boolean') {
    return `${value}`;
  } else if (Array.isArray(value)) {
    return value.join(', ');
  } else if (typeof value === 'object' && value !== null) {
    if (Object.prototype.toString.call(value) === '[object Object]') {
      if ('options' in value || 'im_spec_ids' in value) {
        const imSpecNames = value.im_spec_ids?.map(item => `${item.first_name} ${item.last_name}`);
        return `${filterNameHelperWithCapitalize(value.options?.join(', '))} ${
          imSpecNames ? `(${imSpecNames.join(', ')})` : ''
        }`;
      } else if ('options' in value || 'attorney_ids' in value) {
        const attorneyNames = value.attorney_ids?.map(
          item => `${item.first_name} ${item.last_name}`
        );
        return `${filterNameHelperWithCapitalize(value.options?.join(', '))} ${
          attorneyNames ? `(${attorneyNames.join(', ')})` : ''
        }`;
      } else if ('options' in value || 'companies' in value) {
        const petitionerNames = value.companies?.map(item => item.name);
        return `${filterNameHelperWithCapitalize(value.options?.join(', '))} ${
          petitionerNames ? `(${petitionerNames.join(', ')})` : ''
        }`;
      } else if ('case_id' in value) {
        const caseIds = value.case_id.map(item => item.case_id);
        return caseIds.join(', ');
      } else if ('visa_name' in value) {
        return value.visa_name;
      } else if ('last_hour' in value) {
        return `${capitalizeFirstLetter(value.last_hour)} last hour`;
      } else if ('last_24_hour' in value) {
        return `${capitalizeFirstLetter(value.last_24_hour)} last 24 hours`;
      } else if ('last_month' in value) {
        return `${capitalizeFirstLetter(value.last_month)} last month`;
      } else if ('last_week' in value) {
        return `${capitalizeFirstLetter(value.last_week)} last week`;
      } else if ('last_6_month' in value) {
        return `${capitalizeFirstLetter(value.last_6_month)} last 6 months`;
      } else if ('custom' in value && 'start_date' in value.custom && 'end_date' in value.custom) {
        const startDate = moment(value.custom.start_date).format('DD/MM/YYYY');
        const endDate = moment(value.custom.end_date).format('DD/MM/YYYY');
        return `${startDate} - ${endDate}`;
      }
    }
  }

  return value;
};

export const shouldRenderValue = value => {
  return (
    (Array.isArray(value) && value.length > 0) ||
    (typeof value === 'string' && value.trim() !== '') ||
    (typeof value === 'number' && !isNaN(value))
  );
};

export const countNonEmpty = data => {
  const excludedKeys = new Set([
    'state',
    'case_type',
    'petitioner',
    'last_submitted',
    'last_edited',
    'last_reviewed',
    'last_commented',
    'last_created'
  ]);

  let count = 0;

  for (const key in data) {
    if (!excludedKeys.has(key)) {
      const value = data[key];

      if (Array.isArray(value) && value?.length > 0) {
        count++;
      } else if (
        typeof value === 'object' &&
        Object.keys(value)?.length > 0 &&
        (key !== 'im_spec' || value?.options?.length > 0) && // Check for non-empty options array in im_spec
        (key !== 'attorney' || value?.options?.length > 0) // Check for non-empty options array in attorney
      ) {
        count++;
      }
    }
  }

  if (data?.include_test_cases) {
    count++;
  }

  return count;
};

export const getContent = (log, actualValue, onPreviewUpload) => {
  if (!actualValue)
    return (
      <Typography.Paragraph className="emptied-field">Emptied the field.</Typography.Paragraph>
    );

  if (log.answer.field_type === 'Upload' || log.answer.field_type === 'Multi Upload')
    return (
      <div className="uploaded-files-logs">
        <div className="uploaded-files-logs-items">
          {actualValue.map(({ original_name, id, extension }, index) => (
            <div key={index + 1} className="uploaded-files-logs-items-log">
              <div className="uploaded-files-logs-items-log-icon">{<FileTextFilled />}</div>
              <div className="uploaded-files-logs-items-log-title">
                <Tooltip
                  overlayClassName="upload-title-tooltip"
                  title={original_name}
                  placement="top"
                >
                  <span>{original_name}</span>
                </Tooltip>
              </div>
              <Button
                type="ghost"
                onClick={() => onPreviewUpload(id)}
                className="uploaded-files-logs-items-log-downloadBtn"
              >
                {downloadOrView(browserName, extension)}
              </Button>
            </div>
          ))}
        </div>
        <div className={`uploaded-files-logs-action ${log.action}`}>{log.action}</div>
      </div>
    );

  if (log.answer.field_type === 'Checkbox')
    return (
      <div className="uploaded-files-logs multi-select-logs">
        <div className="uploaded-files-logs-items">
          {actualValue.map((elem, index) => {
            return (
              <Typography.Paragraph className="log-actual-value" key={index}>
                {elem}
              </Typography.Paragraph>
            );
          })}
        </div>
        <div className={`multi-select-logs-action ${log.action}`}>{log.action}</div>
      </div>
    );

  return (
    <Typography.Paragraph className="log-actual-value" ellipsis={{ rows: 2, expandable: true }}>
      {actualValue}
    </Typography.Paragraph>
  );
};
