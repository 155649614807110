import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import moment from 'moment/moment';

import QuestionTooltipContent from 'components/HistoryLogCommentsThread/QuestionTooltipContent';

import { Button, Col, Row, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { HistoryLogCommentsThreadAction } from 'store/Actions';

const HistoryLogManualContent = ({ log, url, question }) => {
  const dispatch = useDispatch();
  const { masterIntakeID } = useParams();

  const onOpenChange = answerID => open => {
    open
      ? dispatch(HistoryLogCommentsThreadAction.getQuestionTitle(answerID, masterIntakeID))
      : dispatch(HistoryLogCommentsThreadAction.setQuestionTitle(null));
  };

  return (
    <Col className="history-log-header-container">
      <Row justify="space-between" align="middle" className="history-log-header-title">
        <Col>
          <Row align="middle">
            <Link to={url} state={{ answer_id: log.answer.id, action: 'history_log' }}>
              <Typography.Title level={5}>
                {log.answer.field_placeholder || log.answer.field_name}
              </Typography.Title>
            </Link>
            <Tooltip
              overlayClassName="history-log-question-title"
              getPopupContainer={triggerNode =>
                triggerNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
                  .parentNode.parentNode.parentNode
              }
              placement="top"
              title={<QuestionTooltipContent question={question} />}
              onOpenChange={onOpenChange(log.answer.id)}
              trigger="click"
              color="#FFFFFF"
            >
              <Button
                type="ghost"
                className="question-info-icon"
                icon={<InfoCircleOutlined style={{ color: '#63A0FF', fontSize: '14px' }} />}
              />
            </Tooltip>
          </Row>
        </Col>
        <Col>
          <Row align="middle">
            <Typography.Title className="last-update" level={5}>
              {moment(log.created_at).format('hh:mm A, MMMM DD, YYYY')}
            </Typography.Title>
          </Row>
        </Col>
      </Row>
      <Row className="history-log-header-description">
        <Col>
          {log.target_case && log.target_case.section_name ? (
            <Row align="middle">
              <Typography.Text className="history-log-header-description-item">
                Section:{' '}
                <Typography.Text className="history-log-header-description-item-value">
                  {log.target_case.section_name}
                </Typography.Text>
              </Typography.Text>
            </Row>
          ) : null}
          {log.target_case && log.target_case.subsection_name ? (
            <Row align="middle">
              <Typography.Text className="history-log-header-description-item">
                Subsection:{' '}
                <Typography.Text className="history-log-header-description-item-value">
                  {log.target_case.subsection_name}
                </Typography.Text>
              </Typography.Text>
            </Row>
          ) : null}
        </Col>
      </Row>
    </Col>
  );
};

HistoryLogManualContent.propTypes = {
  log: PropTypes.object,
  url: PropTypes.string,
  question: PropTypes.object
};

HistoryLogManualContent.defaultProps = {
  log: {},
  url: ''
};

export default HistoryLogManualContent;
