import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import AutocompleteCheckboxTags from 'components/shared/AutocompleteCheckboxTags';
import { Form } from 'antd';

const CaseId = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const selectedList = Form.useWatch('case_id', form);

  const [searchValue, setSearchValue] = useState('');

  const {
    filters: {
      caseID: { list, isLoading }
    },
    filterAnswers: { case_id }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);

  useEffect(() => {
    if (case_id && !case_id.length) {
      form.resetFields();
      setSearchValue('');
    }
  }, [case_id, form]);

  useEffect(() => {
    !!searchValue && dispatch(ImSpecCaseDashboardBetaAction.getCaseID(searchValue));
  }, [dispatch, searchValue]);

  useEffect(() => {
    if (typeof selectedList !== 'undefined') {
      dispatch(ImSpecCaseDashboardBetaAction.setSelectedCaseID(selectedList));
    }
  }, [case_id, dispatch, selectedList]);

  return (
    <AutocompleteCheckboxTags
      name="case ID"
      isLoading={isLoading}
      form={form}
      options={list}
      filterOption="case_id"
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      checkedValues={case_id}
    />
  );
};

export default CaseId;
