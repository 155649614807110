import { ImSpecActionTypes } from 'store/types';

const initialState = {
  userInfo: {},
  isLoading: false
};

const ImSpecProfilePageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ImSpecActionTypes.PROFILE_LOADING:
      return {
        ...state,
        userInfo: {
          ...state.userInfo
        },
        isLoading: payload
      };
    case ImSpecActionTypes.SET_PROFILE_CONTENT:
      return {
        ...state,
        userInfo: { ...payload }
      };
    default:
      return state;
  }
};

export default ImSpecProfilePageReducer;
