import { Col, Row } from 'antd';
import { accessDenied } from 'components/Icons/Icons';
import { Link } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';

const AccessDenied = () => {
  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content access-denied">
        <Col>
          <Row justify="center">
            <h3>Access denied</h3>
          </Row>
          <Row justify="center">
            <p className="not-authorized-text">
              Sorry, you are not authorized to access this page.
            </p>
          </Row>
        </Col>

        <Row type="flex" justify="center" align="middle">
          {accessDenied}
        </Row>

        <Row type="flex" justify="center" align="middle">
          <Link to="/">
            <ArrowLeftOutlined /> Back to your dashboard
          </Link>
        </Row>
      </Col>
    </Row>
  );
};

export default AccessDenied;
