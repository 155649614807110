import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { AuthActions } from 'store/Actions';

import { Button, Col, Form, Input, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { warnSign } from 'components/Icons/Icons';

const initialState = {
  email: '',
  password: ''
};

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { isLoading } = useSelector(state => state.auth);

  const [authResponse, setAuthResponse] = useState({});

  const [loggingUser, setLoggingUser] = useState(initialState);
  const [disabled, setDisabled] = useState(true);

  const sendAnotherEmail = () => {
    const { email } = loggingUser;
    dispatch(AuthActions.resendVerifyEmail({ email, type: 'account-verification' }, navigate));
  };

  const onFieldsChange = (cur, all) => {
    setDisabled(all.some(e => e.touched === false || e.errors.length > 0));
  };

  const onFinish = userData => {
    setLoggingUser(userData);
    dispatch(AuthActions.signIn(userData, navigate, setAuthResponse, locationState));
  };

  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content">
        <Row>
          <h1>Sign in to Legalpad</h1>
        </Row>
        {authResponse?.detail && (
          <Row>
            {authResponse?.detail ===
              'The email and password entered do not match our records.For security reasons your account will be locked for 30 minutes if you enter wrong credentials again. You can reset your password if you forgot it.' && (
              <div className="blockWarning">
                <div>{warnSign}</div>
                <div className="warningText">
                  <p>
                    For security reasons your account will be locked for 30 minutes if you enter
                    wrong credentials again.
                  </p>
                  <p>
                    You can <Link to="/forgot-password">reset your password </Link> if you forgot
                    it.
                  </p>
                </div>
              </div>
            )}
            {authResponse?.detail ===
              'The email and password entered do not match our records.Your account has been locked for 30 minutes due to too many failed sign-in attempts.Please try again later.' && (
              <div className="blockWarning">
                <div>{warnSign}</div>
                <div className="warningText">
                  <p>
                    Your account has been locked for 30 minutes due to too many failed sign-in
                    attempts. Please try again later.
                  </p>
                  <p>
                    You can also <Link to="/forgot-password">reset your password</Link> if you
                    forgot it.
                  </p>
                </div>
              </div>
            )}
            {authResponse?.detail ===
              'We’re sorry, but your account has been locked for 30 minutes due to too many failed sign-in attempts. Please try again later.' && (
              <div className="blockWarning">
                <div>{warnSign}</div>
                <div className="warningText">
                  <p>
                    Your account has been locked for 30 minutes due to too many failed sign-in
                    attempts. Please try again later.
                  </p>
                  <p>
                    You can also <Link to="/forgot-password">reset your password</Link> if you
                    forgot it.
                  </p>
                </div>
              </div>
            )}
          </Row>
        )}
        <Row type="flex" justify="center" align="middle">
          <Form name="signIn" onFinish={onFinish} onFieldsChange={onFieldsChange}>
            <Form.Item
              name="email"
              className={authResponse?.statusText === 'Unauthorized' && 'error-border'}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'This field is required.'
                },
                () => ({
                  validator(_, value) {
                    if (
                      !!value &&
                      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        value
                      )
                    ) {
                      return Promise.reject(
                        new Error('You have entered an invalid e-mail address.')
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              className={authResponse?.statusText === 'Unauthorized' && 'error-border'}
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            {authResponse?.type === 'invalid-credentials' && (
              <p className="error"> {authResponse?.detail} </p>
            )}
            {authResponse?.type === 'not-verified' && (
              <p className="error">
                Account not verified.{' '}
                <Button type="link" onClick={sendAnotherEmail}>
                  Verify now
                </Button>
              </p>
            )}
            <Form.Item>
              <Button disabled={disabled || isLoading} htmlType="submit">
                {!isLoading ? (
                  <span>Sign in</span>
                ) : (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                )}
              </Button>
            </Form.Item>
          </Form>
        </Row>
        {/*todo uncomment when feature is active*/}
        {/*<Row className="ifHasAcc">Don’t have an account yet?  <Link to="/sign-up">Sign up</Link></Row>*/}
        <Row className="forgotPass">
          <Link to="/forgot-password">Forgot my password</Link>
        </Row>
      </Col>
    </Row>
  );
};

export default SignIn;
