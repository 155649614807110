import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

const Phone = ({
  setRequiredErrorClassName,
  curSubsection,
  answersDataState,
  answers,
  curField,
  iterationName,
  questionSet,
  setSavingValue,
  radioDisable,
  onFocus,
  onBlur,
  setInputError,
  inputError,
  setShowRequiredError,
  showRequiredError,
  inputRef,
  sync_info
}) => {
  const [phoneNum, setPhoneNum] = useState(null);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    focus && inputRef.current.focus();
  });

  useEffect(() => {
    setRequiredErrorClassName(
      curField.is_required && showRequiredError && !phoneNum ? 'required-error-block' : ''
    );
  }, [curField.is_required, phoneNum, setRequiredErrorClassName, showRequiredError]);

  useEffect(() => {
    if (!!answersDataState && Object.keys(answersDataState).length) {
      setPhoneNum(answersDataState[curField.name]?.field_value || null);
    }
  }, [answersDataState, curField]);

  const handleOnChangePhone = (e, type, name) => {
    setPhoneNum(e);
    const formData = new FormData();

    if (!e) {
      formData.append('field_name', name);
      formData.append('field_value', '');
      formData.append('field_type', type);
      formData.append('group_name', !!iterationName ? iterationName : '');
      questionSet &&
        !questionSet.startsWith('uId') &&
        formData.append(
          'iteration_key',
          !!answers[iterationName]?.field_value || !questionSet.startsWith('uId')
            ? answers[iterationName]?.field_value[questionSet][name]?.iteration_key
            : null
        );
      setSavingValue(formData);
      setInputError({
        ...inputError,
        hasValidPhone: true
      });
    } else if (!!e && isValidPhoneNumber(e)) {
      formData.append('field_name', name);
      formData.append('field_value', e);
      formData.append('field_type', type);
      formData.append('group_name', !!iterationName ? iterationName : '');
      questionSet &&
        !questionSet.startsWith('uId') &&
        formData.append(
          'iteration_key',
          !!answers[iterationName]?.field_value || !questionSet.startsWith('uId')
            ? answers[iterationName]?.field_value[questionSet][name]?.iteration_key
            : null
        );
      setSavingValue(formData);
      setInputError({
        ...inputError,
        hasValidPhone: true
      });
    } else {
      setInputError({
        ...inputError,
        hasValidPhone: false
      });
    }

    curField.is_required && !e.trim().length
      ? setShowRequiredError(true)
      : setShowRequiredError(false);
  };

  return (
    <div className="statePicker" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      <PhoneInput
        ref={inputRef}
        className={`${
          sync_info.sync_in_progress || curSubsection?.is_blocked || radioDisable ? 'disabled' : ''
        }`}
        id={curField?.id}
        name={curField?.name}
        international
        placeholder={`${curField?.placeholder}${curField.is_required ? '*' : ''}`}
        value={phoneNum}
        onChange={e => handleOnChangePhone(e, curField.type.type, curField?.name)}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={sync_info.sync_in_progress || curSubsection?.is_blocked || radioDisable}
      />
      {phoneNum && !isValidPhoneNumber(phoneNum) && (
        <p className="error">You have entered an invalid phone number</p>
      )}
    </div>
  );
};

Phone.propTypes = {
  setRequiredErrorClassName: PropTypes.func,
  curSubsection: PropTypes.object,
  answersDataState: PropTypes.object,
  answers: PropTypes.object,
  curField: PropTypes.object,
  iterationName: PropTypes.string,
  questionSet: PropTypes.string,
  setSavingValue: PropTypes.func,
  setInputError: PropTypes.func,
  inputError: PropTypes.object,
  setShowRequiredError: PropTypes.func,
  showRequiredError: PropTypes.bool,
  radioDisable: PropTypes.bool,
  inputRef: PropTypes.object,
  sync_info: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

Phone.defaultProps = {
  curSubsection: {},
  answersDataState: {},
  answers: {},
  curField: {},
  iterationName: '',
  questionSet: '',
  inputError: {},
  showRequiredError: false,
  radioDisable: false,
  inputRef: {},
  sync_info: {}
};

export default Phone;
