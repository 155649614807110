import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import CommentContent from 'components/HistoryLogCommentsThread/CommentsContent/elements/CommentContent';
import QuestionTooltipContent from 'components/HistoryLogCommentsThread/QuestionTooltipContent';
import BlurCommentContent from 'components/HistoryLogCommentsThread/CommentsContent/elements/BlurCommentContent';

import { Button, Col, Row, Spin, Tooltip, Typography, Input, Form } from 'antd';
import { CheckCircleFilled, CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { sendCommentIcon } from 'components/Icons/Icons';

import { HistoryLogCommentsThreadAction } from 'store/Actions';

import { sectionAndSubsectionNames } from 'utils/helper';

const { TextArea } = Input;

const IndividualComment = ({
  comment,
  question,
  deletedComment,
  commentsActiveTab,
  sentComment,
  user
}) => {
  const dispatch = useDispatch();
  const { masterIntakeID, userID, caseID, sectionTitle, petitionerName } = useParams();

  const [form] = Form.useForm();
  const message = Form.useWatch('message', form);

  const input_ref = useRef(null);

  const onOpenChange = answerID => open => {
    open
      ? dispatch(HistoryLogCommentsThreadAction.getQuestionTitle(answerID, masterIntakeID))
      : dispatch(HistoryLogCommentsThreadAction.setQuestionTitle(null));
  };

  const onResolveComment = useCallback(
    answer_id => () => {
      dispatch(
        HistoryLogCommentsThreadAction.onResolveComment(
          answer_id,
          caseID,
          masterIntakeID,
          sectionTitle ? sectionAndSubsectionNames(sectionTitle) : sectionTitle
        )
      );
    },
    [caseID, dispatch, masterIntakeID, sectionTitle]
  );

  const url = useMemo(() => {
    if (user?.role === 'customer') {
      if (masterIntakeID)
        return `/master-intake/${masterIntakeID}/${petitionerName}/${comment.subsection_name?.replaceAll(
          ' ',
          '-'
        )}`;

      if (caseID)
        return `/your-questionnaires/${caseID}/${comment.case_data.section_name?.replaceAll(
          ' ',
          '-'
        )}/${comment.case_data.subsection_name?.replaceAll(' ', '-')}`;
    }

    if (user?.role !== 'customer') {
      if (masterIntakeID)
        return `/petitioner-dashboard/master-intake/${masterIntakeID}/${petitionerName}`;

      if (caseID)
        return `/client-management/userID=${userID}/${caseID}/${comment.case_data.section_name?.replaceAll(
          ' ',
          '-'
        )}`;
    }
    return '/';
  }, [
    caseID,
    comment.case_data,
    comment.subsection_name,
    masterIntakeID,
    petitionerName,
    user?.role,
    userID
  ]);

  const resolve = useMemo(() => {
    if (commentsActiveTab === 'resolved')
      return <CheckCircleFilled style={{ fontSize: 24, color: '#5ABB82' }} />;

    if (commentsActiveTab === 'unresolved')
      return (
        <CheckCircleOutlined
          onClick={onResolveComment(comment.answer.id)}
          style={{ fontSize: 24, color: '#B4B3B3' }}
        />
      );

    if (commentsActiveTab === 'all')
      return !comment.unresolved_comments ? (
        <CheckCircleFilled style={{ fontSize: 24, color: '#5ABB82' }} />
      ) : (
        <CheckCircleOutlined
          onClick={onResolveComment(comment.answer.id)}
          style={{ fontSize: 24, color: '#B4B3B3' }}
        />
      );

    return null;
  }, [comment.answer.id, comment.unresolved_comments, commentsActiveTab, onResolveComment]);

  const handleTextAreaKeyUp = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const onKeyUp = event => {
    if (!(form.isFieldTouched('message') && !!message?.length && message.trim())) return;
    if (sentComment.answerId === comment.answer.id && !sentComment.isLoading) return;
    if (event.keyCode === 13) {
      form.submit();
    }
  };

  const onSendComment = answer => values => {
    const { message } = values;
    dispatch(
      HistoryLogCommentsThreadAction.onSubmitComment(
        { answer, message },
        form,
        input_ref,
        caseID,
        masterIntakeID,
        sectionTitle ? sectionAndSubsectionNames(sectionTitle) : sectionTitle
      )
    );
  };

  return (
    <div className="comment-container">
      <Spin
        className="comment-sent"
        wrapperClassName="comment-sent-wrapper"
        tip={<BlurCommentContent form={form} />}
        indicator={null}
        spinning={sentComment.answerId === comment.answer.id && !sentComment.isLoading}
      >
        <Col className="comment-header-container">
          <Row justify="space-between" align="middle" className="comment-header-title">
            <Col>
              <Row align="middle">
                <Link to={url} state={{ answer_id: comment.answer.id, action: 'comment' }}>
                  <Typography.Title level={5}>
                    {comment.answer.field_placeholder || comment.answer.field_name}
                  </Typography.Title>
                </Link>
                <Tooltip
                  overlayClassName="history-log-question-title"
                  getPopupContainer={triggerNode =>
                    triggerNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
                      .parentNode.parentNode.parentNode
                  }
                  placement="top"
                  title={<QuestionTooltipContent question={question} />}
                  onOpenChange={onOpenChange(comment.answer.id)}
                  trigger="click"
                  color="#FFFFFF"
                >
                  <Button
                    type="ghost"
                    className="question-info-icon"
                    icon={<InfoCircleOutlined style={{ color: '#63A0FF', fontSize: '14px' }} />}
                  />
                </Tooltip>
              </Row>
            </Col>
            {user?.role !== 'customer' ? (
              <Col>
                <Row align="middle">
                  <Tooltip
                    placement="left"
                    overlayClassName="comment-resolve-tooltip"
                    title={!comment.unresolved_comments ? 'Resolved' : 'Resolve'}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                  >
                    {resolve}
                  </Tooltip>
                </Row>
              </Col>
            ) : null}
          </Row>
          <Row className="comment-header-description">
            <Col>
              {comment.case_data && comment.case_data.section_name ? (
                <Row align="middle">
                  <Typography.Text className="comment-header-description-item">
                    Section:{' '}
                    <Typography.Text className="comment-header-description-item-value">
                      {comment.case_data.section_name}
                    </Typography.Text>
                  </Typography.Text>
                </Row>
              ) : null}
              {(comment.case_data && comment?.case_data.subsection_name) ||
              comment.subsection_name ? (
                <Row align="middle">
                  <Typography.Text className="comment-header-description-item">
                    Subsection:{' '}
                    <Typography.Text className="comment-header-description-item-value">
                      {comment.case_data
                        ? comment.case_data?.subsection_name
                        : comment.subsection_name}
                    </Typography.Text>
                  </Typography.Text>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Form
          form={form}
          rootClassName="comment-form"
          layout="inline"
          onKeyUp={onKeyUp}
          onFinish={onSendComment(comment.answer.id)}
        >
          <div className="inputWrapper">
            <Form.Item name="message" rootClassName="comment-message">
              <TextArea
                ref={input_ref}
                onKeyDown={handleTextAreaKeyUp}
                placeholder="Write your comment..."
                autoSize={{ minRows: 1, maxRows: 3 }}
              />
            </Form.Item>
            <Form.Item rootClassName="comment-submit" shouldUpdate>
              {() => {
                return (
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="comment-button"
                      disabled={
                        !(form.isFieldTouched('message') && !!message?.length && message.trim())
                      }
                      loading={sentComment.isLoading && sentComment.answerId === comment.answer.id}
                      icon={sendCommentIcon}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </div>
        </Form>
        <CommentContent
          comments={comment.comments}
          answer_id={comment.answer.id}
          count={comment.comments_count}
          deletedComment={deletedComment}
          user={user}
        />
      </Spin>
    </div>
  );
};

IndividualComment.propTypes = {
  comment: PropTypes.object,
  question: PropTypes.object,
  commentsActiveTab: PropTypes.string,
  deletedComment: PropTypes.object,
  sentComment: PropTypes.object,
  user: PropTypes.object
};

IndividualComment.defaultProps = {
  comment: {}
};

export default IndividualComment;
