import request from 'utils/request';

export const ImSpecClientManagementService = {
  getCasesInvitationForm: account_invitation => {
    return request('GET', `api/answer/visa_list/?account_invitation=${account_invitation}`);
  },
  getPetitionsInvitationForm: () => {
    return request('GET', 'api/answer/petitioner/');
  },
  getPrincipalCases: (userID, visa_name) => {
    return request('GET', `api/is/principal-case/${userID}?case_access=${visa_name}`);
  },
  inviteClient: client => {
    return request('POST', 'user/invitation/', client);
  },
  inviteCaseClient: (client, caseUser) => {
    return request('POST', `api/is/user/${caseUser.key}/case/assignee/`, client);
  },
  resendInvitation: userId => {
    return request('POST', `/user/resend-invitation/${userId}`);
  },
  cancelInvitation: userId => {
    return request('POST', `/user/cancel-invitation/${userId}`);
  },
  getClients: (page, pageSize) => {
    return request('GET', `user/all-users/?page=${page}&page_size=${pageSize}`);
  },
  getClientCases: userId => {
    return request('GET', `api/is/cases/users/${userId}/beneficiary`);
  },
  resendCaseInvitation: caseID => {
    return request('GET', `api/is/case-invitation/resend/${caseID}`);
  },
  cancelCaseInvitation: (caseID, data) => {
    return request('POST', `/api/is/case/${caseID}/cancel`, data);
  },
  searchClients: (page, pageSize, data) => {
    return request('POST', `user/search-user?page=${page}&page_size=${pageSize}`, data);
  }
};
