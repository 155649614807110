import { CustomerActionTypes } from 'store/types';
import { CustomerClientPageService } from 'services';

const getCompanies = () => async dispatch => {
  try {
    dispatch(companyLoading(true));
    const { data } = await CustomerClientPageService.getCompanies();

    dispatch(setCompaniesList(data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(companyLoading(false));
  }
};

const getCompanyCases = petitioner_id => async dispatch => {
  try {
    dispatch(companyLoading(true));
    const res = await CustomerClientPageService.getCompanyCases(petitioner_id);

    if (res.status < 200 || res.status >= 300) {
      throw res;
    }
    dispatch(setCompanyCasesList(res.data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(companyLoading(false));
  }
};

const getBeneficiaryTypes = () => async dispatch => {
  try {
    dispatch(beneficiaryTypesLoading(true));
    const { data } = await CustomerClientPageService.getBeneficiary();

    dispatch(setBeneficiaryList(data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(beneficiaryTypesLoading(false));
  }
};

const getAssociatedTypes = () => async dispatch => {
  try {
    dispatch(associatedTypesLoading(true));
    const { data } = await CustomerClientPageService.getAssociated();

    dispatch(setAssociatedList(data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(associatedTypesLoading(false));
  }
};

const getCaseList = (caseID, redirect) => async (dispatch, getState) => {
  try {
    dispatch(caseListLoading(true));
    const {
      customerClientPage: {
        case: { list, child_cases }
      }
    } = getState();

    !!list.length && dispatch(setCaseList([]));
    !!child_cases.length && dispatch(setChildCases([]));
    !!list.length && dispatch(setContacts({ im_spec: null, attorney: null }));

    const { data } = await CustomerClientPageService.getCaseSections(caseID);

    dispatch(setCaseList(data.sections));
    dispatch(setContacts({ im_spec: data.im_spec, attorney: data.attorney }));
    dispatch(setChildCases(data.child_cases));
  } catch (e) {
    if (e.status === 403 && e.data.detail === 'You have not access to this case.') {
      redirect(0, { state: { type: 'case-invitation-error' }, replace: true });
    }
  } finally {
    dispatch(caseListLoading(false));
  }
};

const setChildCases = cases => {
  return {
    type: CustomerActionTypes.SET_CLIENT_PAGE_CHILD_CASES,
    payload: [...cases]
  };
};

const setContacts = contacts => {
  return {
    type: CustomerActionTypes.SET_CONTACTS,
    payload: { ...contacts }
  };
};

const caseListLoading = loading => {
  return {
    type: CustomerActionTypes.CLIENT_CASE_LIST_LOADING,
    payload: loading
  };
};

const setCaseList = list => {
  return {
    type: CustomerActionTypes.SET_CLIENT_CASE_LIST,
    payload: [...list]
  };
};

const beneficiaryTypesLoading = loading => {
  return {
    type: CustomerActionTypes.BENEFICIARY_TYPES_LOADING,
    payload: loading
  };
};

const setBeneficiaryList = beneficiaryList => {
  return {
    type: CustomerActionTypes.SET_BENEFICIARY_TYPES_LIST,
    payload: [...beneficiaryList]
  };
};

const associatedTypesLoading = loading => {
  return {
    type: CustomerActionTypes.ASSOCIATED_TYPES_LOADING,
    payload: loading
  };
};

const setAssociatedList = associatedList => {
  return {
    type: CustomerActionTypes.SET_ASSOCIATED_TYPES_LIST,
    payload: [...associatedList]
  };
};

const companyLoading = loading => {
  return {
    type: CustomerActionTypes.COMPANY_CASE_LIST_LOADING,
    payload: loading
  };
};

const setCompaniesList = companiesList => {
  return {
    type: CustomerActionTypes.COMPANIES_LIST,
    payload: [...companiesList]
  };
};

const setCompanyCasesList = companyCasesList => {
  return {
    type: CustomerActionTypes.SET_COMPANY_CASE_LIST,
    payload: [...companyCasesList]
  };
};

export const CustomerClientPageActions = {
  getCaseList,
  getCompanies,
  getCompanyCases,
  getBeneficiaryTypes,
  getAssociatedTypes
};
