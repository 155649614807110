import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import sectionsTableColumns from 'components/ClientPage/CaseTable/columns';

import { Table, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const CaseTable = ({ data, loading, caseType, role, caseID, state }) => {
  const { userID } = useParams();

  const { user } = useSelector(state => state.auth);

  const columns = sectionsTableColumns(caseID, userID, role);

  const tooltipRow = ({ className, ...props }) => {
    const classNames = className.split(' ');
    if (
      classNames.includes('no-access') ||
      classNames.includes('no-access-owner') ||
      classNames.includes('no-access-pending') ||
      classNames.includes('sync-in-progress') ||
      classNames.includes('sync-in-progress-section') ||
      classNames.includes('no-access-rep')
    ) {
      const tooltipTitle = classNames.includes('no-access') ? (
        'This questionnaire has been sent to your colleague for completion.'
      ) : classNames.includes('no-access-rep') ? (
        'This questionnaire has been sent to the company representative for completion.'
      ) : classNames.includes('no-access-owner') ? (
        <span>
          Access to this section has been disabled. Please contact
          <address>
            {' '}
            <a href="mailto:onboarding@legalpad.io"> onboarding@legalpad.io </a>
          </address>
          if you have questions.
        </span>
      ) : classNames.includes('no-access-pending') ? (
        <span>
          The questionnaire is in pending state. You’ll gain access to it, once the beneficiary
          activates it.
        </span>
      ) : classNames.includes('sync-in-progress') ? (
        'Syncing in progress. Please wait.'
      ) : null;

      return (
        <Tooltip
          title={tooltipTitle}
          overlayClassName="no-access-tooltip"
          getPopupContainer={triggerNode => triggerNode.parentNode.parentNode.parentNode}
        >
          <tr className={className} {...props} />
        </Tooltip>
      );
    }
    return <tr className={className} {...props} />;
  };

  const rowClassName = useCallback(
    record => {
      if (role !== 'customer' && record.sync_in_progress)
        return 'case_sections-list-row sync-in-progress';

      if (role === 'customer') {
        if (
          record.beneficiary_has_access &&
          record.sync_in_progress &&
          record.last_sync_source_type === 'section'
        ) {
          return 'case_sections-list-row sync-in-progress';
        }

        if (
          record.beneficiary_has_access &&
          record.sync_in_progress &&
          record.last_sync_source_type === 'master_intake'
        )
          return 'case_sections-list-row sync-in-progress';
      }

      if (caseType === 'own' && role === 'customer') {
        if (record.owner.is_representative && !record.beneficiary_has_access)
          return 'case_sections-list-row no-access-rep';

        const noAccessClassName =
          record.owner.id === user?.id
            ? 'case_sections-list-row no-access-owner'
            : 'case_sections-list-row no-access';

        return record.beneficiary_has_access ? 'case_sections-list-row' : noAccessClassName;
      } else if (!caseType && role === 'customer') {
        const pendingAccess = 'case_sections-list-row no-access-pending';

        return state === 'Pending' ? pendingAccess : 'case_sections-list-row';
      }

      return 'case_sections-list-row';
    },
    [caseType, role, state, user?.id]
  );

  return (
    <Table
      className="case_sections-list client"
      rowClassName={rowClassName}
      tableLayout="fixed"
      loading={{
        spinning: loading,
        indicator: <LoadingOutlined style={{ fontSize: 30 }} />
      }}
      columns={columns}
      dataSource={data}
      pagination={false}
      components={{
        body: { row: tooltipRow }
      }}
    />
  );
};

CaseTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  caseType: PropTypes.string,
  role: PropTypes.string,
  caseID: PropTypes.string,
  state: PropTypes.string,
  className: PropTypes.string
};

CaseTable.defaultProps = {
  data: [],
  loading: false,
  caseType: '',
  role: '',
  caseID: '',
  state: ''
};

export default CaseTable;
