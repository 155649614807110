import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomerProfilePageActions } from 'store/Actions';

import { Button, Col, Form, Input, Popover, Row } from 'antd';
import { errorIcon, lightBulb, successIcon } from 'components/Icons/Icons';
import { CloseOutlined, InfoCircleFilled } from '@ant-design/icons';

const passVerificationError = {
  hasLength: false,
  isNotEmpty: false,
  hasUpper: false,
  hasLower: false,
  hasSpecial: false,
  hasNumber: false,
  confirm_password: false,
  old_new_different: true
};

const Password = () => {
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const [touched, setTouched] = useState(false);
  const [passwordError, setPasswordError] = useState(passVerificationError);
  const [disabled, setDisabled] = useState(true);
  const [validationResponse, setValidationResponse] = useState('');
  const [visiblePopover, setVisiblePopover] = useState(false);

  const [form] = Form.useForm();

  const handleVisiblePopoverChange = visiblePopover => {
    setVisiblePopover(visiblePopover);
  };

  const onFinish = userPassData => {
    dispatch(
      CustomerProfilePageActions.resetUserPassword(
        userPassData,
        setValidationResponse,
        cancelResetPass
      )
    );
    setDisabled(true);
  };

  const onFieldsChange = (cur, all) => {
    const { touched, name, value } = cur[0];
    const oldPasswordValue = all.find(e => e.name[0] === 'old_password').value;
    const passwordValue = all.find(e => e.name[0] === 'password').value;
    const confirmPasswordValue = all.find(e => e.name[0] === 'confirm_password').value;

    if (touched) {
      if (name[0] === 'password') {
        setTouched(touched);
        setPasswordError(passwordError => ({
          ...passwordError,
          isNotEmpty: !!value,
          hasLength: value.length > 11,
          hasUpper: /[A-Z]/.test(value),
          hasLower: /[a-z]/.test(value),
          hasSpecial: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value),
          hasNumber: /[0-9]/.test(value),
          confirm_password: confirmPasswordValue === value,
          old_new_different: oldPasswordValue !== passwordValue
        }));
        setPasswordError(passwordError => ({
          ...passwordError,
          old_new_different: oldPasswordValue !== passwordValue
        }));
      }
      if (name[0] === 'old_password') {
        setValidationResponse('');
        setPasswordError(passwordError => ({
          ...passwordError,
          isNotEmpty: !!value,
          old_new_different: oldPasswordValue !== passwordValue
        }));
      }
      if (name[0] === 'confirm_password') {
        setPasswordError(passwordError => ({
          ...passwordError,
          isNotEmpty: !!value,
          confirm_password: passwordValue === value
        }));
      }
    }

    setDisabled(
      Object.values(passwordError).some(val => val === false) ||
        passwordValue !== confirmPasswordValue ||
        !oldPasswordValue
    );
  };

  const subSectionDesc = (
    <>
      <div className="subSectionDescBody">
        <div className="subSectionDescBody_content">
          <span>{lightBulb}</span>
          <p style={{ whiteSpace: 'pre-line' }}>
            Changing your password will sign you out of any other devices and browsers where you are
            signed in.
          </p>
        </div>
      </div>
      <div className="close-icon">
        <Button
          type="ghost"
          shape="circle"
          onClick={() => handleVisiblePopoverChange(false)}
          icon={<CloseOutlined />}
        />
      </div>
    </>
  );

  const openResetPass = () => {
    handleVisiblePopoverChange(true);
    setIsEditable(true);
  };

  const cancelResetPass = () => {
    setIsEditable(false);
    setPasswordError(passVerificationError);
    setTouched(false);
    setValidationResponse('');
    form.resetFields();
  };

  return (
    <Col className="password-page">
      <Row className="profile-page_header" type="flex" justify="space-between" align="middle">
        <h2 className="title">
          Password
          <Popover
            placement="right"
            overlayClassName="subSectionDesc"
            content={subSectionDesc}
            trigger="click"
            open={visiblePopover}
            onOpenChange={handleVisiblePopoverChange}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            <InfoCircleFilled />
          </Popover>
        </h2>
        {isEditable ? (
          <Row>
            <Button className="primary-btn cancel" onClick={cancelResetPass}>
              {' '}
              Cancel{' '}
            </Button>
            <Button className="primary-btn" disabled={disabled} onClick={() => form.submit()}>
              {' '}
              Save{' '}
            </Button>
          </Row>
        ) : (
          <Button className="primary-btn" onClick={openResetPass}>
            {' '}
            Update Password{' '}
          </Button>
        )}
      </Row>
      <Col className={`profile-page_content isEditable_${isEditable}`}>
        {isEditable ? (
          <Col>
            <p className="desc">
              Setting a strong password that you’re not using elsewhere will help protect your data.
            </p>
            <Form
              form={form}
              name="resetPass"
              onFinish={onFinish}
              onFieldsChange={onFieldsChange}
              className="changePass"
            >
              <Form.Item
                name="old_password"
                className={validationResponse && 'error-border'}
                rules={[
                  {
                    required: true,
                    message: ''
                  }
                ]}
              >
                <Input.Password placeholder="Old Password" />
              </Form.Item>
              {validationResponse && <p className="error"> {validationResponse} </p>}
              <Form.Item
                name="password"
                className={
                  !!passwordError.isNotEmpty && !passwordError.old_new_different && 'error-border'
                }
                rules={[
                  {
                    required: true,
                    message: ''
                  }
                ]}
              >
                <Input.Password placeholder="New Password" />
              </Form.Item>
              {!!passwordError.isNotEmpty && !passwordError.old_new_different && (
                <p className="error"> Old and new passwords should not match </p>
              )}
              <Form.Item
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: ''
                  }
                ]}
              >
                <Input.Password placeholder="Repeat New Password" />
              </Form.Item>
              <div className="pass-errs">
                <ul>
                  <li>
                    {touched && (passwordError.hasLength ? successIcon : errorIcon)}
                    At least 12 characters
                  </li>
                  <li>
                    {touched && (passwordError.hasLower ? successIcon : errorIcon)}1 lowercase
                    letter
                  </li>
                  <li>{touched && (passwordError.hasNumber ? successIcon : errorIcon)}1 number</li>
                </ul>
                <ul>
                  <li>
                    {touched && (passwordError.hasSpecial ? successIcon : errorIcon)}1 special
                    character
                  </li>
                  <li>
                    {touched && (passwordError.hasUpper ? successIcon : errorIcon)}1 uppercase
                    letter
                  </li>
                  <li>
                    {touched &&
                      (passwordError.confirm_password && passwordError.isNotEmpty
                        ? successIcon
                        : errorIcon)}
                    Passwords match
                  </li>
                </ul>
              </div>
            </Form>
          </Col>
        ) : null}
      </Col>
    </Col>
  );
};

export default Password;
