import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecCaseDashboardBetaAction } from 'store/Actions';

import CaseType from './CaseDashboardQuickFilters/CaseType';
import Petitioner from './CaseDashboardQuickFilters/Petitioner';
import State from './CaseDashboardQuickFilters/State';

import LastUpdate from './DrawerElements/LastUpdate';

import AdvancedQuickFilters from './CaseDashboardQuickFilters/AdvancedQuickFilters';

import { Badge, Button, Dropdown, Popover, Tooltip } from 'antd';
import { DownOutlined, UpOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { filtersIcon, saveIcon } from 'components/Icons/Icons';

import './CaseDashboardDrawer.scss';

import {
  countNonEmpty,
  filterNameHelperWithCapitalize,
  formatData,
  isFilterAnswersEmpty,
  shouldRenderValue
} from 'utils/helper';
import { useMemo, useState } from 'react';

const CaseDashboardFilters = ({ showDrawer, showModal, setActiveTab }) => {
  const dispatch = useDispatch();
  const {
    filterAnswers,
    appliedSavedView: { id: savedViewId, name: savedViewName },
    savedViews: { list: savedViewsList }
  } = useSelector(state => state.ImSpecCaseDashboardBeta);
  const caseDashboardSavedView = JSON.parse(localStorage.getItem('case-dashboard-saved_view'));
  const caseDashboardFilters = JSON.parse(localStorage.getItem('case-dashboard-filters'));
  const countEmpty = countNonEmpty(caseDashboardFilters);
  const [showCaseTypeTooltip, setShowCaseTypeTooltip] = useState(false);
  const [showStateTooltip, setShowStateTooltip] = useState(false);
  const [showLastUpdateTooltip, setShowLastUpdateTooltip] = useState(false);
  const [showPetitionerTooltip, setShowPetitionerTooltip] = useState(false);
  const [showAdvancedTooltip, setShowAdvancedTooltip] = useState(false);

  const {
    case_type = [],
    state = [],
    last_submitted = {},
    last_edited = {},
    last_reviewed = {},
    last_commented = {},
    last_created = {},
    petitioner = {}
  } = caseDashboardFilters ?? [];

  const clearAllFilters = () => {
    dispatch(ImSpecCaseDashboardBetaAction.clearFilters());
    dispatch(ImSpecCaseDashboardBetaAction.getCasesTableData());
  };

  const items = [
    {
      key: '1',
      type: 'sub menu',
      label: 'Submitted',
      popupClassName: 'lastUpdateQuickFilterPopup',
      popupOffset: [20, 0],
      children: [
        {
          key: 'last_submitted',
          className: 'lastUpdateQuickFilter',
          label: (
            <LastUpdate
              type="last_submitted"
              quick={true}
              dataFromStorage={last_submitted}
              setShowTooltip={setShowLastUpdateTooltip}
            />
          )
        }
      ]
    },
    {
      key: '2',
      type: 'sub menu',
      label: 'Edited',
      popupClassName: 'lastUpdateQuickFilterPopup',
      popupOffset: [20, 0],
      children: [
        {
          key: 'last_edited',
          className: 'lastUpdateQuickFilter',
          label: (
            <LastUpdate
              type="last_edited"
              quick={true}
              dataFromStorage={last_edited}
              setShowTooltip={setShowLastUpdateTooltip}
            />
          )
        }
      ]
    },
    {
      key: '3',
      type: 'sub menu',
      label: 'Reviewed',
      popupClassName: 'lastUpdateQuickFilterPopup',
      popupOffset: [20, 0],
      children: [
        {
          key: 'last_reviewed',
          className: 'lastUpdateQuickFilter',
          label: (
            <LastUpdate
              type="last_reviewed"
              quick={true}
              dataFromStorage={last_reviewed}
              setShowTooltip={setShowLastUpdateTooltip}
            />
          )
        }
      ]
    },
    {
      key: '4',
      type: 'sub menu',
      label: 'Commented',
      popupClassName: 'lastUpdateQuickFilterPopup',
      popupOffset: [20, 0],
      children: [
        {
          key: 'last_commented',
          className: 'lastUpdateQuickFilter',
          label: (
            <LastUpdate
              type="last_commented"
              quick={true}
              dataFromStorage={last_commented}
              setShowTooltip={setShowLastUpdateTooltip}
            />
          )
        }
      ]
    },
    {
      key: '5',
      type: 'sub menu',
      label: 'Created',
      popupClassName: 'lastUpdateQuickFilterPopup',
      popupOffset: [20, 0],
      children: [
        {
          key: 'last_created',
          className: 'lastUpdateQuickFilter',
          label: (
            <LastUpdate
              type="last_created"
              quick={true}
              dataFromStorage={last_created}
              setShowTooltip={setShowLastUpdateTooltip}
            />
          )
        }
      ]
    }
  ];

  const handleSavedViewMenuClick = (id = null, name = null, filter_params = null) => {
    if (id) {
      localStorage.setItem('case-dashboard-filters', JSON.stringify(filter_params));
      dispatch(ImSpecCaseDashboardBetaAction.setPage(1));
      dispatch(ImSpecCaseDashboardBetaAction.applyFilters(id, name, filter_params));
    } else {
      clearAllFilters();
      dispatch(ImSpecCaseDashboardBetaAction.setApplySavedViewName({ id: null, name: '' }));
      localStorage.removeItem('case-dashboard-saved_view');
      localStorage.removeItem('case-dashboard-filters');
    }
  };

  const menuProps = {
    items: [
      savedViewName
        ? {
            id: null,
            label: 'Back to default',
            filter_params: null,
            key: 'default',
            icon: <ReloadOutlined />,
            className: 'backDefaultItem',
            onClick: () => handleSavedViewMenuClick()
          }
        : null,
      ...savedViewsList.map(({ id, name, filter_params }) => {
        return {
          id,
          label: name,
          filter_params,
          key: id,
          onClick: () => handleSavedViewMenuClick(id, name, filter_params)
        };
      })
    ]
  };

  const updateBtnProps = {
    items: [
      {
        icon: saveIcon,
        label: 'Save as new',
        key: '2',
        onClick: () => showModal()
      },
      {
        icon: <ReloadOutlined />,
        label: 'Revert changes',
        key: '3',
        onClick: () =>
          dispatch(
            ImSpecCaseDashboardBetaAction.applyFilters(
              caseDashboardSavedView.id,
              caseDashboardSavedView.name,
              savedViewsList.find(view => view.id === savedViewId).filter_params
            )
          )
      }
    ]
  };

  const checkObjectLength = obj => {
    const keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const length = Object.keys(obj[key]).length;

      if (length > 0) {
        return (
          <span className="boldTip">
            : {key.replace('last_', '').charAt(0).toUpperCase() + key.slice(6)}
          </span>
        );
      }
    }

    return null;
  };

  const lastUpdateFormattedData = Object.entries({
    last_submitted,
    last_edited,
    last_reviewed,
    last_commented,
    last_created
  })
    .map(([key, value]) => {
      let formattedValue = formatData(value);
      if (key === 'case_id') {
        formattedValue = value.map(item => item.case_id).join(', ');
      } else if (key === 'case_type') {
        formattedValue = value.map(item => item.visa_name).join(', ');
      } else if (key === 'beneficiary') {
        formattedValue = value.map(item => item.beneficiary).join(', ');
      }
      return shouldRenderValue(formattedValue) ? (
        <p key={key}>
          {key.replace('last_', '').charAt(0).toUpperCase() + key.slice(6)}: {formattedValue}
        </p>
      ) : null;
    })
    .filter(item => item !== null);

  const updateViewDisabled = useMemo(() => {
    if (savedViewId) {
      const savedView = savedViewsList.find(view => view.id === savedViewId);

      return JSON.stringify(savedView?.filter_params) === JSON.stringify(filterAnswers);
    }
    return false;
  }, [filterAnswers, savedViewId, savedViewsList]);

  const downUpIconsStyle = { fontSize: 12 };

  return (
    <div className="quickFilters">
      <div className="quickFiltersBlock">
        <div className="filter">
          <Badge dot={!isFilterAnswersEmpty(caseDashboardFilters)} color="gold">
            {filtersIcon}
          </Badge>
          {savedViewsList.length ? (
            <Dropdown menu={menuProps} overlayClassName="savedViewDropdownList" trigger="click">
              <Button type="ghost">
                {savedViewName ? savedViewName : 'Filters'}
                <DownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <span>Filters</span>
          )}
        </div>
        <Tooltip
          placement="topLeft"
          title={case_type.map(item => item.visa_name).join(', ')}
          color="#E8E8EA"
          overlayInnerStyle={{ color: '#222124' }}
        >
          <Popover
            className="quickFilterBtn"
            overlayClassName="quickFilterOverlay"
            content={
              <CaseType
                case_type={case_type}
                showTooltip={showCaseTypeTooltip}
                setShowTooltip={setShowCaseTypeTooltip}
              />
            }
            trigger="click"
            placement="bottomLeft"
            arrow={false}
            open={showCaseTypeTooltip}
            onOpenChange={setShowCaseTypeTooltip}
          >
            <Button>
              Case Type
              {case_type?.length ? (
                <span className="boldTip">{`: ${case_type.map(item => item.visa_name)}`}</span>
              ) : null}
              {showCaseTypeTooltip ? (
                <UpOutlined style={downUpIconsStyle} />
              ) : (
                <DownOutlined style={downUpIconsStyle} />
              )}
            </Button>
          </Popover>
        </Tooltip>

        <Tooltip
          placement="topLeft"
          title={state.map(item => item).join(', ')}
          color="#E8E8EA"
          overlayInnerStyle={{ color: '#222124' }}
        >
          <Popover
            className="quickFilterBtn"
            overlayClassName="quickFilterOverlay"
            content={<State state={state} setShowTooltip={setShowStateTooltip} />}
            trigger="click"
            placement="bottomLeft"
            arrow={false}
            destroyTooltipOnHide={true}
            open={showStateTooltip}
            onOpenChange={setShowStateTooltip}
          >
            <Button>
              State
              {state?.length ? <span className="boldTip">{`: ${state.join(', ')}`}</span> : null}
              {showStateTooltip ? (
                <UpOutlined style={downUpIconsStyle} />
              ) : (
                <DownOutlined style={downUpIconsStyle} />
              )}
            </Button>
          </Popover>
        </Tooltip>

        <Tooltip
          placement="topLeft"
          title={lastUpdateFormattedData.length > 0 ? lastUpdateFormattedData : ''}
          color="#E8E8EA"
          overlayInnerStyle={{ color: '#222124' }}
        >
          <Dropdown
            destroyPopupOnHide
            menu={{ items, triggerSubMenuAction: 'click' }}
            className="quickFilterBtn"
            overlayClassName="quickFilterOverlayLastUpdate"
            trigger="click"
            open={showLastUpdateTooltip}
            onOpenChange={setShowLastUpdateTooltip}
          >
            <Button>
              Last update
              {checkObjectLength({
                last_submitted,
                last_edited,
                last_reviewed,
                last_commented,
                last_created
              })}
              {showLastUpdateTooltip ? (
                <UpOutlined style={downUpIconsStyle} />
              ) : (
                <DownOutlined style={downUpIconsStyle} />
              )}
            </Button>
          </Dropdown>
        </Tooltip>

        <Tooltip
          placement="topLeft"
          title={petitioner?.options
            ?.map(item => {
              const companies = petitioner?.companies?.length
                ? `: (${petitioner?.companies.map(company => company.name).join(', ')})`
                : '';
              return `${filterNameHelperWithCapitalize(item)}${
                item === 'company_sponsored' ? companies : ''
              }`;
            })
            .join(', ')}
          color="#E8E8EA"
          overlayInnerStyle={{ color: '#222124' }}
        >
          <Popover
            className="quickFilterBtn"
            overlayClassName="quickFilterOverlay"
            content={
              <Petitioner petitioner={petitioner} setShowTooltip={setShowPetitionerTooltip} />
            }
            trigger="click"
            placement="bottomLeft"
            arrow={false}
            destroyTooltipOnHide={true}
            open={showPetitionerTooltip}
            onOpenChange={setShowPetitionerTooltip}
          >
            <Button>
              Petitioner
              {!!petitioner?.options?.length && (
                <span className="boldTip">{`: ${filterNameHelperWithCapitalize(
                  petitioner?.options[0]
                )}`}</span>
              )}
              {showPetitionerTooltip ? (
                <UpOutlined style={downUpIconsStyle} />
              ) : (
                <DownOutlined style={downUpIconsStyle} />
              )}
            </Button>
          </Popover>
        </Tooltip>

        {countEmpty ? (
          <Popover
            className="quickFilterBtn"
            overlayClassName="advancedQuickFiltersContainer"
            content={
              <AdvancedQuickFilters
                showDrawer={showDrawer}
                setShowAdvancedTooltip={setShowAdvancedTooltip}
                setActiveTab={setActiveTab}
              />
            }
            trigger="click"
            placement="bottomRight"
            arrow={false}
            open={showAdvancedTooltip}
            onOpenChange={setShowAdvancedTooltip}
          >
            <Button shape="circle">+{countEmpty}</Button>
          </Popover>
        ) : null}

        <Tooltip placement="topLeft" title="More filters">
          <Button
            className="addAdvancedFilter"
            shape="circle"
            onClick={showDrawer}
            icon={<PlusOutlined style={{ fontSize: '11px', color: '#63A0FF' }} />}
          />
        </Tooltip>

        <Button
          type="ghost"
          onClick={clearAllFilters}
          className="clearBtn"
          disabled={isFilterAnswersEmpty(caseDashboardFilters)}
        >
          Clear all
        </Button>
      </div>
      <div>
        {savedViewId ? (
          <Dropdown.Button
            onClick={() =>
              dispatch(
                ImSpecCaseDashboardBetaAction.updateSavedFilter(
                  savedViewId,
                  savedViewName,
                  null,
                  null,
                  filterAnswers
                )
              )
            }
            trigger="click"
            disabled={updateViewDisabled}
            type="primary"
            icon={<DownOutlined />}
            menu={updateBtnProps}
            overlayClassName="applyActions"
            className="updateBtn"
          >
            Update view
          </Dropdown.Button>
        ) : !isFilterAnswersEmpty(filterAnswers) ? (
          <Button type="ghost" icon={saveIcon} className="saveBtn" onClick={() => showModal()}>
            Save this view
          </Button>
        ) : null}
      </div>
    </div>
  );
};

CaseDashboardFilters.propTypes = {
  showDrawer: PropTypes.func,
  showModal: PropTypes.func,
  setActiveTab: PropTypes.func
};

export default CaseDashboardFilters;
