import { useEffect, useState } from 'react';
import { useParams, useNavigate, useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerSectionReviewAction } from 'store/Actions';

import Sections from './elements/sections';
import UnGroupedSection from './elements/unGroupedSection';

import { sectionAndSubsectionNames } from 'utils/helper';

import { Row, Col, Button, Modal, Tooltip, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { alreadySyncedReview } from 'components/Icons/Icons';
import './reviewSection.scss';

const SectionReview = () => {
  const { caseID, sectionTitle, masterIntakeID, petitionerName } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const masterIntakeReview = useMatch('/master-intake/:masterIntakeID/:petitionerName/review');

  const {
    content: { section },
    answers: { sectionsAnswers, isLoading: answersLoading }
  } = useSelector(state => state.customerSectionsReview);

  const [haveMissing, setHaveMissing] = useState(false);
  const [haveRequired, setHaveRequired] = useState(null);
  const [tooltipDetails, setTooltipDetails] = useState({
    title: null,
    disabled: true
  });

  useEffect(() => {
    if (section.missing_required_fields !== undefined)
      setHaveRequired(section?.missing_required_fields);
  }, [section]);

  useEffect(() => {
    loop1: for (let key in sectionsAnswers) {
      if (!sectionsAnswers[key]) {
        setHaveMissing(true);
        break;
      } else {
        setHaveMissing(false);
      }
      if (sectionsAnswers[key] !== null && typeof sectionsAnswers[key] === 'object') {
        for (let i in sectionsAnswers[key]) {
          if (sectionsAnswers[key][i]) {
            for (let j in sectionsAnswers[key][i]) {
              if (!sectionsAnswers[key][i][j] || sectionsAnswers[key][i][j] === '[]') {
                setHaveMissing(true);
                break loop1;
              }
            }
          }
        }
      } else {
        setHaveMissing(false);
      }
    }
  }, [sectionsAnswers]);

  useEffect(() => {
    if (section.section_status) {
      if (section?.sync_in_progress) {
        setTooltipDetails({
          title: 'Syncing in progress. Please wait.',
          disabled: true
        });
      }
      if (haveRequired !== undefined && haveRequired !== null && !section?.sync_in_progress) {
        if (!tooltipDetails.title && tooltipDetails.disabled) {
          if (section.is_blocked && section?.owner?.is_representative) {
            setTooltipDetails({
              title:
                'This questionnaire has been sent to the company representative for completion.',
              disabled: true
            });
          } else if (section.is_blocked && !section?.owner?.is_representative) {
            setTooltipDetails({
              title: 'This questionnaire has been sent to your colleague for completion.',
              disabled: true
            });
          } else if (
            section.section_status === 'Not Attempted' &&
            !section.is_blocked &&
            !section.last_sync_source_type
          ) {
            setTooltipDetails({
              title: 'None of the fields in this section are filled in.',
              disabled: true
            });
          } else if (haveRequired) {
            setTooltipDetails({
              title: 'You have missing required fields.',
              disabled: true
            });
          } else {
            setTooltipDetails({
              title: null,
              disabled: false
            });
          }
        }
      }
    }
    if (masterIntakeReview) {
      if (section.status) {
        if (section?.sync_available) setTooltipDetails({ title: null, disabled: false });
        if (section?.sync_in_progress) {
          setTooltipDetails({
            title: 'Syncing in progress. Please wait.',
            disabled: true
          });
        }
        if (
          !section?.sync_date ||
          (section?.sync_date && !section?.sync_available && !section?.sync_in_progress)
        )
          setTooltipDetails({ title: null, disabled: false });
        if (section.status === 'Not Attempted') {
          setTooltipDetails({
            title: 'None of the fields in this section are filled in.',
            disabled: true
          });
        } else if (haveRequired) {
          setTooltipDetails({
            title: 'You have missing required fields.',
            disabled: true
          });
        }
      }
    }
  }, [
    haveRequired,
    masterIntakeReview,
    section.is_blocked,
    section.section_status,
    section.status,
    section?.sync_available,
    section?.sync_date,
    section?.sync_in_progress,
    section.last_sync_source_type,
    section?.owner?.is_representative,
    tooltipDetails.disabled,
    tooltipDetails.title
  ]);

  useEffect(() => {
    const getInitialData = () => {
      dispatch(
        CustomerSectionReviewAction.getSectionForReview(
          caseID,
          sectionTitle,
          masterIntakeID,
          petitionerName
        )
      );
      dispatch(
        CustomerSectionReviewAction.getSectionsAnswers(
          caseID,
          sectionTitle,
          masterIntakeID,
          petitionerName
        )
      );
    };

    getInitialData();

    return () => {
      dispatch(CustomerSectionReviewAction.setAnswers({}));
      dispatch(CustomerSectionReviewAction.setSection({}));
    };
  }, [dispatch, sectionTitle, caseID, petitionerName, masterIntakeID]);

  const successModalProps = {
    width: 438,
    centered: true,
    icon: null,
    className: 'submit-section-confirm-modal congrats',
    okButtonProps: { className: 'submit-section-confirm-modal-submit_btn' },
    okText: 'Close',
    onOk: () => !masterIntakeID && navigate(`/your-questionnaires?caseID=${caseID}`)
  };

  const returnToEdit = ({ title }) => {
    const path =
      masterIntakeID && petitionerName
        ? `/master-intake/${masterIntakeID}/${petitionerName}/${title.replaceAll(' ', '-')}`
        : `/your-questionnaires/${caseID}/${section.title.replaceAll(' ', '-')}/${title.replaceAll(
            ' ',
            '-'
          )}`;

    navigate(path, { state: { review: true } });
  };

  const onSubmit = () => {
    masterIntakeReview
      ? dispatch(
          CustomerSectionReviewAction.syncAndSubmit(masterIntakeID, petitionerName, {
            infoModal: Modal.info,
            modalProps: successModalProps
          })
        )
      : dispatch(
          CustomerSectionReviewAction.onSubmitSection(
            caseID,
            sectionTitle,
            {
              infoModal: Modal.info,
              modalProps: successModalProps
            },
            navigate
          )
        );
  };

  const showModalConfirm = () => {
    const modalProps = haveMissing
      ? {
          width: 438,
          centered: true,
          icon: null,
          className: 'submit-section-confirm-modal missing',
          cancelButtonProps: {
            className: 'submit-section-confirm-modal-cancel_btn'
          },
          okButtonProps: {
            className: 'submit-section-confirm-modal-submit_btn'
          },
          okText: 'Submit',
          content: (
            <>
              <span className="submit-section-confirm-modal-title">
                You have a few missing fields.
              </span>
              <span className="submit-section-confirm-modal-content">
                Would you like to submit anyway?
              </span>
            </>
          ),
          onOk: onSubmit
        }
      : {
          width: 438,
          centered: true,
          icon: null,
          className: 'submit-section-confirm-modal',
          cancelButtonProps: {
            className: 'submit-section-confirm-modal-cancel_btn'
          },
          okButtonProps: {
            className: 'submit-section-confirm-modal-submit_btn'
          },
          okText: 'Submit',
          content: (
            <>
              <span className="submit-section-confirm-modal-title">
                Are you sure you want to submit this section?
              </span>
            </>
          ),
          onOk: onSubmit
        };
    Modal.confirm(modalProps);
  };

  return (
    <Col className="review-container">
      <Row justify="space-between" align="middle" className="review-container-title">
        <Col>
          <h1 className="section-title">
            Review{' '}
            {masterIntakeID
              ? `${sectionAndSubsectionNames(petitionerName)} Master Intake`
              : section.title}
          </h1>
        </Col>
        <Col>
          <Row align="middle">
            {masterIntakeReview &&
              section?.sync_date &&
              !section?.sync_available &&
              !section?.sync_in_progress && (
                <Tooltip
                  placement="top"
                  overlayClassName="assign-tooltip"
                  title="Already synced!"
                  getTooltipContainer={trigger => trigger.parentNode}
                >
                  <div className="already-synced">{alreadySyncedReview}</div>
                </Tooltip>
              )}
            <Tooltip
              placement="bottomRight"
              overlayClassName="submit-tooltip"
              title={tooltipDetails.title}
              getTooltipContainer={trigger => trigger.parentNode}
            >
              <Button
                className={`submit-btn ${
                  (section?.sync_in_progress || tooltipDetails.disabled) && 'disabled'
                }`}
                disabled={section?.sync_in_progress || tooltipDetails.disabled}
                onClick={showModalConfirm}
              >
                {((masterIntakeReview && section?.sync_available) ||
                  (masterIntakeReview && section.status === 'Not Attempted')) && (
                  <span>
                    Sync <span style={{ fontWeight: 400 }}>&</span> Submit
                  </span>
                )}
                {masterIntakeReview &&
                  section?.sync_in_progress &&
                  !section?.sync_available &&
                  'Syncing...'}
                {masterIntakeReview &&
                  section?.sync_date &&
                  !section?.sync_available &&
                  !section?.sync_in_progress &&
                  'Submit'}
                {!masterIntakeReview && 'Submit'}
              </Button>
            </Tooltip>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginBottom: '30px' }}>
        <Col>
          {section?.title && (
            <div className="sectionDesc">
              <span />
              <p>
                {section?.has_available_sections_to_sync
                  ? 'Review each section for completeness and make edits if needed. Once you finish reviewing, click Submit to send this intake to your Legalpad team and to sync these details to other case questionnaires.'
                  : 'Review each section for completeness and make edits if needed. Once you finish reviewing, click Submit to send this intake to your Legalpad team.'}
              </p>
            </div>
          )}
          {masterIntakeReview && (
            <div className="sectionDesc">
              <span />
              <p>
                Review each section for completeness and make edits if needed. After reviewing,
                click Sync to provide these details in {sectionAndSubsectionNames(petitionerName)}'s
                employee cases.
              </p>
            </div>
          )}
        </Col>
      </Row>
      <Spin
        spinning={answersLoading}
        indicator={<LoadingOutlined style={{ fontSize: 50, color: '#FBD963' }} />}
      >
        <div className="scrollableBlock">
          {section?.subsection_set?.map(section =>
            section.type === 'grouped' ? (
              <Sections
                key={section.id}
                section={section}
                sectionsAnswers={sectionsAnswers}
                returnToEdit={returnToEdit}
              />
            ) : (
              <UnGroupedSection
                key={section.id}
                section={section}
                sectionsAnswers={sectionsAnswers}
                returnToEdit={returnToEdit}
              />
            )
          )}
        </div>
      </Spin>
    </Col>
  );
};

export default SectionReview;
