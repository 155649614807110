import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImSpecContentAction } from 'store/Actions';

import { Checkbox } from 'antd';

const MultiSelect = ({ curField, questionSet, iterationName }) => {
  const formData = new FormData();
  const dispatch = useDispatch();
  const { masterIntakeID, petitionerName, sectionTitle, caseID } = useParams();

  const { section, answers, sendAnswersLoading, answersLoading, sync_info } = useSelector(
    state => state.ImSpecContent
  );

  const [answersDataState, setAnswersDataState] = useState({});
  const [defaultChecked, setDefaultChecked] = useState([]);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [radioDisable, setRadioDisable] = useState(false);

  useEffect(() => {
    !!iterationName
      ? !questionSet.startsWith('uId') &&
        setAnswersDataState(answers[iterationName]?.field_value[questionSet])
      : setAnswersDataState(answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionSet, answers]);

  useEffect(() => {
    if (sendAnswersLoading) {
      setRadioDisable(sendAnswersLoading);
    } else {
      !sendAnswersLoading && setRadioDisable(answersLoading);
    }
  }, [sendAnswersLoading, answersLoading]);

  useEffect(() => {
    answersDataState &&
      answersDataState[curField.name] &&
      answersDataState[curField.name]?.field_value &&
      setDefaultChecked(JSON.parse(answersDataState[curField.name]?.field_value));
  }, [answersDataState, curField.name]);

  const onChange = checked => {
    setRadioDisable(true);
    setDefaultChecked(checked);
    formData.append('field_name', curField.name);
    formData.append('field_value', JSON.stringify(checked));
    formData.append('field_type', curField.type.type);
    formData.append('group_name', !!iterationName ? iterationName : '');
    questionSet &&
      !questionSet.startsWith('uId') &&
      formData.append(
        'iteration_key',
        !!answers[iterationName]?.field_value || !questionSet.startsWith('uId')
          ? answers[iterationName]?.field_value[questionSet][curField.name]?.iteration_key
          : null
      );
    dispatch(
      ImSpecContentAction.saveAnswer({
        sectionTitle,
        newAnswer: formData,
        caseID,
        masterIntakeID,
        petitionerName
      })
    );

    curField.is_required && !checked.length
      ? setShowRequiredError(true)
      : setShowRequiredError(false);
  };

  return (
    <div
      className={`${
        curField.is_required && showRequiredError ? 'required-error-block-nested' : ''
      }`}
    >
      <Checkbox.Group
        tabIndex={-1}
        disabled={sync_info?.sync_in_progress || section?.is_blocked || radioDisable}
        options={curField?.content.map(value => ({
          key: value,
          label: value,
          value: value
        }))}
        className="radioFieldWrapper"
        value={defaultChecked}
        onChange={onChange}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            defaultChecked.push(event.target.value);
            onChange(defaultChecked);
          }
        }}
      />
      {curField.is_required && <p className="required-error">This field is required.</p>}
    </div>
  );
};

MultiSelect.propTypes = {
  curField: PropTypes.object,
  questionSet: PropTypes.string,
  iterationName: PropTypes.string
};

MultiSelect.defaultProps = {
  curField: {},
  questionSet: '',
  iterationName: ''
};
export default MultiSelect;
