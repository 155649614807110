import PropTypes from 'prop-types';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { ImSpecContentAction } from 'store/Actions';

import useFocus from 'hooks/useFocus';
import useQuery from 'hooks/useQuery';

import {
  ShortText,
  Email,
  Url,
  LongText,
  DateField,
  Country,
  State,
  Phone,
  Dropdown,
  VisaStatus,
  Currency,
  SingleUpload,
  MultiUpload
} from 'components/OpenEndedSet';

import { Button } from 'antd';

import { removeIcon } from 'components/Icons/Icons';
import { FieldTimeOutlined, FileTextFilled } from '@ant-design/icons';

const inputsVerificationError = {
  hasValidEmail: true,
  hasValidUrl: true,
  hasValidPhone: true,
  charactersError: ''
};

const OpenEnded = ({
  realSubsection,
  curField,
  questionSet,
  iterationName,
  setUploadError,
  uploadError,
  curGroup,
  commentsFlag,
  seeMore,
  setIsTyping
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const fieldRef = useRef();
  const { state } = location;
  const { masterIntakeID, petitionerName, sectionTitle, caseID } = useParams();

  const { section, answers, answersLoading, sendAnswersLoading, sync_info } = useSelector(
    state => state.ImSpecContent
  );

  const { comment } = useQuery();

  const seeCommentsRef = useRef(null);
  const [savingValue, setSavingValue] = useState(new FormData());
  const [requiredErrorClassName, setRequiredErrorClassName] = useState('');
  const [answersDataState, setAnswersDataState] = useState({});
  const [inputError, setInputError] = useState(inputsVerificationError);
  const [focused, setFocused] = useState();
  const [showShortTextError, setShowShortTextError] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [radioDisable, setRadioDisable] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [inputRef] = useFocus();
  const [focus, setFocus] = useState(false);

  useLayoutEffect(() => {
    if (
      fieldRef &&
      fieldRef.current &&
      answersDataState &&
      Object.keys(answersDataState).length &&
      (state?.answer_id || comment)
    ) {
      if (!answersLoading && (+fieldRef.current.id === state?.answer_id || +fieldRef.current.id === +comment)) {
        fieldRef.current.scrollIntoView({ behavior: 'smooth' });
        const curAnswer = Object.values(answersDataState).find(
          answer => answer.id === state?.answer_id || answer.id === +comment
        );

        if (state?.action === 'history_log') {
          setTimeout(() => {
            curAnswer && seeMore(curAnswer, curField);
          }, 1000);
        }

        if (state?.action === 'comment' || comment) {
          if (seeCommentsRef && seeCommentsRef.current) {
            setTimeout(() => {
              curAnswer && seeCommentsRef.current.click();
            }, 1000);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersLoading, answersDataState, state]);

  useEffect(() => {
    !!setIsTyping && setIsTyping(!!uploadLoading);
  }, [setIsTyping, uploadLoading]);

  useEffect(() => {
    if (sendAnswersLoading) {
      setRadioDisable(sendAnswersLoading);
    } else {
      !sendAnswersLoading && setRadioDisable(answersLoading);
    }
  }, [sendAnswersLoading, answersLoading]);

  useEffect(() => {
    !!iterationName
      ? !questionSet.startsWith('uId') &&
        setAnswersDataState(answers[iterationName]?.field_value[questionSet])
      : setAnswersDataState(answers);
    !!setIsTyping && setIsTyping(false);
    setShowShortTextError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionSet, answers]);

  // this is for saving inputs
  useEffect(() => {
    const fieldType = savingValue.get('field_type');

    if (savingValue.get('field_value') === answersDataState[curField.name]?.field_value) return;

    if (!focused && !!Array.from(savingValue.keys()).length) {
      if (fieldType === 'Email' && inputError.hasValidEmail) return;
      if (fieldType === 'Url' && inputError.hasValidUrl) return;
      if (fieldType === 'Phone' && !inputError.hasValidPhone) return;
      if (fieldType !== 'Upload' && inputError.charactersError) return;

      dispatch(
        ImSpecContentAction.saveAnswer({
          sectionTitle,
          newAnswer: savingValue,
          caseID,
          redirect: navigate,
          location,
          masterIntakeID,
          petitionerName
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, savingValue]);

  const onBlur = () => {
    setShowShortTextError(true);
    setFocused(false);
    !!setIsTyping && setIsTyping(false);
  };

  const onFocus = () => {
    setFocused(true);
    !!setIsTyping && setIsTyping(true);
  };

  const UploadListItemRender = (originalNode, file, fileList, { remove, preview }) => {
    return (
      <div
        ref={inputRef}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        className={`upload-list-item ${focus ? 'upload-focus' : ''}`}
      >
        <Button
          type="ghost"
          onClick={preview}
          className="upload-list-item-content"
          icon={<FileTextFilled />}
        >
          <span className="upload-list-item-content-title">{file.original_name || file.name}</span>
        </Button>
        <Button
          disabled={sync_info?.sync_in_progress || section?.is_blocked || uploadLoading}
          className="upload-list-item-removeBtn"
          onClick={remove}
          icon={removeIcon}
        />
      </div>
    );
  };

  const onPreviewUpload = file => {
    dispatch(ImSpecContentAction.onPreview(file.id, masterIntakeID));
  };

  return (
    <div
      ref={fieldRef}
      id={answersDataState?.[curField.name]?.id}
      className={requiredErrorClassName}
    >
      <div className="curField">
        {commentsFlag(curField, seeCommentsRef)}
        {curField.type.type === 'Short Text' && (
          <ShortText
            setRequiredErrorClassName={setRequiredErrorClassName}
            curSubsection={section}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            showShortTextError={showShortTextError}
            setSavingValue={setSavingValue}
            setShowShortTextError={setShowShortTextError}
            setInputError={setInputError}
            inputError={inputError}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            focused={focused}
            onFocus={onFocus}
            onBlur={onBlur}
            radioDisable={radioDisable}
            inputRef={inputRef}
            sync_info={sync_info}
          />
        )}
        {curField.type.type === 'Email' && (
          <Email
            setRequiredErrorClassName={setRequiredErrorClassName}
            curSubsection={section}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            savingValue={savingValue}
            setSavingValue={setSavingValue}
            setInputError={setInputError}
            inputError={inputError}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            onFocus={onFocus}
            onBlur={onBlur}
            radioDisable={radioDisable}
            inputRef={inputRef}
            sync_info={sync_info}
          />
        )}
        {curField.type.type === 'Url' && (
          <Url
            setRequiredErrorClassName={setRequiredErrorClassName}
            curSubsection={section}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            savingValue={savingValue}
            setSavingValue={setSavingValue}
            setInputError={setInputError}
            inputError={inputError}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            onFocus={onFocus}
            onBlur={onBlur}
            radioDisable={radioDisable}
            inputRef={inputRef}
            sync_info={sync_info}
          />
        )}
        {curField.type.type === 'Long Text' && (
          <LongText
            setRequiredErrorClassName={setRequiredErrorClassName}
            curSubsection={section}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            setSavingValue={setSavingValue}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            onFocus={onFocus}
            onBlur={onBlur}
            radioDisable={radioDisable}
            inputRef={inputRef}
            sync_info={sync_info}
          />
        )}
        {curField.type.type === 'Date' && (
          <DateField
            setRequiredErrorClassName={setRequiredErrorClassName}
            curSubsection={section}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            setSavingValue={setSavingValue}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            onFocus={onFocus}
            onBlur={onBlur}
            radioDisable={radioDisable}
            sync_info={sync_info}
          />
        )}
        {curField.type.type === 'Country' && (
          <Country
            setRequiredErrorClassName={setRequiredErrorClassName}
            curSubsection={section}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            setSavingValue={setSavingValue}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            onFocus={onFocus}
            onBlur={onBlur}
            radioDisable={radioDisable}
            inputRef={inputRef}
            sync_info={sync_info}
          />
        )}
        {curField.type.type === 'State' && (
          <State
            setRequiredErrorClassName={setRequiredErrorClassName}
            curSubsection={section}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            setSavingValue={setSavingValue}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            onBlur={onBlur}
            radioDisable={radioDisable}
            inputRef={inputRef}
            sync_info={sync_info}
          />
        )}
        {curField.type.type === 'Phone' && (
          <Phone
            setRequiredErrorClassName={setRequiredErrorClassName}
            curSubsection={section}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            setSavingValue={setSavingValue}
            setInputError={setInputError}
            inputError={inputError}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            onFocus={onFocus}
            onBlur={onBlur}
            radioDisable={radioDisable}
            inputRef={inputRef}
            sync_info={sync_info}
          />
        )}
        {curField.type.type === 'Dropdown' && (
          <Dropdown
            setRequiredErrorClassName={setRequiredErrorClassName}
            curSubsection={section}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            setSavingValue={setSavingValue}
            radioDisable={radioDisable}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            inputRef={inputRef}
            sync_info={sync_info}
          />
        )}
        {curField.type.type === 'Visa Status' && (
          <VisaStatus
            setRequiredErrorClassName={setRequiredErrorClassName}
            curSubsection={section}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            setSavingValue={setSavingValue}
            radioDisable={radioDisable}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            inputRef={inputRef}
            sync_info={sync_info}
          />
        )}
        {curField.type.type === 'Currency' && (
          <Currency
            setRequiredErrorClassName={setRequiredErrorClassName}
            curSubsection={section}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            setSavingValue={setSavingValue}
            radioDisable={radioDisable}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            inputRef={inputRef}
            sync_info={sync_info}
          />
        )}
        {curField.type.type === 'Upload' && (
          <SingleUpload
            curGroup={curGroup}
            curSubsection={section}
            realSubsection={realSubsection}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            radioDisable={radioDisable}
            UploadListItemRender={UploadListItemRender}
            onPreviewUpload={onPreviewUpload}
            setUploadLoading={setUploadLoading}
            uploadError={uploadError}
            setUploadError={setUploadError}
            uploadLoading={uploadLoading}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            inputRef={inputRef}
            sync_info={sync_info}
          />
        )}
        {curField.type.type === 'Multi Upload' && (
          <MultiUpload
            curGroup={curGroup}
            curSubsection={section}
            realSubsection={realSubsection}
            answersDataState={answersDataState}
            answers={answers}
            curField={curField}
            iterationName={iterationName}
            questionSet={questionSet}
            radioDisable={radioDisable}
            UploadListItemRender={UploadListItemRender}
            onPreviewUpload={onPreviewUpload}
            setUploadLoading={setUploadLoading}
            uploadError={uploadError}
            setUploadError={setUploadError}
            uploadLoading={uploadLoading}
            showRequiredError={showRequiredError}
            setShowRequiredError={setShowRequiredError}
            inputRef={inputRef}
            sync_info={sync_info}
          />
        )}
        {answersDataState &&
          answersDataState[curField.name] &&
          answersDataState[curField.name]?.field_value !== null && (
            <Button
              type="ghost"
              className={`historyIcon ${
                curField.type.type !== 'Radio Button' && curField.type.type !== 'Checkbox'
                  ? 'upload'
                  : ''
              }`}
              onClick={() => seeMore(answersDataState[curField.name], curField)}
              icon={<FieldTimeOutlined style={{ fontSize: 21, color: '#63A0FF' }} />}
            />
          )}
      </div>
      {((!focused && showShortTextError) || showShortTextError) && inputError.charactersError && (
        <p className="error">{inputError.charactersError}</p>
      )}
      {uploadError.find(error => error.id === curField.id && error.message) && (
        <p className="error">
          {uploadError.find(error => error.id === curField.id && error.id).message}
        </p>
      )}
      {curField.type.type === 'Url' && <span className="url-example">ex: https://example.com</span>}
      {savingValue.get('field_type') === 'Url' &&
        savingValue.get('field_value') &&
        inputError.hasValidUrl && <p className="error">You have entered an invalid URL.</p>}
      {savingValue.get('field_type') === 'Email' &&
        savingValue.get('field_value') &&
        inputError.hasValidEmail && (
          <p className="error">You have entered an invalid e-mail address</p>
        )}
      {curField.is_required && <p className="required-error">This field is required.</p>}
    </div>
  );
};

OpenEnded.propTypes = {
  setIsTyping: PropTypes.func,
  realSubsection: PropTypes.object,
  curField: PropTypes.object,
  questionSet: PropTypes.string,
  iterationName: PropTypes.string,
  setUploadError: PropTypes.func,
  uploadError: PropTypes.array,
  curGroup: PropTypes.object,
  commentsFlag: PropTypes.func,
  seeMore: PropTypes.func
};

OpenEnded.defaultProps = {
  realSubsection: {},
  curField: {},
  questionSet: '',
  iterationName: '',
  uploadError: [],
  curGroup: {}
};

export default OpenEnded;
