import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ImSpecClientPageActions } from 'store/Actions';

import { Button, Form, Modal } from 'antd';
import FloatLabelInput from 'components/shared/FloatLabelInput';
import { useNavigate, useParams } from 'react-router-dom';

const ChangeNameModal = ({
  visible,
  editName,
  userDetails,
  userDetailsLoading,
  setActiveCurCase
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userID } = useParams();

  const [disabled, setDisabled] = useState(true);
  const [emailErr, setEmailErr] = useState('');

  const [form] = Form.useForm();

  useEffect(() => {
    visible &&
      form.setFieldsValue({
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        email: userDetails.email
      });
  }, [form, userDetails, visible]);

  const onFieldsChange = (_, all) => {
    setDisabled(
      all.some(e => e.touched === false || e.errors.length > 0) ||
        !all.find(e => e.value !== userDetails[e.name[0]])
    );
  };

  const onCancel = () => {
    form.resetFields();
    setDisabled(true);
    editName();
    setEmailErr('');
  };

  const onFinish = updatedData => {
    dispatch(
      ImSpecClientPageActions.updateUserData(userDetails.id, updatedData, editName, setEmailErr)
    );
    setActiveCurCase([]);
    navigate(`/client-management/userID=${userID}`);
  };

  return (
    <Modal
      forceRender
      className="assign-modal"
      width={414}
      title="Edit Client Details"
      centered
      closable={false}
      footer={null}
      open={visible}
    >
      <hr />
      <Form
        form={form}
        onFieldsChange={onFieldsChange}
        onFinish={onFinish}
        className="edit-petitioner"
      >
        <Form.Item
          name="first_name"
          validateFirst
          getValueProps={value => ({ value: value || '' })}
          rules={[
            {
              required: true,
              message: 'This field is required.'
            },
            {
              max: 50,
              message: 'Please enter a value shorter than 50 characters.'
            },
            {
              pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
              message: 'This field cannot contain numbers and special characters.'
            }
          ]}
        >
          <FloatLabelInput label="Client First Name" />
        </Form.Item>
        <Form.Item
          name="last_name"
          validateFirst
          getValueProps={value => ({ value: value || '' })}
          rules={[
            {
              required: true,
              message: 'This field is required.'
            },
            {
              max: 50,
              message: 'Please enter a value shorter than 50 characters.'
            },
            {
              pattern: /^[a-zA-Z]+([' -][a-zA-Z]+)*$/,
              message: 'This field cannot contain numbers and special characters.'
            }
          ]}
        >
          <FloatLabelInput label="Client Last Name" />
        </Form.Item>
        <Form.Item
          name="email"
          validateFirst
          getValueProps={value => ({ value: value || '' })}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'This field is required.'
            },
            () => ({
              validator(_, value) {
                if (
                  !!value &&
                  !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    value
                  )
                ) {
                  return Promise.reject(new Error('You have entered an invalid e-mail address.'));
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <FloatLabelInput label="Email" />
        </Form.Item>
        {!!emailErr && <span className="error">{emailErr}</span>}
        {userDetails.state === 'Pending' && (
          <p className="pending-note">
            Invitation summary of all pending items will automatically be sent to the new email
            address.
          </p>
        )}
        {userDetails.state === 'Canceled' && (
          <p className="pending-note">Updating email address will resend the invitation.</p>
        )}
        <Form.Item className="submitting" htmlFor="submit">
          <Button onClick={onCancel} className="assign-btn cancel">
            Cancel
          </Button>
          <Button
            loading={userDetailsLoading}
            disabled={disabled}
            type={disabled ? 'disabled' : 'primary'}
            className="assign-btn assign"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ChangeNameModal.propTypes = {
  visible: PropTypes.bool,
  editName: PropTypes.func,
  userDetails: PropTypes.object,
  userDetailsLoading: PropTypes.bool,
  setActiveCurCase: PropTypes.func
};

ChangeNameModal.defaultProps = {
  visible: false,
  userDetails: {},
  userDetailsLoading: false
};

export default ChangeNameModal;
