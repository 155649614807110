import { ImSpecActionTypes } from '../types';
import { ImSpecContentService, ImSpecPetitionerPageService } from 'services';
import { ImSpecSideBarAction } from 'store/Actions/im_spec.sidebar.action';

import { sectionAndSubsectionNames } from 'utils/helper';

import { Modal } from 'antd';

const getSectionContent =
  (masterIntakeID, petitionerName, sectionTitle, caseID) => async dispatch => {
    try {
      dispatch(ImSpecContentLoading(true));
      let res;

      if (!!masterIntakeID) {
        res = await ImSpecContentService.getMasterIntakeForm(
          masterIntakeID,
          sectionAndSubsectionNames(petitionerName)
        );
        const {
          sync_available,
          sync_date,
          sync_in_progress,
          synced_with_master_intake,
          master_intake_sync_obligation,
          last_sync_type
        } = res.data;
        dispatch(
          updateSyncSectionStatus({
            sync_available,
            sync_date,
            sync_in_progress,
            synced_with_master_intake,
            master_intake_sync_obligation,
            last_sync_type
          })
        );
        dispatch(setSectionContent(res.data));
      } else {
        res = await ImSpecContentService.getSectionContent(
          caseID,
          sectionAndSubsectionNames(sectionTitle)
        );
        const { last_sync_source_type, sync_in_progress, sync_obligation } = res.data;
        dispatch(
          updateSyncSectionStatus({
            last_sync_source_type,
            sync_in_progress,
            sync_obligation
          })
        );
        dispatch(setSectionContent(res.data));
      }
    } catch (e) {
      console.warn(e);
    } finally {
      dispatch(ImSpecContentLoading(false));
    }
  };

const getSectionAnswers =
  (masterIntakeID, petitionerName, sectionTitle, caseID) => async dispatch => {
    try {
      dispatch(ImSpecAnswersLoading(true));
      let res;

      if (!!masterIntakeID) {
        res = await ImSpecContentService.getMasterIntakeAnswers(
          masterIntakeID,
          sectionAndSubsectionNames(petitionerName)
        );
        dispatch(answersContent(res.data));
      } else {
        res = await ImSpecContentService.getSectionAnswers(
          caseID,
          sectionAndSubsectionNames(sectionTitle)
        );
        dispatch(answersContent(res.data));
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setTimeout(() => dispatch(ImSpecAnswersLoading(false)), 1000);
    }
  };

const removeIterativeQuestionSet = (caseID, groupName, iterationKey, sectionTitle) => async () => {
  try {
    await ImSpecContentService.deleteIterationQuestionSet(
      caseID,
      groupName,
      iterationKey,
      sectionAndSubsectionNames(sectionTitle)
    );
  } catch (e) {
    console.warn(e);
  }
};

const seeMoreLogs = (answer, curItem, openModal, masterIntakeID) => async dispatch => {
  try {
    dispatch(entryLogsLoading(true));
    openModal(true);
    let res;

    if (masterIntakeID) {
      res = await ImSpecContentService.getMasterIntakeAnswerLogs(answer.id);
    } else {
      res = await ImSpecContentService.getAnswerLogs(answer.id);
    }

    dispatch(setEntryLogs({ logs: [...res.data], curItem, curAnswer: answer }));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(entryLogsLoading(false));
  }
};

const saveAnswer =
  ({
    sectionTitle,
    newAnswer,
    caseID,
    setUploadLoading,
    setUploadError,
    redirect,
    location,
    masterIntakeID,
    petitionerName
  }) =>
  async (dispatch, getState) => {
    try {
      // ((!!newAnswer.get('group_name') && !newAnswer.get('iteration_key')) || (newAnswer.get('field_type') === 'Radio Button')) && dispatch(ImSpecSendAnswersLoading(true));
      dispatch(ImSpecSendAnswersLoading(true));
      const {
        ImSpecContent: { answers }
      } = getState();

      let res;
      if (masterIntakeID) {
        res = await ImSpecContentService.sendMasterIntakeFieldAnswer(
          masterIntakeID,
          sectionAndSubsectionNames(petitionerName),
          newAnswer
        );
      } else {
        res = await ImSpecContentService.saveAnswer(
          caseID,
          sectionAndSubsectionNames(sectionTitle),
          newAnswer
        );
      }

      !!newAnswer.get('field_file') &&
        setUploadError(prevErrors =>
          prevErrors.map(error =>
            error.name === newAnswer.get('field_name') ? { id: error.id } : { ...error }
          )
        );

      const setNewAnswer = (answer, value) => {
        for (let key in answer) {
          if (answer[key].field_type === 'Hidden' && typeof answer[key].field_value === 'object') {
            setNewAnswer(answer[key].field_value, 'value');
          }

          if (value && typeof answer[key] === 'object') {
            setNewAnswer(answer[key]);
          }
          if (answer[key].id === res.data.data.id) {
            answer[key] = { ...answer[key], ...res.data.data };
          }
        }
      };

      if (!(!!newAnswer.get('group_name') && !newAnswer.get('iteration_key'))) {
        (newAnswer.get('field_type') !== 'Radio Button' ||
          newAnswer.get('field_type') !== 'Upload' ||
          newAnswer.get('field_type') !== 'Multi Upload') &&
          setNewAnswer(answers);
        (newAnswer.get('field_type') !== 'Radio Button' ||
          newAnswer.get('field_type') !== 'Upload' ||
          newAnswer.get('field_type') !== 'Multi Upload') &&
          dispatch(answersContent(answers));
      }

      ((!!newAnswer.get('group_name') && !newAnswer.get('iteration_key')) ||
        newAnswer.get('field_type') === 'Radio Button') &&
        redirect(location.pathname);
      ((!!newAnswer.get('group_name') && !newAnswer.get('iteration_key')) ||
        newAnswer.get('field_type') === 'Radio Button') &&
        dispatch(getSectionAnswers(masterIntakeID, petitionerName, sectionTitle, caseID));
      dispatch(updateSyncSectionStatus(res.data.sync_info));
    } catch (e) {
      console.warn(e);
    } finally {
      !!newAnswer.get('field_file') && setUploadLoading(false);
      dispatch(ImSpecSendAnswersLoading(false));
      // ((!!newAnswer.get('group_name') && !newAnswer.get('iteration_key')) || (newAnswer.get('field_type') === 'Radio Button')) && dispatch(ImSpecSendAnswersLoading(false));
    }
  };

const updateSyncSectionStatus = data => {
  return {
    type: ImSpecActionTypes.UPDATE_SYNC_SECTION_STATUS,
    payload: data
  };
};

const deleteFile =
  (fileID, answerID, setUploadLoading, masterIntakeID) => async (dispatch, getState) => {
    try {
      await setUploadLoading(true);
      const {
        ImSpecContent: { answers }
      } = getState();
      let res;

      if (masterIntakeID) {
        res = await ImSpecContentService.deleteMasterIntakeFile(fileID, answerID);
      } else {
        res = await ImSpecContentService.deleteFile(fileID, answerID);
      }
      const setNewAnswer = (answer, value) => {
        for (let key in answer) {
          if (answer[key].field_type === 'Hidden' && typeof answer[key].field_value === 'object') {
            setNewAnswer(answer[key].field_value, 'value');
          }

          if (value && typeof answer[key] === 'object') {
            setNewAnswer(answer[key]);
          }

          if (answer[key].id === answerID) {
            answer[key] = {
              ...answer[key],
              field_value: JSON.stringify(res.data.data),
              existing_files: [...res.data.data]
            };
          }
        }
      };
      setNewAnswer(answers);

      dispatch(answersContent(answers));
    } catch (e) {
      console.warn(e);
      // todo error handle
      // setUploadError(prevErrors => {
      //     let newState = [];
      //     if (prevErrors.some(({id}) => id === curField.id)) {
      //         newState = prevErrors.map((error) => error.name === curField.name ? {
      //             ...error,
      //             message: e.message
      //         } : {...error});
      //     } else {
      //         newState = [...prevErrors, {id: curField.id, name: curField.name, message: e.message}];
      //     }
      //
      //     return newState;
      // });
    } finally {
      setUploadLoading(false);
    }
  };

const onPreview = (file_id, masterIntakeID) => async () => {
  try {
    let res;
    if (masterIntakeID) {
      res = await ImSpecContentService.checkMasterIntakePreviewLink(file_id);
    } else {
      res = await ImSpecContentService.checkPreviewLink(file_id);
    }

    window.open(res.data.url);
  } catch (e) {
    console.warn(e);
  }
};

const completeSection = (sectionTitle, caseID, approve, setComplete) => async () => {
  try {
    await ImSpecContentService.completeSection(caseID, sectionAndSubsectionNames(sectionTitle), {
      approve
    });
    setComplete(true);
  } catch (e) {
    console.warn(e);
  }
};

const onSync = (masterIntakeID, petitionerName, setSyncDisable) => async dispatch => {
  try {
    setSyncDisable(true);
    const res = await ImSpecPetitionerPageService.syncMasterIntake(
      masterIntakeID,
      sectionAndSubsectionNames(petitionerName)
    );

    dispatch(updateSyncSectionStatus({ ...res.data }));
  } catch (e) {
    const modalProps = {
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal congrats',
      okButtonProps: { className: 'submit-section-confirm-modal-submit_btn' },
      okText: 'Close',
      onOk: () => {}
    };

    const content = (
      <>
        <span className="submit-section-confirm-modal-content">Oops!</span>
        <span className="submit-section-confirm-modal-text">
          This action cannot be completed for now.
        </span>
        <span className="submit-section-confirm-modal-text">Please try again later!</span>
      </>
    );

    e.status === 403 && Modal.info({ ...modalProps, content: content });
  } finally {
    setSyncDisable(false);
  }
};

const seeComments = (answer, masterIntakeID) => async dispatch => {
  try {
    dispatch(ImSpecSeeCommentsLoading(true));
    let res;

    if (masterIntakeID) {
      res = await ImSpecContentService.getMasterIntakeAnswerComments(answer.id);
    } else {
      res = await ImSpecContentService.getAnswerComments(answer.id);
    }

    dispatch(setEntryComments({ comments: [...res.data], answer }));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(ImSpecSeeCommentsLoading(false));
  }
};

const setEntryComments = curItemLogs => {
  return {
    type: ImSpecActionTypes.SET_ENTRY_COMMENTS,
    payload: curItemLogs
  };
};

const addComment = (comment, answer, masterIntakeID) => async (dispatch, getState) => {
  const {
    ImSpecContent: {
      entryComments: { comments },
      answers
    }
  } = getState();
  const answerState = !!answer.iteration_key
    ? answers[answer.group_name].field_value[answer.iteration_key]
    : answers;

  try {
    dispatch(ImSpecSaveCommentLoading(true));
    let res;
    if (masterIntakeID) {
      res = await ImSpecContentService.saveMasterIntakeComment({
        answer: comment.id,
        message: comment.message
      });
    } else {
      res = await ImSpecContentService.saveComment({
        answer: comment.id,
        message: comment.message
      });
    }

    dispatch(
      setEntryComments({
        comments: [...comments, res.data],
        answer: {
          ...answer,
          comments_count: answer.comments_count + 1,
          unresolved_comments_count: answer.unresolved_comments_count + 1
        }
      })
    );

    for (let key in answerState) {
      if (answerState[key].id === answer.id) {
        answerState[key].unresolved_comments_count += 1;
        answerState[key].comments_count += 1;
      }
    }
    dispatch(answersContent({ ...answers }));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(ImSpecSaveCommentLoading(false));
  }
};

const removeComment = (comment, answer, masterIntakeID) => async (dispatch, getState) => {
  const {
    ImSpecContent: {
      entryComments: { comments },
      answers
    }
  } = getState();
  const answerState = !!answer.iteration_key
    ? answers[answer.group_name].field_value[answer.iteration_key]
    : answers;

  try {
    dispatch(ImSpecDeleteCommentLoading(true));

    if (masterIntakeID) {
      await ImSpecContentService.deleteMasterIntakeComment(comment.id);
    } else {
      await ImSpecContentService.deleteComment(comment.id);
    }

    const updatedComments = comments.filter(elem => elem.id !== comment.id);

    dispatch(
      setEntryComments({
        comments: [...updatedComments],
        answer: {
          ...answer,
          comments_count: answer.comments_count + 1,
          unresolved_comments_count: answer.unresolved_comments_count - 1
        }
      })
    );

    for (let key in answerState) {
      if (answerState[key].id === answer.id) {
        answerState[key].unresolved_comments_count -= 1;
        answerState[key].comments_count -= 1;
      }
    }
    dispatch(answersContent({ ...answers }));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(ImSpecDeleteCommentLoading(false));
  }
};

const resolveComment = (answer, masterIntakeID) => async (dispatch, getState) => {
  const {
    ImSpecContent: { answers }
  } = getState();
  const answerState = !!answer.iteration_key
    ? answers[answer.group_name].field_value[answer.iteration_key]
    : answers;

  try {
    dispatch(ImSpecSeeCommentsLoading(true));

    if (masterIntakeID) {
      await ImSpecContentService.resolveMasterIntakeComment(answer.id);
    } else {
      await ImSpecContentService.resolveComment(answer.id);
    }

    dispatch(seeComments({ ...answer, unresolved_comments_count: 0 }, masterIntakeID));

    for (let key in answerState) {
      if (answerState[key].id === answer.id) {
        answerState[key].unresolved_comments_count = 0;
      }
    }
    dispatch(answersContent({ ...answers }));
  } catch (e) {
    console.warn(e);
  }
};

const createDependent =
  (
    values,
    caseID,
    field_name,
    { location, navigate },
    sectionTitle,
    form,
    masterIntakeID,
    petitionerName
  ) =>
  async dispatch => {
    try {
      dispatch(setCreateDependentLoading(true));
      await ImSpecContentService.createDependent(
        values,
        caseID,
        field_name,
        sectionAndSubsectionNames(sectionTitle)
      );

      await navigate(location.pathname);

      await dispatch(getSectionAnswers(masterIntakeID, petitionerName, sectionTitle, caseID));
      await dispatch(ImSpecSideBarAction.getImSpecSidebarCases(caseID));
    } catch (e) {
      console.warn(e);
    } finally {
      dispatch(setCreateDependentLoading(false));
      dispatch(setDependentForm(null, null, null, null, navigate, location));
      form && form.resetFields();
    }
  };

const deleteDependent =
  (data, caseID, field_name, sectionTitle, masterIntakeID, petitionerName) => async dispatch => {
    try {
      await ImSpecContentService.deleteDependent(
        data,
        caseID,
        field_name,
        sectionAndSubsectionNames(sectionTitle)
      );

      const modalProps = {
        width: 438,
        centered: true,
        icon: null,
        title: 'Success!',
        className: 'submit-section-confirm-modal delete-dependent',
        okButtonProps: { className: 'submit-section-confirm-modal-submit_btn' },
        okText: 'Close',
        content: (
          <>
            <span className="submit-section-confirm-modal-text">
              Dependent form successfully removed.
            </span>
          </>
        )
      };

      Modal.info({ ...modalProps });

      dispatch(getSectionAnswers(masterIntakeID, petitionerName, sectionTitle, caseID));
      dispatch(ImSpecSideBarAction.getImSpecSidebarCases(caseID));
    } catch (e) {
      console.warn(e);
    }
  };

const setDependentForm =
  (form_questions, name, iterationName, questionSet, navigate, location) => async dispatch => {
    dispatch(
      setFormQuestionnaire({
        form_questions,
        field_name: name,
        iterationName,
        questionSet
      })
    );

    form_questions && navigate(`${location.pathname}?action=create_dependent`);
  };

const syncAndSubmit = (masterIntakeID, approve, setComplete) => async dispatch => {
  try {
    const res = await ImSpecContentService.syncAndSubmit(masterIntakeID, {
      approve
    });
    setComplete(true);

    dispatch(updateSyncSectionStatus(res.data));
  } catch (e) {
    const modalProps = {
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal congrats',
      okButtonProps: { className: 'submit-section-confirm-modal-submit_btn' },
      okText: 'Close',
      onOk: () => {}
    };

    const content = (
      <>
        <span className="submit-section-confirm-modal-content">Oops!</span>
        <span className="submit-section-confirm-modal-text">
          This action cannot be completed for now.
        </span>
        <span className="submit-section-confirm-modal-text">Please try again later!</span>
      </>
    );

    e.status === 403 && Modal.info({ ...modalProps, content: content });
  }
};

const setCreateDependentLoading = loading => {
  return {
    type: ImSpecActionTypes.SET_CREATE_DEPENDENT_LOADING,
    payload: loading
  };
};

const setChildCaseInfo = info => {
  return {
    type: ImSpecActionTypes.SET_CHILD_CASE_INFO_CONTENT,
    payload: { ...info }
  };
};

const setFormQuestionnaire = questionnaire => {
  return {
    type: ImSpecActionTypes.SET_FORM_QUESTIONNAIRE,
    payload: questionnaire
  };
};

const setEntryLogs = curItemLogs => {
  return {
    type: ImSpecActionTypes.SET_ENTRY_LOGS,
    payload: curItemLogs
  };
};

const entryLogsLoading = loading => {
  return {
    type: ImSpecActionTypes.ENTRY_LOGS_LOADING,
    payload: loading
  };
};

const answersContent = answers => {
  return {
    type: ImSpecActionTypes.SET_IM_SPEC_ANSWERS_CONTENT,
    payload: { ...answers }
  };
};

const setSectionContent = subsectionInfo => {
  return {
    type: ImSpecActionTypes.SET_IM_SPEC_SECTION_CONTENT,
    payload: subsectionInfo
  };
};

const ImSpecContentLoading = loading => {
  return {
    type: ImSpecActionTypes.IM_SPEC_SECTION_CONTENT_LOADING,
    payload: loading
  };
};

const ImSpecAnswersLoading = loading => {
  return {
    type: ImSpecActionTypes.IM_SPEC_SECTION_ANSWERS_LOADING,
    payload: loading
  };
};

const ImSpecSendAnswersLoading = loading => {
  return {
    type: ImSpecActionTypes.IM_SPEC_SECTION_SEND_ANSWERS_LOADING,
    payload: loading
  };
};

const ImSpecDeleteCommentLoading = loading => {
  return {
    type: ImSpecActionTypes.IM_SPEC_SECTION_DELETE_ANSWERS_LOADING,
    payload: loading
  };
};

const ImSpecSeeCommentsLoading = loading => {
  return {
    type: ImSpecActionTypes.IM_SPEC_SECTION_SEE_COMMENTS_LOADING,
    payload: loading
  };
};

const ImSpecSaveCommentLoading = loading => {
  return {
    type: ImSpecActionTypes.IM_SPEC_SECTION_SAVE_COMMENTS_LOADING,
    payload: loading
  };
};

export const ImSpecContentAction = {
  getSectionContent,
  getSectionAnswers,
  saveAnswer,
  deleteFile,
  onPreview,
  setSectionContent,
  answersContent,
  removeIterativeQuestionSet,
  seeMoreLogs,
  setEntryLogs,
  completeSection,
  seeComments,
  setEntryComments,
  addComment,
  removeComment,
  resolveComment,
  setDependentForm,
  deleteDependent,
  createDependent,
  setChildCaseInfo,
  syncAndSubmit,
  onSync
};
