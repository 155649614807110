import { ImSpecActionTypes } from '../types';
import { ImSpecCaseDashboardService } from 'services';

import { Modal } from 'antd';
import { ImSpecSectionDashboardActions } from './im_spec.section.dashboard.action';

const getCasesTableData = search => async (dispatch, getState) => {
  try {
    dispatch(casesLoading(true));
    const {
      ImSpecCaseDashboard: {
        cases: { pageSize, page },
        filterCases: { status, assign, state },
        states: { list }
      }
    } = getState();

    const { data } = search
      ? await ImSpecCaseDashboardService.getSearchCasesData(search, assign, page, pageSize)
      : await ImSpecCaseDashboardService.getCasesData(page, status, assign, state, pageSize);
    const result = search
      ? {
          ...data[0],
          results: data[0].results.map((item, i) => ({
            ...item,
            key: i + 1
          }))
        }
      : {
          ...data,
          results: data.results.map((item, i) => ({ ...item, key: i + 1 }))
        };
    dispatch(setCases(result));

    if (search) {
      const newStates = list.map(state => {
        if (state.state === 'All') {
          state.value = data[0].count;
        }
        return state;
      });

      dispatch(setStates(newStates));
    }
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(casesLoading(false));
  }
};

const getStatuses = () => async (dispatch, getState) => {
  try {
    dispatch(statusLoading(true));
    const {
      ImSpecCaseDashboard: {
        filterCases: { assign }
      }
    } = getState();

    const res = await ImSpecCaseDashboardService.getStatuses(assign);
    dispatch(setStatuses(res.data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(statusLoading(false));
  }
};

const getStates = search => async (dispatch, getState) => {
  try {
    dispatch(stateLoading(true));
    const {
      ImSpecCaseDashboard: {
        filterCases: { assign }
      }
    } = getState();

    const { data } = await ImSpecCaseDashboardService.getStates(assign);
    dispatch(setStates(data));
    search && dispatch(setEntryState('All'));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(stateLoading(false));
  }
};

const onChangeStatuses = status => async dispatch => {
  dispatch(getStatuses());
  dispatch(setEntryStatus(status));
  dispatch(setPage(1));
  dispatch(getCasesTableData());
};

const onChangeStates = state => async dispatch => {
  dispatch(getStates());
  dispatch(setEntryState(state));
  dispatch(setPage(1));
  dispatch(getCasesTableData());
};

const onChangePage = (page, pageSize, search) => async (dispatch, getState) => {
  const {
    ImSpecCaseDashboard: {
      cases: { pageSize: pageSizeFromState, page: pageFromState }
    }
  } = getState();

  if (page !== pageFromState) dispatch(setPage(page));

  if (pageSize !== pageSizeFromState) {
    dispatch(setPage(1));
    dispatch(setPageSize(pageSize));
  }
  dispatch(getCasesTableData(search));
};

const onChangeAssign = (assign, search) => async (dispatch, getState) => {
  const {
    ImSpecCaseDashboard: {
      states: { list }
    }
  } = getState();

  if (list.length && search) {
    dispatch(setEntryAssigned(assign));
    dispatch(getStatuses());
    dispatch(setPage(1));
    dispatch(getCasesTableData(search));
  } else if (list.length && !search) {
    dispatch(setEntryAssigned(assign));
    dispatch(getStates(search));
    dispatch(getStatuses());
    dispatch(setPage(1));
    dispatch(getCasesTableData(search));
  }
};

const caseSpecialists = assign => async dispatch => {
  try {
    dispatch(caseSpecialistsLoading(true));
    const { data } = await ImSpecCaseDashboardService.getCaseSpecialists(assign);

    dispatch(setCaseSpecialists(data));
  } catch (e) {
    console.warn(e);
  } finally {
    dispatch(caseSpecialistsLoading(false));
  }
};

const onSubmitAssignCase = (caseID, data, navigate, setDisabled) => async dispatch => {
  try {
    await dispatch(caseSpecialistsLoading(true));
    const res = await ImSpecCaseDashboardService.handleCaseAssign(caseID, data);

    setDisabled(true);
    navigate(-1);

    await dispatch(caseSpecialistsLoading(false));

    const refreshPage = async () => {
      await dispatch(getStates());
      await dispatch(getStatuses());
      await dispatch(getCasesTableData());
      await dispatch(ImSpecSectionDashboardActions.getCaseList(caseID));
    };

    Modal.info({
      width: 438,
      centered: true,
      icon: null,
      className: 'submit-section-confirm-modal congrats',
      okButtonProps: {
        className: 'submit-section-confirm-modal-submit_btn',
        loading: false
      },
      okText: 'Close',
      content: (
        <>
          <span className="submit-section-confirm-modal-content">Success!</span>
          <span
            className="submit-section-confirm-modal-title"
            dangerouslySetInnerHTML={{ __html: res.data.message }}
          />
        </>
      ),
      onOk: () => refreshPage()
    });
  } catch (e) {
    console.warn(e);
    await dispatch(caseSpecialistsLoading(false));
    navigate(-1);
  }
};

const onSearch = search => async dispatch => {
  try {
    const { data } = await ImSpecCaseDashboardService.onSearch(search);

    dispatch(setSearchResult({ ...data[0], hasData: !!data[0].results.length }));
  } catch (e) {
    console.warn(e);
  }
};

const setSearchQuery = query => {
  return {
    type: ImSpecActionTypes.SET_SEARCH_QUERY,
    payload: query
  };
};

const setSearchResult = result => {
  return {
    type: ImSpecActionTypes.SET_SEARCH_RESULT,
    payload: result
  };
};

const caseSpecialistsLoading = loading => {
  return {
    type: ImSpecActionTypes.CASE_SPECIALISTS_LOADING,
    payload: loading
  };
};

const setCaseSpecialists = specialists => {
  return {
    type: ImSpecActionTypes.SET_CASE_SPECIALISTS,
    payload: specialists
  };
};

const setEntryAssigned = assign => {
  return {
    type: ImSpecActionTypes.SET_ASSIGN,
    payload: assign
  };
};

const setStatuses = statuses => {
  return {
    type: ImSpecActionTypes.SET_STATUSES,
    payload: [...statuses]
  };
};

const setStates = states => {
  return {
    type: ImSpecActionTypes.SET_STATES,
    payload: [...states]
  };
};

const setEntryStatus = status => {
  return {
    type: ImSpecActionTypes.SET_ENTRY_STATUS,
    payload: status
  };
};

const setEntryState = state => {
  return {
    type: ImSpecActionTypes.SET_ENTRY_STATE,
    payload: state
  };
};

const setCases = cases => {
  return {
    type: ImSpecActionTypes.SET_CASES,
    payload: { ...cases }
  };
};

const setPage = page => {
  return {
    type: ImSpecActionTypes.SET_PAGE,
    payload: page
  };
};

const setPageSize = pageSize => {
  return {
    type: ImSpecActionTypes.SET_CASES_PAGE_SIZE,
    payload: pageSize
  };
};

const stateLoading = loading => {
  return {
    type: ImSpecActionTypes.STATES_LOADING,
    payload: loading
  };
};

const statusLoading = loading => {
  return {
    type: ImSpecActionTypes.STATUSES_LOADING,
    payload: loading
  };
};

const casesLoading = loading => {
  return {
    type: ImSpecActionTypes.CASES_LOADING,
    payload: loading
  };
};

export const ImSpecCaseDashboardAction = {
  getCasesTableData,
  getStates,
  getStatuses,
  onChangeStatuses,
  onChangeStates,
  onChangePage,
  onChangeAssign,
  onSubmitAssignCase,
  caseSpecialists,
  setCaseSpecialists,
  onSearch,
  setSearchResult,
  setEntryState,
  setPage,
  setEntryAssigned,
  setSearchQuery,
  setEntryStatus
};
