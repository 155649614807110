import { CustomerActionTypes } from 'store/types';

const initialState = {
  company: {
    companiesList: [],
    isLoading: false
  },
  companyCases: {
    companyCasesList: [],
    isLoading: false
  },
  beneficiaryCases: {
    beneficiaryList: [],
    isLoading: false
  },
  associatedCases: {
    associatedList: [],
    isLoading: false
  },
  case: {
    list: [],
    child_cases: [],
    isLoading: false
  },
  contacts: {
    im_spec: null,
    attorney: null
  }
};

const CustomerClientPageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CustomerActionTypes.COMPANIES_LIST:
      return {
        ...state,
        company: {
          ...state.company,
          companiesList: [...payload]
        }
      };
    case CustomerActionTypes.SET_COMPANY_CASE_LIST:
      return {
        ...state,
        companyCases: {
          ...state.companyCases,
          companyCasesList: [...payload]
        }
      };
    case CustomerActionTypes.COMPANY_CASE_LIST_LOADING:
      return {
        ...state,
        companyCases: {
          ...state.companyCases,
          isLoading: payload
        }
      };
    case CustomerActionTypes.BENEFICIARY_TYPES_LOADING:
      return {
        ...state,
        beneficiaryCases: {
          ...state.beneficiaryCases,
          isLoading: payload
        }
      };
    case CustomerActionTypes.SET_BENEFICIARY_TYPES_LIST:
      return {
        ...state,
        beneficiaryCases: {
          ...state.beneficiaryCases,
          beneficiaryList: [...payload]
        }
      };
    case CustomerActionTypes.ASSOCIATED_TYPES_LOADING:
      return {
        ...state,
        associatedCases: {
          ...state.associatedCases,
          isLoading: payload
        }
      };
    case CustomerActionTypes.SET_ASSOCIATED_TYPES_LIST:
      return {
        ...state,
        associatedCases: {
          ...state.associatedCases,
          associatedList: [...payload]
        }
      };

    case CustomerActionTypes.CLIENT_CASE_LIST_LOADING:
      return {
        ...state,
        case: {
          ...state.case,
          isLoading: payload
        }
      };
    case CustomerActionTypes.SET_CLIENT_CASE_LIST:
      return {
        ...state,
        case: {
          ...state.case,
          list: [...payload]
        }
      };
    case CustomerActionTypes.SET_CONTACTS:
      return {
        ...state,
        contacts: {
          im_spec: payload.im_spec,
          attorney: payload.attorney
        }
      };
    case CustomerActionTypes.SET_CLIENT_PAGE_CHILD_CASES:
      return {
        ...state,
        case: {
          ...state.case,
          child_cases: [...payload]
        }
      };
    default:
      return state;
  }
};

export default CustomerClientPageReducer;
