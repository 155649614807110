import { CustomerActionTypes } from 'store/types';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
  user: user ? { ...user } : {},
  invitedUser: {
    user: {},
    isLoading: false
  },
  isAuthUser: !!user,
  isLoading: false
};

const AuthReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case CustomerActionTypes.REGISTER_REQUEST_OR_LOGIN:
      return {
        ...state,
        isLoading: payload
      };
    case CustomerActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user: { ...payload }
      };
    case CustomerActionTypes.LOG_OUT:
      return {
        ...state,
        user: {}
      };
    case CustomerActionTypes.INVITED_AUTH_USER_DATA:
      return {
        ...state,
        invitedUser: {
          ...state.invitedUser,
          user: { ...payload }
        }
      };
    case CustomerActionTypes.INVITE_AUTH_LOADING:
      return {
        ...state,
        invitedUser: {
          ...state.invitedUser,
          isLoading: payload
        }
      };
    default:
      return state;
  }
};

export default AuthReducer;
