import { ImSpecActionTypes } from 'store/types';

const user = JSON.parse(localStorage.getItem('user'));
const savedView = JSON.parse(localStorage.getItem('case-dashboard-saved_view'));

const initialState = {
  cases: {
    count: 0,
    page: 1,
    pageSize: 100,
    pageSizeOptions: [50, 100, 150, 200],
    list: [],
    isLoading: false
  },
  searchCases: {
    params: {
      query: null,
      pageSize: 5
    },
    result: {
      hasData: null,
      count: 0,
      list: []
    }
  },
  caseSpecialists: {
    immigration_specialists: [],
    immigration_attorneys: [],
    isLoading: false
  },
  savedViews: {
    list: [],
    isLoading: false
  },
  appliedSavedView: savedView
    ? { ...savedView }
    : {
        id: null,
        name: ''
      },
  filters: {
    isLoading: false,
    caseType: {
      list: []
    },
    state: {
      list: []
    },
    petitioner: {
      optionList: [
        { id: 'self_petitioning', value: 'Self-petitioning' },
        { id: 'no_petitioner', value: 'No-petitioner(form)' },
        { id: 'company_sponsored', value: 'Company sponsored' }
      ],
      list: [],
      isLoading: false
    },
    status: {
      list: []
    },
    beneficiary: {
      list: [],
      isLoading: false
    },
    immigrationSpecialist: {
      optionList:
        user?.role === 'attorney'
          ? [
              { id: 'unassigned', value: 'Unassigned' },
              { id: 'assigned_to_others', value: 'Assigned' }
            ]
          : [
              { id: 'unassigned', value: 'Unassigned' },
              { id: 'assigned_to_me', value: 'Assigned to me' },
              { id: 'assigned_to_others', value: 'Assigned to other IS' }
            ],
      immigrationSpecialistsData: {
        list: [],
        isLoading: false
      }
    },
    immigrationAttorney: {
      optionList:
        user?.role === 'IS' || user?.role === 'CS'
          ? [
              { id: 'unassigned', value: 'Unassigned' },
              { id: 'assigned_to_others', value: 'Assigned' }
            ]
          : [
              { id: 'unassigned', value: 'Unassigned' },
              { id: 'assigned_to_me', value: 'Assigned to me' },
              { id: 'assigned_to_others', value: 'Assigned to other IA' }
            ],
      immigrationAttorneysData: {
        list: [],
        isLoading: false
      }
    },
    caseID: {
      list: [],
      isLoading: false
    },
    source: {
      list: []
    }
  },
  filterAnswers: {
    case_type: [],
    state: [],
    last_submitted: {},
    last_edited: {},
    last_reviewed: {},
    last_commented: {},
    last_created: {},
    petitioner: {},
    status: [],
    beneficiary: [],
    im_spec: {},
    attorney: {},
    case_id: [],
    source: [],
    include_test_cases: false
  }
};

const ImSpecCaseDashboardBetaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ImSpecActionTypes.CASES_LOADING:
      return {
        ...state,
        cases: {
          ...state.cases,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_CASES:
      return {
        ...state,
        cases: {
          ...state.cases,
          count: payload.count,
          list: payload.results
        }
      };
    case ImSpecActionTypes.SET_PAGE:
      return {
        ...state,
        cases: {
          ...state.cases,
          page: payload
        }
      };
    case ImSpecActionTypes.SET_CASES_PAGE_SIZE:
      return {
        ...state,
        cases: {
          ...state.cases,
          pageSize: payload
        }
      };
    case ImSpecActionTypes.CASE_SPECIALISTS_LOADING:
      return {
        ...state,
        caseSpecialists: {
          ...state.caseSpecialists,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_CASE_SPECIALISTS:
      return {
        ...state,
        caseSpecialists: {
          ...state.caseSpecialists,
          immigration_specialists: payload.immigration_specialists,
          immigration_attorneys: payload.immigration_attorneys
        }
      };
    case ImSpecActionTypes.SET_SEARCH_RESULT:
      return {
        ...state,
        searchCases: {
          ...state.searchCases,
          result: {
            count: payload.count,
            list: [...payload.results],
            hasData: payload.hasData
          }
        }
      };
    case ImSpecActionTypes.SET_SEARCH_QUERY:
      return {
        ...state,
        searchCases: {
          ...state.searchCases,
          params: {
            ...state.searchCases.params,
            query: payload
          }
        }
      };
    case ImSpecActionTypes.DRAWER_LOADING:
      return {
        ...state,
        filters: {
          ...state.filters,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_CASE_TYPES:
      return {
        ...state,
        filters: {
          ...state.filters,
          caseType: {
            ...state.filters.caseType,
            list: payload
          }
        }
      };
    case ImSpecActionTypes.SET_STATES_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          state: {
            ...state.filters.state,
            list: payload
          }
        }
      };
    case ImSpecActionTypes.SET_PETITIONERS_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          petitioner: {
            ...state.filters.petitioner,
            list: payload
          }
        }
      };
    case ImSpecActionTypes.SET_STATUSES_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          status: {
            ...state.filters.status,
            list: payload
          }
        }
      };
    case ImSpecActionTypes.SET_BENEFICIARY_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          beneficiary: {
            ...state.filters.beneficiary,
            list: payload
          }
        }
      };
    case ImSpecActionTypes.BENEFICIARY_LOADING:
      return {
        ...state,
        filters: {
          ...state.filters,
          beneficiary: {
            ...state.filters.beneficiary,
            isLoading: payload
          }
        }
      };
    case ImSpecActionTypes.SET_IM_SPEC_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          immigrationSpecialist: {
            ...state.filters.immigrationSpecialist,
            immigrationSpecialistsData: {
              ...state.filters.immigrationSpecialist.immigrationSpecialistsData,
              list: payload
            }
          }
        }
      };
    case ImSpecActionTypes.SET_ATTORNEY_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          immigrationAttorney: {
            ...state.filters.immigrationAttorney,
            immigrationAttorneysData: {
              ...state.filters.immigrationAttorney.immigrationAttorneysData,
              list: payload
            }
          }
        }
      };
    case ImSpecActionTypes.SET_CASE_ID_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          caseID: {
            ...state.filters.caseID,
            list: payload
          }
        }
      };
    case ImSpecActionTypes.CASE_ID_LOADING:
      return {
        ...state,
        filters: {
          ...state.filters,
          caseID: {
            ...state.filters.caseID,
            isLoading: payload
          }
        }
      };
    case ImSpecActionTypes.SET_SOURCE_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          source: {
            ...state.filters.source,
            list: payload
          }
        }
      };
    case ImSpecActionTypes.SET_SELECTED_CASE_TYPES:
      return {
        ...state,
        filterAnswers: {
          ...state.filterAnswers,
          case_type: payload
        }
      };
    case ImSpecActionTypes.SET_SELECTED_STATES_DATA:
      return {
        ...state,
        filterAnswers: {
          ...state.filterAnswers,
          state: payload
        }
      };
    case ImSpecActionTypes.SET_LAST_UPDATE:
      return {
        ...state,
        filterAnswers: {
          ...state.filterAnswers,
          ...payload
        }
      };
    case ImSpecActionTypes.SET_SELECTED_PETITIONERS_DATA:
      return {
        ...state,
        filterAnswers: {
          ...state.filterAnswers,
          petitioner: payload
        }
      };
    case ImSpecActionTypes.SET_SELECTED_STATUSES_DATA:
      return {
        ...state,
        filterAnswers: {
          ...state.filterAnswers,
          status: payload
        }
      };
    case ImSpecActionTypes.SET_SELECTED_BENEFICIARY_DATA:
      return {
        ...state,
        filterAnswers: {
          ...state.filterAnswers,
          beneficiary: payload
        }
      };
    case ImSpecActionTypes.SET_SELECTED_IM_SPEC_DATA:
      return {
        ...state,
        filterAnswers: {
          ...state.filterAnswers,
          im_spec: payload
        }
      };
    case ImSpecActionTypes.SET_SELECTED_ATTORNEY_DATA:
      return {
        ...state,
        filterAnswers: {
          ...state.filterAnswers,
          attorney: payload
        }
      };
    case ImSpecActionTypes.SET_SELECTED_CASE_ID_DATA:
      return {
        ...state,
        filterAnswers: {
          ...state.filterAnswers,
          case_id: payload
        }
      };
    case ImSpecActionTypes.SET_SELECTED_SOURCE_DATA:
      return {
        ...state,
        filterAnswers: {
          ...state.filterAnswers,
          source: payload
        }
      };
    case ImSpecActionTypes.SET_INCLUDE_TEST_CASES:
      return {
        ...state,
        filterAnswers: {
          ...state.filterAnswers,
          include_test_cases: payload
        }
      };
    case ImSpecActionTypes.SET_SAVE_FILTERS_LOADING:
      return {
        ...state,
        savedViews: {
          ...state.savedViews,
          isLoading: payload
        }
      };
    case ImSpecActionTypes.SET_SAVED_VIEW_LIST:
      return {
        ...state,
        savedViews: {
          ...state.savedViews,
          list: payload
        }
      };
    case ImSpecActionTypes.RENAME_SAVED_VIEW_NAME:
      return {
        ...state,
        savedViews: {
          ...state.savedViews,
          list: [...payload]
        }
      };
    case ImSpecActionTypes.SET_SAVED_VIEW_NAME:
      return {
        ...state,
        appliedSavedView: {
          ...state.appliedSavedView,
          ...payload
        }
      };
    case ImSpecActionTypes.CLEAR_FILTERS:
      return {
        ...state,
        filterAnswers: payload
          ? {
              ...state.filterAnswers,
              [payload]: initialState.filterAnswers[payload]
            }
          : { ...initialState.filterAnswers }
      };
    case ImSpecActionTypes.ADD_ALL_FILTERS:
      return {
        ...state,
        filterAnswers: payload
      };
    default:
      return state;
  }
};

export default ImSpecCaseDashboardBetaReducer;
