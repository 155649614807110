import PropTypes from 'prop-types';
import Icon, { CopyOutlined } from '@ant-design/icons';
import {
  abilityIcon,
  addressDetails,
  companyIcon,
  contactInformation,
  employmentIcon,
  familyDocuments,
  history,
  qualification,
  status,
  nationalInterest,
  folderIcon
} from 'components/Icons/Icons';

const icons = {
  abilityIcon,
  addressDetails,
  companyIcon,
  contactInformation,
  employmentIcon,
  familyDocuments,
  history,
  intakeIcon: CopyOutlined,
  status,
  qualification,
  nationalInterest,
  folderIcon
};

const SectionIcon = ({ name, ...props }) => {
  const IconComponent = icons[name];
  return <Icon component={IconComponent} {...props} />;
};

SectionIcon.propTypes = {
  name: PropTypes.string
};

SectionIcon.defaultProps = {
  name: ''
};

export default SectionIcon;
