import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';

const PassError = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/forgot-password');
  };

  return (
    <Row type="flex" justify="center" align="middle" className="publicForms">
      <Col span={12} className="publicForms_content resetPass">
        <Row>
          <h4>Oops! Something went wrong.</h4>
        </Row>
        <Row>
          <Col className="subTitle" type="flex" justify="center" align="middle">
            <p style={{ width: '75%' }}>
              You’re seeing this either because you have already reset your password, or the reset
              link has expired.{' '}
            </p>
            <p>
              If you still want to reset your password, click{' '}
              <a href="" onClick={handleClick}>
                here.
              </a>{' '}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PassError;
