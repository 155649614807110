import { Link } from 'react-router-dom';
import { Button, Skeleton, Tooltip } from 'antd';
import moment from 'moment/moment';

import { alreadySynced, syncIcon } from 'components/Icons/Icons';

const masterIntakeTableColumns = ({ syncMasterIntake }) => {
  return [
    {
      title: 'Case type',
      dataIndex: 'case_type',
      key: 'case_type',
      width: '10%',
      ellipsis: true,
      render: (case_type, { petitioner_name, id }) => (
        <div>
          <Link
            to={`/petitioner-dashboard/master-intake/${id}/${petitioner_name.replaceAll(' ', '-')}`}
            style={{ display: 'flex', width: 'fit-content' }}
          >
            <div className={`visa_name v${case_type.replaceAll(' ', '-')}`}>{case_type}</div>
          </Link>
        </div>
      )
    },
    {
      title: <div className="active_cases">Active cases</div>,
      dataIndex: 'active_cases',
      key: 'active_cases',
      width: '15%',
      ellipsis: true,
      render: active_cases => <div className="active_cases">{active_cases}</div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: '15%',
      render: status => (
        <div className={`${status.replaceAll(' ', '-')} section-status`}>
          <span className="table-items">{status}</span>
        </div>
      )
    },
    {
      title: 'Last update',
      dataIndex: 'last_updated_at',
      key: 'last_updated_at',
      ellipsis: true,
      width: '12%',
      render: last_updated_at => (
        <span className="last-update">
          {moment(last_updated_at).format('MM.DD.YYYY')}
          <span className="hours">{moment(last_updated_at).format('hh:mm A')}</span>
        </span>
      )
    },
    {
      title: 'Last sync',
      dataIndex: 'sync_date',
      key: 'sync_date',
      ellipsis: true,
      width: '12%',
      render: sync_date => (
        <span className="last-update">
          {sync_date !== null ? (
            <>
              {moment(sync_date).format('MM.DD.YYYY')}
              <span className="hours">{moment(sync_date).format('hh:mm A')}</span>
            </>
          ) : (
            <>--</>
          )}
        </span>
      )
    },
    {
      title: null,
      dataIndex: 'sync',
      key: 'sync',
      ellipsis: true,
      width: '7%',
      render: (_, { sync_available, sync_in_progress, id, petitioner_name }) => {
        return (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Tooltip
              title={
                sync_available && !sync_in_progress
                  ? 'Sync details to employee cases'
                  : !sync_available && !sync_in_progress
                  ? 'Master intake already synced!'
                  : null
              }
              placement="top"
              overlayClassName="sync-tooltip"
            >
              <Button
                type="ghost"
                className="sync_icon"
                style={{
                  cursor: sync_available && !sync_in_progress ? 'pointer' : 'unset'
                }}
                onClick={sync_available ? () => syncMasterIntake(id, petitioner_name) : null}
                icon={
                  sync_available && !sync_in_progress
                    ? syncIcon
                    : !sync_available && !sync_in_progress
                    ? alreadySynced
                    : null
                }
              >
                {sync_in_progress ? (
                  <div className="in-progress">
                    {syncIcon}
                    <Skeleton.Input className="skeleton-loading" active />
                  </div>
                ) : null}
              </Button>
            </Tooltip>
          </div>
        );
      }
    }
  ];
};

export default masterIntakeTableColumns;
