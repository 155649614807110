import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerProfilePageActions } from 'store/Actions';

import Profile from 'components/Account/elements/Profile';
import Password from 'components/Account/elements/Password';

import { Col } from 'antd';

const Account = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state.customerProfilePage);

  useEffect(() => {
    dispatch(CustomerProfilePageActions.getUserProfile());
  }, [dispatch]);

  return (
    <Col className="account-page">
      <Profile userInfo={userInfo} />
      <Password />
    </Col>
  );
};

export default Account;
